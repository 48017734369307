import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Form, Label, MultiSelect } from '@knack/asterisk-react';

import { type BuilderPageKey } from '@/types/schema/BuilderPage';
import { useLeftPanelContext } from '@/pages/pages/LeftPanelContext';
import { getDropdownMenuOptions } from '@/pages/pages/page-tree/getDropdownMenuOptions';
import { type NewPageFormSchema } from './NewPageForm';

export function NewPageFormMenuSection() {
  const [t] = useTranslation();

  const { nonUserPages, appSingleLoginPage } = useLeftPanelContext();
  const { control } = useFormContext<NewPageFormSchema>();

  const dropdownMenuOptions = getDropdownMenuOptions({
    allPages: nonUserPages,
    appSingleLoginPage
  });

  return (
    <Form.Section className="mt-4">
      <Label htmlFor="new-menu-pages">{t('pages.dialogs.new_menu.select_pages')}</Label>
      <div className="mt-2">
        <Controller
          control={control}
          name="menuPages"
          render={({ field: { onChange, value: selectedPages } }) => {
            const selectedDropdownMenuOptions =
              dropdownMenuOptions.filter((option) =>
                selectedPages?.includes(option.key as BuilderPageKey)
              ) || [];

            return (
              <MultiSelect
                id="new-menu-pages"
                maxVisibleChips={3}
                placeholder={t('pages.page_tree.select_pages')}
                isSearchEnabled
                contentClassName="z-50"
                options={dropdownMenuOptions}
                selectedOptions={selectedDropdownMenuOptions}
                onSelectOptions={(newSelectedPages) => {
                  const selectedOptions = newSelectedPages.map((option) => option.key);
                  onChange(selectedOptions);
                }}
              />
            );
          }}
        />
      </div>
    </Form.Section>
  );
}
