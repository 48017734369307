import { useState } from 'react';
import { DndContext, DragOverlay, type DragEndEvent, type DragStartEvent } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';

import { type BuilderPage } from '@/types/schema/BuilderPage';
import { usePageMutation } from '@/hooks/api/mutations/usePageMutation';
import { useDndUtils } from '@/hooks/useDndUtils';
import { VerticalListSortableItem } from '@/components/dnd/VerticalListSortableItem';
import { useLeftPanelContext } from '@/pages/pages/LeftPanelContext';
import { PageTreeItem } from '@/pages/pages/page-tree/PageTreeItem';

export function DragAndDropContext({ isUserPages }: { isUserPages?: boolean }) {
  const { sortMutation } = usePageMutation();
  const { optimizedSensors, verticalListCollisionDetection } = useDndUtils();
  const { userPages, nonUserRootPages, setUserPages, setNonUserRootPages } = useLeftPanelContext();
  const [beingDraggedPageKey, setBeingDraggedPageKey] = useState<string | null>(null);

  const pages = isUserPages ? userPages : nonUserRootPages;
  const setPages = isUserPages ? setUserPages : setNonUserRootPages;

  function handleSortPages(pagesForSorting: BuilderPage[]) {
    const newPageOrder = pagesForSorting.map((page: BuilderPage) => page.key);

    sortMutation.mutate([
      ...(isUserPages ? nonUserRootPages.map((page) => page.key) : []),
      ...newPageOrder,
      ...(!isUserPages ? userPages.map((page) => page.key) : [])
    ]);
  }

  const handleDragStart = (event: DragStartEvent) => {
    setBeingDraggedPageKey(event.active.id as string);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = pages.findIndex((page) => page.key === active.id);
      const newIndex = pages.findIndex((page) => page.key === over.id);
      const newPages = arrayMove(pages, oldIndex, newIndex);
      if (setPages) {
        setPages(newPages);
      }
      handleSortPages(newPages);
      setBeingDraggedPageKey(null);
    }
  };

  return (
    <DndContext
      sensors={optimizedSensors}
      collisionDetection={verticalListCollisionDetection}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <SortableContext strategy={verticalListSortingStrategy} items={pages.map((page) => page.key)}>
        {pages.map((page) => (
          <VerticalListSortableItem key={page.key} id={page.key}>
            <PageTreeItem page={page} />
          </VerticalListSortableItem>
        ))}
      </SortableContext>
      <DragOverlay>
        {pages.map((page) => {
          if (page.key !== beingDraggedPageKey) {
            return null;
          }

          return <PageTreeItem key={page.key} page={page} />;
        })}
      </DragOverlay>
    </DndContext>
  );
}
