import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  HiPaintBrush as BrushIcon,
  HiViewColumns as ColumnsIcon,
  HiTableCells as FieldsIcon,
  HiCog6Tooth as GeneralSettingsIcon,
  HiMap as MapIcon
} from 'react-icons/hi2';

import { type MapView } from '@/types/schema/views/MapView';
import { CollapsiblePanel } from '@/components/layout/CollapsiblePanel';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { CommonSourceBanner } from '@/pages/pages/settings-panel/view-settings/common/CommonSourceBanner';
import { ViewWithDetailsActionsSettingsCategory } from '@/pages/pages/settings-panel/view-settings/common/details-actions/ViewWithDetailsActionsSettingsCategory';
import { ViewWithDetailsColumnSettings } from '@/pages/pages/settings-panel/view-settings/common/details-field-management/column-settings/ViewWithDetailsColumnSettings';
import { useDetailsSettingsHelpers } from '@/pages/pages/settings-panel/view-settings/common/useDetailsSettingsHelpers';
import { MapDataDisplaySettings } from '@/pages/pages/settings-panel/view-settings/map/MapDataDisplaySettings';
import { MapGeneralSettingsCategory } from '@/pages/pages/settings-panel/view-settings/map/MapGeneralSettingsCategory';
import { MapMapSettingsCategory } from '@/pages/pages/settings-panel/view-settings/map/MapMapSettingsCategory';
import { MapStylingSettings } from '@/pages/pages/settings-panel/view-settings/map/MapStylingSettings';
import { useViewInputEditModeContext } from '@/pages/pages/settings-panel/view-settings/ViewInputEditModeContext';
import {
  ViewSettingsCategoryList,
  type ViewSettingsCategoryItem
} from '@/pages/pages/settings-panel/view-settings/ViewSettingsCategoryList';

type MapSettingsCategoryId =
  | 'general-settings'
  | 'map-settings'
  | 'data-display'
  | 'actions'
  | 'styling'
  | 'column-settings';
type MapSettingsCategoryItem = ViewSettingsCategoryItem<MapSettingsCategoryId>;

export function MapSettings() {
  const [t] = useTranslation();
  const { view } = useActiveViewContext<MapView>();
  const { getViewDetailsInput, getActionColumnSettingsPanelTitle } = useDetailsSettingsHelpers();
  const { viewInputId, isViewInputEditMode, setIsViewInputEditMode } =
    useViewInputEditModeContext();

  const [activeCategoryId, setActiveCategoryId] = useState<MapSettingsCategoryId | null>(null);

  const detailsInputField = getViewDetailsInput(view.columns, viewInputId);

  function getPanelTitle(categories: MapSettingsCategoryItem[]) {
    const activeCategory = activeCategoryId
      ? categories.find((category) => category.id === activeCategoryId)
      : null;

    return activeCategory ? activeCategory.name : t('pages.element_settings.map.heading');
  }

  const MAPS_SETTINGS_CATEGORIES: MapSettingsCategoryItem[] = [
    {
      id: 'general-settings',
      name: t('pages.element_settings.common.categories.general_settings.name'),
      description: t('pages.element_settings.map.categories.general_settings.description'),
      icon: GeneralSettingsIcon
    },
    {
      id: 'map-settings',
      name: t('pages.element_settings.map.categories.map_settings.name'),
      description: t('pages.element_settings.map.categories.map_settings.description'),
      icon: MapIcon
    },
    {
      id: 'data-display',
      name: t('pages.element_settings.common.categories.data_display.name'),
      description: t('pages.element_settings.map.categories.data_display.description'),
      icon: FieldsIcon
    },
    {
      id: 'actions',
      name: t('pages.element_settings.common.categories.actions.name'),
      description: t('pages.element_settings.map.categories.actions.description'),
      icon: ColumnsIcon
    },
    {
      id: 'styling',
      name: t('pages.element_settings.map.categories.styling.name'),
      description: t('pages.element_settings.map.categories.styling.description'),
      icon: BrushIcon
    },
    {
      id: 'column-settings',
      name: getActionColumnSettingsPanelTitle(detailsInputField),
      description: '',
      icon: FieldsIcon,
      shouldHide: true
    }
  ];

  const panelTitle = getPanelTitle(MAPS_SETTINGS_CATEGORIES);
  const shouldHideDivider = activeCategoryId === 'data-display';

  const handleOnBack = () => {
    if (!activeCategoryId) return;

    if (activeCategoryId === 'column-settings') {
      setIsViewInputEditMode(false);
      setActiveCategoryId('data-display');
      return;
    }

    setActiveCategoryId(null);
  };

  useEffect(() => {
    if (isViewInputEditMode && activeCategoryId !== 'column-settings') {
      setActiveCategoryId('column-settings');
    }
  }, [activeCategoryId, isViewInputEditMode]);

  return (
    <CollapsiblePanel.Content
      title={panelTitle}
      onBack={activeCategoryId ? () => handleOnBack() : undefined}
      shouldHideDivider={shouldHideDivider}
    >
      {activeCategoryId ? (
        <>
          {activeCategoryId === 'general-settings' && <MapGeneralSettingsCategory />}
          {activeCategoryId === 'map-settings' && <MapMapSettingsCategory />}
          {activeCategoryId === 'data-display' && <MapDataDisplaySettings />}
          {activeCategoryId === 'actions' && <ViewWithDetailsActionsSettingsCategory />}
          {activeCategoryId === 'styling' && <MapStylingSettings />}
          {activeCategoryId === 'column-settings' && (
            <ViewWithDetailsColumnSettings columns={view.details.columns} />
          )}
        </>
      ) : (
        <>
          <CommonSourceBanner view={view} />
          <ViewSettingsCategoryList
            categories={MAPS_SETTINGS_CATEGORIES}
            onCategorySelect={(selectedCategoryId: string) => {
              setActiveCategoryId(selectedCategoryId as MapSettingsCategoryId);
            }}
          />
        </>
      )}
    </CollapsiblePanel.Content>
  );
}
