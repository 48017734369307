import { trackEvent as intercomTrackEvent } from '@intercom/messenger-js-sdk';

import { useSession } from '@/hooks/useSession';
import { isRudderStackEnabled, rudderStackAnalytics } from '@/utils/rudderstack';
import { shouldInstallIntercom } from '@/IntercomProvider';

const rudderStackTitles = {
  field_add: 'Field Added',
  connection_added: 'Connection Added',
  connection_edited: 'Connection Edited',
  app_previewed: 'App Previewed',
  object_created: 'Object Created',
  import_started: 'Import Started',
  section_added: 'Section Added',
  add_element: 'Element Added',
  add_filtered_user_to_grid: 'Filtered User Added to Grid',
  add_login_to_page: 'Login Added to Page',
  add_page_rule_hide_view: 'Page Rule Hide View Added',
  add_record_rule: 'Record Rule Added',
  theme_edited: 'Theme Edited'
};

interface ThirdPartiesEvent {
  key: string;
  accountId: string;
  additionalInfo?: Record<string, unknown>;
}

const sendEventToThirdParties = (event: ThirdPartiesEvent) => {
  if (shouldInstallIntercom()) {
    // Send event to Intercom
    intercomTrackEvent(event.key, event.additionalInfo ?? {});
  }

  if (isRudderStackEnabled()) {
    // Send event to RudderStack
    void rudderStackAnalytics.track(rudderStackTitles[event.key] || event.key, {
      ...(event.additionalInfo ?? {}),
      groupId: event.accountId
    });
  }
};

export function useTrackEventsHelpers() {
  const session = useSession();

  return {
    trackEvent: (key: string, additionalInfo?: Record<string, unknown>) => {
      sendEventToThirdParties({
        key,
        accountId: session.account?.id,
        additionalInfo
      });
    }
  };
}
