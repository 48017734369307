import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { HiPlus as PlusIcon } from 'react-icons/hi2';
import { DndContext, DragOverlay, type DragEndEvent, type DragStartEvent } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Button } from '@knack/asterisk-react';

import { type FormView, type FormViewDisplayRule } from '@/types/schema/views/FormView';
import { useDndUtils } from '@/hooks/useDndUtils';
import { KNOWLEDGE_BASE_URLS } from '@/utils/knowledge-base';
import { VerticalListSortableItem } from '@/components/dnd/VerticalListSortableItem';
import { LearnMoreLink } from '@/components/LearnMoreLink';
import { usePageEditorContext } from '@/pages/pages/page-editor/PageEditorContext';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { FormDisplayRuleCard } from './FormDisplayRuleCard';
import { FormDisplayRuleFormDialog } from './FormDisplayRuleFormDialog';
import { useFormDisplayRulesHelpers } from './useFormDisplayRulesHelpers';

type NewlyInsertedDisplayRule = {
  key: string;
  insertAction: 'new' | 'duplicate';
};

export function FormDisplayRules() {
  const [t] = useTranslation();

  const { updatePage } = usePageEditorContext();
  const { view, sourceObject } = useActiveViewContext<FormView>();
  const { getInvalidDisplayRules, getAvailableViewFieldsForDisplayRules, getDefaultDisplayRule } =
    useFormDisplayRulesHelpers(view, sourceObject);

  const { optimizedSensors, verticalListCollisionDetection } = useDndUtils();

  const [isAddNewRuleModalOpen, setIsAddNewRuleModalOpen] = useState(false);
  const [newlyInsertedRule, setNewlyInsertedRule] = useState<NewlyInsertedDisplayRule | null>(null);
  const [beingDraggedRuleKey, setBeingDraggedRuleKey] = useState<string | null>(null);

  const availableViewFields = getAvailableViewFieldsForDisplayRules();
  const invalidDisplayRules = getInvalidDisplayRules();
  const totalDisplayRulesCount = view.rules.fields?.length ?? 0;
  const validDisplayRulesCount = totalDisplayRulesCount - invalidDisplayRules.length;

  const onRuleAdd = (newDisplayRule: FormViewDisplayRule) => {
    setNewlyInsertedRule({
      key: newDisplayRule.key,
      insertAction: 'new'
    });

    updatePage({
      type: 'view',
      action: 'update',
      origin: 'builder',
      viewKey: view.key,
      viewSchema: {
        rules: {
          ...view.rules,
          fields: view.rules.fields ? [...view.rules.fields, newDisplayRule] : [newDisplayRule]
        }
      }
    });
  };

  const onRuleSave = (updatedDisplayRule: FormViewDisplayRule) => {
    updatePage({
      type: 'view',
      action: 'update',
      origin: 'builder',
      viewKey: view.key,
      viewSchema: {
        rules: {
          ...view.rules,
          fields: view.rules.fields?.map((displayRule) =>
            displayRule.key === updatedDisplayRule.key ? updatedDisplayRule : displayRule
          )
        }
      }
    });
  };

  const onRuleDuplicate = (displayRuleToDuplicate: FormViewDisplayRule) => {
    const defaultDisplayRule = getDefaultDisplayRule();
    const duplicatedRule: FormViewDisplayRule = {
      ...displayRuleToDuplicate,
      key: defaultDisplayRule.key
    };

    setNewlyInsertedRule({
      key: duplicatedRule.key,
      insertAction: 'duplicate'
    });

    updatePage({
      type: 'view',
      action: 'update',
      origin: 'builder',
      viewKey: view.key,
      viewSchema: {
        rules: {
          ...view.rules,

          // add the duplicated rule right after the original rule in array
          fields: view.rules.fields?.reduce<FormViewDisplayRule[]>((acc, rule) => {
            acc.push(rule);
            if (rule.key === displayRuleToDuplicate.key) {
              acc.push(duplicatedRule);
            }
            return acc;
          }, [])
        }
      }
    });
  };

  const onRuleDelete = (displayRuleKey: FormViewDisplayRule['key']) => {
    updatePage({
      type: 'view',
      action: 'update',
      origin: 'builder',
      viewKey: view.key,
      viewSchema: {
        rules: {
          ...view.rules,
          fields: view.rules.fields?.filter((displayRule) => displayRule.key !== displayRuleKey)
        }
      }
    });
  };

  const handleDragStart = (event: DragStartEvent) => {
    setBeingDraggedRuleKey(event.active.id as string);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    if (!view.rules.fields) {
      return;
    }

    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = view.rules.fields.findIndex((rule) => rule.key === active.id) ?? -1;
      const newIndex = view.rules.fields.findIndex((rule) => rule.key === over.id) ?? -1;

      if (oldIndex === -1 || newIndex === -1) {
        return;
      }

      const reorderedRules = arrayMove(view.rules.fields, oldIndex, newIndex);

      updatePage({
        type: 'view',
        action: 'update',
        origin: 'builder',
        viewKey: view.key,
        viewSchema: {
          rules: {
            ...view.rules,
            fields: reorderedRules
          }
        }
      });

      setBeingDraggedRuleKey(null);
    }
  };

  return (
    <>
      <div className="mb-2 flex items-center gap-1">
        <span className="text-xs font-medium text-emphasis">
          {t('pages.element_settings.form.categories.form_fields.display_rules.manage_rules')}
        </span>
        <span className="rounded-sm bg-subtle px-1 py-0.5 text-xs font-medium text-default">
          {totalDisplayRulesCount === validDisplayRulesCount
            ? totalDisplayRulesCount
            : t(
                'pages.element_settings.form.categories.form_fields.display_rules.valid_rules_count',
                {
                  validCount: validDisplayRulesCount,
                  totalCount: totalDisplayRulesCount
                }
              )}
        </span>
      </div>
      <p className="mb-4 text-xs text-subtle">
        <Trans
          i18nKey="pages.element_settings.form.categories.form_fields.display_rules.manage_rules_description"
          components={[<LearnMoreLink key="0" href={KNOWLEDGE_BASE_URLS.DISPLAY_RULES_OVERVIEW} />]}
        />
      </p>

      <Button intent="secondary" className="w-full" onClick={() => setIsAddNewRuleModalOpen(true)}>
        <PlusIcon size={16} className="mr-2" />
        {t('pages.element_settings.form.categories.form_fields.display_rules.add_rule')}
      </Button>

      {view.rules.fields && view.rules.fields.length > 0 ? (
        <DndContext
          sensors={optimizedSensors}
          collisionDetection={verticalListCollisionDetection}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={view.rules.fields.map((rule) => rule.key)}
            strategy={verticalListSortingStrategy}
          >
            <div className="mt-4 space-y-2">
              {view.rules.fields.map((displayRule, ruleIndex) => (
                <VerticalListSortableItem key={displayRule.key} id={displayRule.key}>
                  <FormDisplayRuleCard
                    displayRule={displayRule}
                    availableViewFields={availableViewFields}
                    ruleNumber={ruleIndex + 1}
                    isInvalid={invalidDisplayRules.some(
                      (invalidRule) => invalidRule.key === displayRule.key
                    )}
                    shouldScrollIntoView={newlyInsertedRule?.key === displayRule.key}
                    onRuleSave={onRuleSave}
                    onRuleDuplicate={onRuleDuplicate}
                    onRuleDelete={onRuleDelete}
                  />
                </VerticalListSortableItem>
              ))}
            </div>
          </SortableContext>

          <DragOverlay>
            {view.rules.fields.map((displayRule, ruleIndex) => {
              if (displayRule.key !== beingDraggedRuleKey) {
                return null;
              }

              return (
                <FormDisplayRuleCard
                  key={displayRule.key}
                  isDragOverlay
                  displayRule={displayRule}
                  availableViewFields={availableViewFields}
                  ruleNumber={ruleIndex + 1}
                  isInvalid={invalidDisplayRules.some(
                    (invalidRule) => invalidRule.key === displayRule.key
                  )}
                  onRuleSave={onRuleSave}
                  onRuleDuplicate={onRuleDuplicate}
                  onRuleDelete={onRuleDelete}
                />
              );
            })}
          </DragOverlay>
        </DndContext>
      ) : (
        <div className="mt-4 text-xs text-subtle">
          <h4 className="mb-2 font-semibold">
            {t(
              'pages.element_settings.form.categories.form_fields.display_rules.rules_information_1'
            )}
          </h4>
          <p className="mb-2">
            {t(
              'pages.element_settings.form.categories.form_fields.display_rules.rules_information_2'
            )}
          </p>
          <ul className="list-outside list-disc space-y-2 pl-5">
            <li>
              <Trans
                i18nKey="pages.element_settings.form.categories.form_fields.display_rules.rules_information_3"
                components={[<span key="0" className="font-semibold" />]}
              />
            </li>
            <li>
              <Trans
                i18nKey="pages.element_settings.form.categories.form_fields.display_rules.rules_information_4"
                components={[<span key="0" className="font-semibold" />]}
              />
            </li>
          </ul>
        </div>
      )}

      {isAddNewRuleModalOpen && (
        <FormDisplayRuleFormDialog
          onOpenChange={setIsAddNewRuleModalOpen}
          displayRule={getDefaultDisplayRule()}
          availableViewFields={availableViewFields}
          formIntent="add"
          onRuleSave={(newDisplayRule) => {
            setIsAddNewRuleModalOpen(false);
            onRuleAdd(newDisplayRule);
          }}
        />
      )}
    </>
  );
}
