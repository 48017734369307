import { type KnackField, type KnackFieldType } from '@/types/schema/KnackField';

export const FIELD_TYPES_INELIGIBLE_FOR_FIELD_RULES: KnackFieldType[] = [
  'auto_increment',
  'concatenation',
  'sum',
  'min',
  'max',
  'average',
  'count',
  'equation'
];

export function getEligibleFieldsForFieldRules(fields: KnackField[]): KnackField[] {
  return fields.filter((field) => !FIELD_TYPES_INELIGIBLE_FOR_FIELD_RULES.includes(field.type));
}
