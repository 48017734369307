import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HiPlus as PlusIcon, HiXMark as RemoveIcon } from 'react-icons/hi2';
import { Fragment } from 'react/jsx-runtime';
import { Button, Select } from '@knack/asterisk-react';
import snakeCase from 'lodash.snakecase';

import { type BuilderPageRule, type BuilderPageRuleCriteria } from '@/types/schema/BuilderPage';
import { type KnackField } from '@/types/schema/KnackField';
import { type KnackObject } from '@/types/schema/KnackObject';
import { useCriteriaHelpers } from '@/hooks/helpers/useCriteriaHelpers';
import { shouldHideValueBasedOnOperator } from '@/utils/field-operators';
import { cn } from '@/utils/tailwind';
import { FormErrorMessage } from '@/components/errors/FormErrorMessage';
import { FieldIcon } from '@/components/FieldIcon';
import { FieldCriteriaValueInput } from '@/components/inputs/FieldCriteriaValueInput';
import { usePageRuleHelpers, type PageRuleCriteriaFieldOption } from './usePageRuleHelpers';

interface PageRuleCriteriaFormProps {
  pageRuleCriteriaFieldOptions: PageRuleCriteriaFieldOption[];
  pageSourceObject: KnackObject | undefined;
}

export function PageRuleCriteriaForm({
  pageRuleCriteriaFieldOptions,
  pageSourceObject
}: PageRuleCriteriaFormProps) {
  const [t] = useTranslation();

  const {
    getCriteriaFieldOperators,
    getDefaultCriteriaValue,
    getDefaultCriteriaOperator,
    shouldResetCriteriaValue,
    shouldResetCriteriaOperator
  } = useCriteriaHelpers();

  const { getPageRuleCriteriaFieldFromKey, getDefaultPageRuleCriteria } = usePageRuleHelpers();

  const {
    control,
    getValues,
    clearErrors: clearFormErrors,
    formState: { errors },
    watch
  } = useFormContext<BuilderPageRule>();

  const {
    fields: criteriaArrayFields,
    append: appendCriteria,
    update: updateCriteria,
    remove: removeCriteria
  } = useFieldArray({
    name: 'criteria',
    control
  });

  const criteriaArray = criteriaArrayFields.map((field, index) => ({
    ...field,
    ...watch('criteria')[index]
  }));

  const onCriteriaFieldChange = ({
    criteriaIndex,
    newCriteriaFieldKey
  }: {
    criteriaIndex: number;
    newCriteriaFieldKey: BuilderPageRuleCriteria['field'];
  }) => {
    let previousField: KnackField | null = null;
    let newField: KnackField | null = null;

    const criteriaToEdit = getValues(`criteria.${criteriaIndex}`);

    pageRuleCriteriaFieldOptions.forEach((criteriaFieldOption) => {
      if (criteriaFieldOption.criteriaFieldKey === criteriaToEdit.field) {
        previousField = criteriaFieldOption.field;
      } else if (criteriaFieldOption.criteriaFieldKey === newCriteriaFieldKey) {
        newField = criteriaFieldOption.field;
      }
    });

    if (!newField) {
      return;
    }

    const newCriteria = {
      ...criteriaToEdit,
      field: newCriteriaFieldKey,
      operator: shouldResetCriteriaOperator(newField, criteriaToEdit.operator, 'submit-rule')
        ? getDefaultCriteriaOperator(newField, 'submit-rule')
        : criteriaToEdit.operator,
      value:
        !previousField || shouldResetCriteriaValue(newField, previousField)
          ? getDefaultCriteriaValue(newField)
          : criteriaToEdit.value
    };

    updateCriteria(criteriaIndex, newCriteria);
    clearFormErrors();
  };

  return (
    <section className="rounded-lg bg-subtle p-2">
      {criteriaArray.length === 0 && t('components.rules.run_every_time')}

      {criteriaArray.map((criteria, criteriaIndex) => {
        const criteriaField = getPageRuleCriteriaFieldFromKey(
          criteria.field,
          pageRuleCriteriaFieldOptions
        );

        const criteriaFieldOperators = criteriaField
          ? getCriteriaFieldOperators(criteriaField, 'submit-rule')
          : [];
        const shouldHideValue = shouldHideValueBasedOnOperator(criteria.operator);

        return (
          <Fragment key={criteria.id}>
            {criteriaIndex > 0 && (
              <p className="my-3 text-xs font-medium">{t('components.rules.and_uppercase')}</p>
            )}

            <div className="flex">
              <div className="min-w-0 flex-1">
                <div className="flex gap-2">
                  <div className="max-w-1/2 -m-1 flex-1 overflow-hidden p-1">
                    <Controller
                      control={control}
                      name={`criteria.${criteriaIndex}.field`}
                      render={({ field: { value: fieldKey } }) => (
                        <Select
                          value={criteriaField ? fieldKey : undefined}
                          onValueChange={(
                            newCriteriaFieldKey: BuilderPageRuleCriteria['field']
                          ) => {
                            onCriteriaFieldChange({
                              criteriaIndex,
                              newCriteriaFieldKey
                            });
                          }}
                        >
                          <Select.Trigger
                            data-testid="criteria-field-select"
                            placeholder={t('actions.select')}
                            className={cn('w-full', {
                              'border-destructive hover:border-destructive focus:border-destructive focus:outline-destructive':
                                errors?.criteria?.[criteriaIndex]?.field
                            })}
                          />
                          <Select.Content className="!w-fit max-w-[480px]">
                            {pageRuleCriteriaFieldOptions.map((criteriaFieldOption) => (
                              <Select.Item
                                key={criteriaFieldOption.criteriaFieldKey}
                                value={criteriaFieldOption.criteriaFieldKey}
                                data-testid="criteria-field-select-option"
                              >
                                <span className="flex items-center">
                                  <FieldIcon
                                    className="mr-2 shrink-0 text-subtle"
                                    size={16}
                                    name={criteriaFieldOption.field.type}
                                  />
                                  <span className="overflow-hidden overflow-ellipsis whitespace-nowrap">
                                    {criteriaFieldOption.label}
                                  </span>
                                </span>
                              </Select.Item>
                            ))}
                          </Select.Content>
                        </Select>
                      )}
                    />
                    <FormErrorMessage
                      name={`criteria.${criteriaIndex}.field`}
                      errors={errors}
                      className="mt-1"
                    />
                  </div>

                  <div className="max-w-1/2 -m-1 flex-1 overflow-hidden p-1">
                    <Controller
                      control={control}
                      name={`criteria.${criteriaIndex}.operator`}
                      render={({ field: { value: selectedOperator, onChange } }) => (
                        <Select
                          disabled={!criteriaField}
                          value={selectedOperator}
                          onValueChange={onChange}
                        >
                          <Select.Trigger
                            data-testid="criteria-operator-select"
                            placeholder={t('actions.select')}
                            className="w-full"
                          />
                          <Select.Content>
                            {criteriaFieldOperators.map((operator) => (
                              <Select.Item key={operator} value={operator}>
                                {t(`operators.${snakeCase(operator)}`)}
                              </Select.Item>
                            ))}
                          </Select.Content>
                        </Select>
                      )}
                    />
                  </div>
                </div>

                {!shouldHideValue && criteriaField && (
                  <div className="mt-2">
                    <FieldCriteriaValueInput
                      selectedField={criteriaField}
                      selectedOperator={criteria.operator}
                      index={criteriaIndex}
                    />
                  </div>
                )}
              </div>
              {criteriaArray.length > 1 && (
                <Button
                  data-testid="remove-criteria-button"
                  intent="minimal"
                  aria-label={t('components.rules.delete_condition')}
                  size="xs"
                  className="ml-2 mt-1.5 text-subtle hover:bg-emphasis"
                  onClick={() => removeCriteria(criteriaIndex)}
                >
                  <RemoveIcon size={16} />
                </Button>
              )}
            </div>
          </Fragment>
        );
      })}
      <Button
        intent="secondary"
        aria-label={t('components.rules.add_condition')}
        className="mt-3"
        onClick={() => {
          const defaultCriteriaToAdd = getDefaultPageRuleCriteria(
            pageRuleCriteriaFieldOptions,
            pageSourceObject
          );
          if (defaultCriteriaToAdd) {
            appendCriteria(defaultCriteriaToAdd);
          }
        }}
      >
        <Button.Icon icon={PlusIcon} position="left" />
        {t('components.rules.condition')}
      </Button>
    </section>
  );
}
