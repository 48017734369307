import { type ZodSchema } from 'zod';

import { type KnackField } from '@/types/schema/KnackField';
import { type KnackObject } from '@/types/schema/KnackObject';
import {
  AddressFormSettings,
  addressSchema
} from '@/components/field-settings/address/AddressFormSettings';
import {
  AggregateFormSettings,
  aggregateSchema,
  countSchema
} from '@/components/field-settings/aggregate/AggregateFormSettings';
import {
  BooleanFormSettings,
  booleanSchema
} from '@/components/field-settings/boolean/BooleanFormSettings';
import {
  ConcatenationFormSettings,
  concatenationSchema
} from '@/components/field-settings/concatenation/ConcatenationFormSettings';
import {
  ConnectionFormSettings,
  connectionSchema
} from '@/components/field-settings/connection/ConnectionFormSettings';
import {
  CurrencyFormSettings,
  currencySchema
} from '@/components/field-settings/currency/CurrencyFormSettings';
import {
  DateTimeFormSettings,
  dateTimeSchema
} from '@/components/field-settings/date-time/DateTimeFormSettings';
import {
  DurationFormSettings,
  durationSchema
} from '@/components/field-settings/duration/DurationFormSettings';
import {
  EmailFormSettings,
  emailSchema
} from '@/components/field-settings/email/EmailFormSettings';
import {
  EquationFormSettings,
  equationSchema
} from '@/components/field-settings/equation/EquationFormSettings';
import { FileFormSettings, fileSchema } from '@/components/field-settings/file/FileFormSettings';
import {
  ImageFormSettings,
  imageSchema
} from '@/components/field-settings/image/ImageFormSettings';
import { LinkFormSettings, linkSchema } from '@/components/field-settings/link/LinkFormSettings';
import {
  MultipleChoiceFormSettings,
  multipleChoiceSchema
} from '@/components/field-settings/multiple-choice/MultipleChoiceFormSettings';
import { NameFormSettings, nameSchema } from '@/components/field-settings/name/NameFormSettings';
import {
  NumberFormSettings,
  numberSchema
} from '@/components/field-settings/number/NumberFormSettings';
import {
  PhoneFormSettings,
  phoneSchema
} from '@/components/field-settings/phone/PhoneFormSettings';
import {
  RatingFormSettings,
  ratingSchema
} from '@/components/field-settings/rating/RatingFormSettings';

type FieldSettingsMap = {
  [key in KnackField['type']]: {
    schema: ZodSchema | null;
    omit?: Record<string, boolean>;
    component:
      | ((props: { field: any; isNewField: boolean; objectKey: KnackObject['key'] }) => JSX.Element)
      | null;
  };
};

// In the future we could remove the `omit` property and use the `schema` to determine which properties are required, unique, and default.
// This would make the code more maintainable and easier to understand.
export const fieldSettingsMap: FieldSettingsMap = {
  short_text: {
    schema: null,
    component: null
  },
  number: {
    schema: numberSchema,
    component: NumberFormSettings
  },
  email: {
    schema: emailSchema,
    omit: { default: true },
    component: EmailFormSettings
  },
  rating: {
    schema: ratingSchema,
    omit: { default: true, required: true, unique: true },
    component: RatingFormSettings
  },
  name: {
    schema: nameSchema,
    omit: { default: true, unique: true },
    component: NameFormSettings
  },
  link: {
    schema: linkSchema,
    component: LinkFormSettings
  },
  address: {
    schema: addressSchema,
    omit: { unique: true, default: true },
    component: AddressFormSettings
  },
  rich_text: {
    schema: null,
    component: null
  },
  paragraph_text: {
    schema: null,
    component: null
  },
  auto_increment: {
    schema: null,
    omit: { required: true, unique: true, default: true },
    component: null
  },
  signature: {
    schema: null,
    omit: { unique: true, default: true },
    component: null
  },
  currency: {
    schema: currencySchema,
    component: CurrencyFormSettings
  },
  sum: {
    schema: aggregateSchema,
    omit: { required: true, unique: true, default: true },
    component: AggregateFormSettings
  },
  min: {
    schema: aggregateSchema,
    omit: { required: true, unique: true, default: true },
    component: AggregateFormSettings
  },
  max: {
    schema: aggregateSchema,
    omit: { required: true, unique: true, default: true },
    component: AggregateFormSettings
  },
  average: {
    schema: aggregateSchema,
    omit: { required: true, unique: true, default: true },
    component: AggregateFormSettings
  },
  count: {
    schema: countSchema,
    omit: { required: true, unique: true, default: true },
    component: AggregateFormSettings
  },
  date_time: {
    schema: dateTimeSchema,
    omit: { unique: true, default: true },
    component: DateTimeFormSettings
  },
  timer: {
    schema: durationSchema,
    omit: { unique: true, default: true },
    component: DurationFormSettings
  },
  boolean: {
    schema: booleanSchema,
    omit: { unique: true },
    component: BooleanFormSettings
  },
  multiple_choice: {
    schema: multipleChoiceSchema,
    omit: { unique: true },
    component: MultipleChoiceFormSettings
  },
  file: {
    schema: fileSchema,
    omit: { unique: true, default: true },
    component: FileFormSettings
  },
  image: {
    schema: imageSchema,
    omit: { unique: true, default: true },
    component: ImageFormSettings
  },
  phone: {
    schema: phoneSchema,
    component: PhoneFormSettings
  },
  connection: {
    schema: connectionSchema,
    omit: { unique: true },
    component: ConnectionFormSettings
  },
  concatenation: {
    schema: concatenationSchema,
    omit: { required: true, unique: true, default: true },
    component: ConcatenationFormSettings
  },
  equation: {
    schema: equationSchema,
    omit: { required: true, unique: true, default: true },
    component: EquationFormSettings
  },
  password: {
    schema: null,
    component: null
  },
  user_roles: {
    schema: null,
    component: null
  }
};
