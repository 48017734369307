import { useEffect, useRef } from 'react';
import { Controller, useFormContext, type ControllerRenderProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DatePicker, Input } from '@knack/asterisk-react';
import { DateTime } from 'luxon';

import { type TimerField } from '@/types/schema/fields';
import { useDateTimeHelpers } from '@/hooks/helpers/useDateTimeHelpers';
import { DAY_PICKER_FROM_YEAR } from '@/components/data-table/helpers/constants';
import { TimeInput } from '@/components/import/confirm-import/date-time/TimeInput';
import {
  defaultDateFormatMap,
  type TimeValues
} from '@/components/import/confirm-import/date-time/types';
import { getDefaultTimerData } from '@/components/import/confirm-import/timer/helpers';
import { type TimerPayload } from '@/components/import/confirm-import/timer/types';

interface TimerInputsProps {
  targetField: TimerField;
  name: string;
  id: string;
}

/**
 *  @deprecated
 */
export function TimerInput({ targetField, name, id }: TimerInputsProps) {
  const [t] = useTranslation();
  const { getValues, setValue } = useFormContext();
  const { getFormattedDatePickerDate } = useDateTimeHelpers();

  const inputRef = useRef<HTMLInputElement>(null);
  const endInputRef = useRef<HTMLInputElement>(null);

  const inputFormat = targetField.format;
  const dateFormat = defaultDateFormatMap[inputFormat.date_format || 'mm/dd/yyyy'];
  const ignoreDate = inputFormat.date_format === 'Ignore Date';

  const timeFormat =
    !inputFormat.time_format || inputFormat.time_format === 'Ignore Time'
      ? 'HH:MM am'
      : inputFormat.time_format;

  const handleSelectDate = (
    date: Date,
    fieldValue: TimerPayload,
    onChange: ControllerRenderProps['onChange'],
    isEndDate?: boolean
  ) => {
    if (isEndDate) {
      onChange({
        times: [
          {
            ...fieldValue,
            to: { ...fieldValue.to, date: DateTime.fromJSDate(date).toFormat(dateFormat) }
          }
        ]
      });
      return;
    }
    onChange({
      times: [
        {
          ...fieldValue,
          from: { ...fieldValue.from, date: DateTime.fromJSDate(date).toFormat(dateFormat) }
        }
      ]
    });
  };

  const handleChangeTime = (
    time: TimeValues,
    fieldValue: TimerPayload,
    onChange: ControllerRenderProps['onChange'],
    isEndTime?: boolean
  ) => {
    const { hours, minutes, am_pm: amPm, rawTime } = time;

    if (isEndTime) {
      onChange({
        times: [
          {
            ...fieldValue,
            to: {
              ...fieldValue.to,
              hours,
              minutes,
              am_pm: amPm,
              rawTime
            }
          }
        ]
      });
      return;
    }

    onChange({
      times: [
        {
          ...fieldValue,
          from: {
            ...fieldValue.from,
            hours,
            minutes,
            am_pm: amPm,
            rawTime
          }
        }
      ]
    });
  };

  useEffect(() => {
    if (!getValues(name)) {
      setValue(name, getDefaultTimerData(targetField.format));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-wrap items-center gap-2">
      <Controller
        name={name}
        render={({ field: { value: fieldValue, onChange } }) => {
          const timesFieldValue =
            fieldValue.times?.[0] || getDefaultTimerData(targetField.format).times[0];

          const formattedFromDate = getFormattedDatePickerDate(
            timesFieldValue.from.date,
            dateFormat
          );
          const formattedToDate = getFormattedDatePickerDate(timesFieldValue.to.date, dateFormat);

          return (
            <>
              {!ignoreDate && (
                <DatePicker
                  mode="single"
                  selected={formattedFromDate}
                  onSelect={(date) => handleSelectDate(date, timesFieldValue, onChange)}
                  triggerRef={inputRef}
                  calendarProps={{
                    fromYear: DAY_PICKER_FROM_YEAR,
                    toYear: DateTime.fromJSDate(formattedToDate).year,
                    disabled: formattedToDate && {
                      after: formattedToDate
                    }
                  }}
                >
                  <Input
                    ref={inputRef}
                    id={id}
                    value={timesFieldValue.from.date}
                    className="text-sm"
                    data-testid="form-view-timer-input"
                  />
                </DatePicker>
              )}
              <TimeInput
                selectedTime={timesFieldValue.from}
                format={timeFormat}
                onChangeTime={(time) => handleChangeTime(time, timesFieldValue, onChange)}
                toTime={
                  !ignoreDate &&
                  DateTime.fromFormat(timesFieldValue.from.date, dateFormat).equals(
                    DateTime.fromFormat(timesFieldValue.to.date, dateFormat)
                  )
                    ? timesFieldValue.to
                    : undefined
                }
                isSameDate={
                  !ignoreDate &&
                  DateTime.fromFormat(timesFieldValue.from.date, dateFormat).equals(
                    DateTime.fromFormat(timesFieldValue.to.date, dateFormat)
                  )
                }
              />
              {!ignoreDate && <p className="text-sm">{t('keywords.to')}</p>}
              {!ignoreDate && (
                <DatePicker
                  mode="single"
                  selected={formattedToDate}
                  onSelect={(date) => handleSelectDate(date, timesFieldValue, onChange, true)}
                  triggerRef={endInputRef}
                  calendarProps={{
                    fromYear: DateTime.fromJSDate(formattedFromDate).year,
                    disabled: formattedFromDate && {
                      before: formattedFromDate
                    }
                  }}
                >
                  <Input
                    ref={endInputRef}
                    id={`${id}-end-date-input`}
                    value={timesFieldValue.to.date}
                    className="text-sm"
                    data-testid="form-view-timer-input"
                  />
                </DatePicker>
              )}
              {ignoreDate && <p>{t('keywords.to')}</p>}
              <TimeInput
                selectedTime={timesFieldValue.to}
                format={timeFormat}
                onChangeTime={(time) => handleChangeTime(time, timesFieldValue, onChange, true)}
                fromTime={
                  !ignoreDate &&
                  DateTime.fromFormat(timesFieldValue.from.date, dateFormat).equals(
                    DateTime.fromFormat(timesFieldValue.to.date, dateFormat)
                  )
                    ? timesFieldValue.from
                    : undefined
                }
                isSameDate={
                  !ignoreDate &&
                  DateTime.fromFormat(timesFieldValue.from.date, dateFormat).equals(
                    DateTime.fromFormat(timesFieldValue.to.date, dateFormat)
                  )
                }
              />
            </>
          );
        }}
      />
    </div>
  );
}
