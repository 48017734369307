import { type BaseKnackField } from '@/types/schema/KnackField';
import { type KnackObject } from '@/types/schema/KnackObject';

export const CONNECTION_INPUT_OPTIONS = ['chosen', 'checkbox', 'radio'] as const;
export const CONNECTION_DEFAULT_OPTIONS = ['none', 'first'] as const;
export const CONNECTION_RELATIONSHIP_OPTIONS = ['one', 'many'] as const;

export type ConnectionOption = (typeof CONNECTION_RELATIONSHIP_OPTIONS)[number];

export interface ConnectionFieldFormat {
  input: (typeof CONNECTION_INPUT_OPTIONS)[number];
  conn_default: (typeof CONNECTION_DEFAULT_OPTIONS)[number];
}

export interface ConnectionField extends BaseKnackField {
  type: 'connection';
  format?: ConnectionFieldFormat;
  relationship: {
    belongs_to: ConnectionOption;
    has: ConnectionOption;
    object?: KnackObject['key'];
  };
}
