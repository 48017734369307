import { type ConcatenationField, type EquationField } from '@/types/schema/fields';

type KnackLegacyEquationField = { type: 'field'; field?: { key: string } };
type KnackLegacyEquationText = { type: 'text'; text: string };
export type KnackLegacyEquationItem = KnackLegacyEquationField | KnackLegacyEquationText;

export const transformLegacyEquation = (equation: KnackLegacyEquationItem[]) =>
  equation
    .map((item) => {
      if (item.type === 'field') {
        return `{${item.field?.key}}`;
      }

      return item.text || '';
    })
    .join('');

export const sanitizeEquationSchema = (field: ConcatenationField | EquationField) => {
  if (!field.format) return field;

  // Trigger when the equation is malformed, or we have unknown properties
  if (
    typeof field.format.equation !== 'string' ||
    typeof (field.format as any).format !== 'undefined'
  ) {
    if (typeof field.format.equation !== 'string') {
      field.format.equation = transformLegacyEquation(field.format.equation);
    }

    if (field.type === 'equation') {
      const equationFallback = {
        equation_type: 'numeric',
        mark_decimal: 'none',
        precision: '1',
        rounding: 'none',
        mark_thousands: 'none',
        format: 'none',
        equation: '',
        date_format: 'mm/dd/yyyy',
        time_format: 'HH:MM am',
        date_result: 'number',
        date_type: 'days',
        post: '',
        pre: ''
      };

      field.format = {
        ...equationFallback,
        ...field.format
      };

      if ((field.format.rounding as any) === 'default') {
        field.format.rounding = 'none';
      }
    }
  }

  return field;
};
