import { type FieldValues } from 'react-hook-form';
import { DateTime } from 'luxon';

import { type DateTimeFieldFormat } from '@/types/schema/fields/DateTimeField';
import { type DateTimeRawValue } from '@/components/data-table/display/fields/Field';
import { type KnackDatePickerSchema } from '@/components/knack-date-picker/KnackDatePickerSchema';
import { getKnackDatePickerFormatFromField } from '@/components/knack-date-picker/transformers/DateTimeFormatToLuxonFormat';
import {
  isDate,
  JsDateToKnackDateTransformer
} from '@/components/knack-date-picker/transformers/JsDateToKnackDateTransformer';
import { KnackDateToJsDateTransformer } from '@/components/knack-date-picker/transformers/KnackDateToJsDateTransformer';
import {
  transformKnackWeekDayToWeekDaysArray,
  transformWeekDayArrayToKnackWeekDay
} from '@/components/knack-date-picker/transformers/KnackWeekDaysTransformer';

const transformFormStateToServerData = (
  state: FieldValues,
  fieldFormat: DateTimeFieldFormat
): DateTimeRawValue => {
  const datePickerFormat = getKnackDatePickerFormatFromField(fieldFormat);

  const endDate =
    isDate(state.repeat?.end_date) && datePickerFormat.date
      ? DateTime.fromJSDate(state.repeat.end_date).toFormat(datePickerFormat.date)
      : undefined;

  return {
    ...JsDateToKnackDateTransformer(state.date, fieldFormat),
    all_day: state.all_day,
    repeat: {
      ...state.repeat,
      ...(state.repeat?.frequency === 'weekly' &&
        transformWeekDayArrayToKnackWeekDay(state.repeat?.weekDays)),
      end_date: endDate
    } satisfies DateTimeRawValue['repeat'] // The zod schema of the form is more strict than the repeat type, so we need to cast it
  };
};

const transformServerDataToFormState = (
  defaultValue: DateTimeRawValue,
  fieldFormat: DateTimeFieldFormat
): KnackDatePickerSchema => {
  const calendarMode = fieldFormat.calendar ? 'range' : 'single';

  const datePickerFormat = getKnackDatePickerFormatFromField(fieldFormat);

  const defaultEndDate = (() => {
    if (!defaultValue.repeat?.end_date) return undefined;

    const endDate = DateTime.fromFormat(
      defaultValue.repeat?.end_date,
      datePickerFormat.date || 'MM/dd/yyyy'
    );
    if (endDate.isValid) return endDate.toJSDate();

    return undefined;
  })();

  const datePickerValues =
    calendarMode === 'single'
      ? KnackDateToJsDateTransformer(defaultValue) || undefined
      : {
          from: KnackDateToJsDateTransformer(defaultValue) || undefined,
          to: (defaultValue.to && KnackDateToJsDateTransformer(defaultValue.to)) || undefined
        };

  return {
    date: datePickerValues,
    all_day: defaultValue.all_day || false,
    repeat: defaultValue.repeat
      ? ({
          frequency: defaultValue.repeat?.frequency || 'daily',
          interval: parseInt(defaultValue.repeat?.interval || '1', 10),
          repeatby: defaultValue.repeat?.repeatby || 'dom',
          endson: defaultValue.repeat?.endson || 'never',
          end_count: parseInt(defaultValue.repeat?.end_count || '1', 10),
          end_date: defaultEndDate,
          weekDays: transformKnackWeekDayToWeekDaysArray(
            defaultValue.repeat || {
              MO: false,
              TU: false,
              WE: false,
              TH: false,
              FR: false,
              SA: false,
              SU: false
            }
          )
        } as KnackDatePickerSchema['repeat']) // The zod schema of the form is more strict than the repeat type, so we need to cast it
      : undefined
  };
};

export const knacDatePickerTransformers = {
  toServer: transformFormStateToServerData,
  fromServer: transformServerDataToFormState
};
