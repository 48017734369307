import { type DateTimeRangeType } from '@/types/schema/fields';
import { type KnackFieldKey } from '@/types/schema/KnackField';
import { type KnackOperator } from '@/types/schema/KnackOperator';

export const KNACK_CRITERIA_VALUE_TYPES = ['custom', 'field'] as const;
export type KnackCriteriaValueType = (typeof KNACK_CRITERIA_VALUE_TYPES)[number];

export const ASSET_UNIT_OPTIONS = ['bytes', 'kilobytes', 'megabytes'] as const;
export type AssetUnitOption = (typeof ASSET_UNIT_OPTIONS)[number];

export type KnackCriteria = {
  field: KnackFieldKey;
  operator: KnackOperator;
  value:
    | string

    // Only for boolean fields
    | boolean

    // If the value is an array of strings, it means the field is a connection. The array will contain the id of the record (e.g. ["66b3d6f8f160620293d83294"])
    | string[]

    // Value for date/time fields
    | KnackCriteriaDateTimeValue;

  // Used for date/time field ranges to specific the range value (e.g. the number of weeks) and address field ranges (e.g. zip code miles)
  range?: string | number;

  // Used for date/time field day ranges from 0 to 6, where 0 is Sunday. Only used in validation rules criteria
  from_date?: number;
  to_date?: number;

  // Used for date/time fields to specify the range type (e.g. number of weeks, number of days, etc.)
  type?: DateTimeRangeType;

  // Used for setting the color of an item in views based on a criteria (e.g. calendar events in a calendar view)
  color?: string;

  // Used by image and file fields. Only used in validation rules criteria
  unit?: AssetUnitOption;
};

export type KnackCriteriaWithValueType = KnackCriteria & {
  value_type?: KnackCriteriaValueType;
  value_field?: KnackFieldKey;
};

export interface KnackCriteriaDateTimeValue {
  date?: string;
  all_day?: boolean;
  hours?: number | string;
  minutes?: number | string;
  time?: string;
  am_pm?: 'AM' | 'PM' | '';

  // Used for date/time field ranges, but only when the range is a date range. Only used in validation rules criteria
  from_date?: {
    all_day?: boolean;
    date: string;
  };
  to_date?: {
    all_day?: boolean;
    date?: string;
    hours?: number;
    minutes?: number;
    time?: number;
    am_pm?: string;
  };
}
