import { useState, type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { type IconType } from 'react-icons';
import { Button, Combobox, Dialog, Tooltip, type ComboboxOption } from '@knack/asterisk-react';

import { type BuilderPage } from '@/types/schema/BuilderPage';
import { type KnackObject } from '@/types/schema/KnackObject';
import { usePagesQuery } from '@/hooks/api/queries/usePagesQuery';

function DisabledActionButtonTooltip({
  isDisabled,
  children
}: {
  isDisabled?: boolean;
  children: ReactNode;
}) {
  const [t] = useTranslation();

  if (!isDisabled) {
    return children;
  }

  return (
    <Tooltip>
      <Tooltip.Trigger asChild>
        <div>{children}</div>
      </Tooltip.Trigger>
      <Tooltip.Content>
        {t('pages.element_settings.common.categories.actions.this_column_already_exists')}
      </Tooltip.Content>
    </Tooltip>
  );
}

export type ActionItem = {
  key: string;
  label: string;
  icon: IconType;
  onClick: () => void;
  isDisabled?: boolean;
  dataTestId?: string;
};

export type ViewActionSettingsProps = {
  actions: ActionItem[];
  sourceObject: KnackObject;
  onApplyLinkToExistingPage: (page: BuilderPage) => void;
  handleCloseLinkToExistingPageDialog: () => void;
};

export function ViewActionsSettings({
  actions,
  sourceObject,
  onApplyLinkToExistingPage,
  handleCloseLinkToExistingPageDialog
}: ViewActionSettingsProps) {
  const [t] = useTranslation();
  const { data: pages = [] } = usePagesQuery();

  const [linkToExistingPageSlug, setLinkToExistingPageSlug] = useState({
    key: '',
    label: ''
  });

  const handleApplyLinkToExistingPage = () => {
    const selectedPage = pages.find((p) => p.key === linkToExistingPageSlug.key);

    if (!selectedPage) {
      return;
    }

    onApplyLinkToExistingPage(selectedPage);

    handleCloseLinkToExistingPageDialog();
  };

  const getParentPageName = (parentSlug: string | null) => {
    const parentPage = pages.find((p) => p.slug === parentSlug);
    return parentPage?.name || parentSlug;
  };

  // A list of existing pages that share the same source object as the original object
  const childPagesWithSameSourceObject: ComboboxOption[] = [];
  pages.forEach((page) => {
    if (page.parentSlug && page.sourceObjectKey === sourceObject.key) {
      const parentName = getParentPageName(page.parentSlug);

      childPagesWithSameSourceObject.push({
        key: page.key,
        label: `${parentName} > ${page.name}`
      });
    }
  });

  const hasChildPages = childPagesWithSameSourceObject.length > 0;

  return (
    <>
      <p>{t('pages.element_settings.common.categories.actions.select_action_type')}</p>
      {actions.map((action) => {
        if (action.key === 'link' && !hasChildPages) {
          return null;
        }

        return (
          <DisabledActionButtonTooltip key={action.key} isDisabled={action.isDisabled}>
            <Button
              intent="secondary"
              disabled={action.isDisabled}
              className="w-full justify-start gap-2 font-normal hover:bg-muted"
              onClick={() => action.onClick()}
              data-testid={action.dataTestId}
            >
              <Button.Icon icon={action.icon} />
              {action.label}
            </Button>
          </DisabledActionButtonTooltip>
        );
      })}
      <Dialog.Content>
        <Dialog.MainContent>
          <Dialog.Header>
            <Dialog.Title>
              {t('pages.element_settings.common.categories.actions.select_page')}
            </Dialog.Title>
            <Dialog.Description>
              {t('pages.element_settings.common.categories.actions.select_page_description')}
            </Dialog.Description>
          </Dialog.Header>
          <Combobox
            id="link-to-existing-page"
            selectedOption={linkToExistingPageSlug || childPagesWithSameSourceObject[0]}
            options={childPagesWithSameSourceObject}
            onSelectOption={(option) => setLinkToExistingPageSlug(option)}
            triggerClassName="mt-4 w-full"
            isSearchEnabled={childPagesWithSameSourceObject.length > 5}
          />
          <p className="mt-4">
            {t('pages.element_settings.common.categories.actions.select_page_additional_info')}
          </p>
        </Dialog.MainContent>
        <Dialog.Footer>
          <Dialog.Close asChild>
            <Button intent="secondary">{t('actions.cancel')}</Button>
          </Dialog.Close>
          <Button intent="primary" onClick={handleApplyLinkToExistingPage}>
            {t('actions.apply')}
          </Button>
        </Dialog.Footer>
      </Dialog.Content>
    </>
  );
}
