import { useLocation, useParams } from 'react-router-dom';

import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';

type LinkToClassicBuilderProps = {
  text: string;
};

export function LinkToClassicBuilder({ text }: LinkToClassicBuilderProps) {
  const location = useLocation();
  const { id: tableOrPageKey } = useParams();

  const { data: application } = useApplicationQuery();

  function getV3Path() {
    const oldBuilderUrl = `${import.meta.env.PUBLIC_BUILDER_URL}/${
      application?.account.slug
    }/${application?.slug}`;

    if (location.pathname.includes('tables') && tableOrPageKey) {
      return `${oldBuilderUrl}/records/objects/${tableOrPageKey}`;
    }
    if (location.pathname.includes('pages') && tableOrPageKey) {
      return `${oldBuilderUrl}/pages/${tableOrPageKey}`;
    }
    if (location.pathname.includes('settings')) {
      return `${oldBuilderUrl}/settings`;
    }
    if (location.pathname.includes('flows')) {
      return `${oldBuilderUrl}/flows`;
    }
    if (location.pathname.includes('payments')) {
      return `${oldBuilderUrl}/settings/ecommerce`;
    }
    if (location.pathname.includes('themes')) {
      return `${oldBuilderUrl}/settings/design`;
    }

    return oldBuilderUrl;
  }

  return (
    <a href={getV3Path()} className="text-emphasis underline underline-offset-4">
      <span>{text} 🔀</span>
    </a>
  );
}
