import { type BaseKnackField } from '@/types/schema/KnackField';

export const BOOLEAN_FORMAT_OPTIONS = ['yes_no', 'on_off', 'true_false'] as const;
export const BOOLEAN_INPUT_OPTIONS = ['dropdown', 'checkbox', 'radios'] as const;

export interface BooleanField extends BaseKnackField {
  type: 'boolean';
  default: boolean;
  format?: {
    default: boolean;
    format: (typeof BOOLEAN_FORMAT_OPTIONS)[number];
    input: (typeof BOOLEAN_INPUT_OPTIONS)[number];
    required: boolean;
    text?: string;
  };
}
