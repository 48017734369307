import { useTranslation } from 'react-i18next';
import { generatePath, Navigate, Outlet, useParams } from 'react-router-dom';

import { type BuilderPage } from '@/types/schema/BuilderPage';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { usePagesQuery } from '@/hooks/api/queries/usePagesQuery';
import { MainLayout } from '@/components/layout/MainLayout';
import { ResizableCollapsiblePanel } from '@/components/layout/ResizableCollapsiblePanel';
import { LeftPanelContextProvider } from '@/pages/pages/LeftPanelContext';
import { PageEditorMessagingContextProvider } from '@/pages/pages/page-editor/PageEditorMessagingContext';
import { PagesPageContextProvider } from '@/pages/pages/PagesPageContext';
import { ROUTES } from '@/Router';
import { LeftPanelContent, OPEN_COLLAPSIBLES_KEY } from './LeftPanelContent';

function PagesPageContent({ pages }: { pages: BuilderPage[] }) {
  const [t] = useTranslation();

  return (
    <MainLayout>
      <PageEditorMessagingContextProvider>
        <PagesPageContextProvider pages={pages}>
          <ResizableCollapsiblePanel
            title={t('components.layout.left_sidebar.pages')}
            panelContent={
              <LeftPanelContextProvider>
                <LeftPanelContent />
              </LeftPanelContextProvider>
            }
            mainContent={<Outlet />}
            autoSaveId="global-panel"
          />
        </PagesPageContextProvider>
      </PageEditorMessagingContextProvider>
    </MainLayout>
  );
}

export function PagesPage() {
  const urlParams = useParams<{ id: string }>();

  const { data: application } = useApplicationQuery();
  const { data: pages, isPending: isPagesQueryPending } = usePagesQuery();

  if (isPagesQueryPending) {
    return null;
  }

  if (!pages) {
    throw new Error('Error fetching pages');
  }

  const { id: activePageKeyFromUrl } = urlParams;
  const lastActivePageKey = localStorage.getItem(`lastActivePageKey-${application?.id}`);

  // If there isn't a page key in the URL but there is a 'last saved page' in local storage, we redirect to that saved page.
  // Example: navigating to `/pages` will redirect to `/pages/scene_123`
  if (!activePageKeyFromUrl && lastActivePageKey) {
    const activePage = pages?.find((p) => p.key === lastActivePageKey);
    if (activePage) {
      if (activePage.type !== 'menu') {
        return (
          <Navigate
            to={generatePath(ROUTES.PAGES_ID, {
              id: lastActivePageKey
            })}
          />
        );
      }
      if (activePage.dropdownPages && activePage.dropdownPages.length > 0) {
        // push the new dropdown page to the list of open collapsibles
        const openCollapsibles = localStorage.getItem(
          `${application?.id}-${OPEN_COLLAPSIBLES_KEY}`
        );
        const newItems = [
          ...(openCollapsibles ? JSON.parse(openCollapsibles) : []),
          activePage.key
        ];
        localStorage.setItem(
          `${application?.id}-${OPEN_COLLAPSIBLES_KEY}`,
          JSON.stringify(newItems)
        );
        return (
          <Navigate
            to={generatePath(ROUTES.PAGES_ID, {
              id: activePage.dropdownPages[0]
            })}
          />
        );
      }
    }
  }

  // Keep track of the current active page in local storage
  if (activePageKeyFromUrl) {
    localStorage.setItem(`lastActivePageKey-${application?.id}`, activePageKeyFromUrl);
  }

  return <PagesPageContent pages={pages} />;
}
