import { type Dispatch, type SetStateAction } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Dialog, useToast } from '@knack/asterisk-react';

import {
  useApplicationSettingsMutation,
  type AppSettingsPayload
} from '@/hooks/api/mutations/useApplicationSettingsMutation';
import { KNOWLEDGE_BASE_URLS } from '@/utils/knowledge-base';

interface DisableScriptProtectionDialogProps {
  isOpen: boolean;
  onOpenChange: Dispatch<SetStateAction<boolean>>;
}

export function DisableScriptProtectionDialog({
  isOpen,
  onOpenChange
}: DisableScriptProtectionDialogProps) {
  const [t] = useTranslation();
  const { mutate: updateApplicationSettings } = useApplicationSettingsMutation();
  const { presentToast } = useToast();

  const handleDisableScriptProtection = async () => {
    const payload: AppSettingsPayload = {
      settings: {
        scriptProtectionEnabled: false
      }
    };
    updateApplicationSettings(payload, {
      onSuccess: () => {
        presentToast({
          title: t('app_settings.settings_saved_successfully')
        });
      },
      onError: () => {
        presentToast({
          title: t('app_settings.settings_saved_error')
        });
      }
    });
  };

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <Dialog.Content>
        <Dialog.MainContent>
          <Dialog.Header className="mb-4">
            <Dialog.Title>{t('app_settings.security.confirmation_required')}</Dialog.Title>
          </Dialog.Header>
          <p>
            <Trans
              i18nKey="app_settings.security.by_disabling_script_protection"
              components={{
                1: (
                  <a
                    href={KNOWLEDGE_BASE_URLS.APP_SETTINGS_OVERVIEW}
                    target="_blank"
                    className="underline"
                    rel="noopener noreferrer"
                  >
                    this setting
                  </a>
                ),
                2: (
                  <a
                    href="https://owasp.org/www-community/attacks/xss"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="underline"
                  >
                    input-based attacks
                  </a>
                )
              }}
            />
          </p>
          <p className="mt-2 text-emphasis">{t('app_settings.security.are_you_sure_disable')}</p>
        </Dialog.MainContent>
        <Dialog.Footer>
          <Dialog.Close asChild>
            <Button
              intent="destructive"
              data-testid="discard-changes-modal-cancel-button"
              onClick={handleDisableScriptProtection}
            >
              {t('app_settings.security.i_understand_disable')}
            </Button>
          </Dialog.Close>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  );
}
