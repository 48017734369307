import { type CalendarView } from '@/types/schema/views/CalendarView';
import { type FormView } from '@/types/schema/views/FormView';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { useGetFormSourceDescription } from '@/pages/pages/settings-panel/view-settings/form/FormSourceBanner';
import { ViewSourceBanner } from '@/pages/pages/settings-panel/view-settings/ViewSourceBanner';

export function CalendarSourceBanner({ action }: { action: FormView['action'] }) {
  const { view } = useActiveViewContext<CalendarView>();
  const sourceDescription = useGetFormSourceDescription({
    ...view,
    action
  });

  return <ViewSourceBanner className="mb-4">{sourceDescription}</ViewSourceBanner>;
}
