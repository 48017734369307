import i18n from 'i18next';

import { type KnackViewType } from '@/types/schema/BuilderView';
import { type SelectableView } from './types';

export const RECORD_VIEWS: SelectableView[] = [
  {
    type: 'table',
    label: i18n.t('views.view_names.table'),
    category: 'record'
  },
  {
    type: 'form',
    label: i18n.t('views.view_names.form'),
    category: 'record'
  },
  {
    type: 'list',
    label: i18n.t('views.view_names.list'),
    category: 'record'
  },
  {
    type: 'details',
    label: i18n.t('views.view_names.details'),
    category: 'record'
  },
  {
    type: 'map',
    label: i18n.t('views.view_names.map'),
    category: 'record'
  },
  {
    type: 'calendar',
    label: i18n.t('views.view_names.calendar'),
    category: 'record'
  },
  {
    type: 'report',
    subType: 'bar',
    label: i18n.t('views.view_names.bar_chart'),
    category: 'record'
  },
  {
    type: 'report',
    subType: 'line',
    label: i18n.t('views.view_names.line_chart'),
    category: 'record'
  },
  {
    type: 'report',
    subType: 'pie',
    label: i18n.t('views.view_names.pie_chart'),
    category: 'record'
  },
  {
    type: 'report',
    subType: 'area',
    label: i18n.t('views.view_names.area_chart'),
    category: 'record'
  },
  {
    type: 'search',
    label: i18n.t('views.view_names.search'),
    category: 'record'
  }
];

export const STATIC_VIEWS: SelectableView[] = [
  {
    type: 'menu',
    label: i18n.t('views.view_names.menu'),
    category: 'static'
  },
  {
    type: 'rich_text',
    label: i18n.t('views.view_names.rich_text'),
    category: 'static'
  }
];

export const ECOMMERCE_VIEWS: SelectableView[] = [
  {
    type: 'checkout',
    label: i18n.t('views.view_names.checkout'),
    category: 'ecommerce'
  },
  {
    type: 'customer',
    label: i18n.t('views.view_names.customer'),
    category: 'ecommerce'
  }
];

export const SINGLE_RECORD_VIEW_TYPES: KnackViewType[] = ['details', 'checkout', 'customer'];
