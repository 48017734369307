import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { HiBolt as TasksIcon } from 'react-icons/hi2';
import {
  MdPlaylistAddCheck as ChecklistIcon,
  MdCallSplit as ConditionalIcon
} from 'react-icons/md';
import { PiPlugs as ConnectionsIcon } from 'react-icons/pi';
import { useParams } from 'react-router-dom';

import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { useTasksQuery } from '@/hooks/api/queries/useTasksQuery';
import { useGlobalState } from '@/hooks/useGlobalStore';
import { DataModelIcon } from '@/components/DataModelIcon';
import { RightSidebar } from '@/components/layout/RightSidebar';
import { SidePanel } from '@/components/SidePanel';
import { useTablesPageContext } from '@/pages/tables/TablesPageContext';
import { ROUTES, type PageUrlParams } from '@/Router';
import { ConnectionsPanel } from './connections/ConnectionsPanel';
import { ConditionalRulesPanel } from './rules/conditional-rules/ConditionalRulesPanel';
import { ValidationRulesPanel } from './rules/validation-rules/ValidationRulesPanel';
import { TasksPanel } from './tasks/TasksPanel';

export type ActiveSidebarItemType =
  | 'connections'
  | 'validationRules'
  | 'conditionalRules'
  | 'tasks';

const getSidebarItems = (accountLevel: number = 0) => [
  {
    id: 'connections',
    icon: ConnectionsIcon,
    tooltipI18nKey: 'components.data_table.right_sidebar.connections.title',
    isPublicFeature: true
  },
  {
    id: 'validationRules',
    icon: ChecklistIcon,
    tooltipI18nKey: 'components.data_table.right_sidebar.validation_rules.title',
    isPublicFeature: true
  },
  {
    id: 'conditionalRules',
    icon: ConditionalIcon,
    tooltipI18nKey: 'components.data_table.right_sidebar.conditional_rules.title',
    isPublicFeature: true
  },
  ...(accountLevel > 1
    ? [
        {
          id: 'tasks',
          icon: TasksIcon,
          tooltipI18nKey: 'components.data_table.right_sidebar.tasks.title',
          isPublicFeature: true
        }
      ]
    : []),
  {
    id: 'dataModel',
    icon: DataModelIcon,
    // Query param to track origin of navigation
    link: `${ROUTES.DATA_MODEL}?origin=builder-next`,
    tooltipI18nKey: 'components.data_table.right_sidebar.data_model.title',
    isPublicFeature: true
  }
];

export function ToolkitSidebar() {
  const [t] = useTranslation();
  const { id: tableKey } = useParams<PageUrlParams>();

  const { data: application, isLoading: isApplicationLoading } = useApplicationQuery();
  const { data: tasks } = useTasksQuery();

  const { mainLayoutContainerRef } = useGlobalState((state) => ({
    mainLayoutContainerRef: state.mainLayoutContainerRef
  }));

  const {
    activeToolkitSidebarItem,
    activeToolkitSidebarItemOriginField,
    isToolkitSidebarOpen,
    setIsToolkitSidebarOpen,
    setActiveToolkitSidebarItem
  } = useTablesPageContext();

  const object = useMemo(
    () => application?.objects.find((o) => o.key === tableKey),
    [application, tableKey]
  );

  const tableTasks =
    useMemo(() => tasks?.filter((task) => task.object_key === tableKey), [tasks, tableKey]) || [];

  const getValidationRulesCount = useMemo(() => {
    if (!object) {
      return 0;
    }

    return object.fields.reduce((acc, field) => {
      const count = field?.validation?.length || 0;
      return acc + count;
    }, 0);
  }, [object]);

  const getConditionalRulesCount = useMemo(() => {
    if (!object) {
      return 0;
    }

    return object.fields.reduce((acc, field) => {
      const count = field?.rules?.length || 0;
      return acc + count;
    }, 0);
  }, [object]);

  const getConnectionsCount = useMemo(() => {
    if (!object) {
      return 0;
    }

    return object.connections.inbound.length + object.connections.outbound.length;
  }, [object]);

  const getIconBadgeNumber = (sidebarItemType: ActiveSidebarItemType) => {
    if (sidebarItemType === 'tasks') {
      return tableTasks.length;
    }

    if (sidebarItemType === 'connections' && object) {
      return getConnectionsCount;
    }

    if (sidebarItemType === 'validationRules' && object) {
      return getValidationRulesCount;
    }

    if (sidebarItemType === 'conditionalRules' && object) {
      return getConditionalRulesCount;
    }

    return 0;
  };

  if (isApplicationLoading || !object) {
    return null; // TODO: FE-1944 - what do design and product want to display in this case, if anything.
  }

  const hasAccessToNonPublicFeatures =
    import.meta.env.DEV || !!application?.account.isInternalAccount;

  return (
    <RightSidebar>
      <SidePanel
        open={isToolkitSidebarOpen}
        modal={false}
        onOpenChange={setIsToolkitSidebarOpen}
        onCloseFinish={() => {
          setActiveToolkitSidebarItem(null);
        }}
      >
        {getSidebarItems(application?.account.productPlan.level).map(
          ({ id, icon, tooltipI18nKey, link, isPublicFeature }) => {
            const isDisabled = !hasAccessToNonPublicFeatures && !isPublicFeature;

            return (
              <RightSidebar.Item
                key={id}
                iconBadgeNumber={getIconBadgeNumber(id as ActiveSidebarItemType)}
                dataTestId={`right-sidebar-item-${id}`}
                icon={icon}
                tooltipText={t(tooltipI18nKey)}
                onClick={() => setActiveToolkitSidebarItem(id as ActiveSidebarItemType)}
                link={link}
                isActive={activeToolkitSidebarItem === id}
                isDisabled={isDisabled}
              />
            );
          }
        )}
        <SidePanel.Content
          portalContainer={mainLayoutContainerRef}
          position="absolute"
          className="right-14 mt-px rounded-tl-lg bg-base p-4"
          data-testid="toolkit-panel-content"
          onInteractOutside={(e) => e.preventDefault()}
          onOpenAutoFocus={(e) => e.preventDefault()}
        >
          {activeToolkitSidebarItem === 'connections' && <ConnectionsPanel table={object} />}
          {activeToolkitSidebarItem === 'validationRules' && (
            <ValidationRulesPanel
              key={activeToolkitSidebarItemOriginField?.key}
              sourceObject={object}
              originFieldKey={activeToolkitSidebarItemOriginField?.key}
            />
          )}
          {activeToolkitSidebarItem === 'conditionalRules' && (
            <ConditionalRulesPanel
              key={activeToolkitSidebarItemOriginField?.key}
              sourceObject={object}
              originFieldKey={activeToolkitSidebarItemOriginField?.key}
            />
          )}
          {activeToolkitSidebarItem === 'tasks' && <TasksPanel table={object} tasks={tableTasks} />}
        </SidePanel.Content>
      </SidePanel>
    </RightSidebar>
  );
}
