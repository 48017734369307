import { useTranslation } from 'react-i18next';
import {
  Divider,
  Input,
  Label,
  Slider,
  Switch,
  Tabs,
  type AppWidth as AppWidthType,
  type NavigationLayout,
  type TitleSize
} from '@knack/asterisk-react';

import { useSettingsMutation } from '@/hooks/api/mutations/useSettingsMutation';
import { imageAllowedFileFormats } from '@/utils/constants';
import { FileDropzone } from '@/components/FileDropzone';
import { Section } from '@/pages/themes/theme-editor/Section';
import { useThemesPageContext } from '@/pages/themes/ThemesPageContext';

const logoMaxFileSizeInBytes = 5 * 1000 * 1000; // 10 MB

export function Layout() {
  const [t] = useTranslation();
  const { theme, setThemeField } = useThemesPageContext();
  const { updateLogoMutation } = useSettingsMutation();
  const navigationLayoutOptions = [
    {
      children: <span>{t('themes.vertical')}</span>,
      value: 'vertical'
    },
    {
      children: <span>{t('themes.horizontal')}</span>,
      value: 'horizontal'
    }
  ];
  const appWidthOptions = [
    {
      children: <span>{t('themes.max')}</span>,
      value: 'max'
    },
    {
      children: <span>{t('themes.full')}</span>,
      value: 'full'
    }
  ];
  const titleSizeOptions = [
    {
      children: <span>{t('themes.small')}</span>,
      value: 'small'
    },
    {
      children: <span>{t('themes.medium')}</span>,
      value: 'medium'
    },
    {
      children: <span>{t('themes.large')}</span>,
      value: 'large'
    }
  ];

  const handleLogoDrop = async (files: File[]) =>
    new Promise((resolve, reject) => {
      updateLogoMutation.mutate(
        { file: files[0] },
        {
          onSuccess: (data) => {
            setThemeField((draft) => {
              draft.mainNavContainer.logo.url = data.logo_url;
              draft.mainNavContainer.logo.name = data.logo;
              draft.mainNavContainer.logo.size = files[0].size;
            });
            setThemeField((draft) => {
              draft.mainNavContainer.logo.isVisible = true;
            });
            resolve(data);
          },
          onError: (error) => {
            reject(error);
          }
        }
      );
    });

  return (
    <>
      <Section sectionNameKey="primary_navigation">
        <Tabs
          defaultValue={theme.mainNavContainer.layout}
          onValueChange={(value) => {
            setThemeField((draft) => {
              draft.mainNavContainer.layout = value as NavigationLayout;
            });
          }}
        >
          <Tabs.List items={navigationLayoutOptions} shouldDisableResponsive />
        </Tabs>
        <div className="mt-4 flex items-center gap-2">
          <Switch
            id="navigation-visible"
            checked={theme.mainNavContainer.navigationMenu.isVisible}
            onCheckedChange={(state) =>
              setThemeField((draft) => {
                draft.mainNavContainer.navigationMenu.isVisible = state;
              })
            }
          />
          <Label htmlFor="navigation-visible">{t('themes.show_pages')}</Label>
        </div>
        <div className="flex items-center gap-2">
          <Switch
            id="is-sticky"
            checked={theme.mainNavContainer.isSticky}
            onCheckedChange={(state) =>
              setThemeField((draft) => {
                draft.mainNavContainer.isSticky = state;
              })
            }
          />
          <Label htmlFor="is-sticky">{t('themes.is_sticky')}</Label>
        </div>
        <div className="flex items-center gap-2">
          <Switch
            id="has-border"
            checked={theme.mainNavContainer.hasBorder}
            onCheckedChange={(state) =>
              setThemeField((draft) => {
                draft.mainNavContainer.hasBorder = state;
              })
            }
          />
          <Label htmlFor="has-border">{t('themes.add_border')}</Label>
        </div>
        <div className="flex items-center gap-2">
          <Switch
            id="add-shadow"
            checked={theme.mainNavContainer.hasShadow}
            onCheckedChange={(state) =>
              setThemeField((draft) => {
                draft.mainNavContainer.hasShadow = state;
              })
            }
          />
          <Label htmlFor="add-shadow">{t('themes.add_shadow')}</Label>
        </div>
      </Section>
      <Divider className="my-4" />
      <Section sectionNameKey="app_width">
        <Tabs
          defaultValue={theme.appWidth}
          onValueChange={(value) => {
            setThemeField((draft) => {
              draft.appWidth = value as AppWidthType;
            });
          }}
        >
          <Tabs.List items={appWidthOptions} shouldDisableResponsive />
        </Tabs>
        <p className="mt-4">{t('themes.page_width_description')}</p>
      </Section>
      <Divider className="my-4" />
      <Section sectionNameKey="app_title">
        <div className="flex items-center gap-2">
          <Switch
            id="show-app-title"
            checked={theme.mainNavContainer.appTitle.isVisible}
            onCheckedChange={(state) =>
              setThemeField((draft) => {
                draft.mainNavContainer.appTitle.isVisible = state;
              })
            }
          />
          <Label htmlFor="show-app-title">{t('themes.show_app_title')}</Label>
        </div>
        <div className="flex items-center gap-2">
          <Switch
            id="show-custom-title"
            checked={theme.mainNavContainer.appTitle.isCustom}
            onCheckedChange={(state) =>
              setThemeField((draft) => {
                draft.mainNavContainer.appTitle.isCustom = state;
              })
            }
          />
          <Label htmlFor="show-custom-title">{t('themes.show_custom_title')}</Label>
        </div>
        {theme.mainNavContainer.appTitle.isCustom && (
          <Input
            placeholder={t('themes.app_title')}
            autoFocus
            defaultValue={theme.mainNavContainer.appTitle.customText}
            className="my-2"
            onChange={(e) =>
              setThemeField((draft) => {
                draft.mainNavContainer.appTitle.customText = e.target.value;
              })
            }
          />
        )}
        <p className="my-2">{t('themes.title_size')}</p>
        <Tabs
          defaultValue={theme.mainNavContainer.appTitle.size}
          onValueChange={(value) => {
            setThemeField((draft) => {
              draft.mainNavContainer.appTitle.size = value as TitleSize;
            });
          }}
        >
          <Tabs.List items={titleSizeOptions} shouldDisableResponsive />
        </Tabs>
        <div className="mt-4 flex items-center gap-2">
          <Switch
            id="show-logo"
            checked={theme.mainNavContainer.logo.isVisible}
            onCheckedChange={(state) =>
              setThemeField((draft) => {
                draft.mainNavContainer.logo.isVisible = state;
              })
            }
          />
          <Label htmlFor="show-logo">{t('themes.show_logo')}</Label>
        </div>
        <FileDropzone
          onDrop={handleLogoDrop}
          className="mt-4"
          allowedFormats={imageAllowedFileFormats}
          maxFileSize={logoMaxFileSizeInBytes}
          filePreviewData={{
            src: theme.mainNavContainer.logo.url,
            name: theme.mainNavContainer.logo.name,
            size: theme.mainNavContainer.logo.size
          }}
          setFilePreviewData={(state) =>
            setThemeField((draft) => {
              draft.mainNavContainer.logo.url = state.src;
              draft.mainNavContainer.logo.name = state.name;
              draft.mainNavContainer.logo.size = state.size;
            })
          }
          errorMapping={(error) => {
            if (error.code === 'file-too-large') {
              return t('components.file_dropzone.file_too_large', {
                size: logoMaxFileSizeInBytes / 1000 / 1000
              });
            }
            if (error.code === 'file-invalid-type') {
              return t('components.file_dropzone.file_invalid');
            }

            return t('components.file_dropzone.file_upload_error');
          }}
          data-testid="upload-logo-file-dropzone"
        />
        <Label htmlFor="logo-size" className="my-4 block">
          {t('themes.logo_size')}
        </Label>
        <Slider
          id="logo-size"
          intent="brand"
          defaultValue={[theme.mainNavContainer.logo.imageWidthPercentage]}
          min={16}
          max={100}
          step={1}
          onValueChange={([value]) =>
            setThemeField((draft) => {
              draft.mainNavContainer.logo.imageWidthPercentage = value;
            })
          }
        />
      </Section>
    </>
  );
}
