import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Label, Select, Switch } from '@knack/asterisk-react';

import { removeDuplicatesFromStringArray } from '@/utils/arrays';

export function DefaultValue() {
  const [t] = useTranslation();
  const { setValue, getValues } = useFormContext();

  const formDefaultValue = getValues('format.default');
  const options: string[] = getValues('format.options') || [];
  const isBlankSelected = formDefaultValue === 'kn-blank';
  const isDefaultValueValid = options?.includes(formDefaultValue);
  const optionsWithoutDuplicates = removeDuplicatesFromStringArray(options);

  return (
    <>
      <div className="flex items-center">
        <Controller
          name="format.default"
          defaultValue="kn-blank"
          render={({ field: { onChange, value } }) => (
            <Switch
              id="default-value-switch"
              className="mr-2"
              data-testid="default-value-switch"
              checked={value !== 'kn-blank'}
              onCheckedChange={(checked) => {
                onChange(checked ? options[0] : 'kn-blank');
              }}
            />
          )}
        />
        <Label htmlFor="checkbox-selected-by-default-switch">
          {t('components.data_table.attributes.field_settings.multiple_choice.default_value_label')}
        </Label>
      </div>

      {!isBlankSelected && (
        <Select
          onValueChange={(value) => {
            setValue('format.default', value);
          }}
          value={isDefaultValueValid ? formDefaultValue : options[0]}
        >
          <Select.Trigger
            id="default-value-select"
            placeholder={t('actions.select')}
            className="w-full rounded-lg"
            data-testid="multiple-choice-form-settings-default-select"
          />
          <Select.Content>
            {optionsWithoutDuplicates.map((option) => (
              <Select.Item
                key={option}
                value={option}
                data-testid={`multiple-choice-field-settings-default-value-${option}`}
              >
                {option}
              </Select.Item>
            ))}
          </Select.Content>
        </Select>
      )}
    </>
  );
}
