import { type BaseKnackField } from '@/types/schema/KnackField';

export const EMAIL_TEXT_FORMATS = ['url', 'same', 'unique'] as const;

export interface EmailField extends BaseKnackField {
  type: 'email';
  format?: {
    text_format: (typeof EMAIL_TEXT_FORMATS)[number];
    label?: string;
  };
}
