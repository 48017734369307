import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { HiPlus as PlusIcon } from 'react-icons/hi2';
import { DndContext, DragOverlay, type DragEndEvent, type DragStartEvent } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Button, Divider } from '@knack/asterisk-react';

import { type FormView, type FormViewSubmitRule } from '@/types/schema/views/FormView';
import { useDndUtils } from '@/hooks/useDndUtils';
import { KNOWLEDGE_BASE_URLS } from '@/utils/knowledge-base';
import { VerticalListSortableItem } from '@/components/dnd/VerticalListSortableItem';
import { LearnMoreLink } from '@/components/LearnMoreLink';
import { usePageEditorContext } from '@/pages/pages/page-editor/PageEditorContext';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { FormSubmitRuleFormDialog } from '@/pages/pages/settings-panel/view-settings/form/submit-rules/FormSubmitRuleFormDialog';
import { useFormSubmitRulesHelpers } from '@/pages/pages/settings-panel/view-settings/form/submit-rules/useFormSubmitRulesHelpers';
import { FormSubmitRuleCard } from './FormSubmitRuleCard';

type NewlyInsertedSubmitRule = {
  key: string;
  insertAction: 'new' | 'duplicate';
};

export function FormSubmitRules() {
  const [t] = useTranslation();

  const { view, sourceObject } = useActiveViewContext<FormView>();
  const { updatePage } = usePageEditorContext();
  const { getDefaultFormSubmitRule } = useFormSubmitRulesHelpers();
  const { optimizedSensors, verticalListCollisionDetection } = useDndUtils();

  const [isAddNewRuleModalOpen, setIsAddNewRuleModalOpen] = useState(false);
  const [newlyInsertedRule, setNewlyInsertedRule] = useState<NewlyInsertedSubmitRule | null>(null);
  const [beingDraggedRuleKey, setBeingDraggedRuleKey] = useState<string | null>(null);

  const nonDefaultSubmitRules = view.rules.submits.filter((rule) => !rule.is_default);
  const defaultSubmitRule = view.rules.submits.find((rule) => rule.is_default);

  const handleDragStart = (event: DragStartEvent) => {
    setBeingDraggedRuleKey(event.active.id as string);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    if (!view.rules.submits) {
      return;
    }

    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = view.rules.submits.findIndex((rule) => rule.key === active.id) ?? -1;
      const newIndex = view.rules.submits.findIndex((rule) => rule.key === over.id) ?? -1;

      if (oldIndex === -1 || newIndex === -1) {
        return;
      }

      const reorderedRules = arrayMove(view.rules.submits, oldIndex, newIndex);

      updatePage({
        type: 'view',
        action: 'update',
        origin: 'builder',
        viewKey: view.key,
        viewSchema: {
          rules: {
            ...view.rules,
            submits: reorderedRules
          }
        }
      });

      setBeingDraggedRuleKey(null);
    }
  };

  const onRuleAdd = (newSubmitRule: FormViewSubmitRule) => {
    setNewlyInsertedRule({
      key: newSubmitRule.key,
      insertAction: 'new'
    });

    updatePage({
      type: 'view',
      action: 'update',
      origin: 'builder',
      viewKey: view.key,
      viewSchema: {
        rules: {
          ...view.rules,
          submits: view.rules.submits
            ? [
                ...nonDefaultSubmitRules,
                newSubmitRule,
                ...(defaultSubmitRule ? [defaultSubmitRule] : [])
              ]
            : [newSubmitRule]
        }
      }
    });
  };

  const onRuleSave = (updatedSubmitRule: FormViewSubmitRule) => {
    updatePage({
      type: 'view',
      action: 'update',
      origin: 'builder',
      viewKey: view.key,
      viewSchema: {
        rules: {
          ...view.rules,
          submits: view.rules.submits?.map((submitRule) =>
            submitRule.key === updatedSubmitRule.key ? updatedSubmitRule : submitRule
          )
        }
      }
    });
  };

  const onRuleDuplicate = (submitRuleToDuplicate: FormViewSubmitRule) => {
    const newSubmitRule = getDefaultFormSubmitRule(sourceObject, view.action);
    const duplicatedRule: FormViewSubmitRule = {
      ...submitRuleToDuplicate,
      is_default: false,
      key: newSubmitRule.key
    };

    setNewlyInsertedRule({
      key: duplicatedRule.key,
      insertAction: 'duplicate'
    });

    let updatedRules = view.rules.submits ? [...view.rules.submits] : [];

    // If the rule being duplicated is the default rule, we push the duplicated rule to the end of the array
    if (submitRuleToDuplicate.is_default) {
      updatedRules = [
        ...nonDefaultSubmitRules,
        duplicatedRule,
        ...(defaultSubmitRule ? [defaultSubmitRule] : [])
      ];
    } else {
      // Otherwise, we add the duplicated rule right after the original rule in the array
      updatedRules = updatedRules.reduce<FormViewSubmitRule[]>((acc, rule) => {
        acc.push(rule);
        if (rule.key === submitRuleToDuplicate.key) {
          acc.push(duplicatedRule);
        }
        return acc;
      }, []);
    }

    updatePage({
      type: 'view',
      action: 'update',
      origin: 'builder',
      viewKey: view.key,
      viewSchema: {
        rules: {
          ...view.rules,
          submits: updatedRules
        }
      }
    });
  };

  const onRuleDelete = (submitRuleKey: FormViewSubmitRule['key']) => {
    updatePage({
      type: 'view',
      action: 'update',
      origin: 'builder',
      viewKey: view.key,
      viewSchema: {
        rules: {
          ...view.rules,
          submits: view.rules.submits?.filter((submitRule) => submitRule.key !== submitRuleKey)
        }
      }
    });
  };

  return (
    <>
      <p className="mb-4 text-xs text-subtle">
        <Trans
          i18nKey="pages.element_settings.form.categories.form_submit_rules.inner_description"
          components={[<LearnMoreLink key="0" href={KNOWLEDGE_BASE_URLS.FORMS_SUBMIT_ACTIONS} />]}
        />
      </p>

      <Button
        intent="secondary"
        className="w-full"
        data-testid="add-action-button"
        onClick={() => setIsAddNewRuleModalOpen(true)}
      >
        <PlusIcon size={16} className="mr-2" />
        {t('pages.element_settings.form.rules.add_action')}
      </Button>

      <DndContext
        sensors={optimizedSensors}
        collisionDetection={verticalListCollisionDetection}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={view.rules.submits.map((rule) => rule.key)}
          strategy={verticalListSortingStrategy}
        >
          <div className="mt-4 space-y-2">
            {nonDefaultSubmitRules.map((submitRule, ruleIndex) => (
              <VerticalListSortableItem key={submitRule.key} id={submitRule.key}>
                <FormSubmitRuleCard
                  submitRule={submitRule}
                  ruleNumber={ruleIndex + 1}
                  shouldScrollIntoView={newlyInsertedRule?.key === submitRule.key}
                  onRuleSave={onRuleSave}
                  onRuleDuplicate={onRuleDuplicate}
                  onRuleDelete={onRuleDelete}
                />
              </VerticalListSortableItem>
            ))}
          </div>
        </SortableContext>

        <DragOverlay>
          {nonDefaultSubmitRules.map((submitRule, ruleIndex) => {
            if (submitRule.key !== beingDraggedRuleKey) {
              return null;
            }

            return (
              <FormSubmitRuleCard
                key={submitRule.key}
                submitRule={submitRule}
                ruleNumber={ruleIndex + 1}
                onRuleDuplicate={() => {}}
                onRuleDelete={() => {}}
              />
            );
          })}
        </DragOverlay>
      </DndContext>

      {defaultSubmitRule && (
        <>
          <Divider className="my-4" />
          <FormSubmitRuleCard
            submitRule={defaultSubmitRule}
            onRuleSave={onRuleSave}
            onRuleDuplicate={onRuleDuplicate}
          />
        </>
      )}

      {isAddNewRuleModalOpen && (
        <FormSubmitRuleFormDialog
          formIntent="add"
          submitRule={getDefaultFormSubmitRule(sourceObject, view.action)}
          onOpenChange={setIsAddNewRuleModalOpen}
          onRuleSave={(newSubmitRule) => {
            setIsAddNewRuleModalOpen(false);
            onRuleAdd(newSubmitRule);
          }}
        />
      )}
    </>
  );
}
