import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DateTimePicker, Form, Label, Select } from '@knack/asterisk-react';
import { DateTime } from 'luxon';

import {
  TASK_SCHEDULE_REPEAT_FREQUENCY_OPTIONS,
  type KnackTask
} from '@/types/schema/tasks/KnackTask';
import { FormErrorMessage } from '@/components/errors/FormErrorMessage';
import { isDate } from '@/components/knack-date-picker/transformers/JsDateToKnackDateTransformer';
import { DEFAULT_TASK_DATE_FORMAT } from '@/pages/tables/toolkit-sidebar/tasks/constants';

export function TaskScheduleFormSection() {
  const [t] = useTranslation();

  const {
    getValues,
    setValue,
    formState: { errors }
    // We should use the inferred type from the zod schema, not an existing type
  } = useFormContext<KnackTask & { schedule: { datetime: Date | undefined } }>();

  return (
    <Form.Section>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <Label htmlFor="task-frequency-select" className="font-medium">
            {t('components.data_table.right_sidebar.tasks.schedule.frequency_label')}
          </Label>
          <Controller
            name="schedule.repeat"
            defaultValue={getValues('schedule.repeat') || TASK_SCHEDULE_REPEAT_FREQUENCY_OPTIONS[0]}
            render={({ field: currentFrequency }) => (
              <Select
                onValueChange={currentFrequency.onChange}
                defaultValue={
                  getValues('schedule.repeat') || TASK_SCHEDULE_REPEAT_FREQUENCY_OPTIONS[0]
                }
              >
                <Select.Trigger
                  id="task-frequency-select"
                  placeholder={t('actions.select')}
                  className="w-full"
                  data-testid="task-frequency-select"
                  {...currentFrequency}
                />
                <Select.Content>
                  {TASK_SCHEDULE_REPEAT_FREQUENCY_OPTIONS.map((frequencyOption) => (
                    <Select.Item key={frequencyOption} value={frequencyOption}>
                      {t(
                        `components.data_table.right_sidebar.tasks.schedule.frequency_options.${frequencyOption}`
                      )}
                    </Select.Item>
                  ))}
                </Select.Content>
              </Select>
            )}
          />
        </div>
        <div className="flex flex-col gap-2">
          <Label htmlFor="task-schedule-input" className="font-medium">
            {t('components.data_table.right_sidebar.tasks.schedule.next_run_date_label')}
          </Label>
          <div className="flex w-full justify-between gap-2">
            <DateTimePicker
              mode="single"
              defaultValue={getValues('schedule.datetime')}
              intent={errors?.schedule?.datetime ? 'destructive' : 'default'}
              format={{
                date: DEFAULT_TASK_DATE_FORMAT,
                time: 'hh:mm a'
              }}
              onChange={(newValue) => {
                if (isDate(newValue)) {
                  setValue('schedule.datetime', newValue, { shouldDirty: true });
                }
              }}
              shouldDisableTimeInsideCalendar
              min={DateTime.now()
                .set({
                  // The user should be able to select today, even if it is in the past because of the selected time
                  hour: 0,
                  minute: 0,
                  second: 0
                })
                .toJSDate()}
            />
          </div>
          <FormErrorMessage errors={errors} name="schedule.datetime" />
        </div>
      </div>
    </Form.Section>
  );
}
