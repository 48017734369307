import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiEllipsisHorizontal as EllipsisHorizontalIcon } from 'react-icons/hi2';
import { Button } from '@knack/asterisk-react';

import { DiscardChangesModal } from '@/components/DiscardChangesModal';
import { KnackPageIcon } from '@/components/KnackPageIcon';
import { usePageEditorContext } from '@/pages/pages/page-editor/PageEditorContext';
import { PageDropdownMenu } from '@/pages/pages/page-tree/page-dropdown-menu/PageDropdownMenu';

export function PageEditorTopBar() {
  const [t] = useTranslation();

  const { page, isPageModified, isSavingPageChanges, savePageChanges, discardPageChanges } =
    usePageEditorContext();

  const [isConfirmDiscardChangesModalOpen, setIsConfirmDiscardChangesModalOpen] = useState(false);
  return (
    <>
      <div className="flex items-center justify-between border-b border-subtle bg-muted px-6 py-4">
        <div className="flex items-center">
          <KnackPageIcon page={page} size={20} className="mr-2" />
          <h2 className="mr-2 truncate text-xl font-medium text-emphasis">{page.name}</h2>
          <PageDropdownMenu page={page} dropdownContentAlign="start" shouldShowPageEditorUiOptions>
            <Button intent="minimalStandalone" size="xs" className="size-7 hover:bg-emphasis">
              <EllipsisHorizontalIcon size={20} />
            </Button>
          </PageDropdownMenu>
        </div>
        <div className="flex items-center">
          {/* //TODO: Uncomment when Product make a final decision - FE-4451  */}
          {/* <Button
            size="sm"
            intent="secondary"
            data-testid="preview-page-button"
            className="mr-2 min-w-16"
            onClick={() => {
              trackEvent('app_previewed');
              setIsPreviewMode(true);
            }}
          >
            {t('pages.page_editor.preview_page')}
          </Button> */}
          <Button
            size="sm"
            intent="minimal"
            className="mr-2 min-w-16"
            disabled={!isPageModified}
            onClick={() => setIsConfirmDiscardChangesModalOpen(true)}
          >
            {t('actions.discard_changes')}
          </Button>
          <Button
            size="sm"
            className="min-w-16"
            disabled={!isPageModified ? true : undefined}
            isLoading={isSavingPageChanges}
            onClick={savePageChanges}
          >
            {t('actions.save_changes')}
          </Button>
        </div>
      </div>

      <DiscardChangesModal
        isOpen={isConfirmDiscardChangesModalOpen}
        onOpenChange={setIsConfirmDiscardChangesModalOpen}
        onConfirm={discardPageChanges}
      />
    </>
  );
}
