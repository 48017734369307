import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Divider, Label, Select } from '@knack/asterisk-react';
import { z } from 'zod';

import {
  CONNECTION_DEFAULT_OPTIONS,
  CONNECTION_INPUT_OPTIONS,
  type ConnectionField
} from '@/types/schema/fields';
import { Relationships } from '@/components/field-settings/connection/Relationships';

type ConnectionFormSettingsProps = {
  field: ConnectionField;
  isNewField?: boolean;
  objectKey: string;
};

export const connectionSchema = z.object({
  format: z
    .object({
      input: z.enum(CONNECTION_INPUT_OPTIONS).default(CONNECTION_INPUT_OPTIONS[0]),
      conn_default: z.enum(CONNECTION_DEFAULT_OPTIONS).default(CONNECTION_DEFAULT_OPTIONS[0])
    })
    .default({
      input: CONNECTION_INPUT_OPTIONS[0],
      conn_default: CONNECTION_DEFAULT_OPTIONS[0]
    }),
  relationship: z.object({
    belongs_to: z.enum(['one', 'many']),
    has: z.enum(['one', 'many']),
    object: z.string()
  })
});

export function ConnectionFormSettings({
  field,
  isNewField = false,
  objectKey
}: ConnectionFormSettingsProps) {
  const [t] = useTranslation();
  const { setValue, getValues, control } = useFormContext<ConnectionField>();

  const formatInputValue = getValues('format.input');
  const relationshipHas = getValues('relationship.has');

  const isOutgoingConnectionOne = relationshipHas === 'one';
  const isOutgoingConnectionMany = relationshipHas === 'many';
  const isRadioButtonsSelected = formatInputValue === 'radio';
  const isDropdownSelected = formatInputValue === 'chosen';

  // FE-4111 - Set default value for format.input due to malformed schemas in prod
  useEffect(() => {
    if (!formatInputValue) {
      setValue('format.input', CONNECTION_INPUT_OPTIONS[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-col gap-4">
      <Divider className="mt-2" />
      <Relationships field={field} isNewField={isNewField} objectKey={objectKey} />
      <div className="flex flex-col gap-2">
        <Label htmlFor="input-select" className="font-medium">
          {t('components.data_table.attributes.field_settings.connection.input_label')}
        </Label>
        <Controller
          name="format.input"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Select onValueChange={onChange} value={value}>
              <Select.Trigger
                id="input-select"
                placeholder={t('actions.select')}
                className="w-full"
                data-testid="connection-form-settings-input-select"
              />
              <Select.Content>
                {CONNECTION_INPUT_OPTIONS.map((option) => {
                  if (
                    (isOutgoingConnectionMany && option === 'radio') ||
                    (isOutgoingConnectionOne && option === 'checkbox')
                  ) {
                    return null;
                  }

                  return (
                    <Select.Item
                      key={option}
                      value={option}
                      data-testid={`connection-field-settings-input-value-${option}`}
                    >
                      {t(
                        `components.data_table.attributes.field_settings.connection.input_options.${option}`
                      )}
                    </Select.Item>
                  );
                })}
              </Select.Content>
            </Select>
          )}
        />

        {isRadioButtonsSelected && (
          <p className="text-xs font-normal" data-testid="radio-buttons-info-warning">
            {t('components.data_table.attributes.field_settings.connection.radio_buttons_info')}
          </p>
        )}
        {isDropdownSelected && isOutgoingConnectionMany && (
          <p className="text-xs font-normal" data-testid="radio-buttons-info-warning">
            {t('components.data_table.attributes.field_settings.connection.dropdown_info')}
          </p>
        )}
      </div>
    </div>
  );
}
