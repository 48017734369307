import { useTranslation } from 'react-i18next';

import { type ListView } from '@/types/schema/views/ListView';
import { BuilderAccordion } from '@/components/BuilderAccordion';
import { InlineRadioCardGroup } from '@/components/InlineRadioCardGroup';
import { usePageEditorContext } from '@/pages/pages/page-editor/PageEditorContext';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { ViewDetailsColumnLayoutSettings } from '@/pages/pages/settings-panel/view-settings/common/details-column-layout/ViewDetailsColumnLayoutSettings';

export function ListViewColumnLayoutSettings() {
  const [t] = useTranslation();
  const { view } = useActiveViewContext<ListView>();
  const { updatePage } = usePageEditorContext();
  const selectedLayoutColumns = view.list_layout;
  const LIST_LAYOUT_OPTIONS: Array<{
    label: string;
    value: ListView['list_layout'];
  }> = [
    {
      label: t('keywords.full'),
      value: 'full'
    },
    {
      label: '1',
      value: 'one-column'
    },
    {
      label: '2',
      value: 'two-column'
    },
    {
      label: '3',
      value: 'three-column'
    }
  ];

  const handleLayoutColumnsChange = (value: string) => {
    const newColumnsLayout = value as ListView['list_layout'];
    updatePage({
      type: 'view',
      action: 'update',
      origin: 'builder',
      viewKey: view.key,
      viewSchema: {
        ...view,
        list_layout: newColumnsLayout
      }
    });
  };

  return (
    <>
      <BuilderAccordion>
        <BuilderAccordion.Item
          isDefaultOpen
          label={t('pages.element_settings.list.categories.styling.list_element_layout')}
        >
          <div className="mb-4">
            <span className="mb-2 block font-medium">
              {t('pages.section_settings.layout_settings.columns')}
            </span>
            <InlineRadioCardGroup
              options={LIST_LAYOUT_OPTIONS}
              value={selectedLayoutColumns}
              onValueChange={handleLayoutColumnsChange}
              itemClassName="size-10"
            />
          </div>
        </BuilderAccordion.Item>
      </BuilderAccordion>
      <ViewDetailsColumnLayoutSettings shouldDisplayInfo />
    </>
  );
}
