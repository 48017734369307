import { useTranslation } from 'react-i18next';
import { Divider, Label } from '@knack/asterisk-react';

import { type MapPinColor, type MapView } from '@/types/schema/views/MapView';
import { BuilderAccordion } from '@/components/BuilderAccordion';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { ViewDetailsColumnLayoutSettings } from '@/pages/pages/settings-panel/view-settings/common/details-column-layout/ViewDetailsColumnLayoutSettings';
import { DisplayRuleColorPicker } from '@/pages/pages/settings-panel/view-settings/common/display-rules/DisplayRuleColorPicker';
import { useUpdateView } from '@/pages/pages/settings-panel/view-settings/useUpdateView';
import { PinRules } from './pin-rules/PinRules';

export function MapStylingSettings() {
  const [t] = useTranslation();
  const { view, sourceObject } = useActiveViewContext<MapView>();
  const updateViewSchema = useUpdateView<MapView>();

  const updateViewColorSchema = (color: string) => {
    updateViewSchema({
      pin_color_default: color
    });
  };

  const updateViewPinRulesSchema = (pinColors: MapPinColor[]) => {
    updateViewSchema({
      pin_colors: pinColors
    });
  };

  return (
    <>
      <ViewDetailsColumnLayoutSettings />
      <Divider className="my-4" />
      <BuilderAccordion>
        <BuilderAccordion.Item
          isDefaultOpen
          label={t('pages.element_settings.map.categories.styling.map_pin_colors')}
        >
          <div className="mb-4">
            <Label
              className="mb-2 block font-medium"
              htmlFor={`${view.key}-view-map-default-pin-color`}
            >
              {t('pages.element_settings.map.styling_settings.default_pin_color')}
            </Label>

            <DisplayRuleColorPicker
              id={`${view.key}-view-map-default-pin-color`}
              value={view.pin_color_default}
              isHexColor
              onChange={updateViewColorSchema}
            />
          </div>

          <PinRules
            sourceObject={sourceObject}
            pinRules={view.pin_colors}
            pinColorDefault={view.pin_color_default}
            onPinRulesChange={updateViewPinRulesSchema}
          />
        </BuilderAccordion.Item>
      </BuilderAccordion>
    </>
  );
}
