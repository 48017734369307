import { createContext, useCallback, useContext, useMemo, useState } from 'react';

import { type KnackField } from '@/types/schema/KnackField';
import { FIELD_TYPES_INELIGIBLE_FOR_FIELD_RULES } from '@/pages/tables/toolkit-sidebar/rules/utils';
import { type ActiveSidebarItemType } from '@/pages/tables/toolkit-sidebar/ToolkitSidebar';

type TablesPageContextState = {
  isToolkitSidebarOpen: boolean;
  setIsToolkitSidebarOpen: (isSidebarOpen: boolean) => void;
  activeToolkitSidebarItem: ActiveSidebarItemType | null;
  setActiveToolkitSidebarItem: (
    activeSidebarItem: ActiveSidebarItemType | null,
    originField?: KnackField
  ) => void;
  activeToolkitSidebarItemOriginField: KnackField | null;
} | null;

type TablesPageContextProviderProps = {
  children: React.ReactNode;
};

const TablesPageContext = createContext<TablesPageContextState>(null);

export function TablesPageContextProvider({ children }: TablesPageContextProviderProps) {
  const [isToolkitSidebarOpen, setIsToolkitSidebarOpen] = useState(false);
  const [activeToolkitSidebarItem, setActiveToolkitSidebarItem] =
    useState<ActiveSidebarItemType | null>(null);
  const [activeToolkitSidebarItemOriginField, setActiveToolkitSidebarItemOriginField] =
    useState<KnackField | null>(null);

  const handleSelectToolkitSidebarItem = useCallback(
    (item: ActiveSidebarItemType | null, originField?: KnackField) => {
      // If there is no item, we reset all the state
      if (!item) {
        setIsToolkitSidebarOpen(false);
        setActiveToolkitSidebarItem(null);
        setActiveToolkitSidebarItemOriginField(null);
        return;
      }

      setIsToolkitSidebarOpen(true);
      setActiveToolkitSidebarItem(item);

      if (originField) {
        if (!FIELD_TYPES_INELIGIBLE_FOR_FIELD_RULES.includes(originField.type)) {
          // Only set the origin field if it's an eligible field type for validation and conditional rules
          setActiveToolkitSidebarItemOriginField(originField);
        } else {
          // Otherwise, reset the field
          setActiveToolkitSidebarItemOriginField(null);
        }
      }
    },
    []
  );

  const contextValue = useMemo(
    () => ({
      isToolkitSidebarOpen,
      setIsToolkitSidebarOpen,
      activeToolkitSidebarItem,
      setActiveToolkitSidebarItem: handleSelectToolkitSidebarItem,
      activeToolkitSidebarItemOriginField
    }),
    [
      isToolkitSidebarOpen,
      activeToolkitSidebarItem,
      handleSelectToolkitSidebarItem,
      activeToolkitSidebarItemOriginField
    ]
  );

  return <TablesPageContext.Provider value={contextValue}>{children}</TablesPageContext.Provider>;
}

export const useTablesPageContext = () => {
  const context = useContext(TablesPageContext);
  if (!context) {
    throw new Error('useTablesPageContext must be used within a TablesPageContext');
  }
  return context;
};
