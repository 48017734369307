import { type KnackCriteria, type KnackCriteriaWithValueType } from '@/types/schema/KnackCriteria';
import { type KnackObject } from '@/types/schema/KnackObject';
import { type Field } from '@/components/data-table/display/fields/Field';
import {
  type AddressField,
  type AutoIncrementField,
  type AverageField,
  type BooleanField,
  type ConcatenationField,
  type ConnectionField,
  type ConnectionOption,
  type CountField,
  type CurrencyField,
  type DateTimeField,
  type EmailField,
  type EquationField,
  type FileField,
  type ImageField,
  type LinkField,
  type MaxField,
  type MinField,
  type MultipleChoiceField,
  type NameField,
  type NumberField,
  type ParagraphTextField,
  type PasswordField,
  type PhoneField,
  type RatingField,
  type RichTextField,
  type ShortTextField,
  type SignatureField,
  type SumField,
  type TimerField,
  type UserRolesField
} from './fields';

export type KnackFieldKey = `field_${string}`;

export interface BaseKnackField {
  _id: string;
  key: KnackFieldKey;
  name: string;
  required: boolean;
  unique: boolean;
  user: boolean;
  conditional: boolean;
  rules?: ConditionalRule[] | null;
  validation: ValidationRule[] | null;
  default?: string | boolean | number | null;
  immutable?: boolean;
  fromSource?: string;
  integration?: string;
  read_only?: boolean;
  meta?: {
    description?: string;
  };
  format?: unknown;
  relationship?: KnackFieldRelationship;
  ecommerce?: boolean;
}

export interface KnackFieldRelationship {
  belongs_to: ConnectionOption;
  has: ConnectionOption;
  object?: KnackObject['key'];
}

export interface ConditionalRule {
  key: string;
  criteria: KnackCriteriaWithValueType[];
  values: ConditionalRuleValues[];

  // `action` and `connection` can come in the schema, but they are not actually used by conditional rules, and shouldn't be added to new rules
  action?: string;
  connection?: string;
}

export interface ConditionalRuleValues {
  field: KnackFieldKey;
  type: 'value' | 'record' | 'current_location' | 'current_date';
  action?: 'append' | 'remove'; // This is only used if the field is `multiple_choice` or `connection`, and has multi-select capabilities
  input?: KnackFieldKey;
  value: Field['rawValue'];
}

export type ValidationRule = {
  key: string;
  message: string;
  criteria: KnackCriteria[];

  // Comes in the schema, but it's not used in the frontend
  value?: string;
};

export const KNACK_FIELD_TYPES = [
  'short_text',
  'paragraph_text',
  'rich_text',
  'concatenation',
  'number',
  'currency',
  'equation',
  'auto_increment',
  'sum',
  'min',
  'max',
  'average',
  'count',
  'multiple_choice',
  'boolean',
  'date_time',
  'timer',
  'file',
  'image',
  'name',
  'email',
  'address',
  'phone',
  'link',
  'signature',
  'rating',
  'connection',
  'user_roles',
  'password'
] as const;

export type KnackFieldType = (typeof KNACK_FIELD_TYPES)[number];

export type KnackField =
  | ShortTextField
  | ParagraphTextField
  | RichTextField
  | ConcatenationField
  | NumberField
  | CurrencyField
  | EquationField
  | AutoIncrementField
  | MultipleChoiceField
  | BooleanField
  | DateTimeField
  | TimerField
  | FileField
  | ImageField
  | NameField
  | EmailField
  | AddressField
  | PhoneField
  | LinkField
  | SignatureField
  | RatingField
  | ConnectionField
  | SumField
  | AverageField
  | MinField
  | MaxField
  | CountField
  | PasswordField
  | UserRolesField;
