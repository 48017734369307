import { useTranslation } from 'react-i18next';
import { HiChevronRight as ChevronRightIcon, HiDocument as PageIcon } from 'react-icons/hi2';
import { Link } from 'react-router-dom';
import { Button } from '@knack/asterisk-react';

import { type BuilderViewChildScenePayload } from '@/types/schema/BuilderView';
import { usePagesQuery } from '@/hooks/api/queries/usePagesQuery';

interface LinkInformationBadgeProps {
  scene?: string | BuilderViewChildScenePayload;
}

interface LinkInformationButtonProps {
  sceneName: string;
  isDisabled?: boolean;
}

function LinkInformationBadgeContainer({ children }: { children: React.ReactNode }) {
  const [t] = useTranslation();

  return (
    <div className="mb-4">
      <p className="font-medium">
        {t('pages.element_settings.common.categories.data_display.field_management.linked_to')}
      </p>
      {children}
    </div>
  );
}

function LinkInformationButton({ sceneName, isDisabled = false }: LinkInformationButtonProps) {
  return (
    <Button
      data-testid="link-information-badge"
      intent="link"
      className="mt-2 flex items-center gap-1 text-subtle"
      disabled={isDisabled}
    >
      <PageIcon size={16} />
      {sceneName}
      <ChevronRightIcon size={16} />
    </Button>
  );
}

export function LinkInformationBadge({ scene }: LinkInformationBadgeProps) {
  const [t] = useTranslation();
  const { data: pages } = usePagesQuery();

  // When you add a new action column, you need to save the changes to create the scene
  const isSceneNotCreated = scene && typeof scene !== 'string';

  if (isSceneNotCreated) {
    return (
      <LinkInformationBadgeContainer>
        <LinkInformationButton sceneName={scene.name} isDisabled />
        <p className="mt-2 text-xs text-subtle">
          {t(
            'pages.element_settings.common.categories.data_display.field_management.link_visibility'
          )}
        </p>
      </LinkInformationBadgeContainer>
    );
  }

  const linkedPage = pages?.find((page) => page.slug === scene);

  if (!linkedPage) {
    return null;
  }

  const linkHref = `/pages/${linkedPage.key}`;

  return (
    <LinkInformationBadgeContainer>
      <Link to={linkHref}>
        <LinkInformationButton sceneName={linkedPage.name} />
      </Link>
    </LinkInformationBadgeContainer>
  );
}
