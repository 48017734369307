import { useMutation, useQueryClient } from '@tanstack/react-query';
import isEmpty from 'lodash.isempty';

import { type KnackField } from '@/types/schema/KnackField';
import { mutationKeys } from '@/hooks/api/mutationKeys';
import { queryKeys } from '@/hooks/api/queryKeys';
import { axiosInstance as axios } from '@/utils/axiosConfig';

type UpdateFieldParams = {
  tableKey: string;
  updatedField: KnackField;
};

async function updateField({ tableKey, updatedField }: UpdateFieldParams) {
  const endpoint = `/v1/objects/${tableKey}/fields/${updatedField.key}`;

  const fieldData = {
    ...updatedField,
    conditional: !isEmpty(updatedField.rules)
  };

  const { data } = await axios.put(endpoint, fieldData, { withCredentials: true });

  return data;
}

export function useFieldMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [mutationKeys.updateField],
    mutationFn: updateField,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [queryKeys.application] });
    }
  });
}
