import { useTranslation } from 'react-i18next';

import { type BuilderViewSourceCriteriaRule } from '@/types/schema/BuilderView';
import { BuilderAccordion } from '@/components/BuilderAccordion';
import { SourceFiltersDialog } from '@/pages/pages/settings-panel/view-settings/common/source-filters/SourceFiltersDialog';
import { SourceFiltersGroupsCard } from '@/pages/pages/settings-panel/view-settings/common/source-filters/SourceFiltersGroupCard';
import { useActiveChartContext } from '@/pages/pages/settings-panel/view-settings/report/chart-settings/ActiveChartContext';
import { ChartSourceTableInfo } from '@/pages/pages/settings-panel/view-settings/report/chart-settings/ChartSourceTableInfo';
import { ReportDataDisplayCalculation } from '@/pages/pages/settings-panel/view-settings/report/data-display/ReportDataDisplayCalculation';
import { ReportDataDisplayCategory } from '@/pages/pages/settings-panel/view-settings/report/data-display/ReportDataDisplayCategory';

export function ReportDataDisplay() {
  const [t] = useTranslation();
  const { chart, chartSourceObject, updateChart } = useActiveChartContext();

  const handleSourceFiltersSubmit = (data: BuilderViewSourceCriteriaRule[][]) => {
    updateChart({
      source: {
        ...chart.source,
        criteria: {
          match: 'any',
          rules: [],
          groups: data || []
        }
      }
    });
  };

  return (
    <>
      <ChartSourceTableInfo />
      <BuilderAccordion>
        <BuilderAccordion.Item
          isDefaultOpen
          label={`${t('pages.element_settings.report.categories.data_display.source_filters')}`}
        >
          <div className="space-y-4">
            <SourceFiltersGroupsCard
              sourceObject={chartSourceObject}
              sourceFiltersCriteria={chart.source.criteria}
            />
            <SourceFiltersDialog
              sourceObject={chartSourceObject}
              sourceFiltersCriteria={chart.source.criteria}
              onFormSubmit={handleSourceFiltersSubmit}
            />
          </div>
        </BuilderAccordion.Item>
        <ReportDataDisplayCategory />
        <ReportDataDisplayCalculation />
      </BuilderAccordion>
    </>
  );
}
