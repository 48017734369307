import { useTranslation } from 'react-i18next';
import { Input, Label, Select, Tabs } from '@knack/asterisk-react';

import { type TableViewColumn } from '@/types/schema/views/TableView';

interface FieldFormattingProps {
  columnItem: TableViewColumn;
  updateViewColumn: (updatedData: Partial<TableViewColumn>) => void;
}

export function FieldFormattingSettings({ columnItem, updateViewColumn }: FieldFormattingProps) {
  const [t] = useTranslation();
  return (
    <>
      <Label className="font-medium">{t('keywords.width')}</Label>
      <Tabs
        key={columnItem.id}
        defaultValue={columnItem.width.type}
        className="mb-4 mt-2 w-40"
        onValueChange={(val) =>
          updateViewColumn({
            id: columnItem.id,
            width: { ...columnItem.width, type: val as TableViewColumn['width']['type'] }
          })
        }
      >
        <Tabs.List
          items={[
            { value: 'default', children: t('keywords.default') },
            { value: 'custom', children: t('keywords.custom') }
          ]}
        />
        <Tabs.Content value="custom" className="p-0 text-default">
          <div className="flex w-full items-center gap-2">
            <Input
              className="w-1/2"
              value={columnItem.width.amount}
              onChange={(e) =>
                updateViewColumn({
                  id: columnItem.id,
                  width: { ...columnItem.width, amount: e.target.value }
                })
              }
            />

            <Select
              value={columnItem.width.units}
              onValueChange={(val: TableViewColumn['width']['units']) =>
                updateViewColumn({
                  id: columnItem.id,
                  width: { ...columnItem.width, units: val }
                })
              }
            >
              <Select.Trigger className="w-1/2" />
              <Select.Content className="min-w-[100px]">
                <Select.Item value="px">px</Select.Item>
                <Select.Item value="pt">%</Select.Item>
              </Select.Content>
            </Select>
          </div>
        </Tabs.Content>
      </Tabs>
      <Label className="font-medium">
        {t('pages.element_settings.common.categories.data_display.field_management.alignment')}
      </Label>
      <Tabs
        defaultValue={columnItem.align}
        className="mt-2 w-[200px]"
        onValueChange={(val) =>
          updateViewColumn({ id: columnItem.id, align: val as TableViewColumn['align'] })
        }
      >
        <Tabs.List
          items={[
            { value: 'left', children: t('keywords.left') },
            { value: 'center', children: t('keywords.center') },
            { value: 'right', children: t('keywords.right') }
          ]}
        />
      </Tabs>
    </>
  );
}
