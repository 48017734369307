import { useTranslation } from 'react-i18next';
import { Button, Dialog } from '@knack/asterisk-react';

interface DiscardChangesModalProps {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  onConfirm: () => void;
  title?: string;
  description?: string;
  cancelText?: string;
  confirmText?: string;
}

export function DiscardChangesModal({
  isOpen,
  onOpenChange,
  onConfirm,
  title,
  description,
  cancelText,
  confirmText
}: DiscardChangesModalProps) {
  const [t] = useTranslation();

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <Dialog.Content data-testid="discard-changes-modal" aria-describedby={undefined}>
        <Dialog.MainContent>
          <Dialog.Header>
            <Dialog.Title>{title || t('components.dialogs.discard_changes.title')}</Dialog.Title>
          </Dialog.Header>
          <div className="mt-6">
            <p>{description || t('components.dialogs.discard_changes.description')}</p>
          </div>
        </Dialog.MainContent>
        <Dialog.Footer>
          <Dialog.Close asChild>
            <Button intent="minimal" data-testid="discard-changes-modal-cancel-button">
              {cancelText || t('components.dialogs.discard_changes.cancel')}
            </Button>
          </Dialog.Close>
          <Dialog.Close asChild>
            <Button
              intent="destructive"
              onClick={onConfirm}
              data-testid="discard-changes-modal-confirm-button"
            >
              {confirmText || t('components.dialogs.discard_changes.confirm')}
            </Button>
          </Dialog.Close>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  );
}
