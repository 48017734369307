import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { HiCalculator as CalculatorIcon } from 'react-icons/hi';
import { EquationHelper } from '@knack/equation-helper';

import { type EquationField } from '@/types/schema/fields';

interface EquationInputProps {
  targetField: EquationField;
  name: string;
}

export function EquationInput({ targetField, name }: EquationInputProps) {
  const { setValue: setFormValue, control } = useFormContext();

  const formValues = useWatch({ control });

  const equationValue = EquationHelper.calculateEquation(
    targetField,
    formValues,
    targetField.format.equation_type
  );

  const isCalculatingCurrentTime = targetField.format.equation.includes('currentTime');

  useEffect(() => {
    // If we already have the current time value when loading the form, we do not recalculate it
    // This could cause an infinite calculation when running the time. Parity with old live-app
    if (isCalculatingCurrentTime && equationValue) {
      return;
    }

    setFormValue(name, equationValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equationValue]);

  return (
    <div className="flex items-center gap-2">
      <CalculatorIcon size={20} />
      <span data-testid={`equation-input-${name}`}>{equationValue}</span>
    </div>
  );
}
