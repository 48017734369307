import { type ViewWithDetails } from '@/types/schema/views/ViewWithDetails';
import { usePageEditorContext } from '@/pages/pages/page-editor/PageEditorContext';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';

type ViewColumn = ViewWithDetails;

export function useUpdateViewColumns<T extends ViewColumn>() {
  const { view } = useActiveViewContext<T>();
  const { updatePage } = usePageEditorContext();

  function updateViewColumnsSchema(viewSchema: Partial<T>) {
    if ((view.type === 'map' || view.type === 'calendar') && 'columns' in viewSchema) {
      updatePage({
        type: 'view',
        origin: 'builder',
        action: 'update',
        viewKey: view.key,
        viewSchema: {
          ...view,
          details: {
            ...view.details,
            columns: viewSchema.columns
          }
        } satisfies Partial<T>
      });
      return;
    }
    updatePage({
      type: 'view',
      origin: 'builder',
      action: 'update',
      viewKey: view.key,
      viewSchema: viewSchema satisfies Partial<T>
    });
  }

  return updateViewColumnsSchema;
}
