import { useState } from 'react';
import { Controller, type FieldPath } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  MdFormatBold as BoldIcon,
  MdFormatItalic as ItalicIcon,
  MdOutlineStrikethroughS as StrikeThroughIcon
} from 'react-icons/md';
import { Button, Tabs, type TabsListItem } from '@knack/asterisk-react';

import {
  type TableDisplayRuleActionType,
  type TableViewDisplayRule
} from '@/types/schema/views/TableView';
import { getNormalizedIconName } from '@/utils/font-awesome';
import { cn } from '@/utils/tailwind';
import { KnackFontAwesomeIcon } from '@/components/font-awesome/KnackFontAwesomeIcon';
import { SelectIconModal } from '@/components/SelectIconModal';
import { DisplayRuleColorPicker } from '@/pages/pages/settings-panel/view-settings/common/display-rules/DisplayRuleColorPicker';

interface TextStyleActionSelectProps {
  formName: FieldPath<TableViewDisplayRule>;
}

const DEFAULT_COLOR_PICKER_COLOR = '#000000';

interface TableDisplayRuleFormActionPickerProps {
  action: TableDisplayRuleActionType;
  formName: FieldPath<TableViewDisplayRule>;
}

function TextStyleActionSelect({ formName }: TextStyleActionSelectProps) {
  return (
    <div className="flex gap-2">
      <Controller
        name={`${formName}.bold`}
        render={({ field: { onChange, value: bold } }) => (
          <Button
            data-testid="text-style-bold-button"
            intent="secondary"
            className={cn({ 'bg-action': bold })}
            onClick={() => onChange(!bold)}
            size="sm"
          >
            <BoldIcon size={16} />
          </Button>
        )}
      />
      <Controller
        name={`${formName}.italic`}
        render={({ field: { onChange, value: italic } }) => (
          <Button
            data-testid="text-style-italic-button"
            intent="secondary"
            onClick={() => onChange(!italic)}
            size="sm"
            className={cn({ 'bg-action': italic })}
          >
            <ItalicIcon size={16} />
          </Button>
        )}
      />
      <Controller
        name={`${formName}.strikethrough`}
        render={({ field: { onChange, value: strikethrough } }) => (
          <Button
            data-testid="text-style-strikethrough-button"
            intent="secondary"
            onClick={() => onChange(!strikethrough)}
            size="sm"
            className={cn({ 'bg-action': strikethrough })}
          >
            <StrikeThroughIcon size={16} />
          </Button>
        )}
      />
    </div>
  );
}

export function TableDisplayRuleFormActionPicker({
  action,
  formName
}: TableDisplayRuleFormActionPickerProps) {
  const [t] = useTranslation();
  const [isSelectIconModalOpen, setIsSelectIconModalOpen] = useState(false);
  const defaultIconAlignment = 'left';
  const alignItems: TabsListItem[] = [
    {
      children: t('keywords.left'),
      value: 'left'
    },
    {
      children: t('keywords.right'),
      value: 'right'
    }
  ];

  if (action === 'text-style') {
    return <TextStyleActionSelect formName={formName} />;
  }

  if (action === 'text-color') {
    return (
      <Controller
        name={`${formName}.color`}
        render={({ field: { value, onChange } }) => {
          const defaultValue = value || DEFAULT_COLOR_PICKER_COLOR;

          if (!value) {
            onChange(DEFAULT_COLOR_PICKER_COLOR);
          }

          return <DisplayRuleColorPicker value={defaultValue} onChange={onChange} />;
        }}
      />
    );
  }

  if (action === 'bg-color') {
    return (
      <Controller
        name={`${formName}.value`}
        render={({ field: { value, onChange } }) => {
          const defaultValue = value || DEFAULT_COLOR_PICKER_COLOR;

          if (!value) {
            onChange(DEFAULT_COLOR_PICKER_COLOR);
          }

          return <DisplayRuleColorPicker value={defaultValue} onChange={onChange} />;
        }}
      />
    );
  }

  if (action === 'icon') {
    return (
      <Controller
        name={`${formName}`}
        render={({ field: { value, onChange } }) => (
          <>
            <div className="mb-2 flex items-center gap-2">
              {value.icon.icon ? (
                <KnackFontAwesomeIcon
                  iconName={getNormalizedIconName(value.icon?.icon)}
                  size="xl"
                  asButton
                  onClick={() => setIsSelectIconModalOpen(true)}
                />
              ) : (
                <Button
                  intent="secondary"
                  aria-label={t('components.icons.select_icon')}
                  onClick={() => setIsSelectIconModalOpen(true)}
                >
                  {t('components.icons.select_icon')}
                </Button>
              )}
            </div>
            <Tabs
              defaultValue={value.icon?.align || defaultIconAlignment}
              className="mb-2"
              onValueChange={(newAlignvalue) =>
                onChange({
                  ...value,
                  icon: {
                    ...value.icon,
                    align: newAlignvalue as 'left' | 'right'
                  }
                })
              }
            >
              <Tabs.List items={alignItems} shouldDisableResponsive />
            </Tabs>
            <DisplayRuleColorPicker
              value={value.color || DEFAULT_COLOR_PICKER_COLOR}
              onChange={(newColor) => {
                onChange({ ...value, color: newColor });
              }}
            />
            {isSelectIconModalOpen && (
              <SelectIconModal
                preSelectedIcon={value.icon.icon}
                onClose={() => setIsSelectIconModalOpen(false)}
                onSubmit={(newSelectedIconName) => {
                  onChange({
                    ...value,
                    icon: {
                      ...value.icon,
                      icon: newSelectedIconName
                    }
                  });
                  setIsSelectIconModalOpen(false);
                }}
              />
            )}
          </>
        )}
      />
    );
  }

  return null;
}
