import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge, Dialog, Label } from '@knack/asterisk-react';
import { t as i18nT } from 'i18next';

import { type BuilderView, type KnackViewType } from '@/types/schema/BuilderView';
import { type ReportView, type ReportViewChart } from '@/types/schema/views/ReportView';
import { useSession } from '@/hooks/useSession';
import { cn } from '@/utils/tailwind';
import { ReportViewIcon } from '@/components/ReportViewIcon';
import { TextTooltip } from '@/components/TextTooltip';
import { UpgradeButton } from '@/components/UpgradeButton';
import { ViewIcon } from '@/components/ViewIcon';
import { AddRecordViewFlowContextProvider } from '@/pages/pages/page-editor/add-view/add-record-view/AddRecordViewFlowContext';
import { getNewStaticViewSchema } from '@/pages/pages/page-editor/add-view/helpers/view-schemas/viewSchemas';
import { usePageEditorContext } from '@/pages/pages/page-editor/PageEditorContext';
import { usePageEditorMessagingContext } from '@/pages/pages/page-editor/PageEditorMessagingContext';
import { AddRecordViewFlow } from './add-record-view/AddRecordViewFlow';
import { AddEcommerceViewFlow } from './AddEcommerceViewFlow';
import { ECOMMERCE_VIEWS, RECORD_VIEWS, STATIC_VIEWS } from './helpers/constants';
import { type SelectableView } from './helpers/types';
import { useAllowedViewTypes } from './helpers/useAllowedViewTypes';
import { usePageDataSources, type PageDataSource } from './helpers/usePageDataSources';

// Since we allow selecting individual charts as the entry point for the Report view, we need to define a new type to represent the value when it starts with a chart type.
type SelectableViewItemValue = KnackViewType | `${ReportView['type']}:${ReportViewChart['type']}`;

enum AddViewSteps {
  'selectViewType',
  'viewSubFlow'
}

interface SelectableViewItemProps {
  selectableView: SelectableView;
  isAllowed: boolean;
  onInitialContinue: (value: SelectableViewItemValue) => void;
}

// Current views that are fully supported by the 'add view' flow.
// Expand list as more new view schemas are added in `/helpers/view-schema/viewSchemas.ts`.
const VIEWS_AVAILABLE_FOR_ADD_VIEW_FLOW = [
  'form',
  'rich_text',
  'menu',
  'report',
  'table',
  'list',
  'details',
  'map',
  'calendar'
];

function getViewDisallowedReason(viewType: KnackViewType, isStarterPlan: boolean) {
  if (viewType === 'map' && isStarterPlan) {
    return i18nT(`views.add_view_dialog.disallowed_view_reasons.map_upgrade`);
  }

  if (viewType === 'details' || viewType === 'map' || viewType === 'calendar') {
    return i18nT(`views.add_view_dialog.disallowed_view_reasons.${viewType}`);
  }

  return '';
}

function SelectableViewItem({
  selectableView,
  isAllowed,
  onInitialContinue
}: SelectableViewItemProps) {
  const sessionData = useSession();

  const isStarterPlan = sessionData.account.productPlan.id.startsWith('starter');
  const isMapView = selectableView.type === 'map';
  const isViewAvailableForAddViewFlow = VIEWS_AVAILABLE_FOR_ADD_VIEW_FLOW.includes(
    selectableView.type
  );
  const canViewBeAdded = isViewAvailableForAddViewFlow && isAllowed;
  const disallowedReason = !isAllowed
    ? getViewDisallowedReason(selectableView.type, isStarterPlan)
    : '';
  const viewIconClasses =
    'mx-auto mb-0.5 size-12 text-subtle group-hover:text-brand-400 group-data-[state=checked]:text-brand-400';

  const itemValue: SelectableViewItemValue = selectableView.subType
    ? `report:${selectableView.subType}`
    : selectableView.type;

  return (
    <div className="relative">
      <TextTooltip label={disallowedReason} asChild>
        {/* FE-2582 - Explore the possibility of using RadioCardGroup from Asterisk once it's flexible enough to accommodate for this use case */}
        {isMapView && isStarterPlan ? (
          <div
            key={itemValue}
            className={cn(
              'flex min-h-28 w-32 items-center justify-center rounded-lg border border-default bg-default p-2 font-medium',
              {
                'group hover:border-brand hover:bg-brand-50 hover:text-emphasis data-[state=checked]:border-brand data-[state=checked]:bg-brand-50':
                  canViewBeAdded,
                'cursor-not-allowed opacity-30': !canViewBeAdded && !isMapView
              }
            )}
          >
            <div className="flex flex-col items-center justify-center">
              <ViewIcon
                type={selectableView.type}
                className={cn(viewIconClasses, {
                  'opacity-30': isMapView && !canViewBeAdded
                })}
              />

              <div>{selectableView.label}</div>

              <UpgradeButton />
            </div>
          </div>
        ) : (
          <button
            disabled={!canViewBeAdded}
            key={itemValue}
            id={itemValue}
            type="button"
            onClick={() => {
              if (canViewBeAdded) {
                onInitialContinue(itemValue);
              }
            }}
            className={cn(
              'flex min-h-28 w-32 items-center justify-center rounded-lg border border-default bg-default p-2 font-medium',
              {
                'group hover:border-brand hover:bg-brand-50 hover:text-emphasis data-[state=checked]:border-brand data-[state=checked]:bg-brand-50':
                  canViewBeAdded,
                'cursor-not-allowed opacity-30': !canViewBeAdded
              }
            )}
          >
            <div className="pointer-events-none cursor-pointer">
              {selectableView.type === 'report' && selectableView.subType ? (
                <ReportViewIcon type={selectableView.subType} className={viewIconClasses} />
              ) : (
                <ViewIcon type={selectableView.type} className={viewIconClasses} />
              )}
              <Label htmlFor={selectableView.type}>{selectableView.label}</Label>
            </div>
          </button>
        )}
      </TextTooltip>

      {!isViewAvailableForAddViewFlow && (
        <Badge
          intent="neutral"
          className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 whitespace-nowrap"
        >
          Contact Support
        </Badge>
      )}
    </div>
  );
}

function AddViewModalContent({ pageDataSources }: { pageDataSources: PageDataSource[] }) {
  const [t] = useTranslation();
  const { messageFromLiveApp } = usePageEditorMessagingContext();
  const { page, setIsAddViewModalOpen, updatePage } = usePageEditorContext();
  const { getPageAllowedViewTypes } = useAllowedViewTypes();

  const [currentStep, setCurrentStep] = useState<AddViewSteps>(AddViewSteps.selectViewType);
  const [selectedViewType, setSelectedViewType] = useState<KnackViewType | undefined>(undefined);
  const [selectedReportViewChart, setSelectedReportViewChart] = useState<
    ReportViewChart['type'] | undefined
  >(undefined);
  const [selectedViewCategory, setSelectedViewCategory] = useState<
    SelectableView['category'] | undefined
  >(undefined);

  const pageAllowedViewTypes = useMemo(
    () => getPageAllowedViewTypes(page),
    [getPageAllowedViewTypes, page]
  );

  if (!messageFromLiveApp || messageFromLiveApp.action !== 'start-add-view') {
    return null;
  }

  const { columnId, sectionId, originViewKey, position } = messageFromLiveApp;

  const handleAddView = (viewSchema: Partial<BuilderView>, newViewType?: KnackViewType) => {
    const viewType = newViewType ?? selectedViewType;
    if (!viewType) return;

    updatePage({
      type: 'view',
      origin: 'builder',
      action: 'add',
      columnId,
      sectionId,
      viewType,
      viewSchema,
      originViewKey,
      position
    });

    setIsAddViewModalOpen(false);
  };

  const onInitialContinue = (value: SelectableViewItemValue) => {
    const [viewType, chartType] = value.split(':') as [
      KnackViewType,
      ReportViewChart['type'] | undefined
    ];
    setSelectedViewType(viewType);
    if (chartType) {
      setSelectedReportViewChart(chartType);
    }

    let viewCategory;
    if (RECORD_VIEWS.some((view) => view.type === viewType)) {
      viewCategory = 'record';
    } else if (STATIC_VIEWS.some((view) => view.type === viewType)) {
      viewCategory = 'static';
    } else if (ECOMMERCE_VIEWS.some((view) => view.type === viewType)) {
      viewCategory = 'ecommerce';
    }

    setSelectedViewCategory(viewCategory);

    // Static views do not need any extra configuration so they can be added right away after being selected
    if (viewCategory === 'static' && viewType) {
      handleAddView(getNewStaticViewSchema(viewType), viewType);
      return;
    }

    setCurrentStep(AddViewSteps.viewSubFlow);
  };

  return (
    <Dialog open onOpenChange={setIsAddViewModalOpen}>
      <Dialog.Content
        data-testid="add-view-modal"
        width={currentStep === AddViewSteps.selectViewType ? 'lg' : undefined}
      >
        {currentStep === AddViewSteps.selectViewType && (
          <Dialog.MainContent className="flex max-h-[75vh] flex-col">
            <Dialog.Header>
              <Dialog.Title>{t('views.add_view_dialog.initial_step_title')}</Dialog.Title>
              <Dialog.Description>
                {t('views.add_view_dialog.initial_step_description')}
              </Dialog.Description>
            </Dialog.Header>
            <div className="flex overflow-auto">
              <div className="mt-6 overflow-y-auto sm:block">
                {currentStep === AddViewSteps.selectViewType && (
                  <div data-testid="add-view-modal-view-list">
                    <div className="mb-6">
                      <h2 className="mb-2 font-medium">
                        {t('views.add_view_dialog.record_views')}
                      </h2>
                      <div className="flex flex-wrap gap-4">
                        {RECORD_VIEWS.map((selectableView) => (
                          <SelectableViewItem
                            key={
                              selectableView.subType
                                ? `${selectableView.type}:${selectableView.subType}`
                                : selectableView.type
                            }
                            selectableView={selectableView}
                            isAllowed={pageAllowedViewTypes.includes(selectableView.type)}
                            onInitialContinue={onInitialContinue}
                          />
                        ))}
                      </div>
                    </div>

                    <div className="mb-6">
                      <h2 className="mb-2 font-medium">
                        {t('views.add_view_dialog.static_views')}
                      </h2>
                      <div className="flex flex-wrap gap-4">
                        {STATIC_VIEWS.map((selectableView) => (
                          <SelectableViewItem
                            key={selectableView.type}
                            selectableView={selectableView}
                            isAllowed={pageAllowedViewTypes.includes(selectableView.type)}
                            onInitialContinue={onInitialContinue}
                          />
                        ))}
                      </div>
                    </div>

                    <div>
                      <h2 className="mb-2 font-medium">{t('keywords.ecommerce')}</h2>
                      <div className="flex flex-wrap gap-4">
                        {ECOMMERCE_VIEWS.map((selectableView) => (
                          <SelectableViewItem
                            key={selectableView.type}
                            selectableView={selectableView}
                            isAllowed={pageAllowedViewTypes.includes(selectableView.type)}
                            onInitialContinue={onInitialContinue}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Dialog.MainContent>
        )}

        {currentStep === AddViewSteps.viewSubFlow && selectedViewType && (
          <>
            {selectedViewCategory === 'record' && (
              <AddRecordViewFlowContextProvider
                page={page}
                pageDataSources={pageDataSources}
                selectedViewType={selectedViewType}
                selectedViewSubType={selectedReportViewChart}
                handleAddView={handleAddView}
                onBack={() => setCurrentStep(AddViewSteps.selectViewType)}
              >
                <AddRecordViewFlow />
              </AddRecordViewFlowContextProvider>
            )}
            {selectedViewCategory === 'ecommerce' && (
              <AddEcommerceViewFlow selectedViewType={selectedViewType} />
            )}
          </>
        )}
      </Dialog.Content>
    </Dialog>
  );
}

export function AddViewModal() {
  const { messageFromLiveApp } = usePageEditorMessagingContext();
  const { isAddViewModalOpen, page } = usePageEditorContext();
  const { getPageDataSources } = usePageDataSources();

  if (
    !isAddViewModalOpen ||
    !messageFromLiveApp ||
    messageFromLiveApp.action !== 'start-add-view'
  ) {
    return null;
  }

  const pageDataSources = getPageDataSources(page);

  // Conditionally render the modal content to force a remount every time it's opened in order to clear any previous state
  return <AddViewModalContent pageDataSources={pageDataSources} />;
}
