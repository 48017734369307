import { useFormContext } from 'react-hook-form';
import { Input } from '@knack/asterisk-react';

import { type AddressField } from '@/types/schema/fields';
import { cn } from '@/utils/tailwind';
import {
  COORDINATES_EDITABLE_VALUES,
  EDITABLE_VALUES_BY_FORMAT
} from '@/components/data-table/display/fields/address/constants';
import { formatAddressPlaceholder } from '@/components/data-table/display/fields/address/formatters/formatPlaceholder';

export function AddressInput({
  targetField,
  name,
  id
}: {
  targetField: AddressField;
  name: string;
  id: string;
}) {
  const format = targetField?.format;
  const addressInputType = format?.input || 'address';
  const addressFormat = format?.format || 'US';
  const { register } = useFormContext();

  return (
    <div
      data-testid="address-input"
      className={cn('flex gap-2', {
        'flex-col': addressInputType === 'address'
      })}
    >
      {addressInputType === 'address' &&
        EDITABLE_VALUES_BY_FORMAT[addressFormat].map((addressSectionKey) => (
          <Input
            id={id}
            key={addressSectionKey}
            className="text-sm"
            placeholder={formatAddressPlaceholder(addressSectionKey, addressFormat)}
            {...register(`${name}.${addressSectionKey}`)}
          />
        ))}

      {addressInputType === 'lat_long' &&
        COORDINATES_EDITABLE_VALUES.map((placeholder) => (
          <Input
            type="number"
            id={id}
            step="any"
            className="text-sm [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
            key={placeholder}
            placeholder={formatAddressPlaceholder(placeholder)}
            {...register(`${name}.${placeholder}`)}
          />
        ))}
    </div>
  );
}
