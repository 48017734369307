import { useTranslation } from 'react-i18next';
import { Input, Label, RichTextEditor } from '@knack/asterisk-react';

import { type CalendarView } from '@/types/schema/views/CalendarView';
import { BuilderAccordion } from '@/components/BuilderAccordion';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { useUpdateView } from '@/pages/pages/settings-panel/view-settings/useUpdateView';

interface EventTitleDescriptionSettingsProps {
  className?: string;
  isDefaultOpen?: boolean;
  contextKey?: 'form' | 'details';
}

export function EventTitleDescriptionSettings({
  className = '',
  isDefaultOpen = true,
  contextKey = 'form'
}: EventTitleDescriptionSettingsProps) {
  const [t] = useTranslation();

  const { view } = useActiveViewContext<CalendarView>();
  const updateViewSchema = useUpdateView<CalendarView>();

  return (
    <BuilderAccordion.Item
      isDefaultOpen={isDefaultOpen}
      label={t('pages.element_settings.general.title_and_description')}
      className={className}
    >
      <div className="mb-4">
        <Label className="mb-2 block font-medium" htmlFor={`${view.key}-view-title`}>
          {t('pages.element_settings.general.view_title')}
        </Label>
        <Input
          value={view[contextKey].title ?? ''}
          data-testid="view-title-input"
          onChange={(e) => {
            updateViewSchema({
              [contextKey]: {
                ...view[contextKey],
                title: e.target.value
              }
            });
          }}
        />
        <p className="mt-2 text-xs text-subtle">{t('pages.element_settings.general.title_hint')}</p>
      </div>
      <div className="mb-4">
        <span className="mb-2 block font-medium">
          {t('pages.element_settings.general.view_description')}
        </span>

        <RichTextEditor
          onUpdate={({ editor }) => {
            updateViewSchema({
              [contextKey]: {
                ...view[contextKey],
                description: editor.getText() ? editor.getHTML() : ''
              }
            });
          }}
          content={view[contextKey].description || ''}
        />
        <p className="mt-2 text-xs text-subtle">
          {t('pages.element_settings.general.description_hint')}
        </p>
      </div>
    </BuilderAccordion.Item>
  );
}
