import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  HiChevronDown as DownIcon,
  HiLink as LinkIcon,
  HiLockClosed as LockIcon,
  HiDocument as PageIcon
} from 'react-icons/hi2';
import { Button, DropdownMenu, Tooltip, useToast } from '@knack/asterisk-react';

import { type BuilderPage } from '@/types/schema/BuilderPage';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { usePagesQuery } from '@/hooks/api/queries/usePagesQuery';
import { copyToClipboard } from '@/utils/copy-to-clipboard';

export function getTopLevelPages(
  pages: BuilderPage[],
  appSingleLoginPage: BuilderPage | undefined
) {
  return pages.reduce<{
    userTopLevelPages: BuilderPage[];
    otherTopLevelPages: BuilderPage[];
  }>(
    (acc, page) => {
      if (appSingleLoginPage) {
        if (page.type === 'user') {
          acc.userTopLevelPages.push(page);
        } else if (
          page.key !== appSingleLoginPage.key &&
          page.type !== 'authentication' &&
          page.type !== 'menu' &&
          page.parentSlug === appSingleLoginPage.slug
        ) {
          acc.otherTopLevelPages.push(page);
        }
      } else if (page.type === 'authentication') {
        const childPage = pages.find((p) => p.parentSlug === page.slug);
        if (childPage) {
          if (childPage.type === 'user') {
            acc.userTopLevelPages.push(childPage);
          } else {
            acc.otherTopLevelPages.push(childPage);
          }
        }
      } else if (!page.parentSlug && page.type !== 'menu') {
        if (page.type === 'user') {
          acc.userTopLevelPages.push(page);
        } else {
          acc.otherTopLevelPages.push(page);
        }
      }

      return acc;
    },
    { userTopLevelPages: [], otherTopLevelPages: [] }
  );
}

export function GoToLiveAppMenu() {
  const { presentToast } = useToast();
  const { data: application } = useApplicationQuery();
  const hasSingleLoginPage = application?.users.scope === 'application';
  const [t] = useTranslation();
  const { data: pages = [] } = usePagesQuery();
  const pagesWithoutParent = pages.filter((page) => !page.parentSlug && !page.menuPageKey);
  const appSingleLoginPage =
    hasSingleLoginPage && pagesWithoutParent.length > 0 ? pagesWithoutParent[0] : undefined;

  const { userTopLevelPages, otherTopLevelPages } = useMemo(
    () => getTopLevelPages(pages, appSingleLoginPage),
    [pages, appSingleLoginPage]
  );

  const getLiveAppTopLevelPageUrl = (page: BuilderPage) =>
    `${import.meta.env.PUBLIC_LIVE_APP_URL}/${application?.account.slug}/${application?.slug}/${page.slug}`;

  // This function only works in localhost or https domains due to browser security restrictions
  async function copyUrlToClipboard(url: string, pageName: string) {
    try {
      await copyToClipboard(url);
      presentToast({
        title: t('components.layout.header.copy_app_link_success', { pageName })
      });
    } catch (error) {
      presentToast({
        title: t('components.layout.header.copy_app_link_error')
      });
    }
  }

  return (
    <DropdownMenu>
      <DropdownMenu.Trigger asChild>
        <Button
          data-testid="go-to-live-app-menu-button"
          intent="secondary"
          size="sm"
          className="rounded-md rounded-l-none border-l-transparent outline-offset-0 hover:border-l-gray-400 focus:outline-offset-[-2px]"
          aria-label={t('components.layout.header.view_app_menu')}
        >
          <DownIcon size={16} />
        </Button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content align="end" className="z-20 max-w-60">
        {otherTopLevelPages?.length > 0 && (
          <>
            <DropdownMenu.Label
              className="font-normal text-subtle"
              data-testid="go-to-live-app-menu-label"
            >
              {t('components.layout.header.pages')}
            </DropdownMenu.Label>
            {otherTopLevelPages.map((page: BuilderPage) => (
              <DropdownMenu.Item
                key={page.id}
                className="group flex h-9 items-center justify-between"
                onSelect={(e) => e.preventDefault()}
              >
                <a
                  href={getLiveAppTopLevelPageUrl(page)}
                  target="_blank"
                  rel="noreferrer"
                  className="flex min-w-0 max-w-60 items-center"
                >
                  {page?.requiresAuthentication ? (
                    <LockIcon size={16} className="mr-2 shrink-0 text-subtle" />
                  ) : (
                    <PageIcon size={16} className="mr-2 shrink-0 text-subtle" />
                  )}
                  <span className="w-full truncate">{page.name}</span>
                </a>
                <Tooltip>
                  <Tooltip.Trigger asChild>
                    <Button
                      data-testid="go-to-live-app-menu-pages-copy-button"
                      className="hidden h-auto shrink-0 p-1 text-subtle hover:bg-emphasis group-hover:flex"
                      intent="minimal"
                      size="sm"
                      onClick={() => copyUrlToClipboard(getLiveAppTopLevelPageUrl(page), page.name)}
                    >
                      <LinkIcon size={16} />
                    </Button>
                  </Tooltip.Trigger>
                  <Tooltip.Content>{t('components.layout.header.copy_app_link')}</Tooltip.Content>
                </Tooltip>
              </DropdownMenu.Item>
            ))}
          </>
        )}

        {userTopLevelPages?.length > 0 && (
          <>
            <DropdownMenu.Separator />
            <DropdownMenu.Label
              className="font-normal text-subtle"
              data-testid="go-to-live-app-menu-user-pages-label"
            >
              {t('components.layout.header.user_pages')}
            </DropdownMenu.Label>
            {userTopLevelPages.map((page: BuilderPage) => (
              <DropdownMenu.Item
                key={page.id}
                className="group flex h-9 items-center justify-between"
                onSelect={(e) => e.preventDefault()}
              >
                <a
                  href={getLiveAppTopLevelPageUrl(page)}
                  target="_blank"
                  rel="noreferrer"
                  className="flex min-w-0 max-w-60 items-center"
                >
                  <LockIcon size={16} className="mr-2 shrink-0 text-subtle" />
                  <span className="w-full truncate">{page.name}</span>
                </a>
                <Tooltip>
                  <Tooltip.Trigger asChild>
                    <Button
                      className="hidden h-auto shrink-0 p-1 text-subtle hover:bg-emphasis group-hover:flex"
                      intent="minimal"
                      size="sm"
                      onClick={() => copyUrlToClipboard(getLiveAppTopLevelPageUrl(page), page.name)}
                    >
                      <LinkIcon size={16} />
                    </Button>
                  </Tooltip.Trigger>
                  <Tooltip.Content>{t('components.layout.header.copy_app_link')}</Tooltip.Content>
                </Tooltip>
              </DropdownMenu.Item>
            ))}
          </>
        )}
      </DropdownMenu.Content>
    </DropdownMenu>
  );
}
