import { useEffect, type ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Select } from '@knack/asterisk-react';

import { ZIP_CODE_MILES_RANGE_OPTIONS } from '@/types/schema/fields/AddressField';
import { type KnackCriteria } from '@/types/schema/KnackCriteria';

interface AddressCriteriaInputProps extends Omit<ComponentProps<typeof Input>, 'onChange'> {
  criteria: KnackCriteria;
  onChange: (criteria: KnackCriteria) => void;
  hasError?: boolean;
}

export function AddressCriteriaInput({
  criteria,
  onChange,
  hasError,
  ...rest
}: AddressCriteriaInputProps) {
  const [t] = useTranslation();

  const fallbackRangeValue = ZIP_CODE_MILES_RANGE_OPTIONS[0];

  useEffect(() => {
    if (criteria.operator !== 'near') {
      return;
    }

    // Ensure that the range has a valid default value
    const isValidValue = !!(
      criteria.range &&
      typeof criteria.range === 'string' &&
      (ZIP_CODE_MILES_RANGE_OPTIONS as Readonly<string[]>).includes(criteria.range)
    );

    if (!isValidValue) {
      onChange({
        ...criteria,
        range: fallbackRangeValue
      });
    }
  }, [criteria, fallbackRangeValue, onChange]);

  if (criteria.operator !== 'near') {
    return (
      <Input
        value={criteria.value as string}
        onChange={(e) => onChange({ ...criteria, value: e.target.value })}
        intent={hasError ? 'destructive' : undefined}
        {...rest}
      />
    );
  }

  return (
    <div className="flex w-full gap-2">
      <div className="flex-1">
        <Input
          placeholder={t('attributes.field_labels.address.zip_code_placeholder')}
          value={criteria.value as string}
          onChange={(e) => onChange({ ...criteria, value: e.target.value })}
          intent={hasError ? 'destructive' : undefined}
          {...rest}
        />
      </div>
      <div className="flex-1">
        <Select
          value={criteria.range !== undefined ? String(criteria.range) : fallbackRangeValue}
          onValueChange={(newRangeValue) => {
            onChange({
              ...criteria,
              range: newRangeValue
            });
          }}
        >
          <Select.Trigger className="size-full" />
          <Select.Content>
            {ZIP_CODE_MILES_RANGE_OPTIONS.map((milesRange) => (
              <Select.Item key={milesRange} value={milesRange}>
                {t('attributes.field_labels.address.miles_range', {
                  count: Number(milesRange)
                })}
              </Select.Item>
            ))}
          </Select.Content>
        </Select>
      </div>
    </div>
  );
}
