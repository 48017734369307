import { useCallback, useEffect } from 'react';

import { copyToClipboard } from '@/utils/copy-to-clipboard';
import { isMacOS } from '@/utils/user-agent';
import { useSelectionStrategy } from '@/components/data-table/display/useSelectionStrategy';
import { useDataTableStore } from '@/components/data-table/useDataTableStore';

export const useSelectionEvents = () => {
  const { moveSelectionVertical, moveSelectionHorizontal } = useSelectionStrategy();
  const dataTableStore = useDataTableStore();

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      const { selectedCell, actions, data } = dataTableStore.getState();
      const { setIsEditing } = actions;
      const modifierKey = isMacOS() ? e.metaKey : e.ctrlKey;

      if (selectedCell && !selectedCell?.isEditing) {
        if (modifierKey && e.key === 'c') {
          const selectedCellValues = data[selectedCell.rowId][selectedCell.fieldId] as {
            value: string;
          };
          void copyToClipboard(selectedCellValues.value.toString());
        } else if (e.key === 'Enter') {
          setIsEditing(true);
          e.preventDefault();
        } else if (e.key === 'ArrowUp') {
          moveSelectionVertical('up');
          e.preventDefault();
        } else if (e.key === 'ArrowDown') {
          moveSelectionVertical('down');
          e.preventDefault();
        } else if (e.key === 'ArrowLeft' || (e.key === 'Tab' && e.shiftKey)) {
          moveSelectionHorizontal('left');
          e.preventDefault();
        } else if (e.key === 'ArrowRight' || e.key === 'Tab') {
          moveSelectionHorizontal('right');
          e.preventDefault();
        }
      }
    },
    [dataTableStore, moveSelectionHorizontal, moveSelectionVertical]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);
};
