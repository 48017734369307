import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Label, RichTextEditor, Switch } from '@knack/asterisk-react';

export function DefaultValue({ defaultValue }: { defaultValue: string }) {
  const [t] = useTranslation();

  const [showEditDefaultValue, setShowEditDefaultValue] = useState(!!defaultValue);

  const { setValue } = useFormContext();

  return (
    <div className="flex size-full">
      <Switch
        className="mr-2"
        data-testid="rich-text-default-value-switch"
        checked={showEditDefaultValue}
        onCheckedChange={() => {
          if (showEditDefaultValue) {
            setValue('default', '');
          }
          setShowEditDefaultValue((prevState) => !prevState);
        }}
      />

      <div className="w-full space-y-2">
        <Label>
          {t('components.data_table.attributes.field_settings.common.add_default_value')}
        </Label>
        {showEditDefaultValue && (
          <RichTextEditor
            className="h-64 w-full"
            onUpdate={({ editor }) => {
              const content = editor.isEmpty ? '' : editor.getHTML();
              setValue('default', content, {
                shouldDirty: true,
                shouldTouch: true,
                shouldValidate: true
              });
            }}
            content={defaultValue}
          />
        )}
      </div>
    </div>
  );
}
