import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useFlagsmith } from 'flagsmith/react';
import Cookies from 'js-cookie';
import LogRocket from 'logrocket';

import { type Session } from '@/types/session';
import { queryKeys } from '@/hooks/api/queryKeys';
import { shouldSendAnalytics } from '@/utils/analytics';
import { axiosInstance as axios } from '@/utils/axiosConfig';
import { getAllEnabledFlags } from '@/utils/flagsmith';
import { isRudderStackEnabled, rudderStackAnalytics } from '@/utils/rudderstack';

export const sessionDefault: Session = {
  account: {
    id: '',
    name: '',
    slug: '',
    status: '',
    settings: {
      hipaa: {
        enabled: false,
        region: null
      }
    },
    productPlan: {
      id: ''
    }
  },
  user: {
    id: '',
    email: '',
    firstName: '',
    lastName: '',
    avatarUri: '',
    isTwoFactorAuthEnabled: false,
    isGoogleUser: false,
    hasSetPassword: false,
    appRights: []
  }
};

export async function getSession() {
  const endpoint = '/v1/dashboard/session/state';

  const { data } = await axios.get<{ session: Session }>(endpoint, {
    withCredentials: true
  });

  return data.session;
}

type PrefixedRecord<T, P extends string> = {
  [K in keyof T as `${P}_${string & K}`]: T[K];
};

function addPrefix<T extends object, P extends string>(obj: T, prefix: P): PrefixedRecord<T, P> {
  return Object.fromEntries(
    Object.entries(obj).map(([k, v]) => [`${prefix}_${k}`, v])
  ) as PrefixedRecord<T, P>;
}

export function dateCreatedFromMongoId(objectId: string) {
  return new Date(parseInt(objectId.substring(0, 8), 16) * 1000);
}

export function getReferral() {
  const params = new URLSearchParams(window.location.search);
  return (
    params.get('referral') || params.get('ref') || Cookies.get('referral') || document.referrer
  );
}

export function useSessionQuery() {
  const flagsmith = useFlagsmith();

  const query = useQuery({
    queryKey: [queryKeys.session],
    queryFn: getSession,
    // prevent fetching the data each time this hook is called
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,

    // refetch in the background every 8 hours
    staleTime: 1000 * 60 * 60 * 8,
    placeholderData: sessionDefault
  });

  useEffect(() => {
    const handleIdentifyAnalytics = async (session: Session) => {
      if (!shouldSendAnalytics(session.user.email)) {
        return;
      }

      await rudderStackAnalytics.identify(session.user.id, {
        referral: getReferral()
      });

      const flags = await getAllEnabledFlags();

      // Only call Segment to group the user if the user has an account id
      // Shared builder-only users don't have account data in the schema
      if (session.account.id !== '') {
        let groupTraits = {
          account_created: dateCreatedFromMongoId(session.account.id)
        };

        if (flagsmith.initialised) {
          groupTraits = {
            ...groupTraits,

            // Add flagsmith_ prefix to all flag names to avoid confusion
            ...addPrefix(flags, 'flagsmith')
          };
        }

        groupTraits = {
          ...groupTraits,
          ...addPrefix(session.user.marketing?.questionnaire ?? {}, 'questionnaire')
        };

        await rudderStackAnalytics.group(session.account.id, groupTraits);
      }
    };

    if (query.data && query.data.user.id !== '') {
      const name = `${query.data.user.firstName} ${query.data.user.lastName}`;

      void handleIdentifyAnalytics(query.data);

      if (import.meta.env.PUBLIC_LOGROCKET_APP_ID && !query.data.account.settings?.hipaa?.enabled) {
        LogRocket.identify(query.data.user.id, {
          name,
          email: query.data.user.email,
          account_id: query.data.account.id,
          plan_id: query.data.account.productPlan.id
        });
      }
    } else if (isRudderStackEnabled()) {
      rudderStackAnalytics.reset();
    }
  }, [flagsmith.initialised, query.data]);

  return query;
}
