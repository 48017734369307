import { type KnackObject } from '@/types/schema/KnackObject';
import { sanitizeEquationSchema } from '@/hooks/api/schema-sanitizer/fields/sanitizeEquationSchema';

export const sanitizeObjectSchema = (object: KnackObject) => {
  object.fields = object.fields.map((field) => {
    if (field.type === 'equation' || field.type === 'concatenation') {
      return sanitizeEquationSchema(field);
    }

    return field;
  });
  return object;
};
