import { useTranslation } from 'react-i18next';

import { type DetailsViewInput } from '@/types/schema/views/DetailsView';
import { BuilderAccordion } from '@/components/BuilderAccordion';
import { ViewColumnSettingsIconSection } from '@/pages/pages/settings-panel/view-settings/common/details-field-management/column-settings/ViewColumnSettingsIconSection';
import { ViewColumnSettingsLabelSection } from '@/pages/pages/settings-panel/view-settings/common/details-field-management/column-settings/ViewColumnSettingsLabelSection';
import { LinkInformationBadge } from '@/pages/pages/settings-panel/view-settings/table/data-display/field-management/column-settings/LinkInformationBadge';
import { ViewColumnDynamicActions } from '@/pages/pages/settings-panel/view-settings/table/data-display/field-management/ViewColumnDynamicActions';

interface ViewWithDetailsActionColumnSettingsProps {
  fieldInput: DetailsViewInput;
  updateListColumn: (fieldInput: Partial<DetailsViewInput>) => void;
}

export function ViewWithDetailsActionColumnSettings({
  fieldInput,
  updateListColumn
}: ViewWithDetailsActionColumnSettingsProps) {
  const [t] = useTranslation();

  const shouldRenderDynamicActions = fieldInput.type === 'action_link';
  const shouldRenderLinkInformationBadge = fieldInput.type === 'scene_link' && fieldInput.scene;

  return (
    <BuilderAccordion>
      {shouldRenderLinkInformationBadge && <LinkInformationBadge scene={fieldInput.scene} />}
      <BuilderAccordion.Item
        label={t('pages.element_settings.common.categories.actions.action_column_properties')}
        isDefaultOpen
      >
        {!shouldRenderDynamicActions && (
          <ViewColumnSettingsLabelSection
            fieldKey={fieldInput.key}
            linkText={fieldInput.link_text}
            linkType={fieldInput.link_type}
            onUpdate={(newOptions) => updateListColumn({ ...newOptions })}
          />
        )}
        <ViewColumnSettingsIconSection
          columnIcon={fieldInput.icon}
          onUpdateIcon={(icon) => updateListColumn({ icon })}
          onRemoveIcon={() => updateListColumn({ icon: null })}
        />
      </BuilderAccordion.Item>
      {shouldRenderDynamicActions && (
        <ViewColumnDynamicActions columnItem={fieldInput} updateViewColumn={updateListColumn} />
      )}
    </BuilderAccordion>
  );
}
