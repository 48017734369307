import DOMPurify from 'dompurify';

import { CurrencyEdit } from '@/components/data-table/display/fields/currency/CurrencyEdit';
import { type CurrencyField } from '@/components/data-table/display/fields/Field';
import { type FieldRenderProps } from '@/components/data-table/display/fields/FieldRender';

export function CurrencyRender(props: FieldRenderProps<CurrencyField>) {
  const { value, isFloating } = props;
  const sanitizedValue = DOMPurify.sanitize(value);

  if (isFloating) {
    return <CurrencyEdit {...props} />;
  }

  return (
    // We use dangerouslySetInnerHTML because the server returns the value as an HTML encoded string, so we have to properly render any special characters
    <div className="flex size-full p-2">
      <p
        className="size-full break-words text-right"
        dangerouslySetInnerHTML={{ __html: sanitizedValue }}
      />
    </div>
  );
}
