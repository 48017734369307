import { useEffect, type ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BiCollapseVertical as CollapseAllIcon,
  BiExpandVertical as ExpandAllIcon
} from 'react-icons/bi';
import { Button, Divider, InputSearch } from '@knack/asterisk-react';
import { useLocalStorage } from 'usehooks-ts';

import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { CollapsiblePanel } from '@/components/layout/CollapsiblePanel';
import { useLeftPanelContext } from '@/pages/pages/LeftPanelContext';
import { PageTree } from './page-tree/PageTree';

export const OPEN_COLLAPSIBLES_KEY = 'page-tree-open-menus';

export function LeftPanelContent() {
  const [t] = useTranslation();
  const { data: app } = useApplicationQuery();
  const { search, setSearch, collapsiblesState, setCollapsiblesState, nonUserPages } =
    useLeftPanelContext();
  const [, setLocalStorageOpenCollapsibles] = useLocalStorage<string[]>(
    `${app?.id}-${OPEN_COLLAPSIBLES_KEY}`,
    []
  );

  useEffect(() => {
    if (collapsiblesState) {
      if (collapsiblesState === 'expanded') {
        // Loop through the pages once to get the slugs of pages with children and store them in a Set, that way we don't have to make this loop on every iteration
        const parentSlugs = new Set(nonUserPages.map((child) => child.parentSlug));

        const keysOfPagesWithChildren = nonUserPages.reduce<string[]>((keys, page) => {
          if (parentSlugs.has(page.slug)) {
            keys.push(page.key);
          }
          return keys;
        }, []);

        setLocalStorageOpenCollapsibles(keysOfPagesWithChildren);
      } else {
        setLocalStorageOpenCollapsibles([]);
      }
      setCollapsiblesState(null);
    }
  }, [collapsiblesState, nonUserPages, setCollapsiblesState, setLocalStorageOpenCollapsibles]);

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearch(value);
  };

  return (
    <CollapsiblePanel.Content title={t('components.layout.left_sidebar.pages')} shouldHideDivider>
      <div className="sticky top-0 z-10 -mt-1 mb-1 bg-muted pb-2 pt-1">
        <InputSearch
          aria-label={t('keywords.search')}
          value={search}
          placeholder={t('keywords.search')}
          onChange={onSearch}
          data-testid="search-pages-input"
        />
      </div>
      <div className="my-4 flex gap-4">
        <Button
          intent="link"
          className="flex items-center"
          onClick={() => setCollapsiblesState('expanded')}
        >
          <ExpandAllIcon />
          <span className="ml-1">{t('pages.page_tree.expand_all')}</span>
        </Button>
        <Button
          intent="link"
          className="flex items-center"
          onClick={() => setCollapsiblesState('collapsed')}
        >
          <CollapseAllIcon />
          <span className="ml-1">{t('pages.page_tree.collapse_all')}</span>
        </Button>
      </div>
      <Divider />
      <PageTree />
    </CollapsiblePanel.Content>
  );
}
