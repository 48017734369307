import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Label, Tabs, type TabsListItem } from '@knack/asterisk-react';

import { type BuilderViewColumnIcon } from '@/types/schema/BuilderView';
import { getNormalizedIconName } from '@/utils/font-awesome';
import { KnackFontAwesomeIcon } from '@/components/font-awesome/KnackFontAwesomeIcon';
import { SelectIconModal } from '@/components/SelectIconModal';

interface ViewColumnSettingsIconSectionProps {
  columnIcon?: BuilderViewColumnIcon | null;
  onUpdateIcon: (icon: BuilderViewColumnIcon) => void;
  onRemoveIcon: () => void;
}

export function ViewColumnSettingsIconSection({
  columnIcon,
  onUpdateIcon,
  onRemoveIcon
}: ViewColumnSettingsIconSectionProps) {
  const [t] = useTranslation();

  const [isIconModalOpen, setIsIconModalOpen] = useState(false);

  const selectedIcon = columnIcon?.icon;
  const iconAligment = columnIcon?.align ?? 'left';

  const iconAlignItems: TabsListItem[] = [
    {
      children: t('keywords.left'),
      value: 'left'
    },
    {
      children: t('keywords.right'),
      value: 'right'
    }
  ];

  const handleSubmitNewIcon = (newSelectedIconName: string | undefined) => {
    if (newSelectedIconName) {
      onUpdateIcon({
        align: iconAligment,
        icon: newSelectedIconName
      });
    }
    setIsIconModalOpen(false);
  };

  return (
    <div className="mt-4">
      <Label className="mb-2 block">{t('components.icons.icon')}</Label>
      <div className="mt-2">
        {selectedIcon ? (
          <div className="flex items-center">
            <KnackFontAwesomeIcon
              iconName={getNormalizedIconName(selectedIcon)}
              size="xl"
              onClick={() => setIsIconModalOpen(true)}
              asButton
            />
            <Button
              data-testid="remove-icon-button"
              intent="secondary"
              className="ml-2"
              aria-label={t('components.icons.remove_icon')}
              onClick={() => {
                onRemoveIcon();
              }}
            >
              {t('components.icons.remove_icon')}
            </Button>
          </div>
        ) : (
          <Button
            intent="secondary"
            aria-label={t('components.icons.select_icon')}
            onClick={() => setIsIconModalOpen(true)}
          >
            {t('components.icons.select_icon')}
          </Button>
        )}
      </div>
      {selectedIcon && (
        <div className="mt-4">
          <Label className="mb-2 block">{t('components.icons.icon_position')}</Label>
          <Tabs
            defaultValue={iconAligment}
            onValueChange={(value) =>
              onUpdateIcon({
                align: value as BuilderViewColumnIcon['align'],
                icon: selectedIcon ?? ''
              })
            }
          >
            <Tabs.List items={iconAlignItems} shouldDisableResponsive />
          </Tabs>
        </div>
      )}
      {isIconModalOpen && (
        <SelectIconModal
          preSelectedIcon={selectedIcon}
          onClose={() => setIsIconModalOpen(false)}
          onSubmit={handleSubmitNewIcon}
        />
      )}
    </div>
  );
}
