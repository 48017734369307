import { Fragment, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import snakeCase from 'lodash.snakecase';

import { type BuilderPageRule } from '@/types/schema/BuilderPage';
import { type KnackCriteria } from '@/types/schema/KnackCriteria';
import { type KnackObject } from '@/types/schema/KnackObject';
import { InlineKnackCriteriaValue } from '@/components/InlineKnackCriteriaValue';
import { InlineKnackField } from '@/components/InlineKnackField';
import { InlineKnackValue } from '@/components/InlineKnackValue';
import { usePageEditorContext } from '@/pages/pages/page-editor/PageEditorContext';
import { SortableCardWrapper } from '@/pages/pages/settings-panel/view-settings/common/SortableCardWrapper';
import { usePageRuleHelpers, type PageRuleCriteriaFieldOption } from './usePageRuleHelpers';

interface PageRuleCardProps {
  pageRule: BuilderPageRule;
  pageRuleCriteriaFieldOptions: PageRuleCriteriaFieldOption[];
  pageSourceObject: KnackObject | undefined;
  ruleNumber?: number;
  shouldScrollIntoView?: boolean;
  isDragOverlay?: boolean;
  onRuleEdit: () => void;
  onRuleDuplicate?: (pageRuleToDuplicate: BuilderPageRule) => void;
  onRuleDelete?: (pageRuleKey: BuilderPageRule['key']) => void;
}

export function PageRuleCard({
  pageRule,
  pageRuleCriteriaFieldOptions,
  pageSourceObject,
  ruleNumber,
  shouldScrollIntoView,
  isDragOverlay = false,
  onRuleEdit,
  onRuleDuplicate,
  onRuleDelete
}: PageRuleCardProps) {
  const [t] = useTranslation();

  const { page } = usePageEditorContext();
  const { getPageRuleCriteriaFieldFromKey, getPageRuleLinkablePages } = usePageRuleHelpers();

  const submitRuleCardRef = useRef<HTMLDivElement | null>(null);

  function getSelectablePageLabelFromSlug(pageSlug: string) {
    const eligibleSelectablePages = getPageRuleLinkablePages(pageSourceObject);
    const selectedPage = eligibleSelectablePages.find((p) => p.slug === pageSlug);

    return selectedPage?.label ?? '-';
  }

  const getViewsToHideAsString = () => {
    if (!pageRule.view_keys || pageRule.view_keys.length === 0) {
      return '-';
    }

    const viewNamesToHide = pageRule.view_keys.map((viewKey) => {
      const view = page.views.find((v) => v.key === viewKey);
      return view?.name || viewKey;
    });

    return viewNamesToHide.join(', ');
  };

  useEffect(() => {
    if (shouldScrollIntoView && submitRuleCardRef.current) {
      submitRuleCardRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SortableCardWrapper
      ref={submitRuleCardRef}
      sortableItemId={pageRule.key}
      isDragOverlay={isDragOverlay}
      onEditClick={onRuleEdit}
      {...(onRuleDuplicate && { onDuplicateClick: () => onRuleDuplicate(pageRule) })}
      {...(onRuleDelete && { onDeleteClick: () => onRuleDelete(pageRule.key) })}
    >
      <h3 className="mb-2 text-xs font-medium text-emphasis">
        {t('components.page_rule_card.rule_number', {
          number: ruleNumber
        })}
      </h3>

      <span className="mb-2">{t('components.rules.when')}</span>

      {pageRule.criteria?.map((criteria, index) => {
        const criteriaField = getPageRuleCriteriaFieldFromKey(
          criteria.field,
          pageRuleCriteriaFieldOptions
        );
        const isLastCriteria = pageRule.criteria ? index === pageRule.criteria.length - 1 : true;

        return (
          // eslint-disable-next-line react/no-array-index-key
          <Fragment key={`${criteria.field}-${index}`}>
            {criteriaField ? (
              <div>
                <InlineKnackField
                  fieldType={criteriaField.type}
                  fieldName={criteriaField.name}
                  className="border-none bg-action"
                />{' '}
                {t(`operators.${snakeCase(criteria.operator)}`)}{' '}
                <InlineKnackCriteriaValue
                  criteria={criteria as KnackCriteria}
                  criteriaField={criteriaField}
                  className="bg-action"
                />
              </div>
            ) : (
              <InlineKnackValue value="-" className="border-none bg-action" />
            )}

            {!isLastCriteria && <div className="my-1">{t('components.rules.and')}</div>}
          </Fragment>
        );
      })}

      <div className="mt-2">
        {pageRule.action === 'message' && (
          <div className="flex flex-col items-start gap-1">
            <InlineKnackValue
              value={t('components.page_rule_card.actions.message')}
              className="border-none bg-action"
            />
            <InlineKnackValue asRichTextRenderer value={pageRule.message} />
          </div>
        )}

        {pageRule.action === 'hide_views' && (
          <div className="flex flex-col items-start gap-1">
            <InlineKnackValue
              value={t('components.page_rule_card.actions.hide_views')}
              className="border-none bg-action"
            />
            <InlineKnackValue value={getViewsToHideAsString()} />
          </div>
        )}

        {pageRule.action === 'existing_page' && pageRule.existing_page && (
          <div className="flex flex-col items-start gap-1">
            <InlineKnackValue
              value={t('components.page_rule_card.actions.existing_page')}
              className="border-none bg-action"
            />
            <InlineKnackValue
              value={getSelectablePageLabelFromSlug(pageRule.existing_page)}
              className={pageRule.existing_page ? 'underline' : undefined}
            />
          </div>
        )}

        {pageRule.action === 'parent_page' && (
          <div className="flex flex-col items-start gap-1">
            <InlineKnackValue
              value={t('components.page_rule_card.actions.parent_page')}
              className="border-none bg-action"
            />
          </div>
        )}

        {pageRule.action === 'url' && (
          <div className="flex flex-col items-start gap-1">
            <InlineKnackValue
              value={t('components.page_rule_card.actions.url')}
              className="border-none bg-action"
            />
            <InlineKnackValue
              value={pageRule.url ?? '-'}
              className={pageRule.url ? 'underline' : undefined}
            />
          </div>
        )}
      </div>
    </SortableCardWrapper>
  );
}
