import { useTranslation } from 'react-i18next';
import { HiChevronLeft as GoBackIcon } from 'react-icons/hi2';
import { Button, Dialog } from '@knack/asterisk-react';

import { ViewSourcePathList } from '@/pages/pages/page-editor/add-view/add-record-view/ViewSourcePathList';
import { AddRecordViewFlowSteps, useAddRecordViewFlowContext } from './AddRecordViewFlowContext';
import { FlowConfirmContent } from './FlowConfirmContent';
import { FlowContinueButton } from './FlowContinueButton';
import { FlowDescription } from './FlowDescription';
import { FlowTitle } from './FlowTitle';
import { ViewSourceList } from './ViewSourceList';

export function AddRecordViewFlow() {
  const [t] = useTranslation();
  const { currentStep, onBack, isAddingChartToReportView } = useAddRecordViewFlowContext();

  // We hide the back button on the `selectViewSource` step when adding a chart to an existing report view
  const shouldHideBackButton =
    currentStep === AddRecordViewFlowSteps.selectViewSource && isAddingChartToReportView;

  return (
    <>
      <Dialog.MainContent>
        <Dialog.Header>
          <FlowTitle />
          <FlowDescription />
        </Dialog.Header>
        <div className="mt-6">
          {currentStep === AddRecordViewFlowSteps.selectViewSource && <ViewSourceList />}
          {currentStep === AddRecordViewFlowSteps.selectViewSourcePath && <ViewSourcePathList />}
          {currentStep === AddRecordViewFlowSteps.confirm && <FlowConfirmContent />}
        </div>
      </Dialog.MainContent>
      <Dialog.Footer>
        {!shouldHideBackButton && (
          <Button
            className="mr-auto"
            intent="minimalStandalone"
            onClick={onBack}
            data-testid="add-view-modal-back-button"
          >
            <GoBackIcon size={20} className="mr-2" />
            {t('actions.back')}
          </Button>
        )}
        <FlowContinueButton />
      </Dialog.Footer>
    </>
  );
}
