import { t } from 'i18next';
import { nanoid } from 'nanoid';

import { type BuilderViewSourceSchema } from '@/types/schema/BuilderView';
import { type KnackFieldType } from '@/types/schema/KnackField';
import { type KnackObject } from '@/types/schema/KnackObject';
import {
  FORM_VIEW_COLUMN_ID_PREFIX,
  FORM_VIEW_GROUP_ID_PREFIX,
  type FormView,
  type FormViewFieldInput
} from '@/types/schema/views/FormView';
import { type ViewSource } from '@/pages/pages/page-editor/add-view/helpers/useViewSources';
import { generateNewViewKey } from './generateNewViewKey';

const NEW_FORM_INPUT_LIMIT = 15;

export const baseFormViewSchema: Partial<FormView> = {
  type: 'form',
  description: '',
  alert: 'none',
  submitButtonText: t('views.new_view_defaults.form.submit_button_text'),
  rules: {
    submits: [
      {
        key: 'submit_1',
        action: 'message',
        message: t('views.new_view_defaults.form.submit_rule_message'),
        reload_show: true,
        is_default: true
      }
    ],
    fields: [],
    records: [],
    emails: []
  }
};

export function generateFormViewInputs({
  viewSourceObject,
  viewSourceSchema
}: {
  viewSourceObject: KnackObject;
  viewSourceSchema: BuilderViewSourceSchema;
}) {
  const formInputs: FormViewFieldInput[] = [];
  const fieldTypesToIgnore: KnackFieldType[] = [
    'concatenation',
    'auto_increment',
    'sum',
    'min',
    'max',
    'average',
    'count'
  ];

  let addedInputsCount = 0;

  viewSourceObject.fields.forEach((field) => {
    if (addedInputsCount >= NEW_FORM_INPUT_LIMIT) {
      return;
    }

    const isSourceConnectionField = viewSourceSchema.connection_key === field.key;

    if (
      isSourceConnectionField ||
      field.conditional ||
      field.ecommerce ||
      fieldTypesToIgnore.includes(field.type)
    ) {
      return;
    }

    formInputs.push({
      id: field.key,
      type: field.type,
      label: field.name,
      instructions: '',
      field: {
        key: field.key
      },
      format: field.format
    } as FormViewFieldInput);

    addedInputsCount += 1;
  });

  return formInputs;
}

export function getFormViewSchemaFromPartialView(
  partialFormView: Partial<FormView>,
  sourceObject: KnackObject
) {
  const formViewSchema: Partial<FormView> = {
    ...baseFormViewSchema,
    ...partialFormView,
    groups: partialFormView.source
      ? [
          {
            id: `${FORM_VIEW_GROUP_ID_PREFIX}${nanoid(10)}`,
            columns: [
              {
                id: `${FORM_VIEW_COLUMN_ID_PREFIX}${nanoid(10)}`,
                inputs: generateFormViewInputs({
                  viewSourceObject: sourceObject,
                  viewSourceSchema: partialFormView.source
                })
              }
            ]
          }
        ]
      : []
  };

  return formViewSchema as FormView;
}

export function getFormViewSchema(
  viewSource: ViewSource,
  viewSourceSchema: BuilderViewSourceSchema
) {
  const viewTitle = t(
    viewSource.recordDisplayQuantity === 'many'
      ? 'views.new_view_defaults.form.title_add'
      : 'views.new_view_defaults.form.title_edit',
    {
      objectName: viewSource.object.inflections.singular
    }
  );

  const formViewSchema: Partial<FormView> = {
    ...baseFormViewSchema,
    key: generateNewViewKey(),
    action: viewSource.recordDisplayQuantity === 'one' ? 'update' : 'create',
    name: viewTitle,
    title: viewTitle,
    source: viewSourceSchema,
    groups: [
      {
        id: `${FORM_VIEW_GROUP_ID_PREFIX}${nanoid(10)}`,
        columns: [
          {
            id: `${FORM_VIEW_COLUMN_ID_PREFIX}${nanoid(10)}`,
            inputs: generateFormViewInputs({
              viewSourceObject: viewSource.object,
              viewSourceSchema
            })
          }
        ]
      }
    ]
  };

  return formViewSchema;
}
