import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from '@knack/asterisk-react';
import { nanoid } from 'nanoid';

import {
  LAYOUT_THREE_COLUMN_STRUCTURES,
  LAYOUT_TWO_COLUMN_STRUCTURES
} from '@/types/schema/BuilderPage';
import { VIEW_COLUMN_ID_PREFIX, VIEW_GROUP_ID_PREFIX } from '@/types/schema/BuilderView';
import {
  type DetailsViewLayoutColumns,
  type DetailsViewOuterColumn
} from '@/types/schema/views/DetailsView';
import { type ViewWithDetails } from '@/types/schema/views/ViewWithDetails';
import { cn } from '@/utils/tailwind';
import { BuilderAccordion } from '@/components/BuilderAccordion';
import { InlineRadioCardGroup } from '@/components/InlineRadioCardGroup';
import { usePageEditorContext } from '@/pages/pages/page-editor/PageEditorContext';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';

const DETAILS_LAYOUT_OPTIONS: Array<{
  label: string;
  value: DetailsViewLayoutColumns;
}> = [
  {
    label: '1',
    value: '1'
  },
  {
    label: '2',
    value: '2'
  },
  {
    label: '3',
    value: '3'
  }
];

function generateNewDetailsOuterColumn(width: number) {
  const column: DetailsViewOuterColumn = {
    id: `${VIEW_COLUMN_ID_PREFIX}${nanoid(10)}`,
    groups: [
      {
        id: `${VIEW_GROUP_ID_PREFIX}${nanoid(10)}`,
        columns: [[]]
      }
    ],
    width
  };

  return column;
}

export type DetailsLayoutTwoColumnStructure = (typeof LAYOUT_TWO_COLUMN_STRUCTURES)[number];
export type DetailsLayoutThreeColumnStructure = (typeof LAYOUT_THREE_COLUMN_STRUCTURES)[number];

interface LayoutStructureOptionProps {
  structure: DetailsLayoutTwoColumnStructure | DetailsLayoutThreeColumnStructure;
  className?: string;
}

function LayoutStructureOption({ structure, className }: LayoutStructureOptionProps) {
  const [firstCol, secondCol, thirdCol] = structure.split('/');

  const getStructureColumn = (width: string) => (
    <span
      style={{ width: `${width}%` }}
      className="min-w-0 rounded-md border border-subtle bg-card px-2 py-1 text-center"
    >
      {width}%
    </span>
  );

  return (
    <div className={cn('flex flex-1 gap-1', className)}>
      {getStructureColumn(firstCol)}
      {secondCol && getStructureColumn(secondCol)}
      {thirdCol && getStructureColumn(thirdCol)}
    </div>
  );
}

function findAllColumnWidths(columns: DetailsViewOuterColumn[]) {
  return columns
    .reduce<number[]>((acc, column) => {
      acc.push(column.width);
      return acc;
    }, [])
    .join('/') as DetailsLayoutTwoColumnStructure | DetailsLayoutThreeColumnStructure;
}

function updateAllColumnWidths(
  columns: DetailsViewOuterColumn[],
  widthString: DetailsLayoutTwoColumnStructure | DetailsLayoutThreeColumnStructure
): DetailsViewOuterColumn[] {
  const widths = widthString.split('/').map(Number);

  return columns.map((column, index) => ({
    ...column,
    width: widths[index]
  }));
}

export function ViewDetailsColumnLayoutSettings({
  shouldDisplayInfo
}: {
  shouldDisplayInfo?: boolean;
}) {
  const [t] = useTranslation();
  const { view } = useActiveViewContext<ViewWithDetails>();
  const { updatePage } = usePageEditorContext();

  const columns = useMemo(() => {
    if (view.type !== 'calendar' && view.type !== 'map') {
      return view.columns;
    }
    return view.details.columns;
  }, [view]);

  const [layoutStructure, setLayoutStructure] = useState<
    DetailsLayoutTwoColumnStructure | DetailsLayoutThreeColumnStructure
  >(findAllColumnWidths(columns));

  const selectedLayoutColumns = String(columns.length);

  const handleLayoutColumnsChange = (value: string) => {
    const newSelectedLayoutColumns = value as DetailsViewLayoutColumns;

    const updatedOuterColumns: DetailsViewOuterColumn[] = [];

    if (newSelectedLayoutColumns === '1') {
      columns.forEach((column, index) => {
        // Keep the first column, delete the rest, and move their inputs to the first column
        if (index === 0) {
          updatedOuterColumns.push(column);
        } else {
          updatedOuterColumns[0].groups[0].columns[0].push(...column.groups[0].columns[0]);
        }
      });
      updatedOuterColumns[0].width = 100;
    }

    if (newSelectedLayoutColumns === '2') {
      // If the group has only one column, keep the existing column and add a new one
      if (columns.length === 1) {
        updatedOuterColumns.push(columns[0], generateNewDetailsOuterColumn(50));
      }

      // If the form has three columns, delete the last column and move its inputs to the second column
      if (columns.length === 3) {
        columns.forEach((column, index) => {
          if (index <= 1) {
            updatedOuterColumns.push(column);
          } else {
            updatedOuterColumns[index - 1].groups[0].columns[0].push(
              ...column.groups[0].columns[0]
            );
          }
        });
      }
      updatedOuterColumns[0].width = 50;
      setLayoutStructure('50/50');
    }

    if (newSelectedLayoutColumns === '3') {
      // If the group has only one column, add two new columns
      if (columns.length === 1) {
        updatedOuterColumns.push(
          columns[0],
          generateNewDetailsOuterColumn(33),
          generateNewDetailsOuterColumn(33)
        );
      }

      // If the group has two columns, add a new column
      if (columns.length === 2) {
        updatedOuterColumns.push(columns[0], columns[1], generateNewDetailsOuterColumn(33));
      }
      updatedOuterColumns[0].width = 33;
      setLayoutStructure('33/33/33');
    }

    const updatedView =
      view.type === 'map'
        ? {
            details: {
              ...view.details,
              columns: updatedOuterColumns
            }
          }
        : {
            columns: updatedOuterColumns
          };

    updatePage({
      type: 'view',
      action: 'update',
      origin: 'builder',
      viewKey: view.key,
      viewSchema: {
        ...view,
        ...updatedView
      }
    });
  };

  const handleLayoutStructureChange = (
    newLayoutStructure: DetailsLayoutTwoColumnStructure | DetailsLayoutThreeColumnStructure
  ) => {
    setLayoutStructure(newLayoutStructure);

    const updatedColumns = updateAllColumnWidths(columns, newLayoutStructure);
    const updatedView =
      view.type === 'map'
        ? {
            details: {
              ...view.details,
              columns: updatedColumns
            }
          }
        : {
            columns: updatedColumns
          };

    updatePage({
      type: 'view',
      action: 'update',
      origin: 'builder',
      viewKey: view.key,
      viewSchema: {
        ...view,
        ...updatedView
      }
    });
  };

  return (
    <BuilderAccordion>
      <BuilderAccordion.Item
        isDefaultOpen
        label={t('pages.element_settings.details.categories.styling.details_element_layout')}
      >
        <div className="mb-4">
          <span className="mb-2 block font-medium">
            {t('pages.section_settings.layout_settings.columns')}
          </span>
          <InlineRadioCardGroup
            options={DETAILS_LAYOUT_OPTIONS}
            value={selectedLayoutColumns}
            onValueChange={handleLayoutColumnsChange}
            itemClassName="size-10"
          />
        </div>
        {shouldDisplayInfo && columns.length > 1 && (
          <div className="mb-4 text-xs text-subtle">
            {t('pages.element_settings.list.categories.styling.use_first_item')}
          </div>
        )}
        {columns.length > 1 && (
          <div>
            <span className="mb-2 block font-medium">
              {t('pages.section_settings.layout_settings.structure')}
            </span>

            <Select value={layoutStructure} onValueChange={handleLayoutStructureChange}>
              <Select.Trigger shouldHideValue className="w-full py-1 pl-1">
                <LayoutStructureOption structure={layoutStructure} />
              </Select.Trigger>
              <Select.Content>
                {columns.length === 2 &&
                  LAYOUT_TWO_COLUMN_STRUCTURES.map((structure) => (
                    <Select.Item key={structure} value={structure} asChild>
                      <>
                        <LayoutStructureOption structure={structure} className="max-w-[90%]" />
                        <Select.ItemIndicator className="ml-auto" />
                      </>
                    </Select.Item>
                  ))}

                {columns.length === 3 &&
                  LAYOUT_THREE_COLUMN_STRUCTURES.map((structure) => (
                    <Select.Item key={structure} value={structure} asChild>
                      <>
                        <LayoutStructureOption structure={structure} className="max-w-[90%]" />
                        <Select.ItemIndicator className="ml-auto" />
                      </>
                    </Select.Item>
                  ))}
              </Select.Content>
            </Select>
          </div>
        )}
      </BuilderAccordion.Item>
    </BuilderAccordion>
  );
}
