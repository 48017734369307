import { useTranslation } from 'react-i18next';

import { type BuilderViewSourceCriteriaRule } from '@/types/schema/BuilderView';
import { type CalendarView } from '@/types/schema/views/CalendarView';
import { BuilderAccordion } from '@/components/BuilderAccordion';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { SourceFiltersDialog } from '@/pages/pages/settings-panel/view-settings/common/source-filters/SourceFiltersDialog';
import { SourceFiltersGroupsCard } from '@/pages/pages/settings-panel/view-settings/common/source-filters/SourceFiltersGroupCard';
import { SourceFiltersWarningBanner } from '@/pages/pages/settings-panel/view-settings/common/source-filters/SourceFiltersWarningBanner';
import { useUpdateView } from '@/pages/pages/settings-panel/view-settings/useUpdateView';

export function DataDisplaySourceSettings() {
  const { view, sourceObject } = useActiveViewContext<CalendarView>();
  const shouldShowSourceFiltersWarning =
    view.source.criteria.match === 'all' && view.source.criteria.rules.length > 0;
  const updateViewSchema = useUpdateView();
  const [t] = useTranslation();

  const handleSourceFiltersSubmit = (data: BuilderViewSourceCriteriaRule[][]) => {
    updateViewSchema({
      source: {
        ...view.source,
        criteria: {
          match: 'any',
          rules: [],
          groups: data || []
        }
      }
    });
  };

  return (
    <BuilderAccordion.Item
      label={t(
        'pages.element_settings.common.categories.data_display.general_settings.source_filters'
      )}
      isDefaultOpen
    >
      <div className="space-y-4">
        {shouldShowSourceFiltersWarning && <SourceFiltersWarningBanner />}
        <SourceFiltersGroupsCard
          sourceObject={sourceObject}
          sourceFiltersCriteria={view.source.criteria}
        />
        <SourceFiltersDialog
          sourceObject={sourceObject}
          sourceFiltersCriteria={view.source.criteria}
          onFormSubmit={handleSourceFiltersSubmit}
        />
      </div>
    </BuilderAccordion.Item>
  );
}
