import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input, Label, Select } from '@knack/asterisk-react';
import { z } from 'zod';

import {
  ALL_SYMBOL_FORMATS,
  DECIMAL_SEPARATOR_OPTIONS,
  PRECISION_OPTIONS,
  ROUNDING_OPTIONS,
  SEPARATORS_MAP,
  THOUSANDS_SEPARATOR_OPTIONS,
  type NumberField
} from '@/types/schema/fields';
import { cn } from '@/utils/tailwind';

type NumberFormSettingsProps = {
  field: NumberField;
};

export const numberSchema = z.object({
  format: z.object({
    format: z.preprocess(
      (incomingValue) => (incomingValue === '' ? ALL_SYMBOL_FORMATS[0] : incomingValue),
      z.enum(ALL_SYMBOL_FORMATS)
    ),
    pre: z.string().optional(),
    post: z.string().optional(),
    mark_decimal: z.enum(DECIMAL_SEPARATOR_OPTIONS),
    mark_thousands: z.enum(THOUSANDS_SEPARATOR_OPTIONS),
    precision: z.preprocess(
      // This is a workaround for the creation of a Number field which comes with precision as a number 2
      // which is a bug from the server as the precision is handled as a string in the schema
      (incomingValue) =>
        typeof incomingValue === 'number' ? incomingValue.toString() : incomingValue,
      z.enum(PRECISION_OPTIONS).default(PRECISION_OPTIONS[0])
    ),
    rounding: z.enum(ROUNDING_OPTIONS)
  })
});

export function NumberFormSettings({ field }: NumberFormSettingsProps) {
  const [t] = useTranslation();
  const { register, watch, setValue, control } = useFormContext<NumberField>();

  const fieldFormFormat = watch('format');

  const hasDecimalPlaces =
    fieldFormFormat?.mark_decimal !== 'none' && fieldFormFormat?.mark_decimal !== undefined;
  const hasCustomFormat = fieldFormFormat?.format === 'custom';
  const hasThousandsSeparator = fieldFormFormat?.mark_thousands !== 'none';
  const hasPercentageFormat = fieldFormFormat?.format === '%';

  const getPreviewValue = (): string => {
    if (!fieldFormFormat) return '';

    const {
      mark_thousands: markThousands = 'none',
      mark_decimal: markDecimal = 'none',
      precision = '0',
      format = 'none',
      pre: preFormatValue,
      post: postFormatValue
    } = fieldFormFormat;

    // Lookup separator characters from the map
    const thousandsSeparator = SEPARATORS_MAP[markThousands];
    const decimalSeparator = SEPARATORS_MAP[markDecimal];
    const numericPrecision = parseInt(precision, 10);

    // Build the numeric part of the preview value
    let baseValue = `1${hasThousandsSeparator ? thousandsSeparator : ''}345`;
    if (hasDecimalPlaces && precision !== '0') {
      baseValue += `${decimalSeparator}${'0'.repeat(numericPrecision)}`;
    }

    const isPostFormat = format === '€_after' || format === '%';
    let previewValue = baseValue;

    if (format !== 'none' && !isPostFormat && !hasCustomFormat) {
      previewValue = `${format}${previewValue}`;
    }

    if (isPostFormat) {
      previewValue = `${previewValue}${format === '€_after' ? '€' : '%'}`;
    }

    if (hasCustomFormat) {
      previewValue = `${preFormatValue || ''}${previewValue}${postFormatValue || ''}`;
    }

    return previewValue;
  };

  return (
    <div className="flex flex-col gap-2">
      <Label className="font-medium">
        {t('components.data_table.attributes.field_settings.number.display_options')}
      </Label>
      <p className="text-xs font-normal">
        {t('components.data_table.attributes.field_settings.number.display_options_info_text')}
      </p>

      <div className="flex flex-row justify-between">
        <div
          className={cn('flex w-full flex-col gap-2', {
            'w-40': hasDecimalPlaces
          })}
        >
          <Label htmlFor="mark-decimal-select" className="font-medium">
            {t('components.data_table.attributes.field_settings.number.decimal_separators')}
          </Label>
          <Controller
            name="format.mark_decimal"
            control={control}
            defaultValue={field?.format?.mark_decimal || DECIMAL_SEPARATOR_OPTIONS[0]}
            render={({ field: currentField }) => (
              <Select
                onValueChange={(value) => {
                  currentField.onChange(value);
                  if (value !== 'none' && !hasDecimalPlaces) {
                    setValue('format.precision', '2');
                  }
                }}
                value={currentField.value || DECIMAL_SEPARATOR_OPTIONS[0]}
              >
                <Select.Trigger
                  id="mark-decimal-select"
                  placeholder={t('actions.select')}
                  className="w-full rounded-lg"
                  data-testid="number-form-settings-mark-decimal-select"
                  {...currentField}
                />
                <Select.Content>
                  {DECIMAL_SEPARATOR_OPTIONS.map((option) => {
                    if (hasPercentageFormat && option === 'none') {
                      return null;
                    }

                    return (
                      <Select.Item
                        key={option}
                        value={option}
                        data-testid={`number-settings-mark-decimal-value-${option}`}
                      >
                        {t(
                          `components.data_table.attributes.field_settings.number.decimal_separators_options.${option}`
                        )}
                      </Select.Item>
                    );
                  })}
                </Select.Content>
              </Select>
            )}
          />
        </div>

        {hasDecimalPlaces && (
          <div className="flex w-40 flex-col gap-2">
            <Label htmlFor="precision-select" className="font-medium">
              {t('components.data_table.attributes.field_settings.number.decimal_places')}
            </Label>
            <Controller
              name="format.precision"
              defaultValue={field?.format?.precision || PRECISION_OPTIONS[0]}
              render={({ field: currentField }) => (
                <Select
                  value={currentField.value.toString() ?? PRECISION_OPTIONS[0]}
                  onValueChange={currentField.onChange}
                >
                  <Select.Trigger
                    id="precision-select"
                    placeholder={t('actions.select')}
                    className="w-full rounded-lg"
                    data-testid="number-form-settings-precision-select"
                    {...currentField}
                  />
                  <Select.Content>
                    {PRECISION_OPTIONS.map((option) => {
                      if (hasPercentageFormat && option === '0') {
                        return null;
                      }

                      return (
                        <Select.Item
                          key={option}
                          value={option}
                          data-testid={`number-settings-precision-value-${option}`}
                          className="truncate"
                        >
                          {`${option} (1${option !== '0' ? '.' : ''}${'0'.repeat(
                            parseInt(option, 10)
                          )})`}
                        </Select.Item>
                      );
                    })}
                  </Select.Content>
                </Select>
              )}
            />
          </div>
        )}
      </div>

      <Label htmlFor="mark-thousands-select" className="font-medium">
        {t('components.data_table.attributes.field_settings.number.thousands_separators')}
      </Label>
      <Controller
        name="format.mark_thousands"
        defaultValue={field?.format?.mark_thousands || THOUSANDS_SEPARATOR_OPTIONS[0]}
        render={({ field: currentField }) => (
          <Select
            onValueChange={currentField.onChange}
            defaultValue={currentField.value || THOUSANDS_SEPARATOR_OPTIONS[0]}
          >
            <Select.Trigger
              id="mark-thousands-select"
              placeholder={t('actions.select')}
              className="w-full rounded-lg"
              data-testid="number-form-settings-mark-thousands-select"
              {...currentField}
            />
            <Select.Content>
              {THOUSANDS_SEPARATOR_OPTIONS.map((option) => (
                <Select.Item
                  key={option}
                  value={option}
                  data-testid={`number-settings-mark-thousands-value-${option}`}
                >
                  {t(
                    `components.data_table.attributes.field_settings.number.thousands_separators_options.${option}`
                  )}
                </Select.Item>
              ))}
            </Select.Content>
          </Select>
        )}
      />

      <Label htmlFor="rounding-select" className="font-medium">
        {t('components.data_table.attributes.field_settings.number.rounding')}
      </Label>
      <Controller
        name="format.rounding"
        defaultValue={field?.format?.rounding || ROUNDING_OPTIONS[0]}
        render={({ field: currentField }) => (
          <Select
            onValueChange={currentField.onChange}
            defaultValue={currentField.value || ROUNDING_OPTIONS[0]}
          >
            <Select.Trigger
              id="rounding-select"
              placeholder={t('actions.select')}
              className="w-full rounded-lg"
              data-testid="number-form-settings-rounding-select"
              {...currentField}
            />
            <Select.Content>
              {ROUNDING_OPTIONS.map((option) => (
                <Select.Item
                  key={option}
                  value={option}
                  data-testid={`number-settings-rounding-value-${option}`}
                >
                  {t(
                    `components.data_table.attributes.field_settings.number.rounding_options.${option}`
                  )}
                </Select.Item>
              ))}
            </Select.Content>
          </Select>
        )}
      />

      <Label htmlFor="format-select" className="font-medium">
        {t('components.data_table.attributes.field_settings.number.format')}
      </Label>
      <Controller
        name="format.format"
        defaultValue={field?.format?.format || ALL_SYMBOL_FORMATS[0]}
        render={({ field: currentField }) => (
          <Select
            onValueChange={(newValue) => {
              currentField.onChange(newValue);
              if (newValue === '%') {
                setValue('format.mark_decimal', 'period');
                setValue('format.precision', '2');
              }
            }}
            defaultValue={currentField.value || ALL_SYMBOL_FORMATS[0]}
          >
            <Select.Trigger
              id="format-select"
              placeholder={t('actions.select')}
              className="w-full rounded-lg"
              data-testid="number-form-settings-format-select"
              {...currentField}
            />
            <Select.Content>
              {ALL_SYMBOL_FORMATS.map((format) => (
                <Select.Item
                  key={format}
                  value={format}
                  data-testid={`number-settings-format-value-${format}`}
                >
                  {t(
                    `components.data_table.attributes.field_settings.number.format_options.${format}`
                  )}
                </Select.Item>
              ))}
            </Select.Content>
          </Select>
        )}
      />
      {hasCustomFormat && (
        <div className="flex flex-row gap-2">
          <div className="flex flex-col gap-2">
            <Label htmlFor="format-pre-input" className="font-medium">
              {t('components.data_table.attributes.field_settings.number.before')}
            </Label>
            <Input
              id="format-pre-input"
              type="text"
              className="w-full rounded-lg"
              data-testid="number-form-settings-before-input"
              defaultValue={field?.format?.pre}
              {...register('format.pre')}
            />
          </div>
          <div className="flex flex-col gap-2">
            <Label htmlFor="format-after-input" className="font-medium">
              {t('components.data_table.attributes.field_settings.number.after')}
            </Label>
            <Input
              id="format-after-input"
              type="text"
              className="w-full rounded-lg"
              data-testid="number-form-settings-after-input"
              defaultValue={field?.format?.post}
              {...register('format.post')}
            />
          </div>
        </div>
      )}

      <p className="font-medium">
        {t('components.data_table.attributes.field_settings.number.preview')}
      </p>
      <div className="rounded-lg bg-base">
        <span className="font-light">{getPreviewValue()}</span>
      </div>
    </div>
  );
}
