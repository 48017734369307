import { useFormContext } from 'react-hook-form';
import { Form, Input, RichTextEditor, Textarea } from '@knack/asterisk-react';

import { type KnackField } from '@/types/schema/KnackField';
import { type KnackObject } from '@/types/schema/KnackObject';
import { FormErrorMessage } from '@/components/errors/FormErrorMessage';
import { AddressInput } from '@/components/inputs/address/AddressInput';
import { BooleanInput } from '@/components/inputs/BooleanInput';
import { ConnectionInput } from '@/components/inputs/ConnectionInput';
import { CurrencyInput } from '@/components/inputs/CurrencyInput';
import { EmailInput } from '@/components/inputs/EmailInput';
import { EquationInput } from '@/components/inputs/EquationInput';
import { FileInput } from '@/components/inputs/FileInput';
import { LinkInput } from '@/components/inputs/LinkInput';
import { MultipleChoiceInput } from '@/components/inputs/MultipleChoiceInput';
import { NumberInput } from '@/components/inputs/NumberInput';
import { PasswordInput } from '@/components/inputs/PasswordInput';
import { PersonInput } from '@/components/inputs/PersonInput';
import { PhoneInput } from '@/components/inputs/PhoneInput';
import { RatingInput } from '@/components/inputs/RatingInput';
import { SignatureInput } from '@/components/inputs/SignatureInput';
import { TimerInput } from '@/components/inputs/TimerInput';
import { UserRolesInput } from '@/components/inputs/UserRolesInput';
import { KnackDatePicker } from '@/components/knack-date-picker/KnackDatePicker';

interface FormFieldInputProps {
  field: KnackField;
  sourceObject: KnackObject;
}

function FieldInput({ field, sourceObject }: FormFieldInputProps) {
  const inputHtmlId = `${field.key}-${field.type}-input`;

  const {
    getValues,
    setValue,
    register,
    formState: { errors }
  } = useFormContext();

  switch (field.type) {
    case 'short_text': {
      return (
        <Input
          id={inputHtmlId}
          data-testid={`short-text-input-${field.key}`}
          intent={errors[field.key] && 'destructive'}
          {...register(field.key)}
        />
      );
    }
    case 'number': {
      return <NumberInput id={inputHtmlId} name={field.key} format={field.format} />;
    }
    case 'address': {
      return <AddressInput id={inputHtmlId} targetField={field} name={field.key} />;
    }
    case 'connection': {
      return <ConnectionInput id={inputHtmlId} field={field} name={field.key} />;
    }
    case 'boolean': {
      return <BooleanInput id={inputHtmlId} targetField={field} name={field.key} />;
    }
    case 'multiple_choice': {
      return <MultipleChoiceInput id={inputHtmlId} targetField={field} name={field.key} />;
    }
    case 'currency': {
      return <CurrencyInput id={inputHtmlId} targetField={field} name={field.key} />;
    }
    case 'email': {
      return <EmailInput id={inputHtmlId} targetField={field} name={field.key} />;
    }
    case 'link': {
      return <LinkInput id={inputHtmlId} targetField={field} name={field.key} />;
    }
    case 'equation': {
      return <EquationInput targetField={field} name={field.key} />;
    }
    case 'name': {
      return <PersonInput id={inputHtmlId} targetField={field} name={field.key} />;
    }
    case 'rating': {
      return <RatingInput targetField={field} name={field.key} />;
    }
    case 'paragraph_text': {
      return (
        <Textarea
          id={inputHtmlId}
          data-testid="paragraph-text-input"
          intent={errors[field.key] && 'destructive'}
          {...register(field.key)}
        />
      );
    }
    case 'rich_text': {
      return (
        <RichTextEditor
          editorProps={{
            attributes: {
              'data-testid': 'rich-text-field-input'
            }
          }}
          content={getValues(field.key)}
          data-testid="default-value-rich-text"
          className="h-64"
          onUpdate={({ editor }) => {
            const content = editor.isEmpty ? '' : editor.getHTML();
            setValue(field.key, content);
          }}
        />
      );
    }
    case 'phone': {
      return <PhoneInput targetField={field} name={field.key} id={inputHtmlId} />;
    }
    case 'file':
    case 'image': {
      return <FileInput name={field.key} targetField={field} />;
    }
    case 'signature': {
      return <SignatureInput name={field.key} />;
    }
    case 'date_time': {
      return (
        <KnackDatePicker
          formPath={field.key}
          dateTimeField={field}
          defaultValue={getValues(field.key)}
        />
      );
    }
    case 'timer': {
      return <TimerInput field={field} />;
    }
    case 'password': {
      return <PasswordInput name={field.key} id={inputHtmlId} />;
    }
    case 'user_roles': {
      return <UserRolesInput name={field.key} id={inputHtmlId} selectedRoleObject={sourceObject} />;
    }
    default: {
      return null;
    }
  }
}

export function FormFieldInput({ field, sourceObject }: FormFieldInputProps) {
  const inputHtmlId = `${field.key}-${field.type}-input`;

  const {
    formState: { errors }
  } = useFormContext();

  return (
    <div aria-label={field.name}>
      <Form.Label htmlFor={inputHtmlId} isRequired={field.required}>
        {field.name}
      </Form.Label>
      <FieldInput field={field} sourceObject={sourceObject} />
      <FormErrorMessage name={field.key} errors={errors} className="mt-1" />
    </div>
  );
}
