import { useTranslation } from 'react-i18next';
import { Label, RadioGroup, Select, Switch } from '@knack/asterisk-react';

import { type BuilderPage } from '@/types/schema/BuilderPage';
import { type ConnectionField } from '@/types/schema/fields';
import { type DetailsViewInput } from '@/types/schema/views/DetailsView';
import { usePageHelpers } from '@/hooks/helpers/usePageHelpers';

interface FieldInputConnectionSettingsProps {
  fieldInput: DetailsViewInput;
  connectionField: ConnectionField;
  updateDetailsColumn: (updatedData: Partial<DetailsViewInput>) => void;
}

export function FieldInputConnectionSettings({
  fieldInput,
  connectionField,
  updateDetailsColumn
}: FieldInputConnectionSettingsProps) {
  const [t] = useTranslation();
  const { getLinkableChildPagesBySourceObject } = usePageHelpers();

  // Render only separators when the relationship is many to many
  const shouldRenderFieldValueSeparator = connectionField.relationship.has === 'many';

  const linkableChildPages = connectionField.relationship.object
    ? getLinkableChildPagesBySourceObject(connectionField.relationship.object)
    : [];

  return (
    <div className="space-y-4">
      {shouldRenderFieldValueSeparator && (
        <div className="space-y-2">
          <Label className="text-subtle">
            {t(
              'pages.element_settings.table.categories.data_display.field_management.field_value_separator'
            )}
          </Label>
          <RadioGroup
            value={fieldInput.conn_separator}
            onValueChange={(value: DetailsViewInput['conn_separator']) =>
              updateDetailsColumn({ conn_separator: value })
            }
          >
            <RadioGroup.Container>
              <RadioGroup.Item id="comma" value="comma" />
              <Label htmlFor="comma">
                {t(
                  'pages.element_settings.table.categories.data_display.field_management.separate_values_with_comma'
                )}
              </Label>
            </RadioGroup.Container>
            <RadioGroup.Container>
              <RadioGroup.Item id="newLine" value="new_line" />
              <Label htmlFor="newLine">
                {t(
                  'pages.element_settings.table.categories.data_display.field_management.separate_values_with_line'
                )}
              </Label>
            </RadioGroup.Container>
          </RadioGroup>
        </div>
      )}

      <div>
        <div className="flex items-center gap-2">
          <Switch
            data-testid="link-to-existing-page-switch"
            checked={!!fieldInput.conn_link}
            onCheckedChange={(val) => {
              if (linkableChildPages.length > 0) {
                if (val) {
                  updateDetailsColumn({ conn_link: linkableChildPages[0].key });
                  return;
                }
                updateDetailsColumn({ conn_link: '' });
              }
            }}
          />

          <Label>
            {t(
              'pages.element_settings.table.categories.data_display.field_management.link_to_an_existing_page'
            )}
          </Label>
        </div>
        {linkableChildPages.length === 0 && (
          <p className="mt-2 text-xs text-subtle">
            {t('pages.element_settings.table.categories.data_display.field_management.link_error')}
          </p>
        )}
      </div>
      {fieldInput.conn_link && (
        <div className="ml-10">
          <Select
            defaultValue={linkableChildPages[0]?.key}
            onValueChange={(value: BuilderPage['key']) => updateDetailsColumn({ conn_link: value })}
          >
            <Select.Trigger className="w-full" data-testid="link-to-existing-page-select" />
            <Select.Content>
              {linkableChildPages.map((page) => (
                <Select.Item key={page.key} value={page.key}>
                  {page.label}
                </Select.Item>
              ))}
            </Select.Content>
          </Select>
        </div>
      )}
    </div>
  );
}
