import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Label } from '@knack/asterisk-react';

import { type KnackCriteriaWithValueType } from '@/types/schema/KnackCriteria';
import { FormErrorMessage } from '@/components/errors/FormErrorMessage';
import { DisplayRuleColorPicker } from '@/pages/pages/settings-panel/view-settings/common/display-rules/DisplayRuleColorPicker';

type ColorFieldType = {
  criteriaFormFieldIndex: number;
  updateCriteria: (index: number, value: KnackCriteriaWithValueType) => void;
  colorFieldTitle?: string;
};

export function CriteriaFormColorInput({
  criteriaFormFieldIndex,
  updateCriteria,
  colorFieldTitle
}: ColorFieldType) {
  const {
    getValues,
    clearErrors: clearFormErrors,
    formState: { errors }
  } = useFormContext<{ criteria: KnackCriteriaWithValueType[] }>();

  const [t] = useTranslation();
  return (
    <div className="mb-2 flex-1">
      <Controller
        name={`criteria.${criteriaFormFieldIndex}.color`}
        render={({ field: { value: fieldKey, onChange: onColorChange } }) => (
          <>
            <Label
              className="mb-2 flex items-center justify-between font-medium"
              htmlFor={`${criteriaFormFieldIndex}-${fieldKey}-view-map-default-pin-color`}
            >
              {colorFieldTitle || t('pages.element_settings.map.styling_settings.pin_color')}
            </Label>

            <DisplayRuleColorPicker
              id={`${criteriaFormFieldIndex}-${fieldKey}-view-map-default-pin-color`}
              value={fieldKey}
              isHexColor
              onChange={(color) => onColorChange(color)}
              onBlur={(color) => {
                updateCriteria(criteriaFormFieldIndex, {
                  ...getValues(`criteria.${criteriaFormFieldIndex}`),
                  color
                });
                clearFormErrors();
              }}
            />
          </>
        )}
      />
      <FormErrorMessage
        name={`criteria.${criteriaFormFieldIndex}.color`}
        errors={errors}
        className="mt-1"
      />
    </div>
  );
}
