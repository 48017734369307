import {
  closestCorners,
  KeyboardSensor,
  MouseSensor,
  pointerWithin,
  TouchSensor,
  useSensor,
  useSensors,
  type CollisionDetection,
  type DragEndEvent
} from '@dnd-kit/core';
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable';

export function useDndUtils() {
  // Optimized sensors for the drag and drop operations
  const optimizedSensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        // Require mouse to move 5px to start dragging, this allow onClick to be triggered on click
        distance: 5
      }
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        // Require mouse to move 5px to start dragging, this allow onClick to be triggered on click
        tolerance: 5,
        // Require to press for 100ms to start dragging, this can reduce the chance of dragging accidentally due to page scroll
        delay: 100
      }
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  // Custom collision detection strategy optimized for vertical lists with variable item heights
  const verticalListCollisionDetection: CollisionDetection = (args) => {
    // First, let's see if there are any collisions with the pointer
    const pointerCollisions = pointerWithin(args);

    // If there are collisions with the pointer, return them
    if (pointerCollisions.length > 0) {
      return pointerCollisions;
    }

    // Otherwise, return a closestCorners collision detection
    return closestCorners(args);
  };

  const handleBasicDragEnd = <T extends { id: string }>({
    event,
    array,
    onMove,
    onComplete
  }: {
    event: DragEndEvent;
    array: T[];
    onMove: (oldIndex: number, newIndex: number) => void;
    onComplete: () => void;
  }) => {
    if (!array) {
      return;
    }

    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = array.findIndex((rule) => rule.id === active.id) ?? -1;
      const newIndex = array.findIndex((rule) => rule.id === over.id) ?? -1;

      if (oldIndex === -1 || newIndex === -1) {
        return;
      }

      onMove(oldIndex, newIndex);

      onComplete();
    }
  };

  return {
    optimizedSensors,
    verticalListCollisionDetection,
    handleBasicDragEnd
  };
}
