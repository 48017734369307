import { useTranslation } from 'react-i18next';

import { SubField } from '@/components/import/subfields/SubField';
import { type Column, type FieldPart } from '@/components/import/types';
import { useImportStore } from '@/components/import/useImportStore';

export function SubFields({
  column,
  isColumnVisible
}: {
  column: Column;
  isColumnVisible: boolean;
}) {
  const [t] = useTranslation();
  const { columns, setColumns } = useImportStore((state) => ({
    columns: state.columns,
    setColumns: state.setColumns,
    getColumnVisibility: state.getColumnVisibility
  }));

  const updateColumns = (newPart: FieldPart) => {
    function getHasMapping(currentColumn: Column) {
      return columns.some((columnToCheck) =>
        columnToCheck.meta?.parts.some((partToCheck) => {
          if (
            partToCheck.mappedColumnIndex === Number(currentColumn.accessorKey) &&
            partToCheck.part.key !== newPart.part.key
          ) {
            return true;
          }
          return false;
        })
      );
    }

    const updateColumnMetaParts = (currentPart) => {
      if (currentPart.part.key === newPart.part.key) {
        return { ...newPart };
      }

      return currentPart;
    };

    const resetColumnMapping = (updatedColumn) => ({
      ...updatedColumn,
      meta: {
        ...updatedColumn.meta,
        isThisColumnMapped: false,
        mappedColumnIndex: undefined
      }
    });

    const updateTargetMappedColumn = (updatedColumn) => ({
      ...updatedColumn,
      meta: {
        ...updatedColumn.meta,
        isThisColumnMapped: true,
        mappedColumnIndex: column.accessorKey
      }
    });

    const resetTargetColumn = (updatedColumn) => ({
      ...updatedColumn,
      meta: {
        ...updatedColumn.meta,
        isThisColumnMapped: false,
        mappedColumnIndex: undefined
      }
    });

    const updateColumnMapping = (currentColumn) => {
      let updatedColumn = currentColumn;

      if (currentColumn.header === column.header) {
        updatedColumn = {
          ...currentColumn,
          meta: {
            ...currentColumn.meta,
            parts: currentColumn.meta?.parts.map(updateColumnMetaParts)
          }
        };
      } else if (!isColumnVisible) {
        updatedColumn = resetColumnMapping(updatedColumn);
      }

      // Update the target mapped column if we activate a new mapping to it
      if (Number(currentColumn.accessorKey) === newPart.mappedColumnIndex) {
        updatedColumn = updateTargetMappedColumn(updatedColumn);
      }

      // Reset the target column if we remove the mapping
      if (currentColumn.meta?.isThisColumnMapped && !getHasMapping(currentColumn)) {
        // Only reset the mapping of the current column
        if (currentColumn.meta?.mappedColumnIndex === column.accessorKey) {
          updatedColumn = resetTargetColumn(updatedColumn);
        }
      }

      return updatedColumn;
    };

    const updatedColumns = columns.map(updateColumnMapping);

    setColumns(updatedColumns);
  };

  if (!isColumnVisible) return null;

  let helperText = t('components.add_table.map_columns', {
    columnType: column.meta.existingKnackField?.type || column.meta.newFieldType
  });

  if (column.meta.existingKnackField?.type === 'name' || column.meta.newFieldType === 'name') {
    helperText = t('components.add_table.map_columns_to_person_field');
  } else if (
    column.meta.existingKnackField?.type === 'address' ||
    column.meta.newFieldType === 'address'
  ) {
    helperText = t('components.add_table.map_columns_to_address_field');
  }

  return (
    <div className="mb-3 ml-6 space-y-3" data-testid="map-columns-subfield-field">
      <div className="max-w-80">{helperText}</div>
      {column.meta.parts.map((subField: FieldPart) => (
        <SubField
          header={column.header}
          columns={columns}
          subField={subField}
          key={subField.part.key}
          updateColumns={updateColumns}
          type={column.meta.existingKnackField?.type || column.meta.newFieldType}
        />
      ))}
    </div>
  );
}
