import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { type KnackFieldType } from '@/types/schema/KnackField';
import { type KnackObject } from '@/types/schema/KnackObject';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';

const getExampleValue = (type: KnackFieldType): string => {
  switch (type) {
    case 'short_text':
    case 'multiple_choice':
      return 'Lorem Ipsum';
    case 'paragraph_text':
    case 'rich_text':
    case 'concatenation':
      return 'Lorem Ipsum Dolor Sit Amet';
    case 'currency':
      return '$123.45';
    case 'equation':
    case 'number':
    case 'auto_increment':
    case 'sum':
    case 'min':
    case 'max':
    case 'average':
    case 'count':
      return '123';
    case 'boolean':
      return '1';
    case 'date_time':
    case 'timer':
      return '01/29/2021 12:00:00';
    case 'name':
      return 'John Doe';
    case 'email':
      return 'email@example.com';
    case 'address':
      return '123 Main Street';
    case 'phone':
      return '+1234567890';
    case 'link':
      return 'http://www.knack.com';
    case 'rating':
      return '4';
    case 'password':
      return '******';
    default:
      return 'Lorem Ipsum';
  }
};

export const useTemplateKeyName = (objectKey: KnackObject['key']) => {
  const [t] = useTranslation();
  const { data: app } = useApplicationQuery();
  // Merge the fields of this table with the fields of the connected tables
  // We have two variables, one with the field key and one with the field name that we can later replace with each other
  // On the server we use the keys, but in the editor we want to show the names for better UX
  return useMemo(() => {
    const table = app?.objects?.find((obj) => obj?.key === objectKey);

    const templateKeyVariablesTemp: string[] = [
      ...(table?.fields.map((currentField) => currentField.key) || [])
    ];

    const templateNameVariablesTemp: string[] = [
      ...(table?.fields.map((currentField) => currentField.name) || [])
    ];

    const exampleValues: string[] =
      table?.fields.map((currentField) => getExampleValue(currentField.type)) || [];

    // Get the field types of the table and the connected tables withouth duplicates
    const fieldTypesTemp: KnackFieldType[] = [
      ...(table?.fields.map((currentField) => currentField.type) || [])
    ].filter((fieldType, index, self) => self.indexOf(fieldType) === index);

    const connections = [
      ...(table?.connections.outbound.filter((connection) => connection.has !== 'many') || []),
      ...(table?.connections.inbound || [])
    ];

    // eslint-disable-next-line no-restricted-syntax
    for (const connection of connections) {
      const connectedTable = app?.objects?.find((obj) => obj?.key === connection.object);
      if (!connectedTable) {
        break;
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const currentField of connectedTable.fields) {
        templateKeyVariablesTemp.push(`${connection.key}.${currentField.key}`);
        templateNameVariablesTemp.push(
          `${t('components.data_table.right_sidebar.connections.connection')} ${connection.name} > ${currentField.name}`
        );

        exampleValues.push(getExampleValue(currentField.type));

        if (!fieldTypesTemp.includes(currentField.type)) {
          fieldTypesTemp.push(currentField.type);
        }
      }
    }

    return {
      templateKeyVariables: templateKeyVariablesTemp,
      templateNameVariables: templateNameVariablesTemp,
      exampleValues,
      fieldTypes: fieldTypesTemp
    };
  }, [app?.objects, objectKey, t]);
};
