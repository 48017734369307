import { type BuilderApplication } from '@/types/schema/BuilderApplication';
import { NUMERIC_FIELD_TYPES } from '@/hooks/helpers/useFieldHelpers';

export const areAggregateFieldsEnabledForObjectKey = (
  objectKey: string,
  app: BuilderApplication
) => {
  const table = app?.objects?.find((obj) => obj?.key === objectKey);

  const hasInboundConnectionWithNumberField = table?.connections?.inbound?.some((connection) => {
    if (connection.belongs_to !== 'many') return false;
    const connectedTable = app?.objects?.find((obj) => obj?.key === connection.object);
    return connectedTable?.fields?.some((field) => NUMERIC_FIELD_TYPES.includes(field.type));
  });

  const hasOutboundConnectionWithNumberField = table?.connections?.outbound?.some((connection) => {
    if (connection.has !== 'many') return false;
    const connectedTable = app?.objects?.find((obj) => obj?.key === connection.object);
    return connectedTable?.fields?.some((field) => NUMERIC_FIELD_TYPES.includes(field.type));
  });

  return hasInboundConnectionWithNumberField || hasOutboundConnectionWithNumberField;
};
