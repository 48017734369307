export function getAdjustedHeight(availableHeight: number) {
  const ranges = [
    { max: 250, value: 250 },
    { max: 300, value: 250 },
    { max: 350, value: 300 },
    { max: 400, value: 350 },
    { max: Infinity, value: 400 }
  ];

  return ranges.find((range) => availableHeight < range.max)?.value || 250;
}
