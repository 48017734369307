import { type DateFormatValue, type TimeFormatValue } from '@/types/schema/fields/DateTimeField';
import { type BaseKnackField } from '@/types/schema/KnackField';

export interface TimerField extends BaseKnackField {
  type: 'timer';
  format: {
    date_format?: DateFormatValue;
    minutes?: (typeof MINUTES_FORMAT_OPTIONS)[number];
    time_format?: TimeFormatValue;
    total_format?: (typeof TOTAL_FORMAT_OPTIONS)[number];
  };
}

export const MINUTES_FORMAT_OPTIONS = ['1', '5', '10', '15', '30'] as const;
export const TOTAL_FORMAT_OPTIONS = [
  'from-to',
  'seconds',
  'minutes',
  'hours',
  'days',
  'weeks'
] as const;
