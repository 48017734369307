import { useQuery } from '@tanstack/react-query';

import { type KnackAccount } from '@/types/schema/KnackAccount';
import { queryKeys } from '@/hooks/api/queryKeys';
import { useSession } from '@/hooks/useSession';
import { axiosInstance as axios } from '@/utils/axiosConfig';

async function getAccount(accountId: string): Promise<KnackAccount> {
  // TODO: create new endpoint to get only the account data that we need. See: https://knack.atlassian.net/browse/FE-1241
  const endpoint = accountId
    ? `/v1/accounts/${accountId}/dashboard/schemas`
    : '/v1/accounts/dashboard/schemas';

  const { data } = await axios.get(endpoint, {
    withCredentials: true
  });

  return data.account;
}

export function useAccountQuery(enabled = true) {
  const session = useSession();

  return useQuery({
    queryKey: [queryKeys.account, session.account.id],
    queryFn: () => getAccount(session.account.id),
    staleTime: 1000 * 60 * 5,
    retry: false,
    enabled
  });
}
