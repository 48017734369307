import { useEffect } from 'react';
import Intercom, { boot, shutdown, update } from '@intercom/messenger-js-sdk';

import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { usePagesQuery } from '@/hooks/api/queries/usePagesQuery';
import { useSessionQuery } from '@/hooks/api/queries/useSessionQuery';
import { isFlagEnabled } from '@/utils/flagsmith';

export function shouldInstallIntercom() {
  return import.meta.env.PUBLIC_INTERCOM_APP_ID !== '';
}

type IntercomProviderProps = {
  children: React.ReactNode;
};

export function IntercomProvider({ children }: IntercomProviderProps) {
  const queryParams = new URLSearchParams(window.location.search);

  const { data: application } = useApplicationQuery();
  const { data: session, isPlaceholderData: isSessionPlaceholderData } = useSessionQuery();
  const isAuthenticated = !!session?.user.id;
  const { data: allPages } = usePagesQuery();

  const isV3Origin = queryParams.has('origin') && queryParams.get('origin') === 'v3';
  const hasNextGenAccess =
    isFlagEnabled('full_nextgen_access') || isFlagEnabled('only_nextgen_access');
  const builderVersion = isV3Origin || !hasNextGenAccess ? '3' : '4';

  const getCurrentValidObject = () =>
    application?.objects.some((object) => object.type === 'StandardObject')
      ? application.objects.find((object) => object.type === 'StandardObject')?.key
      : application?.objects[0]?.key;

  // This is meant to be called on every render as detailed by the package documentation:
  // > The package keeps track of any instances needed internally, therefore re-renders due
  // > to DOM changes won't trigger re-boot of the messenger.
  // See https://www.npmjs.com/package/@intercom/messenger-js-sdk
  useEffect(() => {
    Intercom({
      app_id: import.meta.env.PUBLIC_INTERCOM_APP_ID
    });
  });

  useEffect(() => {
    if (!application || isSessionPlaceholderData) {
      return;
    }

    if (isAuthenticated) {
      boot({
        app_id: import.meta.env.PUBLIC_INTERCOM_APP_ID
      });

      update({
        name: `${session.user.firstName ?? ''} ${session.user.lastName ?? ''}`.trim(),
        email: session.user.email,
        user_id: session.user.id,
        account: application.account.slug,
        app_slug: application.slug,
        current_valid_object: getCurrentValidObject(),
        current_valid_scene: allPages && allPages.length > 0 ? allPages[0].key : undefined,
        builder_version: builderVersion
      });
    } else {
      shutdown();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [application, builderVersion, isAuthenticated, isSessionPlaceholderData, session, allPages]);

  useEffect(() => {
    if (!isAuthenticated || !application || !isSessionPlaceholderData) {
      return;
    }

    update({
      current_valid_object: getCurrentValidObject(),
      current_valid_scene: allPages && allPages.length > 0 ? allPages[0].key : undefined
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPages, isAuthenticated, application, isSessionPlaceholderData]);

  return children;
}
