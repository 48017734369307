import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdAdd as AddIcon } from 'react-icons/md';
import { Button } from '@knack/asterisk-react';
import { useIsMutating } from '@tanstack/react-query';

import { mutationKeys } from '@/hooks/api/mutationKeys';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { AddFieldDialog } from '@/components/field-settings/AddFieldDialog';
import { useFieldsStore } from '@/pages/tables/fields/useFieldsStore';

export function AddNewField() {
  const [t] = useTranslation();

  const [shouldShowAddFieldDialog, setShouldShowAddFieldDialog] = useState(false);

  const objectKey = useFieldsStore().use.objectKey();
  const fields = useFieldsStore().use.fields();

  // Disable the button when the application is loading or when a field is being created or updated
  const pendingCreateFieldMutations = useIsMutating({
    mutationKey: [mutationKeys.createField]
  });

  const pendingUpdateFieldMutations = useIsMutating({
    mutationKey: [mutationKeys.updateField]
  });

  const { isFetching: isApplicationLoading } = useApplicationQuery();

  const isAddFieldDisabled =
    isApplicationLoading || pendingCreateFieldMutations > 0 || pendingUpdateFieldMutations > 0;

  if (!objectKey) return null;

  return (
    <>
      <Button
        intent="secondary"
        className="h-9 whitespace-nowrap"
        data-testid="add-new-field-button"
        disabled={isAddFieldDisabled}
        onClick={() => {
          setShouldShowAddFieldDialog(true);
        }}
      >
        <AddIcon size={20} className="mr-1 flex-shrink-0" />
        {t('pages.tables_page.fields_list.header.add_field')}
      </Button>

      {shouldShowAddFieldDialog && (
        <AddFieldDialog
          objectKey={objectKey}
          onClose={() => setShouldShowAddFieldDialog(false)}
          newFieldState={{
            isBeingCreated: true,
            newIndex: fields.length
          }}
        />
      )}
    </>
  );
}
