import { Fragment } from 'react';
import { HiDatabase as DatabaseIcon } from 'react-icons/hi';
import { Label } from '@knack/asterisk-react';

import { useTrackEventsHelpers } from '@/hooks/helpers/useTrackEventsHelpers';
import { cn } from '@/utils/tailwind';
import { AddRecordViewFlowSteps, useAddRecordViewFlowContext } from './AddRecordViewFlowContext';
import { ConnectionDisambiguation } from './ConnectionDisambiguation';

export function ViewSourcePathList() {
  const {
    selectedViewSource,
    selectedViewSourcePath,
    selectedViewType,
    setSelectedViewSourcePath,
    setSelectedViewSourcePathDirectConnectionFieldKey,
    setSelectedViewSourcePathParentConnectionFieldKey,
    onContinue
  } = useAddRecordViewFlowContext();

  const { trackEvent } = useTrackEventsHelpers();
  if (!selectedViewSource) {
    return null;
  }

  const handleSelectViewSourcePath = (value: string) => {
    const newSelectedViewSourcePath = selectedViewSource.paths.find((path) => path.key === value);
    if (!newSelectedViewSourcePath) {
      return;
    }

    setSelectedViewSourcePath(newSelectedViewSourcePath);

    // If the selected path has no connections, we can continue to the confirm step
    if (!newSelectedViewSourcePath.connections) {
      onContinue(AddRecordViewFlowSteps.confirm);
      return;
    }

    // If there are multiple direct connections the user can choose from, default to the first one
    const hasMultipleDirectConnections = newSelectedViewSourcePath.connections.direct.length > 1;
    if (hasMultipleDirectConnections) {
      setSelectedViewSourcePathDirectConnectionFieldKey(
        newSelectedViewSourcePath.connections.direct[0].field.key
      );
    } else {
      setSelectedViewSourcePathDirectConnectionFieldKey(undefined);
    }

    // If there are multiple parent connections the user can choose from, default to the first one
    const hasMultipleParentConnections = newSelectedViewSourcePath.connections.parent.length > 1;
    if (hasMultipleParentConnections) {
      setSelectedViewSourcePathParentConnectionFieldKey(
        newSelectedViewSourcePath.connections.parent[0].field.key
      );
    } else {
      setSelectedViewSourcePathParentConnectionFieldKey(undefined);
    }

    if (selectedViewType === 'table' && newSelectedViewSourcePath.isAuthenticatedUser) {
      trackEvent('add_filtered_user_to_grid');
    }

    // If there is no need to disambiguate the connection, we can continue to the confirm step
    if (!hasMultipleDirectConnections && !hasMultipleParentConnections) {
      onContinue(AddRecordViewFlowSteps.confirm);
    }
  };

  return (
    <div>
      {selectedViewSource.paths.map((sourcePath) => (
        <Fragment key={sourcePath.key}>
          <button
            id={sourcePath.key}
            type="button"
            onClick={() => handleSelectViewSourcePath(sourcePath.key)}
            data-testid={`view-source-path-list-item-${sourcePath.key}`}
            className={cn(
              'flex w-full items-center rounded-lg border border-default bg-default p-2 font-medium hover:border-emphasis hover:bg-subtle hover:text-emphasis [&:not(:last-child)]:mb-2',
              {
                'border-emphasis bg-subtle text-emphasis':
                  sourcePath.key === selectedViewSourcePath?.key
              }
            )}
          >
            <DatabaseIcon size={16} className="mr-2 shrink-0" />
            <Label
              htmlFor={sourcePath.key}
              className="pointer-events-none text-left font-normal text-default"
            >
              {sourcePath.label}
            </Label>
          </button>

          <ConnectionDisambiguation sourcePathKey={sourcePath.key} />
        </Fragment>
      ))}
    </div>
  );
}
