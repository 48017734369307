import { useMemo } from 'react';

import { type KnackObject } from '@/types/schema/KnackObject';
import { emailTemplateSystemVariables } from '@/hooks/helpers/useEmailTemplatesHelpers';
import { useTemplateKeyName } from '@/components/field-settings/utils/rich-text/useTemplateKeyName';

export function useEmailTemplateKeyName(objectKey: KnackObject['key']) {
  const { templateKeyVariables, templateNameVariables } = useTemplateKeyName(objectKey);

  const emailTemplateKeyVariables = useMemo(
    () => [
      ...templateKeyVariables,
      ...emailTemplateSystemVariables.map((emailTemplateVariable) => emailTemplateVariable.key)
    ],
    [templateKeyVariables]
  );

  const emailTemplateNameVariables = useMemo(
    () => [
      ...templateNameVariables,
      ...emailTemplateSystemVariables.map((emailTemplateVariable) => emailTemplateVariable.name)
    ],
    [templateNameVariables]
  );

  return {
    emailTemplateKeyVariables,
    emailTemplateNameVariables
  };
}
