import { useState } from 'react';
import { FormProvider, useForm, type SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Divider, Label, Select, useToast } from '@knack/asterisk-react';
import { z } from 'zod';

import {
  type ConditionalRule,
  type KnackField,
  type KnackFieldKey
} from '@/types/schema/KnackField';
import { type KnackObject } from '@/types/schema/KnackObject';
import { useFieldMutation } from '@/hooks/api/mutations/useFieldMutation';
import { useCriteriaHelpers } from '@/hooks/helpers/useCriteriaHelpers';
import { useGlobalState } from '@/hooks/useGlobalStore';
import { CriteriaForm } from '@/components/CriteriaForm';
import { DiscardChangesModal } from '@/components/DiscardChangesModal';
import { FieldSelect } from '@/components/FieldSelect';
import { getDefaultValue } from '@/components/import/ConfirmImport';
import { TextTooltip } from '@/components/TextTooltip';
import { RuleFormHeader } from '@/pages/tables/toolkit-sidebar/rules/RuleFormHeader';
import { getEligibleFieldsForFieldRules } from '@/pages/tables/toolkit-sidebar/rules/utils';
import { ConditionalRuleFormActionValue } from './ConditionalRuleFormActionValue';

type RunConditionType = 'custom_criteria' | 'run_with_every_record';

function getNewRuleKey(field: KnackField) {
  const existingKeys = field.rules?.map((r: ConditionalRule) => Number(r.key)) || [];
  const largestConditionalRuleKey = Math.max(0, ...existingKeys);
  return String(largestConditionalRuleKey + 1);
}

interface ConditionalRulesFormProps {
  sourceObject: KnackObject;
  existingRule?: ConditionalRule;
  defaultRuleFieldKey?: KnackFieldKey;
  isDuplicating?: boolean;
  onSaveSuccess: () => void;
  onCancel: () => void;
}

export function ConditionalRulesForm({
  sourceObject,
  existingRule,
  defaultRuleFieldKey,
  isDuplicating,
  onSaveSuccess,
  onCancel
}: ConditionalRulesFormProps) {
  const [t] = useTranslation();
  const { presentToast } = useToast();

  const { mutate: updateField, isPending } = useFieldMutation();
  const { getDefaultCriteria, validateCriteriaValues } = useCriteriaHelpers();
  const isDraftModeEnabled = useGlobalState((state) => state.isDraftModeEnabled);

  const [ruleFieldKey, setRuleFieldKey] = useState<KnackFieldKey | undefined>(defaultRuleFieldKey);
  const [isDiscardChangesModalOpen, setIsDiscardChangesModalOpen] = useState(false);
  const [runCondition, setRunCondition] = useState<RunConditionType>(
    existingRule?.criteria && existingRule.criteria.length > 0
      ? 'custom_criteria'
      : 'run_with_every_record'
  );

  const conditionalRuleFormSchema = z.custom<ConditionalRule>().superRefine((data, context) => {
    const criteriaValueErrors = validateCriteriaValues(data.criteria, sourceObject.fields);

    if (criteriaValueErrors.length) {
      criteriaValueErrors.forEach((error) => {
        context.addIssue({
          path: error.path,
          message: t(error.message || 'errors.value_required'),
          code: 'custom'
        });
      });
    }

    // Conditional rules store the value in an array, but there is only value stored in the array
    const ruleValue = data.values[0];

    if (ruleValue.type === 'record' && !ruleValue.input) {
      context.addIssue({
        path: ['values.0.input'],
        message: t('errors.value_required'),
        code: 'custom'
      });
    }

    if (
      ruleValue.type === 'value' &&
      ruleValue.value &&
      typeof ruleValue.value === 'object' &&
      'password' in ruleValue.value &&
      'password_confirmation' in ruleValue.value
    ) {
      const { password, password_confirmation: passwordConfirmation } = ruleValue.value;
      if (!password || password.trim() === '') {
        context.addIssue({
          path: ['values.0.value.password'],
          message: t('errors.value_required'),
          code: 'custom'
        });
      }

      if (password !== passwordConfirmation) {
        context.addIssue({
          path: ['values.0.value.password_confirmation'],
          message: t('components.data_table.errors.passwords_do_not_match'),
          code: 'custom'
        });
      }
    }
  });

  const RUN_CONDITIONS = [
    {
      key: 'run_with_every_record',
      name: t('components.data_table.right_sidebar.conditional_rules.run_with_every_record')
    },
    {
      key: 'custom_criteria',
      name: t('components.data_table.right_sidebar.conditional_rules.custom_criteria')
    }
  ];

  const defaultCriteria = getDefaultCriteria(sourceObject, 'conditional-rule', true);
  const defaultValues: ConditionalRule = {
    key: '',
    criteria: [],
    values: []
  };

  // If the form is loaded with a pre-selected field, set the rule `values` array with the field's default value
  if (defaultRuleFieldKey) {
    const knackField = sourceObject.fields.find((f) => f.key === defaultRuleFieldKey);
    if (knackField) {
      defaultValues.values.push({
        type: 'value',
        field: knackField.key,
        value: getDefaultValue(knackField)
      });
    }
  }

  const formMethods = useForm<ConditionalRule>({
    resolver: zodResolver(conditionalRuleFormSchema),
    defaultValues: existingRule || defaultValues // If an existing rule was passed, set the default values to the existing rule. Otherwise, use the default values
  });

  const {
    formState: { isDirty },
    handleSubmit,
    setValue
  } = formMethods;

  const handleCancel = () => {
    if (isDirty) {
      setIsDiscardChangesModalOpen(true);
      return;
    }

    onCancel();
  };

  const handleRunConditionChange = (selectedValue: RunConditionType) => {
    if (selectedValue === 'run_with_every_record') {
      setValue('criteria', []);
    } else {
      setValue('criteria', defaultCriteria ? [defaultCriteria] : []);
    }
    setRunCondition(selectedValue);
  };

  const onFormSubmit: SubmitHandler<ConditionalRule> = (conditionalRuleToSave) => {
    if (isDraftModeEnabled) {
      return;
    }

    const ruleField = sourceObject.fields.find((f) => f.key === ruleFieldKey);
    const { value, field: valueFieldKey } = conditionalRuleToSave.values[0];

    // Ensure there is a rule field and the value field matches the selected rule field
    if (!ruleField || valueFieldKey !== ruleField.key) {
      return;
    }

    // Sanitize the date value
    if (value && typeof value === 'object' && 'dateFormatted' in value) {
      value.date = value.dateFormatted;
    }
    if (value && typeof value === 'object' && 'to' in value && value.to) {
      value.to.date = value.to.dateFormatted;
    }

    const existingRules = ruleField.rules || [];
    let updatedRules: ConditionalRule[] = [];

    // If editing an existing rule that isn't being duplicated, just replace the existing rule with the new rule
    if (existingRule && !isDuplicating) {
      updatedRules = existingRules.map((rule) => {
        if (rule.key === existingRule.key) {
          return conditionalRuleToSave;
        }
        return rule;
      });
    } else {
      // Otherwise, add the new rule to the existing rules
      const newRule = {
        ...conditionalRuleToSave,
        key: getNewRuleKey(ruleField)
      };
      updatedRules = [...existingRules, newRule];
    }

    updateField(
      {
        tableKey: sourceObject.key,
        updatedField: {
          ...ruleField,
          rules: updatedRules
        }
      },
      {
        onSuccess: () => {
          presentToast({
            title: `${t('components.data_table.right_sidebar.rule_saved')} ${t('components.data_table.right_sidebar.refresh_page')}`
          });

          onSaveSuccess();
        },
        onError: () => {
          presentToast({
            title: t('components.data_table.right_sidebar.rule_save_error')
          });
        }
      }
    );
  };

  return (
    <>
      <div className="rounded-lg bg-muted p-4">
        <h4 className="mb-4 font-semibold">
          {existingRule && !isDuplicating
            ? t('components.data_table.right_sidebar.conditional_rules.edit_conditional_rule')
            : t('components.data_table.right_sidebar.conditional_rules.new_conditional_rule')}
        </h4>
        <FormProvider {...formMethods}>
          <form
            data-testid="conditional-rule-form"
            id="conditional-rules-form"
            onSubmit={handleSubmit(onFormSubmit)}
          >
            {!existingRule && (
              <>
                <Label className="mb-2 block font-medium" htmlFor="conditional-rule-field-select">
                  {t('components.data_table.right_sidebar.conditional_rules.select_field_label')}
                </Label>

                <FieldSelect
                  id="conditional-rule-field-select"
                  data-testid="conditional-rule-field-select"
                  defaultValue={ruleFieldKey}
                  fields={getEligibleFieldsForFieldRules(sourceObject.fields)}
                  onFieldChange={(selectedFieldKey) => {
                    const knackField = sourceObject.fields.find((f) => f.key === selectedFieldKey);
                    if (knackField) {
                      setValue('values.0.value', getDefaultValue(knackField));
                      setValue('values.0.type', 'value');
                      setValue('values.0.field', knackField.key);
                      setRuleFieldKey(selectedFieldKey);
                    }
                  }}
                />
              </>
            )}

            {ruleFieldKey && (
              <>
                {existingRule ? (
                  <RuleFormHeader fieldKey={ruleFieldKey} sourceObject={sourceObject} />
                ) : (
                  <Divider className="my-4" />
                )}

                <Select value={runCondition} onValueChange={handleRunConditionChange}>
                  <Select.Trigger
                    id="run-condition-select"
                    placeholder={t('actions.select')}
                    className="mt-4 w-full truncate rounded-lg"
                    data-testid="run-condition-select"
                    aria-label={t(
                      'components.data_table.right_sidebar.conditional_rules.select_run_condition'
                    )}
                  />
                  <Select.Content>
                    {RUN_CONDITIONS.map((condition) => (
                      <Select.Item
                        key={condition.key}
                        value={condition.key}
                        data-testid="run-condition-item"
                      >
                        {condition.name}
                      </Select.Item>
                    ))}
                  </Select.Content>
                </Select>

                {runCondition === 'custom_criteria' && (
                  <div className="mt-4">
                    <Label className="mb-2 block font-medium">{t('components.rules.when')}</Label>
                    <CriteriaForm
                      className="p-0"
                      criteriaType="conditional-rule"
                      sourceObject={sourceObject}
                      shouldHaveContent
                      canCriteriaValuesBeField
                    />
                  </div>
                )}

                <div className="mt-4">
                  <ConditionalRuleFormActionValue
                    sourceObject={sourceObject}
                    fieldKey={ruleFieldKey}
                  />
                </div>
              </>
            )}
            <div className="mt-4 flex justify-end gap-2">
              <Button
                data-testid="conditional-rule-cancel-button"
                intent="secondary"
                onClick={handleCancel}
              >
                {t('actions.cancel')}
              </Button>
              {isDraftModeEnabled ? (
                <TextTooltip label={t('components.data_table.disabled_because_draftmode')}>
                  <Button disabled data-testid="conditional-rule-submit-button">
                    {t('actions.save')}
                  </Button>
                </TextTooltip>
              ) : (
                <Button
                  type="submit"
                  disabled={!ruleFieldKey}
                  isLoading={isPending}
                  data-testid="conditional-rule-submit-button"
                >
                  {t('actions.save')}
                </Button>
              )}
            </div>
          </form>
        </FormProvider>
      </div>

      <DiscardChangesModal
        isOpen={isDiscardChangesModalOpen}
        onOpenChange={setIsDiscardChangesModalOpen}
        onConfirm={onCancel}
      />
    </>
  );
}
