import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';

import { type DateTimeField } from '@/types/schema/fields';
import { KnackDatePicker } from '@/components/knack-date-picker/KnackDatePicker';
import {
  getKnackDatePickerInitialValues,
  getKnackDatePickerSchema,
  type KnackDatePickerSchema
} from '@/components/knack-date-picker/KnackDatePickerSchema';

type KnackDatePickerStandaloneProps = {
  field: DateTimeField;
  isRepeatDisabled?: boolean;
  onChange?: (date: KnackDatePickerSchema) => void;
  onSubmit?: (date: KnackDatePickerSchema) => void;
  defaultValue: KnackDatePickerSchema;
};

export function KnackDatePickerStandalone({
  field,
  defaultValue,
  onChange,
  onSubmit,
  isRepeatDisabled
}: KnackDatePickerStandaloneProps) {
  const [t] = useTranslation();

  const form = useForm<KnackDatePickerSchema>({
    resolver: zodResolver(getKnackDatePickerSchema(field, t)),
    defaultValues: { ...getKnackDatePickerInitialValues(), ...defaultValue },
    mode: 'onChange'
  });

  const { handleSubmit, watch } = form;

  const handleOnSubmit = (data: KnackDatePickerSchema) => {
    if (onSubmit) {
      onSubmit(data);
    }
  };

  useEffect(() => {
    const subscription = watch((data) => {
      if (!data || !onChange) return;
      onChange(data as KnackDatePickerSchema);
    });
    return () => subscription.unsubscribe();
  }, [onChange, watch]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(handleOnSubmit)} className="">
        <KnackDatePicker
          dateTimeField={field}
          defaultValue={defaultValue}
          isRepeatDisabled={isRepeatDisabled}
        />
      </form>
    </FormProvider>
  );
}
