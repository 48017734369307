import { Controller, useFieldArray, useFormContext, type Path } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HiPlus as PlusIcon, HiXMark as RemoveIcon } from 'react-icons/hi2';
import { Fragment } from 'react/jsx-runtime';
import { Button, Form, Input, Label, Select } from '@knack/asterisk-react';

import { type KnackField, type KnackFieldKey } from '@/types/schema/KnackField';
import { type KnackConnectionWithObject, type KnackObject } from '@/types/schema/KnackObject';
import {
  EMAIL_RECIPIENT_MODE_OPTIONS,
  EMAIL_RECIPIENT_TYPE_OPTIONS,
  type RecordRule,
  type RecordRuleEmail,
  type RecordRuleEmailRecipient,
  type RecordRuleEmailRecipientMode,
  type RecordRuleEmailRecipientType
} from '@/types/schema/rules/RecordRule';
import { useObjectHelpers } from '@/hooks/helpers/useObjectHelpers';
import { cn } from '@/utils/tailwind';
import { FormErrorMessage } from '@/components/errors/FormErrorMessage';

interface RecordRuleEmailRecipientsProps {
  sourceObject: KnackObject;
  shouldShowOnlyEmails?: boolean;
}

type FormattedEmailFieldList = {
  connectionFieldKey?: `${KnackFieldKey}-${KnackFieldKey}`;
  objectName?: string;
  field: KnackField;
};

const getDefaultCustomEmailRecipient = () => {
  const defaultCustomEmailRecipient: RecordRuleEmailRecipient = {
    recipient_mode: 'to',
    recipient_type: 'custom',
    email: '',
    field: null
  };
  return defaultCustomEmailRecipient;
};

export function RecordRuleEmailRecipients({
  sourceObject,
  shouldShowOnlyEmails
}: RecordRuleEmailRecipientsProps) {
  const [t] = useTranslation();

  const { getConnectionsWithObject } = useObjectHelpers();

  const {
    control,
    register,
    getValues,
    getFieldState,
    clearErrors: clearFormErrors,
    formState: { errors }
  } = useFormContext<RecordRule>();

  const {
    fields: recipientsFormField,
    append: appendRecipient,
    remove: removeRecipient,
    update: updateRecipient
  } = useFieldArray({
    control,
    name: 'email.recipients'
  });

  const emailFieldsInThisSourceObject = sourceObject.fields.reduce<FormattedEmailFieldList[]>(
    (acc, field) => {
      if (field.type === 'email') {
        acc.push({
          field
        });
      }
      return acc;
    },
    []
  );

  const getAllConnectedEmailFields = (sourceConnectedObjects: KnackConnectionWithObject[]) =>
    sourceConnectedObjects.flatMap(({ connection, object }) => {
      const newObjectName = connection.name ? `${object.name} (${connection.name})` : object.name;

      return object.fields.reduce<FormattedEmailFieldList[]>((acc, field) => {
        if (field.type === 'email') {
          acc.push({
            connectionFieldKey: `${connection.key}-${field.key}`,
            objectName: newObjectName,
            field
          });
        }
        return acc;
      }, []);
    });

  const allConnectedObjects = getConnectionsWithObject(sourceObject);

  const allConnectedEmailFields: FormattedEmailFieldList[] = [
    ...emailFieldsInThisSourceObject,
    ...getAllConnectedEmailFields(allConnectedObjects)
  ];

  const hasTableEmailFields = allConnectedEmailFields.length > 0;

  const hasThisFieldError = (fieldName: Path<RecordRuleEmail>) =>
    getFieldState(`email.${fieldName}`).error;

  return (
    <Form.Section>
      <Label htmlFor="send-custom-recipients" className="mb-2 font-medium">
        {t('keywords.recipients')}
      </Label>

      {recipientsFormField.map((recipientFormField, recipientFormFieldIndex) => (
        <Fragment key={recipientFormField.id}>
          <div className="my-2 flex">
            <div className="flex-1">
              {!shouldShowOnlyEmails && (
                <>
                  <div className="mb-2 flex gap-2">
                    <div className="flex-1">
                      <Controller
                        control={control}
                        name={`email.recipients.${recipientFormFieldIndex}.recipient_mode`}
                        render={({ field: { value: recipientMode } }) => (
                          <Select
                            value={recipientMode}
                            onValueChange={(newRecipientMode: RecordRuleEmailRecipientMode) => {
                              updateRecipient(recipientFormFieldIndex, {
                                ...getValues(`email.recipients.${recipientFormFieldIndex}`),
                                recipient_mode: newRecipientMode
                              });
                            }}
                          >
                            <Select.Trigger
                              placeholder={t('actions.select')}
                              className="w-full"
                              data-testid={`send-custom-email-recipient-mode-${recipientFormFieldIndex}`}
                            />
                            <Select.Content>
                              {EMAIL_RECIPIENT_MODE_OPTIONS.map((recipientModeOption) => (
                                <Select.Item key={recipientModeOption} value={recipientModeOption}>
                                  {t(
                                    `components.rules.record_rules.email.recipients.${recipientModeOption}`
                                  )}
                                </Select.Item>
                              ))}
                            </Select.Content>
                          </Select>
                        )}
                      />
                    </div>
                    <div className="flex-1">
                      <Controller
                        control={control}
                        name={`email.recipients.${recipientFormFieldIndex}.recipient_type`}
                        render={({ field: { value: recipientType } }) => (
                          <Select
                            value={recipientType}
                            onValueChange={(newRecipientType: RecordRuleEmailRecipientType) => {
                              updateRecipient(recipientFormFieldIndex, {
                                ...getValues(`email.recipients.${recipientFormFieldIndex}`),
                                recipient_type: newRecipientType
                              });
                            }}
                          >
                            <Select.Trigger
                              placeholder={t('actions.select')}
                              className="w-full"
                              data-testid={`send-custom-email-recipient-type-${recipientFormFieldIndex}`}
                            />
                            <Select.Content>
                              {EMAIL_RECIPIENT_TYPE_OPTIONS.map((recipientTypeOption) => {
                                if (recipientTypeOption === 'field' && !hasTableEmailFields) {
                                  return null;
                                }

                                return (
                                  <Select.Item
                                    key={recipientTypeOption}
                                    value={recipientTypeOption}
                                  >
                                    {t(
                                      `components.rules.record_rules.email.recipients.${recipientTypeOption}`
                                    )}
                                  </Select.Item>
                                );
                              })}
                            </Select.Content>
                          </Select>
                        )}
                      />
                    </div>
                  </div>
                  {recipientFormField.recipient_type === 'field' && (
                    <div className="flex gap-2">
                      <Controller
                        control={control}
                        name={`email.recipients.${recipientFormFieldIndex}.field`}
                        render={({ field: { value: emailField } }) => (
                          <Select
                            value={emailField || ''}
                            onValueChange={(newEmailField: KnackFieldKey) => {
                              updateRecipient(recipientFormFieldIndex, {
                                ...getValues(`email.recipients.${recipientFormFieldIndex}`),
                                field: newEmailField
                              });
                              clearFormErrors();
                            }}
                          >
                            <Select.Trigger
                              placeholder={t('actions.select')}
                              className={cn('w-full', {
                                'border-destructive hover:border-destructive focus:border-destructive focus:outline-destructive':
                                  hasThisFieldError(`recipients.${recipientFormFieldIndex}.field`)
                              })}
                            />
                            <Select.Content>
                              {allConnectedEmailFields.map((emailFieldOption) => (
                                <Select.Item
                                  key={emailFieldOption.field.key}
                                  value={
                                    emailFieldOption.connectionFieldKey ||
                                    emailFieldOption.field.key
                                  }
                                >
                                  {emailFieldOption.objectName
                                    ? `${emailFieldOption.objectName} > ${emailFieldOption.field.name}`
                                    : emailFieldOption.field.name}
                                </Select.Item>
                              ))}
                            </Select.Content>
                          </Select>
                        )}
                      />
                    </div>
                  )}
                </>
              )}

              {(!recipientFormField.recipient_type ||
                recipientFormField.recipient_type === 'custom') && (
                <div>
                  <Input
                    className="w-full"
                    data-testid={`send-custom-email-recipient-email-${recipientFormFieldIndex}`}
                    placeholder={t('placeholders.email')}
                    intent={
                      hasThisFieldError(`recipients.${recipientFormFieldIndex}.email`)
                        ? 'destructive'
                        : undefined
                    }
                    {...register(`email.recipients.${recipientFormFieldIndex}.email`)}
                  />
                  <FormErrorMessage
                    name={`email.recipients.${recipientFormFieldIndex}.email`}
                    errors={errors}
                    className="mt-1"
                  />
                </div>
              )}
            </div>

            {recipientsFormField.length > 1 && (
              <Button
                intent="minimal"
                aria-label={t('components.rules.delete_condition')}
                size="xs"
                className="ml-2 mt-1.5 text-subtle hover:bg-emphasis"
                onClick={() => removeRecipient(recipientFormFieldIndex)}
              >
                <RemoveIcon size={16} />
              </Button>
            )}
          </div>
        </Fragment>
      ))}

      <Button
        intent="secondary"
        aria-label={t('components.rules.add_condition')}
        onClick={() => {
          const defaultRecipient = getDefaultCustomEmailRecipient();
          if (defaultRecipient) {
            appendRecipient(defaultRecipient);
          }
        }}
      >
        <Button.Icon icon={PlusIcon} position="left" />
        {t('keywords.recipient')}
      </Button>
    </Form.Section>
  );
}
