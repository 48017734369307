import { useEffect } from 'react';
import { HiPlus as PlusIcon } from 'react-icons/hi';
import { HiMiniTableCells as TableIcon } from 'react-icons/hi2';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { Spinner } from '@knack/asterisk-react';
import { t } from 'i18next';

import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { KNOWLEDGE_BASE_URLS } from '@/utils/knowledge-base';
import { DataTableStoreProvider } from '@/components/data-table/useDataTableStore';
import { EmptyState } from '@/components/EmptyState';
import { MainLayout } from '@/components/layout/MainLayout';
import { ResizableCollapsiblePanel } from '@/components/layout/ResizableCollapsiblePanel';
import { UserRolesTableTree } from '@/pages/roles/UserRolesTablesTree';
import { FieldsStoreProvider } from '@/pages/tables/fields/useFieldsStore';
import { TablesPageContextProvider } from '@/pages/tables/TablesPageContext';
import { ToolkitSidebar } from '@/pages/tables/toolkit-sidebar/ToolkitSidebar';
import { useLastVisitedStore } from '@/pages/tables/useLastVisitedStore';
import { ROUTES } from '@/Router';

function EmptyTablesState() {
  const navigate = useNavigate();

  return (
    <div className="flex justify-center p-[72px]" data-testid="tables-page-empty-state">
      <EmptyState>
        <EmptyState.Icon icon={<TableIcon />} />
        <EmptyState.Title>{t('components.empty_states.tables.title')}</EmptyState.Title>
        <EmptyState.Description>
          {t('components.empty_states.tables.description')}
        </EmptyState.Description>
        <EmptyState.Cta
          label={t('components.empty_states.tables.cta')}
          icon={<PlusIcon />}
          callback={() => navigate(`${ROUTES.TABLES_ADD}?origin=builder-next`)}
        />
        <EmptyState.Link
          link={KNOWLEDGE_BASE_URLS.IMPORTING_DATA}
          linkText={t('components.empty_states.tables.link_text')}
          isExternalLink
        />
      </EmptyState>
    </div>
  );
}

export function RolesPage() {
  const { data: app, isFetching: isAppFetching } = useApplicationQuery();
  const tables = app?.objects.filter((table) => table.type === 'UserObject') || [];
  const urlParams = useParams<{ id: string }>();
  const { id: tableKey } = urlParams as { id: string };

  const { lastVisitedRoleKey, setLastVisitedRoleKey } = useLastVisitedStore((state) => ({
    lastVisitedRoleKey: state.lastVisitedRoleKey,
    setLastVisitedRoleKey: state.setLastVisitedRoleKey
  }));

  useEffect(() => {
    if (tableKey !== lastVisitedRoleKey) {
      setLastVisitedRoleKey(tableKey);
    }
  }, [lastVisitedRoleKey, setLastVisitedRoleKey, tableKey]);

  if (isAppFetching) {
    <div className="flex w-full items-center justify-center">
      <Spinner />
    </div>;
  }

  return (
    <DataTableStoreProvider>
      <FieldsStoreProvider>
        <TablesPageContextProvider>
          <MainLayout rightSidebar={<ToolkitSidebar />} data-testid="main-layout">
            <ResizableCollapsiblePanel
              title={t('components.layout.left_sidebar.users')}
              autoSaveId="roles-panel"
              panelContent={<UserRolesTableTree data-testid="user-roles-table-tree" />}
              mainContent={tables.length === 0 ? <EmptyTablesState /> : <Outlet />}
            />
          </MainLayout>
        </TablesPageContextProvider>
      </FieldsStoreProvider>
    </DataTableStoreProvider>
  );
}
