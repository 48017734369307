import { Controller, useFormContext, type FieldValues, type Path } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input, RichTextEditor } from '@knack/asterisk-react';

import { type KnackField } from '@/types/schema/KnackField';
import { type KnackObject } from '@/types/schema/KnackObject';
import { DateTimeInput } from '@/components/import/confirm-import/date-time/DateTimeInput';
import { TimerInput } from '@/components/import/confirm-import/timer/TimerInput';
import { AddressInput } from '@/components/inputs/address/AddressInput';
import { BooleanInput } from '@/components/inputs/BooleanInput';
import { ConnectionInput } from '@/components/inputs/ConnectionInput';
import { CurrencyInput } from '@/components/inputs/CurrencyInput';
import { EmailInput } from '@/components/inputs/EmailInput';
import { FileInput } from '@/components/inputs/FileInput';
import { LinkInput } from '@/components/inputs/LinkInput';
import { MultipleChoiceInput } from '@/components/inputs/MultipleChoiceInput';
import { NumberInput } from '@/components/inputs/NumberInput';
import { PasswordInput } from '@/components/inputs/PasswordInput';
import { PersonInput } from '@/components/inputs/PersonInput';
import { PhoneInput } from '@/components/inputs/PhoneInput';
import { RatingInput } from '@/components/inputs/RatingInput';
import { SignatureInput } from '@/components/inputs/SignatureInput';
import { UserRolesInput } from '@/components/inputs/UserRolesInput';

interface FieldRecordValueInputProps<T extends FieldValues> {
  field: KnackField;
  formFieldName: Path<T>;
  sourceObject: KnackObject;
}

function RecordValueInput<T extends FieldValues>({
  field,
  formFieldName,
  sourceObject
}: FieldRecordValueInputProps<T>) {
  const [t] = useTranslation();
  const inputId = `${field.key}-${field.type}-input`;

  const { register, getFieldState } = useFormContext<T>();

  const hasError = getFieldState(formFieldName)?.error;

  switch (field.type) {
    case 'rich_text':
      return (
        <div>
          <Controller
            name={formFieldName}
            render={({ field: { value, onChange } }) => (
              <RichTextEditor
                content={value}
                data-testid="rule-form-value-input-rich-text"
                onUpdate={({ editor }) => {
                  const content = editor.isEmpty ? '' : editor.getHTML();
                  onChange(content);
                }}
              />
            )}
          />
        </div>
      );
    case 'rating':
      return <RatingInput targetField={field} name={formFieldName} />;
    case 'address':
      return <AddressInput id={inputId} targetField={field} name={formFieldName} />;
    case 'name':
      return <PersonInput id={inputId} targetField={field} name={formFieldName} />;
    case 'phone':
      return <PhoneInput id={inputId} targetField={field} name={formFieldName} />;
    case 'file':
    case 'image':
      return <FileInput targetField={field} name={formFieldName} />;
    case 'email':
      return <EmailInput id={inputId} targetField={field} name={formFieldName} />;
    case 'link':
      return <LinkInput id={inputId} targetField={field} name={formFieldName} />;
    case 'currency':
      return <CurrencyInput id={inputId} targetField={field} name={formFieldName} />;
    case 'number':
      return <NumberInput id={inputId} name={formFieldName} format={field.format} />;
    case 'signature':
      return <SignatureInput name={formFieldName} />;
    case 'date_time':
      return <DateTimeInput id={inputId} targetField={field} name={formFieldName} />;
    case 'timer':
      return <TimerInput id={inputId} targetField={field} name={formFieldName} />;
    case 'boolean':
      return <BooleanInput id={inputId} targetField={field} name={formFieldName} />;
    case 'multiple_choice':
      return <MultipleChoiceInput id={inputId} targetField={field} name={formFieldName} />;
    case 'user_roles':
      return <UserRolesInput name={formFieldName} id={inputId} selectedRoleObject={sourceObject} />;
    case 'connection':
      return <ConnectionInput id={inputId} field={field} name={formFieldName} />;
    case 'password':
      return <PasswordInput id={inputId} name={formFieldName} />;

    default:
      return (
        <Input
          data-testid="rule-form-value-input"
          className="text-sm"
          intent={hasError ? 'destructive' : 'default'}
          placeholder={t('actions.enter_value')}
          {...register(formFieldName)}
        />
      );
  }
}

export function FieldRecordValueInput<T extends FieldValues>({
  field,
  formFieldName,
  sourceObject: sourceObjectTable
}: FieldRecordValueInputProps<T>) {
  return (
    <div data-testid={`field-record-${field.type}-input`}>
      <RecordValueInput<T>
        field={field}
        formFieldName={formFieldName}
        sourceObject={sourceObjectTable}
      />
    </div>
  );
}
