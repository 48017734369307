import { useTranslation } from 'react-i18next';
import {
  HiArrowLeftCircle as ArrowIcon,
  HiDocument as DocumentIcon,
  HiPlus as PlusIcon,
  HiRectangleGroup as ViewsIcon
} from 'react-icons/hi2';

import { KNOWLEDGE_BASE_URLS } from '@/utils/knowledge-base';
import { EmptyState } from '@/components/EmptyState';
import { usePagesPageContext } from '@/pages/pages/PagesPageContext';

interface PageEmptyStateProps {
  emptyStateKey: 'no-pages' | 'no-views' | 'select-page';
}

export function PageEmptyState({ emptyStateKey }: PageEmptyStateProps) {
  const [t] = useTranslation();
  const { setIsAddNewPageDialogOpen, setNewPageType } = usePagesPageContext();

  let content: JSX.Element | null = null;

  if (emptyStateKey === 'no-pages') {
    content = (
      <EmptyState>
        <EmptyState.Icon icon={<DocumentIcon />} />
        <EmptyState.Title>
          {t('components.empty_states.create_your_first_page.title')}
        </EmptyState.Title>
        <EmptyState.Description>
          {t('components.empty_states.create_your_first_page.description')}
        </EmptyState.Description>
        <EmptyState.Cta
          label={t('components.empty_states.create_your_first_page.cta')}
          icon={<PlusIcon />}
          callback={() => setIsAddNewPageDialogOpen(true)}
        />
        <EmptyState.Link
          link={KNOWLEDGE_BASE_URLS.PAGES_OVERVIEW}
          linkText={t('components.empty_states.create_your_first_page.link')}
          isExternalLink
        />
      </EmptyState>
    );
  }

  if (emptyStateKey === 'no-views') {
    content = (
      <EmptyState>
        <EmptyState.Icon icon={<ViewsIcon />} />
        <EmptyState.Title>
          {t('components.empty_states.add_views_to_this_page.title')}
        </EmptyState.Title>
        <EmptyState.Description>
          {t('components.empty_states.add_views_to_this_page.description')}
        </EmptyState.Description>
        <EmptyState.Cta
          label={t('components.empty_states.add_views_to_this_page.cta')}
          icon={<PlusIcon />}
          callback={() => {}}
        />
        <EmptyState.Link
          link={KNOWLEDGE_BASE_URLS.ELEMENTS_OVERVIEW}
          linkText={t('components.empty_states.add_views_to_this_page.link')}
          isExternalLink
        />
      </EmptyState>
    );
  }

  if (emptyStateKey === 'select-page') {
    content = (
      <EmptyState>
        <EmptyState.Icon icon={<ArrowIcon />} />
        <EmptyState.Title>{t('components.empty_states.select_a_page.title')}</EmptyState.Title>
        <EmptyState.Description>
          {t('components.empty_states.select_a_page.description')}
        </EmptyState.Description>
        <EmptyState.Cta
          label={t('components.empty_states.select_a_page.cta')}
          icon={<PlusIcon />}
          callback={() => {
            setNewPageType('page');
            setIsAddNewPageDialogOpen(true);
          }}
        />
      </EmptyState>
    );
  }

  return (
    <div className="py-8" data-testid="page-editor-empty-state">
      {content}
    </div>
  );
}
