import { useState } from 'react';
import { FormProvider, useForm, type SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Divider, Label, Textarea, useToast } from '@knack/asterisk-react';
import { z } from 'zod';

import {
  type KnackField,
  type KnackFieldKey,
  type ValidationRule
} from '@/types/schema/KnackField';
import { type KnackObject } from '@/types/schema/KnackObject';
import { useFieldMutation } from '@/hooks/api/mutations/useFieldMutation';
import { useCriteriaHelpers } from '@/hooks/helpers/useCriteriaHelpers';
import { useGlobalState } from '@/hooks/useGlobalStore';
import { CriteriaForm } from '@/components/CriteriaForm';
import { DiscardChangesModal } from '@/components/DiscardChangesModal';
import { FormErrorMessage } from '@/components/errors/FormErrorMessage';
import { FieldSelect } from '@/components/FieldSelect';
import { TextTooltip } from '@/components/TextTooltip';
import { RuleFormHeader } from '@/pages/tables/toolkit-sidebar/rules/RuleFormHeader';
import { getEligibleFieldsForFieldRules } from '@/pages/tables/toolkit-sidebar/rules/utils';

function getNewRuleKey(field: KnackField) {
  const existingKeys = field.validation?.map((r: ValidationRule) => Number(r.key)) || [];
  const largestConditionalRuleKey = Math.max(0, ...existingKeys);
  return String(largestConditionalRuleKey + 1);
}

interface ValidationRuleFormProps {
  sourceObject: KnackObject;
  existingRule?: ValidationRule;
  defaultRuleFieldKey?: KnackFieldKey;
  isDuplicating?: boolean;
  onSaveSuccess: () => void;
  onCancel: () => void;
}

export function ValidationRuleForm({
  sourceObject,
  existingRule,
  defaultRuleFieldKey,
  isDuplicating,
  onSaveSuccess,
  onCancel
}: ValidationRuleFormProps) {
  const [t] = useTranslation();
  const { presentToast } = useToast();

  const { mutate: updateField, isPending } = useFieldMutation();
  const { getDefaultCriteria, validateCriteriaValues } = useCriteriaHelpers();
  const isDraftModeEnabled = useGlobalState((state) => state.isDraftModeEnabled);

  const [ruleFieldKey, setRuleFieldKey] = useState<KnackFieldKey | undefined>(defaultRuleFieldKey);
  const [isDiscardChangesModalOpen, setIsDiscardChangesModalOpen] = useState(false);

  const validationRuleFormSchema = z.custom<ValidationRule>().superRefine((data, context) => {
    const criteriaValueErrors = validateCriteriaValues(data.criteria, sourceObject.fields);

    if (criteriaValueErrors.length) {
      criteriaValueErrors.forEach((error) => {
        context.addIssue({
          path: error.path,
          message: t(error.message || 'errors.value_required'),
          code: 'custom'
        });
      });
    }

    if (!data.message) {
      context.addIssue({
        path: ['message'],
        message: t('components.data_table.right_sidebar.validation_rules.errors.missing_message'),
        code: 'custom'
      });
    }
  });

  const defaultCriteria = getDefaultCriteria(sourceObject, 'validation-rule');

  const defaultValues: ValidationRule = {
    key: '',
    criteria: defaultCriteria ? [defaultCriteria] : [],
    message: t('components.data_table.right_sidebar.validation_rules.text_area_default_value')
  };

  const formMethods = useForm<ValidationRule>({
    resolver: zodResolver(validationRuleFormSchema),
    defaultValues: existingRule || defaultValues
  });

  const {
    formState: { errors, isDirty },
    register,
    handleSubmit
  } = formMethods;

  const handleCancel = () => {
    if (isDirty) {
      setIsDiscardChangesModalOpen(true);
      return;
    }

    onCancel();
  };

  const onFormSubmit: SubmitHandler<ValidationRule> = (validationRuleToSave) => {
    if (isDraftModeEnabled) return;

    const ruleField = sourceObject.fields.find((f) => f.key === ruleFieldKey);

    if (!ruleField) {
      return;
    }

    const existingRules = ruleField.validation || [];
    let updatedRules: ValidationRule[] = [];

    // If editing an existing rule that isn't being duplicated, just replace the existing rule with the new rule
    if (existingRule && !isDuplicating) {
      updatedRules = existingRules.map((rule) => {
        if (rule.key === existingRule.key) {
          return validationRuleToSave;
        }
        return rule;
      });
    } else {
      // Otherwise, add the new rule to the existing rules
      const newRule = {
        ...validationRuleToSave,
        key: getNewRuleKey(ruleField)
      };
      updatedRules = [...existingRules, newRule];
    }

    updateField(
      {
        tableKey: sourceObject.key,
        updatedField: {
          ...ruleField,
          validation: updatedRules
        }
      },
      {
        onSuccess: () => {
          presentToast({
            title: t('components.data_table.right_sidebar.rule_saved')
          });

          onSaveSuccess();
        },
        onError: () => {
          presentToast({
            title: t('components.data_table.right_sidebar.rule_save_error')
          });
        }
      }
    );
  };

  const getDataTestId = () => {
    if (!existingRule) {
      return 'validation-rule-form';
    }
    return isDuplicating ? 'validation-rule-duplicate-form' : 'validation-rule-edit-form';
  };

  return (
    <>
      <div className="rounded-lg bg-muted p-4">
        <h4 className="mb-4 font-semibold">
          {existingRule && !isDuplicating
            ? t('components.data_table.right_sidebar.validation_rules.edit_validation_rule')
            : t('components.data_table.right_sidebar.validation_rules.new_validation_rule')}
        </h4>
        <FormProvider {...formMethods}>
          <form
            data-testid={getDataTestId()}
            id="validation-rules-form"
            onSubmit={handleSubmit(onFormSubmit)}
          >
            {!existingRule && (
              <>
                <Label className="mb-2 block font-medium" htmlFor="validation-rule-field-select">
                  {t('components.data_table.right_sidebar.validation_rules.select_field_label')}
                </Label>

                <FieldSelect
                  id="validation-rule-field-select"
                  data-testid="validation-rule-field-select"
                  defaultValue={ruleFieldKey}
                  fields={getEligibleFieldsForFieldRules(sourceObject.fields)}
                  onFieldChange={setRuleFieldKey}
                />
              </>
            )}

            {ruleFieldKey && (
              <>
                {existingRule ? (
                  <RuleFormHeader fieldKey={ruleFieldKey} sourceObject={sourceObject} />
                ) : (
                  <Divider className="my-4" />
                )}

                {/* {defaultCriteria && <OldCriteriaForm
                  table={sourceObject}
                  defaultValues={defaultValues}
                  defaultCriteria={defaultCriteria}
                  setShouldRenderForm={() => {}}
                />} */}

                <div className="mt-4">
                  <Label className="mb-2 block font-medium">{t('components.rules.when')}</Label>
                  <CriteriaForm
                    className="p-0"
                    criteriaType="validation-rule"
                    sourceObject={sourceObject}
                    shouldHaveContent
                  />
                </div>

                <div className="mt-4">
                  <Label
                    className="mb-2 block font-medium"
                    htmlFor="message"
                    intent={errors.message && 'destructive'}
                  >
                    {t('components.data_table.right_sidebar.validation_rules.text_area_label')}
                  </Label>
                  <Textarea
                    id="message"
                    intent={errors.message && 'destructive'}
                    data-testid="validation-rule-message"
                    {...register('message')}
                  />
                  <FormErrorMessage className="mt-1" errors={errors} name="message" />
                </div>
              </>
            )}

            <div className="mt-4 flex justify-end gap-2">
              <Button
                intent="secondary"
                data-testid="validation-rule-cancel-button"
                onClick={handleCancel}
              >
                {t('actions.cancel')}
              </Button>
              {isDraftModeEnabled ? (
                <TextTooltip label={t('components.data_table.disabled_because_draftmode')}>
                  <Button disabled data-testid="validation-rule-submit-button">
                    {t('actions.save')}
                  </Button>
                </TextTooltip>
              ) : (
                <Button
                  type="submit"
                  disabled={!ruleFieldKey}
                  isLoading={isPending}
                  data-testid="validation-rule-submit-button"
                >
                  {t('actions.save')}
                </Button>
              )}
            </div>
          </form>
        </FormProvider>
      </div>

      <DiscardChangesModal
        isOpen={isDiscardChangesModalOpen}
        onOpenChange={setIsDiscardChangesModalOpen}
        onConfirm={onCancel}
      />
    </>
  );
}
