import { useTranslation } from 'react-i18next';

import { type BuilderView } from '@/types/schema/BuilderView';
import { CollapsiblePanel } from '@/components/layout/CollapsiblePanel';
import { ActiveTabContextProvider } from '@/pages/pages/settings-panel/view-settings/ActiveTabContext';
import { CalendarViewSettings } from '@/pages/pages/settings-panel/view-settings/calendar/CalendarViewSettings';
import { MapSettings } from '@/pages/pages/settings-panel/view-settings/map/MapSettings';
import { ReportViewSettings } from '@/pages/pages/settings-panel/view-settings/report/ReportViewSettings';
import { ViewInputDeleteModeContextProvider } from '@/pages/pages/settings-panel/view-settings/ViewInputDeleteModeContext';
import { ActiveViewContextProvider } from './ActiveViewContextProvider';
import { DetailsSettings } from './details/DetailsSettings';
import { FormSettings } from './form/FormSettings';
import { ListViewSettings } from './list/ListViewSettings';
import { LoginSettings } from './login/LoginSettings';
import { MenuSettings } from './menu/MenuSettings';
import { RichTextSettings } from './rich-text/RichTextSettings';
import { TableViewSettings } from './table/TableViewSettings';
import { ViewInputEditModeContextProvider } from './ViewInputEditModeContext';

interface ViewSettingsProps {
  view: BuilderView;
}

export function ViewSettings({ view }: ViewSettingsProps) {
  const [t] = useTranslation();

  const getViewSettingsContent = () => {
    switch (view.type) {
      case 'form':
      case 'registration':
        return (
          <ViewInputEditModeContextProvider>
            <FormSettings />
          </ViewInputEditModeContextProvider>
        );
      case 'table':
        return (
          <ViewInputEditModeContextProvider>
            <TableViewSettings />
          </ViewInputEditModeContextProvider>
        );
      case 'list':
        return (
          <ViewInputEditModeContextProvider>
            <ListViewSettings />
          </ViewInputEditModeContextProvider>
        );
      case 'rich_text':
        return <RichTextSettings />;
      case 'menu':
        return (
          <ViewInputEditModeContextProvider>
            <ViewInputDeleteModeContextProvider>
              <MenuSettings />
            </ViewInputDeleteModeContextProvider>
          </ViewInputEditModeContextProvider>
        );
      case 'report':
        return (
          <ViewInputEditModeContextProvider>
            <ReportViewSettings />
          </ViewInputEditModeContextProvider>
        );
      case 'details':
        return (
          <ViewInputEditModeContextProvider>
            <DetailsSettings />
          </ViewInputEditModeContextProvider>
        );
      case 'login':
        return <LoginSettings />;
      case 'map':
        return (
          <ViewInputEditModeContextProvider>
            <MapSettings />
          </ViewInputEditModeContextProvider>
        );
      case 'calendar':
        return (
          <ViewInputEditModeContextProvider>
            <ActiveTabContextProvider>
              <CalendarViewSettings />
            </ActiveTabContextProvider>
          </ViewInputEditModeContextProvider>
        );

      // More views will be added here as they get implemented
      default:
        return (
          <CollapsiblePanel.Content title={t('pages.element_settings.heading')}>
            Coming Soon
          </CollapsiblePanel.Content>
        );
    }
  };

  return (
    <ActiveViewContextProvider viewKey={view.key}>
      {getViewSettingsContent()}
    </ActiveViewContextProvider>
  );
}
