import { type BaseKnackField } from '@/types/schema/KnackField';

export interface DateTimeField extends BaseKnackField {
  type: 'date_time';
  format: DateTimeFieldFormat;
}

export type DateTimeFieldRepeatWeekDay = {
  SU: boolean;
  MO: boolean;
  TU: boolean;
  WE: boolean;
  TH: boolean;
  FR: boolean;
  SA: boolean;
};

export interface DateTimeFieldFormat {
  calendar?: boolean; // This is the option for "Yes, add options like repeat and end-time to use with a calendar."
  date_format?: DateFormatValue;
  default_date?: string;
  default_time?: string;
  default_type?: DefaultDateType;
  time_format?: TimeFormatValue;
  time_type?: DefaultTimeType;
}

export type DefaultDateType = (typeof DEFAULT_DATE_TYPE_OPTIONS)[number];
export type DefaultTimeType = (typeof DEFAULT_TIME_TYPE_OPTIONS)[number];
export type DateFormatValue = (typeof DATE_FORMAT_OPTIONS)[number];
export type TimeFormatValue = (typeof TIME_FORMAT_OPTIONS)[number];
export type DateTimeRangeType = (typeof DATE_TIME_RANGE_TYPES)[number];

export const DATE_FORMAT_OPTIONS = [
  'mm/dd/yyyy',
  'dd/mm/yyyy',
  'M D, yyyy',
  'Ignore Date'
] as const;
export type DateFormatOptions = (typeof DATE_FORMAT_OPTIONS)[number];
export const TIME_FORMAT_OPTIONS = ['HH:MM am', 'HH MM (military)', 'Ignore Time'] as const;
export type TimeFormatOptions = (typeof TIME_FORMAT_OPTIONS)[number];
export const DEFAULT_DATE_TYPE_OPTIONS = ['current', 'date', 'none'] as const;
export const DEFAULT_TIME_TYPE_OPTIONS = ['current', 'time', 'none'] as const;
export const DATE_TIME_RANGE_TYPES = [
  'days',
  'week',
  'weeks',
  'month',
  'months',
  'quarter',
  'year',
  'years',
  'rolling weeks',
  'rolling months',
  'rolling years'
] as const;
