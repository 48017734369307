import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiChevronDown as ChevronDownIcon } from 'react-icons/hi2';
import { Badge, Button, Checkbox, DropdownMenu, Label } from '@knack/asterisk-react';

import { type KnackFieldType } from '@/types/schema/KnackField';
import { type Column, type FieldPart } from '@/components/import/types';

interface SubFieldProps {
  columns: Column[];
  subField: FieldPart;
  updateColumns: (newPart: FieldPart) => void;
  header: string;
  type?: KnackFieldType;
}

export function SubField({ columns, subField, updateColumns, header, type }: SubFieldProps) {
  // Pre-select the subfield if it's the first part of the field and map the column of the columns array

  const [t] = useTranslation();
  const [isChecked, setIsChecked] = useState(false);
  const [selectedColumn, setSelectedColumn] = useState<Column | undefined>();

  function handleCheckboxChange() {
    setIsChecked(!isChecked);

    // Reset the selected column if the checkbox is unchecked
    if (isChecked) {
      setSelectedColumn(undefined);
      updateColumns({
        ...subField,
        mapped: false,
        mappedColumnIndex: undefined
      });
    }
  }

  const preSelectSubField = () => {
    switch (type) {
      case 'name':
        columns.forEach((column) => {
          if (column.header === 'Name') {
            if (subField.part.key === 'first') {
              setSelectedColumn(column);
            }
            updateColumns({
              ...subField,
              mapped: true,
              mappedColumnIndex: columns.indexOf(column)
            });
          }
        });
        return subField.part.key === 'first';
      case 'address':
        columns.forEach((column) => {
          if (column.meta.newFieldType === 'address') {
            if (subField.part.key === 'street') {
              updateColumns({
                ...subField,
                mapped: true,
                mappedColumnIndex: columns.indexOf(column)
              });
              setSelectedColumn(column);
            }
          }
        });
        return subField.part.key === 'street';
      case 'link':
        columns.forEach((column) => {
          if (column.header === 'URL') {
            updateColumns({
              ...subField,
              mapped: true,
              mappedColumnIndex: columns.indexOf(column)
            });
            setSelectedColumn(column);
          }
        });
        return subField.part.key === 'url';
      default:
        return false;
    }
  };

  useEffect(() => {
    if (preSelectSubField()) {
      setIsChecked(true);
    }
  }, [type]);

  // Update the selected column when user press Use first row as headers after selecting and mapping a column
  useEffect(() => {
    if (subField.mappedColumnIndex !== undefined) {
      const selectedColumnIndex = columns.findIndex(
        (columnToFind) => Number(columnToFind.accessorKey) === subField.mappedColumnIndex
      );
      setSelectedColumn(columns[selectedColumnIndex]);
    }
  }, [subField.mappedColumnIndex, columns]);

  return (
    <div data-testid={`map-columns-subfield-${subField.part.key}`}>
      <div className="mb-2">
        <Checkbox
          checked={isChecked}
          onClick={() => handleCheckboxChange()}
          data-testid={`subfield-selection-checkbox-${subField.part.key}`}
          id={`subfield-selection-checkbox-${subField.part.key}`}
        />
        <Label className="ml-2" htmlFor={`subfield-selection-checkbox-${subField.part.key}`}>
          {subField.part.label}
        </Label>
      </div>
      {isChecked && (
        <DropdownMenu>
          <DropdownMenu.Trigger asChild>
            <Button
              size="sm"
              intent="secondary"
              className="w-full justify-between"
              data-testid={`subfield-selection-button-${subField.part.key}`}
            >
              {selectedColumn ? selectedColumn.header : t('components.add_table.select_column')}
              <ChevronDownIcon size={20} />
            </Button>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content
            data-testid={`subfield-selection-dropdown-${subField.part.key}`}
            collisionPadding={8}
            align="end"
          >
            {columns.map((columnToMap: Column, index: number) => {
              const hasMappedParts = columnToMap.meta?.parts.some((part) => part.mapped === true);
              const shouldDisableColumn =
                columnToMap.meta?.isThisColumnMapped ||
                // Disable this column from being selected if has an active mapping and it's a different column than the current one
                (hasMappedParts && columnToMap.header !== header);

              return (
                <DropdownMenu.Item
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${columnToMap}-${index}`}
                  data-testid={`subfield-selection-dropdown-item-${columnToMap.header}`}
                  disabled={shouldDisableColumn}
                  defaultValue={selectedColumn?.header ?? t('components.add_table.select_column')}
                  onSelect={() => {
                    setSelectedColumn(columnToMap);
                    updateColumns({
                      ...subField,
                      mapped: true,
                      mappedColumnIndex: index
                    });
                  }}
                >
                  <div className="flex w-full items-center justify-between">
                    <span>{columnToMap.header}</span>
                    {(hasMappedParts || columnToMap.meta?.isThisColumnMapped) && (
                      <Badge>
                        {hasMappedParts
                          ? t('components.add_table.mapping')
                          : t('components.add_table.mapped')}
                      </Badge>
                    )}
                  </div>
                </DropdownMenu.Item>
              );
            })}
          </DropdownMenu.Content>
        </DropdownMenu>
      )}
    </div>
  );
}
