import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { Dialog } from '@knack/asterisk-react';

import { type KnackObject } from '@/types/schema/KnackObject';
import { useObjectHelpers } from '@/hooks/helpers/useObjectHelpers';
import { DuplicateTableForm } from '@/pages/tables/tables-tree/table-dropdown-menu/duplicate-table-form/DuplicateTableForm';
import { ROUTES } from '@/Router';

export enum FormSteps {
  SourceTargetSelect = 1,
  ConfirmCopy = 2
}

export const INITIAL_STEP = FormSteps.SourceTargetSelect;
export const FINAL_STEP = FormSteps.ConfirmCopy;

export function CopyRoleDialogContent({ defaultTable }: { defaultTable: KnackObject }) {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const tableKey = defaultTable.key;

  const closeDialog = () => {
    navigate(generatePath(ROUTES.ROLES_ID, { id: tableKey }));
  };

  const [currentFormStep, setCurrentFormStep] = useState<FormSteps>(INITIAL_STEP);

  const handleStepChange = (newStep: FormSteps) => {
    setCurrentFormStep(newStep);
  };

  return (
    <Dialog open onOpenChange={() => closeDialog()}>
      <Dialog.Content>
        <Dialog.MainContent className="space-y-4">
          <Dialog.Header>
            <span className="text-xs">
              {t('components.dialogs.tables.step', {
                currentStep: currentFormStep,
                totalSteps: FINAL_STEP
              })}
            </span>
            <Dialog.Title>{t('components.dialogs.roles.copy.duplicate_user_role')}</Dialog.Title>
          </Dialog.Header>
          <DuplicateTableForm
            table={defaultTable}
            onStepChange={handleStepChange}
            onSave={closeDialog}
            onCancel={closeDialog}
            isUser
          />
        </Dialog.MainContent>
      </Dialog.Content>
    </Dialog>
  );
}

export type RoleSettingsModalOutletContext = {
  object: KnackObject;
};

export function CopyRoleDialog() {
  const { id } = useParams();
  const { getObjectByKey } = useObjectHelpers();

  if (!id) return null;

  const object = getObjectByKey(id as KnackObject['key']);

  if (!object || object.key !== id) return null;

  return <CopyRoleDialogContent defaultTable={object} />;
}
