import { useEffect, useRef } from 'react';
import LogRocket from 'logrocket';

import { type BuilderApplication } from '@/types/schema/BuilderApplication';
import { type Session } from '@/types/session';
import { shouldSendAnalytics } from '@/utils/analytics';
import { isRudderStackEnabled, rudderStackAnalytics } from '@/utils/rudderstack';

interface ThirdPartyIntegrationsProps {
  session: Session;
  application: BuilderApplication;
  children: React.ReactNode;
}

export function ThirdPartyIntegrations({
  session,
  application,
  children
}: ThirdPartyIntegrationsProps) {
  const hasInitializedIntegrations = useRef(false);

  useEffect(() => {
    const initializeThirdPartyIntegrations = async () => {
      if (shouldSendAnalytics(session.user.email)) {
        await rudderStackAnalytics.identify(session.user.id);
      }

      if (import.meta.env.PUBLIC_LOGROCKET_APP_ID && !application.account.isHipaa) {
        LogRocket.identify(session.user.id, {
          name: `${session.user.firstName} ${session.user.lastName}`,
          email: session.user.email,
          account_id: application.account.id,
          plan_id: application.account.productPlan.id
        });
      }
    };

    if (session.user.id !== '') {
      if (!hasInitializedIntegrations.current) {
        void initializeThirdPartyIntegrations();
        hasInitializedIntegrations.current = true;
      }
    } else if (isRudderStackEnabled()) {
      void rudderStackAnalytics.reset();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session]);

  return children;
}
