import { type IconName, type IconPack, type IconPrefix } from '@fortawesome/fontawesome-svg-core';

type IconMapping = {
  newName: string;
  newPrefix: string;
};

const legacyToNewIconMapping: Record<string, IconMapping> = {
  '500px': { newName: '500px', newPrefix: 'fab' },
  'address-book-o': { newName: 'address-book', newPrefix: 'far' },
  'address-card-o': { newName: 'address-card', newPrefix: 'far' },
  adn: { newName: 'adn', newPrefix: 'fab' },
  amazon: { newName: 'amazon', newPrefix: 'fab' },
  android: { newName: 'android', newPrefix: 'fab' },
  angellist: { newName: 'angellist', newPrefix: 'fab' },
  apple: { newName: 'apple', newPrefix: 'fab' },
  'area-chart': { newName: 'chart-area', newPrefix: 'fas' },
  'arrow-circle-o-down': { newName: 'circle-down', newPrefix: 'far' },
  'arrow-circle-o-left': { newName: 'circle-left', newPrefix: 'far' },
  'arrow-circle-o-right': { newName: 'circle-right', newPrefix: 'far' },
  'arrow-circle-o-up': { newName: 'circle-up', newPrefix: 'far' },
  arrows: { newName: 'up-down-left-right', newPrefix: 'fas' },
  'arrows-alt': { newName: 'maximize', newPrefix: 'fas' },
  'arrows-h': { newName: 'left-right', newPrefix: 'fas' },
  'arrows-v': { newName: 'up-down', newPrefix: 'fas' },
  'asl-interpreting': { newName: 'hands-asl-interpreting', newPrefix: 'fas' },
  automobile: { newName: 'car', newPrefix: 'fas' },
  bandcamp: { newName: 'bandcamp', newPrefix: 'fab' },
  bank: { newName: 'bank', newPrefix: 'fas' },
  'bar-chart': { newName: 'chart-bar', newPrefix: 'far' },
  'bar-chart-o': { newName: 'chart-bar', newPrefix: 'far' },
  bathtub: { newName: 'bath', newPrefix: 'fas' },
  battery: { newName: 'battery-full', newPrefix: 'fas' },
  'battery-0': { newName: 'battery-empty', newPrefix: 'fas' },
  'battery-1': { newName: 'battery-quarter', newPrefix: 'fas' },
  'battery-2': { newName: 'battery-half', newPrefix: 'fas' },
  'battery-3': { newName: 'battery-three-quarters', newPrefix: 'fas' },
  'battery-4': { newName: 'battery-full', newPrefix: 'fas' },
  behance: { newName: 'behance', newPrefix: 'fab' },
  'behance-square': { newName: 'behance-square', newPrefix: 'fab' },
  'bell-o': { newName: 'bell', newPrefix: 'far' },
  'bell-slash-o': { newName: 'bell-slash', newPrefix: 'far' },
  bitbucket: { newName: 'bitbucket', newPrefix: 'fab' },
  'bitbucket-square': { newName: 'bitbucket', newPrefix: 'fab' },
  bitcoin: { newName: 'btc', newPrefix: 'fab' },
  'black-tie': { newName: 'black-tie', newPrefix: 'fab' },
  bluetooth: { newName: 'bluetooth', newPrefix: 'fab' },
  'bluetooth-b': { newName: 'bluetooth-b', newPrefix: 'fab' },
  'bookmark-o': { newName: 'bookmark', newPrefix: 'far' },
  btc: { newName: 'btc', newPrefix: 'fab' },
  'building-o': { newName: 'building', newPrefix: 'far' },
  buysellads: { newName: 'buysellads', newPrefix: 'fab' },
  cab: { newName: 'taxi', newPrefix: 'fas' },
  calendar: { newName: 'calendar-days', newPrefix: 'fas' },
  'calendar-check-o': { newName: 'calendar-check', newPrefix: 'far' },
  'calendar-minus-o': { newName: 'calendar-minus', newPrefix: 'far' },
  'calendar-o': { newName: 'calendar', newPrefix: 'far' },
  'calendar-plus-o': { newName: 'calendar-plus', newPrefix: 'far' },
  'calendar-times-o': { newName: 'calendar-xmark', newPrefix: 'far' },
  'caret-square-o-down': { newName: 'square-caret-down', newPrefix: 'far' },
  'caret-square-o-left': { newName: 'square-caret-left', newPrefix: 'far' },
  'caret-square-o-right': { newName: 'square-caret-right', newPrefix: 'far' },
  'caret-square-o-up': { newName: 'square-caret-up', newPrefix: 'far' },
  cc: { newName: 'closed-captioning', newPrefix: 'far' },
  'cc-amex': { newName: 'cc-amex', newPrefix: 'fab' },
  'cc-diners-club': { newName: 'cc-diners-club', newPrefix: 'fab' },
  'cc-discover': { newName: 'cc-discover', newPrefix: 'fab' },
  'cc-jcb': { newName: 'cc-jcb', newPrefix: 'fab' },
  'cc-mastercard': { newName: 'cc-mastercard', newPrefix: 'fab' },
  'cc-paypal': { newName: 'cc-paypal', newPrefix: 'fab' },
  'cc-stripe': { newName: 'cc-stripe', newPrefix: 'fab' },
  'cc-visa': { newName: 'cc-visa', newPrefix: 'fab' },
  chain: { newName: 'link', newPrefix: 'fas' },
  'chain-broken': { newName: 'link-slash', newPrefix: 'fas' },
  'check-circle-o': { newName: 'circle-check', newPrefix: 'far' },
  'check-square-o': { newName: 'square-check', newPrefix: 'far' },
  chrome: { newName: 'chrome', newPrefix: 'fab' },
  'circle-o': { newName: 'circle', newPrefix: 'far' },
  'circle-o-notch': { newName: 'circle-notch', newPrefix: 'fas' },
  'circle-thin': { newName: 'circle', newPrefix: 'far' },
  clipboard: { newName: 'clipboard', newPrefix: 'far' },
  'clock-o': { newName: 'clock', newPrefix: 'far' },
  clone: { newName: 'clone', newPrefix: 'far' },
  close: { newName: 'xmark', newPrefix: 'fas' },
  'cloud-download': { newName: 'cloud-arrow-down', newPrefix: 'fas' },
  'cloud-upload': { newName: 'cloud-arrow-up', newPrefix: 'fas' },
  cny: { newName: 'yen-sign', newPrefix: 'fas' },
  'code-fork': { newName: 'code-branch', newPrefix: 'fas' },
  codepen: { newName: 'codepen', newPrefix: 'fab' },
  codiepie: { newName: 'codiepie', newPrefix: 'fab' },
  'comment-o': { newName: 'comment', newPrefix: 'far' },
  commenting: { newName: 'comment-dots', newPrefix: 'fas' },
  'commenting-o': { newName: 'comment-dots', newPrefix: 'far' },
  'comments-o': { newName: 'comments', newPrefix: 'far' },
  compass: { newName: 'compass', newPrefix: 'far' },
  compress: { newName: 'down-left-and-up-right-to-center', newPrefix: 'fas' },
  connectdevelop: { newName: 'connectdevelop', newPrefix: 'fab' },
  contao: { newName: 'contao', newPrefix: 'fab' },
  copyright: { newName: 'copyright', newPrefix: 'far' },
  'creative-commons': { newName: 'creative-commons', newPrefix: 'fab' },
  'credit-card': { newName: 'credit-card', newPrefix: 'far' },
  'credit-card-alt': { newName: 'credit-card', newPrefix: 'fas' },
  css3: { newName: 'css3', newPrefix: 'fab' },
  cutlery: { newName: 'utensils', newPrefix: 'fas' },
  dashboard: { newName: 'gauge', newPrefix: 'fas' },
  dashcube: { newName: 'dashcube', newPrefix: 'fab' },
  deafness: { newName: 'ear-deaf', newPrefix: 'fas' },
  dedent: { newName: 'outdent', newPrefix: 'fas' },
  delicious: { newName: 'delicious', newPrefix: 'fab' },
  deviantart: { newName: 'deviantart', newPrefix: 'fab' },
  diamond: { newName: 'gem', newPrefix: 'far' },
  digg: { newName: 'digg', newPrefix: 'fab' },
  dollar: { newName: 'dollar-sign', newPrefix: 'fas' },
  'dot-circle-o': { newName: 'circle-dot', newPrefix: 'far' },
  dribbble: { newName: 'dribbble', newPrefix: 'fab' },
  'drivers-license': { newName: 'id-card', newPrefix: 'fas' },
  'drivers-license-o': { newName: 'id-card', newPrefix: 'far' },
  dropbox: { newName: 'dropbox', newPrefix: 'fab' },
  drupal: { newName: 'drupal', newPrefix: 'fab' },
  edge: { newName: 'edge', newPrefix: 'fab' },
  eercast: { newName: 'sellcast', newPrefix: 'fab' },
  empire: { newName: 'empire', newPrefix: 'fab' },
  'envelope-o': { newName: 'envelope', newPrefix: 'far' },
  'envelope-open-o': { newName: 'envelope-open', newPrefix: 'far' },
  envira: { newName: 'envira', newPrefix: 'fab' },
  etsy: { newName: 'etsy', newPrefix: 'fab' },
  eur: { newName: 'euro-sign', newPrefix: 'fas' },
  euro: { newName: 'euro-sign', newPrefix: 'fas' },
  exchange: { newName: 'right-left', newPrefix: 'fas' },
  expand: { newName: 'up-right-and-down-left-from-center', newPrefix: 'fas' },
  expeditedssl: { newName: 'expeditedssl', newPrefix: 'fab' },
  'external-link': { newName: 'up-right-from-square', newPrefix: 'fas' },
  'external-link-square': { newName: 'square-up-right', newPrefix: 'fas' },
  eye: { newName: 'eye', newPrefix: 'far' },
  'eye-slash': { newName: 'eye-slash', newPrefix: 'far' },
  eyedropper: { newName: 'eye-dropper', newPrefix: 'fas' },
  fa: { newName: 'font-awesome', newPrefix: 'fab' },
  facebook: { newName: 'facebook-f', newPrefix: 'fab' },
  'facebook-f': { newName: 'facebook-f', newPrefix: 'fab' },
  'facebook-official': { newName: 'facebook', newPrefix: 'fab' },
  'facebook-square': { newName: 'facebook-square', newPrefix: 'fab' },
  feed: { newName: 'rss', newPrefix: 'fas' },
  'file-archive-o': { newName: 'file-zipper', newPrefix: 'far' },
  'file-audio-o': { newName: 'file-audio', newPrefix: 'far' },
  'file-code-o': { newName: 'file-code', newPrefix: 'far' },
  'file-excel-o': { newName: 'file-excel', newPrefix: 'far' },
  'file-image-o': { newName: 'file-image', newPrefix: 'far' },
  'file-movie-o': { newName: 'file-video', newPrefix: 'far' },
  'file-o': { newName: 'file', newPrefix: 'far' },
  'file-pdf-o': { newName: 'file-pdf', newPrefix: 'far' },
  'file-photo-o': { newName: 'file-image', newPrefix: 'far' },
  'file-picture-o': { newName: 'file-image', newPrefix: 'far' },
  'file-powerpoint-o': { newName: 'file-powerpoint', newPrefix: 'far' },
  'file-sound-o': { newName: 'file-audio', newPrefix: 'far' },
  'file-text': { newName: 'file-lines', newPrefix: 'fas' },
  'file-text-o': { newName: 'file-lines', newPrefix: 'far' },
  'file-video-o': { newName: 'file-video', newPrefix: 'far' },
  'file-word-o': { newName: 'file-word', newPrefix: 'far' },
  'file-zip-o': { newName: 'file-zipper', newPrefix: 'far' },
  'files-o': { newName: 'copy', newPrefix: 'far' },
  firefox: { newName: 'firefox', newPrefix: 'fab' },
  'first-order': { newName: 'first-order', newPrefix: 'fab' },
  'flag-o': { newName: 'flag', newPrefix: 'far' },
  flash: { newName: 'bolt', newPrefix: 'fas' },
  flickr: { newName: 'flickr', newPrefix: 'fab' },
  'floppy-o': { newName: 'floppy-disk', newPrefix: 'far' },
  'folder-o': { newName: 'folder', newPrefix: 'far' },
  'folder-open-o': { newName: 'folder-open', newPrefix: 'far' },
  'font-awesome': { newName: 'font-awesome', newPrefix: 'fab' },
  fonticons: { newName: 'fonticons', newPrefix: 'fab' },
  'fort-awesome': { newName: 'fort-awesome', newPrefix: 'fab' },
  forumbee: { newName: 'forumbee', newPrefix: 'fab' },
  foursquare: { newName: 'foursquare', newPrefix: 'fab' },
  'free-code-camp': { newName: 'free-code-camp', newPrefix: 'fab' },
  'frown-o': { newName: 'face-frown', newPrefix: 'far' },
  'futbol-o': { newName: 'futbol', newPrefix: 'far' },
  gbp: { newName: 'sterling-sign', newPrefix: 'fas' },
  ge: { newName: 'empire', newPrefix: 'fab' },
  gear: { newName: 'gear', newPrefix: 'fas' },
  gears: { newName: 'gears', newPrefix: 'fas' },
  'get-pocket': { newName: 'get-pocket', newPrefix: 'fab' },
  gg: { newName: 'gg', newPrefix: 'fab' },
  'gg-circle': { newName: 'gg-circle', newPrefix: 'fab' },
  git: { newName: 'git', newPrefix: 'fab' },
  'git-square': { newName: 'git-square', newPrefix: 'fab' },
  github: { newName: 'github', newPrefix: 'fab' },
  'github-alt': { newName: 'github-alt', newPrefix: 'fab' },
  'github-square': { newName: 'github-square', newPrefix: 'fab' },
  gitlab: { newName: 'gitlab', newPrefix: 'fab' },
  gittip: { newName: 'gratipay', newPrefix: 'fab' },
  glass: { newName: 'martini-glass-empty', newPrefix: 'fas' },
  glide: { newName: 'glide', newPrefix: 'fab' },
  'glide-g': { newName: 'glide-g', newPrefix: 'fab' },
  google: { newName: 'google', newPrefix: 'fab' },
  'google-plus': { newName: 'google-plus-g', newPrefix: 'fab' },
  'google-plus-circle': { newName: 'google-plus', newPrefix: 'fab' },
  'google-plus-official': { newName: 'google-plus', newPrefix: 'fab' },
  'google-plus-square': { newName: 'google-plus-square', newPrefix: 'fab' },
  'google-wallet': { newName: 'google-wallet', newPrefix: 'fab' },
  gratipay: { newName: 'gratipay', newPrefix: 'fab' },
  grav: { newName: 'grav', newPrefix: 'fab' },
  group: { newName: 'users', newPrefix: 'fas' },
  'hacker-news': { newName: 'hacker-news', newPrefix: 'fab' },
  'hand-grab-o': { newName: 'hand-back-fist', newPrefix: 'far' },
  'hand-lizard-o': { newName: 'hand-lizard', newPrefix: 'far' },
  'hand-o-down': { newName: 'hand-point-down', newPrefix: 'far' },
  'hand-o-left': { newName: 'hand-point-left', newPrefix: 'far' },
  'hand-o-right': { newName: 'hand-point-right', newPrefix: 'far' },
  'hand-o-up': { newName: 'hand-point-up', newPrefix: 'far' },
  'hand-paper-o': { newName: 'hand', newPrefix: 'far' },
  'hand-peace-o': { newName: 'hand-peace', newPrefix: 'far' },
  'hand-pointer-o': { newName: 'hand-pointer', newPrefix: 'far' },
  'hand-rock-o': { newName: 'hand-back-fist', newPrefix: 'far' },
  'hand-scissors-o': { newName: 'hand-scissors', newPrefix: 'far' },
  'hand-spock-o': { newName: 'hand-spock', newPrefix: 'far' },
  'hand-stop-o': { newName: 'hand', newPrefix: 'far' },
  'handshake-o': { newName: 'handshake', newPrefix: 'far' },
  'hard-of-hearing': { newName: 'ear-deaf', newPrefix: 'fas' },
  'hdd-o': { newName: 'hard-drive', newPrefix: 'far' },
  header: { newName: 'heading', newPrefix: 'fas' },
  'heart-o': { newName: 'heart', newPrefix: 'far' },
  'hospital-o': { newName: 'hospital', newPrefix: 'far' },
  hotel: { newName: 'bed', newPrefix: 'fas' },
  'hourglass-1': { newName: 'hourglass-start', newPrefix: 'fas' },
  'hourglass-2': { newName: 'hourglass', newPrefix: 'fas' },
  'hourglass-3': { newName: 'hourglass-end', newPrefix: 'fas' },
  'hourglass-o': { newName: 'hourglass', newPrefix: 'far' },
  houzz: { newName: 'houzz', newPrefix: 'fab' },
  html5: { newName: 'html5', newPrefix: 'fab' },
  'id-badge': { newName: 'id-badge', newPrefix: 'far' },
  'id-card-o': { newName: 'id-card', newPrefix: 'far' },
  ils: { newName: 'shekel-sign', newPrefix: 'fas' },
  image: { newName: 'image', newPrefix: 'far' },
  imdb: { newName: 'imdb', newPrefix: 'fab' },
  inr: { newName: 'rupee-sign', newPrefix: 'fas' },
  instagram: { newName: 'instagram', newPrefix: 'fab' },
  institution: { newName: 'building-columns', newPrefix: 'fas' },
  'internet-explorer': { newName: 'internet-explorer', newPrefix: 'fab' },
  intersex: { newName: 'transgender', newPrefix: 'fas' },
  ioxhost: { newName: 'ioxhost', newPrefix: 'fab' },
  joomla: { newName: 'joomla', newPrefix: 'fab' },
  jpy: { newName: 'yen-sign', newPrefix: 'fas' },
  jsfiddle: { newName: 'jsfiddle', newPrefix: 'fab' },
  'keyboard-o': { newName: 'keyboard', newPrefix: 'far' },
  krw: { newName: 'won-sign', newPrefix: 'fas' },
  lastfm: { newName: 'lastfm', newPrefix: 'fab' },
  'lastfm-square': { newName: 'lastfm-square', newPrefix: 'fab' },
  leanpub: { newName: 'leanpub', newPrefix: 'fab' },
  legal: { newName: 'gavel', newPrefix: 'fas' },
  'lemon-o': { newName: 'lemon', newPrefix: 'far' },
  'level-down': { newName: 'turn-down', newPrefix: 'fas' },
  'level-up': { newName: 'turn-up', newPrefix: 'fas' },
  'life-bouy': { newName: 'life-ring', newPrefix: 'far' },
  'life-buoy': { newName: 'life-ring', newPrefix: 'far' },
  'life-ring': { newName: 'life-ring', newPrefix: 'far' },
  'life-saver': { newName: 'life-ring', newPrefix: 'far' },
  'lightbulb-o': { newName: 'lightbulb', newPrefix: 'far' },
  'line-chart': { newName: 'chart-line', newPrefix: 'fas' },
  linkedin: { newName: 'linkedin-in', newPrefix: 'fab' },
  'linkedin-square': { newName: 'linkedin', newPrefix: 'fab' },
  linode: { newName: 'linode', newPrefix: 'fab' },
  linux: { newName: 'linux', newPrefix: 'fab' },
  'list-alt': { newName: 'rectangle-list', newPrefix: 'far' },
  'long-arrow-down': { newName: 'down-long', newPrefix: 'fas' },
  'long-arrow-left': { newName: 'left-long', newPrefix: 'fas' },
  'long-arrow-right': { newName: 'right-long', newPrefix: 'fas' },
  'long-arrow-up': { newName: 'up-long', newPrefix: 'fas' },
  'mail-forward': { newName: 'share', newPrefix: 'fas' },
  'mail-reply': { newName: 'reply', newPrefix: 'fas' },
  'mail-reply-all': { newName: 'reply-all', newPrefix: 'fas' },
  'map-marker': { newName: 'location-dot', newPrefix: 'fas' },
  'map-o': { newName: 'map', newPrefix: 'far' },
  maxcdn: { newName: 'maxcdn', newPrefix: 'fab' },
  meanpath: { newName: 'font-awesome', newPrefix: 'fab' },
  medium: { newName: 'medium', newPrefix: 'fab' },
  meetup: { newName: 'meetup', newPrefix: 'fab' },
  'meh-o': { newName: 'face-meh', newPrefix: 'far' },
  'minus-square-o': { newName: 'square-minus', newPrefix: 'far' },
  mixcloud: { newName: 'mixcloud', newPrefix: 'fab' },
  mobile: { newName: 'mobile-screen-button', newPrefix: 'fas' },
  'mobile-phone': { newName: 'mobile-screen-button', newPrefix: 'fas' },
  modx: { newName: 'modx', newPrefix: 'fab' },
  money: { newName: 'money-bill-1', newPrefix: 'far' },
  'moon-o': { newName: 'moon', newPrefix: 'far' },
  'mortar-board': { newName: 'graduation-cap', newPrefix: 'fas' },
  navicon: { newName: 'bars', newPrefix: 'fas' },
  'newspaper-o': { newName: 'newspaper', newPrefix: 'far' },
  'object-group': { newName: 'object-group', newPrefix: 'far' },
  'object-ungroup': { newName: 'object-ungroup', newPrefix: 'far' },
  odnoklassniki: { newName: 'odnoklassniki', newPrefix: 'fab' },
  'odnoklassniki-square': { newName: 'odnoklassniki-square', newPrefix: 'fab' },
  opencart: { newName: 'opencart', newPrefix: 'fab' },
  openid: { newName: 'openid', newPrefix: 'fab' },
  opera: { newName: 'opera', newPrefix: 'fab' },
  'optin-monster': { newName: 'optin-monster', newPrefix: 'fab' },
  pagelines: { newName: 'pagelines', newPrefix: 'fab' },
  'paper-plane-o': { newName: 'paper-plane', newPrefix: 'far' },
  paste: { newName: 'clipboard', newPrefix: 'far' },
  'pause-circle-o': { newName: 'circle-pause', newPrefix: 'far' },
  paypal: { newName: 'paypal', newPrefix: 'fab' },
  pencil: { newName: 'pencil', newPrefix: 'fas' },
  'pencil-square': { newName: 'square-pen', newPrefix: 'fas' },
  'pencil-square-o': { newName: 'pen-to-square', newPrefix: 'far' },
  photo: { newName: 'image', newPrefix: 'far' },
  'picture-o': { newName: 'image', newPrefix: 'far' },
  'pie-chart': { newName: 'chart-pie', newPrefix: 'fas' },
  'pied-piper': { newName: 'pied-piper', newPrefix: 'fab' },
  'pied-piper-alt': { newName: 'pied-piper-alt', newPrefix: 'fab' },
  'pied-piper-pp': { newName: 'pied-piper-pp', newPrefix: 'fab' },
  pinterest: { newName: 'pinterest', newPrefix: 'fab' },
  'pinterest-p': { newName: 'pinterest-p', newPrefix: 'fab' },
  'pinterest-square': { newName: 'pinterest-square', newPrefix: 'fab' },
  'play-circle-o': { newName: 'circle-play', newPrefix: 'far' },
  'plus-square-o': { newName: 'square-plus', newPrefix: 'far' },
  'product-hunt': { newName: 'product-hunt', newPrefix: 'fab' },
  qq: { newName: 'qq', newPrefix: 'fab' },
  'question-circle-o': { newName: 'circle-question', newPrefix: 'far' },
  quora: { newName: 'quora', newPrefix: 'fab' },
  ra: { newName: 'rebel', newPrefix: 'fab' },
  ravelry: { newName: 'ravelry', newPrefix: 'fab' },
  rebel: { newName: 'rebel', newPrefix: 'fab' },
  reddit: { newName: 'reddit', newPrefix: 'fab' },
  'reddit-alien': { newName: 'reddit-alien', newPrefix: 'fab' },
  'reddit-square': { newName: 'reddit-square', newPrefix: 'fab' },
  refresh: { newName: 'arrows-rotate', newPrefix: 'fas' },
  registered: { newName: 'registered', newPrefix: 'far' },
  remove: { newName: 'xmark', newPrefix: 'fas' },
  renren: { newName: 'renren', newPrefix: 'fab' },
  reorder: { newName: 'bars', newPrefix: 'fas' },
  repeat: { newName: 'arrow-rotate-right', newPrefix: 'fas' },
  resistance: { newName: 'rebel', newPrefix: 'fab' },
  rmb: { newName: 'yen-sign', newPrefix: 'fas' },
  'rotate-left': { newName: 'arrow-rotate-left', newPrefix: 'fas' },
  'rotate-right': { newName: 'arrow-rotate-right', newPrefix: 'fas' },
  rouble: { newName: 'ruble-sign', newPrefix: 'fas' },
  rub: { newName: 'ruble-sign', newPrefix: 'fas' },
  ruble: { newName: 'ruble-sign', newPrefix: 'fas' },
  rupee: { newName: 'rupee-sign', newPrefix: 'fas' },
  s15: { newName: 'bath', newPrefix: 'fas' },
  safari: { newName: 'safari', newPrefix: 'fab' },
  scissors: { newName: 'scissors', newPrefix: 'fas' },
  scribd: { newName: 'scribd', newPrefix: 'fab' },
  sellsy: { newName: 'sellsy', newPrefix: 'fab' },
  send: { newName: 'paper-plane', newPrefix: 'fas' },
  'send-o': { newName: 'paper-plane', newPrefix: 'far' },
  'share-square-o': { newName: 'share-from-square', newPrefix: 'far' },
  shekel: { newName: 'shekel-sign', newPrefix: 'fas' },
  sheqel: { newName: 'shekel-sign', newPrefix: 'fas' },
  shield: { newName: 'shield-blank', newPrefix: 'fas' },
  shirtsinbulk: { newName: 'shirtsinbulk', newPrefix: 'fab' },
  'sign-in': { newName: 'right-to-bracket', newPrefix: 'fas' },
  'sign-out': { newName: 'right-from-bracket', newPrefix: 'fas' },
  signing: { newName: 'hands', newPrefix: 'fas' },
  simplybuilt: { newName: 'simplybuilt', newPrefix: 'fab' },
  skyatlas: { newName: 'skyatlas', newPrefix: 'fab' },
  skype: { newName: 'skype', newPrefix: 'fab' },
  slack: { newName: 'slack', newPrefix: 'fab' },
  sliders: { newName: 'sliders', newPrefix: 'fas' },
  slideshare: { newName: 'slideshare', newPrefix: 'fab' },
  'smile-o': { newName: 'face-smile', newPrefix: 'far' },
  snapchat: { newName: 'snapchat', newPrefix: 'fab' },
  'snapchat-ghost': { newName: 'snapchat', newPrefix: 'fab' },
  'snapchat-square': { newName: 'snapchat-square', newPrefix: 'fab' },
  'snowflake-o': { newName: 'snowflake', newPrefix: 'far' },
  'soccer-ball-o': { newName: 'futbol', newPrefix: 'far' },
  'sort-alpha-asc': { newName: 'arrow-down-a-z', newPrefix: 'fas' },
  'sort-alpha-desc': { newName: 'arrow-down-z-a', newPrefix: 'fas' },
  'sort-amount-asc': { newName: 'arrow-down-wide-short', newPrefix: 'fas' },
  'sort-amount-desc': { newName: 'arrow-down-short-wide', newPrefix: 'fas' },
  'sort-asc': { newName: 'sort-up', newPrefix: 'fas' },
  'sort-desc': { newName: 'sort-down', newPrefix: 'fas' },
  'sort-numeric-asc': { newName: 'arrow-down-1-9', newPrefix: 'fas' },
  'sort-numeric-desc': { newName: 'arrow-down-9-1', newPrefix: 'fas' },
  soundcloud: { newName: 'soundcloud', newPrefix: 'fab' },
  spoon: { newName: 'spoon', newPrefix: 'fas' },
  spotify: { newName: 'spotify', newPrefix: 'fab' },
  'square-o': { newName: 'square', newPrefix: 'far' },
  'stack-exchange': { newName: 'stack-exchange', newPrefix: 'fab' },
  'stack-overflow': { newName: 'stack-overflow', newPrefix: 'fab' },
  'star-half-empty': { newName: 'star-half', newPrefix: 'far' },
  'star-half-full': { newName: 'star-half', newPrefix: 'far' },
  'star-half-o': { newName: 'star-half', newPrefix: 'far' },
  'star-o': { newName: 'star', newPrefix: 'far' },
  steam: { newName: 'steam', newPrefix: 'fab' },
  'steam-square': { newName: 'steam-square', newPrefix: 'fab' },
  'sticky-note-o': { newName: 'note-sticky', newPrefix: 'far' },
  'stop-circle-o': { newName: 'circle-stop', newPrefix: 'far' },
  stumbleupon: { newName: 'stumbleupon', newPrefix: 'fab' },
  'stumbleupon-circle': { newName: 'stumbleupon-circle', newPrefix: 'fab' },
  'sun-o': { newName: 'sun', newPrefix: 'far' },
  superpowers: { newName: 'superpowers', newPrefix: 'fab' },
  support: { newName: 'life-ring', newPrefix: 'far' },
  tablet: { newName: 'tablet-screen-button', newPrefix: 'fas' },
  tachometer: { newName: 'gauge', newPrefix: 'fas' },
  telegram: { newName: 'telegram', newPrefix: 'fab' },
  television: { newName: 'tv', newPrefix: 'fas' },
  'tencent-weibo': { newName: 'tencent-weibo', newPrefix: 'fab' },
  themeisle: { newName: 'themeisle', newPrefix: 'fab' },
  thermometer: { newName: 'temperature-full', newPrefix: 'fas' },
  'thermometer-0': { newName: 'temperature-empty', newPrefix: 'fas' },
  'thermometer-1': { newName: 'temperature-quarter', newPrefix: 'fas' },
  'thermometer-2': { newName: 'temperature-half', newPrefix: 'fas' },
  'thermometer-3': { newName: 'temperature-three-quarters', newPrefix: 'fas' },
  'thermometer-4': { newName: 'temperature-full', newPrefix: 'fas' },
  'thumb-tack': { newName: 'thumbtack', newPrefix: 'fas' },
  'thumbs-o-down': { newName: 'thumbs-down', newPrefix: 'far' },
  'thumbs-o-up': { newName: 'thumbs-up', newPrefix: 'far' },
  ticket: { newName: 'ticket-simple', newPrefix: 'fas' },
  'times-circle-o': { newName: 'circle-xmark', newPrefix: 'far' },
  'times-rectangle': { newName: 'rectangle-xmark', newPrefix: 'fas' },
  'times-rectangle-o': { newName: 'rectangle-xmark', newPrefix: 'far' },
  'toggle-down': { newName: 'square-caret-down', newPrefix: 'far' },
  'toggle-left': { newName: 'square-caret-left', newPrefix: 'far' },
  'toggle-right': { newName: 'square-caret-right', newPrefix: 'far' },
  'toggle-up': { newName: 'square-caret-up', newPrefix: 'far' },
  trash: { newName: 'trash-can', newPrefix: 'fas' },
  'trash-o': { newName: 'trash-can', newPrefix: 'far' },
  trello: { newName: 'trello', newPrefix: 'fab' },
  try: { newName: 'lira-sign', newPrefix: 'fas' },
  tumblr: { newName: 'tumblr', newPrefix: 'fab' },
  'tumblr-square': { newName: 'tumblr-square', newPrefix: 'fab' },
  'turkish-lira': { newName: 'lira-sign', newPrefix: 'fas' },
  twitch: { newName: 'twitch', newPrefix: 'fab' },
  twitter: { newName: 'twitter', newPrefix: 'fab' },
  'twitter-square': { newName: 'twitter-square', newPrefix: 'fab' },
  unsorted: { newName: 'sort', newPrefix: 'fas' },
  usb: { newName: 'usb', newPrefix: 'fab' },
  usd: { newName: 'dollar-sign', newPrefix: 'fas' },
  'user-circle-o': { newName: 'circle-user', newPrefix: 'far' },
  'user-o': { newName: 'user', newPrefix: 'far' },
  vcard: { newName: 'address-card', newPrefix: 'fas' },
  'vcard-o': { newName: 'address-card', newPrefix: 'far' },
  viacoin: { newName: 'viacoin', newPrefix: 'fab' },
  viadeo: { newName: 'viadeo', newPrefix: 'fab' },
  'viadeo-square': { newName: 'viadeo-square', newPrefix: 'fab' },
  'video-camera': { newName: 'video', newPrefix: 'fas' },
  vimeo: { newName: 'vimeo-v', newPrefix: 'fab' },
  'vimeo-square': { newName: 'vimeo-square', newPrefix: 'fab' },
  vine: { newName: 'vine', newPrefix: 'fab' },
  vk: { newName: 'vk', newPrefix: 'fab' },
  'volume-control-phone': { newName: 'phone-volume', newPrefix: 'fas' },
  warning: { newName: 'triangle-exclamation', newPrefix: 'fas' },
  wechat: { newName: 'weixin', newPrefix: 'fab' },
  weibo: { newName: 'weibo', newPrefix: 'fab' },
  weixin: { newName: 'weixin', newPrefix: 'fab' },
  whatsapp: { newName: 'whatsapp', newPrefix: 'fab' },
  'wheelchair-alt': { newName: 'accessible-icon', newPrefix: 'fab' },
  'wikipedia-w': { newName: 'wikipedia-w', newPrefix: 'fab' },
  'window-close-o': { newName: 'rectangle-xmark', newPrefix: 'far' },
  'window-maximize': { newName: 'window-maximize', newPrefix: 'far' },
  'window-restore': { newName: 'window-restore', newPrefix: 'far' },
  windows: { newName: 'windows', newPrefix: 'fab' },
  won: { newName: 'won-sign', newPrefix: 'fas' },
  wordpress: { newName: 'wordpress', newPrefix: 'fab' },
  wpbeginner: { newName: 'wpbeginner', newPrefix: 'fab' },
  wpexplorer: { newName: 'wpexplorer', newPrefix: 'fab' },
  wpforms: { newName: 'wpforms', newPrefix: 'fab' },
  xing: { newName: 'xing', newPrefix: 'fab' },
  'xing-square': { newName: 'xing-square', newPrefix: 'fab' },
  'y-combinator': { newName: 'y-combinator', newPrefix: 'fab' },
  'y-combinator-square': { newName: 'hacker-news', newPrefix: 'fab' },
  yahoo: { newName: 'yahoo', newPrefix: 'fab' },
  yc: { newName: 'y-combinator', newPrefix: 'fab' },
  'yc-square': { newName: 'hacker-news', newPrefix: 'fab' },
  yelp: { newName: 'yelp', newPrefix: 'fab' },
  yen: { newName: 'yen-sign', newPrefix: 'fas' },
  yoast: { newName: 'yoast', newPrefix: 'fab' },
  youtube: { newName: 'youtube', newPrefix: 'fab' },
  'youtube-play': { newName: 'youtube', newPrefix: 'fab' },
  'youtube-square': { newName: 'youtube-square', newPrefix: 'fab' }
};

function getIconPrefix(iconName: string) {
  return (iconName.match(/^(fas|far|fab)/)?.[0] as IconPrefix) || 'fas';
}

export function getIconNameWithoutPrefix(iconName: string) {
  return iconName.replace(/^(fa-|fas-|far-|fab-)/, '') as IconName;
}

export function getIconNameParts(iconName: string) {
  const iconPrefix = getIconPrefix(iconName);
  const iconNameWithoutPrefix = getIconNameWithoutPrefix(iconName);

  return [iconPrefix, iconNameWithoutPrefix] as const;
}

export function getIcons(iconPack: IconPack) {
  const iconsWithPrefix: string[] = [];

  Object.values(iconPack).forEach((icon) => {
    const iconWithPrefix = `${icon.prefix}-${icon.iconName}`;
    if (!iconsWithPrefix.includes(iconWithPrefix)) {
      iconsWithPrefix.push(iconWithPrefix);
    }
  });

  return iconsWithPrefix;
}

export function getNormalizedIconName(iconName: string) {
  // If the icon name already starts with 'fas-', 'far-', or 'fab-', it means the prefix is already correct and we don't need to normalize it
  if (/^(fas-|far-|fab-)/.test(iconName)) {
    return iconName;
  }

  const iconNameWithoutPrefix = getIconNameWithoutPrefix(iconName);
  const newIconData = legacyToNewIconMapping[iconNameWithoutPrefix];

  // If the icon name is not in the mapping, we can assume that the icon name is already correct, so we give it the 'fas' prefix
  if (!newIconData) {
    return `fas-${iconNameWithoutPrefix}`;
  }

  return `${newIconData.newPrefix}-${newIconData.newName}`;
}
