import { type TimeFormatValue } from '@/types/schema/fields';
import { type DateTimeRawValue } from '@/components/data-table/display/fields/Field';

export const convertTo24HourFormat = (hours: number, am_pm: 'AM' | 'PM'): number => {
  if (am_pm === 'PM' && hours < 12) {
    return hours + 12;
  }
  if (am_pm === 'AM' && hours === 12) {
    return 0;
  }
  return hours;
};

export const convertTo12HourFormat = (hours: number): { hours: number; am_pm: 'AM' | 'PM' } => {
  if (hours === 0) {
    return { hours: 12, am_pm: 'AM' };
  }
  if (hours >= 12) {
    return { hours: hours > 12 ? hours - 12 : hours, am_pm: 'PM' };
  }
  return { hours, am_pm: 'AM' };
};

export const getNormalizedTimeObject = (
  timeObject: {
    hours?: string | number;
    minutes?: string | number;
    am_pm?: 'AM' | 'PM';
  },
  timeFormat: TimeFormatValue = 'HH MM (military)'
) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  let { hours, minutes, am_pm } = timeObject;
  hours = Number(hours);
  minutes = Number(minutes);

  if (timeFormat === 'HH MM (military)') {
    hours = convertTo24HourFormat(hours, am_pm || 'AM');
  } else if (timeFormat === 'HH:MM am') {
    const timeConversion = convertTo12HourFormat(hours);
    hours = timeConversion.hours;
    am_pm = timeConversion.am_pm;
  }

  const formattedHours = hours < 10 ? `0${hours}` : hours;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  return {
    ...timeObject,
    hours,
    minutes,
    am_pm,
    rawTime: timeFormat !== 'Ignore Time' ? `${formattedHours}:${formattedMinutes}` : ''
  };
};

// When you save a record, you send all the fields, even if they are not edited.
// The server sends us the date as MM/DD/YYYY in the date parameter, but it expects us to send it back the proper format.
// But the server sends us the correct format in the date_formatted parameter, so we can use that to send it back.
const formatDateForUpdating = (dateObj: DateTimeRawValue) =>
  // We only want to apply this over untouched raw values, to do so we use proper_iso_timestamp which is only used by the server
  dateObj.proper_iso_timestamp ? dateObj.date_formatted : dateObj.date;
export function formatKnackDateForUpdating(dateTimeValue: DateTimeRawValue) {
  return {
    ...dateTimeValue,
    date: formatDateForUpdating(dateTimeValue),
    to: dateTimeValue.to
      ? { ...dateTimeValue.to, date: formatDateForUpdating(dateTimeValue.to) }
      : undefined
  };
}
