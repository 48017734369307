import { DateTime } from 'luxon';

import { type DateTimeAttributes } from '@/components/data-table/display/fields/Field';

// IMPORTANT: the transformer is breaking the UTC time zone to make sure the date is not converted to the local time zone
// This is because the server is not handling the time zone correctly
export function KnackDateToJsDateTransformer(knackDate: DateTimeAttributes) {
  if (!knackDate) {
    return null;
  }

  // Instead of converting the variables names to our guidelines, keep it like it come from the server for searchability
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { iso_timestamp, unix_timestamp, date, hours, minutes, am_pm } = knackDate;
  if (iso_timestamp) {
    // In the future we will be able to just do:
    // return DateTime.fromISO(iso_timestamp).toJSDate();

    // The server is sending the date in UTC, but the time zone is not being handled correctly
    // So we want the browser to ignore the time zone
    // So we convert the ISO date to a string forcing the UTC time zone
    // Then we convert the string to a date using the current time zone
    const forcedUTCtoString = DateTime.fromISO(iso_timestamp, { zone: 'utc' }).toFormat(
      'MM/dd/yyyy HH:mm'
    );
    const fromStringToCurrentISODate = DateTime.fromFormat(forcedUTCtoString, 'MM/dd/yyyy HH:mm', {
      // zone: '' //  (Not setting the zone will convert it to the local one)
    }).toJSDate();

    return fromStringToCurrentISODate;
  }

  if (unix_timestamp) {
    return DateTime.fromMillis(unix_timestamp, { zone: 'utc' }).toJSDate();
  }

  // Get the datejs using luxon
  if (date && hours && minutes && am_pm) {
    // The date parameter is in the format MM/dd/yyyy from the server
    const [month, day, year] = date.split('/').map(Number);

    // IMPORTANT: the hours and minutes could be in military format or not. We need to take into consideration the field configuration for this conversion.
    const [hoursNumber, minutesNumber] = [hours, minutes].map(Number);

    const forcedUTCtoString = DateTime.fromObject(
      {
        year,
        month,
        day,
        hour: hoursNumber,
        minute: minutesNumber
      },
      { zone: 'utc' }
    ).toFormat('MM/dd/yyyy HH:mm');

    const fromStringToCurrentISODate = DateTime.fromFormat(forcedUTCtoString, 'MM/dd/yyyy HH:mm', {
      // zone: '' //  (Not setting the zone will convert it to the local one)
    }).toJSDate();

    return fromStringToCurrentISODate;
  }

  return null;
}
