import { type DateTimeFieldRepeatWeekDay } from '@/types/schema/fields/DateTimeField';

export const WEEK_DAYS = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'] as const;

export const transformWeekDayArrayToKnackWeekDay = (
  selectedDays: string[]
): DateTimeFieldRepeatWeekDay => {
  const result = {
    SU: false,
    MO: false,
    TU: false,
    WE: false,
    TH: false,
    FR: false,
    SA: false
  };
  if (!selectedDays) return result;

  WEEK_DAYS.forEach((day) => {
    if (selectedDays.includes(day)) {
      result[day] = true;
    }
  });
  return result;
};

export const transformKnackWeekDayToWeekDaysArray = (
  weekDay: DateTimeFieldRepeatWeekDay
): string[] => {
  const result: string[] = [];

  WEEK_DAYS.forEach((day) => {
    if (weekDay[day]) {
      result.push(day);
    }
  });
  return result;
};
