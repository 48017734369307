import { useTranslation } from 'react-i18next';
import { Divider, Label, RadioGroup, Select, Switch } from '@knack/asterisk-react';

import { type BuilderViewFilterConnectionSources } from '@/types/schema/BuilderView';
import { type ConnectionField } from '@/types/schema/fields';
import { type KnackFilter, type KnackMenuFilter } from '@/types/schema/KnackFilter';
import { type KnackObject } from '@/types/schema/KnackObject';
import { BuilderAccordion } from '@/components/BuilderAccordion';
import { RestrictedConnectionCardGroup } from '@/pages/pages/settings-panel/view-settings/common/filtering/restricted-connections/RestrictedConnectionCardGroup';
import { ViewSettingsRestrictedConnectionsDialog } from '@/pages/pages/settings-panel/view-settings/common/filtering/restricted-connections/RestrictedConnectionsDialog';
import { useRestrictedConnectionHelpers } from '@/pages/pages/settings-panel/view-settings/common/filtering/restricted-connections/useRestrictedConnectionHelpers';
import { ViewSettingsDefaultFiltersDialog } from '@/pages/pages/settings-panel/view-settings/common/filtering/ViewSettingsDefaultFiltersDialog';
import { ViewSettingsMenuFiltersDialog } from '@/pages/pages/settings-panel/view-settings/common/filtering/ViewSettingsMenuFiltersDialog';
import { FiltersGroupsCard } from '@/pages/pages/settings-panel/view-settings/common/FiltersGroupCard';
import { FiltersGroupSingleCard } from '@/pages/pages/settings-panel/view-settings/common/FiltersGroupSingleCard';

export interface ViewSettingsFilteringOption {
  filter_type: 'fields' | 'menu' | 'none';
  filter_fields: 'view' | 'object';
  preset_filters: KnackFilter[];
  menu_filters?: KnackMenuFilter[];
  filter_connection_sources?: BuilderViewFilterConnectionSources;
}

export interface UpdateViewSchemaOptions
  extends Omit<ViewSettingsFilteringOption, 'filter_connection_sources'> {
  filter_connection_sources: BuilderViewFilterConnectionSources | null;
}

interface ViewSettingsFilteringProps {
  sourceObject: KnackObject;
  options: ViewSettingsFilteringOption;
  restrictedConnectionFields?: ConnectionField[];
  updateViewSchema: (options: Partial<UpdateViewSchemaOptions>) => void;
}

export function ViewSettingsFiltering({
  sourceObject,
  options,
  restrictedConnectionFields = [],
  updateViewSchema
}: ViewSettingsFilteringProps) {
  const [t] = useTranslation('translation', {
    keyPrefix: 'pages.element_settings.common.categories.data_display.filtering_section'
  });

  const { getFilterConnectionSources } = useRestrictedConnectionHelpers();

  const shouldAllowFiltering = options.filter_type !== 'none';
  const schemaFilterConnectionSources = options.filter_connection_sources;

  const filterConnectionSources = getFilterConnectionSources(
    restrictedConnectionFields,
    schemaFilterConnectionSources
  );

  const canAddRestrictedConnection = restrictedConnectionFields.length > 0;

  const handleChangeFilterSourceTargetType = (value: 'view' | 'object') => {
    if (filterConnectionSources && canAddRestrictedConnection) {
      updateViewSchema({
        filter_fields: value,
        filter_connection_sources: filterConnectionSources
      });
    } else {
      updateViewSchema({
        filter_fields: value
      });
    }
  };

  return (
    <>
      <div className="mb-2 flex items-center gap-2">
        <Switch
          id="allow-filtering"
          data-testid="allow-filtering-switch"
          checked={shouldAllowFiltering}
          onCheckedChange={(isChecked) => {
            updateViewSchema({
              filter_type: isChecked ? 'fields' : 'none'
            });
          }}
        />
        <Label htmlFor="allow-filtering">{t('allow_filtering')}</Label>
      </div>
      {shouldAllowFiltering && (
        <div>
          <div className="pl-4">
            <RadioGroup
              onValueChange={(value: 'fields' | 'menu') => updateViewSchema({ filter_type: value })}
              value={options.filter_type}
              defaultValue="fields"
            >
              <RadioGroup.Container>
                <RadioGroup.Item value="fields" id="custom-radio" />
                <Label htmlFor="custom-radio">{t('allow_users_to_add_custom_filters')}</Label>
              </RadioGroup.Container>
              <RadioGroup.Container>
                <RadioGroup.Item value="menu" id="menu-radio" />
                <Label htmlFor="menu-radio">{t('enable_preset_filter_links')}</Label>
              </RadioGroup.Container>
            </RadioGroup>
            {options.filter_type === 'menu' ? (
              <div className="my-4">
                <p className="mb-2 text-xs text-emphasis">{t('preset_filters_helper_text')}</p>
                <div className="mb-4">
                  <FiltersGroupSingleCard
                    filtersCriteria={options.menu_filters || []}
                    shouldDisplayLinkTitle
                  />
                </div>
                <div className="flex items-center gap-2">
                  <ViewSettingsMenuFiltersDialog
                    sourceObject={sourceObject}
                    filters={options.menu_filters || []}
                  />
                </div>
              </div>
            ) : (
              <div className="mb-2 mt-4 flex flex-col gap-2">
                <Label>{t('which_fields_can_be_filtered')}</Label>
                <Select
                  value={options.filter_fields}
                  onValueChange={handleChangeFilterSourceTargetType}
                >
                  <Select.Trigger className="w-full" />
                  <Select.Content>
                    <Select.Item value="object">{t('all_fields')}</Select.Item>
                    <Select.Item value="view">{t('only_fields_used_by_this_element')}</Select.Item>
                  </Select.Content>
                </Select>
              </div>
            )}
          </div>
          <BuilderAccordion>
            {options.filter_type === 'fields' && (
              <>
                <Divider className="my-6" />
                <BuilderAccordion.Item isDefaultOpen label={t('set_default_filters')}>
                  {options.preset_filters.length > 0 && (
                    <div className="mb-4">
                      <FiltersGroupsCard
                        filtersCriteria={options.preset_filters}
                        shouldHideEmptyDescription
                      />
                    </div>
                  )}
                  <ViewSettingsDefaultFiltersDialog
                    filters={options.preset_filters}
                    sourceObject={sourceObject}
                  />
                </BuilderAccordion.Item>
              </>
            )}
            <BuilderAccordion.Item isDefaultOpen label={t('restricted_connections.title')}>
              <div className="mb-2">
                {schemaFilterConnectionSources !== null && filterConnectionSources ? (
                  <RestrictedConnectionCardGroup
                    filterConnectionSources={filterConnectionSources}
                  />
                ) : (
                  t('users_can_filter_by_any_connection')
                )}
              </div>
              <ViewSettingsRestrictedConnectionsDialog
                filterConnectionSources={filterConnectionSources}
                restrictedConnectionFields={restrictedConnectionFields}
                canAddRestrictedConnection={canAddRestrictedConnection}
                updateViewSchema={updateViewSchema}
              />
            </BuilderAccordion.Item>
          </BuilderAccordion>
        </div>
      )}
    </>
  );
}
