import { type BaseKnackField } from '@/types/schema/KnackField';

export const MAX_STARS_OPTIONS = ['3', '4', '5'] as const;
export interface RatingField extends BaseKnackField {
  type: 'rating';
  format: {
    method: 'stars';
    allow_half: boolean;
    precision: 2;
    max: (typeof MAX_STARS_OPTIONS)[number];
    min: 1;
  };
}
