import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Divider,
  Input,
  Label,
  MultiSelect,
  RichTextEditor,
  Select,
  Switch,
  type MultiSelectOption
} from '@knack/asterisk-react';
import { z } from 'zod';

import {
  PAGE_RULE_ACTION_TYPES,
  PAGE_RULE_MESSAGE_TYPES,
  type BuilderPageRule
} from '@/types/schema/BuilderPage';
import { type KnackObject } from '@/types/schema/KnackObject';
import { useCriteriaHelpers } from '@/hooks/helpers/useCriteriaHelpers';
import { isDateTimeRangeOperator, shouldHideValueBasedOnOperator } from '@/utils/field-operators';
import { cn } from '@/utils/tailwind';
import { FormErrorMessage } from '@/components/errors/FormErrorMessage';
import { usePageEditorContext } from '@/pages/pages/page-editor/PageEditorContext';
import { PageRuleCriteriaForm } from './PageRuleCriteriaForm';
import { usePageRuleHelpers, type PageRuleCriteriaFieldOption } from './usePageRuleHelpers';

interface PageRuleFormProps {
  pageRule: BuilderPageRule;
  pageRuleCriteriaFieldOptions: PageRuleCriteriaFieldOption[];
  pageSourceObject: KnackObject | undefined;
  formId: string;
  onSubmit: (updatedPageRule: BuilderPageRule) => void;
}

export function PageRuleForm({
  pageRule,
  pageRuleCriteriaFieldOptions,
  pageSourceObject,
  formId,
  onSubmit
}: PageRuleFormProps) {
  const [t] = useTranslation();

  const { page } = usePageEditorContext();
  const { isCriteriaValueEmpty } = useCriteriaHelpers();
  const { getPageRuleLinkablePages } = usePageRuleHelpers();

  const pageRuleFormSchema = z.custom<BuilderPageRule>().superRefine((data, context) => {
    if (data.action === 'message' && !data.message) {
      context.addIssue({
        path: ['message'],
        message: t('errors.value_required'),
        code: 'custom'
      });
    }

    if (data.action === 'url' && !data.url) {
      context.addIssue({
        path: ['url'],
        message: t('errors.value_required'),
        code: 'custom'
      });
    }

    if (data.action === 'existing_page' && !data.existing_page) {
      context.addIssue({
        path: ['existing_page'],
        message: t('errors.value_required'),
        code: 'custom'
      });
    }

    // Validate criteria values
    data.criteria?.forEach((criteria, criteriaIndex) => {
      // Validate criteria value if the value type is not a field and the operator requires a value
      const isValueRequired =
        !shouldHideValueBasedOnOperator(criteria.operator) &&
        !isDateTimeRangeOperator(criteria.operator);

      const selectedCriteriaFieldOption = pageRuleCriteriaFieldOptions.find(
        (fieldOption) => fieldOption.criteriaFieldKey === criteria.field
      );

      const selectedCriteriaField = selectedCriteriaFieldOption?.field;

      if (!selectedCriteriaField) {
        context.addIssue({
          path: [`criteria.${criteriaIndex}.field`],
          message: t('errors.value_required'),
          code: 'custom'
        });
      }

      const isValueEmpty = selectedCriteriaField
        ? isCriteriaValueEmpty(criteria.value, selectedCriteriaField)
        : true;

      if (isValueRequired && isValueEmpty) {
        context.addIssue({
          path: [`criteria.${criteriaIndex}.value`],
          message: t('errors.value_required'),
          code: 'custom'
        });
      }
    });
  });

  const form = useForm<BuilderPageRule>({
    resolver: zodResolver(pageRuleFormSchema),
    defaultValues: pageRule
  });

  const {
    control,
    watch,
    register,
    formState: { errors }
  } = form;

  const { action: currentSelectedActionType } = watch();

  return (
    <FormProvider {...form}>
      <form id={formId} className="w-full" onSubmit={form.handleSubmit(onSubmit)}>
        <div className="mb-4">
          <Label className="mb-2 block font-medium" htmlFor="page-rule-action-type-select">
            {t('components.page_rule_card.edit_modal.action_type')}
          </Label>
          <Controller
            control={control}
            name="action"
            render={({ field: { value: selectedActionType, onChange } }) => (
              <Select value={selectedActionType} onValueChange={onChange}>
                <Select.Trigger
                  id="page-rule-action-type-select"
                  data-testid="page-rule-action-type-select"
                  placeholder={t('actions.select')}
                  className={cn('w-full', {
                    'border-destructive hover:border-destructive focus:border-destructive focus:outline-destructive':
                      errors?.action
                  })}
                />
                <Select.Content>
                  {PAGE_RULE_ACTION_TYPES.map((actionType) => (
                    <Select.Item
                      key={actionType}
                      value={actionType}
                      data-testid={`page-rule-action-${actionType}`}
                    >
                      {t(`components.page_rule_card.actions.${actionType}`)}
                    </Select.Item>
                  ))}
                </Select.Content>
              </Select>
            )}
          />
        </div>

        {currentSelectedActionType === 'message' && (
          <div>
            <p className="mb-2 font-medium">{t('components.page_rule_card.edit_modal.message')}</p>
            <Controller
              control={control}
              name="message"
              render={({ field: { value: message, onChange } }) => (
                <RichTextEditor
                  onUpdate={({ editor }) => {
                    onChange(editor.getText() ? editor.getHTML() : '');
                  }}
                  content={message || ''}
                />
              )}
            />
            <FormErrorMessage name="message" errors={errors} className="mt-1" />

            <Label className="mb-2 mt-4 block font-medium" htmlFor="page-rule-message-type-select">
              {t('components.page_rule_card.edit_modal.message_type')}
            </Label>
            <Controller
              control={control}
              name="type"
              render={({ field: { value: messageType, onChange } }) => (
                <Select value={messageType} onValueChange={onChange}>
                  <Select.Trigger
                    id="page-rule-message-type-select"
                    data-testid="page-rule-message-type-select"
                    placeholder={t('actions.select')}
                    className={cn('w-full', {
                      'border-destructive hover:border-destructive focus:border-destructive focus:outline-destructive':
                        errors?.type
                    })}
                  />
                  <Select.Content className="min-w-[230px]">
                    {PAGE_RULE_MESSAGE_TYPES.map((type) => (
                      <Select.Item key={type} value={type}>
                        {t(`components.page_rule_card.edit_modal.message_types.${type}`)}
                      </Select.Item>
                    ))}
                  </Select.Content>
                </Select>
              )}
            />

            <Label className="mt-4 flex items-center font-medium">
              <Controller
                name="close_link"
                control={control}
                render={({ field: { value: canCloseClick, onChange } }) => (
                  <Switch className="mr-2" checked={canCloseClick} onCheckedChange={onChange} />
                )}
              />
              {t('components.page_rule_card.edit_modal.can_close_message')}
            </Label>
          </div>
        )}

        {currentSelectedActionType === 'hide_views' && (
          <div>
            <p className="mb-2 font-medium">
              {t('components.page_rule_card.edit_modal.select_views_to_hide')}
            </p>
            <Controller
              control={control}
              name="view_keys"
              render={({ field: { value: viewKeys, onChange } }) => {
                const options: MultiSelectOption[] = page.views.map((view) => ({
                  key: view.key,
                  label: view.name || view.key
                }));

                const selectedOptions: MultiSelectOption[] = [];
                viewKeys?.forEach((viewKey) => {
                  const view = page.views.find((v) => v.key === viewKey);
                  if (view) {
                    selectedOptions.push({
                      key: view.key,
                      label: view.name || view.key
                    });
                  }
                });

                return (
                  <MultiSelect
                    id="page-rule-hide-views-multiselect"
                    options={options}
                    selectedOptions={selectedOptions}
                    onSelectOptions={(newSelectedOptions: MultiSelectOption[]) => {
                      onChange(newSelectedOptions.map((option) => option.key));
                    }}
                  />
                );
              }}
            />
            <FormErrorMessage name="view_keys" errors={errors} className="mt-1" />
          </div>
        )}

        {currentSelectedActionType === 'existing_page' && (
          <div>
            <p className="mb-2 font-medium">
              {t('components.page_rule_card.edit_modal.existing_page')}
            </p>
            <Controller
              control={control}
              name="existing_page"
              render={({ field: { value: existingPage, onChange } }) => (
                <Select value={existingPage} onValueChange={onChange}>
                  <Select.Trigger
                    placeholder={t('actions.select')}
                    className={cn('w-full', {
                      'border-destructive hover:border-destructive focus:border-destructive focus:outline-destructive':
                        errors?.existing_page
                    })}
                  />
                  <Select.Content>
                    {getPageRuleLinkablePages(pageSourceObject).map((linkablePage) => (
                      <Select.Item key={linkablePage.slug} value={linkablePage.slug}>
                        {linkablePage.label}
                      </Select.Item>
                    ))}
                  </Select.Content>
                </Select>
              )}
            />
            <FormErrorMessage name="existing_page" errors={errors} className="mt-1" />
          </div>
        )}

        {currentSelectedActionType === 'url' && (
          <div>
            <p className="mb-2 font-medium">
              {t('components.page_rule_card.edit_modal.redirect_url')}
            </p>
            <Input
              intent={errors.url ? 'destructive' : undefined}
              placeholder={t('actions.enter_value')}
              {...register('url')}
            />
            <FormErrorMessage name="url" errors={errors} className="mt-1" />
          </div>
        )}

        <Divider className="my-6" />

        <h3 className="mb-1 font-medium">{t('components.rules.conditions')}</h3>
        <p className="mb-4 text-xs text-subtle">
          {t('components.rules.apply_action_when_conditions_are_met')}
        </p>

        <PageRuleCriteriaForm
          pageRuleCriteriaFieldOptions={pageRuleCriteriaFieldOptions}
          pageSourceObject={pageSourceObject}
        />
      </form>
    </FormProvider>
  );
}
