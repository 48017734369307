import { useTranslation } from 'react-i18next';

import { type KnackObject } from '@/types/schema/KnackObject';
import { useConnectionIdentifiersQuery } from '@/hooks/api/queries/useConnectionIdentifiersQuery';
import { InlineKnackValue } from '@/components/InlineKnackValue';

interface InlineKnackConnectionRecordValueProps {
  objectKey: KnackObject['key'] | undefined;
  recordId: string;
  className?: string;
  dataTestId?: string;
}

export function InlineKnackConnectionRecordValue({
  objectKey,
  recordId,
  className = '',
  dataTestId
}: InlineKnackConnectionRecordValueProps) {
  const [t] = useTranslation();

  const { data: connectionRecord, isLoading } = useConnectionIdentifiersQuery({
    objectKey,
    recordId,
    enabled: !!recordId
  });

  if (isLoading) {
    return (
      <InlineKnackValue
        value={t('keywords.loading')}
        data-testid={dataTestId}
        className={className}
      />
    );
  }

  if (!connectionRecord || connectionRecord.records.length === 0) {
    return null;
  }

  const valueToRender = connectionRecord.records[0].identifier.toString();

  if (!valueToRender) return null;

  return <InlineKnackValue value={valueToRender} data-testid={dataTestId} className={className} />;
}
