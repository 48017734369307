import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  Checkbox,
  Dialog,
  Form,
  Input,
  Label,
  MultiSelect,
  Tabs,
  type MultiSelectOption,
  type TabsListItem
} from '@knack/asterisk-react';
import isEqual from 'lodash/isEqual';
import { z } from 'zod';

import { type BuilderPage, type BuilderPageKey } from '@/types/schema/BuilderPage';
import { usePageMutation } from '@/hooks/api/mutations/usePageMutation';
import { getNormalizedIconName } from '@/utils/font-awesome';
import { KnackFontAwesomeIcon } from '@/components/font-awesome/KnackFontAwesomeIcon';
import { SelectIconModal } from '@/components/SelectIconModal';
import { useLeftPanelContext } from '@/pages/pages/LeftPanelContext';
import { getDropdownMenuOptions } from '@/pages/pages/page-tree/getDropdownMenuOptions';

interface NewFormProps {
  page: BuilderPage;
  onSubmitCallback: () => void;
}

export function DropdownMenuSettings({ page, onSubmitCallback }: NewFormProps) {
  const [t] = useTranslation();
  const { updateDropdownItemsMutation, updateDropdownSettingsMutation } = usePageMutation();
  const { nonUserPages, appSingleLoginPage } = useLeftPanelContext();
  const dropdownMenuOptions = getDropdownMenuOptions({
    allPages: nonUserPages,
    dropdownPageKey: page.key,
    appSingleLoginPage
  });
  const selectedPagesForDropdown = nonUserPages?.filter((item) => item.menuPageKey === page.key);
  const [selectedPages, setSelectedPages] = useState<MultiSelectOption[]>(
    selectedPagesForDropdown?.map((item) => ({
      label: item.name,
      key: item.key,
      value: item.id
    })) || []
  );
  const [isSelectIconModalOpen, setIsSelectIconModalOpen] = useState(false);
  const defaultIconAlignment = 'left';
  const alignItems: TabsListItem[] = [
    {
      children: t('keywords.left'),
      value: 'left'
    },
    {
      children: t('keywords.right'),
      value: 'right'
    }
  ];

  type FormSchema = z.infer<typeof formSchema>;

  const formSchema = z.object({
    pageName: z.string().min(1, t('pages.dialogs.error_page_name_required')),
    pageParentSlug: z.string().optional(),
    ignoreEntrySceneMenu: z.boolean(),
    icon: z
      .object({
        align: z.enum(['left', 'right']),
        icon: z.string()
      })
      .nullable()
  });

  const {
    register,
    handleSubmit,
    formState: { isValid },
    getValues,
    control
  } = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      pageName: page.key ? nonUserPages?.find((item) => item.key === page.key)?.name : '',
      pageParentSlug: '',
      ignoreEntrySceneMenu: !page.ignoreEntrySceneMenu,
      icon: page.icon
    }
  });

  function onSubmit() {
    const menuPages = selectedPages.map((item) => item.key as BuilderPageKey);
    const hasMenuPagesChanged = !isEqual(
      menuPages,
      selectedPagesForDropdown?.map((item) => item.key)
    );
    const hasSettingsChanged =
      page.name !== getValues('pageName') ||
      !isEqual(page.icon, getValues('icon')) ||
      page.ignoreEntrySceneMenu !== !getValues('ignoreEntrySceneMenu');

    if (hasMenuPagesChanged) {
      updateDropdownItemsMutation.mutate(
        {
          pageKey: page.key,
          name: getValues('pageName'),
          menuPages
        },
        {
          onSuccess: () => {
            if (hasSettingsChanged) {
              updateDropdownSettingsMutation.mutate(
                {
                  pageKey: page.key,
                  name: getValues('pageName'),
                  ignoreEntrySceneMenu: !getValues('ignoreEntrySceneMenu'),
                  icon: getValues('icon')
                },
                {
                  onSuccess: () => {
                    onSubmitCallback();
                  }
                }
              );
            } else if (onSubmitCallback) {
              onSubmitCallback();
            }
          }
        }
      );
    } else if (hasSettingsChanged) {
      updateDropdownSettingsMutation.mutate(
        {
          pageKey: page.key,
          name: getValues('pageName'),
          ignoreEntrySceneMenu: !getValues('ignoreEntrySceneMenu'),
          icon: getValues('icon')
        },
        {
          onSuccess: () => {
            if (onSubmitCallback) {
              onSubmitCallback();
            }
          }
        }
      );
    }
  }

  return (
    <Form
      data-testid="dropdown-menu-settings-form"
      onSubmit={handleSubmit(onSubmit)}
      className="mt-2 flex flex-col gap-4"
    >
      <Form.Section>
        <Input
          data-testid="dropdown-menu-settings-input"
          id="dropdown-menu-settings-input"
          {...register('pageName')}
          placeholder={t(`pages.page_tree.new_${page.type}_name`)}
        />
        <div className="my-4">
          <Controller
            name="ignoreEntrySceneMenu"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Label htmlFor="ignore-entry-scene-menu" className="flex items-center">
                <Checkbox
                  className="mr-2"
                  id="ignore-entry-scene-menu"
                  checked={value}
                  onCheckedChange={onChange}
                />
                {t('pages.dialogs.new_menu.include_this_page')}
              </Label>
            )}
          />
        </div>
        <Controller
          name="icon"
          control={control}
          render={({ field: { value, onChange } }) => (
            <div className="mt-4 flex-col items-center gap-2">
              <Label className="mb-2 block font-medium">{t('keywords.icon')}</Label>
              <div className="flex">
                {value?.icon ? (
                  <>
                    <KnackFontAwesomeIcon
                      iconName={getNormalizedIconName(value?.icon)}
                      size="xl"
                      asButton
                      onClick={() => setIsSelectIconModalOpen(true)}
                    />
                    <Button
                      intent="secondary"
                      className="ml-2"
                      aria-label={t('components.icons.remove_icon')}
                      onClick={() => {
                        onChange(null);
                      }}
                    >
                      {t('components.icons.remove_icon')}
                    </Button>
                  </>
                ) : (
                  <Button
                    intent="secondary"
                    aria-label={t('components.icons.select_icon')}
                    onClick={() => setIsSelectIconModalOpen(true)}
                  >
                    {t('components.icons.select_icon')}
                  </Button>
                )}
              </div>
              <div className="mt-4 flex-col items-center gap-2">
                <Label className="mb-2 block font-medium">
                  {t('components.icons.icon_position')}
                </Label>
                <Tabs
                  defaultValue={page.icon?.align || defaultIconAlignment}
                  onValueChange={(newValue) =>
                    onChange({
                      align: newValue as 'left' | 'right',
                      icon: value ? value.icon : ''
                    })
                  }
                >
                  <Tabs.List items={alignItems} shouldDisableResponsive />
                </Tabs>
              </div>
              {isSelectIconModalOpen && (
                <SelectIconModal
                  preSelectedIcon={page.icon?.icon}
                  onClose={() => setIsSelectIconModalOpen(false)}
                  onSubmit={(newSelectedIconName) => {
                    if (newSelectedIconName) {
                      onChange({
                        align: page.icon?.align ?? defaultIconAlignment,
                        icon: newSelectedIconName
                      });
                    }
                    setIsSelectIconModalOpen(false);
                  }}
                />
              )}
            </div>
          )}
        />

        <div className="mt-2">
          <Label>{t('pages.dialogs.new_menu.select_pages')}</Label>
          <div className="mt-2">
            <MultiSelect
              id="new-menu-pages"
              maxVisibleChips={3}
              placeholder={t('pages.page_tree.select_pages')}
              options={dropdownMenuOptions}
              selectedOptions={selectedPages}
              isSearchEnabled
              contentClassName="z-50"
              onSelectOptions={setSelectedPages}
            />
          </div>
        </div>
      </Form.Section>
      <div className="mt-4 flex justify-end gap-2" data-testid="dropdown-menu-settings-footer">
        <Dialog.Close asChild>
          <Button intent="minimal" data-testid="dropdown-menu-settings-button-cancel">
            {t('actions.cancel')}
          </Button>
        </Dialog.Close>
        <Button
          disabled={!isValid}
          type="submit"
          isLoading={
            updateDropdownItemsMutation.isPending || updateDropdownSettingsMutation.isPending
          }
          data-testid="dropdown-menu-settings-submit-button"
        >
          {t('actions.submit')}
        </Button>
      </div>
    </Form>
  );
}
