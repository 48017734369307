import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Label, Textarea } from '@knack/asterisk-react';
import { nanoid } from 'nanoid';

import { removeDuplicatesFromStringArray } from '@/utils/arrays';
import type { MultipleChoiceDraggableOption } from '@/components/field-settings/multiple-choice/FormContent';

type OptionsQuickEditProps = {
  options: MultipleChoiceDraggableOption[];
  setOptions: (options: MultipleChoiceDraggableOption[]) => void;
  onCloseQuickEdit: () => void;
};

export function OptionsQuickEdit({ options, setOptions, onCloseQuickEdit }: OptionsQuickEditProps) {
  const [t] = useTranslation();
  const [text, setText] = useState(() => options.map(({ option }) => option).join('\n'));

  const handleChange = (e) => {
    setText(e.target.value);
  };

  const onSave = () => {
    const parsedArrayList = text.split('\n').filter((option) => !!option);
    const newOptions = removeDuplicatesFromStringArray(parsedArrayList).map((option) => ({
      option,
      id: nanoid(5)
    }));

    setOptions(
      newOptions.length > 0
        ? newOptions
        : [
            {
              id: nanoid(5),
              option: ''
            }
          ]
    );

    onCloseQuickEdit();
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-2">
        <Label htmlFor="quick-edit-textarea">
          {t('components.data_table.attributes.field_settings.multiple_choice.quick_edit_label')}
        </Label>
        <Textarea
          id="quick-edit-textarea"
          rows={text.split('\n').length}
          value={text}
          onChange={handleChange}
          data-testid="quick-edit-textarea"
        />
      </div>
      <div className="flex gap-2 self-end">
        <Button data-testid="quick-edit-cancel-button" intent="minimal" onClick={onCloseQuickEdit}>
          {t('actions.cancel')}
        </Button>
        <Button data-testid="quick-edit-save-button" intent="secondary" onClick={onSave}>
          {t('actions.save')}
        </Button>
      </div>
    </div>
  );
}
