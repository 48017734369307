import { useTranslation } from 'react-i18next';
import { Button } from '@knack/asterisk-react';

import { useTrackEventsHelpers } from '@/hooks/helpers/useTrackEventsHelpers';
import { getChartNameFromType } from '@/pages/pages/settings-panel/view-settings/report/utils';
import { AddRecordViewFlowSteps, useAddRecordViewFlowContext } from './AddRecordViewFlowContext';

export function FlowContinueButton() {
  const [t] = useTranslation();
  const { trackEvent } = useTrackEventsHelpers();
  const {
    currentStep,
    selectedViewSource,
    selectedViewSourcePath,
    selectedViewSubType,
    isAddingChartToReportView,
    onContinue
  } = useAddRecordViewFlowContext();

  if (isAddingChartToReportView && selectedViewSubType) {
    return (
      <Button onClick={() => onContinue()} data-testid="add-chart-modal-save-button">
        {t('pages.element_settings.report.add_new_chart.confirm_add_chart_button', {
          chartName: getChartNameFromType(selectedViewSubType)
        })}
      </Button>
    );
  }

  if (currentStep === AddRecordViewFlowSteps.selectViewSourcePath) {
    const hasMultipleDirectConnections =
      selectedViewSourcePath?.connections && selectedViewSourcePath.connections.direct.length > 1;
    const hasMultipleParentConnections =
      selectedViewSourcePath?.connections && selectedViewSourcePath.connections.parent.length > 1;

    if (hasMultipleDirectConnections || hasMultipleParentConnections) {
      return (
        <Button
          onClick={() => onContinue(AddRecordViewFlowSteps.confirm)}
          data-testid="add-view-modal-save-button"
        >
          {t('actions.continue')}
        </Button>
      );
    }
  }

  if (currentStep === AddRecordViewFlowSteps.confirm && selectedViewSource) {
    return (
      <Button
        onClick={() => {
          trackEvent('add_element', {
            element_type: 'view',
            view_type: selectedViewSubType,
            page_source: selectedViewSource?.paths.some((path) => path.sourceParts.parentObject)
              ? 'parent'
              : 'child'
          });
          onContinue();
        }}
        data-testid="add-view-modal-save-button"
      >
        {t('views.add_view_dialog.confirm_add_view.confirm_button')}
      </Button>
    );
  }

  return null;
}
