import { useFormContext } from 'react-hook-form';
import { DateTimePicker } from '@knack/asterisk-react';
import { type DateRangeWithErrors } from '@knack/asterisk-react/dist/src/components/date-time-picker/DateTimePicker';

import { type TimerField } from '@/types/schema/fields';
import { getKnackDatePickerFormatFromField } from '@/components/knack-date-picker/transformers/DateTimeFormatToLuxonFormat';
import { JsDateToKnackDateTransformer } from '@/components/knack-date-picker/transformers/JsDateToKnackDateTransformer';
import { KnackDateToJsDateTransformer } from '@/components/knack-date-picker/transformers/KnackDateToJsDateTransformer';

export function TimerInput({ field }: { field: TimerField }) {
  const {
    getValues,
    setValue,
    formState: { errors }
  } = useFormContext();

  const rawValue = getValues(field.key);

  const datePickerFormat = getKnackDatePickerFormatFromField(field.format);

  const defaultValue = rawValue.times &&
    rawValue.times.length > 0 && {
      from: KnackDateToJsDateTransformer(rawValue.times[0].from),
      to: KnackDateToJsDateTransformer(rawValue.times[0].to)
    };

  function convertRawValue(newValue: DateRangeWithErrors | undefined) {
    if (!newValue?.from && !newValue?.to) {
      return { times: [] };
    }

    return {
      times: [
        {
          from: JsDateToKnackDateTransformer(newValue?.from, field.format),
          to: JsDateToKnackDateTransformer(newValue?.to, field.format)
        }
      ]
    };
  }

  return (
    <DateTimePicker
      shouldDisableTimeInsideCalendar
      mode="range"
      intent={errors[field.key] ? 'destructive' : 'default'}
      format={datePickerFormat}
      defaultValue={defaultValue}
      onChange={(newValue) => {
        setValue(field.key, convertRawValue(newValue));
      }}
    />
  );
}
