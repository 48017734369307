import i18n from 'i18next';

import { type DateTimeFieldRepeatWeekDay } from '@/types/schema/fields/DateTimeField';

export const defaultDateFormatMap = {
  'dd/mm/yyyy': 'dd/MM/yyyy',
  'mm/dd/yyyy': 'MM/dd/yyyy',
  'M D, yyyy': 'MM/dd/yyyy',
  'Ignore Date': 'none'
} as const;

export interface DateTimePayload {
  date: string;
  time?: string;
  hours?: number;
  minutes?: number;
  am_pm?: 'AM' | 'PM';
  to?: DateTimeTo;
  repeat?: RepeatOptions;
  rawTime?: string;
  all_day?: boolean;
}

export interface DateTimeTo extends Partial<TimeValues> {
  all_day?: boolean;
  date: string;
}

export interface TimeValues {
  hours?: number | string;
  minutes?: number | string;
  am_pm?: 'AM' | 'PM' | '';
  rawTime?: string;
}

type RepeatOptions = {
  frequency: 'daily' | 'weekly' | 'monthly' | 'yearly';
  interval: string;
  repeatby: 'dom' | 'dow'; // day of month | day of week
  endson: 'date' | 'limit' | 'never';
  end_count: string;
  end_date: string;
} & DateTimeFieldRepeatWeekDay;

type SelectPeriodOptionReturnType = ReturnType<typeof selectPeriodOption>;

export type SelectPeriodOption = keyof SelectPeriodOptionReturnType;

// Maps have to be a function because of the i18n.t
export const selectPeriodOption = () =>
  ({
    daily: i18n.t('attributes.field_labels.date_time.repeat_frequency.daily'),
    weekly: i18n.t('attributes.field_labels.date_time.repeat_frequency.weekly'),
    monthly: i18n.t('attributes.field_labels.date_time.repeat_frequency.monthly'),
    yearly: i18n.t('attributes.field_labels.date_time.repeat_frequency.yearly')
  }) as const;

export const weeklyCheckboxOptionsMap = () =>
  ({
    SU: i18n.t('attributes.field_labels.date_time.weekdays.sunday'),
    MO: i18n.t('attributes.field_labels.date_time.weekdays.monday'),
    TU: i18n.t('attributes.field_labels.date_time.weekdays.tuesday'),
    WE: i18n.t('attributes.field_labels.date_time.weekdays.wednesday'),
    TH: i18n.t('attributes.field_labels.date_time.weekdays.thursday'),
    FR: i18n.t('attributes.field_labels.date_time.weekdays.friday'),
    SA: i18n.t('attributes.field_labels.date_time.weekdays.saturday')
  }) as const;

export type DayKey = keyof typeof weeklyCheckboxOptionsMap;
