import { Fragment, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge, Button, Tooltip } from '@knack/asterisk-react';
import snakeCase from 'lodash.snakecase';

import { type KnackField, type KnackFieldKey } from '@/types/schema/KnackField';
import { type KnackObject } from '@/types/schema/KnackObject';
import { type RecordRule } from '@/types/schema/rules/RecordRule';
import { useObjectHelpers } from '@/hooks/helpers/useObjectHelpers';
import { InlineKnackCriteriaValue } from '@/components/InlineKnackCriteriaValue';
import { InlineKnackField } from '@/components/InlineKnackField';
import { EmptyRuleValue } from '@/pages/pages/settings-panel/view-settings/common/EmptyRuleValue';
import { ViewRecordRuleCardConnectionContent } from '@/pages/pages/settings-panel/view-settings/common/record-rules/ViewRecordRuleCardConnectionContent';
import { ViewRecordRuleCardEmailContent } from '@/pages/pages/settings-panel/view-settings/common/record-rules/ViewRecordRuleCardEmailContent';
import { ViewRecordRuleRecordContent } from '@/pages/pages/settings-panel/view-settings/common/record-rules/ViewRecordRuleCardRecordContent';
import { SortableCardWrapper } from '@/pages/pages/settings-panel/view-settings/common/SortableCardWrapper';

interface ViewRecordRuleCardProps {
  recordRule: RecordRule;
  sourceObject: KnackObject;
  sourceObjectFieldsMap: Record<KnackFieldKey, KnackField>;
  ruleNumber: number;
  runEveryTimeText?: string;
  isInvalid?: boolean;
  shouldScrollIntoView?: boolean;
  isDragOverlay?: boolean;
  onRuleEdit: () => void;
  onRuleDuplicate: (displayRuleToDuplicate: RecordRule) => void;
  onRuleDelete: (displayRuleKey: RecordRule['key']) => void;
}

const MAX_NUMBER_OF_CONDITIONS = 4;

export function ViewRecordRuleCard({
  recordRule,
  sourceObject,
  sourceObjectFieldsMap,
  ruleNumber,
  isInvalid = false,
  shouldScrollIntoView = false,
  isDragOverlay = false,
  runEveryTimeText,
  onRuleEdit,
  onRuleDuplicate,
  onRuleDelete
}: ViewRecordRuleCardProps) {
  const [t] = useTranslation();
  const { getConnectionsWithObject } = useObjectHelpers();

  const recordRuleCardRef = useRef<HTMLDivElement | null>(null);

  const sourceObjectConnections = getConnectionsWithObject(sourceObject);

  useEffect(() => {
    if (shouldScrollIntoView && recordRuleCardRef.current) {
      recordRuleCardRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SortableCardWrapper
      ref={recordRuleCardRef}
      sortableItemId={recordRule.key}
      isDragOverlay={isDragOverlay}
      isInactiveState={isInvalid}
      onEditClick={onRuleEdit}
      onDuplicateClick={() => onRuleDuplicate(recordRule)}
      onDeleteClick={() => onRuleDelete(recordRule.key)}
    >
      <div className="mb-2 inline-flex items-center">
        <span className="text-xs font-medium text-emphasis">
          {t('components.record_rule_card.rule_number', {
            number: ruleNumber
          })}
        </span>

        {isInvalid && (
          <Tooltip>
            <Tooltip.Trigger asChild>
              <Badge intent="warning" className="ml-2 cursor-pointer leading-none">
                {t('components.record_rule_card.invalid_rule_badge')}
              </Badge>
            </Tooltip.Trigger>
            <Tooltip.Portal>
              <Tooltip.Content className="max-w-80" side="bottom">
                {t('components.record_rule_card.invalid_rule_tooltip')}
              </Tooltip.Content>
            </Tooltip.Portal>
          </Tooltip>
        )}
      </div>
      {(recordRule.action === 'insert' || recordRule.action === 'connection') && (
        <span className="mb-2 font-medium">
          {recordRule.action === 'insert'
            ? t('components.record_rule_card.actions.insert')
            : t('components.record_rule_card.actions.connection')}
        </span>
      )}
      {!recordRule.criteria.length && (
        <span className="text-xs">{runEveryTimeText || t('components.rules.run_every_time')}</span>
      )}
      {recordRule.criteria.map((criteria, index) => {
        const criteriaField = sourceObjectFieldsMap[criteria.field];
        const isLastCriteria = index === recordRule.criteria.length - 1;
        const isMaxNumberOfConditionsReached = index === MAX_NUMBER_OF_CONDITIONS - 1;

        if (index >= MAX_NUMBER_OF_CONDITIONS) {
          return null;
        }

        if (isMaxNumberOfConditionsReached) {
          return (
            <Button
              key="max-conditions"
              intent="link"
              className="focus:bg-transparent active:bg-transparent"
              onClick={(e) => {
                e.stopPropagation();
                onRuleEdit();
              }}
            >
              + {recordRule.criteria.length + 1 - MAX_NUMBER_OF_CONDITIONS} {t('keywords.more')}
            </Button>
          );
        }

        return (
          // eslint-disable-next-line react/no-array-index-key
          <Fragment key={`${criteria.field}-${index}`}>
            {criteriaField ? (
              <div className="flex flex-wrap items-center gap-1">
                <span>{t('components.rules.when')}</span>
                <InlineKnackField
                  fieldType={criteriaField.type}
                  fieldName={criteriaField.name}
                  className="border-none bg-action"
                />{' '}
                {t(`operators.${snakeCase(criteria.operator)}`)}{' '}
                <InlineKnackCriteriaValue
                  criteria={criteria}
                  criteriaField={criteriaField}
                  className="bg-action"
                />
              </div>
            ) : (
              <EmptyRuleValue />
            )}

            {!isLastCriteria && <div className="my-1">{t('components.rules.and_uppercase')}</div>}
          </Fragment>
        );
      })}
      {!recordRule.action && <EmptyRuleValue />}
      <div className="my-2 flex flex-wrap items-center gap-1">
        {(recordRule.action === 'insert' || recordRule.action === 'connection') && (
          <ViewRecordRuleCardConnectionContent
            recordRule={recordRule}
            sourceObject={sourceObject}
          />
        )}
        {(recordRule.action === 'email' || recordRule.action === 'email_notification') && (
          <ViewRecordRuleCardEmailContent
            recordRule={recordRule}
            sourceObject={sourceObject}
            openRuleEditModal={onRuleEdit}
          />
        )}
        {recordRule.action === 'record' && (
          <ViewRecordRuleRecordContent
            recordRule={recordRule}
            sourceObjectConnections={sourceObjectConnections}
            sourceObjectFieldsMap={sourceObjectFieldsMap}
          />
        )}
      </div>
    </SortableCardWrapper>
  );
}
