import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input, InputPassword } from '@knack/asterisk-react';

import { FormErrorMessage } from '@/components/errors/FormErrorMessage';

interface PasswordInputProps {
  id: string;
  name: string;
}

export function PasswordInput({ id, name }: PasswordInputProps) {
  const [t] = useTranslation();

  const {
    register,
    getFieldState,
    formState: { errors, dirtyFields }
  } = useFormContext();

  const isPasswordInputDirty = dirtyFields?.[name]?.password;
  const isConfirmPasswordInputDirty = dirtyFields?.[name]?.password_confirmation;
  const hasPasswordError = getFieldState(`${name}.password`).invalid;
  const hasConfirmPasswordError = getFieldState(`${name}.password_confirmation`).invalid;

  const DynamicPasswordInput = isPasswordInputDirty ? InputPassword : Input;
  const DynamicConfirmPasswordInput = isConfirmPasswordInputDirty ? InputPassword : Input;

  return (
    <div className="space-y-2">
      <DynamicPasswordInput
        id={id}
        placeholder={t('attributes.field_types.password')}
        data-testid="password-input"
        className="text-sm"
        hasError={hasPasswordError}
        {...register(`${name}.password`)}
        {...(isPasswordInputDirty && { autoFocus: true })}
      />
      <DynamicConfirmPasswordInput
        id="confirm-password-input"
        placeholder={t('components.data_table.password_field.confirm_password')}
        data-testid="confirm-password-input"
        className="text-sm"
        hasError={hasConfirmPasswordError}
        {...register(`${name}.password_confirmation`)}
        {...(isConfirmPasswordInputDirty && { autoFocus: true })}
      />

      <FormErrorMessage name={`${name}.password_confirmation`} errors={errors} className="mt-1" />
    </div>
  );
}
