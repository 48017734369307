import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DateTimePicker, Divider, Label, Switch } from '@knack/asterisk-react';
import { DateTime } from 'luxon';
import { z } from 'zod';

import {
  DATE_FORMAT_OPTIONS,
  DEFAULT_DATE_TYPE_OPTIONS,
  DEFAULT_TIME_TYPE_OPTIONS,
  TIME_FORMAT_OPTIONS,
  type DateTimeField
} from '@/types/schema/fields';
import { getNormalizedDefaultTimeFormat } from '@/components/data-table/helpers/getDefaultValueFromField';
import { DateFormatSettings } from '@/components/field-settings/date-time/components/DateFormatSettings';
import { IncludeDateTimeSwitch } from '@/components/field-settings/date-time/components/IncludeDateTimeSwitch';
import { TimeFormatSettings } from '@/components/field-settings/date-time/components/TimeFormatSettings';
import {
  DEFAULT_LUXON_DATE,
  getLuxonDateFormatFromFieldFormat,
  getLuxonTimeFormatFromFieldFormat
} from '@/components/knack-date-picker/transformers/DateTimeFormatToLuxonFormat';
import { isDate } from '@/components/knack-date-picker/transformers/JsDateToKnackDateTransformer';

type DateTimeFormSettingsProps = {
  field: DateTimeField;
};

export const dateTimeSchema = z.object({
  format: z.object({
    date_format: z.enum(DATE_FORMAT_OPTIONS).default('mm/dd/yyyy'),
    time_format: z.enum(TIME_FORMAT_OPTIONS).default('HH:MM am'),
    calendar: z.boolean().optional(),
    default_date: z.string().optional(),
    default_time: z.string().optional(),
    default_type: z.enum(DEFAULT_DATE_TYPE_OPTIONS).optional(),
    time_type: z.enum(DEFAULT_TIME_TYPE_OPTIONS).optional()
  })
});

export function DateTimeFormSettings({ field }: DateTimeFormSettingsProps) {
  const [t] = useTranslation();
  const { watch, setValue, control } = useFormContext<DateTimeField>();

  const timeFormat: (typeof TIME_FORMAT_OPTIONS)[number] =
    watch('format.time_format') || 'HH:MM am';

  const dateFormat: (typeof DATE_FORMAT_OPTIONS)[number] =
    watch('format.date_format') || 'mm/dd/yyyy';

  // The server expects the date in the format mm/dd/yyyy if the date format is M D, yyyy
  const dateFormatCorrected: (typeof DATE_FORMAT_OPTIONS)[number] =
    dateFormat === 'M D, yyyy' ? 'mm/dd/yyyy' : dateFormat;

  const isSpecificDefaultDateOptionSelected = watch('format.default_type') === 'date';
  const isSpecificDefaultTimeOptionSelected = watch('format.time_type') === 'time';

  const isIgnoreDateSelected = dateFormatCorrected === 'Ignore Date';
  const isIgnoreTimeSelected = timeFormat === 'Ignore Time';

  const [defaultValidDate, defaultValidTime] = watch([
    'format.default_date',
    'format.default_time'
  ]);

  // The server expects the time in the format hh:mma if the time format is HH:MM am
  // Also the V3 saves the value without leading zeros, so we need to adjust the format
  const timeFormatCorrected =
    (defaultValidTime && getNormalizedDefaultTimeFormat(defaultValidTime, field.format)) || 'h:mma';

  useEffect(() => {
    // if  date format is Ignore Date, 'format.calendar' needs to be set to false to avoid invalid numeric representation
    // in the live app UI
    if (isIgnoreDateSelected) {
      setValue('format.calendar', false);
    }
  }, [isIgnoreDateSelected, setValue, watch]);

  return (
    <>
      <div className="flex flex-col gap-4">
        <IncludeDateTimeSwitch field={field} type="date" />

        {!isIgnoreDateSelected && (
          <div className="flex flex-col gap-2">
            <DateFormatSettings field={field} />

            {isSpecificDefaultDateOptionSelected && (
              <div>
                <Controller
                  name="format.default_date"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <DateTimePicker
                      format={{
                        date: getLuxonDateFormatFromFieldFormat(dateFormatCorrected)
                      }}
                      mode="single"
                      intent={!defaultValidDate ? 'destructive' : 'default'}
                      defaultValue={
                        field?.format?.default_date
                          ? DateTime.fromFormat(
                              field?.format?.default_date,
                              getLuxonDateFormatFromFieldFormat(dateFormatCorrected) ||
                                DEFAULT_LUXON_DATE
                            ).toJSDate()
                          : undefined
                      }
                      onChange={(value) => {
                        if (isDate(value)) {
                          const formattedDate = DateTime.fromJSDate(value).toFormat(
                            getLuxonDateFormatFromFieldFormat(dateFormatCorrected) ||
                              DEFAULT_LUXON_DATE
                          );
                          onChange(formattedDate);
                        } else {
                          onChange('');
                        }
                      }}
                    />
                  )}
                />
                {!defaultValidDate && (
                  <p
                    className="py-1 text-destructive"
                    data-testid="default-date-form-settings-error"
                  >
                    {t('components.data_table.errors.no_valid_field', { field: 'date' })}
                  </p>
                )}
              </div>
            )}

            <div className="mt-2 flex items-center">
              <Controller
                name="format.calendar"
                defaultValue={field?.format?.calendar || false}
                render={({ field: { onChange, value } }) => (
                  <Switch
                    id="enable-repeat-option-and-end-date-switch"
                    className="mr-2"
                    data-testid="enable-repeat-option-and-end-date-switch"
                    checked={value}
                    onCheckedChange={onChange}
                  />
                )}
              />
              <Label htmlFor="enable-repeat-option-and-end-date-switch" className="font-medium">
                {t(
                  'components.data_table.attributes.field_settings.date_time.include_repeat_and_end_date'
                )}
              </Label>
            </div>
          </div>
        )}
      </div>

      <Divider className="my-2" />

      <div className="flex flex-col gap-4">
        <IncludeDateTimeSwitch field={field} type="time" />

        {!isIgnoreTimeSelected && (
          <div className="flex flex-col gap-2">
            <TimeFormatSettings field={field} />

            {isSpecificDefaultTimeOptionSelected && (
              <div>
                <Controller
                  name="format.default_time"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <DateTimePicker
                      format={{
                        time: getLuxonTimeFormatFromFieldFormat(timeFormat)
                      }}
                      mode="single"
                      intent={!defaultValidTime ? 'destructive' : 'default'}
                      defaultValue={
                        field?.format?.default_time
                          ? DateTime.fromFormat(
                              field?.format?.default_time,
                              timeFormatCorrected
                            ).toJSDate()
                          : undefined
                      }
                      onChange={(value) => {
                        if (isDate(value)) {
                          onChange(
                            DateTime.fromJSDate(value).toFormat(
                              // IMPORTANT: in this field the AM/PM has no space separation
                              // Also we have stopped saving this field with leading zeros
                              timeFormat === 'HH:MM am' ? 'hh:mma' : 'HH:mm'
                            )
                          );
                        } else {
                          onChange('');
                        }
                      }}
                    />
                  )}
                />
                {!defaultValidTime && (
                  <p
                    className="py-1 text-destructive"
                    data-testid="default-date-form-settings-error"
                  >
                    {t('components.data_table.errors.no_valid_field', { field: 'time' })}
                  </p>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}
