import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { nanoid } from 'nanoid';
import { z, type IssueData } from 'zod';

import { type BuilderPage } from '@/types/schema/BuilderPage';
import {
  type KnackField,
  type KnackFieldKey,
  type KnackFieldType
} from '@/types/schema/KnackField';
import { type KnackConnectionWithObject, type KnackObject } from '@/types/schema/KnackObject';
import {
  RECORD_RULE_VALUE_TYPES,
  type RecordRule,
  type RecordRuleActionType,
  type RecordRuleConnectionKey,
  type RecordRuleValue,
  type RecordRuleValueType
} from '@/types/schema/rules/RecordRule';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { useCriteriaHelpers } from '@/hooks/helpers/useCriteriaHelpers';
import { useEmailTemplateHelpers } from '@/hooks/helpers/useEmailTemplatesHelpers';
import { useFieldHelpers } from '@/hooks/helpers/useFieldHelpers';
import { useObjectHelpers } from '@/hooks/helpers/useObjectHelpers';
import { useSession } from '@/hooks/useSession';

export type RecordRuleConnectedValueOption = {
  label: string;
  value: `${KnackFieldKey}-${KnackFieldKey}`;
};

export type RecordRuleRecordValueFieldOption = {
  label: string;
  value: KnackFieldKey;
  type: KnackFieldType;
};

export function useRecordRuleHelpers() {
  const [t] = useTranslation();

  const session = useSession();
  const { data: application } = useApplicationQuery();
  const { getObjectByKey, getMutableFields, hasRoleObjects } = useObjectHelpers();
  const { getFieldByKey } = useFieldHelpers();
  const { getTemplateWithKeyVariables } = useEmailTemplateHelpers();
  const { validateCriteriaValues } = useCriteriaHelpers();

  const getRecordRuleFormSchema = (sourceObject: KnackObject, shouldIgnoreCriteria?: boolean) =>
    z.custom<RecordRule>().superRefine((data, context) => {
      const addIssueToErrorContext = (
        path: IssueData['path'],
        message: string | undefined = 'errors.value_required'
      ) => {
        context.addIssue({
          path,
          message: t(message),
          code: 'custom'
        });
      };

      if (!shouldIgnoreCriteria) {
        // Validate criteria values
        const criteriaValueErrors = validateCriteriaValues(data.criteria, sourceObject.fields);

        if (criteriaValueErrors.length) {
          criteriaValueErrors.forEach((error) => {
            addIssueToErrorContext(error.path, error.message);
          });
        }
      }

      if (data.action === 'email') {
        // Ensure there is a value for the sender's email
        if (!data.email?.from_email) {
          addIssueToErrorContext(['email.from_email']);
        }

        // Check email validity
        if (data.email?.from_email) {
          const isValidEmail = z.string().email().safeParse(data.email.from_email);

          if (!isValidEmail.success) {
            addIssueToErrorContext(['email.from_email'], 'errors.invalid_email');
          }
        }

        // Validate email subject
        if (!data.email?.subject) {
          addIssueToErrorContext(['email.subject']);
        }

        // Transform email subjects and email message from {field name} to {field_key} e.g {First Name} to {field_1}
        if (data.email?.subject) {
          data.email.subject = getTemplateWithKeyVariables(data.email.subject, sourceObject.fields);
        }
        if (data.email?.message) {
          data.email.message = getTemplateWithKeyVariables(data.email.message, sourceObject.fields);
        }
      }

      // Validate email recipients for both 'email' and 'email_notification' actions
      if (data.action === 'email' || data.action === 'email_notification') {
        data.email?.recipients.forEach((recipient, recipientIndex) => {
          if (recipient.recipient_type === 'field' && !recipient.field) {
            addIssueToErrorContext([`email.recipients.${recipientIndex}.field`]);
          }

          if (!recipient.recipient_type || recipient.recipient_type === 'custom') {
            // Ensure there is a value for the recipient email
            if (!recipient.email) {
              addIssueToErrorContext([`email.recipients.${recipientIndex}.email`]);
            }

            // Check email validity
            const isValidEmail = z.string().email().safeParse(recipient.email);
            if (!isValidEmail.success) {
              addIssueToErrorContext(
                [`email.recipients.${recipientIndex}.email`],
                'errors.invalid_email'
              );
            }
          }
        });
      }

      // Validate record rule values
      if (data.action !== 'email' && data.values.length !== 0) {
        data.values.forEach((value, valueIndex) => {
          const field = getFieldByKey(value.field);

          if (!field) {
            return;
          }

          if (value.type === 'record' && !value.input) {
            addIssueToErrorContext([`values.${valueIndex}.input`]);
          }

          if (value.type === 'connection' && !value.connection_field) {
            addIssueToErrorContext([`values.${valueIndex}.value`]);
          }
        });
      }
    });

  const getRecordValueTypeAvailableFields = (
    targetField: KnackField,
    availableFields: KnackField[]
  ) => {
    switch (targetField.type) {
      case 'signature':
      case 'address':
      case 'name':
      case 'timer':
      case 'multiple_choice':
      case 'boolean':
        return availableFields.filter(
          (f) => f.type === targetField.type && f.key !== targetField.key
        );
      case 'file':
      case 'image':
        return availableFields.filter(
          (f) => (f.type === 'file' || f.type === 'image') && f.key !== targetField.key
        );
      case 'connection':
        return [targetField];
      default:
        return availableFields;
    }
  };

  // Get the object that will be used to populate the fields that the user can set values for.
  // If the action is 'record', then the source object is the object that the rule is being created for.
  const getRecordRuleValuesObject = useCallback(
    (
      sourceObject: KnackObject,
      action: RecordRuleActionType,
      connectionKey: RecordRuleConnectionKey | undefined
    ) => {
      if (!application || action === 'record' || !connectionKey) {
        return sourceObject;
      }

      const [connectedObjectKey] = connectionKey.split('.');
      const connectedObject = application.objects.find(
        (object) => object.key === connectedObjectKey
      );

      if (!connectedObject) {
        return sourceObject;
      }

      return connectedObject;
    },
    [application]
  );

  // Get the fields that the user can select as the target field when setting values in a record rule
  const getEligibleFieldsAsRecordRuleValueTarget = useCallback(
    (
      ruleConnection: RecordRule['connection'] | undefined,
      ruleAction: RecordRuleActionType,
      ruleValuesObject: KnackObject
    ) => {
      const mutableFields = getMutableFields(ruleValuesObject);

      // If the rule action is for inserting a new connected record, we should exclude the connection field from the list of eligible fields you can set values for
      if (ruleAction === 'insert' && ruleConnection) {
        const ruleConnectionFieldKey = ruleConnection.split('.')[1];
        return mutableFields.filter((mutableField) => ruleConnectionFieldKey !== mutableField.key);
      }

      return mutableFields;
    },
    [getMutableFields]
  );

  // Since record rules allow field values to be set to the value of a different field, this ensures that the fields are compatible
  const isFieldCompatibleWithSelectedFieldForValue = useCallback(
    (field: KnackField, selectedField: KnackField) => {
      switch (selectedField.type) {
        case 'name':
        case 'address':
        case 'boolean':
        case 'signature':
        case 'timer':
        case 'date_time':
        case 'multiple_choice':
          return selectedField.type === field.type;

        case 'connection':
          return (
            selectedField.type === field.type &&
            selectedField.relationship.object === field.relationship.object
          );

        case 'image':
          // Allow links to work with images with sources from URLs
          if (field.type === 'link' && selectedField.format.source === 'url') {
            return true;
          }

          // Otherwise, only allow images to be set to image, file, or concatenation fields
          return field.type === 'image' || field.type === 'file' || field.type === 'concatenation';

        case 'file':
          // Only allow images to be set to image, file, or concatenation fields
          return field.type === 'image' || field.type === 'file' || field.type === 'concatenation';

        default:
          return true;
      }
    },
    []
  );

  // Retrieves the connection options that are eligible to be selected for a record rule's value of type `connection`
  const getRuleConnectedValueOptions = useCallback(
    (
      selectedField: KnackField,
      sourceObject: KnackObject,
      connectionsWithObject: KnackConnectionWithObject[]
    ) => {
      const options: RecordRuleConnectedValueOption[] = [];

      connectionsWithObject.forEach(({ connection, object }) => {
        const isOutgoingConnection = sourceObject.fields.some((f) => f.key === connection.key);
        const isSingleConnection = isOutgoingConnection
          ? connection.has === 'one'
          : connection.belongs_to === 'one';

        if (!isSingleConnection) {
          return;
        }

        object.fields.forEach((field) => {
          if (!isFieldCompatibleWithSelectedFieldForValue(field, selectedField)) {
            return;
          }

          let label = object.name;

          const isDifferentName =
            connection.name !== object.inflections.singular &&
            connection.name !== object.inflections.plural;

          if (isDifferentName) {
            label += ` (${connection.name})`;
          }

          label += ` > ${field.name}`;

          options.push({
            // value has to be like field_204-field_24. The first field is the source object field and the second field is the field in the connected object
            value: `${connection.key}-${field.key}`,
            label
          });
        });
      });

      return options;
    },
    [isFieldCompatibleWithSelectedFieldForValue]
  );

  // Retrieves the fields of the `values` object that are eligible to be selected as the source field for a record rule's value of type `record`
  const getRuleRecordValueFieldOptions = useCallback(
    (selectedField: KnackField, sourceObject: KnackObject) => {
      const options: RecordRuleRecordValueFieldOption[] = [];

      sourceObject.fields.forEach((field) => {
        if (!isFieldCompatibleWithSelectedFieldForValue(field, selectedField)) {
          return;
        }

        // Only add the User Roles option, if roles are available
        if (field.type === 'user_roles' && !hasRoleObjects()) {
          return;
        }

        options.push({
          value: field.key,
          label: field.name,
          type: field.type
        });
      });

      return options;
    },
    [hasRoleObjects, isFieldCompatibleWithSelectedFieldForValue]
  );

  const getRuleValueTypeOptions = useCallback(
    (
      field: KnackField,
      hasConnectedValueOptions: boolean,
      activePage?: BuilderPage,
      allowedRecordRuleValueTypes?: RecordRuleValueType[]
    ) => {
      // Check if the 'user' option (‘to the user's current location’) should be shown as a value type
      const shouldShowRuleUserValueType = () => {
        // If we are not dealing with an active page (e.g. inside the page editor) or the field is not a connection, then we don't show this option
        if (!activePage || field.type !== 'connection') {
          return false;
        }

        const connectionObject = field.relationship.object
          ? getObjectByKey(field.relationship.object)
          : undefined;

        // If the connection object is not found or the connection object is not a user object, then we don't show this option
        if (!connectionObject || !connectionObject.profile_key) {
          return false;
        }

        // If the connection object is a user object that points to the global 'accounts' object, then we show the option
        if (connectionObject.profile_key === 'all_users') {
          return true;
        }

        // Otherwise, we check if the active page allows access for this user role
        return activePage.allowedProfileKeys?.includes(connectionObject.profile_key) || false;
      };

      const allowedRuleValueTypes = allowedRecordRuleValueTypes || [...RECORD_RULE_VALUE_TYPES];
      const ruleValueTypesToShow: RecordRuleValueType[] = [];

      if (allowedRuleValueTypes.includes('value')) {
        ruleValueTypesToShow.push('value');
      }

      if (allowedRuleValueTypes.includes('record')) {
        ruleValueTypesToShow.push('record');
      }

      if (allowedRuleValueTypes.includes('current_date') && field.type === 'date_time') {
        ruleValueTypesToShow.push('current_date');
      }

      if (allowedRuleValueTypes.includes('current_location') && field.type === 'address') {
        ruleValueTypesToShow.push('current_location');
      }

      if (allowedRuleValueTypes.includes('connection') && hasConnectedValueOptions) {
        ruleValueTypesToShow.push('connection');
      }

      if (allowedRuleValueTypes.includes('user') && shouldShowRuleUserValueType()) {
        ruleValueTypesToShow.push('user');
      }

      return ruleValueTypesToShow;
    },
    [getObjectByKey]
  );

  // Get the new default value when changing the value type of the rule
  const getNewDefaultRecordRuleValue = useCallback(
    (
      currentRuleValue: RecordRuleValue,
      newRuleValueType: RecordRuleValue['type'],
      selectedTargetField: KnackField,
      sourceObject: KnackObject,
      ruleConnectedValueOptions: RecordRuleConnectedValueOption[]
    ) => {
      const baseRuleValue = {
        field: selectedTargetField.key,
        value: ''
      } satisfies Partial<RecordRuleValue>;

      switch (newRuleValueType) {
        case 'value':
          return {
            ...baseRuleValue,
            type: 'value'
          } satisfies Partial<RecordRuleValue> as RecordRuleValue;

        case 'record': {
          const recordValueFieldOptions = getRuleRecordValueFieldOptions(
            selectedTargetField,
            sourceObject
          );

          return {
            ...baseRuleValue,
            type: 'record',
            input: recordValueFieldOptions[0].value
          } satisfies Partial<RecordRuleValue> as RecordRuleValue;
        }

        case 'connection': {
          if (ruleConnectedValueOptions.length === 0) {
            return currentRuleValue;
          }

          return {
            ...baseRuleValue,
            type: 'connection',
            connection_field: ruleConnectedValueOptions[0].value
          } satisfies Partial<RecordRuleValue> as RecordRuleValue;
        }

        default:
          return currentRuleValue;
      }
    },
    [getRuleRecordValueFieldOptions]
  );

  // Get the default value for a new record rule
  const getDefaultRecordRuleValue = useCallback(
    (
      ruleConnection: RecordRule['connection'] | undefined,
      ruleAction: RecordRuleActionType,
      ruleValuesObject: KnackObject
    ) => {
      const eligibleValueTargetFields = getEligibleFieldsAsRecordRuleValueTarget(
        ruleConnection,
        ruleAction,
        ruleValuesObject
      );

      if (eligibleValueTargetFields.length === 0) {
        throw new Error('No eligible fields found for record rule value');
      }

      const defaultRecordValue: RecordRuleValue = {
        field: eligibleValueTargetFields[0].key,
        type: 'value',
        value: ''
      };

      return defaultRecordValue;
    },
    [getEligibleFieldsAsRecordRuleValueTarget]
  );

  // Get the default shape for a new record rule
  const getDefaultRecordRule = useCallback(
    (sourceObject: KnackObject) => {
      const defaultRecordRuleValue = getDefaultRecordRuleValue(undefined, 'record', sourceObject);

      const defaultRecordRule: RecordRule = {
        key: `record_${nanoid(10)}`,
        criteria: [],
        action: 'record',
        values: [defaultRecordRuleValue]
      };

      return defaultRecordRule;
    },
    [getDefaultRecordRuleValue]
  );

  const getDefaultEmailRecordRule = useCallback(() => {
    const defaultEmailRecordRule: RecordRule = {
      key: `record_${nanoid(10)}`,
      action: 'email',
      criteria: [],
      values: [],
      email: {
        from_email: session.user.email,
        subject: t('components.rules.record_rules.email.default_subject'),
        message: t('components.rules.record_rules.email.default_message'),
        recipients: [
          {
            recipient_mode: 'to',
            recipient_type: 'custom',
            email: '',
            field: null
          }
        ]
      }
    };
    return defaultEmailRecordRule;
  }, [session.user.email, t]);

  return {
    getRecordRuleFormSchema,
    getDefaultRecordRule,
    getDefaultEmailRecordRule,
    getNewDefaultRecordRuleValue,
    getDefaultRecordRuleValue,
    getRecordRuleValuesObject,
    getEligibleFieldsAsRecordRuleValueTarget,
    getRuleConnectedValueOptions,
    getRuleRecordValueFieldOptions,
    getRuleValueTypeOptions,
    getRecordValueTypeAvailableFields
  };
}
