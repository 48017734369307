import { useMemo, useState } from 'react';
import { useFormContext, type FieldValues, type Path } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Combobox, type ComboboxOption } from '@knack/asterisk-react';
import debounce from 'lodash.debounce';
import get from 'lodash.get';

import { type ConnectionField } from '@/types/schema/fields';
import { type KnackFilter } from '@/types/schema/KnackFilter';
import { useConnectionIdentifiersQuery } from '@/hooks/api/queries/useConnectionIdentifiersQuery';
import { useConnectionRecordsQuery } from '@/hooks/api/queries/useConnectionRecordsQuery';
import { useObjectHelpers } from '@/hooks/helpers/useObjectHelpers';

interface ConnectionFieldInputProps<FormSchema extends FieldValues> {
  field: ConnectionField;
  formFieldName: Path<FormSchema>;
  isDisabled?: boolean;
}

const MAX_RECORDS_COUNT_TO_LOAD = 500;
const DEBOUNCE_TIME = 350;

export function ConnectionFieldInput<FormSchema extends FieldValues>({
  field,
  formFieldName,
  isDisabled
}: ConnectionFieldInputProps<FormSchema>) {
  const [t] = useTranslation();
  const { getObjectByKey } = useObjectHelpers();

  const {
    getValues,
    setValue,
    formState: { errors }
  } = useFormContext();

  const connectionObject = field.relationship.object
    ? getObjectByKey(field.relationship.object)
    : undefined;
  const connectionFieldKey = connectionObject?.identifier || '';
  const initialFormValue: string = getValues(formFieldName)?.[0] || '';

  // To obtain the value of the selected connection identifier, we have to use this hook.
  // If we are editing, the call has been made previously in the sidepanel when loading the filter cards.
  // This means that this call will be cached and will not have an effect on performance.
  const { data: selectedRecord, isLoading: isLoadingIdentifier } = useConnectionIdentifiersQuery({
    objectKey: field.relationship.object,
    enabled: !!initialFormValue,
    recordId: initialFormValue
  });

  const [searchRecordValue, setSearchRecordValue] = useState('');
  const [activeLabelOption, setActiveLabelOption] = useState(
    selectedRecord?.records[0]?.identifier.toString() ?? ''
  );

  const onSearchRecord = useMemo(
    () =>
      debounce((value: string) => {
        setSearchRecordValue(value);
      }, DEBOUNCE_TIME),
    []
  );

  const connectionRecordsFilters: KnackFilter[] =
    connectionFieldKey && !isLoadingIdentifier && searchRecordValue
      ? [
          {
            field: connectionFieldKey,
            operator: 'contains',
            value: searchRecordValue
          }
        ]
      : [];

  const { data: connectionRecords, isPending } = useConnectionRecordsQuery({
    objectKey: field.relationship.object || '',
    options: {
      rowsPerPage: 'all_records',
      limitReturn: false,
      filters: connectionRecordsFilters
    }
  });

  const isRecordLimitReached =
    connectionRecords?.total_records &&
    connectionRecords?.total_records > MAX_RECORDS_COUNT_TO_LOAD;

  function handleChangeValue(option: ComboboxOption) {
    setSearchRecordValue('');
    setValue(formFieldName, [option.key] as any);
    setActiveLabelOption(option.label);
  }

  function getHelperText() {
    if (isRecordLimitReached) {
      return t('components.add_into_existing_table.connections_over_500');
    }

    if (connectionRecords?.records.length === 0) {
      return t('components.add_into_existing_table.no_records_found');
    }

    return undefined;
  }

  const formattedOptions =
    connectionRecords?.records.map((item) => ({
      label: item.identifier?.toString(),
      key: item.id
    })) || [];

  const formattedSelectedOption: ComboboxOption = {
    key: initialFormValue,
    label: activeLabelOption
  };

  return (
    <Combobox
      id={`${field.key}-connection-select`}
      data-testid={`${field.key}-connection-select`}
      options={formattedOptions ?? []}
      selectedOption={formattedSelectedOption}
      description={getHelperText()}
      isSearchEnabled
      onSearchChange={onSearchRecord}
      disabled={isPending || isDisabled}
      intent={get(errors, formFieldName) ? 'destructive' : 'default'}
      onSelectOption={(option) => handleChangeValue(option)}
    />
  );
}
