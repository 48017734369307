import { Fragment, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import snakeCase from 'lodash.snakecase';

import { type FormView, type FormViewSubmitRule } from '@/types/schema/views/FormView';
import { usePageHelpers } from '@/hooks/helpers/usePageHelpers';
import { InlineKnackCriteriaValue } from '@/components/InlineKnackCriteriaValue';
import { InlineKnackField } from '@/components/InlineKnackField';
import { InlineKnackValue } from '@/components/InlineKnackValue';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { SortableCardWrapper } from '@/pages/pages/settings-panel/view-settings/common/SortableCardWrapper';
import { FormSubmitRuleFormDialog } from './FormSubmitRuleFormDialog';

interface FormSubmitRuleCardProps {
  submitRule: FormViewSubmitRule;
  ruleNumber?: number;
  shouldScrollIntoView?: boolean;
  isDragOverlay?: boolean;
  onRuleSave?: (updatedSubmitRule: FormViewSubmitRule) => void;
  onRuleDuplicate?: (submitRuleToDuplicate: FormViewSubmitRule) => void;
  onRuleDelete?: (submitRuleKey: FormViewSubmitRule['key']) => void;
}

export function FormSubmitRuleCard({
  submitRule,
  ruleNumber,
  shouldScrollIntoView,
  isDragOverlay = false,
  onRuleSave,
  onRuleDuplicate,
  onRuleDelete
}: FormSubmitRuleCardProps) {
  const [t] = useTranslation();

  const { sourceObject } = useActiveViewContext<FormView>();
  const { getSelectablePageLabelFromSlug, getExistingChildPageLabelFromSlug } = usePageHelpers();

  const submitRuleCardRef = useRef<HTMLDivElement | null>(null);
  const [isEditRuleModalOpen, setIsEditRuleModalOpen] = useState(false);

  useEffect(() => {
    if (shouldScrollIntoView && submitRuleCardRef.current) {
      submitRuleCardRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <SortableCardWrapper
        ref={submitRuleCardRef}
        sortableItemId={submitRule.key}
        isDragOverlay={isDragOverlay}
        isDraggingAllowed={!submitRule.is_default}
        onEditClick={() => setIsEditRuleModalOpen(true)}
        {...(onRuleDuplicate && { onDuplicateClick: () => onRuleDuplicate(submitRule) })}
        {...(onRuleDelete && { onDeleteClick: () => onRuleDelete(submitRule.key) })}
      >
        <h3 className="mb-2 text-xs font-medium text-emphasis">
          {submitRule.is_default
            ? t('components.submit_rule_card.default_rule')
            : t('components.submit_rule_card.rule_number', {
                number: ruleNumber
              })}
        </h3>

        {!submitRule.is_default && (
          <>
            <span className="mb-2">{t('components.rules.when')}</span>

            {submitRule.criteria?.map((criteria, index) => {
              const criteriaField = sourceObject.fields.find((f) => f.key === criteria.field);
              const isLastCriteria = submitRule.criteria
                ? index === submitRule.criteria.length - 1
                : true;

              const isFieldValue = criteria.value_type === 'field';
              const valueField = isFieldValue
                ? sourceObject.fields.find((f) => f.key === criteria.value_field)
                : undefined;

              return (
                // eslint-disable-next-line react/no-array-index-key
                <Fragment key={`${criteria.field}-${index}`}>
                  {criteriaField ? (
                    <div>
                      <InlineKnackField
                        fieldType={criteriaField.type}
                        fieldName={criteriaField.name}
                        className="border-none bg-action"
                      />{' '}
                      {t(`operators.${snakeCase(criteria.operator)}`)}{' '}
                      {isFieldValue && valueField ? (
                        <InlineKnackField
                          fieldType={valueField.type}
                          fieldName={valueField.name}
                          className="border-none bg-action"
                        />
                      ) : (
                        <InlineKnackCriteriaValue
                          criteria={criteria}
                          criteriaField={criteriaField}
                          className="bg-action"
                        />
                      )}
                    </div>
                  ) : (
                    <InlineKnackValue value="-" className="border-none bg-action" />
                  )}

                  {!isLastCriteria && <div className="my-1">{t('components.rules.and')}</div>}
                </Fragment>
              );
            })}
          </>
        )}

        <div className="mt-2">
          {submitRule.action === 'message' && (
            <div className="flex flex-col items-start gap-1">
              <InlineKnackValue
                value={t('components.submit_rule_card.actions.message')}
                className="border-none bg-action"
              />
              <InlineKnackValue asRichTextRenderer value={submitRule.message} />
              {(submitRule.reload_show || submitRule.reload_auto) && (
                <span className="inline-flex items-center whitespace-pre">
                  {t('components.rules.and')}{' '}
                  <InlineKnackValue
                    value={
                      submitRule.reload_show
                        ? t('components.submit_rule_card.additional_actions.reload_show')
                        : t('components.submit_rule_card.additional_actions.reload_auto')
                    }
                    className="border-none bg-action"
                  />
                </span>
              )}
            </div>
          )}

          {submitRule.action === 'existing_page' && submitRule.existing_page && (
            <div className="flex flex-col items-start gap-1">
              <InlineKnackValue
                value={t('components.submit_rule_card.actions.existing_page')}
                className="border-none bg-action"
              />
              <InlineKnackValue
                value={getSelectablePageLabelFromSlug(sourceObject.key, submitRule.existing_page)}
                className={submitRule.existing_page ? 'underline' : undefined}
              />
            </div>
          )}

          {submitRule.action === 'child_page' && (
            <div className="flex flex-col items-start gap-1">
              <InlineKnackValue
                value={t('components.submit_rule_card.actions.child_page')}
                className="border-none bg-action"
              />
              {typeof submitRule.scene === 'string' ? (
                <InlineKnackValue
                  value={getExistingChildPageLabelFromSlug(submitRule.scene)}
                  className={submitRule.scene ? 'underline' : undefined}
                />
              ) : (
                <InlineKnackValue
                  value={submitRule.scene?.name ?? '-'}
                  className={submitRule.scene ? 'underline' : undefined}
                />
              )}
            </div>
          )}

          {submitRule.action === 'parent_page' && (
            <div className="flex flex-col items-start gap-1">
              <InlineKnackValue
                value={t('components.submit_rule_card.actions.parent_page')}
                className="border-none bg-action"
              />
            </div>
          )}

          {submitRule.action === 'url' && (
            <div className="flex flex-col items-start gap-1">
              <InlineKnackValue
                value={t('components.submit_rule_card.actions.url')}
                className="border-none bg-action"
              />
              <InlineKnackValue
                value={submitRule.url ?? '-'}
                className={submitRule.url ? 'underline' : undefined}
              />
            </div>
          )}
        </div>
      </SortableCardWrapper>

      {isEditRuleModalOpen && (
        <FormSubmitRuleFormDialog
          submitRule={submitRule}
          onOpenChange={setIsEditRuleModalOpen}
          onRuleSave={(updatedRule) => {
            setIsEditRuleModalOpen(false);

            if (onRuleSave) {
              onRuleSave(updatedRule);
            }
          }}
        />
      )}
    </>
  );
}
