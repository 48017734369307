import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  type BuilderApplication,
  type BuilderApplicationSettings
} from '@/types/schema/BuilderApplication';
import { queryKeys } from '@/hooks/api/queryKeys';
import { axiosInstance as axios } from '@/utils/axiosConfig';

// Expand this type as we need more settings properties to be updated
export interface AppSettingsPayload {
  name?: string;
  slug?: string;
  description?: string;
  users?: {
    enabled?: boolean;
    registration?: BuilderApplication['users']['registration'];
    scope?: BuilderApplication['users']['scope'];
  };
  settings?: {
    cluster?: string;
    support_access?: boolean;
    encoded_record_ids?: boolean;
    googleMapsApiKey?: string;
    googleMapId?: string;
    mapsAndGeocoderProvider?: BuilderApplicationSettings['mapsAndGeocoderProvider'];
    isMainNavAuthBased?: boolean;
    sso_google?: {
      client_id?: string;
      client_secret?: string;
      domain_restriction?: string;
    };
    lockout_options?: {
      lockout_enforced?: boolean;
      lockout_password_reset?: boolean;
      lockout_message?: string;
      password_reset_message?: string;
      lockout_user_email?: boolean;
      lockout_user_email_message?: string;
      lockout_failed_attempts?: BuilderApplicationSettings['lockoutOptions']['lockoutFailedAttempts'];
      lockout_attempt_window?: BuilderApplicationSettings['lockoutOptions']['lockoutAttemptWindowInMinutes'];
      lockout_length?: BuilderApplicationSettings['lockoutOptions']['lockoutLength'];
    };
    password_options?: {
      password_require_expiration: boolean;
      password_require_expiration_message: string;
      password_require_no_reuse: boolean;
      password_require_no_reuse_message: string;
      password_minimum_character: boolean;
      password_require_no_common: boolean;
      password_require_number: boolean;
      password_special_character: boolean;
      password_require_lowercase: boolean;
      password_require_uppercase: boolean;
    };
    inactivity_timeout_enabled?: boolean;
    inactivity_timeout?: number;
    inactivity_message?: string;
    from_email?: string;
    technical_contact?: string;
    isRecordHistoryEnabled?: boolean;
    isSystemFieldsEnabled?: boolean;
    scriptProtectionEnabled?: boolean;
    ip_whitelist?: string;
    secureViewApiPayloads?: boolean;
    https_redirect?: boolean;
    css?: string;
    javascript?: string;
  };
}

async function updateApplication(appSettingsPayload: AppSettingsPayload) {
  const { data } = await axios.put(
    '/v1/settings',
    {
      ...appSettingsPayload,
      builderVersion: 'v4'
    },
    {
      withCredentials: true
    }
  );

  return data;
}

export function useApplicationSettingsMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (appSettingsPayload: AppSettingsPayload) => updateApplication(appSettingsPayload),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [queryKeys.application] });
      void queryClient.invalidateQueries({ queryKey: [queryKeys.applicationPages] });
    }
  });
}
