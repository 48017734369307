import { generatePath, useNavigate, useSearchParams } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { queryKeys } from '@/hooks/api/queryKeys';
import { ApiWrapper } from '@/utils/api-wrapper';
import { getBuilderBaseUrl } from '@/utils/application';
import { isFlagEnabled } from '@/utils/flagsmith';
import { ROUTES } from '@/Router';

async function createTemplateObjects(templates: string[]) {
  const response = await ApiWrapper.createObjectFromTemplates(templates);
  return response;
}

export function useCreateTemplateObjectsMutation() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const isNextGenAccess =
    isFlagEnabled('full_nextgen_access') || isFlagEnabled('only_nextgen_access');

  return useMutation({
    mutationFn: (templates: string[]) => createTemplateObjects(templates),
    onSuccess: async (response) => {
      await queryClient.invalidateQueries({ queryKey: [queryKeys.application] });

      const shouldRedirectToOldBuilder =
        (searchParams.has('origin') && searchParams.get('origin') === 'v3') || !isNextGenAccess;

      if (shouldRedirectToOldBuilder) {
        const builderBaseUrl = getBuilderBaseUrl();

        if (response.objects.length > 0) {
          // Redirect to the last object added
          window.location.replace(
            `${builderBaseUrl}/schema/list/objects/${
              response.objects[response.objects.length - 1].key
            }/fields`
          );
        } else {
          // Redirect to the list of tables
          window.location.replace(`${builderBaseUrl}/schema/list`);
        }
        return;
      }

      if (response.objects.length > 0) {
        navigate(
          `${generatePath(ROUTES.TABLES_ID, {
            id: response.objects[response.objects.length - 1].key
          })}`
        );
      } else {
        navigate(`${generatePath(ROUTES.TABLES)}`);
      }
    }
  });
}
