import { useQuery } from '@tanstack/react-query';

import { type KnackObject } from '@/types/schema/KnackObject';
import { type KnackConnectionRecord } from '@/types/schema/KnackRecord';
import { queryKeys } from '@/hooks/api/queryKeys';
import { useApplication } from '@/hooks/useApplication';
import { getApplicationBasePathSegments } from '@/utils/application';
import { axiosInstance } from '@/utils/axiosConfig';

interface ConnectionRecordsQueryParams {
  objectKey?: KnackObject['key'];
  enabled?: boolean;
  recordId?: string;
}

interface GetConnectionIdentifiersResponse {
  records: KnackConnectionRecord[];
}

async function getConnectionIdentifiers(
  applicationId: string,
  objectKey?: KnackObject['key'],
  recordId?: string
) {
  const queryParams = recordId
    ? new URLSearchParams({
        'ids[]': recordId
      })
    : undefined;

  const { data } = await axiosInstance.get<GetConnectionIdentifiersResponse>(
    `/v1/applications/${applicationId}/objects/${objectKey}/identifiers`,
    { params: queryParams, withCredentials: true }
  );

  return data;
}

export function useConnectionIdentifiersQuery({
  objectKey,
  recordId,
  enabled = true
}: ConnectionRecordsQueryParams) {
  const { accountSlug, appSlug } = getApplicationBasePathSegments();
  const application = useApplication();

  if (!application) throw new Error('Application not found');

  return useQuery({
    queryKey: [queryKeys.connectionIdentifiers, accountSlug, appSlug, objectKey, recordId],
    queryFn: async () => getConnectionIdentifiers(application.id, objectKey, recordId),
    enabled: enabled && !!objectKey,
    staleTime: 1000 * 60
  });
}
