import { Trans } from 'react-i18next';
import { HiExclamationTriangle as WarningIcon } from 'react-icons/hi2';

import { type RelatedAggregateFields } from '@/components/field-settings/utils/getRelatedAggregateFields';

export function AffectedAggregateFieldsWarningCard({
  relatedAggregateFields
}: {
  relatedAggregateFields: RelatedAggregateFields[];
}) {
  return (
    <span className="mb-2 flex rounded-lg bg-warning p-4 text-warning-emphasis">
      <WarningIcon size={18} className="mr-2 shrink-0" />
      <span>
        <Trans
          i18nKey="pages.tables_page.fields_list.field_row.contextual_menu.change_field_type.affect_formulas_equations_warning"
          components={[<strong key="0" />]}
        />
        <ul className="mt-2">
          {relatedAggregateFields.map(({ table, fields }) => (
            <li key={table.key}>
              <div>
                <strong>{table.name}</strong>
              </div>
              <ul className="my-2 ml-4 list-disc">
                {fields.map((field) => (
                  <li key={field.key}>{field.name}</li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </span>
    </span>
  );
}
