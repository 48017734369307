import { useTranslation } from 'react-i18next';
import { Button, Tooltip } from '@knack/asterisk-react';

import { cn } from '@/utils/tailwind';
import { CollapsiblePanelContent } from '@/components/layout/CollapsiblePanelContent';
import ExpandPanelIcon from '@/assets/svg/expand-panel.svg?react';

interface CollapsiblePanelProps {
  isCollapsed: boolean;
  toggleCollapse: () => void;
  title: string;
  children: React.ReactNode;
  isResizable?: boolean;
  position?: 'left' | 'right';
}

function CollapsiblePanel({
  isCollapsed,
  toggleCollapse,
  title,
  children,
  isResizable = false,
  position = 'left'
}: CollapsiblePanelProps) {
  const [t] = useTranslation();
  const rootClasses = cn('size-full bg-muted flex flex-col overflow-hidden', {
    'items-center': isCollapsed
  });
  const nonResizableClasses = cn('w-80 flex-shrink-0', {
    'w-9': isCollapsed,
    'border-r': position === 'left',
    'border-l': position === 'right'
  });

  return (
    <div
      className={cn(rootClasses, !isResizable ? nonResizableClasses : '')}
      data-testid={`collapsible-panel-container-${position}`}
    >
      <Tooltip>
        <Tooltip.Trigger asChild>
          <Button
            intent="minimal"
            size="sm"
            className={cn('size-8', {
              'absolute right-4 top-3 z-20': !isCollapsed,
              'mt-3 flex justify-center': isCollapsed
            })}
            onClick={toggleCollapse}
            data-testid="expand-collapsible-panel-button"
          >
            <ExpandPanelIcon
              className={cn('size-4', {
                'rotate-180 transform':
                  (position === 'right' && isCollapsed) || (position === 'left' && !isCollapsed)
              })}
              data-testid="expand-panel-icon"
            />
          </Button>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content>
            {t(`actions.${isCollapsed ? 'show' : 'hide'}_panel_tooltip`, {
              title
            })}
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip>

      <div className={cn('flex grow flex-col', { hidden: isCollapsed })}>{children}</div>
    </div>
  );
}

// Attach Content as a static property of CollapsiblePanel
CollapsiblePanel.Content = CollapsiblePanelContent;

export { CollapsiblePanel };
