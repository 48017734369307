import { useTranslation } from 'react-i18next';
import { Tabs, type TabsListItem } from '@knack/asterisk-react';

import { usePageHelpers } from '@/hooks/helpers/usePageHelpers';
import { CollapsiblePanel } from '@/components/layout/CollapsiblePanel';
import { TabListItemCountChip } from '@/components/TabListItemCountChip';
import { usePageEditorContext } from '@/pages/pages/page-editor/PageEditorContext';
import { PageAccessSettings } from '@/pages/pages/settings-panel/page-settings/page-access/PageAccessSettings';
import { PageRules } from '@/pages/pages/settings-panel/page-settings/page-rules/PageRules';
import { PageSettingsGeneral } from '@/pages/pages/settings-panel/page-settings/PageSettingsGeneral';

export function PageSettings() {
  const [t] = useTranslation();

  const { page } = usePageEditorContext();
  const { canPageHaveRules } = usePageHelpers();

  const shouldShowPageRulesTab = canPageHaveRules(page);

  const panelTitle =
    page.type === 'authentication'
      ? t('pages.login_page_settings.heading')
      : t('pages.settings.heading');

  const tabsListItems: TabsListItem[] = [
    {
      children: t('pages.settings.tabs.general'),
      value: 'general'
    },
    {
      children: t('pages.settings.tabs.access'),
      value: 'access'
    }
  ];

  // If the page can have rules, insert the rules tab as the second item in the tabs list
  if (shouldShowPageRulesTab) {
    tabsListItems.splice(1, 0, {
      children: (
        <>
          {t('pages.settings.tabs.rules')}
          <TabListItemCountChip count={page.rules?.length ?? 0} isGradientTabs={false} />
        </>
      ),
      value: 'rules'
    });
  }

  return (
    <CollapsiblePanel.Content title={panelTitle}>
      <Tabs defaultValue="general">
        <Tabs.List
          key={page.rules?.length ?? 0}
          items={tabsListItems}
          shouldDisableResponsive
          data-testid="page-settings-tabs"
        />
        <Tabs.Content value="general" className="p-0 text-default">
          <PageSettingsGeneral />
        </Tabs.Content>
        {shouldShowPageRulesTab && (
          <Tabs.Content value="rules" className="p-0 text-default">
            <PageRules />
          </Tabs.Content>
        )}
        <Tabs.Content value="access" className="p-0 text-default">
          <PageAccessSettings />
        </Tabs.Content>
      </Tabs>
    </CollapsiblePanel.Content>
  );
}
