import { useTranslation } from 'react-i18next';

import { type BuilderViewFilterConnectionSource } from '@/types/schema/BuilderView';
import { type ConnectionField } from '@/types/schema/fields';
import { type KnackFieldKey } from '@/types/schema/KnackField';
import { type KnackFilter } from '@/types/schema/KnackFilter';
import { useFieldHelpers } from '@/hooks/helpers/useFieldHelpers';
import { useObjectHelpers } from '@/hooks/helpers/useObjectHelpers';
import { FiltersGroupSingleCard } from '@/pages/pages/settings-panel/view-settings/common/FiltersGroupSingleCard';
import {
  useConnectionParentOptions,
  type SelectableConnectionParentOption
} from '@/pages/pages/settings-panel/view-settings/helpers/useConnectionParentOptions';

interface RestrictedConnectionCardProps {
  filterConnectionSourceGroup: {
    key: KnackFieldKey;
    source: BuilderViewFilterConnectionSource;
    filters: KnackFilter[];
  };
}

export function RestrictedConnectionCard({
  filterConnectionSourceGroup
}: RestrictedConnectionCardProps) {
  const [t] = useTranslation();
  const { getFieldByKey } = useFieldHelpers();
  const { getObjectByKey } = useObjectHelpers();

  const connectionField = getFieldByKey(filterConnectionSourceGroup.key) as ConnectionField;
  const connectedObject = connectionField.relationship.object
    ? getObjectByKey(connectionField.relationship.object)
    : null;

  const connectionParentOptions = useConnectionParentOptions({
    field: connectionField
  }) as SelectableConnectionParentOption[];

  if (!connectedObject) {
    return null;
  }

  const connectedSource = connectionParentOptions.find((conn) =>
    conn.value.includes(filterConnectionSourceGroup.source.connection_key)
  );

  return (
    <div className="mb-4">
      <p className="mb-2 font-medium">{connectedObject.inflections.singular}</p>
      {connectedSource && <p className="mb-2 text-xs text-subtle">{connectedSource.label}</p>}
      {filterConnectionSourceGroup.filters.length > 0 && (
        <div>
          <p className="text-xs text-subtle">
            {t(
              'pages.element_settings.common.categories.data_display.filtering_section.restricted_connections.show_filter_records_card'
            )}
          </p>
          <FiltersGroupSingleCard filtersCriteria={filterConnectionSourceGroup.filters} />
        </div>
      )}
    </div>
  );
}
