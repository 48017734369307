import { useFormContext } from 'react-hook-form';
import { type ReturnDate } from '@knack/asterisk-react/dist/src/components/date-time-picker/components/DateTimeInput/DateTimeInput';
import { type DateRangeWithErrors } from '@knack/asterisk-react/dist/src/components/date-time-picker/DateTimePicker';

import { type DateTimeField } from '@/types/schema/fields/DateTimeField';
import { useFieldNameConcatenate } from '@/utils/forms/useFieldNameConcatenate';
import { KnackDatePickerRepeatOptions } from '@/components/knack-date-picker/components/KnackDatePickerRepeatOptions';
import { DatePickerFormWrapper } from '@/components/knack-date-picker/form/DatePickerFormWrapper';
import { type KnackDatePickerSchema } from '@/components/knack-date-picker/KnackDatePickerSchema';
import { getKnackDatePickerFormatFromField } from '@/components/knack-date-picker/transformers/DateTimeFormatToLuxonFormat';

type KnackDatePickerProps = {
  formPath?: string;
  dateTimeField: DateTimeField;
  isRepeatDisabled?: boolean;
  defaultValue: KnackDatePickerSchema;
};

export function KnackDatePicker(props: KnackDatePickerProps) {
  const { formPath, dateTimeField, defaultValue, isRepeatDisabled = false } = props;

  const getFieldKey = useFieldNameConcatenate<KnackDatePickerSchema>(formPath);

  const { watch } = useFormContext<KnackDatePickerSchema>();

  const calendarMode = dateTimeField.format.calendar ? 'range' : 'single';

  const datePickerFormat = getKnackDatePickerFormatFromField(dateTimeField.format);

  return (
    <div className="w-fit">
      <DatePickerFormWrapper
        defaultValue={defaultValue.date as ReturnDate | DateRangeWithErrors} // TODO: needs better typings?
        mode={calendarMode}
        name={getFieldKey('date')}
        format={{
          date: datePickerFormat.date,
          time: watch('all_day') === true ? undefined : datePickerFormat.time
        }}
      />
      {!isRepeatDisabled && (
        <KnackDatePickerRepeatOptions
          formPath={formPath}
          mode={calendarMode}
          format={datePickerFormat}
        />
      )}
    </div>
  );
}
