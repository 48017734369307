import { useTranslation } from 'react-i18next';
import { Tabs, type TabsListItem } from '@knack/asterisk-react';

import { BuilderAccordion } from '@/components/BuilderAccordion';
import { useActiveTabContext } from '@/pages/pages/settings-panel/view-settings/ActiveTabContext';
import { EventTitleDescriptionSettings } from '@/pages/pages/settings-panel/view-settings/calendar/add-event/EventTitleDescriptionSettings';
import { ViewWithDetailsActionsSettingsCategory } from '@/pages/pages/settings-panel/view-settings/common/details-actions/ViewWithDetailsActionsSettingsCategory';
import { ViewDetailsFieldManagement } from '@/pages/pages/settings-panel/view-settings/common/details-field-management/ViewDetailsFieldManagement';

export function EventDetailsSettings() {
  const { activeTab = 'general', setActiveTab } = useActiveTabContext();
  const [t] = useTranslation();
  const tabListItems: TabsListItem[] = [
    {
      value: 'general',
      children: t('keywords.general')
    },
    {
      value: 'fields',
      children: t('keywords.fields')
    },
    {
      value: 'actions',
      children: t('keywords.actions')
    }
  ];

  return (
    <Tabs defaultValue={activeTab} className="h-full" onValueChange={setActiveTab}>
      <Tabs.List items={tabListItems} intent="page" shouldDisableResponsive shouldUseGradient />
      <Tabs.Content value="general" className="p-0 text-default">
        <EventTitleDescriptionSettings contextKey="details" />
      </Tabs.Content>
      <Tabs.Content value="fields" className="p-0 text-default">
        <BuilderAccordion>
          <ViewDetailsFieldManagement />
        </BuilderAccordion>
      </Tabs.Content>
      <Tabs.Content value="actions" className="p-0 text-default">
        <ViewWithDetailsActionsSettingsCategory />
      </Tabs.Content>
    </Tabs>
  );
}
