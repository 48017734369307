import { useTranslation } from 'react-i18next';
import { Input, Label, Switch } from '@knack/asterisk-react';

const DEFAULT_CHARACTER_LIMIT = 75;

export function TruncateTextSettings({ columnItem, updateViewColumn }) {
  const [t] = useTranslation();

  const handleChangeTruncateSetting = (isTruncationEnabled: boolean) => {
    if (isTruncationEnabled && !columnItem.character_limit) {
      updateViewColumn({
        id: columnItem.id,
        truncate: isTruncationEnabled,
        character_limit: DEFAULT_CHARACTER_LIMIT
      });
      return;
    }

    updateViewColumn({
      id: columnItem.id,
      truncate: isTruncationEnabled
    });
  };

  return (
    <>
      <Label className="flex items-center gap-2">
        <Switch checked={columnItem.truncate} onCheckedChange={handleChangeTruncateSetting} />
        {t('pages.element_settings.table.categories.data_display.field_management.truncate_option')}
      </Label>
      {columnItem.truncate && (
        <div className="ml-10 mt-4">
          <Label>
            {t(
              'pages.element_settings.table.categories.data_display.field_management.character_limit'
            )}
            <Input
              type="number"
              min={1}
              className="max-w-20"
              value={columnItem.character_limit || DEFAULT_CHARACTER_LIMIT}
              onChange={(e) => {
                let newValue = Number(e.target.value);

                // Don't allow the user to type 0 as a character limit
                if (newValue === 0) {
                  newValue = 1;
                }

                updateViewColumn({
                  id: columnItem.id,
                  character_limit: newValue
                });
              }}
            />
          </Label>
        </div>
      )}
    </>
  );
}
