import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiExternalLink as ExternalIcon } from 'react-icons/hi';
import {
  // HiCodeBracketSquare as CodeBracketIcon,
  HiCodeBracket as CodeIcon,
  HiSquare2Stack as CopyIcon,
  HiCreditCard as CreditCardIcon,
  HiPaintBrush as DesignIcon,
  HiChevronDown as DownIcon,
  HiEye as EyeIcon,
  HiArrowTopRightOnSquare as GoToLiveAppIcon,
  HiArrowTopRightOnSquare as GoToPageIcon,
  HiQuestionMarkCircle as HelpIcon,
  HiLockClosed as LockIcon,
  HiCog6Tooth as SettingsIcon
} from 'react-icons/hi2';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  AsteriskLogo,
  Button,
  DropdownMenu,
  ThemeProviderContext,
  Tooltip
} from '@knack/asterisk-react';

import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { usePagesQuery } from '@/hooks/api/queries/usePagesQuery';
import { usePageHelpers } from '@/hooks/helpers/usePageHelpers';
import { useTrackEventsHelpers } from '@/hooks/helpers/useTrackEventsHelpers';
import { copyToClipboard } from '@/utils/copy-to-clipboard';
import { FEATURE_FLAGS, isFlagEnabled } from '@/utils/flagsmith';
import { KNOWLEDGE_BASE_URLS } from '@/utils/knowledge-base';
import { safeLogRocketTrack } from '@/utils/logrocket';
import { cn } from '@/utils/tailwind';
import { getHasTrialExpired, getTrialDaysLeft } from '@/utils/trials';
import { GoToLiveAppMenu } from '@/components/GoToLiveAppMenu';
import { HeaderSaving } from '@/components/layout/HeaderSaving';
import { LinkToClassicBuilder } from '@/components/LinkToClassicBuilder';
import { LiveAppFrame } from '@/components/LiveAppFrame';
import { LiveAppPreview, SCREEN_SIZES, type ScreenSize } from '@/components/LiveAppPreview';
import { ROUTES } from '@/Router';
import { EditableAppName } from './EditableAppName';

export const HEADER_HEIGHT = 64;

export function Header() {
  const [t] = useTranslation();
  const { id: tableOrPageKey } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { data: application } = useApplicationQuery();
  const { data: allPages } = usePagesQuery();

  const { isDarkMode } = useContext(ThemeProviderContext);
  const { trackEvent } = useTrackEventsHelpers();
  const { getPageLiveAppUrl } = usePageHelpers();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isQuickViewEnabled, setIsQuickViewEnabled] = useState(searchParams.has('quickView'));
  const [previewScreenSize, setPreviewScreenSize] = useState<ScreenSize>(SCREEN_SIZES.desktop);

  const menuItemClass = 'gap-2';
  const menuItemIconClass = 'text-subtle';
  const oldBuilderUrl = `${import.meta.env.PUBLIC_BUILDER_URL}/${
    application?.account.slug
  }/${application?.slug}`;
  const dashboardUrl = `${import.meta.env.PUBLIC_DASHBOARD_URL}`;
  const plansPageUrl = `${dashboardUrl}/settings/billing/plans`;
  const hasTrialExpired = application ? getHasTrialExpired(application.account.betaDeadline) : true;
  const trialDaysLeft =
    !hasTrialExpired && application ? getTrialDaysLeft(application.account.betaDeadline) : 0;
  const activePage = allPages?.find((p) => p.key === tableOrPageKey) || null;
  const liveAppUrl = getPageLiveAppUrl(activePage ?? undefined);

  const trackDashboardLinkClickEvent = () => {
    safeLogRocketTrack('NavigateToDashboard', application?.account.isHipaa);
  };

  const trackBuilderLinkClickEvent = () => {
    safeLogRocketTrack('NavigateToBuilder', application?.account.isHipaa);
  };

  const handleSettingsClick = () => {
    navigate(ROUTES.SETTINGS_GENERAL, { replace: true });
  };

  const handleLiveAppDesignClick = () => {
    navigate(ROUTES.THEMES, { replace: true });
  };

  const handleUserSettingsClick = () => {
    navigate(ROUTES.SETTINGS_USERS, { replace: true });
  };

  const handleAPISettingsClick = () => {
    navigate(ROUTES.SETTINGS_CODE_API, { replace: true });
  };

  return (
    <>
      <div
        className="relative flex items-center justify-between gap-2 bg-muted px-3 pl-[0.70rem]"
        style={{ height: HEADER_HEIGHT }}
        data-testid="header"
      >
        <div className="flex w-2/3 items-center gap-2">
          <Tooltip>
            <Tooltip.Trigger asChild>
              <Button
                intent="minimal"
                size="sm"
                aria-label={t('components.layout.header.go_to_dashboard')}
                asChild
              >
                <a
                  href={dashboardUrl}
                  aria-label="Dashboard Link"
                  onClick={trackDashboardLinkClickEvent}
                >
                  <div
                    className={cn('size-5 text-brand', {
                      'text-subtle': isDarkMode
                    })}
                  >
                    <AsteriskLogo />
                  </div>
                </a>
              </Button>
            </Tooltip.Trigger>
            <Tooltip.Content className="z-20" side="right" sideOffset={8}>
              {t('components.layout.header.go_to_dashboard')}
            </Tooltip.Content>
          </Tooltip>
          <div className="flex items-center gap-1 overflow-x-hidden rounded-lg p-1">
            {application && <EditableAppName appName={application.name} />}
            <DropdownMenu open={isDropdownOpen} onOpenChange={setIsDropdownOpen}>
              <DropdownMenu.Trigger asChild>
                <Button
                  intent="minimal"
                  size="sm"
                  className={cn({ 'bg-subtle': isDropdownOpen })}
                  aria-label={t('components.layout.header.menu')}
                  data-testid="header-menu-button"
                >
                  <DownIcon size={16} />
                </Button>
              </DropdownMenu.Trigger>
              <DropdownMenu.Content align="start">
                <DropdownMenu.Item asChild>
                  <a href={liveAppUrl} className={menuItemClass} target="_blank" rel="noreferrer">
                    <GoToPageIcon size={16} className={menuItemIconClass} />
                    {t('components.layout.header.go_to_live_app')}
                  </a>
                </DropdownMenu.Item>
                <DropdownMenu.Item
                  className={menuItemClass}
                  onSelect={() => copyToClipboard(liveAppUrl)}
                >
                  <CopyIcon size={16} className={menuItemIconClass} />
                  {t('components.layout.header.copy_live_app_url')}
                </DropdownMenu.Item>
                <DropdownMenu.Separator />
                <DropdownMenu.Item onClick={handleSettingsClick}>
                  <SettingsIcon size={16} className={menuItemIconClass} />
                  {t('components.layout.header.app_settings')}
                </DropdownMenu.Item>
                <DropdownMenu.Item onClick={handleLiveAppDesignClick}>
                  <DesignIcon size={16} className={menuItemIconClass} />
                  {t('components.layout.header.live_app_design')}
                </DropdownMenu.Item>
                <DropdownMenu.Item onClick={handleUserSettingsClick}>
                  <LockIcon size={16} className={menuItemIconClass} />
                  {t('components.layout.header.user_logins')}
                </DropdownMenu.Item>
                <DropdownMenu.Item asChild>
                  <a
                    href={`${oldBuilderUrl}/settings/ecommerce`}
                    className={menuItemClass}
                    onClick={trackBuilderLinkClickEvent}
                  >
                    <CreditCardIcon size={16} className={menuItemIconClass} />
                    {t('components.layout.header.e_commerce')}
                  </a>
                </DropdownMenu.Item>
                <DropdownMenu.Item onClick={handleAPISettingsClick}>
                  <CodeIcon size={16} className={menuItemIconClass} />
                  {t('app_settings.sections.api')}
                </DropdownMenu.Item>
                {/* Remove Embed until the feature is ready */}
                {/* <DropdownMenu.Item asChild>
                  <a
                    href={`${oldBuilderUrl}/settings/embeds`}
                    className={menuItemClass}
                    onClick={trackBuilderLinkClickEvent}
                  >
                    <CodeBracketIcon size={16} className={menuItemIconClass} />
                    {t('components.layout.header.embed')}
                  </a>
                </DropdownMenu.Item> */}
                <DropdownMenu.Separator />
                <DropdownMenu.Item asChild>
                  <a
                    href={dashboardUrl}
                    className={menuItemClass}
                    onClick={trackDashboardLinkClickEvent}
                  >
                    <div className={cn('ml-0.5 h-3.5 w-3.5', menuItemIconClass)}>
                      <AsteriskLogo />
                    </div>
                    {t('components.layout.header.go_to_knack_dashboard')}
                  </a>
                </DropdownMenu.Item>
              </DropdownMenu.Content>
            </DropdownMenu>
            <HeaderSaving />
          </div>
          {isFlagEnabled(FEATURE_FLAGS.full_nextgen_access) &&
            !isFlagEnabled(FEATURE_FLAGS.only_nextgen_access) && (
              <div className="shrink-0 rounded-2xl bg-action-gradient py-px pl-[1.5px] pr-[1.5px]">
                <div className="flex h-6 items-center rounded-2xl bg-base px-2 font-medium">
                  <span
                    style={{
                      background: 'linear-gradient(to right, #86307b, #e61875)',
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent'
                    }}
                  >
                    Early Access
                  </span>
                </div>
              </div>
            )}
          {isFlagEnabled(FEATURE_FLAGS.full_nextgen_access) && (
            <LinkToClassicBuilder text={t('components.layout.header.classic_builder')} />
          )}
        </div>
        <div className="flex flex-shrink-0 items-center justify-end gap-5">
          {application?.account.isTrial && (
            <div className="flex items-center gap-2">
              <span className="hidden text-subtle sm:block md:text-base">
                {t('components.layout.header.trial_days_left', { count: trialDaysLeft })}
              </span>
              <a href={plansPageUrl} className="border-b-gradient text-brand-gradient">
                {t('actions.upgrade')}
              </a>
            </div>
          )}
          <div className="flex items-center">
            <Tooltip>
              <Tooltip.Trigger asChild>
                <Button
                  intent="minimal"
                  size="sm"
                  className="mr-2 px-1.5 py-1 text-default hover:bg-subtle disabled:pointer-events-auto hover:disabled:bg-transparent"
                  aria-label={t('components.layout.header.help_and_support')}
                  data-testid="help-button"
                  asChild
                >
                  <a href={KNOWLEDGE_BASE_URLS.KNACK_DOCS} target="_blank" rel="noreferrer">
                    <HelpIcon size={24} />
                    <span className="sr-only">
                      {t('components.layout.header.help_and_support')}
                    </span>
                  </a>
                </Button>
              </Tooltip.Trigger>
              <Tooltip.Content className="z-20">
                {t('components.layout.header.help_and_support')}
              </Tooltip.Content>
            </Tooltip>
            <Button
              intent="secondary"
              size="sm"
              className="mr-2"
              onClick={() => {
                trackEvent('app_previewed');
                setIsQuickViewEnabled(true);
              }}
            >
              <EyeIcon size={16} className="mr-1" />
              {t('components.layout.header.quick_view')}
            </Button>
            <Button
              intent="secondary"
              size="sm"
              className="rounded-md rounded-r-none focus:outline-offset-[-2px]"
              asChild
            >
              <a href={liveAppUrl} target="_blank" rel="noreferrer">
                <ExternalIcon size={16} className="mr-1" />
                <span className="hidden md:inline-block">
                  {t('components.layout.header.go_to_live_app')}
                </span>
                <GoToLiveAppIcon size={16} className="md:hidden" />
              </a>
            </Button>
            <GoToLiveAppMenu />
          </div>
        </div>
      </div>
      {isQuickViewEnabled && (
        <LiveAppPreview
          data-testid="live-app-quick-view-button"
          title={t('components.layout.header.live_app_quick_view')}
          closeButtonLabel={t('actions.stop_quick_view')}
          onClose={() => {
            setPreviewScreenSize(SCREEN_SIZES.desktop);
            setIsQuickViewEnabled(false);
            // Hack to bypass the issue with the pointer-events on the body
            // when there are two modals opened at the same time
            setTimeout(() => {
              document.body.style.pointerEvents = '';
            }, 0);
          }}
          onScreenSizeChange={(size) => setPreviewScreenSize(size)}
        >
          <LiveAppFrame
            pagePath="" // Always render the home page when opening the 'Quick View'
            registerIFrame={() => {}}
            screenSize={previewScreenSize}
            isQuickViewMode
          />
        </LiveAppPreview>
      )}
    </>
  );
}
