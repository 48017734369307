import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Label, Select } from '@knack/asterisk-react';
import { DateTime } from 'luxon';

import {
  DATE_FORMAT_OPTIONS,
  DEFAULT_DATE_TYPE_OPTIONS,
  type DateFormatOptions,
  type DateTimeField
} from '@/types/schema/fields';
import {
  DEFAULT_LUXON_DATE,
  getLuxonDateFormatFromFieldFormat
} from '@/components/knack-date-picker/transformers/DateTimeFormatToLuxonFormat';

interface DateFormatSettingsProps {
  field: DateTimeField;
}

export function DateFormatSettings({ field }: DateFormatSettingsProps) {
  const [t] = useTranslation();
  const { getValues, setValue } = useFormContext<DateTimeField>();

  return (
    <>
      <Label htmlFor="date-format-select" className="font-medium">
        {t('components.data_table.attributes.field_settings.date_time.date_format')}
      </Label>
      <Controller
        name="format.date_format"
        defaultValue={field?.format?.date_format || DATE_FORMAT_OPTIONS[0]}
        render={({ field: currentField }) => (
          <Select
            onValueChange={(value: DateFormatOptions) => {
              currentField.onChange(value);

              const currentValidDate = getValues('format.default_date');
              const newDateFormatCorrected: DateFormatOptions =
                value === 'M D, yyyy' ? 'mm/dd/yyyy' : value;
              const oldDateFormatCorrected: DateFormatOptions =
                currentField.value === 'M D, yyyy' ? 'mm/dd/yyyy' : currentField.value;

              setValue(
                'format.default_date',
                currentValidDate
                  ? DateTime.fromFormat(
                      currentValidDate,
                      getLuxonDateFormatFromFieldFormat(oldDateFormatCorrected) ||
                        DEFAULT_LUXON_DATE
                    ).toFormat(
                      getLuxonDateFormatFromFieldFormat(newDateFormatCorrected) ||
                        DEFAULT_LUXON_DATE
                    )
                  : undefined
              );
            }}
            defaultValue={currentField.value || DATE_FORMAT_OPTIONS[0]}
          >
            <Select.Trigger
              id="date-format-select"
              placeholder={t('actions.select')}
              className="w-full rounded-lg"
              data-testid="date_time-form-settings-date-format-select"
              {...currentField}
            />
            <Select.Content>
              {DATE_FORMAT_OPTIONS.map(
                (format) =>
                  format !== 'Ignore Date' && (
                    <Select.Item
                      key={format}
                      value={format}
                      data-testid={`date_time-settings-date-format-value-${format}`}
                    >
                      {t(
                        `components.data_table.attributes.field_settings.date_time.date_format_options.${format}`
                      )}
                    </Select.Item>
                  )
              )}
            </Select.Content>
          </Select>
        )}
      />

      <Label htmlFor="default-type-date-select" className="font-medium">
        {t('components.data_table.attributes.field_settings.date_time.default_date')}
      </Label>
      <Controller
        name="format.default_type"
        defaultValue={field?.format?.default_type || DEFAULT_DATE_TYPE_OPTIONS[0]}
        render={({ field: currentField }) => (
          <Select
            onValueChange={currentField.onChange}
            defaultValue={currentField.value || DEFAULT_DATE_TYPE_OPTIONS[0]}
          >
            <Select.Trigger
              id="default-type-date-select"
              placeholder={t('actions.select')}
              className="w-full rounded-lg"
              data-testid="date_time-form-settings-default-type-select"
              {...currentField}
            />
            <Select.Content>
              {DEFAULT_DATE_TYPE_OPTIONS.map((format) => (
                <Select.Item
                  key={format}
                  value={format}
                  data-testid={`date_time-settings-default-type-value-${format}`}
                >
                  {t(
                    `components.data_table.attributes.field_settings.date_time.default_date_options.${format}`
                  )}
                </Select.Item>
              ))}
            </Select.Content>
          </Select>
        )}
      />
    </>
  );
}
