import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { type BuilderPage, type PageType } from '@/types/schema/BuilderPage';
import { type KnackConnectionRecord } from '@/types/schema/KnackRecord';
import { useConnectionRecordsQuery } from '@/hooks/api/queries/useConnectionRecordsQuery';
import { type PageEditorSelectedItem } from '@/pages/pages/page-editor/helpers/types';
import { usePageEditorMessagingContext } from '@/pages/pages/page-editor/PageEditorMessagingContext';

type PagesPageContextState = {
  pages: BuilderPage[];
  activePage: BuilderPage | null;
  activePageSourceObjectRecords: KnackConnectionRecord[] | null;
  settingsPanelItem: PagesPageSettingsPanelItem | null;
  isSettingsPanelOpen: boolean;
  isAddNewPageDialogOpen: boolean;
  newPageType: PageType;
  setSettingsPanelItem: (item: PagesPageSettingsPanelItem | null) => void;
  setIsSettingsPanelOpen: (isOpen: boolean) => void;
  setIsAddNewPageDialogOpen: (isOpen: boolean) => void;
  setNewPageType: (type: PageType) => void;
} | null;

type PagesPageContextProviderProps = {
  pages: BuilderPage[];
  children: React.ReactNode;
};

export type PagesPageSettingsPanelItem =
  | {
      type: 'page';
      page: BuilderPage;
    }
  | PageEditorSelectedItem;

const PagesPageContext = createContext<PagesPageContextState>(null);

export function PagesPageContextProvider({ pages, children }: PagesPageContextProviderProps) {
  const urlParams = useParams<{ id: string }>();
  const { id: activePageKey } = urlParams;

  const activePage = pages.find((p) => p.key === activePageKey) || null;

  const { data: activePageSourceObjectRecords } = useConnectionRecordsQuery({
    objectKey: activePage?.sourceObjectKey || '',
    enabled: !!activePage?.sourceObjectKey
  });

  const { sendMessageToLiveApp, resetMessageFromLiveApp } = usePageEditorMessagingContext();

  const [isSettingsPanelOpen, setIsSettingsPanelOpen] = useState(false);
  const [settingsPanelItem, setSettingsPanelItem] = useState<PagesPageSettingsPanelItem | null>(
    () => {
      // If there is an active page, we want to default the settings panel item to the active page
      if (activePage) {
        return { type: 'page', page: activePage };
      }

      return null;
    }
  );
  const [newPageType, setNewPageType] = useState<PageType>('page');
  const [isAddNewPageDialogOpen, setIsAddNewPageDialogOpen] = useState(false);

  // If the active page key changes, we set the settings panel item to be the new active page
  useEffect(() => {
    // When the active page key changes, we reset the message from the Live App so we don't have messages from a previous page
    resetMessageFromLiveApp();

    // If the active page key changes but there is no page, we reset the settings panel item
    if (!activePage) {
      setSettingsPanelItem(null);
      return;
    }

    // Otherwise, we set the settings panel item to be the new active page
    setSettingsPanelItem({ type: 'page', page: activePage });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePageKey]);

  const handleSetSettingsPanelItem = useCallback(
    (item: PagesPageSettingsPanelItem | null) => {
      setSettingsPanelItem(item);

      // We want to ensure the settings panel is open when setting an item
      setIsSettingsPanelOpen(true);

      // If the settings panel item is null or is a page, we set the selected item in the Live App to null
      if (!item || item.type === 'page') {
        sendMessageToLiveApp({ action: 'select', selectedItem: null });
      }
    },
    [sendMessageToLiveApp]
  );

  const contextValue = useMemo(
    () => ({
      pages,
      activePage,
      activePageSourceObjectRecords: activePageSourceObjectRecords?.records || null,
      isSettingsPanelOpen,
      setIsSettingsPanelOpen,
      settingsPanelItem,
      setSettingsPanelItem: handleSetSettingsPanelItem,
      isAddNewPageDialogOpen,
      setIsAddNewPageDialogOpen,
      newPageType,
      setNewPageType
    }),
    [
      pages,
      activePage,
      activePageSourceObjectRecords,
      isSettingsPanelOpen,
      settingsPanelItem,
      handleSetSettingsPanelItem,
      isAddNewPageDialogOpen,
      newPageType
    ]
  );

  return <PagesPageContext.Provider value={contextValue}>{children}</PagesPageContext.Provider>;
}

export const usePagesPageContext = () => {
  const context = useContext(PagesPageContext);
  if (!context) {
    throw new Error('usePagesPageContext must be used within a PagesPageContextProvider');
  }
  return context;
};
