import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import snakeCase from 'lodash.snakecase';

import { type BuilderViewSourceSchemaCriteria } from '@/types/schema/BuilderView';
import { type KnackCriteria } from '@/types/schema/KnackCriteria';
import { type KnackField, type KnackFieldKey } from '@/types/schema/KnackField';
import { type KnackObject } from '@/types/schema/KnackObject';
import { InlineKnackCriteriaValue } from '@/components/InlineKnackCriteriaValue';
import { InlineKnackField } from '@/components/InlineKnackField';

function GroupLogicalOperatorText({ match }: { match: FilterGroupMatch }) {
  const [t] = useTranslation();

  return (
    <span>
      {match === 'any' ? t('components.rules.or_uppercase') : t('components.rules.and_uppercase')}
    </span>
  );
}

function FilterLogicalOperatorText({ match }: { match: FilterGroupMatch }) {
  const [t] = useTranslation();

  return (
    <span>
      {match === 'any' ? t('components.rules.and_uppercase') : t('components.rules.or_uppercase')}
    </span>
  );
}

type SourceFiltersGroupCardProps = {
  sourceObject: KnackObject;
  sourceFiltersCriteria: BuilderViewSourceSchemaCriteria;
};

type FilterGroupMatch = 'any' | 'all';

type FilterGroupProps = {
  index: number;
  field: KnackField;
  filterRule: KnackCriteria;
  match: FilterGroupMatch;
};

function FilterGroup({ index, field, filterRule, match }: FilterGroupProps) {
  const [t] = useTranslation();

  const firstItem = index === 0;

  return (
    <div className="flex items-center gap-1">
      {!firstItem && <FilterLogicalOperatorText match={match} />}
      <InlineKnackField
        fieldName={field.name}
        fieldType={field.type}
        className="bg-action text-default"
      />
      {t(`operators.${snakeCase(filterRule.operator)}`)}{' '}
      <InlineKnackCriteriaValue criteria={filterRule} criteriaField={field} className="bg-action" />
    </div>
  );
}

export function SourceFiltersGroupsCard({
  sourceObject,
  sourceFiltersCriteria
}: SourceFiltersGroupCardProps) {
  const [t] = useTranslation();

  const hasSourceFilters =
    sourceFiltersCriteria.rules.length > 0 || sourceFiltersCriteria.groups.length > 0;

  const getKnackField = (fieldKey: KnackFieldKey) =>
    sourceObject.fields.find((f) => f.key === fieldKey);

  if (!hasSourceFilters) {
    return (
      <p>
        {t(
          'pages.element_settings.common.categories.data_display.general_settings.all_records_are_shown'
        )}
      </p>
    );
  }

  const rulesGroup = sourceFiltersCriteria.rules.map((rule) => [rule]);
  const criteriaRules = [...rulesGroup, ...sourceFiltersCriteria.groups];

  return (
    <div className="space-y-1" data-testid="source-filters-group-card">
      <p className="text-xs">
        {t(
          'pages.element_settings.common.categories.data_display.general_settings.show_records_where'
        )}
      </p>
      {criteriaRules.map((groupRule, groupRuleIndex) => (
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={groupRuleIndex}>
          {groupRuleIndex > 0 && (
            <p className="my-2">
              <GroupLogicalOperatorText match={sourceFiltersCriteria.match} />
            </p>
          )}
          <div className="rounded-lg bg-subtle p-2">
            {groupRule.map((rule, ruleIndex) => {
              const knackField = getKnackField(rule.field);

              if (!knackField) {
                return null;
              }

              return (
                <FilterGroup
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${rule.field}-${ruleIndex}-group`}
                  index={ruleIndex}
                  field={knackField}
                  filterRule={rule}
                  data-testid={`inline-knack-source-filter-${ruleIndex}`}
                  match={sourceFiltersCriteria.match}
                />
              );
            })}
          </div>
        </Fragment>
      ))}
    </div>
  );
}
