import { formatTimerRawValue } from '@/components/data-table/display/fields/date-time/formatters/dateFormatter';
import { DurationEdit } from '@/components/data-table/display/fields/duration/DurationEdit';
import { type DurationField } from '@/components/data-table/display/fields/Field';
import { type FieldRenderProps } from '@/components/data-table/display/fields/FieldRender';
import { useDataTableStore } from '@/components/data-table/useDataTableStore';

export function DurationRender(props: FieldRenderProps<DurationField>) {
  const { isFloating, rawValue, rowId, fieldId, type } = props;

  const currentField = useDataTableStore().use.getField<typeof type>(fieldId);

  if (isFloating) {
    return <DurationEdit {...props} />;
  }

  return (
    <div
      className="flex size-full flex-col p-2"
      data-testid={`duration-render-${rowId}-${fieldId}`}
    >
      <p className="truncate">{formatTimerRawValue(rawValue, currentField.format)}</p>
    </div>
  );
}
