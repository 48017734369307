import { Trans, useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { Dialog } from '@knack/asterisk-react';

import { type KnackObject } from '@/types/schema/KnackObject';
import { useObjectHelpers } from '@/hooks/helpers/useObjectHelpers';
import { DeleteTableForm } from '@/pages/tables/tables-tree/table-dropdown-menu/DeleteTableForm';
import { ROUTES } from '@/Router';

function DeleteRoleDialogContent({ defaultTable }) {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const tableKey = defaultTable.key;
  const closeDialog = () => {
    navigate(generatePath(ROUTES.ROLES_ID, { id: tableKey }));
  };

  return (
    <Dialog open onOpenChange={() => closeDialog()}>
      <Dialog.Content>
        <Dialog.MainContent className="space-y-4">
          <Dialog.Header>
            <Dialog.Title>
              {t('components.dialogs.roles.delete.title', {
                roleName: defaultTable.name
              })}
            </Dialog.Title>
          </Dialog.Header>
          <Dialog.Description>
            <Trans
              i18nKey="components.dialogs.roles.delete.title"
              values={{ roleName: defaultTable.name }}
            >
              <span className="font-semibold">tableName</span>
            </Trans>
          </Dialog.Description>
          <DeleteTableForm
            table={defaultTable}
            onDelete={closeDialog}
            onCancel={closeDialog}
            isUser
          />
        </Dialog.MainContent>
      </Dialog.Content>
    </Dialog>
  );
}

export function DeleteRoleDialog() {
  const { id } = useParams();
  const { getObjectByKey } = useObjectHelpers();
  if (!id) return null;

  const object = getObjectByKey(id as KnackObject['key']);

  if (!object || object.key !== id) return null;

  return <DeleteRoleDialogContent defaultTable={object} />;
}
