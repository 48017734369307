// The conditions to determine what an internal email is, come from the
// definition of the `is_knack` property in `data-etl-pipelines`
import { isRudderStackEnabled } from '@/utils/rudderstack';

export const isInternalEmail = (email: unknown) => {
  if (typeof email !== 'string') {
    return false;
  }

  // By default we don't want to track knack emails
  if (import.meta.env.PUBLIC_ANALYTICS_TRACK_KNACK_EMAILS !== 'true') {
    const internalDomains = ['@knack.com', '@knackhq.com', '@appnowgo.com'];

    // Check for domain endings
    if (internalDomains.some((domain) => email.endsWith(domain))) {
      return true;
    }
  }

  const emailPatterns = [
    /@ziggystar/,
    /@resurgenstech\.com/,
    /dev\+playwright/,
    /kn\.test\.internal/
  ];

  return emailPatterns.some((pattern) => pattern.test(email));
};

export const shouldSendAnalytics = (email: string): boolean =>
  isRudderStackEnabled() && !!email && !isInternalEmail(email);
