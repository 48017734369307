import { useEffect } from 'react';
import {
  FormProvider,
  useForm,
  useFormContext,
  useWatch,
  type FieldArrayWithId
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HiDocumentDuplicate as DuplicateIcon, HiTrash as RemoveIcon } from 'react-icons/hi2';
import { useSortable } from '@dnd-kit/sortable';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@knack/asterisk-react';
import { z } from 'zod';

import { type BuilderViewActionRule } from '@/types/schema/BuilderView';
import { type KnackObject } from '@/types/schema/KnackObject';
import { type RecordRule } from '@/types/schema/rules/RecordRule';
import { cn } from '@/utils/tailwind';
import { DragButton } from '@/components/dnd/DragButton';
import { RecordRuleFormFields } from '@/components/record-rule/RecordRuleFormFields';
import { usePageEditorContext } from '@/pages/pages/page-editor/PageEditorContext';

type RecordRuleWithId = FieldArrayWithId<BuilderViewActionRule, 'record_rules', 'id'>;

interface DynamicSortableActionProps {
  recordRule: RecordRuleWithId;
  recordRuleIndex: number;
  sourceObject: KnackObject;
  onRecordRuleDelete: () => void;
  onRecordRuleDuplicate: (rule: RecordRuleWithId) => void;
}

export function DynamicActionRecordRule({
  recordRule,
  recordRuleIndex,
  sourceObject,
  onRecordRuleDelete,
  onRecordRuleDuplicate
}: DynamicSortableActionProps) {
  const { page } = usePageEditorContext();
  const [t] = useTranslation('translation', {
    keyPrefix:
      'pages.element_settings.table.categories.data_display.field_management.dynamic_actions_column'
  });
  const { isDragging, isSorting } = useSortable({
    id: recordRule.id
  });

  const parentForm = useFormContext<BuilderViewActionRule>();
  const parentFormErrors = parentForm.formState.errors?.record_rules?.[recordRuleIndex];
  const shouldDisplayRemoveButton = parentForm.getValues('record_rules').length > 1;

  // Record rule form schema without validation - Validation comes from the parent component
  const recordRuleFormSchema = z.custom<RecordRuleWithId>();

  const form = useForm<RecordRule>({
    resolver: zodResolver(recordRuleFormSchema),
    defaultValues: recordRule,
    errors: parentFormErrors
  });

  const formData = useWatch<RecordRule>({ control: form.control }) as RecordRuleWithId;

  useEffect(() => {
    if (formData) {
      parentForm.setValue(`record_rules.${recordRuleIndex}`, formData, {
        shouldDirty: true
      });
    }
    parentForm.clearErrors(`record_rules.${recordRuleIndex}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  useEffect(() => {
    if (!parentFormErrors) {
      form.clearErrors();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentFormErrors]);

  return (
    <div
      className={cn('group relative my-2 w-full items-start gap-2 rounded-lg bg-subtle', {
        'after:absolute after:inset-0 after:rounded-lg after:bg-muted/80': isDragging,
        'pointer-events-none': isSorting
      })}
    >
      <DragButton sortableId={recordRule.id} />
      <div className="p-2">
        <div className="mb-4 flex items-center justify-between">
          <p>
            {t('record_rule_title', {
              index: recordRuleIndex + 1
            })}
          </p>
          <div>
            {shouldDisplayRemoveButton && (
              <Button size="sm" intent="minimal" onClick={onRecordRuleDelete}>
                <Button.Icon icon={RemoveIcon} className="text-subtle" />
              </Button>
            )}
            <Button size="sm" intent="minimal" onClick={() => onRecordRuleDuplicate(recordRule)}>
              <Button.Icon icon={DuplicateIcon} className="text-subtle" />
            </Button>
          </div>
        </div>
        <FormProvider {...form}>
          <RecordRuleFormFields
            shouldIgnoreCriteria
            valueGroupClassName="p-0"
            recordRule={recordRule}
            sourceObject={sourceObject}
            labels={{ actionType: 'Action' }}
            sourcePage={page}
          />
        </FormProvider>
      </div>
    </div>
  );
}
