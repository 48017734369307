import { useTranslation } from 'react-i18next';
import { type IconType } from 'react-icons';
import { HiTrash as DeleteIcon, HiPencil as EditIcon } from 'react-icons/hi2';
import { Button, Checkbox, Label } from '@knack/asterisk-react';

import { cn } from '@/utils/tailwind';

interface EditableItemListProps {
  className?: string;
  children: React.ReactNode;
}

interface EditableItemListItemProps {
  itemName: string;
  isChecked?: boolean;
  isDisabled?: boolean;
  isEditDisabled?: boolean;
  itemIcon?: IconType;
  itemIconElement?: React.ReactNode;
  onCheck?: (isChecked: boolean) => void;
  onEditClick?: () => void;
  onDeleteClick?: () => void;
}

function EditableItemListItem({
  itemName,
  itemIcon: ItemIcon,
  itemIconElement: ItemIconElement,
  isChecked = false,
  isDisabled = false,
  isEditDisabled = false,
  onCheck,
  onEditClick,
  onDeleteClick
}: EditableItemListItemProps) {
  const [t] = useTranslation();

  return (
    <div
      className={cn(
        'group flex h-9 items-center rounded-lg border border-default bg-card py-1 pl-2.5 pr-2 hover:border-emphasis hover:bg-muted',
        {
          'border-emphasis': isChecked && !isDisabled,
          'bg-muted hover:border-default': isDisabled
        }
      )}
    >
      <Label className="-ml-1 flex items-center truncate py-1 pl-1 text-emphasis">
        {onCheck && (
          <Checkbox
            disabled={isDisabled}
            className="mr-2"
            checked={isChecked}
            onCheckedChange={onCheck}
          />
        )}
        {ItemIcon && <ItemIcon size={16} className="shrink-0 text-subtle" />}
        {ItemIconElement && ItemIconElement}
        <span className="ml-2 truncate">{itemName}</span>
      </Label>

      {(onEditClick || onDeleteClick) && (
        <div className="ml-auto flex items-center gap-1">
          {!isDisabled && onEditClick && (
            <Button
              intent="minimal"
              size="xs"
              className="hidden size-6 rounded-md text-subtle group-hover:flex"
              disabled={isEditDisabled}
              onClick={onEditClick}
              data-testid={`edit-item-${itemName}`}
              aria-label={t('actions.edit')}
            >
              <EditIcon size={14} />
            </Button>
          )}

          {!isDisabled && onDeleteClick && (
            <Button
              intent="minimal"
              size="xs"
              className="hidden size-6 rounded-md text-subtle group-hover:flex"
              onClick={onDeleteClick}
              aria-label={t('actions.delete')}
            >
              <DeleteIcon size={14} />
            </Button>
          )}
        </div>
      )}
    </div>
  );
}

function EditableItemList({ className, children }: EditableItemListProps) {
  return <div className={cn('space-y-2', className)}>{children}</div>;
}

const CompoundEditableItemList = Object.assign(EditableItemList, {
  Item: EditableItemListItem
});

export { CompoundEditableItemList as EditableItemList };
