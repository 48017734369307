import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiPlus as PlusIcon } from 'react-icons/hi2';
import { TbSeparatorHorizontal as DividerIcon } from 'react-icons/tb';
import { Button, DropdownMenu } from '@knack/asterisk-react';
import { nanoid } from 'nanoid';

import { type CalendarView } from '@/types/schema/views/CalendarView';
import {
  FORM_VIEW_DIVIDER_INPUT_ID_PREFIX,
  type FormView,
  type FormViewDividerInput,
  type FormViewStaticInput
} from '@/types/schema/views/FormView';
import { FieldIcon } from '@/components/FieldIcon';
import { usePageEditorContext } from '@/pages/pages/page-editor/PageEditorContext';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { useUpdateView } from '@/pages/pages/settings-panel/view-settings/useUpdateView';
import { FormSectionBreakInputEditModal } from './FormSectionBreakInputEditModal';

function getDefaultDividerSchema() {
  const divider: FormViewDividerInput = {
    id: `${FORM_VIEW_DIVIDER_INPUT_ID_PREFIX}${nanoid(10)}`,
    type: 'divider',
    instructions: ''
  };

  return divider;
}

export function AddStaticInputButton() {
  const [t] = useTranslation();
  const { updatePage } = usePageEditorContext();
  const updateViewSchema = useUpdateView<CalendarView>();
  const { view } = useActiveViewContext<FormView | CalendarView>();

  const [isSectionBreakInputModalOpen, setIsSectionBreakInputModalOpen] = useState(false);

  const addInputToView = (staticInput: FormViewStaticInput) => {
    if (view.type === 'calendar') {
      updateViewSchema({
        form: {
          ...view.form,
          groups: view.form.groups.map((group) => ({
            ...group,
            columns: group.columns.map((column, columnIndex) => {
              // Add the static input to the last column
              if (columnIndex === group.columns.length - 1) {
                return { ...column, inputs: [...column.inputs, staticInput] };
              }

              return column;
            })
          }))
        }
      });
    } else {
      updatePage({
        type: 'view',
        origin: 'builder',
        action: 'update',
        viewKey: view.key,
        viewSchema: {
          groups: view.groups.map((group) => ({
            ...group,
            columns: group.columns.map((column, columnIndex) => {
              // Add the static input to the last column
              if (columnIndex === group.columns.length - 1) {
                return { ...column, inputs: [...column.inputs, staticInput] };
              }

              return column;
            })
          }))
        }
      });
    }
  };

  return (
    <>
      <DropdownMenu>
        <DropdownMenu.Trigger asChild>
          <Button intent="secondary" className="w-full">
            <PlusIcon size={16} className="mr-2" />
            {t(
              'pages.element_settings.form.categories.form_fields.field_management.add_static_input'
            )}
          </Button>
        </DropdownMenu.Trigger>
        <DropdownMenu.Content>
          <DropdownMenu.Item onClick={() => setIsSectionBreakInputModalOpen(true)}>
            <FieldIcon name="rich_text" size={16} className="mr-2 shrink-0" />
            {t(
              'pages.element_settings.form.categories.form_fields.field_management.rich_text_input'
            )}
          </DropdownMenu.Item>
          <DropdownMenu.Item onClick={() => addInputToView(getDefaultDividerSchema())}>
            <DividerIcon size={16} className="mr-2 shrink-0" />
            {t('pages.element_settings.form.categories.form_fields.field_management.divider_input')}
          </DropdownMenu.Item>
        </DropdownMenu.Content>
      </DropdownMenu>

      {isSectionBreakInputModalOpen && (
        <FormSectionBreakInputEditModal onOpenChange={setIsSectionBreakInputModalOpen} />
      )}
    </>
  );
}
