import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiPlus as PlusIcon } from 'react-icons/hi2';
import { TbSeparatorHorizontal as DividerIcon } from 'react-icons/tb';
import { Button, DropdownMenu } from '@knack/asterisk-react';

import { type DetailsViewInput } from '@/types/schema/views/DetailsView';
import { type ViewWithDetails } from '@/types/schema/views/ViewWithDetails';
import { FieldIcon } from '@/components/FieldIcon';
import { generateDetailsViewDividerInput } from '@/pages/pages/page-editor/add-view/helpers/view-schemas/detailsViewSchema';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { ViewDetailsRichTextInputAddModal } from '@/pages/pages/settings-panel/view-settings/common/details-field-management/ViewDetailsRichTextInputAddModal';
import { useUpdateViewColumns } from '@/pages/pages/settings-panel/view-settings/useUpdateViewColumns';

export function ViewDetailsAddStaticInputButton({ disabled }: { disabled?: boolean }) {
  const [t] = useTranslation();

  const { view } = useActiveViewContext<ViewWithDetails>();

  const [isSectionBreakInputModalOpen, setIsSectionBreakInputModalOpen] = useState(false);

  const updateViewColumnsSchema = useUpdateViewColumns<ViewWithDetails>();

  const columns = useMemo(() => {
    if (view.type !== 'calendar' && view.type !== 'map') {
      return view.columns;
    }
    return view.details.columns;
  }, [view]);

  const addInputToView = (detailsInput: DetailsViewInput) => {
    if (detailsInput.type !== 'divider' && detailsInput.type !== 'special_title') {
      return;
    }

    const updatedColumns = columns.map((outerColumn, outerColumnIndex) => {
      if (outerColumnIndex === columns.length - 1) {
        return {
          ...outerColumn,
          groups: outerColumn.groups.map((group, groupIndex) => {
            if (groupIndex === columns[0].groups.length - 1) {
              return {
                ...group,
                columns: group.columns.map((column, columnIndex) => {
                  // Add the new static input to the last column in the last group in the last outer column
                  if (columnIndex === group.columns.length - 1) {
                    return [...column, detailsInput];
                  }
                  return column;
                })
              };
            }
            return group;
          })
        };
      }
      return outerColumn;
    });

    if (view.type === 'calendar' || view.type === 'map') {
      updateViewColumnsSchema({
        details: {
          ...view.details,
          columns: updatedColumns
        }
      });
    }

    updateViewColumnsSchema({
      columns: updatedColumns
    });
  };

  return (
    <>
      <DropdownMenu>
        <DropdownMenu.Trigger disabled={disabled} asChild>
          <Button intent="secondary" className="w-full">
            <PlusIcon size={16} className="mr-2" />
            {t(
              'pages.element_settings.common.categories.data_display.field_management.add_static_input'
            )}
          </Button>
        </DropdownMenu.Trigger>
        <DropdownMenu.Content>
          <DropdownMenu.Item onClick={() => setIsSectionBreakInputModalOpen(true)}>
            <FieldIcon name="rich_text" size={16} className="mr-2 shrink-0" />
            {t(
              'pages.element_settings.common.categories.data_display.field_management.rich_text_input'
            )}
          </DropdownMenu.Item>
          <DropdownMenu.Item onClick={() => addInputToView(generateDetailsViewDividerInput())}>
            <DividerIcon size={16} className="mr-2 shrink-0" />
            {t(
              'pages.element_settings.common.categories.data_display.field_management.divider_input'
            )}
          </DropdownMenu.Item>
        </DropdownMenu.Content>
      </DropdownMenu>

      {isSectionBreakInputModalOpen && (
        <ViewDetailsRichTextInputAddModal onOpenChange={setIsSectionBreakInputModalOpen} />
      )}
    </>
  );
}
