import { useTranslation } from 'react-i18next';
import { Dialog } from '@knack/asterisk-react';

import { type KnackObject } from '@/types/schema/KnackObject';
import { useTrackEventsHelpers } from '@/hooks/helpers/useTrackEventsHelpers';
import { FieldSettings, type NewFieldState } from '@/components/field-settings/FieldSettings';

type AddFieldDialogProps = {
  objectKey: string;
  newFieldState: NewFieldState;
  onClose: () => void;
};

const DEFAULT_FIELD_TYPE = 'short_text';

export function AddFieldDialog({ objectKey, newFieldState, onClose }: AddFieldDialogProps) {
  const [t] = useTranslation();
  const { trackEvent } = useTrackEventsHelpers();
  return (
    <Dialog open onOpenChange={onClose}>
      <Dialog.Content data-testid="add-field-dialog">
        <Dialog.MainContent>
          <Dialog.Header>
            <Dialog.Title>{t('components.data_table.contextual_menu.add_field')}</Dialog.Title>
          </Dialog.Header>
          <div className="mt-6">
            <FieldSettings
              objectKey={objectKey as KnackObject['key']}
              initialType={DEFAULT_FIELD_TYPE}
              onCancel={onClose}
              onSave={() => {
                trackEvent('field_add');
                onClose();
              }}
              newFieldState={newFieldState}
            />
          </div>
        </Dialog.MainContent>
      </Dialog.Content>
    </Dialog>
  );
}
