import { BuilderAccordion } from '@/components/BuilderAccordion';
import { GeneralSettingsAppearance } from '@/pages/pages/settings-panel/view-settings/calendar/general/GeneralSettingsAppearance';
import { GeneralSettingsEventColor } from '@/pages/pages/settings-panel/view-settings/calendar/general/GeneralSettingsEventColor';
import { GeneralSettingsUserOptions } from '@/pages/pages/settings-panel/view-settings/calendar/general/GeneralSettingsUserOptions';
import { ViewTitleDescriptionSettings } from '@/pages/pages/settings-panel/view-settings/common/general-settings/ViewTitleDescriptionSettings';

export function GeneralSettings() {
  return (
    <BuilderAccordion>
      <ViewTitleDescriptionSettings />
      <GeneralSettingsAppearance />
      <GeneralSettingsEventColor />
      <GeneralSettingsUserOptions />
    </BuilderAccordion>
  );
}
