import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HiPencil as EditIcon, HiPlus as PlusIcon } from 'react-icons/hi2';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Dialog } from '@knack/asterisk-react';
import { z } from 'zod';

import { type KnackCriteria } from '@/types/schema/KnackCriteria';
import { type KnackFilter } from '@/types/schema/KnackFilter';
import { type KnackObject } from '@/types/schema/KnackObject';
import { useCriteriaHelpers } from '@/hooks/helpers/useCriteriaHelpers';
import { useObjectHelpers } from '@/hooks/helpers/useObjectHelpers';
import { cn } from '@/utils/tailwind';
import { CriteriaForm } from '@/components/CriteriaForm';

export type TableViewColumnFiltersData = {
  criteria: KnackFilter[];
};

interface TableColumnFiltersDialogContentProps {
  filters: KnackFilter[];
  sourceObject: KnackObject;
  onFormSubmit: (data: TableViewColumnFiltersData) => void;
}

function TableColumnFiltersDialogContent({
  filters,
  sourceObject,
  onFormSubmit
}: TableColumnFiltersDialogContentProps) {
  const [t] = useTranslation();
  const { validateCriteriaValues } = useCriteriaHelpers();

  const tableColumnFiltersFormSchema = z
    .object({
      criteria: z.custom<KnackCriteria[]>()
    })
    .superRefine((data, context) => {
      const criteriaValueErrors = validateCriteriaValues(data.criteria, sourceObject.fields);

      if (criteriaValueErrors.length) {
        criteriaValueErrors.forEach((error) => {
          context.addIssue({
            path: error.path,
            message: t(error.message || 'errors.value_required'),
            code: 'custom'
          });
        });
      }
    });

  const form = useForm<TableViewColumnFiltersData>({
    resolver: zodResolver(tableColumnFiltersFormSchema),
    defaultValues: {
      criteria: filters
    }
  });

  const hasFilters = form.getValues('criteria').length > 0;

  return (
    <FormProvider {...form}>
      <form className="w-full" onSubmit={form.handleSubmit(onFormSubmit)}>
        <Dialog.MainContent>
          <Dialog.Header>
            <Dialog.Title>
              {t(
                'pages.element_settings.table.categories.data_display.field_management.column_filters'
              )}
            </Dialog.Title>
            <Dialog.Description className="text-xs text-subtle">
              {t(
                'pages.element_settings.common.categories.data_display.filtering_section.default_filters_description'
              )}
            </Dialog.Description>
          </Dialog.Header>
          <div>
            {hasFilters && (
              <p className="my-4">
                {t(
                  'pages.element_settings.common.categories.data_display.general_settings.show_the_records_match'
                )}
              </p>
            )}
            <div
              className={cn({
                'rounded-lg bg-subtle': hasFilters
              })}
            >
              <CriteriaForm criteriaType="filter" sourceObject={sourceObject} />
            </div>
          </div>
        </Dialog.MainContent>
        <Dialog.Footer>
          <Dialog.Close asChild>
            <Button intent="minimal">{t('actions.cancel')}</Button>
          </Dialog.Close>
          <Button type="submit">{t('actions.apply')}</Button>
        </Dialog.Footer>
      </form>
    </FormProvider>
  );
}

export function TableViewColumnSettingsFiltersDialog({ column, field, updateViewColumn }) {
  const [t] = useTranslation();
  const { getObjectByKey } = useObjectHelpers();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleColumnFiltersSubmit = (data: TableViewColumnFiltersData) => {
    updateViewColumn({
      source: {
        filters: data.criteria
      }
    });

    setIsDialogOpen(false);
  };

  const sourceObject = getObjectByKey(field.relationship.object);

  if (!sourceObject) {
    return null;
  }

  const columnFilters = column?.source?.filters || [];

  return (
    <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <Dialog.Trigger asChild>
        <Button intent="secondary">
          {columnFilters.length ? (
            <EditIcon size={16} className="mr-1" />
          ) : (
            <PlusIcon size={16} className="mr-1" />
          )}
          {t('keywords.filters')}
        </Button>
      </Dialog.Trigger>
      <Dialog.Content>
        <TableColumnFiltersDialogContent
          sourceObject={sourceObject}
          filters={columnFilters}
          onFormSubmit={handleColumnFiltersSubmit}
        />
      </Dialog.Content>
    </Dialog>
  );
}
