import { useEffect, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Label, Select } from '@knack/asterisk-react';
import { type z } from 'zod';

import {
  type AverageField,
  type MaxField,
  type MinField,
  type SumField
} from '@/types/schema/fields';
import { type KnackField, type KnackFieldKey } from '@/types/schema/KnackField';
import { type KnackConnection } from '@/types/schema/KnackObject';
import { NUMERIC_FIELD_TYPES } from '@/hooks/helpers/useFieldHelpers';
import { useApplication } from '@/hooks/useApplication';
import { type aggregateSchema } from '@/components/field-settings/aggregate/AggregateFormSettings';

type AggregateFieldSelectorProps = {
  field: AggregateField;
  aggregateFieldConnections: KnackConnection[];
};

type AggregateField = SumField | MaxField | MinField | AverageField;

type AggregateFieldSchema = z.infer<typeof aggregateSchema>;

export function AggregateFieldSelector({
  field,
  aggregateFieldConnections
}: AggregateFieldSelectorProps) {
  const [t] = useTranslation();

  const application = useApplication();
  const knackObjects = application?.objects;

  const { setValue, getValues, control } = useFormContext<AggregateFieldSchema>();

  const connectedTables = useMemo(
    // get the tables that are in the aggregateFieldConnections
    () =>
      knackObjects?.filter((object) =>
        aggregateFieldConnections?.some((conn) => conn.object === object.key)
      ),
    [knackObjects, aggregateFieldConnections]
  );

  const numericConnectedFields = useMemo(() => {
    if (!connectedTables) return [];

    const connectedFields: (KnackField & {
      tableKey: string;
      tableName: string;
      connectionField?: {
        key: KnackFieldKey;
        name: string;
      };
    })[] = [];
    connectedTables.forEach((table) => {
      table.fields.forEach((connectedField) => {
        const connectedFieldConnection = aggregateFieldConnections?.find(
          (conn) => conn.object === table.key
        );

        if (NUMERIC_FIELD_TYPES.includes(connectedField?.type)) {
          connectedFields.push({
            ...connectedField,
            tableKey: table.key,
            tableName: table.name,
            ...(connectedFieldConnection && {
              connectionField: {
                key: connectedFieldConnection.key || '',
                name: connectedFieldConnection.name || ''
              }
            })
          });
        }
      });
    });
    return connectedFields;
  }, [connectedTables, aggregateFieldConnections]);

  // Initialize the connection field on the Add new field form
  useEffect(() => {
    if (!field.format?.connection && numericConnectedFields[0].connectionField?.key) {
      setValue('format.connection.key', numericConnectedFields[0].connectionField.key);
    }
  }, [numericConnectedFields, setValue, getValues, field.format]);

  const defaultFormatFieldKey = field.format?.field.key || numericConnectedFields[0].key;

  return (
    numericConnectedFields?.length > 0 && (
      <div className="flex flex-col gap-2">
        <Label htmlFor="connection-item-value">
          {t('components.data_table.attributes.field_settings.aggregate.field_to_type_label', {
            type: field?.type
          })}
        </Label>
        <Controller
          control={control}
          name="format.field.key"
          defaultValue={defaultFormatFieldKey}
          render={({ field: formField }) => (
            <Select
              defaultValue={formField.value}
              onValueChange={(value) => {
                const connectedField = numericConnectedFields.find((f) => f.key === value);
                if (connectedField?.connectionField?.key) {
                  setValue('format.connection.key', connectedField.connectionField.key);
                }
                formField.onChange(value);
              }}
            >
              <Select.Trigger className="w-full truncate" />
              <Select.Content>
                {numericConnectedFields?.map((f) => (
                  <Select.Item key={f.key} value={f.key} className="truncate">
                    {`${f.tableName} (${f.connectionField?.name}) > ${f.name}`}
                  </Select.Item>
                ))}
              </Select.Content>
            </Select>
          )}
        />
      </div>
    )
  );
}
