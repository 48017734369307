import { useTranslation } from 'react-i18next';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { Tabs, type TabsListItem } from '@knack/asterisk-react';

import { ROUTES } from '@/Router';

export function SettingsPageCodeSection() {
  const { pathname } = useLocation();
  const [t] = useTranslation();
  const tabsListItems: TabsListItem[] = [
    {
      children: <Link to={ROUTES.SETTINGS_CODE_API}>{t('app_settings.sections.api')}</Link>,
      value: ROUTES.SETTINGS_CODE_API
    },
    {
      children: (
        <Link to={ROUTES.SETTINGS_CODE_CSS}>{t('app_settings.code.css')} (Coming Soon)</Link>
      ),
      value: ROUTES.SETTINGS_CODE_CSS,
      isDisabled: true
    },
    {
      children: (
        <Link to={ROUTES.SETTINGS_CODE_JAVASCRIPT}>
          {t('app_settings.code.javascript')} (Coming Soon)
        </Link>
      ),
      value: ROUTES.SETTINGS_CODE_JAVASCRIPT,
      isDisabled: true
    }
  ];

  return (
    <div>
      <Tabs value={pathname}>
        <Tabs.List
          className="w-auto"
          items={tabsListItems}
          shouldDisableResponsive
          intent="page"
          shouldUseGradient
        />
      </Tabs>
      <Outlet />
    </div>
  );
}
