import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from '@knack/asterisk-react';

import { type KnackField, type KnackFieldKey } from '@/types/schema/KnackField';
import { FieldIcon } from '@/components/FieldIcon';

type FieldSelectProps = {
  id: string;
  fields: KnackField[] | undefined;
  defaultValue?: KnackFieldKey;
  onFieldChange: (newFieldKey: KnackFieldKey) => void;
};

export const FieldSelect = forwardRef<HTMLButtonElement, FieldSelectProps>(
  ({ id, fields, defaultValue, onFieldChange, ...props }, forwardedRef) => {
    const [t] = useTranslation();

    return (
      <Select onValueChange={onFieldChange} defaultValue={defaultValue}>
        <Select.Trigger
          id={id}
          placeholder={t('actions.select')}
          className="w-full truncate"
          data-testid="field-select"
          ref={forwardedRef}
          {...props}
        />
        <Select.Content className="min-w-52">
          {fields?.map((field) => (
            <Select.Item key={field.key} value={field.key} data-testid={`field-item-${field.key}`}>
              <span className="flex items-center gap-2" data-testid="field-select-item">
                <FieldIcon name={field.type} size={16} className="flex-shrink-0" />
                <span className="truncate">{field.name}</span>
              </span>
            </Select.Item>
          ))}
        </Select.Content>
      </Select>
    );
  }
);

FieldSelect.displayName = 'FieldSelect';
