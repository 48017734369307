import { generatePath, useNavigate, useSearchParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import FormData from 'form-data';

import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { getBuilderBaseUrl } from '@/utils/application';
import { axiosInstance } from '@/utils/axiosConfig';
import { isFlagEnabled } from '@/utils/flagsmith';
import {
  type DefaultValue,
  type MatchRule,
  type SerializedMappedColumn,
  type SerializedNewColumn
} from '@/components/import/types';
import { ROUTES } from '@/Router';

async function importGoogleSheets({
  action = 'create',
  hasHeaderRow,
  match,
  serializedNewColumns = [],
  serializedMappedColumns = [],
  fileName,
  tableKeyToUpdate,
  token,
  isUser = false,
  selectedSheetIndex,
  shouldSkipRecordsWithErrors,
  defaultValues = [],
  spreadsheetId
}: {
  action?: 'create' | 'update';
  hasHeaderRow: boolean;
  match: { updateRecords: boolean; insertUnmatched: boolean; rules: MatchRule[] };
  serializedNewColumns?: SerializedNewColumn[];
  serializedMappedColumns?: SerializedMappedColumn[];
  fileName?: string;
  tableKeyToUpdate?: string;
  file: google.picker.DocumentObject;
  token: string;
  isUser?: boolean;
  selectedSheetIndex?: number;
  shouldSkipRecordsWithErrors: boolean;
  defaultValues?: DefaultValue[];
  spreadsheetId: string;
}) {
  const form = new FormData();
  form.append('importAction', action);
  form.append('hasHeaderRow', hasHeaderRow.toString());
  form.append('match', JSON.stringify(match));
  form.append('mappedFields', JSON.stringify(serializedMappedColumns));
  form.append('newFields', JSON.stringify(serializedNewColumns));
  form.append('defaultValues', JSON.stringify(defaultValues));
  form.append('isUser', JSON.stringify(isUser));
  form.append('token', token);
  form.append('shouldSkipRecordsWithErrors', shouldSkipRecordsWithErrors.toString());
  form.append('spreadsheetId', spreadsheetId);

  if (fileName) {
    form.append('objectName', fileName);
  }

  if (selectedSheetIndex !== undefined) {
    form.append('selectedSheetIndex', selectedSheetIndex.toString());
  }

  if (action === 'update' && tableKeyToUpdate) {
    form.append('objectKey', tableKeyToUpdate);
  }

  const endpoint = '/v1/import/google-sheets';

  const response = await axiosInstance.post(endpoint, form, { withCredentials: true });

  return response.data;
}

export function useImportGoogleSheetsMutation(existingObjectKey?: string) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { refetch: refetchApp } = useApplicationQuery();
  const isNextGenAccess =
    isFlagEnabled('full_nextgen_access') || isFlagEnabled('only_nextgen_access');

  return useMutation({
    mutationFn: importGoogleSheets,
    onSuccess: async (response) => {
      const shouldRedirectToOldBuilder =
        (searchParams.has('origin') && searchParams.get('origin') === 'v3') || !isNextGenAccess;

      const tableKey = response.changes.inserts.objects[0]?.key || existingObjectKey;
      await refetchApp();

      if (shouldRedirectToOldBuilder) {
        const builderBaseUrl = getBuilderBaseUrl();
        const oldBuilderPath = `${builderBaseUrl}/records/objects/${tableKey}`;
        window.location.href = oldBuilderPath;
        return;
      }

      const newBuilderPath = `${generatePath(ROUTES.TABLES_ID, {
        id: tableKey
      })}`;

      navigate(newBuilderPath);
    }
  });
}
