import { useTranslation } from 'react-i18next';
import { HiArrowLeft as BackIcon } from 'react-icons/hi2';
import { Button, Dialog } from '@knack/asterisk-react';

interface DynamicActionDialogFooterProps {
  formStep: number;
  setFormStep: React.Dispatch<React.SetStateAction<number>>;
}

export function DynamicActionsDialogFooter({
  formStep,
  setFormStep
}: DynamicActionDialogFooterProps) {
  const [t] = useTranslation();

  return (
    <Dialog.Footer className="justify-between">
      <div>
        {formStep === 2 && (
          <Button intent="minimal" onClick={() => setFormStep(formStep - 1)} className="gap-1">
            <Button.Icon icon={BackIcon} />
            {t('actions.back')}
          </Button>
        )}
      </div>
      <div>
        <Dialog.Close asChild>
          <Button intent="minimal">{t('actions.cancel')}</Button>
        </Dialog.Close>
        <Button type="submit">{formStep === 1 ? t('actions.next') : t('actions.apply')}</Button>
      </div>
    </Dialog.Footer>
  );
}
