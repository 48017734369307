import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MultiSelect, Select } from '@knack/asterisk-react';

import { type KnackObject } from '@/types/schema/KnackObject';
import { useObjectHelpers } from '@/hooks/helpers/useObjectHelpers';

interface UserRolesInputProps {
  name: string;
  id: string;
  isCriteria?: boolean;
  selectedRoleObject?: KnackObject;
}

export function UserRolesInput({ name, id, isCriteria, selectedRoleObject }: UserRolesInputProps) {
  const [t] = useTranslation();
  const { getRoleObjects } = useObjectHelpers();
  const roleObjects = getRoleObjects();

  if (isCriteria) {
    return (
      <Controller
        name={name}
        render={({ field }) => (
          <Select onValueChange={field.onChange} defaultValue={field.value || ''}>
            <Select.Trigger
              id={id}
              placeholder={t('actions.select')}
              className="w-full truncate"
              {...field}
            />
            <Select.Content>
              {roleObjects.map((option) => (
                <Select.Item key={option.profile_key} value={option.profile_key || ''}>
                  {option.name}
                </Select.Item>
              ))}
            </Select.Content>
          </Select>
        )}
      />
    );
  }

  return (
    <Controller
      name={name}
      render={({ field }) => {
        const fieldValues =
          (field.value &&
            field.value.map((val: string) => {
              const matchedItem = roleObjects.find((item) => item.profile_key === val);
              return {
                label: matchedItem?.name || val,
                value: val,
                key: val
              };
            })) ||
          [];

        return (
          <MultiSelect
            id={id}
            maxVisibleChips={5}
            isSearchEnabled
            options={
              roleObjects.map((item) => ({
                label: item.name,
                key: item.profile_key || ''
              })) || []
            }
            // to have the currentRole always show up first in the list
            selectedOptions={
              selectedRoleObject && selectedRoleObject.profile_key
                ? [
                    {
                      label: selectedRoleObject.name,
                      key: selectedRoleObject.profile_key
                    },
                    ...fieldValues.filter((val) => val.key !== selectedRoleObject.profile_key)
                  ]
                : fieldValues
            }
            onSelectOptions={(values) => {
              const updatedValues = values.map((val) => val.key);
              if (selectedRoleObject && selectedRoleObject.profile_key) {
                if (!updatedValues.includes(selectedRoleObject.profile_key)) {
                  updatedValues.unshift(selectedRoleObject.profile_key);
                }
              }
              field.onChange(updatedValues);
            }}
          />
        );
      }}
    />
  );
}
