import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IMask } from 'react-imask';
import { Label, Select, Switch } from '@knack/asterisk-react';
import { z } from 'zod';

import { PHONE_FIELD_FORMATS, type PhoneField } from '@/types/schema/fields';
import { getMaskConfig } from '@/components/data-table/display/fields/phone/PhoneEdit';

type PhoneFormSettingsProps = {
  field: PhoneField;
};

export const phoneSchema = z.object({
  format: z.object({
    format: z.enum(PHONE_FIELD_FORMATS),
    extension: z.boolean()
  })
});

export function PhoneFormSettings({ field }: PhoneFormSettingsProps) {
  const [t] = useTranslation();
  const { setValue, getValues } = useFormContext();

  const updateDefaultValueWithMask = (newFormat: string, hasExtension: boolean) => {
    const defaultValue = getValues('default');
    if (defaultValue) {
      const maskConfig = getMaskConfig(newFormat, hasExtension);
      const pipeConfig =
        maskConfig.type === 'pattern'
          ? { mask: maskConfig.mask, placeholderChar: maskConfig.placeholderChar }
          : { mask: maskConfig.mask };

      const pipe = IMask.createPipe(pipeConfig);
      const newValue = pipe(defaultValue);

      if (newValue !== defaultValue) {
        setValue('default', newValue);
      }
    }
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-2">
        <Label htmlFor="phone-format-select">
          {t('components.data_table.attributes.field_settings.phone.format_label')}
        </Label>
        <Controller
          name="format.format"
          defaultValue={field?.format?.format || PHONE_FIELD_FORMATS[0]}
          render={({ field: currentField }) => (
            <Select
              onValueChange={(value) => {
                currentField.onChange(value);
                const hasExtension = getValues('format.extension');
                updateDefaultValueWithMask(value, hasExtension);
              }}
              defaultValue={currentField.value || PHONE_FIELD_FORMATS[0]}
            >
              <Select.Trigger
                id="phone-format-select"
                placeholder={t('actions.select')}
                className="w-full rounded-lg"
                data-testid="phone-form-settings-format-select"
                {...currentField}
              />
              <Select.Content>
                {PHONE_FIELD_FORMATS.map((option) => (
                  <Select.Item
                    key={option}
                    value={option}
                    data-testid={`phone-settings-format-value-${option}`}
                  >
                    {t(
                      `components.data_table.attributes.field_settings.phone.format_options.${option}`
                    )}
                  </Select.Item>
                ))}
              </Select.Content>
            </Select>
          )}
        />
      </div>

      <div className="flex items-center">
        <Controller
          name="format.extension"
          defaultValue={field?.format?.extension || false}
          render={({ field: { onChange, value } }) => (
            <Switch
              id="extension-switch"
              className="mr-2"
              data-testid="extension-value-switch"
              checked={value}
              onCheckedChange={(checked) => {
                onChange(checked);
                const format = getValues('format.format');
                updateDefaultValueWithMask(format, checked);
              }}
            />
          )}
        />
        <Label htmlFor="extension-switch" className="font-medium">
          {t('components.data_table.attributes.field_settings.phone.include_extension_label')}
        </Label>
      </div>
    </div>
  );
}
