import { Trans, useTranslation } from 'react-i18next';
import { Button, InputSearch } from '@knack/asterisk-react';

import { type KnackField } from '@/types/schema/KnackField';
import { type KnackObject } from '@/types/schema/KnackObject';
import { type FormViewFieldInput } from '@/types/schema/views/FormView';
import { FormFieldInputItem } from '@/pages/pages/settings-panel/view-settings/form/field-management/FormFieldInputItem';

type FormFieldsType = {
  activeFieldInputs: FormViewFieldInput[];
  allowedFields: KnackField[];
  disallowedFieldsInTheForm: FormViewFieldInput[];
  visibleAllowedFields: KnackField[];
  visibleActiveFieldInputs: FormViewFieldInput[];
  visibleFields: KnackField[];
  visibleDisallowedFields: KnackField[];
  searchValue: string;
  onSearch: (value: string) => void;
  onAddAllFieldInputs: () => void;
  onRemoveAllFieldInputs: () => void;
  onAddFieldInput: (field: KnackField) => void;
  onRemoveFieldInput: (field: KnackField) => void;
  sourceObject: KnackObject;
};

export function FormFields({
  activeFieldInputs,
  allowedFields,
  disallowedFieldsInTheForm,
  visibleAllowedFields,
  visibleActiveFieldInputs,
  visibleFields,
  visibleDisallowedFields,
  searchValue,
  onSearch,
  onAddAllFieldInputs,
  onRemoveAllFieldInputs,
  onAddFieldInput,
  onRemoveFieldInput,
  sourceObject
}: FormFieldsType) {
  const [t] = useTranslation();
  return (
    <>
      <div className="mb-2 flex items-center gap-1">
        <span className="text-xs font-medium text-emphasis">
          {t('pages.element_settings.form.categories.form_fields.field_management.manage_fields')}
        </span>
        <span className="rounded-sm bg-subtle px-1 py-0.5 text-xs font-medium text-default">
          {t(
            'pages.element_settings.form.categories.form_fields.field_management.active_inputs_count',
            {
              currentCount: activeFieldInputs.length,
              totalCount: allowedFields.length + disallowedFieldsInTheForm.length
            }
          )}
        </span>
      </div>
      <p className="mb-4 text-xs text-subtle">
        {t(
          'pages.element_settings.form.categories.form_fields.field_management.manage_fields_description'
        )}
      </p>
      <InputSearch
        className="mb-4"
        aria-label={t(
          'pages.element_settings.form.categories.form_fields.field_management.search_fields'
        )}
        placeholder={t(
          'pages.element_settings.form.categories.form_fields.field_management.search_fields'
        )}
        value={searchValue}
        onChange={(e) => onSearch(e.target.value)}
      />
      <div className="mb-4 flex gap-3">
        <Button
          disabled={
            visibleAllowedFields.length === 0 ||
            visibleAllowedFields.length === visibleActiveFieldInputs.length ||
            visibleFields.length === 0
          }
          intent="link"
          className="text-xs"
          onClick={onAddAllFieldInputs}
        >
          {t('pages.element_settings.form.categories.form_fields.field_management.add_all')}
        </Button>
        <Button
          disabled={visibleActiveFieldInputs.length === 0 || visibleFields.length === 0}
          intent="link"
          className="text-xs"
          onClick={onRemoveAllFieldInputs}
        >
          {t('pages.element_settings.form.categories.form_fields.field_management.remove_all')}
        </Button>
      </div>
      {visibleAllowedFields.length > 0 && (
        <div className="space-y-2">
          {visibleAllowedFields.map((field) => {
            const fieldInput = activeFieldInputs.find((input) => input.field.key === field.key);

            return (
              <FormFieldInputItem
                key={field.key}
                field={field}
                fieldInput={fieldInput}
                isChecked={fieldInput?.field.key === field.key}
                onCheck={(isChecked) => {
                  if (isChecked) {
                    onAddFieldInput(field);
                  } else {
                    onRemoveFieldInput(field);
                  }
                }}
              />
            );
          })}
        </div>
      )}
      {visibleDisallowedFields.length > 0 && (
        <div className="mt-6">
          <p className="mb-2 text-xs font-medium text-emphasis">
            {t(
              'pages.element_settings.form.categories.form_fields.field_management.disallowed_fields'
            )}
          </p>
          <div className="space-y-2">
            {visibleDisallowedFields.map((field) => {
              // Determine if the disallowed field is already in the form so we can show it as checked
              const isChecked = disallowedFieldsInTheForm.some((f) => f.field.key === field.key);

              return (
                <FormFieldInputItem
                  key={field.key}
                  field={field}
                  isDisallowed
                  isChecked={isChecked}
                />
              );
            })}
          </div>
        </div>
      )}
      {visibleFields.length === 0 && (
        <div className="text-subtle">
          <Trans
            i18nKey="pages.element_settings.form.categories.form_fields.field_management.no_results"
            components={[<p key="0" className="mb-2" />, <p key="1" />]}
            values={{
              objectName: sourceObject.name
            }}
          />
        </div>
      )}
    </>
  );
}
