import { type MapView } from '@/types/schema/views/MapView';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { useRestrictedConnectionHelpers } from '@/pages/pages/settings-panel/view-settings/common/filtering/restricted-connections/useRestrictedConnectionHelpers';
import {
  ViewSettingsFiltering,
  type UpdateViewSchemaOptions
} from '@/pages/pages/settings-panel/view-settings/common/filtering/ViewSettingsFiltering';
import { useUpdateView } from '@/pages/pages/settings-panel/view-settings/useUpdateView';

export function MapSettingsFiltering() {
  const { view, sourceObject } = useActiveViewContext<MapView>();
  const updateViewSchema = useUpdateView<MapView>();

  const { restrictedConnectionFields } = useRestrictedConnectionHelpers();

  const mapViewFilteringOptions = {
    filter_type: view.details.filter_type,
    filter_fields: view.details.filter_fields,
    preset_filters: view.details.preset_filters,
    menu_filters: view.details.menu_filters,
    ...(view.details.filter_connection_sources && {
      filter_connection_sources: view.details.filter_connection_sources
    })
  };

  const updateMapViewSchema = (options: Partial<UpdateViewSchemaOptions>) =>
    updateViewSchema({ details: { ...view.details, ...options } });

  return (
    <ViewSettingsFiltering
      sourceObject={sourceObject}
      options={mapViewFilteringOptions}
      updateViewSchema={updateMapViewSchema}
      restrictedConnectionFields={restrictedConnectionFields}
    />
  );
}
