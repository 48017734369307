import { useEffect } from 'react';
import { type FieldError, type FieldErrorsImpl, type Merge } from 'react-hook-form';
import { DateTimePicker, Label, Select } from '@knack/asterisk-react';
import { t } from 'i18next';
import get from 'lodash.get';
import { DateTime } from 'luxon';

import { type DateTimeField } from '@/types/schema/fields';
import { type KnackCriteria, type KnackCriteriaDateTimeValue } from '@/types/schema/KnackCriteria';
import { useCriteriaDateTimeHelpers } from '@/hooks/helpers/useCriteriaDateTimeHelpers';
import { isDateTimeExtendedRangeOperator } from '@/utils/field-operators';
import {
  DEFAULT_LUXON_DATE,
  getLuxonDateFormatFromFieldFormat
} from '@/components/knack-date-picker/transformers/DateTimeFormatToLuxonFormat';
import { isDate } from '@/components/knack-date-picker/transformers/JsDateToKnackDateTransformer';

const getDaysOfWeek = () => [
  { label: t('attributes.field_labels.date_time.weekdays.monday'), value: '1' },
  { label: t('attributes.field_labels.date_time.weekdays.tuesday'), value: '2' },
  { label: t('attributes.field_labels.date_time.weekdays.wednesday'), value: '3' },
  { label: t('attributes.field_labels.date_time.weekdays.thursday'), value: '4' },
  { label: t('attributes.field_labels.date_time.weekdays.friday'), value: '5' },
  { label: t('attributes.field_labels.date_time.weekdays.saturday'), value: '6' },
  { label: t('attributes.field_labels.date_time.weekdays.sunday'), value: '0' }
];

interface DateTimeExtendedRangeCriteriaInputProps {
  field: DateTimeField;
  criteria: KnackCriteria;
  onChange: (criteria: KnackCriteria) => void;
  errors: Merge<FieldError, FieldErrorsImpl<KnackCriteria>> | undefined;
}

function DayOfWeekInput({
  criteria,
  onChange
}: {
  criteria: KnackCriteria;
  onChange: (criteria: KnackCriteria) => void;
}) {
  const daysOfWeek = getDaysOfWeek();

  // This effect is needed on first mount in order to ensure that the criteria has valid defaults
  useEffect(() => {
    const isValueValid = daysOfWeek.some((day) => day.value === criteria.value);

    onChange({
      ...criteria,

      // Reset any other properties related to extended range operators
      ...((criteria.from_date !== undefined || criteria.to_date !== undefined) && {
        from_date: undefined,
        to_date: undefined
      }),

      // If the value is not valid, set it Monday (1)
      ...(!isValueValid && { value: '1' })
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value =
    daysOfWeek.find((day) => {
      // If the operator is "is a day of the week" the value should be a string, but it's possible for it to be a number due to messy schema shenanigans, so we need to handle both cases
      if (typeof criteria.value === 'string' || typeof criteria.value === 'number') {
        return day.value === criteria.value.toString();
      }
      return false;
    })?.value ?? '1';

  return (
    <Select
      value={value}
      onValueChange={(selectedDay: string) => {
        onChange({ ...criteria, value: selectedDay });
      }}
    >
      <Select.Trigger className="w-full truncate" data-testid="day-of-week-select" />
      <Select.Content>
        {daysOfWeek.map((day) => (
          <Select.Item
            key={day.value}
            value={day.value}
            data-testid={`day-of-week-select-${day.value}`}
          >
            {day.label}
          </Select.Item>
        ))}
      </Select.Content>
    </Select>
  );
}

function BetweenDaysInput({
  criteria,
  onChange
}: {
  criteria: KnackCriteria;
  onChange: (criteria: KnackCriteria) => void;
}) {
  const daysOfWeek = getDaysOfWeek();

  // This effect is needed on first mount in order to ensure that the criteria has valid defaults
  useEffect(() => {
    if (criteria.operator === 'is between days of the week') {
      const isFromValueValid =
        typeof criteria.from_date === 'number' &&
        daysOfWeek.some((day) => day.value === criteria.from_date?.toString());
      const isToValueValid =
        typeof criteria.to_date === 'number' &&
        daysOfWeek.some((day) => day.value === criteria.to_date?.toString());

      onChange({
        ...criteria,

        // If either value is not valid, set them to Monday (1) and Friday (5)
        ...((!isFromValueValid || !isToValueValid) && { from_date: 1, to_date: 5 }),

        // Reset the value property in the criteria since it is not used for this operator
        value: ''
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fromValue =
    daysOfWeek.find((day) => {
      if (typeof criteria.from_date === 'number') {
        return day.value === criteria.from_date.toString();
      }
      return false;
    })?.value ?? '1';

  const toValue =
    daysOfWeek.find((day) => {
      if (typeof criteria.to_date === 'number') {
        return day.value === criteria.to_date.toString();
      }
      return false;
    })?.value ?? '5';

  return (
    <div>
      <Label className="mb-2 block font-medium" htmlFor="from_date">
        {t('attributes.field_labels.date_time.from')}
      </Label>
      <Select
        value={fromValue}
        onValueChange={(selectedDay) => {
          onChange({ ...criteria, from_date: parseInt(selectedDay, 10) });
        }}
      >
        <Select.Trigger
          id="from_date"
          className="w-full truncate"
          data-testid="day-of-week-start-select"
        />
        <Select.Content>
          {daysOfWeek.map((day) => (
            <Select.Item
              key={day.value}
              value={day.value}
              data-testid={`day-of-week-start-select-${day.value}`}
            >
              {day.label}
            </Select.Item>
          ))}
        </Select.Content>
      </Select>

      <Label className="my-2 block font-medium" htmlFor="to_date">
        {t('attributes.field_labels.date_time.to')}
      </Label>
      <Select
        value={toValue}
        onValueChange={(selectedDay) => {
          onChange({ ...criteria, to_date: parseInt(selectedDay, 10) });
        }}
      >
        <Select.Trigger
          id="to_date"
          className="w-full truncate"
          data-testid="day-of-week-end-select"
        />
        <Select.Content>
          {daysOfWeek.map((day) => (
            <Select.Item
              key={day.value}
              value={day.value}
              data-testid={`day-of-week-end-select-${day.value}`}
            >
              {day.label}
            </Select.Item>
          ))}
        </Select.Content>
      </Select>
    </div>
  );
}

function BetweenDatesInput({
  field,
  criteria,
  onChange,
  errors
}: DateTimeExtendedRangeCriteriaInputProps) {
  const { getDefaultCriteriaDateValue } = useCriteriaDateTimeHelpers();

  const criteriaValue = criteria.value as KnackCriteriaDateTimeValue;

  // If there isn't a value for `value.from_date.date` or `value.to_date.date` set, try to use the date from `value.date` instead. If there isn't a value for `value.date` set, use the default date value.
  const fromDate =
    criteriaValue.from_date?.date ||
    criteriaValue.date ||
    getDefaultCriteriaDateValue(field.format);

  // If there isn't a value for `value.to_date.date` set, use the same date as `value.from_date`
  const toDate = criteriaValue.to_date?.date || fromDate;

  const defaultDateFormat = field.format.date_format || 'mm/dd/yyyy';
  const normalizedDateFormat =
    getLuxonDateFormatFromFieldFormat(defaultDateFormat) || DEFAULT_LUXON_DATE;

  const formattedFromDate = fromDate
    ? DateTime.fromFormat(fromDate, normalizedDateFormat).toJSDate()
    : undefined;
  const formattedToDate = toDate
    ? DateTime.fromFormat(toDate, normalizedDateFormat).toJSDate()
    : undefined;

  const hasFromDateError = get(errors, 'value.from_date.date');
  const hasToDateError = get(errors, 'value.to_date.date');

  // This effect is needed on first mount in order to ensure that the criteria has valid defaults for each of the extended range operators properties
  useEffect(() => {
    onChange({
      ...criteria,

      // Set the proper values
      value: {
        date: fromDate,
        from_date: {
          date: fromDate
        },
        to_date: {
          date: toDate
        }
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-col">
      <Label className="mb-2 block font-medium" htmlFor="from_date">
        {t('attributes.field_labels.date_time.from')}
      </Label>
      <DateTimePicker
        mode="single"
        shouldDisableTimeInsideCalendar
        defaultValue={formattedFromDate}
        intent={hasFromDateError ? 'destructive' : 'default'}
        format={{
          date: normalizedDateFormat
        }}
        onChange={(newValue) => {
          if (isDate(newValue)) {
            const formattedDate = DateTime.fromJSDate(newValue).toFormat(normalizedDateFormat);
            onChange({
              ...criteria,
              value: {
                ...criteriaValue,
                date: formattedDate,
                from_date: {
                  date: formattedDate
                }
              }
            });
          }
        }}
      />

      <Label className="my-2 block font-medium" htmlFor="to_date">
        {t('attributes.field_labels.date_time.to')}
      </Label>

      <DateTimePicker
        mode="single"
        shouldDisableTimeInsideCalendar
        defaultValue={formattedToDate}
        intent={hasToDateError ? 'destructive' : 'default'}
        format={{
          date: normalizedDateFormat
        }}
        onChange={(newValue) => {
          if (isDate(newValue)) {
            const formattedDate = DateTime.fromJSDate(newValue).toFormat(normalizedDateFormat);
            onChange({
              ...criteria,
              value: {
                ...criteriaValue,
                to_date: {
                  date: formattedDate
                }
              }
            });
          }
        }}
      />
    </div>
  );
}

export function DateTimeExtendedRangeCriteriaInput({
  field,
  criteria,
  onChange,
  errors
}: DateTimeExtendedRangeCriteriaInputProps) {
  if (!isDateTimeExtendedRangeOperator(criteria.operator)) {
    return null;
  }

  if (criteria.operator === 'is a day of the week') {
    return <DayOfWeekInput criteria={criteria} onChange={onChange} />;
  }

  if (criteria.operator === 'is between days of the week') {
    return <BetweenDaysInput criteria={criteria} onChange={onChange} />;
  }

  if (criteria.operator === 'is between dates') {
    return (
      <BetweenDatesInput field={field} criteria={criteria} onChange={onChange} errors={errors} />
    );
  }

  return null;
}
