import { useCallback } from 'react';

import { type KnackField, type KnackFieldType } from '@/types/schema/KnackField';

export type EmailTemplateSystemVariable = {
  name: string;
  key: string;
  type: KnackFieldType;
};

export const emailTemplateSystemVariables: EmailTemplateSystemVariable[] = [
  {
    key: 'record_id',
    name: 'RECORD ID',
    type: 'short_text'
  },
  {
    key: 'parent_url',
    name: 'PARENT PAGE URL',
    type: 'short_text'
  },
  {
    key: 'page_url',
    name: 'PAGE URL',
    type: 'short_text'
  },
  {
    key: 'charge_summary',
    name: 'PAYMENT SUMMARY',
    type: 'short_text'
  }
] satisfies EmailTemplateSystemVariable[];

export function useEmailTemplateHelpers() {
  const getTemplateWithKeyVariables = useCallback((text: string, fields: KnackField[]) => {
    let updatedText = text;

    fields.forEach((field) => {
      const nameVariable = `{${field.name}}`;
      const keyVariable = `{${field.key}}`;

      if (updatedText.includes(nameVariable)) {
        updatedText = updatedText.replaceAll(nameVariable, keyVariable);
      }
    });

    emailTemplateSystemVariables.forEach((variable) => {
      const nameVariable = `{${variable.name}}`;
      const keyVariable = `{${variable.key}}`;

      if (updatedText.includes(nameVariable)) {
        updatedText = updatedText.replaceAll(nameVariable, keyVariable);
      }
    });

    return updatedText;
  }, []);

  const getTemplateWithNameVariables = useCallback((text: string, fields: KnackField[]) => {
    let updatedText = text;

    fields.forEach((field) => {
      const nameVariable = `{${field.name}}`;
      const keyVariable = `{${field.key}}`;

      if (updatedText.includes(keyVariable)) {
        updatedText = updatedText.replaceAll(keyVariable, nameVariable);
      }
    });

    emailTemplateSystemVariables.forEach((variable) => {
      const nameVariable = `{${variable.name}}`;
      const keyVariable = `{${variable.key}}`;

      if (updatedText.includes(keyVariable)) {
        updatedText = updatedText.replaceAll(keyVariable, nameVariable);
      }
    });

    return updatedText;
  }, []);

  return {
    getTemplateWithKeyVariables,
    getTemplateWithNameVariables
  };
}
