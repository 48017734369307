import { useTranslation } from 'react-i18next';
import { HiOutlineUpload as UploadIcon } from 'react-icons/hi';
import { HiXMark as XIcon } from 'react-icons/hi2';
import { Button } from '@knack/asterisk-react';

import { CellErrors } from '@/components/data-table/display/fields/CellErrors';
import { CellLoading } from '@/components/data-table/display/fields/CellLoading';
import { CellError } from '@/components/data-table/display/fields/date-time/components/CellError';
import { type FileField } from '@/components/data-table/display/fields/Field';
import { type FieldRenderProps } from '@/components/data-table/display/fields/FieldRender';
import { getFileIconByType } from '@/components/data-table/display/fields/file/getFileIconByType';
import {
  getFileFieldUploadErrorMessage,
  useFileUpload
} from '@/components/data-table/display/fields/file/useFileUpload';
import { useDataTableStore } from '@/components/data-table/useDataTableStore';

export function FileEdit(props: FieldRenderProps<FileField>) {
  const { rawValue, rowId, fieldId, value } = props;
  const [t] = useTranslation();
  const FileIcon = getFileIconByType(rawValue?.filename || '');

  const { getRootProps, getInputProps, isDragActive, fileRejections } = useFileUpload(props);
  const { saveCell } = useDataTableStore().use.actions();

  const removeFile = async () => {
    await saveCell(rowId, fieldId, '', { value: '', rawValue: '' });
  };

  return (
    <div className="flex size-full items-center bg-base">
      <div className="size-full bg-base p-1 ring-2 ring-black" {...getRootProps()}>
        <CellLoading rowId={rowId} fieldId={fieldId} />

        {!!value && (
          <div
            className="flex w-fit min-w-0 max-w-full items-center rounded bg-subtle p-1"
            data-testid={`file-field-edit-${rowId}-${fieldId}`}
          >
            <FileIcon
              className="mr-1"
              size="16"
              data-testid={`file-icon-edit-${rowId}-${fieldId}`}
            />
            <p className="max-w-full truncate">
              <a href={rawValue.signed_url} target="_blank" rel="noreferrer">
                {rawValue.filename}
              </a>
            </p>

            <Button
              intent="minimal"
              size="sm"
              onClick={removeFile}
              data-testid={`delete-file-button-${rowId}-${fieldId}`}
              className="ml-2 h-fit p-0.5"
            >
              <XIcon data-testid={`delete-file-icon-${rowId}-${fieldId}`} />
            </Button>
          </div>
        )}

        {!value && (
          <div className="flex items-center justify-between">
            <Button intent="secondary" className="mr-1 h-fit rounded-md p-1.5">
              <UploadIcon />
            </Button>

            <input
              {...getInputProps()}
              data-testid={`file-upload-input-floating-${rowId}-${fieldId}`}
            />
            <p className="grow basis-15 truncate">
              {isDragActive
                ? t('components.data_table.file_field.upload_file')
                : t('components.data_table.file_field.drop_file')}
            </p>
          </div>
        )}

        <div className="absolute left-0 top-full w-full bg-base">
          {fileRejections.length > 0 && (
            <div className="mb-2 flex flex-col gap-2 text-destructive">
              {fileRejections.map((rejection) => (
                <div key={rejection.file.name} className="bg-subtle">
                  {rejection.errors.map((error) => (
                    <CellError
                      key={error.code}
                      message={getFileFieldUploadErrorMessage(error, t)}
                      testId={`file-edit-error-${rowId}-${fieldId}`}
                    />
                  ))}
                </div>
              ))}
            </div>
          )}

          <CellErrors rowId={rowId} fieldId={fieldId} />
        </div>
      </div>
    </div>
  );
}
