import { generatePath, Navigate, Outlet, useParams } from 'react-router-dom';

import { type KnackObject } from '@/types/schema/KnackObject';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { TablesPageLayout } from '@/pages/tables/TablesPageLayout';
import { ROUTES, type PageUrlParams } from '@/Router';

export function TablesPageContent({
  activeTab = 'records',
  isIndex = false
}: {
  activeTab?: 'records' | 'fields';
  isIndex?: boolean;
}) {
  const urlParams = useParams<PageUrlParams>();
  const { data: app } = useApplicationQuery();

  const isStandardTable = (tableType: KnackObject['type']) => tableType === 'StandardObject';

  const firstValidTable = app?.objects.find((table) => isStandardTable(table.type));

  const defaultRedirectPath = firstValidTable
    ? generatePath(ROUTES.TABLES_ID, { id: firstValidTable.key })
    : generatePath(ROUTES.TABLES);

  if (isIndex) {
    return <Navigate to={defaultRedirectPath} replace />;
  }

  const { id: tableKey } = urlParams;

  if (!tableKey) {
    throw new Error('No table key provided');
  }

  const tableType = app?.objects.find((obj) => obj.key === tableKey)?.type;
  if (tableType && !isStandardTable(tableType)) {
    // If the table type is not standard we redirect to the correct section
    if (tableType === 'UserObject') {
      return <Navigate to={generatePath(ROUTES.ROLES_ID, { id: tableKey })} replace />;
    }
    return <Navigate to={defaultRedirectPath} replace />;
  }

  return (
    <>
      <Outlet />
      <TablesPageLayout
        objectKey={tableKey as KnackObject['key']}
        data-testid="table-page-layout"
        activeTab={activeTab}
      />
    </>
  );
}
