import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { IntercomProvider, shouldInstallIntercom } from '@/IntercomProvider';

type ChatBotProviderProps = {
  children: React.ReactNode;
};

export function ChatBotProvider({ children }: ChatBotProviderProps) {
  const { data: application } = useApplicationQuery();

  if (!application) {
    return children;
  }

  if (shouldInstallIntercom()) {
    return <IntercomProvider>{children}</IntercomProvider>;
  }

  return children;
}
