interface TabListItemCountChipProps {
  count: number;
  isGradientTabs?: boolean;
}

export function TabListItemCountChip({ count, isGradientTabs = true }: TabListItemCountChipProps) {
  if (isGradientTabs) {
    return (
      <span className="ml-2 rounded-sm bg-subtle px-1.5 py-1 text-xs font-medium group-data-[state=active]:bg-brand-muted">
        <span className="group-data-[state=active]:text-brand-gradient">{count}</span>
      </span>
    );
  }

  return (
    <span className="ml-0.5 rounded-sm bg-subtle px-1.5 py-0.5 text-xs font-medium group-data-[state=active]:bg-brand-muted">
      <span className="group-data-[state=active]:text-emphasis">{count}</span>
    </span>
  );
}
