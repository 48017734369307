import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from '@knack/asterisk-react';
import { z } from 'zod';

import { initialRepeatValue } from '@/components/knack-date-picker/components/KnackDatePickerRepeatOptions';
import { type KnackDatePickerSchema } from '@/components/knack-date-picker/KnackDatePickerSchema';

export const knackDateFrequencySchema = z.union([
  z.object({
    frequency: z.enum(['daily', 'yearly']),
    interval: z.coerce.number().positive().max(30)
  }),
  z.object({
    frequency: z.enum(['weekly']),
    interval: z.coerce.number().positive().max(30),
    weekDays: z.array(z.enum(['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA']))
  }),
  z.object({
    frequency: z.enum(['monthly']),
    interval: z.coerce.number().positive().max(30),
    repeatby: z.enum(['dom', 'dow'])
  })
]);

export type KnackDateFrequencySchema = z.infer<typeof knackDateFrequencySchema>;

export function getFrequencyShortcut(
  repeatValue: KnackDatePickerSchema['repeat'] | undefined,
  showCustomRepeat: boolean
): string {
  if (!repeatValue) return '-';
  if (showCustomRepeat) return 'custom';

  if (repeatValue.frequency === 'daily' && repeatValue.interval === 1) return 'daily';
  if (
    repeatValue.frequency === 'weekly' &&
    repeatValue.interval === 1 &&
    repeatValue.weekDays.length === 0
  )
    return 'weekly';
  if (
    repeatValue.frequency === 'monthly' &&
    repeatValue.interval === 1 &&
    repeatValue.repeatby === 'dom'
  )
    return 'monthly';
  if (repeatValue.frequency === 'yearly' && repeatValue.interval === 1) return 'yearly';

  return 'custom';
}

interface FrequencySelectorProps {
  repeatValue: KnackDatePickerSchema['repeat'];
  onChange: (value: KnackDatePickerSchema['repeat'] | null) => void;
  showCustomRepeat: boolean;
  setShowCustomRepeat: (value: boolean) => void;
}

export function FrequencySelector({
  repeatValue,
  onChange,
  showCustomRepeat,
  setShowCustomRepeat
}: FrequencySelectorProps) {
  const [t] = useTranslation();
  const handleValueChange = useCallback(
    (frequencyShortCut: string) => {
      const frequencyMap = {
        '-': null,
        daily: { ...initialRepeatValue, frequency: 'daily', interval: 1 },
        weekly: { ...initialRepeatValue, frequency: 'weekly', interval: 1, weekDays: [] },
        monthly: { ...initialRepeatValue, frequency: 'monthly', interval: 1, repeatby: 'dom' },
        yearly: { ...initialRepeatValue, frequency: 'yearly', interval: 1 }
      };

      if (frequencyShortCut === 'custom') {
        setShowCustomRepeat(true);
        onChange(repeatValue || { ...initialRepeatValue });
      } else {
        setShowCustomRepeat(false);
        onChange(frequencyMap[frequencyShortCut]);
      }
    },
    [onChange, repeatValue, setShowCustomRepeat]
  );

  // This fixes showing the custom frequency if none of the shortcuts are selected based on the default state
  if (
    showCustomRepeat === false &&
    getFrequencyShortcut(repeatValue, showCustomRepeat) === 'custom'
  ) {
    setShowCustomRepeat(true);
  }

  return (
    <Select
      value={getFrequencyShortcut(repeatValue, showCustomRepeat)}
      onValueChange={handleValueChange}
    >
      <Select.Trigger
        aria-label={t('date_picker.select_frequency')}
        placeholder={t('date_picker.select_frequency')}
      />
      <Select.Content>
        <Select.Group>
          <Select.Item value="-">{t('date_picker.does_not_repeat')}</Select.Item>
          <Select.Item value="daily">{t('date_picker.daily')}</Select.Item>
          <Select.Item value="weekly">{t('date_picker.weekly')}</Select.Item>
          <Select.Item value="monthly">{t('date_picker.monthly')}</Select.Item>
          <Select.Item value="yearly">{t('date_picker.yearly')}</Select.Item>
          <Select.Item value="custom">{t('date_picker.custom')}</Select.Item>
        </Select.Group>
      </Select.Content>
    </Select>
  );
}
