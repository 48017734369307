export const parseTime = (time: string) => {
  const parsedTime = time.split(':').map(Number);
  let hours = parsedTime[0];
  const minutes = parsedTime[1];
  if (Number.isNaN(hours) || Number.isNaN(minutes) || hours < 0 || minutes < 0 || minutes > 59) {
    return '';
  }

  hours = hours <= 12 ? hours : hours - 12;

  if (hours === 0) {
    hours = 12;
  }

  // Pad hours and minutes with leading zeros if necessary
  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}`;
};

export const getMeridian = (time: string) => {
  let [hours] = time.split(':').map(Number);
  if (Number.isNaN(hours) || hours < 0) {
    return 'AM';
  }
  if (hours === 0) {
    hours = 12;
    return 'AM';
  }
  return hours < 12 ? 'AM' : 'PM';
};
