import { Trans, useTranslation } from 'react-i18next';
import { Button, Dialog } from '@knack/asterisk-react';

import { KNOWLEDGE_BASE_URLS } from '@/utils/knowledge-base';

interface TaskRunModalProps {
  onOpenChange: (isOpen: boolean) => void;
  handleRunTask: () => void;
  shouldShowRunTaskRateLimitError: boolean;
}

export function TaskRunModal({
  onOpenChange,
  handleRunTask,
  shouldShowRunTaskRateLimitError
}: TaskRunModalProps) {
  const [t] = useTranslation();

  return (
    <Dialog open onOpenChange={onOpenChange}>
      <Dialog.Content data-testid="run-task-modal">
        <Dialog.MainContent className="pb-0">
          <Dialog.Header>
            <Dialog.Title>
              {t('components.data_table.right_sidebar.tasks.run_task_manually.title')}
            </Dialog.Title>
          </Dialog.Header>
          <div className="mt-6">
            <p>{t('components.data_table.right_sidebar.tasks.run_task_manually.description')}</p>
          </div>
          {shouldShowRunTaskRateLimitError && (
            <div className="mt-6 rounded-lg bg-destructive p-4 text-destructive-emphasis">
              <p className="mb-1 font-medium">
                {t(
                  'components.data_table.right_sidebar.tasks.run_task_manually.rate_limit_error_title'
                )}
              </p>
              <Trans
                i18nKey="components.data_table.right_sidebar.tasks.run_task_manually.rate_limit_error_body"
                components={[
                  // eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid, jsx-a11y/control-has-associated-label
                  <a
                    key="0"
                    href={KNOWLEDGE_BASE_URLS.TASKS_OVERVIEW}
                    target="_blank"
                    rel="noreferrer"
                    className="font-medium text-default underline"
                  />
                ]}
              />
            </div>
          )}
        </Dialog.MainContent>
        <Dialog.Footer className="gap-2">
          <Dialog.Close asChild>
            <Button intent="minimal">{t('actions.cancel')}</Button>
          </Dialog.Close>
          <Button onClick={handleRunTask} data-testid="confirm-run-task-button">
            {t('components.data_table.right_sidebar.tasks.run_task_manually.run_task')}
          </Button>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  );
}
