import { useQuery } from '@tanstack/react-query';

import { queryKeys } from '@/hooks/api/queryKeys';
import { axiosInstance as axios } from '@/utils/axiosConfig';

async function getCustomCode(appId: string | undefined, type: 'css' | 'javascript') {
  const fileExtension = type === 'css' ? 'css' : 'js';

  const response = await axios.get<string>(
    `https://${import.meta.env.PUBLIC_S3_APP_CDN_DOMAIN}/${appId}/custom/v4/main.${fileExtension}?${Date.now()}`
  );

  return response.data;
}

export function useCustomCodeQuery(appId: string | undefined, type: 'css' | 'javascript') {
  return useQuery({
    queryKey: [queryKeys.customCode, type],
    queryFn: async () => getCustomCode(appId, type),
    enabled: !!appId // Disable query when `appId` is undefined
  });
}
