import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, Input, RichTextEditor } from '@knack/asterisk-react';

import { type DetailsViewInput } from '@/types/schema/views/DetailsView';
import { type ViewWithDetails } from '@/types/schema/views/ViewWithDetails';
import { generateDetailsViewRichTextInput } from '@/pages/pages/page-editor/add-view/helpers/view-schemas/detailsViewSchema';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { useUpdateViewColumns } from '@/pages/pages/settings-panel/view-settings/useUpdateViewColumns';

interface ViewDetailsRichTextInputAddModalProps {
  onOpenChange: (isOpen: boolean) => void;
}

export function ViewDetailsRichTextInputAddModal({
  onOpenChange
}: ViewDetailsRichTextInputAddModalProps) {
  const [t] = useTranslation();

  const { view } = useActiveViewContext<ViewWithDetails>();

  const initialInputState = generateDetailsViewRichTextInput();
  const [inputSchema, setInputSchema] = useState<DetailsViewInput>(initialInputState);

  const updateViewColumnsSchema = useUpdateViewColumns<ViewWithDetails>();

  const columns = useMemo(() => {
    if (view.type !== 'calendar' && view.type !== 'map') {
      return view.columns;
    }
    return view.details.columns;
  }, [view]);

  const dialogTitle = t(
    'pages.element_settings.form.categories.form_fields.field_management.add_rich_text'
  );

  const onSave = () => {
    // If both the title and the copy of the Rich Text input are empty, we set the title (value) to the initial state value, since we don't want to save an empty Rich Text input
    if (!inputSchema.value && !inputSchema.copy) {
      inputSchema.value = initialInputState.value;
    }

    const updatedView = {
      columns: columns.map((outerColumn, outerColumnIndex) => {
        if (outerColumnIndex === columns.length - 1) {
          return {
            ...outerColumn,
            groups: outerColumn.groups.map((group, groupIndex) => {
              if (groupIndex === columns[0].groups.length - 1) {
                return {
                  ...group,
                  columns: group.columns.map((column, columnIndex) => {
                    // Add a new rich text input to the last column in the last group in the last outer column
                    if (columnIndex === group.columns.length - 1) {
                      return [...column, inputSchema];
                    }

                    return column;
                  })
                };
              }
              return group;
            })
          };
        }

        return outerColumn;
      })
    };

    updateViewColumnsSchema(updatedView);
  };

  return (
    <Dialog open onOpenChange={onOpenChange}>
      <Dialog.Content>
        <Dialog.MainContent>
          <Dialog.Header>
            <Dialog.Title>{dialogTitle}</Dialog.Title>
            <Dialog.Description className="sr-only">{dialogTitle}</Dialog.Description>
          </Dialog.Header>
          <div className="mt-6">
            <div className="mb-4">
              <span className="mb-2 block font-medium">
                {t(
                  'pages.element_settings.form.categories.form_fields.field_management.rich_text_title'
                )}
              </span>
              <Input
                value={inputSchema.value}
                onChange={(e) => {
                  setInputSchema((prev) => ({
                    ...prev,
                    value: e.target.value
                  }));
                }}
              />
            </div>
            <div>
              <span className="mb-2 block font-medium">
                {t(
                  'pages.element_settings.form.categories.form_fields.field_management.rich_text_content'
                )}
              </span>
              <RichTextEditor
                content={inputSchema.copy}
                onUpdate={({ editor }) => {
                  setInputSchema((prev) => ({
                    ...prev,
                    copy: editor.isEmpty ? '' : editor.getHTML()
                  }));
                }}
              />
            </div>
          </div>
        </Dialog.MainContent>
        <Dialog.Footer>
          <Dialog.Close asChild>
            <Button intent="minimal">{t('actions.cancel')}</Button>
          </Dialog.Close>
          <Dialog.Close asChild>
            <Button onClick={onSave}>{t('actions.add')}</Button>
          </Dialog.Close>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  );
}
