import { useEffect } from 'react';
import { FormProvider, useForm, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { type KnackObject } from '@/types/schema/KnackObject';
import { type RecordRule } from '@/types/schema/rules/RecordRule';
import { type KnackTask } from '@/types/schema/tasks/KnackTask';
import { RecordRuleFormFields } from '@/components/record-rule/RecordRuleFormFields';

interface RecordRuleFormProps {
  recordRule: RecordRule;
  sourceObject: KnackObject;
}

export function TaskActionForm({ recordRule, sourceObject }: RecordRuleFormProps) {
  const [t] = useTranslation();

  // Record rule form schema without validation - Validation comes from the parent component
  const recordRuleFormSchema = z.custom<RecordRule>();

  const parentForm = useFormContext<KnackTask>();

  const form = useForm<RecordRule>({
    resolver: zodResolver(recordRuleFormSchema),
    defaultValues: recordRule,
    errors: parentForm.formState.errors?.action
  });

  const formData = useWatch<RecordRule>({ control: form.control });

  useEffect(() => {
    if (formData) {
      parentForm.setValue('action', formData as RecordRule, { shouldDirty: true });
    }
    parentForm.clearErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  useEffect(() => {
    if (!parentForm.formState.errors?.action) {
      form.clearErrors();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentForm.formState.errors]);

  return (
    <FormProvider {...form}>
      <RecordRuleFormFields
        recordRule={recordRule}
        sourceObject={sourceObject}
        recordRuleAllowedValueTypes={['record', 'value', 'connection', 'current_date']}
        labels={{
          actionType: t('components.data_table.right_sidebar.tasks.action.action_type'),
          values: t('components.data_table.right_sidebar.tasks.action.set_values'),
          runEveryTime: t('components.data_table.right_sidebar.tasks.action.run_on_every_record')
        }}
      />
    </FormProvider>
  );
}
