import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, InputSearch, Label, Select } from '@knack/asterisk-react';

import { KnackTableIcon } from '@/components/KnackTableIcon';
import {
  AddRecordViewFlowSteps,
  useAddRecordViewFlowContext
} from '@/pages/pages/page-editor/add-view/add-record-view/AddRecordViewFlowContext';
import { type ViewSource } from '@/pages/pages/page-editor/add-view/helpers/useViewSources';

function ViewSourceListItem({
  viewSource,
  onValueChange
}: {
  viewSource: ViewSource;
  onValueChange: (newViewSourceKey: string) => void;
}) {
  return (
    <button
      id={viewSource.object.key}
      type="button"
      onClick={() => onValueChange(viewSource.object.key)}
      className="flex w-full items-center rounded-lg border border-default bg-default p-2 font-medium hover:border-emphasis hover:bg-subtle hover:text-emphasis [&:not(:last-child)]:mb-2"
    >
      <KnackTableIcon tableType={viewSource.object.type} className="mr-2 shrink-0" />
      <Label htmlFor={viewSource.object.key} className="pointer-events-none">
        {viewSource.object.inflections.plural}
      </Label>
    </button>
  );
}

function FormViewSources({ onValueChange }: { onValueChange: (newViewSourceKey: string) => void }) {
  const [t] = useTranslation();
  const { viewSourcesForFormView } = useAddRecordViewFlowContext();
  const { selectedFormRecordAction, setSelectedFormRecordAction, setSelectedViewSource } =
    useAddRecordViewFlowContext();
  const [search, setSearch] = useState('');

  if (!viewSourcesForFormView) {
    return null;
  }

  const viewSourcesToRender =
    selectedFormRecordAction === 'add'
      ? viewSourcesForFormView.adding
      : viewSourcesForFormView.updating;

  const filteredViewSources = viewSourcesToRender.filter((viewSource) =>
    viewSource.object.inflections.plural.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <>
      <Label className="mb-2 block font-medium">
        {t('views.add_view_dialog.form_when_is_submitted')}
      </Label>
      <Select
        value={selectedFormRecordAction}
        onValueChange={(value) => {
          setSelectedFormRecordAction(value as 'add' | 'update');

          // Clear the selected view source when the record display quantity changes (e.g. from 'update' to 'add')
          setSelectedViewSource(undefined);
        }}
      >
        <Select.Trigger className="mb-4 w-full" />
        <Select.Content>
          <Select.Item disabled={viewSourcesForFormView.adding.length === 0} value="add">
            {t('views.add_view_dialog.form_view_select_action_add')}
          </Select.Item>
          <Select.Item disabled={viewSourcesForFormView.updating.length === 0} value="update">
            {t('views.add_view_dialog.form_view_select_action_update')}
          </Select.Item>
        </Select.Content>
      </Select>

      <Divider className="mb-4" />
      {viewSourcesToRender.length > 10 && (
        <>
          <Label className="mb-1 block font-medium">
            {t('views.add_view_dialog.select_source_table')}
          </Label>
          <span className="mb-2 block text-xs text-subtle">
            {selectedFormRecordAction === 'update'
              ? t('views.add_view_dialog.form_view_select_source_table_description_update')
              : t('views.add_view_dialog.form_view_select_source_table_description_add')}
          </span>
          <InputSearch
            aria-label={t('keywords.search')}
            className="mb-4"
            value={search}
            placeholder={t('keywords.search')}
            onChange={(e) => setSearch(e.target.value)}
            data-testid="add-element-search-tables-input"
          />
        </>
      )}
      <div className="max-h-[410px] overflow-y-auto">
        {filteredViewSources.map((viewSource) => (
          <ViewSourceListItem
            key={viewSource.object.key}
            viewSource={viewSource}
            onValueChange={onValueChange}
          />
        ))}
      </div>
    </>
  );
}

export function ViewSourceList() {
  const {
    selectedViewType,
    viewSources,
    viewSourcesForFormView,
    selectedFormRecordAction,
    setSelectedViewSource,
    setSelectedViewSourcePath,
    setSelectedViewSourcePathDirectConnectionFieldKey,
    setSelectedViewSourcePathParentConnectionFieldKey,
    onContinue
  } = useAddRecordViewFlowContext();

  const onValueChange = (newViewSourceKey: string) => {
    let viewSourcesToSearch = viewSources;

    // If the selected view type is a 'form', we need to determine what view sources to search based on the form record action ('add' or 'update')
    if (selectedViewType === 'form' && viewSourcesForFormView) {
      viewSourcesToSearch =
        selectedFormRecordAction === 'add'
          ? viewSourcesForFormView.adding
          : viewSourcesForFormView.updating;
    }

    const newSelectedViewSource = viewSourcesToSearch.find(
      (source) => source.object.key === newViewSourceKey
    );

    setSelectedViewSource(newSelectedViewSource);

    // Clear any selections made by the user in the view source or view source path steps
    setSelectedViewSourcePathDirectConnectionFieldKey(undefined);
    setSelectedViewSourcePathParentConnectionFieldKey(undefined);

    // If there is only one path, we need to check a few things before proceeding to the next step
    if (newSelectedViewSource?.paths.length === 1) {
      // Automatically select the only path available
      setSelectedViewSourcePath(newSelectedViewSource.paths[0]);

      // If there are no path connections, it means there are no connections to choose from, so we can skip the path selection step
      if (!newSelectedViewSource.paths[0].connections) {
        onContinue(AddRecordViewFlowSteps.confirm);
        return;
      }

      // If there are multiple direct connections the user can choose from, default to the first one
      const hasMultipleDirectConnections =
        newSelectedViewSource.paths[0].connections.direct.length > 1;
      if (hasMultipleDirectConnections) {
        setSelectedViewSourcePathDirectConnectionFieldKey(
          newSelectedViewSource.paths[0].connections.direct[0].field.key
        );
      }

      // If there are multiple parent connections the user can choose from, default to the first one
      const hasMultipleParentConnections =
        newSelectedViewSource.paths[0].connections.parent.length > 1;
      if (hasMultipleParentConnections) {
        setSelectedViewSourcePathParentConnectionFieldKey(
          newSelectedViewSource.paths[0].connections.parent[0].field.key
        );
      }
    }

    onContinue(AddRecordViewFlowSteps.selectViewSourcePath);
  };

  return (
    <div data-testid="add-view-modal-view-list">
      {selectedViewType === 'form' ? (
        <FormViewSources onValueChange={onValueChange} />
      ) : (
        <div className="max-h-[410px] overflow-y-auto">
          {viewSources.map((viewSource) => (
            <ViewSourceListItem
              key={viewSource.object.key}
              viewSource={viewSource}
              onValueChange={onValueChange}
            />
          ))}
        </div>
      )}
    </div>
  );
}
