import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, SimplePagination } from '@knack/asterisk-react';
import debounce from 'lodash.debounce';

import { ROWS_PER_PAGE, ROWS_PER_PAGE_OPTIONS } from '@/components/data-table/helpers/constants';
import {
  useDataTableStore,
  useDataTableStorePersist
} from '@/components/data-table/useDataTableStore';

const loadPageDebounceTime = 300;

export function DataTablePagination() {
  const [t] = useTranslation();
  const totalPages = useDataTableStore().use.totalPages();
  const page = useDataTableStorePersist().use.page();

  const { loadPage, setPagesInViewport } = useDataTableStore().use.actions();

  const rowsPerPage = useDataTableStorePersist().use.rowsPerPage() || ROWS_PER_PAGE;

  const { setRowsPerPage, setPage } = useDataTableStorePersist().use.actions();

  const debounceLoadPage = useMemo(
    () =>
      debounce((newPage: number) => {
        setPagesInViewport([newPage - 1]);
        void loadPage(newPage - 1);
      }, loadPageDebounceTime),
    [loadPage, setPagesInViewport]
  );
  const onFieldChange = (value: string) => {
    setPage(null);
    setRowsPerPage(parseInt(value, 10));
    setPagesInViewport([0]);
    void loadPage(0);
  };

  return (
    <div className="flex gap-2" data-testid="data-table-pagination">
      <div className="flex items-center gap-2">
        <p>{t('components.data_table.footer.show')}</p>
        <Select
          aria-label={t('components.data_table.footer.rows_per_page_selector')}
          onValueChange={onFieldChange}
          defaultValue={rowsPerPage.toString()}
        >
          <Select.Trigger id="rows-per-page" />
          <Select.Content className="min-w-fit">
            {ROWS_PER_PAGE_OPTIONS.map((option) => (
              <Select.Item key={option} value={option.toString()}>
                {t('components.data_table.footer.per_page', { count: option })}
              </Select.Item>
            ))}
          </Select.Content>
        </Select>
      </div>
      <SimplePagination
        currentPage={page || 1}
        totalPages={totalPages}
        maxVisiblePages={5}
        onPageChange={async (newPage) => {
          setPage(newPage);
          debounceLoadPage(newPage);
        }}
      />
    </div>
  );
}
