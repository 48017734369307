import { type TableView } from '@/types/schema/views/TableView';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { useRestrictedConnectionHelpers } from '@/pages/pages/settings-panel/view-settings/common/filtering/restricted-connections/useRestrictedConnectionHelpers';
import { ViewSettingsFiltering } from '@/pages/pages/settings-panel/view-settings/common/filtering/ViewSettingsFiltering';
import { useUpdateView } from '@/pages/pages/settings-panel/view-settings/useUpdateView';

export function TableViewSettingsFiltering() {
  const { view, sourceObject } = useActiveViewContext<TableView>();
  const updateViewSchema = useUpdateView<TableView>();

  const { restrictedConnectionFields } = useRestrictedConnectionHelpers();

  const tableViewFilteringOptions = {
    filter_type: view.filter_type,
    filter_fields: view.filter_fields,
    preset_filters: view.preset_filters,
    menu_filters: view.menu_filters,
    ...(view.filter_connection_sources && {
      filter_connection_sources: view.filter_connection_sources
    })
  };

  return (
    <ViewSettingsFiltering
      sourceObject={sourceObject}
      options={tableViewFilteringOptions}
      updateViewSchema={updateViewSchema}
      restrictedConnectionFields={restrictedConnectionFields}
    />
  );
}
