import { ColumnTypeInference, FieldPredictor } from '@knack/field-predictor';
import i18n from 'i18next';
import { utils } from 'xlsx';

import { type KnackField, type KnackFieldType } from '@/types/schema/KnackField';
import { type GoogleSheetPreview } from '@/pages/add-table/types';
import {
  type Column,
  type PredictedFieldFormat,
  type RawData,
  type SerializedMappedColumn,
  type SerializedNewColumn
} from './types';

interface ColumnTypeFormatCounter {
  [typeFormatKey: string]: {
    count: number;
    format?: PredictedFieldFormat;
  };
}

export interface ColumnTypeFormat {
  type: KnackFieldType;
  format?: PredictedFieldFormat;
}

// Function to infer column types
export function inferColumnTypes(data: RawData[], headerRow: RawData | null): ColumnTypeFormat[] {
  return ColumnTypeInference.getColumnTypes(data, headerRow).map((col: ColumnTypeFormat) => ({
    type: col.type,
    format: col.format
  }));
}
function getMostCommonTypesByColumn(rowTypes: ColumnTypeFormat[][]) {
  const counters: ColumnTypeFormatCounter[] = Array(rowTypes[0].length)
    .fill(null)
    .map(() => ({}));

  rowTypes.forEach((row) => {
    row.forEach(({ type, format }, columnIndex) => {
      // this creates a fieldType + fieldFormat pair so it can be later used for counting
      const typeFormatKey = `${type}|${JSON.stringify(format) || ''}`;
      if (!counters[columnIndex][typeFormatKey]) {
        counters[columnIndex][typeFormatKey] = { count: 0, format };
      }
      counters[columnIndex][typeFormatKey].count += 1;
    });
  });

  // Find the type with the maximum count for each of the columns
  return counters.map((counter) => {
    let maxCount = 0;
    let mostCommonTypeFormat: ColumnTypeFormat = { type: 'short_text' };

    Object.entries(counter).forEach(([key, { count, format }]) => {
      if (count > maxCount) {
        maxCount = count;
        const [type] = key.split('|');
        mostCommonTypeFormat = { type: type as KnackFieldType, format };
      }
    });
    return mostCommonTypeFormat;
  });
}

export function getColumnTypes(data: RawData[]) {
  if (!data.length) return [];

  const rowTypes: ColumnTypeFormat[][] = [];

  data.forEach((row) => {
    const predictedRow = row.map((columnValue) => {
      const prediction: { type: KnackFieldType; format?: PredictedFieldFormat } = {
        type: 'short_text'
      };
      if (columnValue) {
        const predictionResult = FieldPredictor.predict(columnValue);
        if (predictionResult && predictionResult.type) {
          const { type, format } = predictionResult;
          prediction.format = format;
          if (type === 'link:url') {
            prediction.type = 'link';
          } else {
            prediction.type = type;
          }
        }
      }
      return prediction;
    });

    rowTypes.push(predictedRow);
  });
  return getMostCommonTypesByColumn(rowTypes);
}

// This function is used to get the default parts for a field type
export const getDefaultSubFieldParts = (fieldType: KnackFieldType) => {
  const i18nSubfieldPrefix = 'components.add_table.subfield_types';
  const fieldTypes = {
    name: {
      title: 'title',
      first: 'first_name',
      middle: 'middle_name',
      last: 'last_name'
    },
    address: {
      street: 'street_address',
      street2: 'street_address_2',
      city: 'city',
      state: 'state',
      zip: 'postal_code',
      country: 'country',
      latitude: 'latitude',
      longitude: 'longitude'
    },
    link: {
      url: 'url',
      label: 'link_text'
    }
  };

  if (!fieldTypes[fieldType]) return [];

  return Object.entries(fieldTypes[fieldType] as Record<string, string>).map(([key, value]) => ({
    mapped: false,
    mappedColumnIndex: undefined,
    part: {
      key,
      label: i18n.t(`${i18nSubfieldPrefix}.${value}`)
    }
  }));
};

export function getSerializedNewColumns(columns: Column[]): SerializedNewColumn[] {
  return columns.map((col) => {
    const complex = col.meta.parts.some((part) => part.mapped === true);
    const {
      newFieldType,
      newFieldFormat,
      connectionMatchField,
      connectionNoMatchRule,
      connectionObjectKey,
      parts
    } = col.meta;
    return {
      name: col.header,
      index: Number(col.accessorKey),
      added: true,
      addedFieldType: newFieldType,
      addedFieldFormat: newFieldFormat,
      parts,
      predictedType: newFieldType,
      predictedFormat: newFieldFormat,
      complex,
      ...(connectionObjectKey && {
        connectionMatchField,
        connectionObjectKey,
        connectionNoMatchRule
      })
    };
  });
}

export function getSerializedMappedColumns(columns: Column[]): SerializedMappedColumn[] {
  return columns.map((col) => {
    const complex = col.meta.parts.some((part) => part.mapped === true);
    const {
      connectionMatchField,
      connectionNoMatchRule,
      connectionObjectKey,
      existingKnackField,
      newFieldFormat,
      parts
    } = col.meta;

    const format = (newFieldFormat || existingKnackField?.format) as PredictedFieldFormat;

    return {
      key: existingKnackField?.key || '',
      parts,
      mapped: true,
      mappedColumnIndex: Number(col.accessorKey),
      matchable: true,
      name: existingKnackField?.name || '',
      type: existingKnackField?.type,
      format,
      complex,
      ...(connectionObjectKey && {
        connectionMatchField,
        connectionObjectKey,
        connectionNoMatchRule
      })
    };
  });
}

export function checkMatchingColumnName(columnValue, fields) {
  return fields.find((field) => field.name.trim() === columnValue.trim());
}

// method to sanitize sheet name based on Excel's constraints
// https://support.microsoft.com/en-us/office/rename-a-worksheet-3f1f7148-ee83-404d-8ef0-9ff99fbad1f9
export function sanitizeSheetName(sheetName: string, sheetIndex: number): string {
  const invalidChars = /[/\\*?[\]:]/g;
  let sanitized = sheetName.replace(invalidChars, '_');

  if (sanitized.startsWith("'")) {
    sanitized = sanitized.slice(1);
  }
  if (sanitized.endsWith("'")) {
    sanitized = sanitized.slice(0, -1);
  }

  sanitized = sanitized.substring(0, 31);

  if (!sanitized.trim() || sanitized === '_'.repeat(sanitized.length)) {
    sanitized = `Sheet${sheetIndex + 1}`;
  }

  if (sanitized.toLowerCase() === 'history') {
    sanitized = `Sheet${sheetIndex + 1}`;
  }

  return sanitized;
}

export function transformGoogleSheetsDataToWorkbook(googleSheetsData: GoogleSheetPreview[]) {
  const workbook = utils.book_new();
  googleSheetsData.forEach((sheetData, index) => {
    const matrix = sheetData.preview.map((row) => row.map((cell) => cell));
    const worksheet = utils.aoa_to_sheet(matrix);
    const sanitizedSheetName = sanitizeSheetName(sheetData.name, index);
    utils.book_append_sheet(workbook, worksheet, sanitizedSheetName);
  });
  return workbook;
}

const onlyReadFields: Array<KnackField['type']> = [
  'auto_increment',
  'equation',
  'concatenation',
  'min',
  'max',
  'average',
  'count',
  'sum'
];

export const isReadOnlyField = (fieldType: KnackField['type']) =>
  onlyReadFields.includes(fieldType);

export const getNonMatchableKnackFields = () => [
  'date_time',
  'timer',
  'signature',
  'image',
  'address',
  'name'
];
