import { type BuilderApplication } from '@/types/schema/BuilderApplication';
import { type KnackField } from '@/types/schema/KnackField';
import { type KnackConnection, type KnackObject } from '@/types/schema/KnackObject';

function getRelatedAggregateFieldsFor(
  connections: KnackConnection[],
  app: BuilderApplication,
  field: KnackField
) {
  const relatedAggregateFields: RelatedAggregateFields[] = [];
  connections.forEach((connection) => {
    if (!['many', 'one'].includes(connection.has)) {
      return;
    }
    const connectedTable = app.objects.find((obj) => obj.key === connection.object);
    if (!connectedTable) {
      return;
    }
    const fields = connectedTable.fields.filter((connectedTableField) => {
      if (connectedTableField.type === 'count') {
        return connectedTableField.format?.connection === field.key;
      }

      if (
        connectedTableField.type !== 'sum' &&
        connectedTableField.type !== 'min' &&
        connectedTableField.type !== 'max' &&
        connectedTableField.type !== 'average'
      ) {
        return false;
      }

      return connectedTableField?.format?.field?.key === field.key;
    });

    if (fields.length > 0) {
      relatedAggregateFields.push({ table: connectedTable, fields });
    }
  });
  return relatedAggregateFields;
}

export type RelatedAggregateFields = {
  table: KnackObject;
  fields: KnackField[];
};

export const getRelatedAggregateFields = (
  objectKey: string,
  field: KnackField,
  app: BuilderApplication
): RelatedAggregateFields[] => {
  const table = app.objects.find((obj) => obj.key === objectKey);
  if (!table) return [];

  const inboundRelatedAggregateFields = getRelatedAggregateFieldsFor(
    table.connections.inbound,
    app,
    field
  );

  const outboundRelatedAggregateFields = getRelatedAggregateFieldsFor(
    table.connections.outbound,
    app,
    field
  );

  return [...inboundRelatedAggregateFields, ...outboundRelatedAggregateFields];
};
