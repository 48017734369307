import { useTranslation } from 'react-i18next';
import { Tabs, type TabsListItem } from '@knack/asterisk-react';

import { ViewDetailsFieldManagement } from '@/pages/pages/settings-panel/view-settings/common/details-field-management/ViewDetailsFieldManagement';
import { DataDisplayGeneralSettings } from '@/pages/pages/settings-panel/view-settings/list/data-display/DataDisplayGeneralSettings';
import { ListSettingsFiltering } from '@/pages/pages/settings-panel/view-settings/list/ListSettingsFiltering';

export function ListDataDisplaySettingsCategory() {
  const [t] = useTranslation();

  const tabListItems: TabsListItem[] = [
    {
      value: 'general',
      children: t('keywords.general')
    },
    {
      value: 'fields',
      children: t('keywords.fields')
    },
    {
      value: 'filtering',
      children: t('keywords.filtering')
    }
  ];

  return (
    <Tabs defaultValue="general" className="h-full">
      <Tabs.List items={tabListItems} intent="page" shouldDisableResponsive shouldUseGradient />
      <Tabs.Content value="general" className="p-0 text-default">
        <DataDisplayGeneralSettings />
      </Tabs.Content>
      <Tabs.Content value="fields" className="p-0 text-default">
        <ViewDetailsFieldManagement />
      </Tabs.Content>
      <Tabs.Content value="filtering" className="p-0 text-default">
        <ListSettingsFiltering />
      </Tabs.Content>
    </Tabs>
  );
}
