import { useEffect, useRef, useState } from 'react';
import {
  HiViewColumns as ColumnsIcon,
  HiTableCells as FieldsIcon,
  HiCog6Tooth as GeneralSettingsIcon,
  HiArrowTopRightOnSquare as LinkIcon
} from 'react-icons/hi2';
import { Link } from 'react-router-dom';
import { t } from 'i18next';

import { type CalendarView } from '@/types/schema/views/CalendarView';
import { KNOWLEDGE_BASE_URLS } from '@/utils/knowledge-base';
import { CollapsiblePanel } from '@/components/layout/CollapsiblePanel';
import { usePageEditorMessagingContext } from '@/pages/pages/page-editor/PageEditorMessagingContext';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { EventFormSettings } from '@/pages/pages/settings-panel/view-settings/calendar/add-event/EventFormSettings';
import { DataDisplaySettings } from '@/pages/pages/settings-panel/view-settings/calendar/data-display/DataDisplaySettings';
import { EventDetailsSettings } from '@/pages/pages/settings-panel/view-settings/calendar/event-detail/EventDetailsSettings';
import { EventFieldProperties } from '@/pages/pages/settings-panel/view-settings/calendar/event-detail/EventFieldProperties';
import { GeneralSettings } from '@/pages/pages/settings-panel/view-settings/calendar/general/GeneralSettings';
import { CommonSourceBanner } from '@/pages/pages/settings-panel/view-settings/common/CommonSourceBanner';
import { FormInputEditModal } from '@/pages/pages/settings-panel/view-settings/form/FormInputEditModal';
import { useViewInputEditModeContext } from '@/pages/pages/settings-panel/view-settings/ViewInputEditModeContext';
import {
  ViewSettingsCategoryList,
  type ViewSettingsCategoryItem
} from '@/pages/pages/settings-panel/view-settings/ViewSettingsCategoryList';

type CalendarSettingsCategoryId =
  | 'general-settings'
  | 'data-display'
  | 'event-detail'
  | 'event-form'
  | 'event-field-properties';
type CalendarSettingsCategoryItem = ViewSettingsCategoryItem<CalendarSettingsCategoryId>;

function getPanelTitle(
  activeCategoryId: CalendarSettingsCategoryId | undefined,
  categories: Pick<CalendarSettingsCategoryItem, 'id' | 'name'>[]
) {
  const activeCategory = activeCategoryId
    ? categories.find((category) => category.id === activeCategoryId)
    : undefined;

  return activeCategory ? activeCategory.name : t('pages.element_settings.calendar.heading');
}

const CALENDAR_SETTINGS_CATEGORIES: CalendarSettingsCategoryItem[] = [
  {
    id: 'general-settings',
    name: t('pages.element_settings.calendar.categories.general_settings.name'),
    description: t('pages.element_settings.calendar.categories.general_settings.description'),
    icon: GeneralSettingsIcon
  },
  {
    id: 'data-display',
    name: t('pages.element_settings.common.categories.data_display.name'),
    description: t('pages.element_settings.common.categories.data_display.description'),
    icon: FieldsIcon
  },
  {
    id: 'event-detail',
    name: t('pages.element_settings.calendar.categories.event_details.name'),
    description: t('pages.element_settings.calendar.categories.event_details.description'),
    icon: ColumnsIcon
  },
  {
    id: 'event-form',
    name: t('pages.element_settings.calendar.categories.event_form.name'),
    description: t('pages.element_settings.calendar.categories.event_form.description'),
    icon: ColumnsIcon
  }
];
const FIELD_PROPERTY = {
  id: 'event-field-properties' as CalendarSettingsCategoryId,
  name: 'Field Properties'
};

export function CalendarViewSettings() {
  const [activeCategoryId, setActiveCategoryId] = useState<CalendarSettingsCategoryId | undefined>(
    undefined
  );
  const { isViewInputEditMode, setIsViewInputEditMode } = useViewInputEditModeContext();
  const { sendMessageToLiveApp } = usePageEditorMessagingContext();
  const panelTitle = getPanelTitle(activeCategoryId, [
    ...CALENDAR_SETTINGS_CATEGORIES,
    FIELD_PROPERTY
  ]);
  const { view } = useActiveViewContext<CalendarView>();
  const navigationRef = useRef<Array<CalendarSettingsCategoryId | undefined>>([undefined]);

  const handleCategorySelect = (selectedCategoryId: string) => {
    const categoryId = selectedCategoryId as CalendarSettingsCategoryId;
    if (categoryId === 'event-detail') {
      sendMessageToLiveApp({
        action: 'calendar-action',
        calendarAction: 'show-details',
        viewKey: view.key
      });
    }
    navigationRef.current.push(categoryId);
    setActiveCategoryId(categoryId);
  };

  const handleBack = () => {
    // remove the current trace from navigation tree
    const fromPage = navigationRef.current.pop();
    const currentState = navigationRef.current.slice(-1)?.[0];
    setActiveCategoryId(currentState);
    if (fromPage === 'event-form') {
      sendMessageToLiveApp({
        action: 'calendar-action',
        calendarAction: 'hide-event-form',
        viewKey: view.key
      });
    }
    if (fromPage === 'event-detail') {
      sendMessageToLiveApp({
        action: 'calendar-action',
        calendarAction: 'hide-details',
        viewKey: view.key
      });
    }
  };

  useEffect(() => {
    if (isViewInputEditMode) {
      navigationRef.current.push('event-field-properties');
      setIsViewInputEditMode(false);
      setActiveCategoryId('event-field-properties');
    }
  }, [isViewInputEditMode, setIsViewInputEditMode]);

  return (
    <>
      <CollapsiblePanel.Content
        title={panelTitle}
        description={
          (activeCategoryId === 'event-detail' &&
            t('pages.element_settings.calendar.categories.event_details.editor_description')) ||
          ''
        }
        // render back button only when activeCategoryId is defined
        {...(activeCategoryId && {
          onBack: handleBack,
          backTitle:
            navigationRef.current.slice(-2)?.[0] || t('pages.element_settings.calendar.heading')
        })}
        footer={
          <Link
            to={KNOWLEDGE_BASE_URLS.CALENDAR_ELEMENT}
            className="flex items-center justify-center gap-2 p-4 underline"
          >
            {t('pages.element_settings.calendar.categories.general_settings.learn_more')}{' '}
            <LinkIcon />
          </Link>
        }
      >
        {activeCategoryId ? (
          <>
            {activeCategoryId === 'general-settings' && <GeneralSettings />}
            {activeCategoryId === 'data-display' && <DataDisplaySettings />}
            {activeCategoryId === 'event-detail' && <EventDetailsSettings />}
            {activeCategoryId === 'event-form' && <EventFormSettings />}
            {activeCategoryId === 'event-field-properties' && <EventFieldProperties />}
          </>
        ) : (
          <>
            <CommonSourceBanner view={view} />
            <ViewSettingsCategoryList
              categories={CALENDAR_SETTINGS_CATEGORIES}
              onCategorySelect={handleCategorySelect}
            />
          </>
        )}
      </CollapsiblePanel.Content>
      <FormInputEditModal />
    </>
  );
}
