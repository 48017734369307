import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Select } from '@knack/asterisk-react';
import snakeCase from 'lodash.snakecase';

import { type KnackField } from '@/types/schema/KnackField';
import { type KnackOperator } from '@/types/schema/KnackOperator';
import { useCriteriaHelpers } from '@/hooks/helpers/useCriteriaHelpers';
import { type DataTableFiltersFormData } from '@/components/data-table/display/header/filters/Filters';

interface OperatorSelectProps {
  field: KnackField;
  ruleIndex: number;
}

export function OperatorSelect({ field, ruleIndex }: OperatorSelectProps) {
  const [t] = useTranslation();
  const { getCriteriaFieldOperators } = useCriteriaHelpers();

  const {
    getValues,
    setValue,
    clearErrors: clearFormErrors
  } = useFormContext<DataTableFiltersFormData>();

  const criteriaFieldOperators = getCriteriaFieldOperators(field, 'filter') || [];

  const onCriteriaOperatorChange = ({ criteriaFormFieldIndex, newOperator }) => {
    setValue(`criteria.${criteriaFormFieldIndex}`, {
      ...getValues(`criteria.${criteriaFormFieldIndex}`),
      operator: newOperator
    });

    clearFormErrors();
  };

  return (
    <Controller
      name={`criteria.${ruleIndex}.operator`}
      render={({ field: { value } }) => (
        <Select
          value={value}
          onValueChange={(newOperator: KnackOperator) => {
            onCriteriaOperatorChange({
              criteriaFormFieldIndex: ruleIndex,
              newOperator
            });
          }}
        >
          <Select.Trigger
            data-testid={`filter-rule-${ruleIndex}-operator-select`}
            placeholder={t('components.data_table.filtering.select')}
            className="w-full"
          />
          <Select.Content>
            {criteriaFieldOperators.map((fieldOperator) => (
              <Select.Item key={fieldOperator} value={fieldOperator}>
                {t(`operators.${snakeCase(fieldOperator)}`)}
              </Select.Item>
            ))}
          </Select.Content>
        </Select>
      )}
    />
  );
}
