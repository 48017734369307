import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  HiSquare2Stack as CopyIcon,
  HiEye as EyeIcon,
  HiEyeSlash as HideEyeIcon
} from 'react-icons/hi2';
import { Button, Skeleton, Tooltip, useToast } from '@knack/asterisk-react';

import { useApiKeyQuery } from '@/hooks/api/queries/useApiKeyQuery';
import { useApplication } from '@/hooks/useApplication';
import { copyToClipboard } from '@/utils/copy-to-clipboard';
import { LearnMoreLink } from '@/components/LearnMoreLink';
import { ApiUsageDialog } from '@/pages/settings/ApiUsageDialog';
import { GenerateApiKeyDialog } from '@/pages/settings/GenerateApiKeyDialog';

function ApiDocsLink({ children }: { children?: React.ReactNode }) {
  return (
    <LearnMoreLink href="https://docs.knack.com/docs/introduction-to-the-api">
      {children}
    </LearnMoreLink>
  );
}

enum CopyType {
  ApplicationId = 'application_id',
  Key = 'key'
}

const API_KEY_PLACEHOLDER = '••••••••••••••••••••••••••••••••••••';

export function ApiSettings() {
  const application = useApplication();
  const [t] = useTranslation();
  const { data: apiKey, isPending } = useApiKeyQuery();
  const { presentToast } = useToast();
  const [isApiKeyVisible, setIsApiKeyVisible] = useState(false);
  const [isApiUsageVisible, setIsApiUsageVisible] = useState(false);
  const [isGenerateNewApiKeyDialogVisible, setIsGenerateNewApiKeyDialogVisible] = useState(false);

  const copy = async (text: string, type: CopyType) => {
    await copyToClipboard(text);
    presentToast({
      title: t('app_settings.api.copied', { type: t(`app_settings.api.${type}`) })
    });
  };

  return (
    <div className="space-y-6">
      <p className="text-sm">
        <Trans i18nKey="app_settings.api.description">
          <ApiDocsLink />
        </Trans>
      </p>
      <div className="space-y-2">
        <p className="text-sm font-medium">{t('app_settings.api.application_id')}</p>
        <p className="text-sm text-subtle">{application?.id}</p>
        <Button
          intent="secondary"
          size="sm"
          onClick={() => copy(application?.id ?? '', CopyType.ApplicationId)}
        >
          <Button.Icon icon={CopyIcon} position="left" /> {t('app_settings.api.copy_id')}
        </Button>
      </div>
      <div className="space-y-2">
        <p className="text-sm font-medium">{t('app_settings.api.key')}</p>
        {isPending ? (
          <Skeleton className="h-6 w-72" />
        ) : (
          <div className="flex items-center gap-2">
            <p className="text-sm text-subtle">{isApiKeyVisible ? apiKey : API_KEY_PLACEHOLDER}</p>

            <Tooltip>
              <Tooltip.Trigger asChild>
                <Button
                  intent="minimal"
                  size="xs"
                  className="focus:outline-transparent"
                  onClick={() => setIsApiKeyVisible(!isApiKeyVisible)}
                >
                  {isApiKeyVisible ? <HideEyeIcon size={16} /> : <EyeIcon size={16} />}
                </Button>
              </Tooltip.Trigger>
              <Tooltip.Content>{t('app_settings.api.show_key')}</Tooltip.Content>
            </Tooltip>
          </div>
        )}
        <div className="flex gap-2">
          <Button
            intent="secondary"
            size="sm"
            disabled={isPending}
            onClick={() => copy(apiKey ?? '', CopyType.Key)}
          >
            <Button.Icon icon={CopyIcon} position="left" /> {t('app_settings.api.copy_key')}
          </Button>
          <Button
            intent="secondary"
            size="sm"
            disabled={isPending}
            onClick={() => setIsGenerateNewApiKeyDialogVisible(true)}
          >
            {t('app_settings.api.generate_key')}
          </Button>
        </div>
      </div>
      <Button intent="secondary" size="sm" onClick={() => setIsApiUsageVisible(true)}>
        <Button.Icon icon={EyeIcon} position="left" />
        {t('app_settings.api.view_usage')}
      </Button>
      <ApiUsageDialog isOpen={isApiUsageVisible} onOpenChange={setIsApiUsageVisible} />
      <GenerateApiKeyDialog
        isOpen={isGenerateNewApiKeyDialogVisible}
        onOpenChange={setIsGenerateNewApiKeyDialogVisible}
      />
    </div>
  );
}
