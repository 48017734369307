import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  HiTrash as DeleteIcon,
  HiBolt as DynamicIcon,
  HiPencil as EditIcon,
  HiLink as LinkIcon,
  HiRectangleStack as ViewIcon
} from 'react-icons/hi2';
import { Dialog } from '@knack/asterisk-react';
import { nanoid } from 'nanoid';

import { type BuilderPage } from '@/types/schema/BuilderPage';
import {
  VIEW_COLUMN_ID_PREFIX,
  type BuilderViewChildScenePayload
} from '@/types/schema/BuilderView';
import { type TableView } from '@/types/schema/views/TableView';
import { getDetailsViewSchemaFromPartialView } from '@/pages/pages/page-editor/add-view/helpers/view-schemas/detailsViewSchema';
import { getFormViewSchemaFromPartialView } from '@/pages/pages/page-editor/add-view/helpers/view-schemas/formViewSchema';
import { baseTableViewColumnSchema } from '@/pages/pages/page-editor/add-view/helpers/view-schemas/tableViewSchema';
import { usePageEditorContext } from '@/pages/pages/page-editor/PageEditorContext';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { ViewActionsSettings } from '@/pages/pages/settings-panel/view-settings/common/details-actions/ViewActionsSettings';
import { useUpdateView } from '@/pages/pages/settings-panel/view-settings/useUpdateView';
import { useViewInputEditModeContext } from '@/pages/pages/settings-panel/view-settings/ViewInputEditModeContext';

export function TableActionsSettingsCategory() {
  const [t] = useTranslation();
  const { view, sourceObject } = useActiveViewContext<TableView>();
  const { page: currentPage } = usePageEditorContext();
  const { setViewInputId, setIsViewInputEditMode } = useViewInputEditModeContext();
  const updateViewSchema = useUpdateView();

  const [isLinkToExistingPageDialogOpen, setIsLinkToExistingPageDialogOpen] = useState(false);

  const handleClickLinkAction = (isEditAction?: boolean) => {
    const detailsViewName = t('views.new_view_defaults.details.name', {
      objectName: sourceObject.inflections.singular
    });
    const editFormViewName = t('views.new_view_defaults.form.title_edit', {
      objectName: sourceObject.inflections.singular
    });

    const childDetailsView = getDetailsViewSchemaFromPartialView(
      {
        name: detailsViewName,
        title: detailsViewName,
        source: {
          object: sourceObject.key,
          criteria: {
            match: 'any',
            groups: [],
            rules: []
          }
        }
      },
      sourceObject
    );

    const childFormView = getFormViewSchemaFromPartialView(
      {
        action: 'update',
        name: editFormViewName,
        title: editFormViewName,
        source: {
          object: sourceObject.key,
          criteria: {
            match: 'any',
            groups: [],
            rules: []
          }
        }
      },
      sourceObject
    );

    const viewToAdd = isEditAction ? childFormView : childDetailsView;

    const newChildPageWithDefaultView: BuilderViewChildScenePayload = {
      name: viewToAdd.name,
      object: sourceObject.key,
      parent: currentPage.slug,
      views: [
        {
          ...viewToAdd
        }
      ]
    };

    updateViewSchema({
      columns: [
        ...view.columns,
        {
          ...baseTableViewColumnSchema,
          id: `${VIEW_COLUMN_ID_PREFIX}${nanoid(10)}`,
          link_text: isEditAction ? t('actions.edit') : t('actions.view'),
          header: newChildPageWithDefaultView.name,
          type: 'link',
          // We send an object with the partial information of the child page and the server is in charge of creating it
          scene: newChildPageWithDefaultView,
          align: 'center'
        }
      ]
    });
  };

  const handleClickDeleteRecordAction = () => {
    updateViewSchema({
      columns: [
        ...view.columns,
        {
          ...baseTableViewColumnSchema,
          id: `${VIEW_COLUMN_ID_PREFIX}${nanoid(10)}`,
          link_text: t('actions.delete'),
          header: t('actions.delete'),
          type: 'delete',
          align: 'center'
        }
      ]
    });
  };

  const handleClickDynamicAction = () => {
    const newColumnId = `${VIEW_COLUMN_ID_PREFIX}${nanoid(10)}`;

    updateViewSchema({
      columns: [
        ...view.columns,
        {
          ...baseTableViewColumnSchema,
          id: newColumnId,
          link_text: 'Action',
          header: 'Dynamic action',
          type: 'action_link',
          align: 'center',
          action_rules: []
        }
      ]
    });

    // Open the action column settings automatically
    setIsViewInputEditMode(true);
    setViewInputId(newColumnId);
  };

  const handleApplyLinkToExistingPage = (selectedPage: BuilderPage) => {
    updateViewSchema({
      columns: [
        ...view.columns,
        {
          ...baseTableViewColumnSchema,
          id: `${VIEW_COLUMN_ID_PREFIX}${nanoid(10)}`,
          link_text: selectedPage.name,
          header: selectedPage.name,
          type: 'link',
          scene: selectedPage.slug,
          align: 'center'
        }
      ]
    });
  };

  const viewActionList = [
    {
      key: 'edit',
      label: t('pages.element_settings.common.categories.actions.edit_record'),
      icon: EditIcon,
      onClick: () => handleClickLinkAction(true)
    },
    {
      key: 'view',
      label: t('pages.element_settings.common.categories.actions.view_record'),
      icon: ViewIcon,
      onClick: handleClickLinkAction
    },
    {
      key: 'delete',
      label: t('pages.element_settings.common.categories.actions.delete_record'),
      icon: DeleteIcon,
      onClick: handleClickDeleteRecordAction,
      isDisabled: view.columns.some((column) => column.type === 'delete')
    },
    {
      key: 'link',
      label: t('pages.element_settings.common.categories.actions.link_record'),
      icon: LinkIcon,
      onClick: () => setIsLinkToExistingPageDialogOpen(true),
      dataTestId: 'button-link-to-existing-page-action'
    },
    {
      key: 'action_link',
      label: t('pages.element_settings.common.categories.actions.dynamic_action'),
      icon: DynamicIcon,
      onClick: handleClickDynamicAction,
      dataTestId: 'button-dynamic-action-action'
    }
  ];

  return (
    <div className="space-y-2">
      <Dialog
        open={isLinkToExistingPageDialogOpen}
        onOpenChange={setIsLinkToExistingPageDialogOpen}
      >
        <ViewActionsSettings
          sourceObject={sourceObject}
          actions={viewActionList}
          onApplyLinkToExistingPage={handleApplyLinkToExistingPage}
          handleCloseLinkToExistingPageDialog={() => setIsLinkToExistingPageDialogOpen(false)}
        />
      </Dialog>
    </div>
  );
}
