import { type BuilderView, type BuilderViewKey } from '@/types/schema/BuilderView';
import { type KnackCriteriaDateTimeValue } from '@/types/schema/KnackCriteria';
import { type KnackFieldKey } from '@/types/schema/KnackField';
import { type KnackObjectProfileKey } from '@/types/schema/KnackObject';
import { type KnackOperator } from '@/types/schema/KnackOperator';

export type PageType = 'page' | 'user' | 'authentication' | 'menu';

export type BuilderPageKey = `scene_${string}`;

export interface PageIcon {
  icon: string;
  align: 'left' | 'right';
}

export interface BuilderPage {
  id: string;
  key: BuilderPageKey;
  slug: string;
  name: string;
  parentSlug: null | string;
  type: PageType;
  icon: null | PageIcon;
  sourceObjectKey: null | `object_${string}`;
  menuPageKey: null | BuilderPageKey;
  dropdownPages: null | BuilderPageKey[];
  requiresAuthentication: boolean;
  isTitleVisible: boolean;
  allowedProfileKeys?: KnackObjectProfileKey[];
  limitProfileAccess?: boolean;
  rules: BuilderPageRule[] | null;
  views: BuilderView[];
  groups: BuilderPageSection[];
  pageMenuDisplay?: boolean;
  ignoreEntrySceneMenu?: boolean;
  print?: boolean;
  modal?: boolean;
  modalPreventBackgroundClickClose?: boolean;
}

export interface BuilderPageRule {
  key: string;
  action: BuilderPageRuleActionType;
  criteria: BuilderPageRuleCriteria[];
  message: string;
  type: BuilderPageRuleMessageType;
  close_link: boolean;
  existing_page?: string;
  view_keys?: BuilderViewKey[];
  url?: string;
}

export const PAGE_RULE_ACTION_TYPES = [
  'message',
  'hide_views',
  'existing_page',
  'parent_page',
  'url'
] as const;
export type BuilderPageRuleActionType = (typeof PAGE_RULE_ACTION_TYPES)[number];

export const PAGE_RULE_MESSAGE_TYPES = ['neutral', 'alert', 'warning', 'confirmation'] as const;
export type BuilderPageRuleMessageType = (typeof PAGE_RULE_MESSAGE_TYPES)[number];

export type BuilderPageRuleCriteria = {
  field:
    | KnackFieldKey // regular field key if the field is in the source object of the page
    | `${KnackFieldKey}-${KnackFieldKey}` // connection field key if the field is from a connected object (e.g. `field_1-field_2`)
    | `${KnackObjectProfileKey}-${KnackFieldKey}`; // user role field key if the field is from a user role object (e.g. `profile_1-field_1`)
  operator: KnackOperator;
  value:
    | string

    // Only for boolean fields
    | boolean

    // If the value is an array of strings, it means the field is a connection. The array will contain the id of the record (e.g. ["66b3d6f8f160620293d83294"])
    | string[]

    // Value for date/time fields
    | KnackCriteriaDateTimeValue;
};

export interface BuilderPageSection {
  id: string;
  columns: BuilderPageColumn[];
  layout: SectionLayout;
}

export interface BuilderPageColumn {
  id: string;
  viewKeys: BuilderViewKey[];
  width: '25%' | '33%' | '50%' | '66%' | '75%' | '100%';
}

export const LAYOUT_COLUMNS = ['1', '2', '3'] as const;
export const LAYOUT_TWO_COLUMN_STRUCTURES = ['50/50', '66/33', '33/66', '25/75', '75/25'] as const;
export const LAYOUT_THREE_COLUMN_STRUCTURES = [
  '33/33/33',
  '50/25/25',
  '25/50/25',
  '25/25/50'
] as const;

export type SectionLayoutColumns = (typeof LAYOUT_COLUMNS)[number];
export type SectionLayoutTwoColumnStructure = (typeof LAYOUT_TWO_COLUMN_STRUCTURES)[number];
export type SectionLayoutThreeColumnStructure = (typeof LAYOUT_THREE_COLUMN_STRUCTURES)[number];

export type SectionLayout =
  | {
      columns: '1';
      structure: '100';
    }
  | {
      columns: '2';
      structure: SectionLayoutTwoColumnStructure;
    }
  | {
      columns: '3';
      structure: SectionLayoutThreeColumnStructure;
    };
