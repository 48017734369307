import { useState } from 'react';
import { HiDatabase as DatabaseIcon } from 'react-icons/hi';
import {
  HiCreditCard as CardIcon,
  HiEnvelope as EmailIcon,
  HiViewColumns as FieldsIcon,
  HiCog6Tooth as GeneralSettingsIcon,
  HiPaperAirplane as SubmissionIcon
} from 'react-icons/hi2';
import { t } from 'i18next';

import { type FormView } from '@/types/schema/views/FormView';
import { CollapsiblePanel } from '@/components/layout/CollapsiblePanel';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { FormEmailRules } from '@/pages/pages/settings-panel/view-settings/form/email-rules/FormEmailRules';
import { FormSourceBanner } from '@/pages/pages/settings-panel/view-settings/form/FormSourceBanner';
import { FormRecordRules } from '@/pages/pages/settings-panel/view-settings/form/record-rules/FormRecordRules';
import { FormSubmitRules } from '@/pages/pages/settings-panel/view-settings/form/submit-rules/FormSubmitRules';
import {
  ViewSettingsCategoryList,
  type ViewSettingsCategoryItem
} from '@/pages/pages/settings-panel/view-settings/ViewSettingsCategoryList';
import { FormFieldsCategory } from './FormFieldsCategory';
import { FormGeneralSettingsCategory } from './FormGeneralSettingsCategory';
import { FormInputEditModal } from './FormInputEditModal';

type FormSettingsCategoryId =
  | 'general-settings'
  | 'form-fields'
  | 'form-submit-rules'
  | 'form-record-rules'
  | 'form-email-rules'
  | 'ecommerce';
type FormSettingsCategoryItem = ViewSettingsCategoryItem<FormSettingsCategoryId>;

function getFormSettingsCategories(view: FormView) {
  const submitRulesCount = view.rules.submits?.length ?? 0;
  const recordRulesCount = view.rules.records?.length ?? 0;
  const emailRulesCount = view.rules.emails?.length ?? 0;

  const FORM_SETTINGS_CATEGORIES: FormSettingsCategoryItem[] = [
    {
      id: 'general-settings',
      name: t('pages.element_settings.form.categories.general_settings.name'),
      description: t('pages.element_settings.form.categories.general_settings.description'),
      icon: GeneralSettingsIcon
    },
    {
      id: 'form-fields',
      name: t('pages.element_settings.form.categories.form_fields.name'),
      description: t('pages.element_settings.form.categories.form_fields.description'),
      badge: t('pages.element_settings.form.categories.form_fields.rules_badge', {
        count: view.rules.fields?.length ?? 0
      }),
      icon: FieldsIcon
    },
    {
      id: 'form-submit-rules',
      name: t('pages.element_settings.form.categories.form_submit_rules.name'),
      description: t('pages.element_settings.form.categories.form_submit_rules.description'),
      badge: t('pages.element_settings.form.rules.rules_badge', {
        count: submitRulesCount
      }),
      icon: SubmissionIcon
    },
    {
      id: 'form-record-rules',
      name: t('pages.element_settings.form.categories.form_record_rules.name'),
      description: t('pages.element_settings.form.categories.form_record_rules.description'),
      badge: t('pages.element_settings.form.rules.rules_badge', {
        count: recordRulesCount
      }),
      icon: DatabaseIcon
    },
    {
      id: 'form-email-rules',
      name: t('pages.element_settings.form.categories.form_email_rules.name'),
      description: t('pages.element_settings.form.categories.form_email_rules.description'),
      badge: t('pages.element_settings.form.rules.rules_badge', {
        count: emailRulesCount
      }),
      icon: EmailIcon
    },
    {
      id: 'ecommerce',
      name: t('pages.element_settings.form.categories.ecommerce.name'),
      description: t('pages.element_settings.form.categories.ecommerce.description'),
      icon: CardIcon,
      isComingSoon: true
    }
  ];

  return FORM_SETTINGS_CATEGORIES;
}

function getPanelTitle(
  activeCategoryId: FormSettingsCategoryId | null,
  categories: FormSettingsCategoryItem[]
) {
  const activeCategory = activeCategoryId
    ? categories.find((category) => category.id === activeCategoryId)
    : null;

  return activeCategory ? activeCategory.name : t('pages.element_settings.form.heading');
}

export function FormSettings() {
  const { view } = useActiveViewContext<FormView>();

  const [activeCategoryId, setActiveCategoryId] = useState<FormSettingsCategoryId | null>(null);

  const formSettingsCategories = getFormSettingsCategories(view);
  const panelTitle = getPanelTitle(activeCategoryId, formSettingsCategories);

  return (
    <>
      <CollapsiblePanel.Content
        title={panelTitle}
        onBack={activeCategoryId ? () => setActiveCategoryId(null) : undefined}
        shouldHideDivider={activeCategoryId === 'form-fields'}
      >
        {activeCategoryId ? (
          <>
            {activeCategoryId === 'general-settings' && <FormGeneralSettingsCategory />}
            {activeCategoryId === 'form-fields' && <FormFieldsCategory />}
            {activeCategoryId === 'form-submit-rules' && <FormSubmitRules />}
            {activeCategoryId === 'form-record-rules' && <FormRecordRules />}
            {activeCategoryId === 'form-email-rules' && <FormEmailRules />}
          </>
        ) : (
          <>
            <FormSourceBanner />
            <ViewSettingsCategoryList
              categories={formSettingsCategories}
              onCategorySelect={(selectedCategoryId: string) => {
                setActiveCategoryId(selectedCategoryId as FormSettingsCategoryId);
              }}
            />
          </>
        )}
      </CollapsiblePanel.Content>

      <FormInputEditModal />
    </>
  );
}
