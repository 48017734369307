import { useTranslation } from 'react-i18next';
import { Label } from '@knack/asterisk-react';

import { type KnackFieldKey } from '@/types/schema/KnackField';
import { type KnackObject } from '@/types/schema/KnackObject';
import { InlineKnackField } from '@/components/InlineKnackField';

interface RuleFormHeaderProps {
  sourceObject: KnackObject;
  fieldKey: KnackFieldKey;
}

export function RuleFormHeader({ sourceObject, fieldKey }: RuleFormHeaderProps) {
  const [t] = useTranslation();

  const field = sourceObject.fields.find((f) => f.key === fieldKey);

  if (!field) {
    return null;
  }

  return (
    <div data-testid="rule-form-header">
      <Label className="mb-2 block font-medium">{t('components.data_table.sorting.field')}</Label>
      <InlineKnackField
        fieldType={field.type}
        fieldName={field.name}
        className="w-auto bg-subtle"
      />
    </div>
  );
}
