import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiCollapseVertical as CollapseIcon, BiExpandVertical as ExpandIcon } from 'react-icons/bi';
import { HiChevronDown as ChevronDownIcon, HiPlus as PlusIcon } from 'react-icons/hi2';
import { MdCallSplit as ConditionalIcon } from 'react-icons/md';
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  type DragEndEvent
} from '@dnd-kit/core';
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext } from '@dnd-kit/sortable';
import { Button, Collapsible, Divider, useToast } from '@knack/asterisk-react';

import {
  type ConditionalRule,
  type KnackField,
  type KnackFieldKey
} from '@/types/schema/KnackField';
import { type KnackObject } from '@/types/schema/KnackObject';
import { useFieldMutation } from '@/hooks/api/mutations/useFieldMutation';
import { EmptyState } from '@/components/EmptyState';
import { FieldIcon } from '@/components/FieldIcon';
import { SidePanel } from '@/components/SidePanel';
import { ConditionalRuleCardWrapper } from './ConditionalRulesCardWrapper';
import { ConditionalRulesForm } from './ConditionalRulesForm';

interface RuleToDuplicate {
  rule: ConditionalRule;
  fieldKey: KnackFieldKey;
}

interface SortableConditionalRulesProps {
  field: KnackField;
  sourceObject: KnackObject;
  onDuplicateClick: (args: RuleToDuplicate) => void;
}

interface ConditionalRulesPanelProps {
  sourceObject: KnackObject;
  originFieldKey?: KnackFieldKey;
}

function SortableConditionalRules({
  field,
  sourceObject,
  onDuplicateClick
}: SortableConditionalRulesProps) {
  const [t] = useTranslation();
  const { presentToast } = useToast();
  const sensors = useSensors(useSensor(PointerSensor), useSensor(KeyboardSensor));

  const { mutate: updateField } = useFieldMutation();

  const [sortableRules, setSortableRules] = useState<ConditionalRule[]>(
    () => field.rules?.filter((rule) => rule.key) ?? []
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = sortableRules.findIndex((v) => v.key === active.id);
      const newIndex = sortableRules.findIndex((v) => v.key === over.id);
      const newOrderedRules = arrayMove(sortableRules, oldIndex, newIndex);

      setSortableRules(newOrderedRules);

      updateField(
        {
          tableKey: sourceObject.key,
          updatedField: {
            ...field,
            rules: newOrderedRules
          }
        },
        {
          onError: () => {
            presentToast({
              title: t(
                'components.data_table.right_sidebar.conditional_rules.errors.rule_sort_error'
              )
            });
          }
        }
      );
    }
  };

  useEffect(() => {
    setSortableRules(field.rules?.filter((rule) => rule.key) ?? []);
  }, [field.rules]);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      modifiers={[restrictToVerticalAxis, restrictToParentElement]}
    >
      <SortableContext items={sortableRules.map((rule) => rule.key)}>
        <div className="space-y-2">
          {sortableRules.map((rule) => (
            <ConditionalRuleCardWrapper
              key={rule.key}
              rule={rule}
              sourceObject={sourceObject}
              field={field}
              onDuplicateClick={() => {
                onDuplicateClick({ rule, fieldKey: field.key });
              }}
            />
          ))}
        </div>
      </SortableContext>
    </DndContext>
  );
}

export function ConditionalRulesPanel({
  sourceObject,
  originFieldKey
}: ConditionalRulesPanelProps) {
  const [t] = useTranslation();

  const fieldsWithRules = sourceObject.fields.filter((field) => field.rules?.length);

  const [shouldRenderForm, setShouldRenderForm] = useState(
    () => fieldsWithRules.length === 0 && !!originFieldKey
  );
  const [ruleToDuplicate, setRuleToDuplicate] = useState<RuleToDuplicate | null>(null);
  const [fieldsWithExpandedRules, setFieldsWithExpandedRules] =
    useState<KnackField[]>(fieldsWithRules);

  useEffect(() => {
    setFieldsWithExpandedRules(sourceObject.fields.filter((field) => field.rules?.length));
  }, [sourceObject.fields]);

  return (
    <>
      <SidePanel.Header>
        <SidePanel.Title
          className="text-xl font-medium leading-6"
          data-testid="conditional-rules-title"
        >
          {t('components.data_table.right_sidebar.conditional_rules.title')}
        </SidePanel.Title>
        <SidePanel.Description className="text-xs">
          {t('components.data_table.right_sidebar.conditional_rules.subtitle')}
        </SidePanel.Description>
      </SidePanel.Header>

      {!fieldsWithRules.length && !shouldRenderForm && (
        <div className="mt-2">
          <EmptyState data-testid="conditional-rules-empty-state">
            <EmptyState.Icon className="rotate-90" icon={<ConditionalIcon />} />
            <EmptyState.Description className="text-emphasis">
              {t('components.data_table.right_sidebar.conditional_rules.description')}
            </EmptyState.Description>
          </EmptyState>
        </div>
      )}

      {shouldRenderForm ? (
        <div className="-mx-2 mt-4 h-[calc(100%-64px)] overflow-auto px-2">
          <ConditionalRulesForm
            sourceObject={sourceObject}
            existingRule={ruleToDuplicate?.rule}
            defaultRuleFieldKey={ruleToDuplicate?.fieldKey || originFieldKey}
            isDuplicating={!!ruleToDuplicate}
            onCancel={() => {
              setRuleToDuplicate(null);
              setShouldRenderForm(false);
            }}
            onSaveSuccess={() => {
              setRuleToDuplicate(null);
              setShouldRenderForm(false);
            }}
          />
        </div>
      ) : (
        <div className="align-center mt-4 flex flex-row justify-between gap-2">
          <Button
            className={fieldsWithRules.length <= 1 ? 'w-full' : ''}
            intent="secondary"
            onClick={() => setShouldRenderForm(true)}
            data-testid="conditional-rule-add-button"
          >
            <Button.Icon icon={PlusIcon} position="left" />
            {t('components.data_table.right_sidebar.conditional_rules.conditional_rule')}
          </Button>

          {fieldsWithRules.length > 1 && (
            <span className="flex flex-row items-center gap-2">
              <Button
                className="py-2 pl-1 pr-2"
                intent="link"
                onClick={() => setFieldsWithExpandedRules(fieldsWithRules)}
                // We disable the 'Expand All' button if all fields are already expanded.
                disabled={fieldsWithExpandedRules.length === fieldsWithRules.length}
              >
                <Button.Icon icon={ExpandIcon} position="left" />
                {t('components.data_table.right_sidebar.expand_all')}
              </Button>
              <Button
                className="py-2 pl-1 pr-2"
                intent="link"
                onClick={() => setFieldsWithExpandedRules([])}
                // We disabled the 'Collapse All' button if both all fields are already collapsed.
                disabled={fieldsWithExpandedRules.length === 0}
              >
                <Button.Icon icon={CollapseIcon} position="left" />
                {t('components.data_table.right_sidebar.collapse_all')}
              </Button>
            </span>
          )}
        </div>
      )}

      {!shouldRenderForm && fieldsWithRules.length > 0 && (
        <>
          <Divider className="mt-4" />
          <div
            className="-mx-2 h-[calc(100%-124px)] space-y-4 overflow-auto p-4"
            data-testid="conditional-rules-list"
          >
            {fieldsWithRules.map((field, index) => (
              <Collapsible
                defaultOpen
                key={field.key}
                data-testid={`conditional-rule-collapsible-${index}`}
                open={fieldsWithExpandedRules.some((f) => f.key === field.key)}
                onOpenChange={(isExpanded) => {
                  if (isExpanded) {
                    setFieldsWithExpandedRules([...fieldsWithExpandedRules, field]);
                  } else {
                    setFieldsWithExpandedRules(
                      fieldsWithExpandedRules.filter((f) => f.key !== field.key)
                    );
                  }
                }}
              >
                <Collapsible.Trigger
                  className="group flex w-full items-center transition-all"
                  data-testid="conditional-rule-collapsible-trigger"
                >
                  <span className="flex items-center">
                    <FieldIcon name={field.type} size={16} className="mr-1" />
                    <span className="mr-2 truncate font-semibold">{field.name}</span>
                    <span
                      data-testid="conditional-rule-count"
                      className="flex size-5 items-center justify-center rounded-sm bg-muted p-1 text-xs font-medium"
                    >
                      {String(field.rules?.length) || '0'}
                    </span>
                  </span>
                  <ChevronDownIcon
                    size={16}
                    className="ml-auto transition-transform duration-300 group-data-[state=open]:rotate-180"
                  />
                </Collapsible.Trigger>
                <Collapsible.Content className="mt-2">
                  <SortableConditionalRules
                    field={field}
                    sourceObject={sourceObject}
                    onDuplicateClick={({ rule, fieldKey }) => {
                      setRuleToDuplicate({ rule, fieldKey });
                      setShouldRenderForm(true);
                    }}
                  />
                </Collapsible.Content>
              </Collapsible>
            ))}
          </div>
        </>
      )}
    </>
  );
}
