import { Fragment, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Badge, Tooltip } from '@knack/asterisk-react';
import snakeCase from 'lodash.snakecase';

import { type KnackField } from '@/types/schema/KnackField';
import { type FormViewDisplayRule } from '@/types/schema/views/FormView';
import { InlineKnackCriteriaValue } from '@/components/InlineKnackCriteriaValue';
import { InlineKnackField } from '@/components/InlineKnackField';
import { InlineKnackValue } from '@/components/InlineKnackValue';
import { EmptyRuleValue } from '@/pages/pages/settings-panel/view-settings/common/EmptyRuleValue';
import { SortableCardWrapper } from '@/pages/pages/settings-panel/view-settings/common/SortableCardWrapper';
import { FormDisplayRuleFormDialog } from './FormDisplayRuleFormDialog';

interface FormDisplayRuleCardProps {
  displayRule: FormViewDisplayRule;
  availableViewFields: KnackField[];
  ruleNumber: number;
  isInvalid?: boolean;
  shouldScrollIntoView?: boolean;
  isDragOverlay?: boolean;
  onRuleSave: (updatedDisplayRule: FormViewDisplayRule) => void;
  onRuleDuplicate: (displayRuleToDuplicate: FormViewDisplayRule) => void;
  onRuleDelete: (displayRuleKey: FormViewDisplayRule['key']) => void;
}

export function FormDisplayRuleCard({
  displayRule,
  availableViewFields,
  ruleNumber,
  isInvalid = false,
  shouldScrollIntoView = false,
  isDragOverlay = false,
  onRuleSave,
  onRuleDuplicate,
  onRuleDelete
}: FormDisplayRuleCardProps) {
  const [t] = useTranslation();

  const displayRuleCardRef = useRef<HTMLDivElement | null>(null);
  const [isEditRuleModalOpen, setIsEditRuleModalOpen] = useState(false);

  useEffect(() => {
    if (shouldScrollIntoView && displayRuleCardRef.current) {
      displayRuleCardRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <SortableCardWrapper
        ref={displayRuleCardRef}
        sortableItemId={displayRule.key}
        isDragOverlay={isDragOverlay}
        isInactiveState={isInvalid}
        onEditClick={() => setIsEditRuleModalOpen(true)}
        onDuplicateClick={() => onRuleDuplicate(displayRule)}
        onDeleteClick={() => onRuleDelete(displayRule.key)}
      >
        <div className="mb-2 inline-flex items-center">
          <span className="text-xs font-medium text-emphasis">
            {t('components.display_rule_card.rule_number', {
              number: ruleNumber
            })}
          </span>

          {isInvalid && (
            <Tooltip>
              <Tooltip.Trigger asChild>
                <Badge intent="warning" className="ml-2 cursor-pointer leading-none">
                  {t('components.display_rule_card.invalid_rule_badge')}
                </Badge>
              </Tooltip.Trigger>
              <Tooltip.Portal>
                <Tooltip.Content className="max-w-80" side="bottom">
                  {t('components.display_rule_card.invalid_form_rule_tooltip')}
                </Tooltip.Content>
              </Tooltip.Portal>
            </Tooltip>
          )}
        </div>

        {displayRule.criteria.length === 0 && <EmptyRuleValue />}

        {displayRule.criteria.map((criteria, index) => {
          const criteriaField = availableViewFields.find((f) => f.key === criteria.field);
          const isLastCriteria = index === displayRule.criteria.length - 1;

          return (
            // eslint-disable-next-line react/no-array-index-key
            <Fragment key={`${criteria.field}-${index}`}>
              {criteriaField ? (
                <div className="flex flex-wrap items-center gap-1">
                  {index === 0 && <span>{t('components.rules.when')}</span>}
                  <InlineKnackField
                    fieldType={criteriaField.type}
                    fieldName={criteriaField.name}
                    className="border-none bg-action"
                  />{' '}
                  {t(`operators.${snakeCase(criteria.operator)}`)}{' '}
                  <InlineKnackCriteriaValue
                    criteria={criteria}
                    criteriaField={criteriaField}
                    className="bg-action"
                  />
                </div>
              ) : (
                <EmptyRuleValue />
              )}

              {!isLastCriteria && <div className="my-1">{t('components.rules.and_uppercase')}</div>}
            </Fragment>
          );
        })}

        <span className="my-2">{t('components.rules.then')}</span>

        {displayRule.actions.length === 0 && <EmptyRuleValue />}

        {displayRule.actions.map((action, index) => {
          const actionField = availableViewFields.find((f) => f.key === action.field);

          return (
            // eslint-disable-next-line react/no-array-index-key
            <Fragment key={`${action.field}-${index}`}>
              {actionField ? (
                <div>
                  {action.action === 'label' ? (
                    <Trans
                      i18nKey="components.display_rule_card.actions_display.label"
                      components={[
                        <InlineKnackField
                          key="0"
                          fieldType={actionField.type}
                          fieldName={actionField.name}
                          className="border-none bg-action"
                        />,
                        <InlineKnackValue key="1" value={action.value} className="bg-action" />
                      ]}
                    />
                  ) : (
                    <>
                      {t(`components.display_rule_card.actions.forms.${snakeCase(action.action)}`)}{' '}
                      <InlineKnackField
                        fieldType={actionField.type}
                        fieldName={actionField.name}
                        className="border-none bg-action"
                      />{' '}
                    </>
                  )}
                </div>
              ) : (
                <EmptyRuleValue />
              )}

              {index < displayRule.actions.length - 1 && (
                <div className="my-1">{t('components.rules.and_uppercase')}</div>
              )}
            </Fragment>
          );
        })}
      </SortableCardWrapper>

      {isEditRuleModalOpen && (
        <FormDisplayRuleFormDialog
          onOpenChange={setIsEditRuleModalOpen}
          displayRule={displayRule}
          availableViewFields={availableViewFields}
          onRuleSave={(updatedDisplayRule) => {
            setIsEditRuleModalOpen(false);
            onRuleSave(updatedDisplayRule);
          }}
        />
      )}
    </>
  );
}
