import { useCallback, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IMask, useIMask } from 'react-imask';
import { Input, Label, Switch } from '@knack/asterisk-react';
import type { MaskedPattern, MaskedRegExp } from 'imask';

import { PHONE_FORMAT_TO_MASK } from '@/utils/constants';
import { getMaskConfig } from '@/components/data-table/display/fields/phone/PhoneEdit';

export function DefaultValue({ defaultValue }: { defaultValue: string }) {
  const [t] = useTranslation();
  const firstOnAcceptCall = useRef(true);

  const [showEditDefaultValue, setShowEditDefaultValue] = useState(!!defaultValue || false);

  const { setValue, getValues } = useFormContext();

  const currentFormat = getValues('format.format');
  const hasExtension = getValues('format.extension');

  const maskConfig = getMaskConfig(currentFormat, hasExtension);

  const getCurrentValueWithoutPlaceholder = useCallback(
    (currentValue: string) => {
      if (currentFormat === 'any') {
        return currentValue;
      }

      const pipeConfig =
        maskConfig.type === 'pattern'
          ? { mask: maskConfig.mask, placeholderChar: maskConfig.placeholderChar }
          : { mask: maskConfig.mask };

      return IMask.createPipe(pipeConfig)(currentValue);
    },
    [currentFormat, maskConfig]
  );

  const imaskConfig =
    maskConfig.type === 'pattern'
      ? ({
          mask: maskConfig.mask,
          placeholderChar: maskConfig.placeholderChar
        } as MaskedPattern<string>)
      : ({
          mask: maskConfig.mask
        } as MaskedRegExp);

  const { ref: inputRef } = useIMask<HTMLInputElement>(imaskConfig, {
    defaultValue: defaultValue || '',
    onAccept: (val) => {
      if (firstOnAcceptCall.current) {
        firstOnAcceptCall.current = false;
        return;
      }

      setValue('default', getCurrentValueWithoutPlaceholder(val));
    }
  });

  return (
    <div className="flex">
      <Switch
        className="mr-2"
        data-testid="phone-default-value-switch"
        checked={showEditDefaultValue}
        onCheckedChange={() => {
          if (showEditDefaultValue) {
            setValue('default', '');
          }
          setShowEditDefaultValue(!showEditDefaultValue);
        }}
      />

      <div className="flex flex-grow flex-col">
        <Label htmlFor="field-settings-default-value-input">
          {t('components.data_table.attributes.field_settings.common.add_default_value')}
        </Label>
        {showEditDefaultValue && (
          <Input
            placeholder={PHONE_FORMAT_TO_MASK[currentFormat]}
            id="phone-field-settings-default-value-input"
            className="mt-2 w-full rounded-lg"
            data-testid="default-value-input"
            ref={inputRef}
          />
        )}
      </div>
    </div>
  );
}
