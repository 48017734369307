import { type AppTheme } from '@knack/asterisk-react';
import cloneDeep from 'lodash/cloneDeep';
import isArray from 'lodash/isArray';
import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';
import mapValues from 'lodash/mapValues';

/**
 * Remove a nested field from an object by a dot-separated path.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function removeNestedField(obj: Record<string, any>, path: string) {
  const keys = path.split('.');
  let current = obj;
  for (let i = 0; i < keys.length - 1; i += 1) {
    if (!current || typeof current !== 'object' || !(keys[i] in current)) {
      return; // Path doesn't exist, nothing to remove
    }
    current = current[keys[i]];
  }
  delete current[keys[keys.length - 1]];
}

/**
 * Check if the structure of the imported theme is valid by comparing it with the default theme.
 */
export function isThemeStructureValid(importedTheme: AppTheme, defaultAppTheme: AppTheme) {
  if (
    typeof importedTheme !== 'object' ||
    importedTheme === null ||
    typeof defaultAppTheme !== 'object' ||
    defaultAppTheme === null
  ) {
    return false;
  }

  const keys1 = Object.keys(importedTheme);
  const keys2 = Object.keys(defaultAppTheme);

  // Return if the root keys are different
  if (keys1.length !== keys2.length) {
    return false;
  }

  // Helper function to get the structure and types of the object
  function getStructureAndTypes(obj) {
    return mapValues(obj, (value) => {
      if (isArray(value)) {
        return value.map((item) => typeof item);
      }
      if (isObject(value)) {
        return getStructureAndTypes(value); // Recursively get structure for nested objects
      }
      return typeof value; // Return type for primitive values
    });
  }

  // Clone the objects to avoid modifying the original ones
  const newTheme = cloneDeep(importedTheme);
  const defaultTheme = cloneDeep(defaultAppTheme);

  // Remove the `colors.customTokens` field from both objects because it's not needed for comparison
  removeNestedField(newTheme, 'colors.customTokens');
  removeNestedField(defaultTheme, 'colors.customTokens');

  // Check if the new theme has a custom font and remove it from the comparison
  if (
    newTheme.font &&
    typeof newTheme.font === 'object' &&
    'name' in newTheme.font &&
    'type' in newTheme.font &&
    'url' in newTheme.font
  ) {
    removeNestedField(newTheme, 'font');
    removeNestedField(defaultTheme, 'font');
  }

  // Get the structure and types of both objects
  const structure1 = getStructureAndTypes(newTheme);
  const structure2 = getStructureAndTypes(defaultTheme);

  // Use lodash's isEqual to compare the structures
  return isEqual(structure1, structure2);
}
