import * as z from 'zod';

import { type DateTimeField } from '@/types/schema/fields/DateTimeField';
import { getKnackDateRepeatSchema } from '@/components/knack-date-picker/components/KnackDatePickerRepeatOptions';
import { getDatePickerZodSchema } from '@/components/knack-date-picker/form/DatePickerFormSchemas';
import { getKnackDatePickerFormatFromField } from '@/components/knack-date-picker/transformers/DateTimeFormatToLuxonFormat';

export const getKnackDatePickerSchema = (field: DateTimeField, t: (value: string) => string) => {
  const calendarMode = field.format.calendar ? 'range' : 'single';

  const datePickerFormat = getKnackDatePickerFormatFromField(field.format);

  return z
    .object({
      date: getDatePickerZodSchema(calendarMode, datePickerFormat, t, field.required)
    })
    .merge(getKnackDateRepeatSchema(t));
};

export type KnackDatePickerSchema = z.infer<ReturnType<typeof getKnackDatePickerSchema>>;

export const getKnackDatePickerInitialValues = () => ({
  all_day: false
});
