import { useLayoutEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Popover } from '@knack/asterisk-react';
import Editor, { useMonaco, type OnMount } from '@monaco-editor/react';
import { type editor } from 'monaco-editor';

import { type KnackField } from '@/types/schema/KnackField';
import { type KnackObject } from '@/types/schema/KnackObject';
import { cn } from '@/utils/tailwind';
import { replaceTemplateVariables } from '@/components/field-settings/utils/replaceTemplateVariables';
import { FieldSelector } from '@/components/field-settings/utils/rich-text/FieldSelector';
import { FormulaSelector } from '@/components/field-settings/utils/rich-text/FormulaSelector';
import { useTemplateKeyName } from '@/components/field-settings/utils/rich-text/useTemplateKeyName';
import { ToolbarDropdownButton } from '@/components/rich-text/ToolbarDropdownButton';

type RichTextCodeEditorProps = {
  functionTypes: 'numeric' | 'date' | 'strings';
  content: string;
  onChange: (value: string) => void;
  field: KnackField;
  objectKey: KnackObject['key'];
};

export function RichTextCodeEditor({
  functionTypes,
  content,
  onChange,
  field,
  objectKey
}: RichTextCodeEditorProps) {
  const [t] = useTranslation();
  const editorRef = useRef<editor.IStandaloneCodeEditor>();
  const monaco = useMonaco();

  const { templateKeyVariables, templateNameVariables, fieldTypes } = useTemplateKeyName(objectKey);

  const handleEditorDidMount: OnMount = (editorMounted) => {
    editorRef.current = editorMounted;
  };

  // This will be called before the editor is mounted
  // With this we ensure that when the editor is mounted, it has the correct configuration
  useLayoutEffect(() => {
    // TODO: FE-2947 - Implement autocomplete and diagnostics for our custom functions
    // This adds custom declarations to the editor
    // monaco?.languages.typescript.typescriptDefaults.setExtraLibs([
    //   {
    //     content: `
    //     declare function left(text: string, number: number): void;
    //   `,
    //     filePath: 'node_modules/@types/external/index.d.ts'
    //   }
    // ]);

    // Identify template strings and numbers for syntax highlighting
    monaco?.languages.setMonarchTokensProvider('typescript', {
      tokenizer: {
        root: [
          [/\{(.*?)\}/, 'template-string'],
          [/[0-9:]+/, 'number']
        ]
      }
    });

    // Define a new theme that contains only rules that match this language
    monaco?.editor.defineTheme('knack-theme', {
      base: 'vs-dark',
      inherit: true,
      rules: [
        { token: 'template-string', foreground: '00CC00' },
        { token: 'number', foreground: '00CCCC' }
      ],
      colors: {}
    });

    // Remove any extra lib
    monaco?.languages.typescript.javascriptDefaults.setExtraLibs([]);

    // Change compiler options to also remove all the libs
    monaco?.languages.typescript.typescriptDefaults.setCompilerOptions({
      target: monaco.languages.typescript.ScriptTarget.ES2016,
      allowNonTsExtensions: true,
      noLib: true,
      allowJs: true,
      checkJs: true,
      moduleResolution: monaco.languages.typescript.ModuleResolutionKind.NodeJs,
      module: monaco.languages.typescript.ModuleKind.CommonJS,
      typeRoots: ['node_modules/@types'],
      lib: [] // We removed all the libs to avoid having any predefined functions
    });

    // Disabling specific diagnostics that can mess with our custom language
    // For example, we want to allow template strings to be used as strings
    monaco?.languages.typescript.typescriptDefaults.setDiagnosticsOptions({
      noSemanticValidation: true,
      noSyntaxValidation: true,
      noSuggestionDiagnostics: true
      // TODO: FE-2947 - Implement custom autocompletion
      // If we re-enable the diagnostics, we can ignore specific codes to accommodate our custom language
      // diagnosticCodesToIgnore: [1434, 1161, 1005, 2304, 2769, 18004, 2345]
    });

    // TODO: FE-2947 - Implement custom autocompletion
    // This code can be handy to autocomplete template strings when the user types '{' or press 'Ctrl+Space'

    // const registerCompletionDispose = monaco?.languages.registerCompletionItemProvider(
    //   'typescript',
    //   {
    //     triggerCharacters: [
    //       '{',
    //     ],
    //     provideCompletionItems: (model, position) => {
    //       const word = model.getWordUntilPosition(position);
    //       const range = {
    //         startLineNumber: position.lineNumber,
    //         endLineNumber: position.lineNumber,
    //         startColumn: word.startColumn,
    //         endColumn: word.endColumn
    //       };
    //       return {
    //         incomplete: true,
    //         suggestions: [
    //           {
    //             label: '{Short Text3}',
    //             kind: monaco.languages.CompletionItemKind.Keyword,
    //             documentation: '{Short Text3}',
    //             insertText: '{Short Text3}',
    //             range,
    //             triggerCharacters: ['{'],
    //             commitCharacters: ['{']
    //           }
    //         ]
    //       };
    //     }
    //   }
    // );

    return () => {
      // registerCompletionDispose?.dispose();
    };
  }, [monaco]);

  return (
    <div>
      <div
        className={cn(
          'flex shrink-0 flex-wrap items-center gap-1 overflow-auto rounded-tl-lg rounded-tr-lg border-b border-t border-subtle bg-default p-2 pb-2'
        )}
        data-testid="rich-text-code-editor"
      >
        <Popover>
          <Popover.Trigger>
            <ToolbarDropdownButton>
              {t('components.data_table.attributes.field_settings.rich_text_formula.button')}
            </ToolbarDropdownButton>
          </Popover.Trigger>
          <Popover.Content>
            <FormulaSelector
              editorRef={editorRef}
              equationType={functionTypes}
              fieldTypes={fieldTypes}
            />
          </Popover.Content>
        </Popover>
        <Popover>
          <Popover.Trigger>
            <ToolbarDropdownButton>
              {t('components.data_table.attributes.field_settings.rich_text_fields.button')}
            </ToolbarDropdownButton>
          </Popover.Trigger>

          <Popover.Content>
            <FieldSelector editorRef={editorRef} field={field} objectKey={objectKey} />
          </Popover.Content>
        </Popover>
      </div>
      <Editor
        height="200px"
        defaultLanguage="typescript"
        defaultValue={
          // Here we replace the field key with the field name in the editor.
          // This is because we want the user to see the field name instead of the field key. {Number 1} instead of {field_321}
          replaceTemplateVariables({
            text: content,
            variableList: templateKeyVariables,
            valueList: templateNameVariables.map((name) => `{${name}}`)
          })
        }
        theme="knack-theme"
        options={{
          minimap: { enabled: false },
          padding: { top: 10, bottom: 10 },
          fontSize: 14,
          wordWrap: 'on',
          scrollBeyondLastLine: false,
          automaticLayout: true,
          lineNumbers: 'off',
          contextmenu: false,
          // TODO: FE-2947 - Re-enable this suggestions with our custom ones
          quickSuggestions: false,
          hover: { enabled: false },
          suggest: {
            snippetsPreventQuickSuggestions: true,
            showWords: false,
            showKeywords: false,
            showModules: false,
            showValues: false,
            showConstants: false,
            showDeprecated: false,
            showFunctions: false,
            showInterfaces: false,
            showVariables: false,
            showClasses: false
          }
        }}
        onMount={handleEditorDidMount}
        onChange={(value) => {
          onChange(
            // Here we replace the field name back to the field key before saving it to the database. {field_321} instead of {Number 1}
            replaceTemplateVariables({
              text: value || '',
              variableList: templateNameVariables,
              valueList: templateKeyVariables.map((key) => `{${key}}`)
            })
          );
        }}
      />
    </div>
  );
}
