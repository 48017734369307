import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  type DragEndEvent
} from '@dnd-kit/core';
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext } from '@dnd-kit/sortable';
import { Spinner, useToast } from '@knack/asterisk-react';

import { type KnackField } from '@/types/schema/KnackField';
import { useTableMutation } from '@/hooks/api/mutations/useTableMutation';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { FieldRow } from '@/pages/tables/fields/FieldRow';
import { useFieldsStore } from './useFieldsStore';

export function FieldsList() {
  const [t] = useTranslation();
  const { presentToast } = useToast();

  const sensors = useSensors(useSensor(PointerSensor), useSensor(KeyboardSensor));

  const { sortFieldsMutation } = useTableMutation();

  const { data: application, isLoading: isAppLoading } = useApplicationQuery();

  const fields = useFieldsStore().use.fields();
  const visibleFields = useFieldsStore().use.visibleFields();
  const tableKey = useFieldsStore().use.objectKey();

  const { setVisibleFields } = useFieldsStore().use.actions();

  const table = useMemo(
    () => application?.objects.find((o) => o.key === tableKey),
    [application, tableKey]
  );

  if (!table) {
    return null;
  }

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const draggedField = fields.find((f) => f.key === active.id);

      if (!draggedField) return;

      const oldIndex = fields.findIndex((v) => v.key === active.id);
      const newIndex = fields.findIndex((v) => v.key === over.id);

      const updatedTable = {
        ...table,
        fields: arrayMove(fields, oldIndex, newIndex)
      };

      setVisibleFields(updatedTable.fields);

      sortFieldsMutation.mutate(
        {
          object_key: table.key,
          order: updatedTable.fields.map((f) => f.key)
        },
        {
          onError: () => {
            presentToast({
              title: t('pages.tables_page.fields_list.sort_error')
            });
          }
        }
      );
    }
  };

  if (isAppLoading) {
    return (
      <div data-testid="fields-list-container" className="flex w-full items-center justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="mb-6 flex size-full flex-col gap-2 px-6">
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToVerticalAxis, restrictToParentElement]}
      >
        <SortableContext items={visibleFields.map((field: KnackField) => field.key)}>
          {visibleFields.map((field, i) => (
            <FieldRow field={field} index={i} key={field.key} />
          ))}
        </SortableContext>
      </DndContext>
    </div>
  );
}
