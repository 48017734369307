import {
  type BuilderViewActionRule,
  type BuilderViewBase,
  type BuilderViewChildScenePayload,
  type BuilderViewColumnIcon,
  type BuilderViewLinkColumnType,
  type BuilderViewSourceSchema
} from '@/types/schema/BuilderView';
import { type KnackCriteria } from '@/types/schema/KnackCriteria';
import { type KnackFieldKey } from '@/types/schema/KnackField';

export const DETAILS_VIEW_DIVIDER_INPUT_ID_PREFIX = 'divider_';
export const DETAILS_VIEW_RICH_TEXT_INPUT_ID_PREFIX = 'rich_text_';
export const DETAILS_VIEW_LINK_INPUT_ID_PREFIX = 'scene_link_';
export const DETAILS_VIEW_ACTION_INPUT_ID_PREFIX = 'action_link_';

export interface DetailsView extends BuilderViewBase {
  id: string;
  type: 'details';
  columns: DetailsViewOuterColumn[];
  layout: 'full' | 'one-column' | 'two-column' | 'three-column';
  label_format: 'left' | 'right' | 'top' | 'none';
  groups: unknown[];
  inputs: unknown[];
  links: unknown[];
  source: BuilderViewSourceSchema;
  hide_fields: boolean;
  rules?: {
    fields: DetailsViewDisplayRule[];
  };
  design: unknown;
}

export const DETAILS_VIEW_LAYOUT_COLUMNS = ['1', '2', '3'] as const;
export type DetailsViewLayoutColumns = (typeof DETAILS_VIEW_LAYOUT_COLUMNS)[number];

export interface DetailsViewOuterColumn {
  id: string;
  groups: DetailsViewOuterColumnGroup[];
  width: number;
}

export interface DetailsViewOuterColumnGroup {
  id: string;
  columns: DetailsViewColumn[];
}

export type DetailsViewColumn = DetailsViewInput[];

export interface DetailsViewInput {
  id: string;
  label?: string;
  key: KnackFieldKey;
  type: 'field' | 'scene_link' | 'action_link' | 'delete' | 'divider' | 'special_title';
  name: string;
  scene?: string | BuilderViewChildScenePayload;
  action_rules?: BuilderViewActionRule[];
  conn_separator: 'comma' | 'new_line' | '';
  conn_link: `scene_${string}` | '';
  link_type: BuilderViewLinkColumnType;
  link_text: string;
  link_field: string;
  link_design_active: boolean;
  icon: BuilderViewColumnIcon | null;
  img_gallery: string;
  show_map: boolean;
  map_width: number;
  map_height: number;
  value: string;
  copy: string;
  format: {
    label_custom: boolean;
    label_format: DetailsViewInputLabelAlignment;
    styles?: DetailsViewInputFieldStyles[];
  };
  connection?: {
    key: KnackFieldKey;
  };
}

export type DetailsViewInputFieldStyles = DetailsViewInputLabelText | DetailsViewInputLabelHeading;
export type DetailsViewInputLabelText = 'strong' | 'em';
export type DetailsViewInputLabelAlignment = 'left' | 'right' | 'top' | 'none';
export type DetailsViewInputLabelHeading = 'h1' | 'h2';

export interface DetailsViewDisplayRule {
  key: KnackFieldKey;
  criteria: KnackCriteria[];
  actions: DetailsViewDisplayRuleAction[];
}

export interface DetailsViewDisplayRuleAction {
  action: DetailsViewDisplayRuleActionType;
  field: KnackFieldKey;
  value: string;
  color?: string;
  bold?: boolean;
  italic?: boolean;
  strikethrough?: boolean;
  icon?: DetailsViewDisplayRuleIcon;
}

export const DETAILS_VIEW_DISPLAY_RULE_ACTION_TYPES = [
  'show',
  'hide',
  'label',
  'bg-color',
  'text-color',
  'text-style'
] as const;

export type DetailsViewDisplayRuleActionType =
  (typeof DETAILS_VIEW_DISPLAY_RULE_ACTION_TYPES)[number];

export type DetailsViewDisplayRuleIcon = {
  icon: string;
  align: 'left' | 'right';
  color: string;
};

export type NewlyInsertedDetailsDisplayRule = {
  key: string;
  insertAction: 'new' | 'duplicate';
};
