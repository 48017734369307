import { Label } from '@knack/asterisk-react';
import * as ReactCheckboxPrimitive from '@radix-ui/react-checkbox';

import { cn } from '@/utils/tailwind';

interface InlineCheckboxCardGroupProps {
  options: Array<{
    label: string;
    value: string;
  }>;
  values: string[];
  onValueChange: (value: string[]) => void;
  className?: string;
  itemClassName?: string;
}

export function InlineCheckboxCardGroup({
  options,
  values,
  onValueChange,
  className,
  itemClassName
}: InlineCheckboxCardGroupProps) {
  return (
    <ReactCheckboxPrimitive.Root className={cn('flex gap-2', className)} defaultValue={values}>
      {options.map((option) => (
        <ReactCheckboxPrimitive.Checkbox
          key={option.value}
          value={option.value}
          checked={values.includes(option.value)}
          className={cn(
            'flex items-center justify-center rounded-lg border border-default bg-default p-1 font-medium hover:border-emphasis hover:bg-subtle hover:text-emphasis data-[state=checked]:border-emphasis data-[state=checked]:bg-subtle data-[state=checked]:text-emphasis',
            itemClassName
          )}
          onCheckedChange={(checked) => {
            if (checked) {
              onValueChange([...values, option.value]);
            } else {
              onValueChange(values.filter((value) => value !== option.value));
            }
          }}
        >
          <Label className="cursor-pointer">{option.label}</Label>
        </ReactCheckboxPrimitive.Checkbox>
      ))}
    </ReactCheckboxPrimitive.Root>
  );
}
