import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Dialog, Form, Input } from '@knack/asterisk-react';
import { z } from 'zod';

import { useCreateEmptyObjectMutation } from '@/hooks/api/mutations/useCreateEmptyObjectMutation';
import { useTableMutation } from '@/hooks/api/mutations/useTableMutation';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { useTrackEventsHelpers } from '@/hooks/helpers/useTrackEventsHelpers';
import { TABLE_NAME_MAX_CHAR_LENGTH } from '@/utils/constants';
import { generateUniqueStringInList, isStringUniqueInList } from '@/utils/uniqueStrings';
import { ApiErrorBanner } from '@/components/errors/ApiErrorBanner';
import { isImportingFromNewApp } from '@/pages/add-table/addTableHelper';
import { FIRST_STANDARD_OBJECT_KEY } from '@/pages/add-table/constants';

export function CreateBlankTable({ onBack, isNewApp }: { onBack: () => void; isNewApp: boolean }) {
  const { data: application } = useApplicationQuery();
  const { trackEvent } = useTrackEventsHelpers();
  const [t] = useTranslation();
  const createEmptyObject = useCreateEmptyObjectMutation();
  const { deleteMutation } = useTableMutation();

  const existingTableNames = application?.objects.map((o) => o.name) || [];

  type FormSchema = z.infer<typeof formSchema>;

  const formSchema = z.object({
    tableName: z
      .string()
      .min(1, t('components.dialogs.tables.settings.table_name_required'))
      .max(
        TABLE_NAME_MAX_CHAR_LENGTH,
        t('components.dialogs.tables.settings.table_name_max_length', {
          max: TABLE_NAME_MAX_CHAR_LENGTH
        })
      )
      .refine(
        (val) => isStringUniqueInList(val, existingTableNames),
        (val) => ({
          message: t('errors.table_name_unique', {
            fieldName: val
          })
        })
      )
      .refine(
        (val) => val !== 'Accounts',
        (val) => ({
          type: 'table_name_reserved',
          message: t('errors.accounts_is_a_reserved_name', {
            fieldName: val
          })
        })
      )
  });

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      tableName: generateUniqueStringInList(
        existingTableNames,
        t('components.add_table.default_table_name')
      )
    },
    mode: 'onChange'
  });

  const onSubmit = ({ tableName }: FormSchema) => {
    if (application && isNewApp && isImportingFromNewApp(application)) {
      deleteMutation.mutate(FIRST_STANDARD_OBJECT_KEY, {
        onSettled: () => {
          createEmptyObject.mutate({
            objectName: tableName
          });
        }
      });

      return;
    }

    createEmptyObject.mutate(
      {
        objectName: tableName
      },
      {
        onSuccess: () => {
          trackEvent('object_created', {
            object_type: 'data',
            mode: 'scratch'
          });
        }
      }
    );
  };

  return (
    <Dialog open onOpenChange={onBack}>
      <Dialog.Content data-testid="table-dropdown-menu-dialog-content">
        <Dialog.MainContent>
          <Dialog.Header className="mb-6">
            <Dialog.Title className="font-medium">
              {t('components.add_table.create_blank_table_title')}
            </Dialog.Title>
            <ApiErrorBanner
              translationPrefix="components.add_table.errors"
              error={createEmptyObject?.error}
              data-testid="error-banner"
              fallbackKey="errors.generic_error"
            />
          </Dialog.Header>
          <Form
            data-testid="create-table-form"
            onSubmit={handleSubmit(onSubmit)}
            className="space-y-6"
          >
            <Form.Section>
              <Form.Label htmlFor="new-table-name-input">
                {t('components.dialogs.tables.table_name')}
              </Form.Label>
              <Input.Container>
                <Input
                  data-testid="new-table-name-input"
                  id="new-table-name-input"
                  title={t('components.dialogs.tables.table_name')}
                  intent={errors.tableName && 'destructive'}
                  {...register('tableName', {
                    onChange: async () => {
                      if (createEmptyObject?.error) {
                        createEmptyObject.reset();
                      }
                    },
                    onBlur: (e: React.FocusEvent<HTMLInputElement>) => {
                      e.target.value = e.target.value.trim();
                    }
                  })}
                />
              </Input.Container>
              {errors.tableName && (
                <p className="mt-1 text-destructive" data-testid="new-table-name-error">
                  {errors.tableName.message}
                </p>
              )}
            </Form.Section>
            <div className="flex justify-end gap-2" data-testid="create-table-form-dialog-footer">
              <Button
                onClick={onBack}
                data-testid="create-table-form-button-cancel"
                intent="minimal"
              >
                {t('actions.cancel')}
              </Button>
              <Button
                data-testid="create-table-form-button-save"
                type="submit"
                isLoading={createEmptyObject?.isPending}
                disabled={!!errors.tableName}
              >
                {t('actions.save')}
              </Button>
            </div>
          </Form>
        </Dialog.MainContent>
      </Dialog.Content>
    </Dialog>
  );
}
