import {
  type KnackCriteriaDateTimeValue,
  type KnackCriteriaWithValueType
} from '@/types/schema/KnackCriteria';
import { getRuleTimeFormatted } from '@/utils/rules/getRuleTimeFormatted';
import i18n from '@/i18n';

export function getDateTimeConditionalRuleCriteriaValue(criteria: KnackCriteriaWithValueType) {
  const { operator, type, range } = criteria;
  const value = criteria.value as KnackCriteriaDateTimeValue;

  if (
    operator === 'is during the previous' ||
    operator === 'is during the next' ||
    operator === 'is before the previous' ||
    operator === 'is after the next'
  ) {
    if (!type || !range) return '';
    return i18n.t(`attributes.field_labels.date_time.number_${type.replace(' ', '_')}`, {
      number: range
    });
  }

  if (operator === 'is during the current') {
    if (!type) return '';
    return i18n.t(`attributes.field_labels.date_time.${type}`);
  }

  if (value.time !== undefined && !value.time) {
    return `${value.date}, ${i18n.t('attributes.field_labels.date_time.at_any_time')}` || '';
  }

  let timeString = '';

  if (value.time) {
    timeString = value.time;
  } else if (value.hours !== undefined && value.minutes !== undefined) {
    timeString = getRuleTimeFormatted(Number(value.hours), Number(value.minutes));
  }

  return `${value.date || ''} ${timeString}`.trim();
}
