import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HiPlus as PlusIcon, HiXMark as RemoveIcon } from 'react-icons/hi2';
import { Fragment } from 'react/jsx-runtime';
import { Button, Form, Select } from '@knack/asterisk-react';
import snakeCase from 'lodash.snakecase';

import {
  TABLE_VIEW_DISPLAY_RULE_ACTION_TYPES,
  type TableDisplayRuleActionType,
  type TableView,
  type TableViewDisplayRule
} from '@/types/schema/views/TableView';
import { cn } from '@/utils/tailwind';
import { FormErrorMessage } from '@/components/errors/FormErrorMessage';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { TableDisplayRuleFormActionPicker } from '@/pages/pages/settings-panel/view-settings/table/data-display/display-rules/TableDisplayRuleFormActionPicker';
import { useTableDisplayRulesHelpers } from '@/pages/pages/settings-panel/view-settings/table/data-display/display-rules/useTableDisplayRulesHelpers';

export function TableDisplayRuleFormActions() {
  const [t] = useTranslation();
  const { view, sourceObject } = useActiveViewContext<TableView>();
  const { getDefaultDisplayRuleAction } = useTableDisplayRulesHelpers({ view, sourceObject });
  const availableViewFields = sourceObject.fields;
  const {
    control,
    getValues,
    clearErrors: clearFormErrors,
    formState: { errors }
  } = useFormContext<TableViewDisplayRule>();
  const {
    fields: actionFormFields,
    append: appendAction,
    remove: removeAction,
    update: updateAction
  } = useFieldArray({
    name: 'actions',
    control
  });

  return (
    <Form.Section className="rounded-lg bg-subtle p-2">
      {actionFormFields.length === 0 && (
        <p className="text-subtle">{t('components.rules.no_actions')}</p>
      )}

      {actionFormFields.map((actionFormField, actionFormFieldIndex) => (
        <Fragment key={actionFormField.id}>
          {actionFormFieldIndex > 0 && (
            <p className="my-3 text-xs font-medium">{t('components.rules.and_uppercase')}</p>
          )}

          <div className="flex">
            <div
              className={cn('-m-1 flex flex-1 flex-wrap gap-2 overflow-hidden p-1', {
                'items-center': actionFormField.action !== 'icon'
              })}
            >
              <div className="flex-1">
                <Controller
                  name={`actions.${actionFormFieldIndex}.action`}
                  render={({ field: { value: selectedActionType } }) => (
                    <Select
                      value={selectedActionType}
                      onValueChange={(newActionType: TableDisplayRuleActionType) => {
                        updateAction(actionFormFieldIndex, {
                          ...getValues(`actions.${actionFormFieldIndex}`),
                          action: newActionType
                        });
                        clearFormErrors();
                      }}
                    >
                      <Select.Trigger
                        data-testid="display-rule-action-select"
                        placeholder={t('actions.select')}
                        className="w-full"
                      />
                      <Select.Content>
                        {TABLE_VIEW_DISPLAY_RULE_ACTION_TYPES.map(
                          (actionType: TableDisplayRuleActionType) => (
                            <Select.Item
                              data-testid={`display-rule-action-select-item-${actionType}`}
                              key={actionType}
                              value={actionType}
                            >
                              {actionType === 'hide'
                                ? t('actions.hide')
                                : t(
                                    `components.display_rule_card.actions.${snakeCase(actionType)}`
                                  )}
                            </Select.Item>
                          )
                        )}
                      </Select.Content>
                    </Select>
                  )}
                />
              </div>
              <div className="flex-1">
                <div className="flex items-center">
                  <div className="flex-1">
                    <TableDisplayRuleFormActionPicker
                      action={actionFormField.action}
                      formName={`actions.${actionFormFieldIndex}`}
                    />
                    <FormErrorMessage
                      name={`actions.${actionFormFieldIndex}.action`}
                      errors={errors}
                    />
                  </div>
                </div>
              </div>
            </div>

            {actionFormFields.length > 1 && (
              <Button
                intent="minimal"
                aria-label={t('components.rules.delete_action')}
                size="xs"
                className="ml-2 mt-1.5 text-subtle hover:bg-emphasis"
                onClick={() => removeAction(actionFormFieldIndex)}
              >
                <RemoveIcon size={16} />
              </Button>
            )}
          </div>
        </Fragment>
      ))}

      {availableViewFields.length > 0 && (
        <Button
          data-testid="display-rule-add-action"
          intent="secondary"
          aria-label={t('components.rules.add_action')}
          className="mt-3"
          onClick={() => {
            const defaultAction = getDefaultDisplayRuleAction();
            if (defaultAction) {
              appendAction(defaultAction);
            }
          }}
        >
          <Button.Icon icon={PlusIcon} position="left" />
          {t('components.rules.action')}
        </Button>
      )}
    </Form.Section>
  );
}
