import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
  type RouteObject
} from 'react-router-dom';

import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { useSessionHelpers } from '@/hooks/helpers/useSessionHelpers';
import { getApplicationBasePath, getBuilderBaseUrl } from '@/utils/application';
import { FEATURE_FLAGS, isFlagEnabled } from '@/utils/flagsmith';
import { isRudderStackEnabled, rudderStackAnalytics } from '@/utils/rudderstack';
import { EditRecordModal } from '@/components/data-table/display/edit-record/EditRecordModal';
import { ResetPasswordSubPage } from '@/components/data-table/display/user-roles/ResetPassword';
import { SendAccountInfoSubPage } from '@/components/data-table/display/user-roles/SendAccountInfoSubPage';
import { SendWelcomeSubPage } from '@/components/data-table/display/user-roles/SendWelcomeSubPage';
import { RouterErrorBoundary } from '@/components/errors/RouterErrorBoundary';
import { AddTablePage } from '@/pages/add-table/AddTablePage';
import { DataModelPage } from '@/pages/data-model/DataModelPage';
import { CalendarPlaygroundPage } from '@/pages/dev-playground/DateTime/CalendarPlaygroundPage';
import { FlowsList } from '@/pages/flows/flows-list/FlowsList';
import { FlowsPage } from '@/pages/flows/FlowsPage';
import { ImportRecordsPage } from '@/pages/import-records/ImportRecordsPage';
import { NotFoundPage } from '@/pages/not-found/NotFoundPage';
import { PageEditor } from '@/pages/pages/page-editor/PageEditor';
import { PagesPage } from '@/pages/pages/PagesPage';
import { CopyRoleDialog } from '@/pages/roles/copy-role/CopyRole';
import { DeleteRoleDialog } from '@/pages/roles/delete-role/DeleteRole';
import { RoleSettingsDialog } from '@/pages/roles/role-settings/RoleSettings';
import { RolesPage } from '@/pages/roles/RolesPage';
import { RolesPageContent } from '@/pages/roles/RolesPageContent';
import { ApiSettings } from '@/pages/settings/api-settings/ApiSettings';
import { CoreSettings } from '@/pages/settings/core-settings/CoreSettings';
import { CustomCodeSettings } from '@/pages/settings/custom-code-settings/CustomCodeSettings';
import { DataComplianceSettings } from '@/pages/settings/data-compliance-settings/DataCompliance';
import { MapProviderSettings } from '@/pages/settings/map-provider-settings/MapProviderSettings';
import { SecuritySettings } from '@/pages/settings/security-settings/SecuritySettings';
import { SettingsPage } from '@/pages/settings/SettingsPage';
import { SettingsPageCodeSection } from '@/pages/settings/SettingsPageCodeSection';
import { SettingsPageGeneralSection } from '@/pages/settings/SettingsPageGeneralSection';
import { SettingsPageUsersLoginSection } from '@/pages/settings/SettingsPageUsersLoginSection';
import { TablesPage } from '@/pages/tables/TablesPage';
import { TablesPageContent } from '@/pages/tables/TablesPageContent';
import { ThemeEditor } from '@/pages/themes/theme-editor/ThemeEditor';
import { ThemesList } from '@/pages/themes/themes-list/ThemesList';
import { ThemesPage } from '@/pages/themes/ThemesPage';
import { WelcomePage } from '@/pages/welcome/WelcomePage';

type FlagCondition = 'AND' | 'OR';

type ProtectedRouteProps = {
  children?: JSX.Element;
  flagProtection?: (keyof typeof FEATURE_FLAGS)[];
  flagCondition?: FlagCondition;
  allowSharedBuilder?: boolean;
};

export type PageUrlParams = {
  id: string;
};

export enum ROUTES {
  ROOT = '/',
  TABLES = '/tables',
  TABLES_ADD = '/tables/add',
  TABLES_ID = '/tables/:id',
  TABLES_ID_FIELDS = '/tables/:id/fields',
  TABLES_ID_ADD = '/tables/:id/add',
  TABLES_ID_IMPORT = '/tables/:id/import',
  TABLES_ID_RECORD_ID_EDIT = '/tables/:id/record/:recordId/edit',
  FLOWS = '/flows',
  DATA_MODEL = '/data-model',
  PAGES = '/pages',
  PAGES_ID = '/pages/:id',
  THEMES = '/themes',
  THEMES_ID = '/themes/:id',
  ROLES = '/roles',
  ROLES_ID = '/roles/:id',
  ROLES_ID_FIELDS = '/roles/:id/fields',
  ROLES_ID_RECORD_ID_EDIT = '/roles/:id/record/:recordId/edit',
  ROLES_ID_SETTINGS_SETTING_ID = '/roles/:id/settings/:selectedTableKey/:settingId',
  ROLES_ID_SEND_ACCOUNT_INFO = '/roles/:id/action/send-account-info/:rowId',
  ROLES_ID_RESET_PASSWORD = '/roles/:id/action/reset-password/:rowId',
  ROLES_ID_SEND_WELCOME_EMAIL = '/roles/:id/action/send-welcome-email/:rowId',
  ROLES_ID_COPY = '/roles/:id/copy',
  ROLES_ID_DELETE = '/roles/:id/delete',
  PAYMENTS = '/payments',
  SETTINGS = '/settings',
  SETTINGS_GENERAL = '/settings/general',
  SETTINGS_GENERAL_CORE = '/settings/general/core',
  SETTINGS_GENERAL_SECURITY = '/settings/general/security',
  SETTINGS_GENERAL_LOCALIZATION = '/settings/general/localization',
  SETTINGS_GENERAL_MAPS = '/settings/general/maps',
  SETTINGS_GENERALS_COMPLIANCE = '/settings/general/compliance',
  SETTINGS_USERS = '/settings/users',
  SETTINGS_CODE = '/settings/code',
  SETTINGS_CODE_API = '/settings/code/api',
  SETTINGS_CODE_CSS = '/settings/code/css',
  SETTINGS_CODE_JAVASCRIPT = '/settings/code/javascript',
  WELCOME = '/welcome',
  CATCH_ALL = '*'
}

function ProtectedRoute({
  children,
  flagProtection = [],
  flagCondition = 'OR',
  allowSharedBuilder = true
}: ProtectedRouteProps) {
  const { data: application } = useApplicationQuery();
  const { isSharedBuilder } = useSessionHelpers();

  if (!allowSharedBuilder && isSharedBuilder()) {
    return <Navigate to={ROUTES.ROOT} replace />;
  }

  let isFlagCheckPassed = false;

  // If the route has flag protection, check if the flags are enabled
  // If we only have one flag, we can just check if it's enabled
  if (flagProtection.length === 1) {
    isFlagCheckPassed = isFlagEnabled(flagProtection[0]);
    // If we have more than one flag, we need to check if all of them are enabled (AND) or any of them (OR)
  } else if (flagProtection.length > 1) {
    const flagsEnabled = flagProtection.map((flag) => isFlagEnabled(flag));
    isFlagCheckPassed =
      flagCondition === 'AND' ? flagsEnabled.every(Boolean) : flagsEnabled.some(Boolean);
  }

  // We give priority to the flags, so if the route has flag protection and any of the flags is enabled, we can continue
  if (flagProtection.length > 0 && isFlagCheckPassed) {
    return children || <Outlet />;
  }

  // Next, check if the user has access to non-public features
  const hasAccessToNonPublicFeatures =
    import.meta.env.DEV || !!application?.account.isInternalAccount;
  if (hasAccessToNonPublicFeatures) {
    return children || <Outlet />;
  }

  if (window.analytics || isRudderStackEnabled()) {
    void rudderStackAnalytics.page();
  }

  // Otherwise, redirect to the v3 builder
  window.location.replace(getBuilderBaseUrl());
  return null;
}

export const routeList: RouteObject[] = [
  {
    element: <RouterErrorBoundary />,
    children: [
      {
        path: ROUTES.ROOT,
        element: (
          <Navigate to={{ pathname: ROUTES.TABLES, search: `${window.location.search}` }} replace />
        )
      },
      {
        path: ROUTES.TABLES_ADD,
        element: <AddTablePage />
      },
      {
        path: ROUTES.TABLES_ID_IMPORT,
        element: <ImportRecordsPage />
      },
      {
        element: (
          <ProtectedRoute
            flagProtection={[FEATURE_FLAGS.albato_flows]}
            allowSharedBuilder={false}
          />
        ),
        children: [
          {
            path: ROUTES.FLOWS,
            element: <FlowsPage />,
            children: [
              {
                index: true,
                element: <FlowsList />
              }
            ]
          }
        ]
      },
      {
        element: (
          <ProtectedRoute
            flagProtection={[FEATURE_FLAGS.full_nextgen_access, FEATURE_FLAGS.only_nextgen_access]}
            flagCondition="OR"
          />
        ),
        children: [
          {
            path: ROUTES.PAGES,
            element: <PagesPage />,
            children: [
              {
                index: true,
                element: <PageEditor isIndex />
              },
              {
                path: ROUTES.PAGES_ID,
                element: <PageEditor />
              }
            ]
          }
        ]
      },
      {
        element: (
          <ProtectedRoute
            flagProtection={[FEATURE_FLAGS.full_nextgen_access, FEATURE_FLAGS.only_nextgen_access]}
            flagCondition="OR"
          />
        ),
        children: [
          {
            path: ROUTES.THEMES,
            element: <ThemesPage />,
            children: [
              {
                index: true,
                element: <ThemesList />
              },
              {
                path: ROUTES.THEMES_ID,
                element: <ThemeEditor />
              }
            ]
          }
        ]
      },
      {
        element: (
          <ProtectedRoute
            flagProtection={[FEATURE_FLAGS.full_nextgen_access, FEATURE_FLAGS.only_nextgen_access]}
            flagCondition="OR"
          />
        ),
        children: [
          {
            path: ROUTES.TABLES,
            element: <TablesPage />,
            children: [
              {
                index: true,
                element: <TablesPageContent isIndex />
              },
              {
                path: ROUTES.TABLES_ID,
                element: <TablesPageContent activeTab="records" />,
                children: [
                  {
                    path: ROUTES.TABLES_ID_RECORD_ID_EDIT,
                    element: <EditRecordModal />
                  }
                ]
              },
              {
                path: ROUTES.TABLES_ID_FIELDS,
                element: <TablesPageContent activeTab="fields" />
              }
            ]
          }
        ]
      },
      {
        element: (
          <ProtectedRoute
            flagProtection={[FEATURE_FLAGS.full_nextgen_access, FEATURE_FLAGS.only_nextgen_access]}
            flagCondition="OR"
          />
        ),
        children: [
          {
            path: ROUTES.ROLES,
            element: <RolesPage />,
            children: [
              {
                index: true,
                element: <RolesPageContent isIndex />
              },
              {
                path: ROUTES.ROLES_ID,
                element: <RolesPageContent activeTab="records" />,
                children: [
                  {
                    path: ROUTES.ROLES_ID_SETTINGS_SETTING_ID,
                    element: <RoleSettingsDialog />
                  },
                  {
                    path: ROUTES.ROLES_ID_COPY,
                    element: <CopyRoleDialog />
                  },
                  {
                    path: ROUTES.ROLES_ID_DELETE,
                    element: <DeleteRoleDialog />
                  },
                  {
                    path: ROUTES.ROLES_ID_RECORD_ID_EDIT,
                    element: <EditRecordModal />
                  },
                  {
                    path: ROUTES.ROLES_ID_SEND_ACCOUNT_INFO,
                    element: <SendAccountInfoSubPage />
                  },
                  {
                    path: ROUTES.ROLES_ID_RESET_PASSWORD,
                    element: <ResetPasswordSubPage />
                  },
                  {
                    path: ROUTES.ROLES_ID_SEND_WELCOME_EMAIL,
                    element: <SendWelcomeSubPage />
                  }
                ]
              },
              {
                path: ROUTES.ROLES_ID_FIELDS,
                element: <RolesPageContent activeTab="fields" />
              }
            ]
          }
        ]
      },
      {
        element: (
          <ProtectedRoute
            flagProtection={[FEATURE_FLAGS.full_nextgen_access, FEATURE_FLAGS.only_nextgen_access]}
            flagCondition="OR"
          >
            <SettingsPage />
          </ProtectedRoute>
        ),
        children: [
          {
            path: ROUTES.SETTINGS,
            element: <Navigate to={ROUTES.SETTINGS_GENERAL} replace />
          },
          {
            path: ROUTES.SETTINGS_GENERAL,
            element: <Navigate to={ROUTES.SETTINGS_GENERAL_CORE} replace />
          },
          {
            element: <SettingsPageGeneralSection />,
            children: [
              {
                path: ROUTES.SETTINGS_GENERAL_CORE,
                element: <CoreSettings />
              },
              {
                path: ROUTES.SETTINGS_GENERAL_SECURITY,
                element: <SecuritySettings />
              },
              {
                path: ROUTES.SETTINGS_GENERAL_LOCALIZATION,
                element: <>localization</>
              },
              {
                path: ROUTES.SETTINGS_GENERAL_MAPS,
                element: <MapProviderSettings />
              },
              {
                path: ROUTES.SETTINGS_GENERALS_COMPLIANCE,
                element: <DataComplianceSettings />
              }
            ]
          },
          {
            path: ROUTES.SETTINGS_USERS,
            element: <SettingsPageUsersLoginSection />
          },
          {
            path: ROUTES.SETTINGS_CODE,
            element: <Navigate to={ROUTES.SETTINGS_CODE_API} replace />
          },
          {
            element: <SettingsPageCodeSection />,
            children: [
              {
                path: ROUTES.SETTINGS_CODE_API,
                element: <ApiSettings />
              },
              {
                path: ROUTES.SETTINGS_CODE_CSS,
                element: <CustomCodeSettings type="css" key="css" />
              },
              {
                path: ROUTES.SETTINGS_CODE_JAVASCRIPT,
                element: <CustomCodeSettings type="javascript" key="javascript" />
              }
            ]
          }
        ]
      },
      {
        path: ROUTES.DATA_MODEL,
        element: <DataModelPage />
      },
      {
        path: ROUTES.WELCOME,
        element: <WelcomePage />
      },
      {
        path: ROUTES.CATCH_ALL,
        element: <NotFoundPage />
      },
      // Testing routes:
      ...(import.meta.env.DEV
        ? [
            {
              path: 'dev-playground/calendar',
              element: <CalendarPlaygroundPage />
            }
          ]
        : [])
    ]
  }
];

const router = createBrowserRouter(routeList, {
  basename: getApplicationBasePath()
});

export function Router() {
  return <RouterProvider router={router} />;
}
