import { isAxiosError, type AxiosError } from 'axios';

import i18n from '@/i18n';

type ServerError = {
  errorCode?: string;
  message: string;
};

type ServerErrorResponse = {
  errors: ServerError[];
  status: number;
};

export type ErrorResponseProps = {
  error: Error | AxiosError | unknown;
  fallbackKey: string;
  translationPrefix?: string;
  i18nOptions?: { [key: string]: string };
};

function sanitizeServerError(errorDetail: any): ServerError | undefined {
  const hasNecessaryTypes =
    (typeof errorDetail?.error === 'string' || typeof errorDetail?.message === 'string') &&
    ['undefined', 'string'].includes(typeof errorDetail.errorCode);

  if (!hasNecessaryTypes) {
    return undefined;
  }

  return {
    message: errorDetail.message ?? errorDetail.error,
    errorCode: errorDetail.errorCode
  };
}

export function sanitizeServerErrorResponse(error: any): ServerErrorResponse | undefined {
  if (isAxiosError(error)) {
    const errors = error.response?.data?.errors ?? [
      {
        message: error.response?.data?.message,
        error: error.response?.data?.error,
        errorCode: error.response?.data?.errorCode
      }
    ];

    return sanitizeServerErrorResponse({
      status: error.response?.status,
      errors
    });
  }

  // If coming from API calls that go through API wrapper, the error object is possibly already formatted
  // like a ServerErrorResponse (due to the error interceptor in that library) rather than an AxiosError
  if (typeof error?.status !== 'number') {
    return undefined;
  }
  const serverErrorResponse: ServerErrorResponse = {
    status: error.status,
    errors: []
  };

  const errors = Array.isArray(error.errors) ? error.errors : [error.errors];

  serverErrorResponse.errors.push(
    ...errors.map(sanitizeServerError).filter((errorDetail) => !!errorDetail)
  );

  return serverErrorResponse;
}

export function getErrorMessages({
  error,
  fallbackKey,
  translationPrefix,
  i18nOptions
}: ErrorResponseProps) {
  const translatedFallbackErrorMessage = [i18n.t(fallbackKey, i18nOptions)];

  const serverErrorResponse = sanitizeServerErrorResponse(error);

  const errorMessages = serverErrorResponse?.errors;
  const errorCode = serverErrorResponse?.status;

  if ((errorCode === 500 && errorMessages?.length === 0) || !errorMessages) {
    return translatedFallbackErrorMessage;
  }

  const translatedErrorMessages = errorMessages?.map((err) =>
    translationPrefix ? i18n.t(`${translationPrefix}.${err.errorCode}`, i18nOptions) : err.message
  );

  return translatedErrorMessages || translatedFallbackErrorMessage;
}
