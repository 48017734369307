import { useId, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Checkbox, Label } from '@knack/asterisk-react';
import { type DateTimePickerProps } from '@knack/asterisk-react/dist/src/components/date-time-picker/DateTimePicker';
import * as z from 'zod';

import { useFieldNameConcatenate } from '@/utils/forms/useFieldNameConcatenate';
import { EndsOn, getKnackDateEndsSchema } from '@/components/knack-date-picker/components/EndsOn';
import {
  FrequencySelector,
  knackDateFrequencySchema
} from '@/components/knack-date-picker/components/FrequencySelector';
import { RepeatsEvery } from '@/components/knack-date-picker/components/RepeatsEvery';
import { type KnackDatePickerSchema } from '@/components/knack-date-picker/KnackDatePickerSchema';

export const getKnackDateRepeatSchema = (t: (key: string) => string) =>
  z.object({
    repeat: knackDateFrequencySchema.and(getKnackDateEndsSchema(t)).optional(),
    all_day: z.boolean()
  });

export type KnackDateRepeatSchema = z.infer<ReturnType<typeof getKnackDateRepeatSchema>>;

export const initialRepeatValue = {
  frequency: 'daily',
  interval: 1,
  endson: 'never'
} satisfies KnackDateRepeatSchema['repeat'];

export function KnackDatePickerRepeatOptions(
  props: Pick<DateTimePickerProps, 'format' | 'mode'> & {
    formPath?: string;
  }
) {
  const { formPath, format, mode } = props;

  const getFieldKey = useFieldNameConcatenate<KnackDateRepeatSchema>(formPath);

  const id = useId();
  const [t] = useTranslation();
  const [showCustomRepeat, setShowCustomRepeat] = useState(false);

  const {
    control,
    formState: { errors }
  } = useFormContext<KnackDateRepeatSchema>();

  if (mode === 'single') return null;

  return (
    <div className="mt-2">
      {format.date && format.time && (
        <Controller
          name={getFieldKey('all_day')}
          control={control}
          render={({ field: { value, onChange } }) => (
            <Label className="mb-2 flex items-center" htmlFor={`${id}all-day-toggle`}>
              <Checkbox
                id={`${id}all-day-toggle`}
                className="mr-2"
                checked={value as boolean}
                onCheckedChange={onChange}
              />
              <span>{t('date_picker.all_day')}</span>
            </Label>
          )}
        />
      )}

      {format.date && (
        <Controller
          name={getFieldKey('repeat')}
          control={control}
          render={({ field: { value: repeatValue, onChange } }) => (
            <div className="flex flex-col gap-2">
              <FrequencySelector
                repeatValue={repeatValue as KnackDatePickerSchema['repeat']}
                onChange={onChange}
                showCustomRepeat={showCustomRepeat}
                setShowCustomRepeat={setShowCustomRepeat}
              />
              {showCustomRepeat && repeatValue && (
                <RepeatsEvery
                  formPath={formPath}
                  repeatValue={repeatValue as KnackDatePickerSchema['repeat']}
                  onChange={onChange}
                  errors={errors}
                />
              )}
              {repeatValue && (
                <EndsOn
                  formPath={formPath}
                  repeatValue={repeatValue}
                  onChange={onChange}
                  errors={errors}
                  format={format}
                />
              )}
            </div>
          )}
        />
      )}
    </div>
  );
}
