import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  amberTokens,
  blueTokens,
  cyanTokens,
  defaultTokens,
  Divider,
  fuchsiaTokens,
  greenTokens,
  Label,
  limeTokens,
  pinkTokens,
  presetNames,
  RadioGroup,
  Select,
  Switch,
  violetTokens,
  type ThemeMode
} from '@knack/asterisk-react';

import { useSession } from '@/hooks/useSession';
import { Section } from '@/pages/themes/theme-editor/Section';
import { Appearance } from '@/pages/themes/theme-editor/styles/Appearance';
import {
  darkBackgroundColorPresets,
  lightBackgroundColorPresets
} from '@/pages/themes/theme-editor/styles/backgroundColorPresets';
import {
  ColorPresetStrip,
  ColorSampleStrip
} from '@/pages/themes/theme-editor/styles/ColorSampleStrip';
import { CustomColors } from '@/pages/themes/theme-editor/styles/CustomColors';
import { Font } from '@/pages/themes/theme-editor/styles/Font';
import { TableDesign } from '@/pages/themes/theme-editor/styles/TableDesign';
import { useThemesPageContext } from '@/pages/themes/ThemesPageContext';

export function Styles() {
  const [t] = useTranslation();
  const { theme, setThemeField } = useThemesPageContext();
  const [tempPreset, setTempPreset] = useState<string>(theme.colors.preset);
  const { user } = useSession();
  const isKnackInternalAccount = user.email.includes('knack');
  const themeModeOptions = [
    {
      i18nKey: 'system',
      value: 'system'
    },
    {
      i18nKey: 'light_mode_only',
      value: 'light'
    },
    {
      i18nKey: 'dark_mode_only',
      value: 'dark'
    }
  ];
  const filteredPresetNames = isKnackInternalAccount ? presetNames : presetNames.slice(1);

  const getSelectedPresetTokens = () => {
    switch (theme.colors.preset) {
      case 'default':
        return defaultTokens;
      case 'amber':
        return amberTokens;
      case 'blue':
        return blueTokens;
      case 'cyan':
        return cyanTokens;
      case 'fuchsia':
        return fuchsiaTokens;
      case 'green':
        return greenTokens;
      case 'lime':
        return limeTokens;
      case 'pink':
        return pinkTokens;
      case 'violet':
        return violetTokens;
      default:
        return defaultTokens;
    }
  };

  return (
    <>
      <Section sectionNameKey="font">
        <Font />
      </Section>
      <Divider className="my-4" />
      <Section sectionNameKey="table" isDefaultOpen={false}>
        <TableDesign />
      </Section>
      <Divider className="my-4" />
      <Section sectionNameKey="appearance" isDefaultOpen={false}>
        <Appearance />
      </Section>
      <Divider className="my-4" />
      <Section sectionNameKey="colors">
        <Select
          value={theme.mode}
          onValueChange={(value) => {
            setThemeField((draft) => {
              draft.mode = value as ThemeMode;
            });
            setTimeout(() => {
              setThemeField((draft) => {
                draft.shouldAllowModeToggle = value === 'system';
              });
            }, 0);
          }}
        >
          <Select.Trigger placeholder={t('keywords.default')} className="w-full rounded-lg" />
          <Select.Content>
            {themeModeOptions.map((option) => (
              <Select.Item
                key={option.value}
                value={option.value}
                data-testid={`theme-mode-option-${option.value}`}
              >
                {t(`themes.${option.i18nKey}`)}
              </Select.Item>
            ))}
          </Select.Content>
        </Select>
        <div className="mb-4 mt-2 text-xs">{t('themes.system_mode')}</div>
        <div className="flex items-center gap-2">
          <Switch
            id="show-appearance-mode-toggle"
            checked={theme.shouldAllowModeToggle}
            disabled={theme.mode !== 'system'}
            onCheckedChange={(state) =>
              setThemeField((draft) => {
                draft.shouldAllowModeToggle = state;
              })
            }
          />
          <Label htmlFor="show-appearance-mode-toggle">{t('themes.allow_mode_toggle')}</Label>
        </div>
        <div className="mb-2 mt-6">{t('themes.color_options')}</div>
        <RadioGroup
          onValueChange={(value) => {
            setThemeField((draft) => {
              draft.colors.preset = value === 'preset' ? tempPreset : 'custom';
              setTempPreset(theme.colors.preset);
              draft.colors.customTokens =
                value === 'custom' ? draft.colors.customTokens || getSelectedPresetTokens() : null;
            });
          }}
          value={theme.colors.preset === 'custom' ? 'custom' : 'preset'}
          defaultValue="preset"
        >
          <RadioGroup.Container>
            <RadioGroup.Item value="preset" id="preset-radio" />
            <Label htmlFor="preset-radio">{t('themes.preset_colors')}</Label>
          </RadioGroup.Container>
          <RadioGroup.Container>
            <RadioGroup.Item value="custom" id="custom-radio" />
            <Label htmlFor="custom-radio">{t('themes.customize_all_colors')}</Label>
          </RadioGroup.Container>
        </RadioGroup>
        {theme.colors.preset !== 'custom' && (
          <>
            <div className="mt-6">{t('themes.primary_color')}</div>
            <ColorPresetStrip
              presetNames={filteredPresetNames}
              onClick={(preset) =>
                setThemeField((draft) => {
                  draft.colors.preset = preset.name;
                })
              }
              activeIndex={filteredPresetNames.findIndex(
                (preset) => preset.name === theme.colors.preset
              )}
            />
            {(theme.mode === 'system' || theme.mode === 'light') && (
              <>
                <div className="mt-6">{t('themes.light_mode_background_color')}</div>
                <ColorSampleStrip
                  presetColors={lightBackgroundColorPresets as readonly string[]}
                  onClick={(background) =>
                    setThemeField((draft) => {
                      draft.background.light = background as string;
                    })
                  }
                  activeBackground={theme.background.light}
                />
              </>
            )}
            {(theme.mode === 'system' || theme.mode === 'dark') && (
              <>
                <div className="mt-6">{t('themes.dark_mode_background_color')}</div>
                <ColorSampleStrip
                  presetColors={darkBackgroundColorPresets as readonly string[]}
                  onClick={(background) =>
                    setThemeField((draft) => {
                      draft.background.dark = background as string;
                    })
                  }
                  activeBackground={theme.background.dark}
                  isInvertedCheckmark
                />
              </>
            )}
          </>
        )}
        {theme.colors.preset === 'custom' && <CustomColors />}
      </Section>
    </>
  );
}
