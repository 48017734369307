import { type BaseKnackField } from '@/types/schema/KnackField';

export const NAME_FIELD_FORMATS = [
  'First Last',
  'First Middle Last',
  'Last, First',
  'Last, First Middle',
  'Title First Last'
] as const;

export type NameFieldFormat = (typeof NAME_FIELD_FORMATS)[number];

export interface NameField extends BaseKnackField {
  type: 'name';
  format?: {
    format: NameFieldFormat;
  };
}
