import { useTranslation } from 'react-i18next';
import { HiExternalLink as ExternalIcon } from 'react-icons/hi';
import { HiExclamationCircle as InfoIcon } from 'react-icons/hi2';
import { Button, Dialog } from '@knack/asterisk-react';
import formatHelper from '@knack/format-helper';

import { useAccountQuery } from '@/hooks/api/queries/useAccountQuery';
import { useApiUsageQuery } from '@/hooks/api/queries/useApiUsageQuery';
import { useApplication } from '@/hooks/useApplication';

type ApiUsageDialogProps = {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
};

export function ApiUsageDialog({ isOpen, onOpenChange }: ApiUsageDialogProps) {
  const [t] = useTranslation();
  const application = useApplication();
  const { data: account } = useAccountQuery();
  const { data, isPending } = useApiUsageQuery();

  const formatNumber = (n: number | string) =>
    formatHelper.formatNumber(n, {
      mark_thousands: 'comma'
    });

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <Dialog.Content data-testid="api-usage-modal" aria-describedby={undefined}>
        <Dialog.MainContent>
          <Dialog.Header>
            <Dialog.Title>{t('app_settings.api.usage.title')}</Dialog.Title>
          </Dialog.Header>
          <div className="mt-6 space-y-6">
            <div className="space-y-2 text-sm">
              <p className="font-medium">{t('app_settings.api.usage.plan')}</p>
              <p className="text-subtle">{application?.account.productPlan.name}</p>
            </div>
            <div className="space-y-2 text-sm">
              <p className="font-medium">{t('app_settings.api.usage.limit')}</p>
              <p className="text-subtle">{formatNumber(account?.totalDailyApiLimit ?? 0)}</p>
              <div className="flex items-center gap-2 rounded-lg bg-muted p-3">
                <InfoIcon size={16} className="text-subtle" />
                <p>{t('app_settings.api.usage.limit_reset')}</p>
              </div>
            </div>
            {isPending ? (
              <p className="text-sm">{t('keywords.loading')}</p>
            ) : (
              <div className="max-h-[25vh] w-full overflow-y-auto rounded border border-subtle">
                <div className="flex border-b border-subtle bg-muted text-sm font-medium">
                  <div className="flex-1 p-2">{t('app_settings.api.usage.date')}</div>
                  <div className="flex-1 p-2">{t('app_settings.api.usage.count')}</div>
                </div>
                <div>
                  {data?.results.map((row) => (
                    <div key={row.date} className="flex border-subtle text-sm">
                      <div className="flex-1 p-2">{row.date}</div>
                      <div className="flex-1 p-2">{formatNumber(row.count)}</div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </Dialog.MainContent>
        <div className="flex justify-between p-6 pt-0">
          <Button intent="minimalStandalone" asChild>
            <a href="https://docs.knack.com/docs/api-limits" target="_blank" rel="noreferrer">
              {t('app_settings.api.usage.learn')}
              <Button.Icon icon={ExternalIcon} position="right" />
            </a>
          </Button>
          <Dialog.Close asChild>
            <Button intent="minimal">{t('actions.close')}</Button>
          </Dialog.Close>
        </div>
      </Dialog.Content>
    </Dialog>
  );
}
