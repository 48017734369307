import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Select } from '@knack/asterisk-react';

import { type DataTableFiltersFormData } from '@/components/data-table/display/header/filters/Filters';

export function MatchSelector({ ruleIndex }: { ruleIndex: number }) {
  const [t] = useTranslation();
  const { control } = useFormContext<DataTableFiltersFormData>();

  const isFirstRule = ruleIndex === 0;

  return (
    <div className="min-w-20 text-right">
      {isFirstRule ? (
        <p className="mt-2.5">{t('components.data_table.filtering.when')}</p>
      ) : (
        <Controller
          control={control}
          name="match"
          render={({ field: { value, onChange } }) => {
            if (ruleIndex > 1) {
              return <p className="mt-2.5">{t(`components.rules.${value}_uppercase`)}</p>;
            }

            return (
              <Select value={value} onValueChange={onChange}>
                <Select.Trigger
                  placeholder={t('components.data_table.filtering.select')}
                  className="w-full"
                  data-testid="match-select"
                />
                <Select.Content className="min-w-24">
                  <Select.Item value="and">{t('components.rules.and_uppercase')}</Select.Item>
                  <Select.Item value="or">{t('components.rules.or_uppercase')}</Select.Item>
                </Select.Content>
              </Select>
            );
          }}
        />
      )}
    </div>
  );
}
