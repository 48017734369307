import { type DateTimePickerProps } from '@knack/asterisk-react/dist/src/components/date-time-picker/DateTimePicker';

import {
  type DateFormatValue,
  type DateTimeFieldFormat,
  type TimeFormatValue
} from '@/types/schema/fields/DateTimeField';

export const DEFAULT_LUXON_DATE = 'MM/dd/yyyy';
export const DEFAULT_LUXON_TIME = 'HH:mm';

const dateFormatToLuxonFormatMap: Record<DateFormatValue, DateTimePickerProps['format']['date']> = {
  'dd/mm/yyyy': 'dd/MM/yyyy',
  'mm/dd/yyyy': 'MM/dd/yyyy',
  'M D, yyyy': 'MM/dd/yyyy',
  'Ignore Date': undefined
} as const;

const knackTimeFormatToDatePickerMap: Record<
  TimeFormatValue,
  DateTimePickerProps['format']['time']
> = {
  'HH:MM am': 'hh:mm a',
  'HH MM (military)': 'HH:mm',
  'Ignore Time': undefined
} as const;

export const getLuxonDateFormatFromFieldFormat = (format: DateFormatValue | undefined) =>
  dateFormatToLuxonFormatMap[format || 'Ignore Date'];

export const getLuxonTimeFormatFromFieldFormat = (format: TimeFormatValue | undefined) =>
  knackTimeFormatToDatePickerMap[format || 'Ignore Time'];

export const getKnackDatePickerFormatFromField = (
  format: DateTimeFieldFormat
): DateTimePickerProps['format'] => ({
  date: getLuxonDateFormatFromFieldFormat(format.date_format),
  time: getLuxonTimeFormatFromFieldFormat(format.time_format)
});
