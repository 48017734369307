import { Collapsible } from '@knack/asterisk-react';

import { useLeftPanelContext } from '@/pages/pages/LeftPanelContext';
import { AddNewPageButton } from '@/pages/pages/page-tree/AddNewPageButton';
import { CollapsibleSectionHeader } from '@/pages/pages/page-tree/CollapsibleSectionHeader';
import { DragAndDropContext } from '@/pages/pages/page-tree/DragAndDropContext';
import { PageTreeItem } from '@/pages/pages/page-tree/PageTreeItem';

export function CollapsibleSection({
  sectionName,
  isUserPages,
  testId
}: {
  sectionName: string;
  isUserPages?: boolean;
  testId: string;
}) {
  const { hasAppSingleLoginPage, appSingleLoginPage, isSearching, search } = useLeftPanelContext();

  return (
    <Collapsible defaultOpen data-testid={testId}>
      <CollapsibleSectionHeader sectionName={sectionName} isUserPages={isUserPages} />
      <Collapsible.Content className="collapsible-animation rounded-lg pt-2">
        {/* Render the single login page (which should be the only page in the app without a parent) */}
        {!isUserPages &&
          hasAppSingleLoginPage &&
          appSingleLoginPage &&
          (!isSearching ||
            (isSearching &&
              appSingleLoginPage.name.toLowerCase().includes(search.toLowerCase()))) && (
            <PageTreeItem
              key={appSingleLoginPage.key}
              page={appSingleLoginPage}
              shouldSkipChildren
            />
          )}
        <DragAndDropContext isUserPages={isUserPages} />
        <AddNewPageButton isUserPage={isUserPages} />
      </Collapsible.Content>
    </Collapsible>
  );
}
