import { useCallback } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HiQuestionMarkCircle as QuestionMarkIcon } from 'react-icons/hi';
import { Label } from '@knack/asterisk-react';
import { EquationHelper } from '@knack/equation-helper';
import { DateTime } from 'luxon';
import { z } from 'zod';

import { type ConcatenationField } from '@/types/schema/fields';
import { type KnackObject } from '@/types/schema/KnackObject';
import { replaceTemplateVariables } from '@/components/field-settings/utils/replaceTemplateVariables';
import { RichTextCodeEditor } from '@/components/field-settings/utils/rich-text/RichTextCodeEditor';
import { useTemplateKeyName } from '@/components/field-settings/utils/rich-text/useTemplateKeyName';
import { TextTooltip } from '@/components/TextTooltip';

type ConcatenationFormSettingsProps = {
  field: ConcatenationField;
  objectKey: KnackObject['key'];
};

export const concatenationSchema = z.object({
  format: z.object({
    count_field: z.string(),
    equation_type: z.string(),
    equation: z.string(),
    formula_field: z.string()
  })
});

export type ConcatenationSchemaType = z.infer<typeof concatenationSchema>;

export function ConcatenationFormSettings({ field, objectKey }: ConcatenationFormSettingsProps) {
  const [t] = useTranslation();

  const { templateKeyVariables, exampleValues } = useTemplateKeyName(objectKey);

  const getRealTimeEquation = useCallback(
    (value: string) => {
      try {
        return EquationHelper.evalStringEquation(
          replaceTemplateVariables({
            text: value,
            variableList: templateKeyVariables,
            valueList: exampleValues
          }),
          true,
          false,
          DateTime.local().toFormat('ZZZZ')
        );
      } catch (error) {
        type EquationHelperError = {
          message: string;
        };

        const errorMessage = error as EquationHelperError;
        return errorMessage.message;
      }
    },
    [templateKeyVariables, exampleValues]
  );

  return (
    <Controller<ConcatenationSchemaType, 'format'>
      name="format"
      defaultValue={
        field?.format || {
          count_field: 'Connection',
          equation_type: 'numeric',
          equation: '',
          formula_field: 'Field'
        }
      }
      render={({ field: currentField }) => (
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <RichTextCodeEditor
              objectKey={objectKey}
              field={field}
              functionTypes="strings"
              content={currentField.value.equation}
              onChange={(value) => {
                currentField.onChange({
                  ...currentField.value,
                  equation: value
                });
              }}
            />
            <Label className="flex font-medium">
              {t('components.data_table.attributes.field_settings.equation.preview_output')}

              <TextTooltip
                label={t(
                  'components.data_table.attributes.field_settings.equation.preview_tooltip'
                )}
                asChild
              >
                <div>
                  <QuestionMarkIcon />
                </div>
              </TextTooltip>
            </Label>
            {getRealTimeEquation(currentField.value.equation)}
          </div>
        </div>
      )}
    />
  );
}
