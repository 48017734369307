import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { HiExternalLink as ExternalIcon } from 'react-icons/hi';
import { Button } from '@knack/asterisk-react';

import { useSessionQuery } from '@/hooks/api/queries/useSessionQuery';
import { safeLogRocketTrack } from '@/utils/logrocket';
import { PageError } from '@/components/errors/PageError';

export function AppInMaintenance() {
  const [t, { language }] = useTranslation();

  const { data: session } = useSessionQuery();

  const handleDashboardLinkClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    safeLogRocketTrack('NavigateToDashboard', session?.account?.settings?.hipaa?.enabled);

    // Redirect to the dashboard
    window.location.href = import.meta.env.PUBLIC_DASHBOARD_URL;
  };

  return (
    <>
      <PageError
        title={t('components.app_in_maintenance.title')}
        message={t('components.app_in_maintenance.message')}
      >
        <div className="flex gap-4">
          <Button intent="secondary" data-testid="app-in-maintenance-system-status-button">
            <a href="https://status.knack.com/" target="_blank" rel="noreferrer">
              {t('components.app_in_maintenance.system_status')}
            </a>
            <ExternalIcon size="16" className="ml-1" data-testid="empty-state-external-icon" />
          </Button>
          <Button asChild data-testid="app-in-maintenance-go-to-dashboard-button">
            <a href={import.meta.env.PUBLIC_DASHBOARD_URL} onClick={handleDashboardLinkClick}>
              {t('navigation.go_to_dashboard')}
            </a>
          </Button>
        </div>
      </PageError>
      <Helmet>
        <title lang={language}>{t('components.app_in_maintenance.title')}</title>
      </Helmet>
    </>
  );
}
