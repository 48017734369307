import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiPlus as AddIcon, HiPencil as EditIcon } from 'react-icons/hi2';
import { Button, Dialog } from '@knack/asterisk-react';
import size from 'lodash/size';

import { type BuilderViewFilterConnectionSources } from '@/types/schema/BuilderView';
import { type ConnectionField } from '@/types/schema/fields';
import { type KnackFieldKey } from '@/types/schema/KnackField';
import { RestrictedConnectionDialogGroup } from '@/pages/pages/settings-panel/view-settings/common/filtering/restricted-connections/RestrictedConnectionDialogGroup';
import {
  type FilterConnectionSourceGroup,
  type RestrictedConnectionFormChangeData
} from '@/pages/pages/settings-panel/view-settings/common/filtering/restricted-connections/types';
import { useRestrictedConnectionHelpers } from '@/pages/pages/settings-panel/view-settings/common/filtering/restricted-connections/useRestrictedConnectionHelpers';
import { type UpdateViewSchemaOptions } from '@/pages/pages/settings-panel/view-settings/common/filtering/ViewSettingsFiltering';

interface RestrictedConnectionDialogContentProps {
  filterConnectionSources?: BuilderViewFilterConnectionSources;
  restrictedConnectionFields: ConnectionField[];
  onFormSubmit: (data: BuilderViewFilterConnectionSources | null) => void;
}

function RestrictedConnectionsDialogContent({
  filterConnectionSources,
  restrictedConnectionFields,
  onFormSubmit
}: RestrictedConnectionDialogContentProps) {
  const [t] = useTranslation();
  const { getDefaultFilterConnectionSources } = useRestrictedConnectionHelpers();
  const selectedFilterConnectionSources =
    filterConnectionSources ?? getDefaultFilterConnectionSources(restrictedConnectionFields);

  const [filterConnectionSourcesLocalState, setFilterConnectionSourcesLocalState] = useState(
    selectedFilterConnectionSources
  );

  const filterConnectionSourceGroups: FilterConnectionSourceGroup[] = useMemo(
    () =>
      Object.keys(filterConnectionSourcesLocalState).map((key) => ({
        key: key as KnackFieldKey,
        filters: filterConnectionSourcesLocalState[key].filters,
        source: filterConnectionSourcesLocalState[key].source
      })),
    [filterConnectionSourcesLocalState]
  );

  const onRestrictedConnectionChange = (
    restrictedConnectionFormData: RestrictedConnectionFormChangeData,
    fieldKey: KnackFieldKey
  ) => {
    const { criteria, source } = restrictedConnectionFormData;

    setFilterConnectionSourcesLocalState((prevState) => ({
      ...prevState,
      [fieldKey]: {
        ...prevState[fieldKey],
        filters: criteria || prevState[fieldKey].filters,
        source: source || {
          connection_key: ''
        }
      }
    }));
  };

  const onApply = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onFormSubmit(filterConnectionSourcesLocalState);
  };

  return (
    <form onSubmit={onApply} className="w-full">
      <Dialog.MainContent>
        <Dialog.Header>
          <Dialog.Title>
            {t(
              'pages.element_settings.common.categories.data_display.filtering_section.restricted_connections.dialog_title'
            )}
          </Dialog.Title>
          <Dialog.Description className="text-xs text-subtle">
            {t(
              'pages.element_settings.common.categories.data_display.filtering_section.restricted_connections.description'
            )}
          </Dialog.Description>
        </Dialog.Header>
        {filterConnectionSourceGroups.map((group) => (
          <RestrictedConnectionDialogGroup
            key={group.key}
            filterConnectionSourceGroup={group}
            onRestrictedConnectionChange={onRestrictedConnectionChange}
          />
        ))}
      </Dialog.MainContent>
      <Dialog.Footer className="items-center justify-between">
        <Button intent="minimal" className="text-destructive" onClick={() => onFormSubmit(null)}>
          {t(
            'pages.element_settings.common.categories.data_display.filtering_section.restricted_connections.clear'
          )}
        </Button>
        <div className="flex gap-4">
          <Dialog.Close asChild>
            <Button intent="minimal">{t('actions.cancel')}</Button>
          </Dialog.Close>
          <Button type="submit">{t('actions.apply')}</Button>
        </div>
      </Dialog.Footer>
    </form>
  );
}

export function ViewSettingsRestrictedConnectionsDialog({
  filterConnectionSources,
  restrictedConnectionFields,
  canAddRestrictedConnection,
  updateViewSchema
}: {
  filterConnectionSources?: BuilderViewFilterConnectionSources;
  restrictedConnectionFields: ConnectionField[];
  canAddRestrictedConnection: boolean;
  updateViewSchema: (options: Partial<UpdateViewSchemaOptions>) => void;
}) {
  const [t] = useTranslation();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const isFilterConnectionSourceEmpty = size(filterConnectionSources) === 0;

  const onFormSubmit = (data: BuilderViewFilterConnectionSources | null) => {
    updateViewSchema({
      filter_connection_sources: data
    });
    setIsDialogOpen(false);
  };

  return (
    <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <Dialog.Trigger asChild>
        <Button
          data-testid="view-settings-restricted-connections-dialog-trigger"
          intent="secondary"
          disabled={!canAddRestrictedConnection}
        >
          {isFilterConnectionSourceEmpty ? (
            <AddIcon size={16} className="mr-1" />
          ) : (
            <EditIcon size={16} className="mr-1" />
          )}
          {t(
            'pages.element_settings.common.categories.data_display.filtering_section.restricted_connections.title'
          )}
        </Button>
      </Dialog.Trigger>

      <Dialog.Content>
        <RestrictedConnectionsDialogContent
          filterConnectionSources={filterConnectionSources}
          restrictedConnectionFields={restrictedConnectionFields}
          onFormSubmit={onFormSubmit}
        />
      </Dialog.Content>
    </Dialog>
  );
}
