import { useTranslation } from 'react-i18next';
import { Select } from '@knack/asterisk-react';

import { type ConnectionField } from '@/types/schema/fields';
import {
  type FormViewConnectionInput,
  type FormViewConnectionInputSource
} from '@/types/schema/views/form/Connection';
import {
  getConnectionParentOptionPartsFromSelectableValue,
  useConnectionParentOptions,
  type SelectableConnectionParentOption
} from '@/pages/pages/settings-panel/view-settings/helpers/useConnectionParentOptions';

interface ConnectionParentOptionsSelectProps {
  connectionInputToUpdate: FormViewConnectionInput;
  setConnectionInputToUpdate: (input: FormViewConnectionInput) => void;
  connectionField: ConnectionField;
}

export function ConnectionParentOptionsSelect({
  connectionInputToUpdate,
  setConnectionInputToUpdate,
  connectionField
}: ConnectionParentOptionsSelectProps) {
  const [t] = useTranslation();
  const connectionParentOptions = useConnectionParentOptions({ field: connectionField });

  // If there is only one option, it means it's the default option ('All records'), so there are not connection parents to select
  if (connectionParentOptions.length === 1) {
    return null;
  }

  const getSelectedValue = (): SelectableConnectionParentOption['value'] => {
    if (connectionInputToUpdate.source) {
      // If the input depends on another connection input in the form, the generated value will be based on that connection
      if (connectionInputToUpdate.source.connections?.length) {
        const sourceConnection = connectionInputToUpdate.source.connections[0];
        return `${sourceConnection.field.key}-${sourceConnection.source.field.key}-${sourceConnection.source.type}`;
      }

      const {
        connection_key: connectionKey,
        remote_key: remoteKey,
        type
      } = connectionInputToUpdate.source;

      if (connectionKey && remoteKey && type) {
        return `${connectionKey}-${remoteKey}-${type}`;
      }

      return 'all';
    }

    return 'all';
  };

  const handleValueChange = (value: SelectableConnectionParentOption['value']) => {
    // If the selected value is 'all', we just keep the source filters and remove any source connections
    if (value === 'all') {
      setConnectionInputToUpdate({
        ...connectionInputToUpdate,
        source: {
          filters: connectionInputToUpdate.source?.filters || []
        }
      });
      return;
    }

    // Otherwise, the `value` will be a string with the format `connectionKey-remoteKey-type`, so we need to get those parts in their respective types
    const connectionParentOptionParts = getConnectionParentOptionPartsFromSelectableValue(value);

    if (!connectionParentOptionParts) {
      return;
    }

    const { connectionFieldKey, remoteFieldKey, type } = connectionParentOptionParts;

    // If the source of the connection is an input field, it means that the value of this input depends on the value of a parent input in the form
    if (type === 'input') {
      setConnectionInputToUpdate({
        ...connectionInputToUpdate,
        source: {
          filters: connectionInputToUpdate.source?.filters || [],
          connections: [
            {
              field: {
                key: connectionFieldKey
              },
              source: {
                type,
                field: {
                  key: remoteFieldKey
                }
              }
            }
          ]
        }
      });
      return;
    }

    setConnectionInputToUpdate({
      ...connectionInputToUpdate,
      source: {
        connection_key: connectionFieldKey,
        remote_key: remoteFieldKey,
        type,
        filters: connectionInputToUpdate.source?.filters || []
      } as FormViewConnectionInputSource
    });
  };

  return (
    <div className="mb-4">
      <span className="mb-2 block font-medium">
        {t(
          'pages.element_settings.form.categories.form_fields.field_management.edit_field_modal.field_records_to_include'
        )}
      </span>
      <Select value={getSelectedValue()} onValueChange={handleValueChange}>
        <Select.Trigger placeholder={t('actions.select')} className="w-full" />
        <Select.Content>
          {(connectionParentOptions as SelectableConnectionParentOption[]).map((option) => (
            <Select.Item key={option.value} value={option.value}>
              {option.label}
            </Select.Item>
          ))}
        </Select.Content>
      </Select>
    </div>
  );
}
