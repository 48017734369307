import { useEffect, useRef, useState } from 'react';
import {
  HiChevronDown as ChevronDownIcon,
  HiEllipsisHorizontal as EllipsisHorizontalIcon
} from 'react-icons/hi2';
import { Link, useParams } from 'react-router-dom';
import { useSortable } from '@dnd-kit/sortable';
import { Button, Collapsible } from '@knack/asterisk-react';

import { type BuilderPage } from '@/types/schema/BuilderPage';
import { cn } from '@/utils/tailwind';
import { useLeftPanelContext } from '@/pages/pages/LeftPanelContext';
import { PageDropdownMenu } from '@/pages/pages/page-tree/page-dropdown-menu/PageDropdownMenu';
import { type PageUrlParams } from '@/Router';

interface ItemWrapperProps {
  page: BuilderPage;
  children: React.ReactNode;
  className: string;
  linkHref: string;
  isActive?: boolean;
  style: React.CSSProperties;
  isCollapsibleOpen?: boolean;
  hasChildren: boolean;
}

export function ItemWrapper({
  page,
  children,
  className,
  linkHref,
  isActive,
  style,
  isCollapsibleOpen,
  hasChildren
}: ItemWrapperProps) {
  const { id: selectedPageKey } = useParams<PageUrlParams>();
  const { isSearching, isDialogOpen } = useLeftPanelContext();

  const { attributes, listeners, transform, isDragging } = useSortable({
    id: page.key,
    disabled: isSearching || isDialogOpen
  });

  const itemRef = useRef<HTMLDivElement>(null);
  const collapsibleRef = useRef<HTMLButtonElement>(null);
  const isCollapsible = typeof isCollapsibleOpen !== 'undefined';
  const [isPageDropdownOpen, setIsPageDropdownOpen] = useState(false);

  const rightButtonWrapperClasses = 'absolute flex size-8 items-center justify-center gap-2';
  const rightButtonClasses = cn('invisible', {
    'group-hover:visible': !transform,
    visible: isDragging
  });
  const rightButtonHoverClasses = cn('size-7', {
    'visible focus:bg-brand-100': isActive || isPageDropdownOpen,
    'hover:bg-emphasis': !isActive,
    'hover:bg-brand-200': isActive
  });
  const itemLinkClasses = cn(
    {
      'bg-brand-100 text-emphasis': isActive,
      'group-hover:bg-subtle': !isActive && !transform,
      'bg-subtle':
        (transform && isDragging && selectedPageKey !== page.key) ||
        (isPageDropdownOpen && !isActive)
    },
    className
  );
  // The children of the item link truncates the text, so this max-width is needed
  const itemLinkChildrenClasses = 'flex max-w-[calc(100%-60px)] items-center gap-1';

  useEffect(() => {
    if (isActive) {
      itemRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, [isActive]);

  const handleDropdownMenuTrigger = (isOpen: boolean, pageKey: BuilderPage['key']) => {
    if (isOpen) {
      setIsPageDropdownOpen(pageKey === page.key);
    } else {
      setIsPageDropdownOpen(false);
    }
  };

  return (
    <div
      className={cn('group relative overflow-hidden', {
        'opacity-50': isDragging
      })}
      ref={itemRef}
      {...listeners}
      {...attributes}
    >
      {/* Menu pages are not clickable/accessible */}
      {page.type === 'menu' ? (
        <button
          style={style}
          type="button"
          className={itemLinkClasses}
          onClick={() => isCollapsible && collapsibleRef.current?.click()}
        >
          <div className={itemLinkChildrenClasses}>{children}</div>
        </button>
      ) : (
        <Link
          to={linkHref}
          style={style}
          data-testid="page-tree-item-link"
          onClick={() => isCollapsible && !isCollapsibleOpen && collapsibleRef.current?.click()}
          className={itemLinkClasses}
        >
          <div className={itemLinkChildrenClasses}>{children}</div>
        </Link>
      )}

      <PageDropdownMenu page={page} onDropdownMenuOpenChange={handleDropdownMenuTrigger}>
        <div
          className={cn('right-8 top-0.5', rightButtonWrapperClasses, {
            'right-1': !hasChildren
          })}
        >
          <Button
            intent="minimalStandalone"
            size="xs"
            className={cn(rightButtonClasses, rightButtonHoverClasses)}
          >
            <EllipsisHorizontalIcon size={20} />
          </Button>
        </div>
      </PageDropdownMenu>
      {hasChildren && (
        <Collapsible.Trigger
          data-testid="page-tree-item-collapsible-trigger"
          className={cn('right-1 top-1', rightButtonWrapperClasses)}
          ref={collapsibleRef}
          asChild
        >
          <Button intent="minimalStandalone" size="xs" className={rightButtonHoverClasses}>
            <ChevronDownIcon
              size={16}
              className={cn('transition-transform duration-300', {
                'rotate-180': isCollapsibleOpen
              })}
            />
          </Button>
        </Collapsible.Trigger>
      )}
    </div>
  );
}
