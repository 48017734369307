import { type BuilderViewFilterConnectionSources } from '@/types/schema/BuilderView';
import { type KnackFieldKey } from '@/types/schema/KnackField';
import { RestrictedConnectionCard } from '@/pages/pages/settings-panel/view-settings/common/filtering/restricted-connections/RestrictedConnectionCard';
import { useRestrictedConnectionHelpers } from '@/pages/pages/settings-panel/view-settings/common/filtering/restricted-connections/useRestrictedConnectionHelpers';

export function RestrictedConnectionCardGroup({
  filterConnectionSources
}: {
  filterConnectionSources: BuilderViewFilterConnectionSources;
}) {
  const { formatRestrictedConnectionSourceGroup } = useRestrictedConnectionHelpers();
  return Object.keys(filterConnectionSources).map((key) => {
    const filterConnectionSourceGroup = formatRestrictedConnectionSourceGroup(
      key as KnackFieldKey,
      filterConnectionSources
    );

    return (
      <RestrictedConnectionCard
        key={key}
        filterConnectionSourceGroup={filterConnectionSourceGroup}
      />
    );
  });
}
