import {
  type BuilderViewBase,
  type BuilderViewFilterConnectionSources,
  type BuilderViewSourceSchema
} from '@/types/schema/BuilderView';
import { type KnackCriteria } from '@/types/schema/KnackCriteria';
import { type KnackFieldKey } from '@/types/schema/KnackField';
import { type KnackFilter, type KnackMenuFilter } from '@/types/schema/KnackFilter';
import type { DetailsViewOuterColumn } from '@/types/schema/views/DetailsView';

export interface MapDetailsView {
  columns: DetailsViewOuterColumn[];
  filter_fields: 'view' | 'object';
  filter_type: 'fields' | 'menu' | 'none';
  label_format: 'left' | 'right' | 'top' | 'none';
  layout: 'full' | 'one_quarter' | 'one_third' | 'half' | 'two_thirds' | 'three_quarters';
  list_layout: 'one-column' | 'two-column' | 'three-column';
  menu_filters?: KnackMenuFilter[];
  preset_filters: KnackFilter[];
  allow_preset_filters?: boolean;
  filter_connection_sources?: BuilderViewFilterConnectionSources | null;
}

export type MapPinColor = KnackCriteria;

export enum MapUnit {
  Miles = 'miles',
  Kilometers = 'kilometers'
}

export enum MapStartingPoint {
  Blank = 'blank',
  Location = 'location',
  Address = 'address'
}

export enum MapDefaultRange {
  Ten = 10,
  TwentyFive = 25,
  Fifty = 50,
  Hundred = 100,
  Any = 100000
}

export interface MapView extends BuilderViewBase {
  type: 'map';
  source: BuilderViewSourceSchema;
  address_field: {
    key: KnackFieldKey;
  };
  allow_limit: boolean;
  columns: DetailsViewOuterColumn[];
  default_range: MapDefaultRange;
  design: unknown;
  details: MapDetailsView;
  display_pagination_below: boolean;
  groups: unknown[];
  inputs: unknown[];
  layout: string;
  links: unknown[];
  list_width: number;
  map_height: number;
  map_width: number;
  pin_color_default: string;
  pin_colors: MapPinColor[];
  position_results_below_map?: boolean;
  rows_per_page: string;
  starting_point: MapStartingPoint;
  starting_address: string;
  title_field: {
    key: string;
  };
  units: MapUnit;
  no_data_text?: string;
  hide_fields?: boolean;
}
