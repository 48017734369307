import { useEffect, useMemo, useState } from 'react';

import { type KnackField } from '@/types/schema/KnackField';
import { type CalendarView } from '@/types/schema/views/CalendarView';
import { type FormViewFieldInput, type FormViewInput } from '@/types/schema/views/FormView';
import { BuilderAccordion } from '@/components/BuilderAccordion';
import { usePageEditorMessagingContext } from '@/pages/pages/page-editor/PageEditorMessagingContext';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { CalendarSourceBanner } from '@/pages/pages/settings-panel/view-settings/calendar/add-event/CalendarSourceBanner';
import { EventTitleDescriptionSettings } from '@/pages/pages/settings-panel/view-settings/calendar/add-event/EventTitleDescriptionSettings';
import { AddStaticInputButton } from '@/pages/pages/settings-panel/view-settings/form/field-management/AddStaticInputButton';
import {
  createFieldInput,
  getFieldsByEligibility
} from '@/pages/pages/settings-panel/view-settings/form/field-management/FormFieldManagement';
import { FormFields } from '@/pages/pages/settings-panel/view-settings/form/field-management/FormFields';
import { useUpdateView } from '@/pages/pages/settings-panel/view-settings/useUpdateView';

export function EventFormSettings() {
  const { sendMessageToLiveApp } = usePageEditorMessagingContext();
  const { view, sourceObject } = useActiveViewContext<CalendarView>();
  const updateViewSchema = useUpdateView();
  const [searchValue, setSearchValue] = useState('');
  const [visibleFields, setVisibleFields] = useState<KnackField[]>(sourceObject.fields);
  const activeFieldInputs = useMemo(
    () =>
      view.form.groups.reduce((acc: FormViewFieldInput[], group) => {
        group.columns.forEach((column) => {
          column.inputs.forEach((input) => {
            if (input.type !== 'divider' && input.type !== 'section_break') {
              acc.push(input);
            }
          });
        });
        return acc;
      }, []),
    [view.form.groups]
  );

  const [allowedFields, disallowedFields] = useMemo(
    () => getFieldsByEligibility(sourceObject.fields),
    [sourceObject.fields]
  );

  // Allowed and disallowed fields that are visible after a search
  const [visibleAllowedFields, visibleDisallowedFields] = useMemo(
    () => getFieldsByEligibility(visibleFields),
    [visibleFields]
  );

  // The non-static field inputs that have been already added to the form and are visible after a search
  const visibleActiveFieldInputs = activeFieldInputs.filter((input) =>
    visibleAllowedFields.some((field) => input.field.key === field.key)
  );

  // There might be disallowed fields that are already in the form, so we need to account for them
  const disallowedFieldsInTheForm = activeFieldInputs.filter((input) =>
    disallowedFields.some((field) => input.field.key === field.key)
  );

  const onAddAllFieldInputs = () => {
    const inputsToAdd: FormViewInput[] = [];

    // Create inputs for all the fields that are not already in the form
    visibleAllowedFields.forEach((field) => {
      if (activeFieldInputs.some((input) => input.field.key === field.key)) {
        return;
      }

      inputsToAdd.push(createFieldInput(field));
    });

    updateViewSchema({
      form: {
        ...view.form,
        // Add the new field inputs to the last column in the last group
        groups: view.form.groups.map((group, groupIndex) => {
          if (groupIndex === view.form.groups.length - 1) {
            return {
              ...group,
              columns: group.columns.map((column, columnIndex) => {
                if (columnIndex === group.columns.length - 1) {
                  return {
                    ...column,
                    inputs: [...column.inputs, ...inputsToAdd]
                  };
                }
                return column;
              })
            };
          }
          return group;
        })
      }
    });
  };

  const onAddFieldInput = (field: KnackField) => {
    updateViewSchema({
      form: {
        ...view.form,
        // Add the new field input to the last column in the last group
        groups: view.form.groups.map((group, groupIndex) => {
          if (groupIndex === view.form.groups.length - 1) {
            return {
              ...group,
              columns: group.columns.map((column, columnIndex) => {
                if (columnIndex === group.columns.length - 1) {
                  return {
                    ...column,
                    inputs: [...column.inputs, createFieldInput(field)]
                  };
                }
                return column;
              })
            };
          }
          return group;
        })
      }
    });
  };

  const onRemoveAllFieldInputs = () => {
    updateViewSchema({
      form: {
        ...view.form,
        groups: view.form.groups.map((group) => ({
          ...group,
          columns: group.columns.map((column) => ({
            ...column,
            inputs: column.inputs.filter((input) => {
              const isStaticInput = input.type === 'divider' || input.type === 'section_break';
              return (
                isStaticInput ||
                !visibleAllowedFields.some((field) => input.field.key === field.key)
              );
            })
          }))
        }))
      }
    });
  };

  const onRemoveFieldInput = (field: KnackField) => {
    updateViewSchema({
      form: {
        ...view.form,
        groups: view.form.groups.map((group) => ({
          ...group,
          columns: group.columns.map((column) => ({
            ...column,
            inputs: column.inputs.filter((input) => {
              const isStaticInput = input.type === 'divider' || input.type === 'section_break';

              if (!isStaticInput) {
                return input.field.key !== field.key;
              }

              return input;
            })
          }))
        }))
      }
    });
  };

  const onSearch = (value: string) => {
    const trimmedValue = value.trim().toLowerCase();

    setSearchValue(trimmedValue);

    if (trimmedValue === '') {
      setVisibleFields(sourceObject.fields);
      return;
    }

    setVisibleFields(
      sourceObject.fields.filter((field) => field.name.toLowerCase().includes(trimmedValue))
    );
  };

  useEffect(() => {
    sendMessageToLiveApp({
      action: 'calendar-action',
      calendarAction: 'show-event-form',
      viewKey: view.key
    });
  }, [sendMessageToLiveApp, view.key]);

  return (
    <>
      <CalendarSourceBanner action="insert" />
      <BuilderAccordion>
        <EventTitleDescriptionSettings />

        <FormFields
          activeFieldInputs={activeFieldInputs}
          allowedFields={allowedFields}
          disallowedFieldsInTheForm={disallowedFieldsInTheForm}
          onAddAllFieldInputs={onAddAllFieldInputs}
          onAddFieldInput={onAddFieldInput}
          onRemoveAllFieldInputs={onRemoveAllFieldInputs}
          onRemoveFieldInput={onRemoveFieldInput}
          onSearch={onSearch}
          searchValue={searchValue}
          sourceObject={sourceObject}
          visibleActiveFieldInputs={visibleActiveFieldInputs}
          visibleAllowedFields={visibleAllowedFields}
          visibleDisallowedFields={visibleDisallowedFields}
          visibleFields={visibleFields}
        />
        <div className="sticky -bottom-6 -mb-6 mt-auto border-t border-t-subtle bg-muted py-4">
          <AddStaticInputButton />
        </div>
      </BuilderAccordion>
    </>
  );
}
