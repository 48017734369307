import { useTranslation } from 'react-i18next';
import { Button, Dialog } from '@knack/asterisk-react';

interface RecordRuleFormDialogProps {
  formId: string;
  formIntent: 'edit' | 'add';
  onOpenChange: (isOpen: boolean) => void;
  isEmailRecordRule?: boolean;
  children: React.ReactNode;
}

export function RecordRuleFormDialog({
  formId,
  formIntent,
  onOpenChange,
  isEmailRecordRule,
  children
}: RecordRuleFormDialogProps) {
  const [t] = useTranslation();

  const getDialogTitle = () => {
    if (isEmailRecordRule) {
      return formIntent === 'edit'
        ? t('components.record_rule_card.edit_modal_email_record_rule.title')
        : t('components.record_rule_card.add_modal_email_record_rule.title');
    }

    return formIntent === 'edit'
      ? t('components.record_rule_card.edit_modal.title')
      : t('components.record_rule_card.add_modal.title');
  };

  const getDialogDescription = () => {
    if (isEmailRecordRule) {
      return formIntent === 'edit'
        ? t('components.record_rule_card.edit_modal_email_record_rule.description')
        : t('components.record_rule_card.add_modal_email_record_rule.description');
    }

    return formIntent === 'edit'
      ? t('components.record_rule_card.edit_modal.description')
      : t('components.record_rule_card.add_modal.description');
  };

  return (
    <Dialog open onOpenChange={onOpenChange}>
      <Dialog.Content>
        <Dialog.MainContent>
          <Dialog.Header>
            <Dialog.Title>{getDialogTitle()}</Dialog.Title>
            <Dialog.Description className="sr-only">{getDialogDescription()}</Dialog.Description>
          </Dialog.Header>

          <div className="mt-6">{children}</div>
        </Dialog.MainContent>
        <Dialog.Footer>
          <Dialog.Close asChild>
            <Button intent="minimal">{t('actions.cancel')}</Button>
          </Dialog.Close>
          <Button form={formId} type="submit" data-testid="view-record-rule-modal-submit">
            {formIntent === 'edit'
              ? t('components.record_rule_card.edit_modal.submit_button')
              : t('components.record_rule_card.add_modal.submit_button')}
          </Button>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  );
}
