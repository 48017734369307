import { type KnackField } from '@/types/schema/KnackField';

export function getFilteredFieldActionValueFields(
  targetField: KnackField,
  availableFields: KnackField[]
): KnackField[] {
  switch (targetField.type) {
    case 'signature':
    case 'address':
    case 'name':
    case 'timer':
      return availableFields.filter((f) => f.type === targetField.type);
    case 'file':
    case 'image':
      return availableFields.filter((f) => f.type === 'file' || f.type === 'image');
    case 'connection': {
      const filteredFields = availableFields.filter((f) => {
        if (f.type !== targetField.type) {
          return false;
        }

        const hasSameRelationshipObject = f.relationship.object === targetField.relationship.object;
        let isRelationshipAllowed = true;

        // We don't want a M:X connection to be able to be used as the value of a 1:X connection
        if (targetField.relationship.has === 'one' && f.relationship.has === 'many') {
          isRelationshipAllowed = false;
        }

        if (hasSameRelationshipObject && isRelationshipAllowed) {
          return true;
        }

        return false;
      });
      return filteredFields;
    }
    default:
      return availableFields;
  }
}
