import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
  type DetailsViewInput,
  type DetailsViewOuterColumn
} from '@/types/schema/views/DetailsView';
import { type ViewSettingsCategoryItem } from '@/pages/pages/settings-panel/view-settings/ViewSettingsCategoryList';

export type ViewWithDetailsSettingsCategoryId =
  | 'general-settings'
  | 'data-display'
  | 'actions'
  | 'styling'
  | 'column-settings';
export type ViewWithDetailsCategoryItem =
  ViewSettingsCategoryItem<ViewWithDetailsSettingsCategoryId>;

export function useDetailsSettingsHelpers() {
  const [t] = useTranslation();

  function getPanelTitle(
    activeCategoryId: ViewWithDetailsSettingsCategoryId | null,
    categories: ViewWithDetailsCategoryItem[],
    isListElement?: boolean
  ) {
    const activeCategory = activeCategoryId
      ? categories.find((category) => category.id === activeCategoryId)
      : null;

    return activeCategory
      ? activeCategory.name
      : t(`pages.element_settings.${isListElement ? 'list' : 'details'}.heading`);
  }

  function getActionColumnSettingsPanelTitle(input: DetailsViewInput | undefined) {
    switch (input?.type) {
      case 'action_link': {
        return t('pages.element_settings.common.categories.dynamic_action_title');
      }
      case 'scene_link': {
        return t('pages.element_settings.common.categories.link_action_title');
      }
      case 'delete': {
        return t('pages.element_settings.common.categories.delete_action_title');
      }
      case 'special_title': {
        return t('pages.element_settings.common.categories.static_input_title');
      }
      default:
        return t('pages.element_settings.common.categories.edit_field_title');
    }
  }

  const getViewDetailsInput = useCallback(
    (columns: DetailsViewOuterColumn[], viewInputId: string | null) => {
      let viewDetailsInput: DetailsViewInput | undefined;

      columns.forEach((outerColumn) => {
        outerColumn.groups.forEach((group) => {
          group.columns.forEach((column) => {
            column.forEach((input) => {
              if (input.key === viewInputId) {
                viewDetailsInput = input;
                return;
              }

              if (input.id === viewInputId) {
                viewDetailsInput = input;
              }
            });
          });
        });
      });

      return viewDetailsInput;
    },
    []
  );

  return {
    getActionColumnSettingsPanelTitle,
    getPanelTitle,
    getViewDetailsInput
  };
}
