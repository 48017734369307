import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Select } from '@knack/asterisk-react';

import { type KnackField } from '@/types/schema/KnackField';
import { type KnackObject } from '@/types/schema/KnackObject';
import { type RecordRule, type RecordRuleValue } from '@/types/schema/rules/RecordRule';
import {
  type RecordRuleConnectedValueOption,
  type RecordRuleRecordValueFieldOption
} from '@/hooks/helpers/useRecordRuleHelpers';
import { FieldIcon } from '@/components/FieldIcon';
import { FieldRecordValueInput } from '@/components/inputs/FieldRecordValueInput';

interface RecordRuleValueInputProps {
  ruleActionType: RecordRuleValue['type'];
  ruleConnectedValueOptions: RecordRuleConnectedValueOption[];
  ruleRecordValueFieldOptions: RecordRuleRecordValueFieldOption[];
  sourceObject: KnackObject;
  selectedField: KnackField;
  formFieldName: `values.${number}`;
}

export function RecordRuleValueInput({
  ruleActionType,
  ruleConnectedValueOptions,
  ruleRecordValueFieldOptions,
  selectedField,
  sourceObject,
  formFieldName
}: RecordRuleValueInputProps) {
  const [t] = useTranslation();

  const {
    control,
    formState: { errors },
    getFieldState
  } = useFormContext<RecordRule>();

  const hasErrors = getFieldState(formFieldName)?.error;

  if (ruleActionType === 'connection') {
    return (
      <Controller
        control={control}
        name={`${formFieldName}.connection_field`}
        render={({ field: { value: fieldKey, onChange } }) => (
          <Select value={fieldKey} onValueChange={onChange}>
            <Select.Trigger className="w-full" />
            <Select.Content>
              {ruleConnectedValueOptions.map((option) => (
                <Select.Item key={option.value} value={option.value}>
                  {option.label}
                </Select.Item>
              ))}
            </Select.Content>
          </Select>
        )}
      />
    );
  }

  if (ruleActionType === 'record') {
    return (
      <Controller
        control={control}
        name={`${formFieldName}.input`}
        render={({ field: { value: fieldKey, onChange } }) => (
          <Select value={fieldKey} onValueChange={onChange}>
            <Select.Trigger className="w-full" />
            <Select.Content>
              {ruleRecordValueFieldOptions.map((fieldOption) => (
                <Select.Item key={fieldOption.value} value={fieldOption.value}>
                  <span className="flex items-center">
                    <FieldIcon
                      className="mr-2 shrink-0 text-subtle"
                      size={16}
                      name={fieldOption.type}
                    />
                    {fieldOption.label}
                  </span>
                </Select.Item>
              ))}
            </Select.Content>
          </Select>
        )}
      />
    );
  }

  if (!selectedField) {
    return null;
  }

  return (
    <>
      <FieldRecordValueInput<RecordRule>
        field={selectedField}
        formFieldName={`${formFieldName}.value`}
        sourceObject={sourceObject}
      />
      {hasErrors && (
        <span className="text-xs text-destructive">
          {errors.values?.message || t('errors.value_required')}
        </span>
      )}
    </>
  );
}
