import { Fragment, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  MdFormatBold as BoldIcon,
  MdFormatItalic as ItalicIcon,
  MdOutlineStrikethroughS as StrikeThroughIcon
} from 'react-icons/md';
import { Badge, Tooltip } from '@knack/asterisk-react';
import snakeCase from 'lodash.snakecase';

import { type KnackObject } from '@/types/schema/KnackObject';
import {
  type TableDisplayRuleAction,
  type TableDisplayRuleActionType,
  type TableViewDisplayRule
} from '@/types/schema/views/TableView';
import { InlineKnackCriteriaValue } from '@/components/InlineKnackCriteriaValue';
import { InlineKnackField } from '@/components/InlineKnackField';
import { InlineKnackValue } from '@/components/InlineKnackValue';
import { EmptyRuleValue } from '@/pages/pages/settings-panel/view-settings/common/EmptyRuleValue';
import { SortableCardWrapper } from '@/pages/pages/settings-panel/view-settings/common/SortableCardWrapper';
import { TableDisplayRuleFormDialog } from '@/pages/pages/settings-panel/view-settings/table/data-display/display-rules/TableDisplayRuleFormDialog';

interface TableDisplayRuleCardProps {
  displayRule: TableViewDisplayRule;
  sourceObject: KnackObject;
  ruleNumber: number;
  isInvalid?: boolean;
  shouldScrollIntoView?: boolean;
  isDragOverlay?: boolean;
  onRuleSave: (updatedDisplayRule: TableViewDisplayRule) => void;
  onRuleDuplicate: (displayRuleToDuplicate: TableViewDisplayRule) => void;
  onRuleDelete: (displayRuleKey: TableViewDisplayRule['key']) => void;
}

export function TableDisplayRuleCard({
  displayRule,
  sourceObject,
  ruleNumber,
  isInvalid = false,
  shouldScrollIntoView = false,
  isDragOverlay = false,
  onRuleSave,
  onRuleDuplicate,
  onRuleDelete
}: TableDisplayRuleCardProps) {
  const [t] = useTranslation();
  const availableViewFields = sourceObject.fields;

  const displayRuleCardRef = useRef<HTMLDivElement | null>(null);
  const [isEditRuleModalOpen, setIsEditRuleModalOpen] = useState(false);

  useEffect(() => {
    if (shouldScrollIntoView && displayRuleCardRef.current) {
      displayRuleCardRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getActionText = (action: TableDisplayRuleActionType) => {
    switch (action) {
      case 'text-style':
        return t('components.display_rule_card.actions_display.text_style');
      case 'bg-color':
        return t('components.display_rule_card.actions_display.bg_color');
      case 'text-color':
        return t('components.display_rule_card.actions_display.text_color');
      case 'hide':
        return t('components.display_rule_card.actions_display.hide');
      default:
        return '';
    }
  };

  const renderTextStyleIcon = (action: TableDisplayRuleAction) => (
    <div className="flex items-center gap-1">
      {action.bold && (
        <div className="rounded-lg border border-emphasis p-1">
          <BoldIcon size={16} />
        </div>
      )}
      {action.italic && (
        <div className="rounded-lg border border-emphasis p-1">
          <ItalicIcon size={16} />
        </div>
      )}
      {action.strikethrough && (
        <div className="rounded-lg border border-emphasis p-1">
          <StrikeThroughIcon size={16} />
        </div>
      )}
    </div>
  );

  return (
    <>
      <SortableCardWrapper
        ref={displayRuleCardRef}
        sortableItemId={displayRule.key}
        isDragOverlay={isDragOverlay}
        isInactiveState={isInvalid}
        onEditClick={() => setIsEditRuleModalOpen(true)}
        onDuplicateClick={() => onRuleDuplicate(displayRule)}
        onDeleteClick={() => onRuleDelete(displayRule.key)}
      >
        <div className="mb-2 inline-flex items-center">
          <span className="text-xs font-medium text-emphasis">
            {t('components.display_rule_card.rule_number', {
              number: ruleNumber
            })}
          </span>

          {isInvalid && (
            <Tooltip>
              <Tooltip.Trigger asChild>
                <Badge intent="warning" className="ml-2 cursor-pointer leading-none">
                  {t('components.display_rule_card.invalid_rule_badge')}
                </Badge>
              </Tooltip.Trigger>
              <Tooltip.Portal>
                <Tooltip.Content className="max-w-80" side="bottom">
                  {t('components.display_rule_card.invalid_form_rule_tooltip')}
                </Tooltip.Content>
              </Tooltip.Portal>
            </Tooltip>
          )}
        </div>

        {displayRule.criteria.length === 0 && <EmptyRuleValue />}

        {displayRule.criteria.map((criteria, index) => {
          const criteriaField = availableViewFields.find((f) => f.key === criteria.field);
          const isLastCriteria = index === displayRule.criteria.length - 1;

          return (
            // eslint-disable-next-line react/no-array-index-key
            <Fragment key={`${criteria.field}-${index}`}>
              {criteriaField ? (
                <div className="flex flex-wrap items-center gap-1">
                  {index === 0 && <span>{t('components.rules.when')}</span>}
                  <InlineKnackField
                    fieldType={criteriaField.type}
                    fieldName={criteriaField.name}
                    className="border-none bg-action"
                  />
                  {t(`operators.${snakeCase(criteria.operator)}`)}
                  <InlineKnackCriteriaValue
                    criteria={criteria}
                    criteriaField={criteriaField}
                    className="bg-action"
                  />
                </div>
              ) : (
                <EmptyRuleValue />
              )}

              {!isLastCriteria && <div className="my-1">{t('components.rules.and_uppercase')}</div>}
            </Fragment>
          );
        })}

        <span className="my-2">{t('components.rules.then')}</span>

        {displayRule.actions.length === 0 && <EmptyRuleValue />}

        {displayRule.actions.map((action, index) => (
          <Fragment key={action.action}>
            <div className="flex flex-wrap items-center gap-1">
              {getActionText(action.action)}
              {(action.action === 'text-color' || action.action === 'bg-color') && (
                <>
                  <div
                    style={{
                      backgroundColor: action.action === 'bg-color' ? action.value : action.color
                    }}
                    className="size-4 rounded-md"
                  />
                  <InlineKnackValue
                    key="1"
                    value={
                      action.action === 'bg-color'
                        ? action.value?.toUpperCase()
                        : action.color?.toUpperCase()
                    }
                    className="bg-action"
                  />
                </>
              )}
              {action.action === 'text-style' && renderTextStyleIcon(action)}
            </div>

            {index < displayRule.actions.length - 1 && (
              <div className="my-1">{t('components.rules.and_uppercase')}</div>
            )}
          </Fragment>
        ))}
      </SortableCardWrapper>

      {isEditRuleModalOpen && (
        <TableDisplayRuleFormDialog
          onOpenChange={setIsEditRuleModalOpen}
          displayRule={displayRule}
          sourceObject={sourceObject}
          onRuleSave={(updatedDisplayRule) => {
            setIsEditRuleModalOpen(false);
            onRuleSave(updatedDisplayRule);
          }}
        />
      )}
    </>
  );
}
