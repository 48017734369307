import { useEffect, useRef } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { t } from 'i18next';
import { debounce } from 'lodash';
import { z } from 'zod';

import { type KnackCriteria } from '@/types/schema/KnackCriteria';
import { type CalendarView } from '@/types/schema/views/CalendarView';
import { useCriteriaHelpers } from '@/hooks/helpers/useCriteriaHelpers';
import { CriteriaForm } from '@/components/CriteriaForm';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { useUpdateView } from '@/pages/pages/settings-panel/view-settings/useUpdateView';

export function CalendarCriteriaForm() {
  const { view, sourceObject } = useActiveViewContext<CalendarView>();
  const updateViewSchema = useUpdateView<CalendarView>();
  const { validateCriteriaValues } = useCriteriaHelpers();

  const resolverSchema = z.custom<{ criteria: KnackCriteria[] }>().superRefine((data, context) => {
    const criteriaValueErrors = validateCriteriaValues(data.criteria, sourceObject.fields);

    if (criteriaValueErrors.length) {
      criteriaValueErrors.forEach((error) => {
        context.addIssue({
          path: error.path,
          message: t(error.message || 'errors.value_required'),
          code: 'custom'
        });
      });
    }
  }); // Wraps it in an array for validation
  type EventColorSchema = z.infer<typeof resolverSchema>;

  const formMethods = useForm<EventColorSchema>({
    resolver: zodResolver(resolverSchema),
    defaultValues: {
      criteria: view.events.event_colors
    }
  });
  const formState = useWatch({ control: formMethods.control, name: 'criteria' });
  const prevFormState = useRef(formState);

  useEffect(() => {
    const handler = debounce(() => {
      // update only when there is a change in formstate
      if (prevFormState.current === formState) return;
      prevFormState.current = formState;
      const eventColor = formState;
      updateViewSchema({ events: { ...view.events, event_colors: eventColor } });
    }, 300);

    handler();

    return () => {
      handler.cancel();
    };
    // want to update schema only when value is changed to avoid unnecessary re-renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState]);

  return (
    <FormProvider {...formMethods}>
      <CriteriaForm
        className="p-0"
        criteriaType="display-rule"
        sourceObject={sourceObject}
        includeColorCriteria
        isSingleCriteriaForm={false}
        wrapperClassName="p-3 bg-subtle rounded-lg"
        addButtonLabel={t('pages.element_settings.calendar.categories.criteria.button_text')}
        colorCriteriaPosition="above"
        colorFieldTitle={t('pages.element_settings.calendar.categories.criteria.color_field_title')}
      />
    </FormProvider>
  );
}
