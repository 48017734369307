import { useTranslation } from 'react-i18next';
import { Input, RichTextEditor } from '@knack/asterisk-react';

import {
  type DetailsViewInput,
  type DetailsViewOuterColumn
} from '@/types/schema/views/DetailsView';
import { type ViewWithDetails } from '@/types/schema/views/ViewWithDetails';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { useUpdateViewColumns } from '@/pages/pages/settings-panel/view-settings/useUpdateViewColumns';

interface ViewDetailsRichTextInputSettingsProps {
  columns: DetailsViewOuterColumn[];
  input: DetailsViewInput;
}

export function ViewDetailsRichTextInputSettings({
  columns,
  input
}: ViewDetailsRichTextInputSettingsProps) {
  const [t] = useTranslation();

  const { view } = useActiveViewContext<ViewWithDetails>();
  const updateViewColumnsSchema = useUpdateViewColumns<ViewWithDetails>();

  const onChange = (partialDetailsViewInput: Partial<DetailsViewInput>) => {
    const updatedColumns: DetailsViewOuterColumn[] = columns.map((outerColumn) => ({
      ...outerColumn,
      groups: outerColumn.groups.map((group) => ({
        ...group,
        columns: group.columns.map((column) => [
          ...column.map((i) => {
            if (i.id === input.id) {
              return {
                ...input,
                ...partialDetailsViewInput
              };
            }

            return i;
          })
        ])
      }))
    }));

    if (view.type === 'calendar' || view.type === 'map') {
      updateViewColumnsSchema({
        details: {
          ...view.details,
          columns: updatedColumns
        }
      });
    }

    updateViewColumnsSchema({
      columns: updatedColumns
    });
  };

  return (
    <div className="mt-6">
      <div className="mb-4">
        <span className="mb-2 block font-medium">
          {t('pages.element_settings.form.categories.form_fields.field_management.rich_text_title')}
        </span>
        <Input
          value={input.value}
          onChange={(e) => {
            onChange({
              value: e.target.value
            });
          }}
        />
      </div>
      <div>
        <span className="mb-2 block font-medium">
          {t(
            'pages.element_settings.form.categories.form_fields.field_management.rich_text_content'
          )}
        </span>
        <RichTextEditor
          content={input.copy}
          onUpdate={({ editor }) => {
            onChange({
              copy: editor.isEmpty ? '' : editor.getHTML()
            });
          }}
        />
      </div>
    </div>
  );
}
