export function getApplicationBasePathSegments() {
  const pathSegments = window.location.pathname.split('/').filter(Boolean);
  const [accountSlug, appSlug] = pathSegments;
  return { accountSlug, appSlug };
}

export function getApplicationBasePath() {
  const { accountSlug, appSlug } = getApplicationBasePathSegments();

  if (!accountSlug || !appSlug) {
    return '/';
  }

  return `/${accountSlug}/${appSlug}`;
}

export function getBuilderBaseUrl() {
  const applicationBasePath = getApplicationBasePath();
  return `${import.meta.env.PUBLIC_BUILDER_URL}${applicationBasePath}`;
}

// Returns the current URL but replaces the domain with the CRM domain, while keeping the rest of the URL (like protocol, path, query string, etc.) the same.
export function getDomainAsCrm() {
  const devDomain = 'builder.knackcrm.com';

  return window.location.href.replace(/^(https?:\/\/)[^/]+(\/.+)$/i, `$1${devDomain}$2`);
}
