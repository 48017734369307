import {
  dateTimeExtendedRangeOperators,
  dateTimeRangeOperators,
  hideValueOperators
} from '@/types/schema/KnackOperator';

export function shouldHideValueBasedOnOperator(operator: string) {
  return (hideValueOperators as ReadonlyArray<string>).includes(operator);
}

export function isDateTimeRangeOperator(operator: string) {
  return (dateTimeRangeOperators as ReadonlyArray<string>).includes(operator);
}

export function isDateTimeExtendedRangeOperator(operator: string) {
  return (dateTimeExtendedRangeOperators as ReadonlyArray<string>).includes(operator);
}
