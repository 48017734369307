import { useEffect, useState } from 'react';
import { useFlagsmith } from 'flagsmith/react';

interface FlagsmithSetupProps {
  accountId: string;
  children: React.ReactNode;
}

export function FlagsmithSetup({ accountId, children }: FlagsmithSetupProps) {
  const flagsmith = useFlagsmith();
  const [hasIdentifiedFlagsmith, setHasIdentifiedFlagsmith] = useState(false);

  useEffect(() => {
    if (!import.meta.env.PUBLIC_FLAGSMITH_ENV_ID) {
      setHasIdentifiedFlagsmith(true);
      return;
    }

    const handleIdentify = async () => {
      if (flagsmith.initialised && !flagsmith.getContext().identity) {
        await flagsmith.identify(accountId);
        setHasIdentifiedFlagsmith(true);
      }
    };

    void handleIdentify();
  }, [accountId, flagsmith]);

  if (!hasIdentifiedFlagsmith) {
    return null;
  }

  return children;
}
