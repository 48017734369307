import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { HiInformationCircle as InfoIcon } from 'react-icons/hi2';
import { Input, Select, Tooltip, type AppTheme } from '@knack/asterisk-react';
import { type CustomFont } from '@knack/asterisk-react/dist/src/themes/types';

import { useThemesPageContext } from '@/pages/themes/ThemesPageContext';

export const fonts = [
  'Inter',
  'Fira Sans',
  'Libre Franklin',
  'Merriweather Sans',
  'Lato',
  'Nunito',
  'Nunito Sans',
  'Open Sans',
  'PT Sans',
  'Roboto',
  'Rubik',
  'Source Sans 3'
] as const;

function getFontProperty<T extends keyof CustomFont>(
  font: AppTheme['font'],
  property: T
): CustomFont[T] | null {
  if (!font) {
    return null;
  }

  return typeof font === 'string' ? null : font[property];
}

export function Font() {
  const [t] = useTranslation();
  const { theme, setThemeField } = useThemesPageContext();

  const isCustomFont = useMemo(() => typeof theme.font !== 'string', [theme.font]);

  return (
    <div>
      <Select
        value={isCustomFont ? 'custom' : (theme.font as string)}
        onValueChange={(value) => {
          if (value === 'custom') {
            setThemeField((draft) => {
              draft.font = {
                type: 'stylesheet',
                name: '',
                url: ''
              };
            });
          } else {
            setThemeField((draft) => {
              draft.font = value;
            });
          }
        }}
      >
        <Select.Trigger placeholder={t('keywords.default')} className="w-full rounded-lg" />
        <Select.Content>
          {fonts.map((font, index) => (
            <Select.Item key={`font-${font}`} value={font} data-testid={`font-option-${font}`}>
              {/* The Inter (Knack's default font) name should not be displayed here due to IP reasons */}
              {index === 0 ? t('keywords.default') : font}
            </Select.Item>
          ))}
          <Select.Item key="font-custom" value="custom" data-testid="font-option-custom">
            {t('keywords.custom')}
          </Select.Item>
        </Select.Content>
      </Select>

      {isCustomFont && (
        <div className="mt-4 flex flex-row gap-2" data-testid="custom-font-inputs">
          <div className="w-1/3">
            <div className="mb-2 flex flex-row items-center gap-1">
              {t('themes.font_name')}
              <Tooltip>
                <Tooltip.Trigger>
                  <InfoIcon size={16} />
                </Tooltip.Trigger>
                <Tooltip.Content className="max-w-56 text-center leading-5">
                  {t('themes.font_name_info')}
                </Tooltip.Content>
              </Tooltip>
            </div>
            <Input
              onChange={(e) => {
                setThemeField((draft) => {
                  draft.font = {
                    type: getFontProperty(draft.font, 'type') ?? 'stylesheet',
                    name: e.target.value,
                    url: getFontProperty(draft.font, 'url') ?? ''
                  };
                });
              }}
              size="sm"
              placeholder={t('themes.font_name')}
              aria-label={t('themes.font_name')}
              maxLength={64}
              defaultValue={getFontProperty(theme.font, 'name') ?? ''}
            />
          </div>
          <div className="flex-1">
            <div className="mb-2 flex flex-row items-center gap-1">
              {t('themes.font_url')}
              <Tooltip>
                <Tooltip.Trigger>
                  <InfoIcon size={16} />
                </Tooltip.Trigger>
                <Tooltip.Content className="max-w-56 text-center leading-5">
                  {t('themes.font_url_info')}
                </Tooltip.Content>
              </Tooltip>
            </div>
            <Input
              onChange={(e) => {
                setThemeField((draft) => {
                  draft.font = {
                    type: getFontProperty(draft.font, 'type') ?? 'stylesheet',
                    name: getFontProperty(draft.font, 'name') ?? '',
                    url: e.target.value
                  };
                });
              }}
              size="sm"
              placeholder={t('themes.font_url')}
              aria-label={t('themes.font_url')}
              maxLength={2048}
              defaultValue={getFontProperty(theme.font, 'url') ?? ''}
            />
          </div>
        </div>
      )}
    </div>
  );
}
