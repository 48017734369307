import { useTranslation } from 'react-i18next';
import { Tabs, type TabsListItem } from '@knack/asterisk-react';

import { type CalendarView } from '@/types/schema/views/CalendarView';
import { BuilderAccordion } from '@/components/BuilderAccordion';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { DataDisplayLimitSettings } from '@/pages/pages/settings-panel/view-settings/calendar/data-display/DataDisplayLimitSettings';
import { DataDisplaySourceSettings } from '@/pages/pages/settings-panel/view-settings/calendar/data-display/DataDisplaySourceSettings';
import { useRestrictedConnectionHelpers } from '@/pages/pages/settings-panel/view-settings/common/filtering/restricted-connections/useRestrictedConnectionHelpers';
import { ViewSettingsFiltering } from '@/pages/pages/settings-panel/view-settings/common/filtering/ViewSettingsFiltering';
import { useUpdateView } from '@/pages/pages/settings-panel/view-settings/useUpdateView';

export function DataDisplaySettings() {
  const { view, sourceObject } = useActiveViewContext<CalendarView>();
  const [t] = useTranslation();
  const updateViewSchema = useUpdateView<CalendarView>();
  const tabListItems: TabsListItem[] = [
    {
      value: 'source',
      children: t('pages.element_settings.common.categories.data_display.source')
    },
    {
      value: 'filtering',
      children: t('pages.element_settings.common.categories.data_display.filtering')
    }
  ];

  const { restrictedConnectionFields } = useRestrictedConnectionHelpers();

  const listViewFilteringOptions = {
    filter_type: view.filter_type,
    filter_fields: view.filter_fields,
    preset_filters: view.preset_filters,
    menu_filters: view.menu_filters,
    ...(view.filter_connection_sources && {
      filter_connection_sources: view.filter_connection_sources
    })
  };

  return (
    <Tabs defaultValue="source" className="h-full">
      <Tabs.List items={tabListItems} intent="page" shouldDisableResponsive shouldUseGradient />
      <Tabs.Content value="source" className="p-0 text-default">
        <BuilderAccordion>
          <DataDisplaySourceSettings />
          <DataDisplayLimitSettings />
        </BuilderAccordion>
      </Tabs.Content>
      <Tabs.Content value="filtering" className="p-0 text-default">
        <BuilderAccordion>
          <ViewSettingsFiltering
            sourceObject={sourceObject}
            options={listViewFilteringOptions}
            updateViewSchema={updateViewSchema}
            restrictedConnectionFields={restrictedConnectionFields}
          />
        </BuilderAccordion>
      </Tabs.Content>
    </Tabs>
  );
}
