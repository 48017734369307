import { Controller, type ControllerRenderProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Checkbox, Label, RadioGroup } from '@knack/asterisk-react';

import { weeklyCheckboxOptionsMap, type DateTimePayload, type DayKey } from './types';

interface DateTimeRepeatCheckboxProps {
  name: string;
  currentFieldValue: DateTimePayload;
}

/**
 * @deprecated
 */
export function DateTimeRepeatsOnOptions({ name, currentFieldValue }: DateTimeRepeatCheckboxProps) {
  const [t] = useTranslation();
  const isWeekly = currentFieldValue.repeat?.frequency === 'weekly';

  const handleSelectWeeklyCheckbox = (
    day: DayKey,
    fieldValue: DateTimePayload,
    onChange: ControllerRenderProps['onChange']
  ) => {
    onChange({
      ...fieldValue,
      repeat: {
        ...fieldValue.repeat,
        [day]: !fieldValue.repeat?.[day]
      }
    });
  };

  const handleSelectMonthlyOptions = (
    option: string,
    fieldValue: DateTimePayload,
    onChange: ControllerRenderProps['onChange']
  ) => {
    onChange({
      ...fieldValue,
      repeat: {
        ...fieldValue.repeat,
        repeatby: option as 'dom' | 'dow'
      }
    });
  };

  if (isWeekly) {
    return (
      <Controller
        name={name}
        render={({ field: { value: fieldValue, onChange } }) => (
          <div className="flex flex-wrap gap-2">
            {Object.keys(weeklyCheckboxOptionsMap()).map((day) => {
              const dayOption = day as DayKey;
              return (
                <div key={day} className="flex items-center gap-2">
                  <Checkbox
                    id={dayOption}
                    checked={fieldValue.repeat?.[dayOption]}
                    className="bg-base"
                    onClick={() => handleSelectWeeklyCheckbox(dayOption, fieldValue, onChange)}
                  />
                  <Label htmlFor={dayOption}>{weeklyCheckboxOptionsMap()[dayOption]}</Label>
                </div>
              );
            })}
          </div>
        )}
      />
    );
  }

  return (
    <Controller
      name={name}
      render={({ field: { value: fieldValue, onChange } }) => (
        <RadioGroup
          defaultValue="dom"
          onValueChange={(val) => handleSelectMonthlyOptions(val, fieldValue, onChange)}
          className="flex"
        >
          <RadioGroup.Container>
            <RadioGroup.Item id="dom" value="dom" />
            <Label htmlFor="dom">{t('attributes.field_labels.date_time.day_of_the_month')}</Label>
          </RadioGroup.Container>
          <RadioGroup.Container>
            <RadioGroup.Item id="dow" value="dow" />
            <Label htmlFor="dow">{t('attributes.field_labels.date_time.day_of_the_week')}</Label>
          </RadioGroup.Container>
        </RadioGroup>
      )}
    />
  );
}
