import { useContext, useEffect, useRef, useState } from 'react';
import { RichTextRenderer, ThemeProviderContext } from '@knack/asterisk-react';
import DOMPurify from 'dompurify';

import { cn } from '@/utils/tailwind';
import { type RichTextField } from '@/components/data-table/display/fields/Field';
import { type FieldRenderProps } from '@/components/data-table/display/fields/FieldRender';
import { RichTextEdit } from '@/components/data-table/display/fields/rich-text/RichTextEdit';

export function RichTextValue(props: FieldRenderProps<RichTextField>) {
  const { value } = props;

  const ref = useRef<HTMLDivElement>(null);
  const refContainer = useRef<HTMLDivElement>(null);
  const [isOverflown, setIsOverflown] = useState(false);
  const { isDarkMode } = useContext(ThemeProviderContext);
  const sanitizedValue = DOMPurify.sanitize(value);

  useEffect(() => {
    if (!ref.current || !refContainer.current) {
      return;
    }
    const height = ref.current.clientHeight;
    const heightContainer = refContainer.current.clientHeight;

    if (height > heightContainer) {
      setIsOverflown(true);
    } else {
      setIsOverflown(false);
    }
  }, [value]);

  return (
    <div ref={refContainer} className="relative flex size-full overflow-hidden p-2">
      <div ref={ref} className="h-fit">
        <RichTextRenderer
          // We use dangerouslySetInnerHTML because the server returns the value as an HTML encoded string, so we have to properly render any special characters
          dangerouslySetInnerHTML={{ __html: sanitizedValue }}
        />
      </div>
      {isOverflown && (
        <p
          // IMPORTANT: gray-200 is the token for the bg-subtle color which is used in the hover.
          className={cn(
            'right-2px absolute bottom-0 h-12 w-full bg-gradient-to-t from-white from-20% group-hover:from-gray-200',
            { 'from-black': isDarkMode }
          )}
        />
      )}
    </div>
  );
}

export function RichTextRender(props: FieldRenderProps<RichTextField>) {
  const { isFloating } = props;

  if (isFloating) {
    return <RichTextEdit {...props} />;
  }

  return <RichTextValue {...props} />;
}
