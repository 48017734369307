import { useEffect, useMemo, useState } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { Collapsible } from '@knack/asterisk-react';
import { useLocalStorage } from 'usehooks-ts';

import { type BuilderPage } from '@/types/schema/BuilderPage';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { KnackPageIcon } from '@/components/KnackPageIcon';
import { OPEN_COLLAPSIBLES_KEY } from '@/pages/pages/LeftPanelContent';
import { useLeftPanelContext } from '@/pages/pages/LeftPanelContext';
import { AddNewPageButton } from '@/pages/pages/page-tree/AddNewPageButton';
import { ItemWrapper } from '@/pages/pages/page-tree/ItemWrapper';
import { ROUTES, type PageUrlParams } from '@/Router';

interface PageTreeItemProps {
  page: BuilderPage;
  leftPadding?: number;
  shouldSkipChildren?: boolean;
}

const LEFT_PADDING_STEP_IN_PX = 24;

export function PageTreeItem({
  page,
  leftPadding = 10,
  shouldSkipChildren = false
}: PageTreeItemProps) {
  const { data: app } = useApplicationQuery();
  const { id: currentPage } = useParams<PageUrlParams>();
  const { nonUserPages, collapsiblesState: leftPanelCollapsibleState } = useLeftPanelContext();

  const isCurrentPageActive = currentPage === page.key;
  const linkHref = generatePath(ROUTES.PAGES_ID, { id: page.key });

  const [localStorageOpenCollapsibles, setLocalStorageOpenCollapsibles] = useLocalStorage<string[]>(
    `${app?.id}-${OPEN_COLLAPSIBLES_KEY}`,
    []
  );

  const [isInitialLoadCompleted, setIsInitialLoadCompleted] = useState(false);
  const [isCollapsibleOpen, setIsCollapsibleOpen] = useState(() => {
    const isInLocalStorage = localStorageOpenCollapsibles.includes(page.key);
    const hasActiveChild = nonUserPages.some(
      (item) =>
        item.key === currentPage && (item.parentSlug === page.slug || item.menuPageKey === page.key)
    );
    return isInLocalStorage || hasActiveChild;
  });

  const childrenItems = useMemo(() => {
    if (shouldSkipChildren) {
      return [];
    }

    return nonUserPages.filter(
      (item) =>
        (item.parentSlug && item.parentSlug === page.slug) ||
        (item.menuPageKey && item.menuPageKey === page.key)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nonUserPages]);

  const updateOpenCollapsibles = (isOpen: boolean) => {
    let newItems: string[] = [];

    if (isOpen) {
      newItems = [...localStorageOpenCollapsibles];
      newItems.push(page.key);
    } else {
      newItems = localStorageOpenCollapsibles.filter((item) => item !== page.key);
    }

    setIsCollapsibleOpen(isOpen);
    setLocalStorageOpenCollapsibles(newItems);
  };

  useEffect(() => {
    // We use this effect to open the collapsible and add it to the localStorage if the childrenItems list is updated
    if (!isInitialLoadCompleted) {
      setIsInitialLoadCompleted(true);
      return;
    }

    if (childrenItems.length > 0) {
      updateOpenCollapsibles(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childrenItems.length]);

  useEffect(() => {
    if (leftPanelCollapsibleState) {
      setIsCollapsibleOpen(leftPanelCollapsibleState === 'expanded');
    }
  }, [leftPanelCollapsibleState]);

  return (
    <Collapsible open={isCollapsibleOpen} onOpenChange={updateOpenCollapsibles}>
      <ItemWrapper
        page={page}
        className="flex h-9 w-full items-center gap-2 rounded-lg p-2 hover:bg-subtle"
        linkHref={linkHref}
        isActive={isCurrentPageActive}
        style={{ paddingLeft: `${leftPadding}px` }}
        isCollapsibleOpen={isCollapsibleOpen}
        hasChildren={childrenItems.length > 0}
      >
        <KnackPageIcon page={page} isGradient={isCurrentPageActive} />
        <span className="flex-1 truncate">{page.name}</span>
      </ItemWrapper>
      <Collapsible.Content
        className="collapsible-animation rounded-lg bg-muted"
        data-testid="page-tree-item-collapsible-content"
      >
        {childrenItems.map((childPage) => (
          <PageTreeItem
            key={childPage.key}
            page={childPage}
            leftPadding={leftPadding + LEFT_PADDING_STEP_IN_PX}
          />
        ))}
        {page.type === 'menu' && <AddNewPageButton parentPage={page} />}
      </Collapsible.Content>
    </Collapsible>
  );
}
