import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdCallSplit as ConditionalIcon } from 'react-icons/md';
import { Badge, Button, Dialog, Divider, Input, Label, Switch } from '@knack/asterisk-react';

import { type CalendarView } from '@/types/schema/views/CalendarView';
import { type FormView, type FormViewFieldInput } from '@/types/schema/views/FormView';
import { FieldIcon } from '@/components/FieldIcon';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { useUpdateView } from '@/pages/pages/settings-panel/view-settings/useUpdateView';
import { AllowNewConnectedRecordsSwitch } from './AllowNewConnectedRecordsSwitch';
import { ConnectionFilters } from './ConnectionFilters';
import { ConnectionParentOptionsSelect } from './ConnectionParentOptionsSelect';

interface FormInputEditModalProps {
  fieldInput: FormViewFieldInput;
  onOpenChange: (isOpen: boolean) => void;
}

export function FormFieldInputEditModal({ fieldInput, onOpenChange }: FormInputEditModalProps) {
  const [t] = useTranslation();

  const updateViewSchema = useUpdateView<FormView | CalendarView>();
  const { view, sourceObject } = useActiveViewContext<FormView | CalendarView>();

  const field = sourceObject.fields.find((f) => f.key === fieldInput.id);

  const [fieldInputToUpdate, setFieldInputToUpdate] = useState<FormViewFieldInput>(fieldInput);

  if (!field) {
    throw new Error(`Field for form input with id ${fieldInput.id} not found`);
  }

  const shouldShowReadOnlySwitch = view.type !== 'calendar' && view.action === 'update';
  const shouldShowAllowNewOptionsSwitch =
    fieldInputToUpdate.type === 'multiple_choice' && !field.immutable;
  const shouldShowPasswordInputSettings =
    fieldInputToUpdate.type === 'password' &&
    (view.type === 'calendar' || view.action === 'update') &&
    view.source.authenticated_user;

  // Get the total number of display rules in the form that use this field as its criteria
  const displayRulesCount =
    view.type !== 'calendar' && view.rules.fields
      ? view.rules.fields.reduce((acc, rule) => {
          const count = rule.criteria.filter((criteria) => criteria.field === field.key).length;
          return acc + count;
        }, 0)
      : 0;

  const applyChangesToActivePage = () => {
    const formGroups = view.type === 'calendar' ? view.form.groups : view.groups;
    const updatedFormGroups = formGroups.map((group) => ({
      ...group,
      columns: group.columns.map((column) => ({
        ...column,
        inputs: column.inputs.map((input) => {
          if (input.type === 'divider' || input.type === 'section_break') {
            return input;
          }
          return input.field.key === fieldInputToUpdate.field.key ? fieldInputToUpdate : input;
        })
      }))
    }));

    if (view.type === 'calendar') {
      updateViewSchema({
        form: {
          ...view.form,
          groups: updatedFormGroups
        }
      });
    } else {
      updateViewSchema({
        groups: updatedFormGroups
      });
    }

    onOpenChange(false);
  };

  return (
    <Dialog open onOpenChange={onOpenChange}>
      <Dialog.Content>
        <Dialog.MainContent>
          <Dialog.Header>
            <Dialog.Title>
              {t(
                'pages.element_settings.form.categories.form_fields.field_management.edit_field_modal.title'
              )}
            </Dialog.Title>
            <Dialog.Description>
              {t(
                'pages.element_settings.form.categories.form_fields.field_management.edit_field_modal.description'
              )}
            </Dialog.Description>
          </Dialog.Header>
          <div className="mt-6">
            <div className="mb-4">
              <span className="mb-2 block">
                {t(
                  'pages.element_settings.form.categories.form_fields.field_management.edit_field_modal.field_name'
                )}
              </span>
              <Badge className="inline-flex items-center rounded-md">{field.name}</Badge>
            </div>
            <div>
              <span className="mb-2 block">
                {t(
                  'pages.element_settings.form.categories.form_fields.field_management.edit_field_modal.field_type'
                )}
              </span>
              <Badge className="inline-flex items-center rounded-md">
                <FieldIcon size={16} name={field.type} className="mr-1" />
                {t(`attributes.field_types.${field.type}`)}
              </Badge>
            </div>
            <Divider className="my-6" />
            <div className="mb-4">
              <Label className="mb-2 block" htmlFor={`field-label-${field.key}`}>
                {t(
                  'pages.element_settings.form.categories.form_fields.field_management.edit_field_modal.field_label'
                )}
              </Label>
              <Input
                id={`field-label-${field.key}`}
                value={fieldInputToUpdate.label}
                onChange={(e) =>
                  setFieldInputToUpdate({ ...fieldInputToUpdate, label: e.target.value })
                }
              />
            </div>
            <div className="mb-4">
              <Label className="mb-2 block" htmlFor={`field-instructions-${field.key}`}>
                {t(
                  'pages.element_settings.form.categories.form_fields.field_management.edit_field_modal.field_instructions'
                )}
              </Label>
              <Input
                id={`field-instructions-${field.key}`}
                value={fieldInputToUpdate.instructions}
                onChange={(e) =>
                  setFieldInputToUpdate({ ...fieldInputToUpdate, instructions: e.target.value })
                }
              />
            </div>

            {shouldShowReadOnlySwitch && (
              <Label className="mb-4 flex items-center">
                <Switch
                  className="mr-2"
                  checked={fieldInputToUpdate.read_only}
                  onCheckedChange={(checked) =>
                    setFieldInputToUpdate({ ...fieldInputToUpdate, read_only: checked })
                  }
                />
                {t(
                  'pages.element_settings.form.categories.form_fields.field_management.edit_field_modal.field_read_only'
                )}
              </Label>
            )}

            {shouldShowAllowNewOptionsSwitch && (
              <Label className="flex items-center">
                <Switch
                  className="mr-2"
                  checked={fieldInputToUpdate.allow_option_inserts}
                  onCheckedChange={(checked) => {
                    setFieldInputToUpdate({
                      ...fieldInputToUpdate,
                      allow_option_inserts: checked
                    });
                  }}
                />
                {t(
                  'pages.element_settings.form.categories.form_fields.field_management.edit_field_modal.field_allow_new_options'
                )}
              </Label>
            )}

            {shouldShowPasswordInputSettings && (
              <>
                <Label className="flex items-center">
                  <Switch
                    className="mr-2"
                    checked={fieldInputToUpdate.action_authenticate}
                    onCheckedChange={(checked) => {
                      // If there is no 'current password label' defined yet, we set a the default one
                      const currentPasswordLabel =
                        fieldInputToUpdate.label_authenticate === undefined
                          ? t(
                              'pages.element_settings.form.categories.form_fields.field_management.edit_field_modal.field_current_password_input_label_default'
                            )
                          : fieldInputToUpdate.label_authenticate;

                      setFieldInputToUpdate({
                        ...fieldInputToUpdate,
                        label_authenticate: currentPasswordLabel,
                        action_authenticate: checked
                      });
                    }}
                  />
                  {t(
                    'pages.element_settings.form.categories.form_fields.field_management.edit_field_modal.field_require_password'
                  )}
                </Label>

                {fieldInputToUpdate.action_authenticate && (
                  <div className="mt-2 pl-12">
                    <Label
                      className="mb-2 block"
                      htmlFor={`field-current-password-label-${field.key}`}
                    >
                      {t(
                        'pages.element_settings.form.categories.form_fields.field_management.edit_field_modal.field_current_password_input_label'
                      )}
                    </Label>
                    <Input
                      id={`field-current-password-label-${field.key}`}
                      value={fieldInputToUpdate.label_authenticate}
                      onChange={(e) =>
                        setFieldInputToUpdate({
                          ...fieldInputToUpdate,
                          label_authenticate: e.target.value
                        })
                      }
                    />
                  </div>
                )}
              </>
            )}

            {fieldInputToUpdate.type === 'connection' && field.type === 'connection' && (
              <>
                <Divider className="my-6" />
                <ConnectionParentOptionsSelect
                  connectionInputToUpdate={fieldInputToUpdate}
                  setConnectionInputToUpdate={setFieldInputToUpdate}
                  connectionField={field}
                />
                <ConnectionFilters
                  connectionInputToUpdate={fieldInputToUpdate}
                  setConnectionInputToUpdate={setFieldInputToUpdate}
                  connectionField={field}
                />
                <AllowNewConnectedRecordsSwitch
                  connectionInputToUpdate={fieldInputToUpdate}
                  setConnectionInputToUpdate={setFieldInputToUpdate}
                  connectionField={field}
                />
              </>
            )}

            {view.type !== 'calendar' && (
              <>
                <Divider className="my-6" />
                <div className="flex">
                  <span className="mr-2 inline-flex items-center rounded-sm bg-subtle px-1 py-0.5 text-xs font-medium text-subtle">
                    <ConditionalIcon size={16} className="text-subtle" />
                    <span className="ml-1">{displayRulesCount}</span>
                  </span>
                  {displayRulesCount === 0 ? (
                    <span className="font-medium">
                      {t(
                        'pages.element_settings.form.categories.form_fields.field_management.edit_field_modal.field_no_display_rules'
                      )}
                    </span>
                  ) : (
                    <span className="font-medium">
                      {t(
                        'pages.element_settings.form.categories.form_fields.field_management.edit_field_modal.field_display_rules',
                        {
                          count: displayRulesCount
                        }
                      )}
                    </span>
                  )}
                </div>
              </>
            )}
          </div>
        </Dialog.MainContent>
        <Dialog.Footer>
          <Dialog.Close asChild>
            <Button intent="minimal">{t('actions.cancel')}</Button>
          </Dialog.Close>
          <Button onClick={applyChangesToActivePage}>{t('actions.apply')}</Button>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  );
}
