import { lazy, Suspense } from 'react';
import { Spinner, Tooltip } from '@knack/asterisk-react';

import { getIconNameWithoutPrefix } from '@/utils/font-awesome';
import { type FontAwesomeIconLazyProps } from '@/components/font-awesome/FontAwesomeIconLazy';

export const FontAwesomeIconLazy = lazy(() =>
  import('@/components/font-awesome/FontAwesomeIconLazy').then((module) => ({
    default: module.FontAwesomeIconLazy
  }))
);

export function KnackFontAwesomeIcon(props: FontAwesomeIconLazyProps) {
  const { iconName } = props;
  return (
    <Suspense fallback={<Spinner />}>
      <Tooltip>
        <Tooltip.Trigger asChild>
          <div className="inline-block">
            <FontAwesomeIconLazy {...props} />
          </div>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content className="z-50">{getIconNameWithoutPrefix(iconName)}</Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip>
    </Suspense>
  );
}
