import { useCallback } from 'react';
import { nanoid } from 'nanoid';

import {
  type BuilderPage,
  type BuilderPageRule,
  type BuilderPageRuleCriteria
} from '@/types/schema/BuilderPage';
import { type KnackField, type KnackFieldKey } from '@/types/schema/KnackField';
import { type KnackObject } from '@/types/schema/KnackObject';
import { useCriteriaHelpers } from '@/hooks/helpers/useCriteriaHelpers';
import { useObjectHelpers } from '@/hooks/helpers/useObjectHelpers';
import { usePageHelpers, type EligibleLinkablePage } from '@/hooks/helpers/usePageHelpers';

export type PageRuleCriteriaFieldOption = {
  field: KnackField;
  criteriaFieldKey: BuilderPageRuleCriteria['field'];
  label: string;
};

export function usePageRuleHelpers() {
  const { getObjectByKey, getRoleObjects, hasRoleObjects } = useObjectHelpers();
  const { getDefaultCriteria, getDefaultCriteriaOperator, getDefaultCriteriaValue } =
    useCriteriaHelpers();
  const { getEligibleLinkablePagesFromObjectKey, getStartPages, getPageRoleObjects } =
    usePageHelpers();

  const getDefaultPageRuleCriteria = useCallback(
    (
      criteriaFieldOptions: PageRuleCriteriaFieldOption[],
      pageSourceObject: KnackObject | undefined
    ) => {
      // If the page has a source object, use the default criteria for that object
      if (pageSourceObject) {
        return getDefaultCriteria(
          pageSourceObject,
          'submit-rule' // Page rules are essentially submit rules but with other actions
        );
      }

      // Otherwise, use the default criteria for the first criteria field available
      if (criteriaFieldOptions.length > 0) {
        const firstOptionField = criteriaFieldOptions[0].field;
        const pageRuleCriteria: BuilderPageRuleCriteria = {
          field: criteriaFieldOptions[0].criteriaFieldKey,
          operator: getDefaultCriteriaOperator(firstOptionField, 'submit-rule'),
          value: getDefaultCriteriaValue(firstOptionField)
        };
        return pageRuleCriteria;
      }

      return undefined;
    },
    [getDefaultCriteria, getDefaultCriteriaOperator, getDefaultCriteriaValue]
  );

  const getDefaultPageRule = useCallback(
    (
      criteriaFieldOptions: PageRuleCriteriaFieldOption[],
      sourceObject: KnackObject | undefined
    ) => {
      const defaultCriteria = getDefaultPageRuleCriteria(criteriaFieldOptions, sourceObject);

      const pageRule: BuilderPageRule = {
        key: `submit_${nanoid(10)}`,
        action: 'message',
        type: 'neutral',
        criteria: defaultCriteria ? [defaultCriteria] : [],
        close_link: false,
        message: '',
        view_keys: []
      };

      return pageRule;
    },
    [getDefaultPageRuleCriteria]
  );

  const getPageRuleCriteriaFieldOptions = useCallback(
    (page: BuilderPage, pageSourceObject: KnackObject | undefined) => {
      const criteriaFieldOptions: PageRuleCriteriaFieldOption[] = [];
      const cachedHasRoleObjects = hasRoleObjects();

      // If the page has a source object, add the fields and connected fields from that object as options
      if (pageSourceObject) {
        pageSourceObject.fields.forEach((field) => {
          // Only add the User Roles option if roles are available
          if (field.type === 'user_roles' && !cachedHasRoleObjects) {
            return;
          }

          criteriaFieldOptions.push({
            field,
            criteriaFieldKey: field.key,
            label: field.name
          });
        });

        // Add outbound connected fields as options
        pageSourceObject.connections.outbound.forEach((connection) => {
          if (connection.has === 'many') {
            return;
          }

          const connectedObject = getObjectByKey(connection.object);
          if (connectedObject) {
            connectedObject.fields.forEach((field) => {
              const connectionName =
                connectedObject.name === connection.name
                  ? connection.name
                  : `${connectedObject.name} (${connection.name})`;

              criteriaFieldOptions.push({
                field,
                criteriaFieldKey: `${connection.key}-${field.key}`,
                label: `${connectionName} > ${field.name}`
              });
            });
          }
        });

        // Add inbound connected fields as options
        pageSourceObject.connections.inbound.forEach((connection) => {
          if (connection.belongs_to === 'many') {
            return;
          }

          const connectedObject = getObjectByKey(connection.object);
          if (connectedObject) {
            connectedObject.fields.forEach((field) => {
              const connectionName =
                connectedObject.name === connection.name
                  ? connection.name
                  : `${connectedObject.name} (${connection.name})`;

              criteriaFieldOptions.push({
                field,
                criteriaFieldKey: `${connection.key}-${field.key}`,
                label: `${connectionName} > ${field.name}`
              });
            });
          }
        });
      }

      // If the page is behind a login, get fields for any roles that have access to the page
      if (page.requiresAuthentication) {
        const roleObjects =
          page.allowedProfileKeys && page.allowedProfileKeys.length > 0
            ? getPageRoleObjects(page, true)
            : getRoleObjects(true);

        roleObjects.forEach((roleObject) => {
          roleObject.fields.forEach((field) => {
            // Only add the User Roles option if roles are available
            if (field.type === 'user_roles' && !cachedHasRoleObjects) {
              return;
            }
            if (!roleObject.profile_key) {
              return;
            }
            criteriaFieldOptions.push({
              field,
              criteriaFieldKey: `${roleObject.profile_key}-${field.key}`,
              label: `${roleObject.name} > ${field.name}`
            });
          });

          // Add outbound connected fields as options
          roleObject.connections.outbound.forEach((connection) => {
            if (connection.has === 'many') {
              return;
            }

            const connectedObject = getObjectByKey(connection.object);
            if (connectedObject) {
              connectedObject.fields.forEach((field) => {
                const connectionName =
                  connectedObject.name === connection.name
                    ? connection.name
                    : `${connectedObject.name} (${connection.name})`;

                criteriaFieldOptions.push({
                  field,
                  criteriaFieldKey: `${connection.key}-${field.key}`,
                  label: `${roleObject.name} > ${connectionName} > ${field.name}`
                });
              });
            }
          });

          // Add inbound connected fields as options
          roleObject.connections.inbound.forEach((connection) => {
            if (connection.belongs_to === 'many') {
              return;
            }

            const connectedObject = getObjectByKey(connection.object);
            if (connectedObject) {
              connectedObject.fields.forEach((field) => {
                criteriaFieldOptions.push({
                  field,
                  criteriaFieldKey: `${connection.key}-${field.key}`,
                  label: `${connectedObject.name} > ${roleObject.name} > ${field.name}`
                });
              });
            }
          });
        });
      }

      return criteriaFieldOptions;
    },
    [hasRoleObjects, getObjectByKey, getPageRoleObjects, getRoleObjects]
  );

  const getPageRuleCriteriaFieldFromKey = useCallback(
    (
      criteriaFieldKey: BuilderPageRuleCriteria['field'],
      criteriaFieldOptions: PageRuleCriteriaFieldOption[]
    ) => {
      // Check if the field key is a foreign field, which is a field that references a field from a connected object (e.g. "field_1-field_2")
      const isForeignField = criteriaFieldKey.includes('-');

      // If the field is a foreign field, we need to use that field's key, which is the second part of the criteria field key (e.g. "field_1-field_2")
      const fieldKey = isForeignField
        ? (criteriaFieldKey.split('-')[1] as KnackFieldKey)
        : criteriaFieldKey;

      const fieldOption = criteriaFieldOptions.find(
        (criteriaFieldOption) => criteriaFieldOption.field.key === fieldKey
      );

      if (!fieldOption) {
        return undefined;
      }

      return fieldOption.field;
    },
    []
  );

  const getPageRuleLinkablePages = useCallback(
    (pageSourceObject: KnackObject | undefined) => {
      // If the page has a source object, use the linkable pages from that object
      if (pageSourceObject) {
        return getEligibleLinkablePagesFromObjectKey(pageSourceObject.key);
      }

      // Otherwise, use the start pages
      const linkablePages: EligibleLinkablePage[] = getStartPages().map((page) => ({
        label: page.name,
        name: page.name,
        slug: page.slug,
        key: page.key
      }));

      return linkablePages;
    },
    [getEligibleLinkablePagesFromObjectKey, getStartPages]
  );

  return {
    getDefaultPageRule,
    getDefaultPageRuleCriteria,
    getPageRuleCriteriaFieldFromKey,
    getPageRuleCriteriaFieldOptions,
    getPageRuleLinkablePages
  };
}
