import { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { HiPlus as PlusIcon } from 'react-icons/hi2';
import { DndContext, DragOverlay, type DragEndEvent, type DragStartEvent } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Button } from '@knack/asterisk-react';

import { type KnackField, type KnackFieldKey } from '@/types/schema/KnackField';
import { type RecordRule } from '@/types/schema/rules/RecordRule';
import { type FormView } from '@/types/schema/views/FormView';
import { useRecordRuleHelpers } from '@/hooks/helpers/useRecordRuleHelpers';
import { useDndUtils } from '@/hooks/useDndUtils';
import { VerticalListSortableItem } from '@/components/dnd/VerticalListSortableItem';
import { LearnMoreLink } from '@/components/LearnMoreLink';
import { RecordRuleForm } from '@/components/record-rule/RecordRuleForm';
import { RecordRuleFormDialog } from '@/components/record-rule/RecordRuleFormDialog';
import { usePageEditorContext } from '@/pages/pages/page-editor/PageEditorContext';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { ViewRecordRuleCard } from '@/pages/pages/settings-panel/view-settings/common/record-rules/ViewRecordRuleCard';
import { useUpdateView } from '@/pages/pages/settings-panel/view-settings/useUpdateView';

type NewlyInsertedRecordRule = {
  key: string;
  insertAction: 'new' | 'duplicate';
};

type EmailRuleInModal = {
  emailRule: RecordRule;
  modalIntent: 'add' | 'edit';
};

export function FormEmailRules() {
  const [t] = useTranslation();

  const { page } = usePageEditorContext();
  const { view, sourceObject } = useActiveViewContext<FormView>();
  const updateView = useUpdateView<FormView>();
  const { getDefaultEmailRecordRule } = useRecordRuleHelpers();
  const { optimizedSensors, verticalListCollisionDetection } = useDndUtils();

  const [emailRuleInModal, setEmailRuleInModal] = useState<EmailRuleInModal | null>(null);
  const [newlyInsertedRule, setNewlyInsertedRule] = useState<NewlyInsertedRecordRule | null>(null);
  const [beingDraggedRuleKey, setBeingDraggedRuleKey] = useState<string | null>(null);

  // Create a fieldMap to quickly access the fields without having to iterate over sourceObject.fields every time.
  const sourceObjectFieldsMap: Record<KnackFieldKey, KnackField> = useMemo(
    () => Object.fromEntries(sourceObject.fields.map((field) => [field.key, field])),
    [sourceObject]
  );

  const handleDragStart = (event: DragStartEvent) => {
    setBeingDraggedRuleKey(event.active.id as string);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    if (!view.rules.emails) {
      return;
    }

    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = view.rules.emails.findIndex((rule) => rule.key === active.id) ?? -1;
      const newIndex = view.rules.emails.findIndex((rule) => rule.key === over.id) ?? -1;

      if (oldIndex === -1 || newIndex === -1) {
        return;
      }

      const reorderedRules = arrayMove(view.rules.emails, oldIndex, newIndex);

      updateView({
        rules: {
          ...view.rules,
          emails: reorderedRules
        }
      });

      setBeingDraggedRuleKey(null);
    }
  };

  const onRuleAdd = (newEmailRule: RecordRule) => {
    setNewlyInsertedRule({
      key: newEmailRule.key,
      insertAction: 'new'
    });

    updateView({
      rules: {
        ...view.rules,
        emails: view.rules.emails ? [...view.rules.emails, newEmailRule] : [newEmailRule]
      }
    });
  };

  const onRuleSave = (updatedEmailRule: RecordRule) => {
    updateView({
      rules: {
        ...view.rules,
        emails: view.rules.emails?.map((emailRule) =>
          emailRule.key === updatedEmailRule.key ? updatedEmailRule : emailRule
        )
      }
    });
  };

  const onRuleDuplicate = (emailRuleToDuplicate: RecordRule) => {
    if (!view.rules.emails) {
      return;
    }

    const newEmailRule = getDefaultEmailRecordRule();
    const duplicatedRule: RecordRule = {
      ...emailRuleToDuplicate,
      key: newEmailRule.key
    };

    setNewlyInsertedRule({
      key: duplicatedRule.key,
      insertAction: 'duplicate'
    });

    updateView({
      rules: {
        ...view.rules,
        emails: [...view.rules.emails, duplicatedRule]
      }
    });
  };

  const onRuleDelete = (emailRuleKey: RecordRule['key']) => {
    updateView({
      rules: {
        ...view.rules,
        emails: view.rules.emails?.filter((emailRule) => emailRule.key !== emailRuleKey)
      }
    });
  };

  return (
    <>
      <p className="mb-4 text-xs text-subtle">
        <Trans
          i18nKey="pages.element_settings.form.categories.form_email_rules.inner_description"
          components={[
            <LearnMoreLink key="0" href="https://docs.knack.com/docs/forms-record-actions" />
          ]}
        />
      </p>

      <Button
        intent="secondary"
        className="w-full"
        onClick={() =>
          setEmailRuleInModal({
            modalIntent: 'add',
            emailRule: getDefaultEmailRecordRule()
          })
        }
        data-testid="add-email-rule-button"
      >
        <PlusIcon size={16} className="mr-2" />
        {t('pages.element_settings.form.rules.add_action')}
      </Button>

      {view.rules.emails && view.rules.emails.length > 0 && (
        <DndContext
          sensors={optimizedSensors}
          collisionDetection={verticalListCollisionDetection}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={view.rules.emails.map((rule) => rule.key)}
            strategy={verticalListSortingStrategy}
          >
            <div className="mt-4 space-y-2">
              {view.rules.emails.map((emailRule, ruleIndex) => (
                <VerticalListSortableItem key={emailRule.key} id={emailRule.key}>
                  <ViewRecordRuleCard
                    key={emailRule.key}
                    recordRule={emailRule}
                    runEveryTimeText={t('pages.element_settings.form.rules.run_every_time')}
                    sourceObject={sourceObject}
                    sourceObjectFieldsMap={sourceObjectFieldsMap}
                    shouldScrollIntoView={newlyInsertedRule?.key === emailRule.key}
                    ruleNumber={ruleIndex + 1}
                    onRuleEdit={() => setEmailRuleInModal({ modalIntent: 'edit', emailRule })}
                    onRuleDuplicate={onRuleDuplicate}
                    onRuleDelete={onRuleDelete}
                  />
                </VerticalListSortableItem>
              ))}
            </div>
          </SortableContext>

          <DragOverlay>
            {view.rules.emails.map((emailRule, ruleIndex) => {
              if (emailRule.key !== beingDraggedRuleKey) {
                return null;
              }

              return (
                <ViewRecordRuleCard
                  key={emailRule.key}
                  isDragOverlay
                  recordRule={emailRule}
                  runEveryTimeText={t('pages.element_settings.form.rules.run_every_time')}
                  sourceObject={sourceObject}
                  sourceObjectFieldsMap={sourceObjectFieldsMap}
                  ruleNumber={ruleIndex + 1}
                  onRuleEdit={() => setEmailRuleInModal({ modalIntent: 'edit', emailRule })}
                  onRuleDuplicate={onRuleDuplicate}
                  onRuleDelete={onRuleDelete}
                />
              );
            })}
          </DragOverlay>
        </DndContext>
      )}

      {emailRuleInModal && (
        <RecordRuleFormDialog
          formId="email-rule-form"
          formIntent={emailRuleInModal.modalIntent}
          onOpenChange={() => setEmailRuleInModal(null)}
          isEmailRecordRule
        >
          <RecordRuleForm
            formId="email-rule-form"
            recordRule={emailRuleInModal.emailRule}
            recordRuleActionTypes={['email', 'email_notification']}
            canCriteriaValuesBeField={view.action === 'update'}
            sourceObject={sourceObject}
            sourcePage={page}
            onSubmit={(modifiedEmailRule) => {
              setEmailRuleInModal(null);

              if (emailRuleInModal.modalIntent === 'edit') {
                onRuleSave(modifiedEmailRule);
              }

              if (emailRuleInModal.modalIntent === 'add') {
                onRuleAdd(modifiedEmailRule);
              }
            }}
            labels={{
              runEveryTime: t('pages.element_settings.form.rules.run_every_time')
            }}
          />
        </RecordRuleFormDialog>
      )}
    </>
  );
}
