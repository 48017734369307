export const KNOWLEDGE_BASE_URLS = {
  USERS_AND_LIVE_APP_ACCESS: 'https://docs.knack.com/docs/users-and-live-app-access-how-to-guides',
  RECORDS_OVERVIEW: 'https://docs.knack.com/docs/records-overview',
  MANAGING_LOGIN_PAGES_AND_USER_ACCESS:
    'https://docs.knack.com/docs/managing-login-pages-and-user-access',
  CONNECTIONS_GUIDE: 'https://docs.knack.com/docs/connections-guide',
  EDITING_A_MULTIPLE_CHOICE_FIELD:
    'https://docs.knack.com/docs/field-management#editing-a-multiple-choice-field',
  KNACK_DOCS: 'https://docs.knack.com/docs',
  IMPORTING_DATA: 'https://docs.knack.com/docs/importing-data',
  LIMITED_USE_DISCLOSURE: 'https://docs.knack.com/docs/limited-use-disclosure',
  PAGES_OVERVIEW: 'https://docs.knack.com/docs/pages-overview',
  ELEMENTS_OVERVIEW: 'https://docs.knack.com/docs/elements-overview',
  PAGE_RULES: 'https://docs.knack.com/docs/page-rules',
  CALENDAR_ELEMENT: 'https://docs.knack.com/docs/calendar-element',
  DISPLAY_RULES_OVERVIEW: 'https://docs.knack.com/docs/display-rules-overview',
  FORMS_SUBMIT_ACTIONS: 'https://docs.knack.com/docs/forms-submit-actions',
  USER_ROLES_OVERVIEW: 'https://docs.knack.com/docs/user-roles-overview#activating-users',
  ABOUT_USER_ROLES: 'https://docs.knack.com/docs/about-user-roles',
  APP_SETTINGS_OVERVIEW: 'https://docs.knack.com/docs/app-settings-overview',
  SECURITY_AND_PRIVACY_SETTINGS: 'https://docs.knack.com/docs/security-and-privacy-settings',
  SECURITY_AND_PRIVACY_SETTINGS_OVERVIEW:
    'https://docs.knack.com/docs/security-and-privacy-settings-overview',
  SECURITY_AND_PRIVACY_SETTINGS_SCRIPT_ATTACK_PROTECTION_ALLOWLIST:
    'https://docs.knack.com/docs/security-and-privacy-settings#script-attack-protection-allowlist',
  SECURITY_AND_PRIVACY_SETTINGS_SUPPORT_ACCESS:
    'https://docs.knack.com/docs/security-and-privacy-settings#support-access',
  TASKS_OVERVIEW: 'https://docs.knack.com/docs/tasks-overview#task-limits',
  CUSTOMIZE_WHICH_RECORDS_DISPLAY:
    'https://docs.knack.com/docs/customize-which-records-display#filter-update-required'
};
