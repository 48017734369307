import { useTranslation } from 'react-i18next';
import { HiChevronLeft as GoBackIcon } from 'react-icons/hi2';
import { generatePath, Link, useSearchParams } from 'react-router-dom';
import { Button } from '@knack/asterisk-react';

import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { getBuilderBaseUrl } from '@/utils/application';
import { isFlagEnabled } from '@/utils/flagsmith';
import { safeLogRocketTrack } from '@/utils/logrocket';
import { isRudderStackEnabled, rudderStackAnalytics } from '@/utils/rudderstack';
import { ROUTES } from '@/Router';
import { DataModelToolbar } from './DataModelToolbar';

export function DataModelHeader() {
  const [searchParams] = useSearchParams();

  const { data: application } = useApplicationQuery();
  const [t] = useTranslation();
  const isNextGenAccess =
    isFlagEnabled('full_nextgen_access') || isFlagEnabled('only_nextgen_access');
  const backLinkRefCallback = async (element: HTMLAnchorElement | null) => {
    if (element && isRudderStackEnabled() && application) {
      await rudderStackAnalytics.track('Clicked Action', {
        application_id: application.id,
        account_id: application.account.id,
        location: 'Data Model',
        text: 'Back'
      });
    }

    safeLogRocketTrack('NavigateToBuilder', application?.account.isHipaa);
  };

  const oldBuilderPath = getBuilderBaseUrl();
  const newBuilderPath = `${generatePath(ROUTES.TABLES)}`;
  const shouldRedirectToOldBuilder =
    (searchParams.has('origin') && searchParams.get('origin') === 'v3') || !isNextGenAccess;

  return (
    <div
      className="items-center border-b border-subtle bg-default px-6 py-4 shadow-sm"
      data-testid="data-model-header"
    >
      <div className="mb-3 flex w-full items-center justify-between *:flex-1 after:flex-1">
        <div>
          <Button
            type="button"
            intent="minimalStandalone"
            className="h-auto text-base text-default"
            data-testid="go-back-button"
            asChild
          >
            <Link
              ref={backLinkRefCallback}
              reloadDocument={shouldRedirectToOldBuilder}
              to={shouldRedirectToOldBuilder ? oldBuilderPath : newBuilderPath}
            >
              <GoBackIcon size={20} className="mr-2" />
              {t('actions.back')}
            </Link>
          </Button>
        </div>
        <div className="flex items-center justify-center gap-2">
          <h2
            className="font-semibold text-emphasis"
            data-testid="data-model-header-application-name"
          >
            {application?.name}
          </h2>
          <span className="font-normal text-subtle">{t('components.data_model.title')}</span>
        </div>
      </div>
      <DataModelToolbar />
    </div>
  );
}
