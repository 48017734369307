import { Trans } from 'react-i18next';

import { type CalendarView } from '@/types/schema/views/CalendarView';
import { type TableView } from '@/types/schema/views/TableView';
import { type ViewWithDetails } from '@/types/schema/views/ViewWithDetails';
import { useObjectHelpers } from '@/hooks/helpers/useObjectHelpers';
import { ViewSourceBanner } from '@/pages/pages/settings-panel/view-settings/ViewSourceBanner';

type ViewsWithSource = TableView | ViewWithDetails | CalendarView;

function useGetSourceDescription(view: ViewsWithSource) {
  const { getObjectByKey, getObjectByFieldKey } = useObjectHelpers();

  if (!view.source) {
    return null;
  }

  const viewSourceObject = getObjectByKey(view.source.object);

  if (!viewSourceObject) {
    return null;
  }

  const i18nKey =
    view.type === 'details'
      ? 'pages.element_settings.common.source_object_details_information'
      : 'pages.element_settings.common.source_object_information';

  const defaultSourceDescription = (
    <Trans
      i18nKey={i18nKey}
      components={[
        <ViewSourceBanner.ObjectName key="0" objectName={viewSourceObject.inflections.singular} />
      ]}
      values={{
        objectName: viewSourceObject.inflections.singular
      }}
    />
  );

  // If the view source object has a direct connection, we need to include it in the source description
  if (view.source.connection_key) {
    const connectionFieldObject = getObjectByFieldKey(view.source.connection_key);
    const connectionField = connectionFieldObject?.fields.find(
      (field) => field.key === view.source.connection_key
    );

    let connectedObject = connectionFieldObject;
    if (connectionFieldObject?.key === view.source.object) {
      connectedObject = connectionField?.relationship?.object
        ? getObjectByKey(connectionField.relationship.object)
        : undefined;
    }

    if (!connectedObject) {
      return defaultSourceDescription;
    }

    const originObjectI18nKey = view.source.authenticated_user ? 'logged_in_user' : 'page';

    // When there is a parent and a direct connection, the parent connection represents the connection that the source object has with the origin object of the page (which could be the source object of the page itself, or the logged-in user object)
    // Example: "This form updates a Coach record [connected to the Team] [connected to this page's Player]"
    //                                                ^ this is the direct connection         ^ this is the parent connection
    if (view.source.parent_source) {
      const parentSourceObject = getObjectByKey(view.source.parent_source.object);

      if (parentSourceObject) {
        return (
          <Trans
            i18nKey={`pages.element_settings.common.source_object_connected_to_object_connected_to_the_${originObjectI18nKey}`}
            components={[
              <ViewSourceBanner.ObjectName
                key="0"
                objectName={viewSourceObject.inflections.singular}
              />,
              <ViewSourceBanner.ObjectName
                key="1"
                objectName={connectedObject.inflections.singular}
              />,
              <ViewSourceBanner.ObjectName
                key="2"
                objectName={parentSourceObject.inflections.singular}
              />
            ]}
            values={{
              objectName: viewSourceObject.inflections.singular,
              connectedObjectName: connectedObject.inflections.singular,
              parentSourceObjectName: parentSourceObject.inflections.singular
            }}
          />
        );
      }
    }

    // If there is no parent connection, we only need to include the direct connection which is the connection that the source object has with the origin object of the page (which could be the source object of the page itself, or the logged-in user object)
    // Example: "This form updates a Team record [connected to this page's Game]"
    //                                                   ^ this is the direct connection
    return (
      <Trans
        i18nKey={`pages.element_settings.common.source_object_connected_to_the_${originObjectI18nKey}`}
        components={[
          <ViewSourceBanner.ObjectName
            key="0"
            objectName={viewSourceObject.inflections.singular}
          />,
          <ViewSourceBanner.ObjectName key="1" objectName={connectedObject.inflections.singular} />
        ]}
        values={{
          objectName: viewSourceObject.inflections.singular,
          connectedObjectName: connectedObject.inflections.singular
        }}
      />
    );
  }

  return defaultSourceDescription;
}

export function CommonSourceBanner({ view }: { view: ViewsWithSource }) {
  const sourceDescription = useGetSourceDescription(view);

  return <ViewSourceBanner className="mb-4">{sourceDescription}</ViewSourceBanner>;
}
