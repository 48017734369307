import { t } from 'i18next';

import { type MenuView } from '@/types/schema/views/MenuView';
import { generateNewViewKey } from './generateNewViewKey';

export const baseMenuViewSchema: Partial<MenuView> = {
  type: 'menu',
  format: 'none',
  description: '',
  links: []
};

export function getMenuViewSchema() {
  return {
    ...baseMenuViewSchema,
    key: generateNewViewKey(),
    name: t('views.new_view_defaults.menu.name'),
    label: t('views.new_view_defaults.menu.label')
  };
}
