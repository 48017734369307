import { useEffect, useRef } from 'react';
import { Controller, useFormContext, type Path } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Form, Input, Label, RichTextEditor } from '@knack/asterisk-react';
import { type Editor } from '@tiptap/react';

import { type KnackObject } from '@/types/schema/KnackObject';
import { type RecordRule, type RecordRuleEmail } from '@/types/schema/rules/RecordRule';
import { useEmailTemplateHelpers } from '@/hooks/helpers/useEmailTemplatesHelpers';
import { cn } from '@/utils/tailwind';
import { EmailTemplateFieldSelector } from '@/components/EmailTemplateFieldSelector';
import { FormErrorMessage } from '@/components/errors/FormErrorMessage';
import { RecordRuleEmailRecipients } from '@/components/record-rule/RecordRuleEmailRecipients';

interface RecordRuleCustomEmailFieldsProps {
  sourceObject: KnackObject;
}

export function RecordRuleCustomEmailFields({ sourceObject }: RecordRuleCustomEmailFieldsProps) {
  const [t] = useTranslation();

  const { getTemplateWithNameVariables } = useEmailTemplateHelpers();

  const editorRef = useRef<Editor | null>(null);

  const {
    register,
    control,
    getValues,
    setValue,
    getFieldState,
    formState: { errors }
  } = useFormContext<RecordRule>();

  const hasThisFieldError = (fieldName: Path<RecordRuleEmail>) =>
    getFieldState(`email.${fieldName}`).error;

  // When this component first loads, we want to set the value of the email subject and message with the field keys converted to field names
  useEffect(() => {
    const emailSubject = getValues('email.subject');
    const emailMessage = getValues('email.message');

    const subjectWithFieldNames = emailSubject
      ? getTemplateWithNameVariables(emailSubject, sourceObject.fields)
      : '';
    const messageWithFieldNames = emailMessage
      ? getTemplateWithNameVariables(emailMessage, sourceObject.fields)
      : '';

    setValue('email.subject', subjectWithFieldNames);
    setValue('email.message', messageWithFieldNames);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form.Section>
      <div className="flex flex-col gap-4">
        <div className="flex w-full gap-2">
          <div className="flex w-1/2 flex-col">
            <Label htmlFor="send-custom-email-from-name-input" className="mb-2 font-medium">
              {t('components.rules.record_rules.email.senders_name')}
            </Label>
            <Input
              id="send-custom-email-from-name-input"
              data-testid="send-custom-email-from-name-input"
              className="w-full"
              intent={hasThisFieldError('from_name') ? 'destructive' : undefined}
              {...register(`email.from_name`)}
            />
          </div>
          <div className="flex w-1/2 flex-col">
            <Label htmlFor="send-custom-email-from-email-input" className="mb-2 font-medium">
              {t('components.rules.record_rules.email.senders_email')}
            </Label>
            <Input
              id="send-custom-email-from-email-input"
              data-testid="send-custom-email-from-email-input"
              className="w-full"
              intent={hasThisFieldError('from_email') ? 'destructive' : undefined}
              {...register(`email.from_email`)}
            />
            <FormErrorMessage name="email.from_email" className="mt-1" errors={errors} />
          </div>
        </div>
        <RecordRuleEmailRecipients sourceObject={sourceObject} />
        <div className="flex flex-col">
          <Label htmlFor="send-custom-email-subject" className="text-sm font-medium">
            {t('keywords.subject')}
          </Label>
          <Controller
            control={control}
            name="email.subject"
            render={({ field: { value, onChange } }) => (
              <>
                <div
                  className={cn(
                    'mt-2 flex items-center rounded-lg border border-default hover:border-emphasis',
                    {
                      'border-destructive hover:border-destructive': hasThisFieldError('subject')
                    }
                  )}
                >
                  <Input
                    id="send-custom-email-subject"
                    data-testid="send-custom-email-subject-input"
                    className="w-full rounded-r-none border-0 focus:outline-0"
                    intent={hasThisFieldError('subject') ? 'destructive' : undefined}
                    value={value}
                    onChange={onChange}
                  />
                  <EmailTemplateFieldSelector
                    className="rounded-l-none bg-input"
                    availableFields={sourceObject.fields}
                    onSelectField={(selectedField) => {
                      onChange(`${value}{${selectedField.name}}`);
                    }}
                  />
                </div>
                <FormErrorMessage name="email.subject" errors={errors} className="mt-1" />
              </>
            )}
          />
        </div>
        <div className="flex flex-col gap-2">
          <Label htmlFor="send-custom-email-message" className="font-medium">
            {t('keywords.message')}
          </Label>
          <Controller
            control={control}
            name="email.message"
            render={({ field: { value, onChange } }) => (
              <RichTextEditor
                content={value}
                data-testid="send-custom-email-message"
                editorProps={{
                  attributes: {
                    'data-testid': 'send-custom-email-message-rich-text'
                  }
                }}
                toolbarChildren={
                  <EmailTemplateFieldSelector
                    contentAlign="start"
                    className="bg-transparent hover:border-0"
                    shouldIncludeSystemVariables
                    availableFields={sourceObject.fields}
                    onSelectField={(selectedField) => {
                      editorRef.current?.commands.insertContent(`{${selectedField.name}}`);
                    }}
                  />
                }
                onCreate={({ editor }) => {
                  editorRef.current = editor;

                  const messageWithFieldNames = value
                    ? getTemplateWithNameVariables(value, sourceObject.fields)
                    : '';

                  // We also want to set the initial content of the editor to the email message with the field keys converted to field names.
                  // There is a bug in the RichTextEditor component where the content is not updated after the initial render, since it maintains an internal state for it.
                  editor.commands.setContent(messageWithFieldNames);
                }}
                onUpdate={({ editor }) => {
                  const content = editor.isEmpty ? '' : editor.getHTML();
                  onChange(content);
                }}
              />
            )}
          />
        </div>
      </div>
    </Form.Section>
  );
}
