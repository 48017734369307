import { DateTime } from 'luxon';

import { type DateTimeFieldFormat } from '@/types/schema/fields/DateTimeField';
import { type KnackField } from '@/types/schema/KnackField';
import { type KnackObject } from '@/types/schema/KnackObject';
import { getOptionFromDefaultValue } from '@/components/field-settings/boolean/DefaultValue';
import {
  DEFAULT_LUXON_DATE,
  getLuxonDateFormatFromFieldFormat
} from '@/components/knack-date-picker/transformers/DateTimeFormatToLuxonFormat';
import { JsDateToKnackDateTransformer } from '@/components/knack-date-picker/transformers/JsDateToKnackDateTransformer';

export const getNormalizedDefaultTimeFormat = (time: string, fieldFormat: DateTimeFieldFormat) => {
  let format = 'HH:mm';

  if (fieldFormat.time_format === 'HH:MM am') {
    // The V3 saves the value without leading zeros, so we need to adjust the format
    // Ex.: 1:32AM
    format = time.length === 6 ? 'h:mma' : 'hh:mma';
  }

  return format;
};

export const getDefaultValueFromDateTimeField = (fieldFormat: DateTimeFieldFormat) => {
  let dateTime = DateTime.now();

  // If there's a default_date and default_type is "date", parse it.
  if (
    fieldFormat.date_format !== 'Ignore Date' &&
    fieldFormat?.default_type === 'date' &&
    fieldFormat?.default_date
  ) {
    const parsedDate = DateTime.fromFormat(
      fieldFormat.default_date,
      getLuxonDateFormatFromFieldFormat(fieldFormat.date_format) || DEFAULT_LUXON_DATE
    );
    if (parsedDate.isValid) {
      // Retain the current time but overwrite the year, month, and day.
      dateTime = dateTime.set({
        year: parsedDate.year,
        month: parsedDate.month,
        day: parsedDate.day
      });
    }
  }

  if (fieldFormat.time_format !== 'Ignore Time') {
    if (fieldFormat?.time_type === 'time' && fieldFormat?.default_time) {
      const luxonTimeFormat = getNormalizedDefaultTimeFormat(fieldFormat.default_time, fieldFormat);
      // If there's a default_time and time_type is "time", parse it.
      const parsedTime = DateTime.fromFormat(fieldFormat.default_time, luxonTimeFormat);
      if (parsedTime.isValid) {
        // Overwrite hour/minute but keep the date from above.
        dateTime = dateTime.set({
          hour: parsedTime.hour,
          minute: parsedTime.minute,
          second: 0,
          millisecond: 0
        });
      }
    }

    if (fieldFormat?.time_type === 'none') {
      dateTime = dateTime.set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0
      });
    }
  }

  const knackDate = JsDateToKnackDateTransformer(dateTime.toJSDate(), fieldFormat);
  return {
    value: knackDate?.date || '',
    rawValue: {
      ...knackDate,
      ...(fieldFormat.calendar && {
        to: { ...knackDate }
      })
    }
  };
};

export function getDefaultValueFromField(field: KnackField, table: KnackObject | null) {
  if (field?.type === 'boolean') {
    return {
      value: getOptionFromDefaultValue(field.format?.default, field.format?.format) || '',
      rawValue: field?.format?.default || false
    };
  }

  if (field?.type === 'multiple_choice') {
    if (field.format?.default === 'kn-blank') {
      return {
        value: '',
        rawValue: ''
      };
    }
    return {
      value: field?.format?.default || '',
      rawValue: field?.format?.default || ''
    };
  }

  if (field?.type === 'date_time') {
    return getDefaultValueFromDateTimeField(field?.format);
  }

  if (field?.type === 'user_roles') {
    // If the table is all users we want the default value to be empty
    if (table?.profile_key === 'all_users') {
      return {
        value: '',
        rawValue: []
      };
    }

    return {
      value: '',
      rawValue: [table?.profile_key]
    };
  }

  return {
    value: field?.default || '',
    rawValue: field?.default || ''
  };
}
