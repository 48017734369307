import { useMemo } from 'react';
import { Controller, useFormContext, type FieldValues, type Path } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Select } from '@knack/asterisk-react';
import get from 'lodash.get';

import { type MultipleChoiceField } from '@/types/schema/fields';
import { removeDuplicatesFromStringArray } from '@/utils/arrays';
import { cn } from '@/utils/tailwind';

interface MultipleChoiceFieldInputProps<FormSchema extends FieldValues> {
  field: MultipleChoiceField;
  formFieldName: Path<FormSchema>;
  isDisabled?: boolean;
}

export function MultipleChoiceFieldInput<FormSchema extends FieldValues>({
  field,
  formFieldName,
  isDisabled
}: MultipleChoiceFieldInputProps<FormSchema>) {
  const [t] = useTranslation();
  const {
    formState: { errors }
  } = useFormContext();

  const optionsWithoutDuplicates = useMemo(
    () => removeDuplicatesFromStringArray(field.format.options),
    [field.format.options]
  );

  return (
    <Controller
      name={formFieldName}
      render={({ field: { value, onChange } }) => {
        const isMultipleChoiceValueValid = field.format.options.includes(value);

        return (
          <Select
            value={isMultipleChoiceValueValid ? value : undefined}
            onValueChange={onChange}
            disabled={isDisabled}
          >
            <Select.Trigger
              placeholder={t('actions.select')}
              className={cn('w-full', {
                'border-destructive hover:border-destructive focus:border-destructive focus:outline-destructive':
                  get(errors, formFieldName)
              })}
            />
            <Select.Content>
              {optionsWithoutDuplicates.map((option) => (
                <Select.Item key={option} value={option}>
                  {option}
                </Select.Item>
              ))}
            </Select.Content>
          </Select>
        );
      }}
    />
  );
}
