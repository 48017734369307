import { useEffect } from 'react';
import { FormProvider, useForm, useFormContext, useWatch, type FieldErrors } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { type BuilderViewActionRule } from '@/types/schema/BuilderView';
import { type KnackObject } from '@/types/schema/KnackObject';
import { type FormViewSubmitRule } from '@/types/schema/views/FormView';
import { usePagesQuery } from '@/hooks/api/queries/usePagesQuery';
import { SubmitRuleFormFields } from '@/components/submit-rule/SubmitRuleFormFields';
import { usePageEditorContext } from '@/pages/pages/page-editor/PageEditorContext';

interface DynamicActionSubmitRuleProps {
  sourceObject: KnackObject;
  submitRule: FormViewSubmitRule;
}

export function DynamicActionSubmitRule({
  sourceObject,
  submitRule
}: DynamicActionSubmitRuleProps) {
  const { data: pages = [] } = usePagesQuery();
  const { page: activePage } = usePageEditorContext();

  const parentForm = useFormContext<BuilderViewActionRule>();
  const parentFormErrors = parentForm.formState.errors
    .submit_rules?.[0] as FieldErrors<FormViewSubmitRule>;

  // Submit rule form schema without validation - Validation comes from the parent component
  const submitRuleFormSchema = z.custom<FormViewSubmitRule>();

  const initialSubmitRuleValues = {
    ...submitRule
  };

  const form = useForm<FormViewSubmitRule>({
    resolver: zodResolver(submitRuleFormSchema),
    defaultValues: submitRule,
    errors: parentFormErrors
  });

  const formData = useWatch<FormViewSubmitRule>({ control: form.control });

  const onChangeActionSubmitRule = (updatedSubmitRule: FormViewSubmitRule) => {
    parentForm.setValue('submit_rules', [updatedSubmitRule], {
      shouldDirty: true
    });
  };

  useEffect(() => {
    if (formData) {
      onChangeActionSubmitRule(formData as FormViewSubmitRule);
    }
    parentForm.clearErrors('submit_rules');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  useEffect(() => {
    if (!parentFormErrors) {
      form.clearErrors();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentFormErrors]);

  return (
    <FormProvider {...form}>
      <SubmitRuleFormFields
        pages={pages}
        activePage={activePage}
        submitRule={submitRule}
        sourceObject={sourceObject}
        initialSubmitRuleValues={initialSubmitRuleValues}
        isActionSubmitRule
      />
    </FormProvider>
  );
}
