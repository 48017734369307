import { useRestrictedConnectionHelpers } from '@/pages/pages/settings-panel/view-settings/common/filtering/restricted-connections/useRestrictedConnectionHelpers';
import {
  ViewSettingsFiltering,
  type UpdateViewSchemaOptions
} from '@/pages/pages/settings-panel/view-settings/common/filtering/ViewSettingsFiltering';
import { useActiveChartContext } from '@/pages/pages/settings-panel/view-settings/report/chart-settings/ActiveChartContext';

export function ChartFilteringSettings() {
  const { chart, chartSourceObject, updateChart } = useActiveChartContext();
  const { getReportRestrictedConnectionFields } = useRestrictedConnectionHelpers();

  const { filter_connection_sources: filterConnectionSources, ...restOptions } = chart.filters;

  const restrictedConnectionFields = getReportRestrictedConnectionFields(chart);

  const handleUpdateChartFilters = (options: Partial<UpdateViewSchemaOptions>) => {
    updateChart({
      filters: {
        ...chart.filters,
        ...options
      }
    });
  };

  const chartFilterOptions = {
    ...restOptions,
    ...(filterConnectionSources && {
      filter_connection_sources: filterConnectionSources
    })
  };

  return (
    <ViewSettingsFiltering
      sourceObject={chartSourceObject}
      options={chartFilterOptions}
      updateViewSchema={handleUpdateChartFilters}
      restrictedConnectionFields={restrictedConnectionFields}
    />
  );
}
