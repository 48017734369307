import { useTranslation } from 'react-i18next';
import { Input, Label, Switch } from '@knack/asterisk-react';

import { type DetailsViewInput } from '@/types/schema/views/DetailsView';

interface MapSettingsProps {
  fieldInput: DetailsViewInput;
  updateDetailsColumn: (updatedData: Partial<DetailsViewInput>) => void;
}

export function MapSettings({ fieldInput, updateDetailsColumn }: MapSettingsProps) {
  const [t] = useTranslation();

  return (
    <div className="mb-4">
      <Label className="mb-2 block">
        {t(
          'pages.element_settings.common.categories.data_display.field_management.edit_field_modal.map'
        )}
      </Label>
      <div>
        <div className="flex">
          <Switch
            id={`field-show-map-${fieldInput.key}`}
            checked={fieldInput.show_map}
            onCheckedChange={(checked) => {
              updateDetailsColumn({
                show_map: checked
              });
            }}
          />
          <div className="ml-2 flex w-full flex-col gap-2">
            <Label htmlFor={`field-show-map-${fieldInput.key}`}>
              {t(
                'pages.element_settings.common.categories.data_display.field_management.edit_field_modal.map_show_address'
              )}
            </Label>
            {fieldInput.show_map && (
              <div>
                <Label className="mb-2 block" htmlFor={`field-map-height-${fieldInput.key}`}>
                  {t(
                    'pages.element_settings.common.categories.data_display.field_management.edit_field_modal.map_height'
                  )}
                </Label>
                <div>
                  <div className="relative flex items-center">
                    <Input
                      id={`field-map-height-${fieldInput.key}`}
                      type="number"
                      value={fieldInput.map_height}
                      onChange={(e) =>
                        updateDetailsColumn({
                          map_height: Number(e.target.value)
                        })
                      }
                      data-testid="input-field-map-height"
                    />
                    <div className="absolute right-0.5 flex h-9 items-center rounded-r-lg bg-muted px-3 text-subtle">
                      {t(
                        'pages.element_settings.common.categories.data_display.field_management.edit_field_modal.map_height_pixels'
                      )}
                    </div>
                  </div>
                  <p className="mt-2 text-xs text-subtle">
                    {t(
                      'pages.element_settings.common.categories.data_display.field_management.edit_field_modal.map_height_hint'
                    )}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
