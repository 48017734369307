import { t } from 'i18next';
import { z } from 'zod';

import { type KnackField } from '@/types/schema/KnackField';
import { getAddressSchema } from '@/components/data-table/display/fields/address/AddressSchema';
import { getCurrencySchema } from '@/components/data-table/display/fields/currency/CurrencySchema';
import { getEmailSchema } from '@/components/data-table/display/fields/email/EmailSchema';
import { getFileSchema } from '@/components/data-table/display/fields/file/FileSchema';
import { getImageSchema } from '@/components/data-table/display/fields/image/ImageSchema';
import { getLinkSchema } from '@/components/data-table/display/fields/link/LinkSchema';
import { getNameSchema } from '@/components/data-table/display/fields/name/NameSchema';
import { getNumberSchema } from '@/components/data-table/display/fields/number/NumberSchema';
import { getParagraphTextSchema } from '@/components/data-table/display/fields/paragraph-text/ParagraphTextSchema';
import { getPasswordSchema } from '@/components/data-table/display/fields/password/PasswordSchema';
import { getRichTextSchema } from '@/components/data-table/display/fields/rich-text/RichTextSchema';
import { getShortTextSchema } from '@/components/data-table/display/fields/short-text/ShortTextSchema';
import { type DataTableRowValue } from '@/components/data-table/display/types';
import { getKnackDatePickerSchema } from '@/components/knack-date-picker/KnackDatePickerSchema';

export const generateEditRecordFormSchema = (
  record: { [key: string]: DataTableRowValue },
  knackFields: KnackField[]
) => {
  const schemaFields: Record<string, any> = {};

  Object.entries(record).forEach(([key]) => {
    const field = knackFields.find((f) => f.key === key);
    if (!field) return;

    switch (field.type) {
      case 'short_text':
        schemaFields[key] = getShortTextSchema(field).shape.shortText;
        break;
      case 'number':
        schemaFields[key] = getNumberSchema(field).shape.number;
        break;
      case 'paragraph_text':
        schemaFields[key] = getParagraphTextSchema(field).shape.paragraph;
        break;
      case 'rich_text':
        schemaFields[key] = getRichTextSchema(field).shape.richText;
        break;
      case 'name':
        schemaFields[key] = getNameSchema(field);
        break;
      case 'link':
        schemaFields[key] = getLinkSchema(field);
        break;
      case 'address':
        schemaFields[key] = getAddressSchema(field);
        break;
      case 'currency':
        schemaFields[key] = getCurrencySchema(field).shape.currency;
        break;
      case 'email':
        schemaFields[key] = getEmailSchema(field);
        break;
      case 'rating':
        schemaFields[key] = z.union([z.number(), z.string().length(0)]);
        break;
      case 'file':
        schemaFields[key] = getFileSchema(field);
        break;
      case 'image':
        schemaFields[key] = getImageSchema(field);
        break;
      case 'password':
        schemaFields[key] = getPasswordSchema(field);
        break;
      case 'date_time':
        schemaFields[key] = getKnackDatePickerSchema(field, t);
        break;
      default:
        schemaFields[key] = z.any();
        break;
    }
  });

  return z.object(schemaFields);
};

export type EditRecordFormSchema = z.infer<ReturnType<typeof generateEditRecordFormSchema>>;
