import { type DateTimePickerProps } from '@knack/asterisk-react/dist/src/components/date-time-picker/DateTimePicker';
import { z } from 'zod';

export const getDatePickerDateSchema = (t: (key: string) => string, isRequired: boolean) => {
  const dateSchema = z.date({
    required_error: t('errors.date_is_required'),
    invalid_type_error: t('errors.date_is_invalid')
  });
  return isRequired ? dateSchema : dateSchema.optional();
};

export const getDatePickerTimeSchema = (t: (key: string) => string, isRequired: boolean) => {
  const dateSchema = z.date({
    required_error: t('errors.time_is_required'),
    invalid_type_error: t('errors.time_is_invalid')
  });
  return isRequired ? dateSchema : dateSchema.optional();
};

const getDatePickerDateRangeSchema = (t: (key: string) => string, isRequired: boolean) => {
  const fromSchema = z.date({
    required_error: t('errors.start_date_is_required'),
    invalid_type_error: t('errors.start_date_is_invalid')
  });

  const toSchema = z.date({
    required_error: t('errors.end_date_is_required'),
    invalid_type_error: t('errors.end_date_is_invalid')
  });

  return z
    .object(
      {
        from: isRequired ? fromSchema : fromSchema.optional(),
        to: isRequired ? toSchema : toSchema.optional()
      },
      {
        required_error: t('errors.date_is_required')
      }
    )
    .superRefine(({ from, to }, context) => {
      if (from && to && to < from) {
        context.addIssue({
          code: 'custom',
          message: t('errors.date_range_invalid'),
          path: ['to']
        });
      }
    });
};

export const getDatePickerZodSchema = (
  mode: DateTimePickerProps['mode'],
  format: DateTimePickerProps['format'],
  t: (key: string) => string,
  isRequired: boolean
) => {
  if (mode === 'range') {
    return getDatePickerDateRangeSchema(t, isRequired);
  }

  if (!format.date) {
    return getDatePickerTimeSchema(t, isRequired);
  }

  return getDatePickerDateSchema(t, isRequired);
};
