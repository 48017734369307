import { useTranslation } from 'react-i18next';
import { HiUserGroup as UsersIcon } from 'react-icons/hi';
import {
  HiDocumentDuplicate as PagesIcon,
  HiCreditCard as PaymentsIcon,
  HiCog6Tooth as SettingsIcon,
  HiTableCells as TableIcon,
  HiPaintBrush as ThemeIcon
} from 'react-icons/hi2';
import { MdMoveDown as FlowsIcon } from 'react-icons/md';
import { generatePath, Link, useLocation } from 'react-router-dom';
import { Button, Tooltip } from '@knack/asterisk-react';
import kebabCase from 'lodash.kebabcase';

import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { useSessionHelpers } from '@/hooks/helpers/useSessionHelpers';
import { FEATURE_FLAGS, isFlagEnabled } from '@/utils/flagsmith';
import { cn } from '@/utils/tailwind';
import { useLastVisitedStore } from '@/pages/tables/useLastVisitedStore';
import { ROUTES } from '@/Router';
import { NavItemProfile } from './NavItemProfile';

export function LeftSidebar() {
  const [t] = useTranslation();
  const { pathname } = useLocation();
  const { data: app } = useApplicationQuery();
  const lastVisitedTableKey = useLastVisitedStore((state) => state.lastVisitedTableKey);
  const lastVisitedRoleKey = useLastVisitedStore((state) => state.lastVisitedRoleKey);
  const { isSharedBuilder } = useSessionHelpers();
  const tables = app?.objects.filter((table) => table.type === 'StandardObject') || [];
  const roles = app?.objects.filter((table) => table.profile_key === 'all_users') || [];

  const isLastVisitedTableAvailable = tables.some((table) => table.key === lastVisitedTableKey);

  const sidebarButtons = [
    {
      icon: TableIcon,
      text: t('components.layout.left_sidebar.tables'),
      link: generatePath(ROUTES.TABLES_ID, {
        id: isLastVisitedTableAvailable ? lastVisitedTableKey : (tables[0]?.key ?? '')
      }),
      isActive: pathname.startsWith(ROUTES.TABLES),
      disabled: false
    },
    {
      icon: UsersIcon,
      text: t('components.layout.left_sidebar.users'),
      link: generatePath(ROUTES.ROLES_ID, {
        id: (lastVisitedRoleKey || roles[0]?.key) ?? ''
      }),
      isActive: pathname.startsWith(ROUTES.ROLES)
    },
    {
      icon: PagesIcon,
      text: t('components.layout.left_sidebar.pages'),
      link: ROUTES.PAGES,
      isActive: pathname.startsWith(ROUTES.PAGES)
    },
    {
      icon: ThemeIcon,
      text: t('components.layout.left_sidebar.themes'),
      link: ROUTES.THEMES,
      isActive: pathname.startsWith(ROUTES.THEMES)
    },
    ...(!isSharedBuilder()
      ? [
          {
            icon: FlowsIcon,
            text: t('components.layout.left_sidebar.flows'),
            link: ROUTES.FLOWS,
            isActive: pathname.startsWith(ROUTES.FLOWS),
            disabled: !isFlagEnabled(FEATURE_FLAGS.albato_flows)
          }
        ]
      : []),
    {
      icon: PaymentsIcon,
      text: t('components.layout.left_sidebar.payments'),
      link: ROUTES.PAYMENTS,
      isActive: pathname.startsWith(ROUTES.PAYMENTS),
      disabled: !isFlagEnabled(FEATURE_FLAGS.fe_payments),
      tooltip: t('keywords.payments_contact_support')
    },
    {
      icon: SettingsIcon,
      text: t('components.layout.left_sidebar.settings'),
      link: ROUTES.SETTINGS,
      isActive: pathname.startsWith(ROUTES.SETTINGS)
    }
  ];

  return (
    <aside
      data-testid="left-sidebar"
      className="flex min-w-[56px] flex-col justify-between bg-muted"
    >
      <div className="flex flex-col gap-1 px-1">
        {sidebarButtons.map((button) => (
          <Tooltip key={button.text}>
            <Tooltip.Trigger asChild>
              <Button
                key={button.link}
                asChild
                intent="minimal"
                className={cn(
                  'group size-12 rounded-lg text-default hover:bg-brand-100 focus:outline-0',
                  {
                    'focus:bg-brand-100': !button.disabled,
                    'cursor-not-allowed opacity-50 hover:bg-muted': button.disabled,
                    'bg-brand-100': button.isActive && !button.disabled
                  }
                )}
                data-testid={`${kebabCase(button.text.toLowerCase())}-navigation-button`}
              >
                <Link
                  to={button.link}
                  aria-disabled={button.disabled}
                  onClick={(e) => {
                    if (button.disabled) {
                      e.preventDefault();
                    }
                  }}
                >
                  <button.icon
                    size={24}
                    className={cn({
                      'fill-[url(#svg-gradient-1)]': button.isActive,
                      'group-hover:fill-[url(#svg-gradient-1)] group-focus:fill-[url(#svg-gradient-1)]':
                        !button.disabled
                    })}
                  />
                  <span className="sr-only">{button.text}</span>
                </Link>
              </Button>
            </Tooltip.Trigger>
            <Tooltip.Content side="right" sideOffset={8} className={cn('z-20')}>
              {button.disabled
                ? button.tooltip ||
                  t('keywords.feature_coming_soon', {
                    feature: button.text
                  })
                : button.text}
            </Tooltip.Content>
          </Tooltip>
        ))}
      </div>
      <div className="flex items-center justify-center py-3">
        <NavItemProfile />
      </div>
    </aside>
  );
}
