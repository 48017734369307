import { useTranslation } from 'react-i18next';
import { Input, Label, RadioGroup, Select } from '@knack/asterisk-react';

import { type BuilderViewLinkColumnType } from '@/types/schema/BuilderView';
import { type KnackFieldKey } from '@/types/schema/KnackField';
import { type TableView } from '@/types/schema/views/TableView';
import { type ViewWithDetails } from '@/types/schema/views/ViewWithDetails';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';

type FieldSectionOptions = {
  link_type: BuilderViewLinkColumnType;
  link_text: string;
  key?: KnackFieldKey;
};

interface ViewColumnSettingsFieldSectionProps {
  linkType: BuilderViewLinkColumnType;
  linkText: string;
  fieldKey?: KnackFieldKey;
  onUpdate: (data: Partial<FieldSectionOptions>) => void;
}

export function ViewColumnSettingsLabelSection({
  linkType,
  linkText,
  fieldKey,
  onUpdate
}: ViewColumnSettingsFieldSectionProps) {
  const [t] = useTranslation();
  const { sourceObject } = useActiveViewContext<TableView | ViewWithDetails>();

  const handleChangeLinkType = (type: BuilderViewLinkColumnType) => {
    // When we first switch from "text" to "field", we don't have the field key
    const shouldUpdateFieldKey = type === 'field' && !fieldKey;

    onUpdate({
      link_type: type,
      ...(shouldUpdateFieldKey && { key: sourceObject?.fields[0].key })
    });
  };

  if (!sourceObject) {
    return null;
  }

  return (
    <div>
      <Label>
        {t('pages.element_settings.common.categories.data_display.field_management.link_type')}
      </Label>
      <RadioGroup onValueChange={handleChangeLinkType} value={linkType} className="mt-2">
        <RadioGroup.Container>
          <RadioGroup.Item value="text" id="text" />
          <Label htmlFor="text">{t('keywords.text')}</Label>
        </RadioGroup.Container>
        <RadioGroup.Container>
          <RadioGroup.Item value="field" id="field" />
          <Label htmlFor="field">{t('keywords.field')}</Label>
        </RadioGroup.Container>
      </RadioGroup>
      {linkType === 'text' ? (
        <div className="mt-4">
          <Label>
            {t(
              'pages.element_settings.common.categories.data_display.field_management.button_text'
            )}
            <Input
              data-testid="link-text-input"
              className="mt-2"
              value={linkText}
              onChange={(e) => onUpdate({ link_text: e.target.value })}
            />
          </Label>
        </div>
      ) : (
        <Select
          value={fieldKey ?? sourceObject.fields[0].key}
          onValueChange={(key: KnackFieldKey) => onUpdate({ key })}
        >
          <Select.Trigger className="mt-2 w-full" data-testid="link-type-field-select" />
          <Select.Content>
            {sourceObject.fields.map((field) => (
              <Select.Item key={field.key} value={field.key}>
                {field.name}
              </Select.Item>
            ))}
          </Select.Content>
        </Select>
      )}
    </div>
  );
}
