import { FormProvider, useForm, type FieldErrors } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { type BuilderPage } from '@/types/schema/BuilderPage';
import { type KnackObject } from '@/types/schema/KnackObject';
import {
  type RecordRule,
  type RecordRuleActionType,
  type RecordRuleValueType
} from '@/types/schema/rules/RecordRule';
import { useRecordRuleHelpers } from '@/hooks/helpers/useRecordRuleHelpers';
import { type RecordRuleFormFieldsLabels } from '@/components/record-rule/helpers';
import { RecordRuleFormFields } from '@/components/record-rule/RecordRuleFormFields';

interface RecordRuleFormProps {
  recordRule: RecordRule;
  sourceObject: KnackObject;
  formId: string;
  onSubmit: (updatedRecordRule: RecordRule) => void;
  sourcePage?: BuilderPage;
  recordRuleActionTypes?: RecordRuleActionType[];
  recordRuleAllowedValueTypes?: RecordRuleValueType[];
  canCriteriaValuesBeField?: boolean;
  labels?: Partial<RecordRuleFormFieldsLabels>;
  errors?: FieldErrors<RecordRule>;
}

export function RecordRuleForm({
  recordRule,
  sourceObject,
  formId,
  onSubmit,
  sourcePage,
  recordRuleActionTypes,
  recordRuleAllowedValueTypes,
  canCriteriaValuesBeField,
  labels,
  errors
}: RecordRuleFormProps) {
  const { getRecordRuleFormSchema } = useRecordRuleHelpers();

  const recordRuleFormSchema = getRecordRuleFormSchema(sourceObject);

  const form = useForm<RecordRule>({
    resolver: zodResolver(recordRuleFormSchema),
    defaultValues: recordRule,
    errors
  });

  const handleSubmit = (updatedRecordRule: RecordRule) => {
    onSubmit(updatedRecordRule);
  };

  return (
    <FormProvider {...form}>
      <form id={formId} className="w-full" onSubmit={form.handleSubmit(handleSubmit)}>
        <RecordRuleFormFields
          recordRule={recordRule}
          sourceObject={sourceObject}
          sourcePage={sourcePage}
          recordRuleActionTypes={recordRuleActionTypes}
          recordRuleAllowedValueTypes={recordRuleAllowedValueTypes}
          canCriteriaValuesBeField={canCriteriaValuesBeField}
          labels={labels}
        />
      </form>
    </FormProvider>
  );
}
