import { type KnackField } from '@/types/schema/KnackField';
import { type UpdateRecordPayload } from '@/hooks/api/mutations/useUpdateRecordMutation';
import { type EditRecordFormSchema } from '@/components/data-table/display/edit-record/EditRecordSchema';
import {
  formatKnackDateForUpdating,
  getNormalizedTimeObject
} from '@/components/data-table/display/edit-record/utils';
import { type DateTimeRawValue } from '@/components/data-table/display/fields/Field';
import { type DataTableRowValue } from '@/components/data-table/display/types';
import { getDefaultValue } from '@/components/import/ConfirmImport';
import { knacDatePickerTransformers } from '@/components/knack-date-picker/KnackDatePickerTransformers';

export function transformFormStateToServerData(
  formData: EditRecordFormSchema,
  fields: KnackField[]
): UpdateRecordPayload['data'] {
  const transformedData = Object.fromEntries(
    Object.entries(formData).map(([key, value]) => {
      const currentField = fields.find((f) => f.key === key);
      if (!currentField) return [key, value];
      if (currentField.type === 'date_time' && value) {
        return [
          key,
          formatKnackDateForUpdating(
            knacDatePickerTransformers.toServer(value, currentField.format)
          )
        ];
      }

      return [key, value];
    })
  );

  return transformedData;
}

export function transformServerDataToFormState(
  record: { [key: string]: DataTableRowValue },
  fields: KnackField[]
): EditRecordFormSchema {
  return Object.fromEntries(
    Object.entries(record).map(([key, field]) => {
      const currentField = fields.find((f) => f.key === key);
      if (!currentField) return [key, field.rawValue];
      if (currentField && currentField.type === 'date_time' && field.rawValue) {
        const dateTimeValue = field.rawValue as DateTimeRawValue;

        return [key, knacDatePickerTransformers.fromServer(dateTimeValue, currentField.format)];
      }
      if (currentField && currentField.type === 'number' && field.rawValue) {
        if (currentField.format?.mark_decimal === 'comma') {
          return [key, field.rawValue.toString().replace('.', ',')];
        }
        return [key, field.rawValue.toString()];
      }
      if (currentField && currentField.type === 'phone' && field.value) {
        return [key, field.value.toString()];
      }
      if (currentField && currentField.type === 'timer' && field.rawValue) {
        const rawValueWithTimes = field.rawValue as {
          times: Array<{
            from: { hours: string; minutes: string };
            to: { hours: string; minutes: string };
          }>;
        };

        return [
          key,
          {
            ...rawValueWithTimes,
            times: rawValueWithTimes.times.map((time) => ({
              from: getNormalizedTimeObject(time.from),
              to: getNormalizedTimeObject(time.to)
            }))
          }
        ];
      }

      if (currentField && currentField.type === 'password') {
        return [key, { password: field.rawValue, password_confirmation: field.rawValue }];
      }

      return [key, field.rawValue || getDefaultValue(currentField)];
    })
  );
}

export const editRecordTransformers = {
  toServer: transformFormStateToServerData,
  fromServer: transformServerDataToFormState
};
