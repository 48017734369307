import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  Dialog,
  Label,
  MultiSelect,
  RadioGroup,
  useToast,
  type MultiSelectOption
} from '@knack/asterisk-react';
import { z } from 'zod';

import { type BuilderPage } from '@/types/schema/BuilderPage';
import { type KnackObjectProfileKey } from '@/types/schema/KnackObject';
import { LOGIN_VIEW_REGISTRATION_TYPES, type LoginView } from '@/types/schema/views/LoginView';
import { usePageMutation } from '@/hooks/api/mutations/usePageMutation';
import { useObjectHelpers } from '@/hooks/helpers/useObjectHelpers';
import { useTrackEventsHelpers } from '@/hooks/helpers/useTrackEventsHelpers';

interface AddLoginFormProps {
  page: BuilderPage;
  closeDialog: () => void;
}

const addLoginToPageFormSchema = z.object({
  registrationType: z.custom<LoginView['registrationType']>(),
  limitProfileAccess: z.boolean(),
  allowedProfileKeys: z.custom<LoginView['allowedProfileKeys']>()
});

type AddLoginToPageFormSchemaType = z.infer<typeof addLoginToPageFormSchema>;

export function AddLoginForm({ page, closeDialog }: AddLoginFormProps) {
  const [t] = useTranslation();
  const { presentToast } = useToast();
  const { trackEvent } = useTrackEventsHelpers();
  const { addLoginMutation } = usePageMutation();
  const { getRoleObjects } = useObjectHelpers();

  const userRoleObjects = getRoleObjects();
  const userRoleOptions: MultiSelectOption[] = userRoleObjects.map((role) => ({
    key: role.profile_key as string,
    label: role.name
  }));

  const { control, watch, handleSubmit } = useForm<AddLoginToPageFormSchemaType>({
    resolver: zodResolver(addLoginToPageFormSchema),
    defaultValues: {
      registrationType: 'open',
      limitProfileAccess: false,
      allowedProfileKeys: []
    }
  });

  const { limitProfileAccess } = watch();

  const onSubmit = (data: AddLoginToPageFormSchemaType) => {
    addLoginMutation.mutate(
      {
        pageKey: page.key,
        registrationType: data.registrationType,
        limitProfileAccess: data.limitProfileAccess,
        allowedProfiles: data.allowedProfileKeys
      },
      {
        onSuccess: () => {
          trackEvent('add_login_to_page', {
            userRolesCount: data.allowedProfileKeys.includes('all_users')
              ? 0
              : data.allowedProfileKeys.length
          });
          closeDialog();
        },
        onError: () => {
          presentToast({
            title: t('errors.generic_error'),
            intent: 'destructive'
          });
        }
      }
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <Label
          htmlFor="add-login-to-page-registration-type"
          className="mb-2 inline-block font-medium"
        >
          {t(`pages.settings.access.can_new_users_register`)}
        </Label>
        <Controller
          control={control}
          name="registrationType"
          render={({ field: { onChange, value } }) => (
            <RadioGroup
              id="add-login-to-page-registration-type"
              value={value}
              onValueChange={onChange}
            >
              {LOGIN_VIEW_REGISTRATION_TYPES.map((registrationType) => (
                <RadioGroup.Container key={registrationType}>
                  <RadioGroup.Item id={registrationType} value={registrationType} />
                  <Label htmlFor={registrationType}>
                    {t(`pages.settings.access.registration_type_labels.${registrationType}`)}
                  </Label>
                </RadioGroup.Container>
              ))}
            </RadioGroup>
          )}
        />
      </div>

      <div className="mt-4">
        <Label
          htmlFor="add-login-to-page-limit-profile-access"
          className="mb-2 inline-block font-medium"
        >
          {t(`pages.settings.access.which_user_roles_can_access`)}
        </Label>
        <Controller
          control={control}
          name="limitProfileAccess"
          render={({ field: { onChange, value } }) => (
            <RadioGroup
              id="add-login-to-page-limit-profile-access"
              value={String(value)}
              onValueChange={(newValue) => {
                onChange(newValue === 'true');
              }}
            >
              <RadioGroup.Container>
                <RadioGroup.Item id="add-login-to-page-limit-profile-access-false" value="false" />
                <Label htmlFor="add-login-to-page-limit-profile-access-false">
                  {t('pages.settings.access.all_logged_in_users')}
                </Label>
              </RadioGroup.Container>
              <RadioGroup.Container>
                <RadioGroup.Item id="add-login-to-page-limit-profile-access-true" value="true" />
                <Label htmlFor="add-login-to-page-limit-profile-access-true">
                  {t('pages.settings.access.specific_user_roles_only')}
                </Label>
              </RadioGroup.Container>
            </RadioGroup>
          )}
        />

        {limitProfileAccess && (
          <div className="mt-2 pl-6">
            <Controller
              control={control}
              name="allowedProfileKeys"
              render={({ field: { onChange, value: selectedProfileKeys } }) => {
                const selectedUserRoleOptions =
                  userRoleOptions.filter((option) =>
                    selectedProfileKeys?.includes(option.key as KnackObjectProfileKey)
                  ) || [];

                return (
                  <MultiSelect
                    id="add-login-to-page-user-roles"
                    options={userRoleOptions}
                    selectedOptions={selectedUserRoleOptions}
                    onSelectOptions={(newSelectedProfileKeys) => {
                      const selectedOptions = newSelectedProfileKeys.map(
                        (option) => option.key as KnackObjectProfileKey
                      );
                      onChange(selectedOptions);
                    }}
                  />
                );
              }}
            />
          </div>
        )}
      </div>

      <div className="mt-12 flex justify-end gap-2" data-testid="add-login-to-page-dialog-footer">
        <Dialog.Close asChild>
          <Button intent="minimal" data-testid="add-login-to-page-button-cancel">
            {t('actions.cancel')}
          </Button>
        </Dialog.Close>
        <Button
          type="submit"
          isLoading={addLoginMutation.isPending}
          data-testid="add-login-to-page-submit-button"
        >
          {t('pages.dialogs.add_login.submit_button')}
        </Button>
      </div>
    </form>
  );
}
