import { useTranslation } from 'react-i18next';
import { Input, Label } from '@knack/asterisk-react';

import { type CalendarView } from '@/types/schema/views/CalendarView';
import { BuilderAccordion } from '@/components/BuilderAccordion';
import { DEFAULT_COLOR_CRITERIA } from '@/pages/pages/page-editor/add-view/helpers/view-schemas/calendarViewSchema';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { CalendarCriteriaForm } from '@/pages/pages/settings-panel/view-settings/calendar/general/CalendarCriteriaForm';
import { DisplayRuleColorPicker } from '@/pages/pages/settings-panel/view-settings/common/display-rules/DisplayRuleColorPicker';
import { useUpdateView } from '@/pages/pages/settings-panel/view-settings/useUpdateView';

export function GeneralSettingsEventColor() {
  const { view } = useActiveViewContext<CalendarView>();
  const [t] = useTranslation();
  const updateViewSchema = useUpdateView();

  const handleDefaultEventColor = (color: string) => {
    updateViewSchema({ events: { ...view.events, event_color_default: color } });
  };

  return (
    <BuilderAccordion.Item
      isDefaultOpen
      label={t(
        'pages.element_settings.calendar.categories.general_settings.section_titles.event_color'
      )}
    >
      <div className="flex flex-col gap-4">
        <Label>
          {t('pages.element_settings.calendar.categories.general_settings.default_color')}
          <DisplayRuleColorPicker
            value={view.events.event_color_default || DEFAULT_COLOR_CRITERIA}
            onChange={handleDefaultEventColor}
          />
        </Label>
        <Label>
          {t('pages.element_settings.calendar.categories.general_settings.color_rules')}
          <Input.Hint>
            {t('pages.element_settings.calendar.categories.general_settings.rule_hint')}
          </Input.Hint>
        </Label>
        <CalendarCriteriaForm />
      </div>
    </BuilderAccordion.Item>
  );
}
