import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputSearch } from '@knack/asterisk-react';

import { type KnackField, type KnackFieldKey } from '@/types/schema/KnackField';
import { type DetailsViewInput } from '@/types/schema/views/DetailsView';
import { type ViewWithDetails } from '@/types/schema/views/ViewWithDetails';
import { useObjectHelpers } from '@/hooks/helpers/useObjectHelpers';
import { FieldIcon } from '@/components/FieldIcon';
import { generateDetailsViewFieldInput } from '@/pages/pages/page-editor/add-view/helpers/view-schemas/detailsViewSchema';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import {
  ViewFieldListCollapsible,
  type ViewFieldInput
} from '@/pages/pages/settings-panel/view-settings/common/view-field-list-collapsible/ViewFieldListCollapsible';
import { useUpdateViewColumns } from '@/pages/pages/settings-panel/view-settings/useUpdateViewColumns';
import { useAllVisibleFields } from './useAllVisibleFields';
import { ViewDetailsAddStaticInputButton } from './ViewDetailsAddStaticInputButton';

export function ViewDetailsFieldManagement() {
  const [t] = useTranslation();

  const { getObjectByKey } = useObjectHelpers();
  const { view, sourceObject } = useActiveViewContext<ViewWithDetails>();
  const updateViewColumnsSchema = useUpdateViewColumns<ViewWithDetails>();

  const allVisibleFields = useAllVisibleFields(sourceObject);

  const [searchValue, setSearchValue] = useState('');
  const [visibleFields, setVisibleFields] = useState<KnackField[]>(sourceObject.fields);

  const columns = useMemo(() => {
    if (view.type !== 'calendar' && view.type !== 'map') {
      return view.columns;
    }
    return view.details.columns;
  }, [view]);

  // The field inputs that have been already added to the view
  const activeViewFieldInputs: ViewFieldInput[] = useMemo(() => {
    const inputs: ViewFieldInput[] = [];
    columns.forEach((outerColumn) => {
      outerColumn.groups.forEach((group) => {
        group.columns.forEach((column) => {
          column.forEach((input) => {
            if (input.type === 'field') {
              inputs.push({
                id: input.id,
                fieldKey: input.key,
                connectionKey: input.connection?.key
              });
            }
          });
        });
      });
    });
    return inputs;
  }, [columns]);

  const onAddFieldInput = (field: KnackField, connectionFieldKey?: KnackFieldKey) => {
    const fieldInputToAdd = generateDetailsViewFieldInput(field, connectionFieldKey);

    const updatedView: Partial<ViewWithDetails> = {
      columns: columns.map((outerColumn, outerColumnIndex) => {
        // If the outer column is not the last one, we don't need to add the field input to it
        if (outerColumnIndex !== columns.length - 1) {
          return outerColumn;
        }

        return {
          ...outerColumn,
          // Add the new field input to the last column in the last group
          groups: outerColumn.groups.map((group, groupIndex) => {
            if (groupIndex === columns[0].groups.length - 1) {
              return {
                ...group,
                columns: group.columns.map((column, columnIndex) => {
                  if (columnIndex === group.columns.length - 1) {
                    return [...column, fieldInputToAdd];
                  }
                  return column;
                })
              };
            }
            return group;
          })
        };
      })
    };

    updateViewColumnsSchema(updatedView);
  };

  const onRemoveFieldInput = (field: KnackField, connectionFieldKey?: KnackFieldKey) => {
    const updatedView: Partial<ViewWithDetails> = {
      columns: columns.map((outerColumn) => ({
        ...outerColumn,
        groups: outerColumn.groups.map((group) => ({
          ...group,
          columns: group.columns.map((column) =>
            column.filter((fieldInput) =>
              connectionFieldKey
                ? !(
                    fieldInput.key === field.key &&
                    fieldInput.connection?.key === connectionFieldKey
                  )
                : fieldInput.key !== field.key
            )
          )
        }))
      }))
    };

    updateViewColumnsSchema(updatedView);
  };

  const onAddAllFieldInputs = (fieldsToAdd: KnackField[], connectionFieldKey?: KnackFieldKey) => {
    const inputsToAdd: DetailsViewInput[] = [];

    fieldsToAdd.forEach((field) => {
      // Fields inside connections can have the same key as other fields in other connections to the same table, so we check for the connection.key to differentiate them
      const shouldAddField = connectionFieldKey
        ? !activeViewFieldInputs.some(
            (input) => input.connectionKey === connectionFieldKey && input.fieldKey === field.key
          )
        : !activeViewFieldInputs.some((input) => input.fieldKey === field.key);

      if (shouldAddField) {
        inputsToAdd.push(generateDetailsViewFieldInput(field, connectionFieldKey));
      }
    });

    const updatedView: Partial<ViewWithDetails> = {
      columns: columns.map((outerColumn, outerColumnIndex) => {
        // If the outer column is not the last one, we don't need to add the field input to it
        if (outerColumnIndex !== columns.length - 1) {
          return outerColumn;
        }

        return {
          ...outerColumn,
          // Add the new field inputs to the last column in the last group
          groups: outerColumn.groups.map((group, groupIndex) => {
            if (groupIndex === columns[0].groups.length - 1) {
              return {
                ...group,
                columns: group.columns.map((column, columnIndex) => {
                  if (columnIndex === group.columns.length - 1) {
                    return [...column, ...inputsToAdd];
                  }
                  return column;
                })
              };
            }
            return group;
          })
        };
      })
    };

    updateViewColumnsSchema(updatedView);
  };

  const onRemoveAllFieldInputs = (
    fieldsToRemove: KnackField[],
    connectionFieldKey?: KnackFieldKey
  ) => {
    const updatedView: Partial<ViewWithDetails> = {
      columns: columns.map((outerColumn) => ({
        ...outerColumn,
        groups: outerColumn.groups.map((group) => ({
          ...group,
          columns: group.columns.map((column) =>
            column.filter((input) => {
              if (input.type !== 'field') {
                return true;
              }

              const currentInputKey = input.key;
              const currentInputConnectionKey = input.connection?.key;

              // Fields inside connections can have the same key as other fields in other connections to the same table,  so we check for the connection.key to differentiate them
              if (connectionFieldKey) {
                return !fieldsToRemove.find(
                  (fieldInputToRemove) =>
                    fieldInputToRemove.key === currentInputKey &&
                    connectionFieldKey === currentInputConnectionKey
                );
              }

              return !fieldsToRemove.find(
                (fieldInputToRemove) => fieldInputToRemove.key === currentInputKey
              );
            })
          )
        }))
      }))
    };

    updateViewColumnsSchema(updatedView);
  };

  const onSearch = (value: string) => {
    const trimmedValue = value.trim().toLowerCase();
    setSearchValue(trimmedValue);

    if (trimmedValue === '') {
      setVisibleFields(sourceObject.fields);
      return;
    }

    setVisibleFields(
      sourceObject.fields.filter((field) => field.name.toLowerCase().includes(trimmedValue))
    );
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-4">
        <div>
          <div className="mb-2 flex items-center gap-1">
            <span className="text-xs font-medium text-emphasis">
              {t(
                'pages.element_settings.common.categories.data_display.field_management.manage_fields'
              )}
            </span>
            <span className="rounded-sm bg-subtle px-1 py-0.5 text-xs font-medium text-default">
              {t(
                'pages.element_settings.common.categories.data_display.field_management.active_inputs_count',
                {
                  currentCount: activeViewFieldInputs.length,
                  totalCount: allVisibleFields.length
                }
              )}
            </span>
          </div>
          <p className="text-xs text-subtle">
            {t(
              'pages.element_settings.common.categories.data_display.field_management.manage_fields_description'
            )}
          </p>
        </div>

        <InputSearch
          aria-label={t(
            'pages.element_settings.common.categories.data_display.field_management.search_fields'
          )}
          placeholder={t(
            'pages.element_settings.common.categories.data_display.field_management.search_fields'
          )}
          value={searchValue}
          onChange={(e) => onSearch(e.target.value)}
        />
      </div>

      <div className="flex flex-col gap-6">
        <ViewFieldListCollapsible
          visibleFields={visibleFields}
          activeFieldInputs={activeViewFieldInputs}
          onAddFieldInput={onAddFieldInput}
          onRemoveFieldInput={onRemoveFieldInput}
          onAddAllFieldInputs={onAddAllFieldInputs}
          onRemoveAllFieldInputs={onRemoveAllFieldInputs}
          triggerElement={<p className="text-sm">{sourceObject.name}</p>}
          dataTestId="fields-collapsible-source"
        />

        {sourceObject.connections.outbound.map((connection) => {
          const connObject = getObjectByKey(connection.object);

          if (!connObject) {
            return null;
          }

          const connectionFields = connObject.fields.filter((field) =>
            field.name.toLowerCase().includes(searchValue)
          );

          return (
            <ViewFieldListCollapsible
              key={`connection-outbound-${connection.key}`}
              connectionKey={connection.key}
              visibleFields={connectionFields}
              activeFieldInputs={activeViewFieldInputs}
              onAddFieldInput={onAddFieldInput}
              onRemoveFieldInput={onRemoveFieldInput}
              onAddAllFieldInputs={onAddAllFieldInputs}
              onRemoveAllFieldInputs={onRemoveAllFieldInputs}
              dataTestId="fields-collapsible-outbound"
              triggerElement={
                <>
                  <FieldIcon size={16} className="mt-1" name="connection" />
                  <div>
                    <p>{connObject.name}</p>
                    <p className="flex items-center gap-1 text-xs text-subtle">
                      <span className="py-1">{sourceObject.name}</span>
                      {'-->'}
                      <span className="py-1">{connection.name}</span>
                    </p>
                  </div>
                </>
              }
            />
          );
        })}

        {sourceObject.connections.inbound.map((connection) => {
          const connObject = getObjectByKey(connection.object);

          if (!connObject) {
            return null;
          }

          const connectionFields = connObject.fields.filter((field) =>
            field.name.toLowerCase().includes(searchValue)
          );

          return (
            <ViewFieldListCollapsible
              key={`connection-inbound-${connection.key}`}
              connectionKey={connection.key}
              visibleFields={connectionFields}
              activeFieldInputs={activeViewFieldInputs}
              onAddFieldInput={onAddFieldInput}
              onRemoveFieldInput={onRemoveFieldInput}
              onAddAllFieldInputs={onAddAllFieldInputs}
              onRemoveAllFieldInputs={onRemoveAllFieldInputs}
              dataTestId="fields-collapsible-inbound"
              triggerElement={
                <>
                  <FieldIcon size={16} className="mt-1" name="connection" />
                  <div>
                    <p>{connObject.name}</p>
                    <p className="flex items-center gap-1 text-xs text-subtle">
                      <span className="py-1">{connObject.name}</span>
                      {'-->'}
                      <span className="py-1">{connection.name}</span>
                    </p>
                  </div>
                </>
              }
            />
          );
        })}
      </div>

      <div className="sticky -bottom-6 -mb-6 mt-auto border-t border-t-subtle bg-muted py-4">
        <ViewDetailsAddStaticInputButton />
      </div>
    </div>
  );
}
