import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiExternalLink as ExternalIcon } from 'react-icons/hi';
import { Button, useToast } from '@knack/asterisk-react';
import Editor from '@monaco-editor/react';

import {
  useApplicationSettingsMutation,
  type AppSettingsPayload
} from '@/hooks/api/mutations/useApplicationSettingsMutation';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { useCustomCodeQuery } from '@/hooks/api/queries/useCustomCodeQuery';
import { NavigateAwayModal } from '@/components/NavigateAwayModal';

export function CustomCodeSettings({ type }: { type: 'css' | 'javascript' }) {
  const [t] = useTranslation();
  const { data: application } = useApplicationQuery();
  const { data: customCode } = useCustomCodeQuery(application?.id, type);
  const customCodeValue = customCode || '';
  const { mutate: updateApplicationSettings } = useApplicationSettingsMutation();
  const [editorContent, setEditorContent] = useState<string>(customCodeValue);
  const { presentToast } = useToast();
  const sectionDetails = {
    css: {
      link: 'https://docs.knack.com/docs/using-css-with-knack'
    },
    javascript: {
      link: 'https://docs.knack.com/docs/using-javascript-with-knack'
    }
  };

  const onSave = () => {
    const payload: AppSettingsPayload = {
      settings: {
        [type]: editorContent || ''
      }
    };

    updateApplicationSettings(payload, {
      onSuccess: () => {
        presentToast({
          title: t('app_settings.settings_saved_successfully')
        });
      }
    });
  };

  return (
    <div>
      <h1>{t(`app_settings.code.${type}_description`)}</h1>
      <p className="my-4">
        <a href={sectionDetails[type].link} target="_blank" rel="noreferrer" className="underline">
          {t('app_settings.code.view_dev_docs')}
          <ExternalIcon size={16} className="ml-1 inline-block" />
        </a>
      </p>
      <Editor
        value={editorContent}
        height="calc(100vh - 400px)"
        language={type}
        theme="knack-theme"
        options={{
          minimap: { enabled: false },
          wordWrap: 'on',
          scrollBeyondLastLine: false,
          automaticLayout: true
        }}
        onChange={(value) => {
          setEditorContent(value || '');
        }}
      />
      <Button className="mt-4" onClick={onSave}>
        {t('app_settings.code.save_code')}
      </Button>
      <NavigateAwayModal condition={editorContent !== customCodeValue} />
    </div>
  );
}
