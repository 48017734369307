import { type BaseKnackField } from '@/types/schema/KnackField';

export const ADDRESS_INPUT_OPTIONS = ['address', 'lat_long'] as const;
export const FORMAT_OPTIONS = ['US', 'international', 'international_country'] as const;
export const ZIP_CODE_MILES_RANGE_OPTIONS = ['1', '5', '25', '50', '100'] as const;

export type AddressFormatOptions = (typeof FORMAT_OPTIONS)[number];

export interface AddressField extends BaseKnackField {
  type: 'address';
  format: {
    enable_address_autocomplete?: boolean;
    enable_autocomplete?: boolean;
    enable_geocoding?: boolean;
    format?: AddressFormatOptions;
    input?: (typeof ADDRESS_INPUT_OPTIONS)[number];
  };
}
