import { type ComponentPropsWithoutRef } from 'react';
import { Trans } from 'react-i18next';

import { isFlagEnabled } from '@/utils/flagsmith';
import { KNOWLEDGE_BASE_URLS } from '@/utils/knowledge-base';
import { cn } from '@/utils/tailwind';

export function DisclosureLinks({ className }: ComponentPropsWithoutRef<'div'>) {
  const isImportV2Enabled = isFlagEnabled('gt_add_import_v2');

  return (
    <div className={cn('text-center', className)}>
      <p>
        <Trans
          i18nKey="components.add_table.view_imports_guide"
          components={[
            <a
              href={KNOWLEDGE_BASE_URLS.IMPORTING_DATA}
              className={cn('underline', { 'text-brand-500': isImportV2Enabled })}
              key="viewImportsGuideLink"
            >
              Import Guide Link
            </a>
          ]}
        />
      </p>
      <p>
        <Trans
          i18nKey="components.add_table.limited_use_disclosure"
          components={[
            <a
              href={KNOWLEDGE_BASE_URLS.LIMITED_USE_DISCLOSURE}
              className={cn('underline', { 'text-brand-500': isImportV2Enabled })}
              key="limitedUseDisclosureLink"
            >
              Limited Use Disclosure Link
            </a>
          ]}
        />
      </p>
    </div>
  );
}
