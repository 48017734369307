import React from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Label, Switch } from '@knack/asterisk-react';

import { type CalendarView } from '@/types/schema/views/CalendarView';
import { BuilderAccordion } from '@/components/BuilderAccordion';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { useUpdateView } from '@/pages/pages/settings-panel/view-settings/useUpdateView';

const DEFAULT_RECORDS_LIMIT = '25';

export function DataDisplayLimitSettings() {
  const { view } = useActiveViewContext<CalendarView>();
  const [t] = useTranslation();
  const updateViewSchema = useUpdateView();

  const handleChangeLimit = (value: boolean) => {
    if (value) {
      updateViewSchema({
        source: {
          ...view.source,
          limit: undefined
        }
      });
      return;
    }

    updateViewSchema({
      source: {
        ...view.source,
        limit: DEFAULT_RECORDS_LIMIT
      }
    });
  };

  const handleChangeRecordsLimitInput = (
    e: React.ChangeEvent<HTMLInputElement>,
    resetValue?: boolean
  ) => {
    if (resetValue && e.target.value === '') {
      updateViewSchema({
        source: {
          ...view.source,
          limit: undefined
        }
      });
      return;
    }

    updateViewSchema({
      source: {
        ...view.source,
        limit: e.target.value
      }
    });
  };

  return (
    <BuilderAccordion.Item
      label={t('pages.element_settings.common.categories.data_display.general_settings.limit')}
      isDefaultOpen
    >
      <Label className="flex items-center gap-2">
        <Switch checked={!view.source?.limit} onCheckedChange={handleChangeLimit} />
        {t(
          'pages.element_settings.common.categories.data_display.general_settings.show_all_records'
        )}
      </Label>
      {view.source.limit && (
        <div className="ml-10 mt-2 flex items-center gap-1">
          <span>
            {t(
              'pages.element_settings.common.categories.data_display.general_settings.show_the_first'
            )}
          </span>
          <Input
            aria-label={t(
              'pages.element_settings.common.categories.data_display.general_settings.show_all_records'
            )}
            data-testid="records-limit-input"
            className="h-auto w-10 px-2 py-1.5"
            value={view.source.limit}
            onChange={handleChangeRecordsLimitInput}
          />
          <span>{t('keywords.records')}</span>
        </div>
      )}
    </BuilderAccordion.Item>
  );
}
