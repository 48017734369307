import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Dialog } from '@knack/asterisk-react';

import { type FormView, type FormViewSubmitRule } from '@/types/schema/views/FormView';
import { usePagesQuery } from '@/hooks/api/queries/usePagesQuery';
import { useSubmitRuleHelpers } from '@/hooks/helpers/useSubmitRuleHelpers';
import { SubmitRuleFormFields } from '@/components/submit-rule/SubmitRuleFormFields';
import { usePageEditorContext } from '@/pages/pages/page-editor/PageEditorContext';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';

interface FormSubmitRuleFormDialogProps {
  submitRule: FormViewSubmitRule;
  formIntent?: 'edit' | 'add';
  onOpenChange: (isOpen: boolean) => void;
  onRuleSave: (updatedSubmitRule: FormViewSubmitRule) => void;
}

export function FormSubmitRuleFormDialog({
  submitRule,
  formIntent = 'edit',
  onOpenChange,
  onRuleSave
}: FormSubmitRuleFormDialogProps) {
  const [t] = useTranslation();

  const { data: pages = [] } = usePagesQuery();
  const { sourceObject, view } = useActiveViewContext<FormView>();
  const { page: activePage } = usePageEditorContext();
  const { getSubmitRuleFormSchema } = useSubmitRuleHelpers();

  const initialSubmitRuleValues = {
    ...submitRule
  };

  // Sanitize the scene value if the action is 'child_page' and there is no child page with that page slug
  if (
    submitRule.action === 'child_page' &&
    submitRule.scene &&
    typeof submitRule.scene === 'string' &&
    !pages.some((page) => page.slug === submitRule.scene)
  ) {
    initialSubmitRuleValues.scene = null;
  }

  const form = useForm<FormViewSubmitRule>({
    resolver: zodResolver(getSubmitRuleFormSchema(sourceObject)),
    defaultValues: initialSubmitRuleValues
  });

  const { handleSubmit, watch } = form;

  const { action: currentSelectedActionType } = watch();

  const onSubmit = (updatedSubmitRule: FormViewSubmitRule) => {
    const formattedSubmitRule = {
      ...updatedSubmitRule
    };

    // If the action is 'child_page' and the child page doesn't already exist, we need to set the `scene` to be an object with specific properties so the API can create the page.
    // Once the page is created, the rule's `scene` value will be a string containing the new page's slug.
    if (
      currentSelectedActionType === 'child_page' &&
      !initialSubmitRuleValues.scene &&
      updatedSubmitRule.scene &&
      typeof updatedSubmitRule.scene !== 'string'
    ) {
      formattedSubmitRule.scene = {
        name: updatedSubmitRule.scene.name,
        object: sourceObject.key,
        parent: activePage.slug,
        allowed_profiles: activePage.allowedProfileKeys || [],
        views: []
      };
    }

    onRuleSave(formattedSubmitRule);
  };

  return (
    <Dialog open onOpenChange={onOpenChange}>
      <Dialog.Content>
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit)} className="w-full">
            <Dialog.MainContent>
              <Dialog.Header>
                <Dialog.Title>
                  {formIntent === 'edit'
                    ? t('components.submit_rule_card.edit_modal.title')
                    : t('components.submit_rule_card.add_modal.title')}
                </Dialog.Title>
                <Dialog.Description className="sr-only">
                  {formIntent === 'edit'
                    ? t('components.submit_rule_card.edit_modal.description')
                    : t('components.submit_rule_card.add_modal.description')}
                </Dialog.Description>
              </Dialog.Header>

              <SubmitRuleFormFields
                activePage={activePage}
                pages={pages}
                submitRule={submitRule}
                sourceObject={sourceObject}
                initialSubmitRuleValues={initialSubmitRuleValues}
                canCriteriaValuesBeField={view.action === 'update'}
              />
            </Dialog.MainContent>
            <Dialog.Footer>
              <Dialog.Close asChild>
                <Button intent="minimal">{t('actions.cancel')}</Button>
              </Dialog.Close>
              <Button type="submit" data-testid="submit-rule-form-submit-button">
                {formIntent === 'edit'
                  ? t('components.submit_rule_card.edit_modal.submit_button')
                  : t('components.submit_rule_card.add_modal.submit_button')}
              </Button>
            </Dialog.Footer>
          </form>
        </FormProvider>
      </Dialog.Content>
    </Dialog>
  );
}
