import { useTranslation } from 'react-i18next';
import snakeCase from 'lodash.snakecase';
import { DateTime } from 'luxon';

import { type DateTimeField, type DateTimeFieldFormat } from '@/types/schema/fields';
import { type KnackCriteria, type KnackCriteriaDateTimeValue } from '@/types/schema/KnackCriteria';
import { useDateTimeHelpers } from '@/hooks/helpers/useDateTimeHelpers';

export function useCriteriaDateTimeHelpers() {
  const [t] = useTranslation();

  const { isTimeStringInMilitaryFormat, getHourMinutesStringPadded, getNormalizedDateFormat } =
    useDateTimeHelpers();

  const getDefaultCriteriaTimeValues = (dateTimeFieldFormat: DateTimeFieldFormat) => {
    if (dateTimeFieldFormat.time_type === 'none') {
      const criteriaTimeValues: KnackCriteriaDateTimeValue = {
        time: '',
        hours: '',
        minutes: '',
        am_pm: ''
      };

      return criteriaTimeValues;
    }

    if (dateTimeFieldFormat.time_type === 'current') {
      const defaultFormat =
        dateTimeFieldFormat.time_format === 'HH MM (military)' ? 'HH:mm' : 'h:mma';

      const criteriaTimeValues: KnackCriteriaDateTimeValue = {
        time: DateTime.now().toFormat(defaultFormat),
        hours: DateTime.now().hour,
        minutes: DateTime.now().minute,
        am_pm: DateTime.now().hour < 12 ? 'AM' : 'PM'
      };

      return criteriaTimeValues;
    }

    if (dateTimeFieldFormat.time_type === 'time' && dateTimeFieldFormat.default_time) {
      const isMilitaryFormat = isTimeStringInMilitaryFormat(dateTimeFieldFormat.default_time);

      const timeStringInMilitary = isMilitaryFormat
        ? dateTimeFieldFormat.default_time
        : DateTime.fromFormat(dateTimeFieldFormat.default_time, 'h:mma').toFormat('HH:mm');

      const timeString =
        dateTimeFieldFormat.time_format === 'HH MM (military)'
          ? timeStringInMilitary
          : DateTime.fromFormat(dateTimeFieldFormat.default_time, 'HH:mm')
              .toFormat('h:mma')
              .toLowerCase();

      const [hours, minutes] = timeStringInMilitary.split(':').map(Number);

      const criteriaTimeValues: KnackCriteriaDateTimeValue = {
        time: timeString,
        hours,
        minutes,
        am_pm: hours < 12 ? 'AM' : 'PM'
      };

      return criteriaTimeValues;
    }

    return '';
  };

  const getDefaultCriteriaDateValue = (dateTimeFieldFormat: DateTimeFieldFormat) => {
    const normalizedDateFormat = getNormalizedDateFormat(dateTimeFieldFormat);

    if (dateTimeFieldFormat.default_type === 'date' && dateTimeFieldFormat.default_date) {
      const formattedDate = DateTime.fromFormat(
        dateTimeFieldFormat.default_date,
        'MM/dd/yyyy'
      ).toFormat(normalizedDateFormat);

      return formattedDate;
    }

    if (dateTimeFieldFormat.default_type === 'current') {
      return DateTime.now().toFormat(normalizedDateFormat);
    }

    return '';
  };

  const getDefaultCriteriaDateTimeValue = (dateTimeFieldFormat: DateTimeFieldFormat) => {
    const defaultDate: KnackCriteriaDateTimeValue = {
      date: getDefaultCriteriaDateValue(dateTimeFieldFormat),
      all_day: false, // always `false` in criterias, for some reason

      // Only include time properties if the time is not ignored
      ...(dateTimeFieldFormat.time_format !== 'Ignore Time' && {
        ...getDefaultCriteriaTimeValues(dateTimeFieldFormat)
      })
    };

    return defaultDate;
  };

  const getCriteriaTimeDisplayString = (
    criteriaDateTimeValue: KnackCriteriaDateTimeValue,
    criteriaField: DateTimeField
  ) => {
    if (criteriaDateTimeValue.hours === '' && criteriaDateTimeValue.minutes === '') {
      return '';
    }

    const hours =
      typeof criteriaDateTimeValue.hours === 'string'
        ? Number(criteriaDateTimeValue.hours)
        : criteriaDateTimeValue.hours;
    const minutes =
      typeof criteriaDateTimeValue.minutes === 'string'
        ? Number(criteriaDateTimeValue.minutes)
        : criteriaDateTimeValue.minutes;

    if (hours === undefined || minutes === undefined) {
      return '';
    }

    if (criteriaField.format.time_format === 'HH MM (military)') {
      const hourMinutesStringPadded = getHourMinutesStringPadded(hours, minutes);
      return hourMinutesStringPadded || '';
    }

    const hoursIn12HourFormat = hours > 12 ? hours - 12 : hours;
    return `${getHourMinutesStringPadded(hoursIn12HourFormat, minutes)} ${criteriaDateTimeValue.am_pm || ''}`.trim();
  };

  const getCriteriaDateTimeDisplayString = (
    criteria: KnackCriteria,
    criteriaField: DateTimeField
  ) => {
    const { operator, type, range, value } = criteria;

    if (
      operator === 'is during the previous' ||
      operator === 'is during the next' ||
      operator === 'is before the previous' ||
      operator === 'is after the next'
    ) {
      if (!type || !range) {
        return '-';
      }

      return t(`attributes.field_labels.date_time.number_${snakeCase(type)}`, {
        number: range
      });
    }

    if (operator === 'is during the current') {
      if (!type) {
        return '-';
      }

      return t(`attributes.field_labels.date_time.${type}`);
    }

    if (
      criteriaField.format.date_format === 'Ignore Date' &&
      criteriaField.format.time_format === 'Ignore Time'
    ) {
      return '-';
    }

    // If the criteria value is not an object or does not have a date property, return an empty string
    if (typeof value !== 'object' || !('date' in value)) {
      return '-';
    }

    if (criteriaField.format.date_format === 'Ignore Date') {
      return getCriteriaTimeDisplayString(value, criteriaField) || '-';
    }

    if (criteriaField.format.time_format === 'Ignore Time') {
      return value.date || '-';
    }

    if (value.date === '' && value.time === '') {
      return t('attributes.field_labels.date_time.any_date_any_time');
    }

    const timeString = getCriteriaTimeDisplayString(value, criteriaField);
    return `${value.date || ''} ${timeString}`.trim();
  };

  return {
    getDefaultCriteriaDateTimeValue,
    getDefaultCriteriaDateValue,
    getCriteriaDateTimeDisplayString
  };
}
