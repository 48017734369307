import { useCallback, useEffect } from 'react';

import { useSelectionStrategy } from '@/components/data-table/display/useSelectionStrategy';
import { useDataTableStore } from '@/components/data-table/useDataTableStore';

export const useDateTimeKeyboardEvents = (ref: React.MutableRefObject<HTMLElement | null>) => {
  const { moveSelectionVertical, moveSelectionHorizontal } = useSelectionStrategy();
  const selectedCell = useDataTableStore().use.selectedCell();
  const { setIsEditing } = useDataTableStore().use.actions();

  // Memoized event handler
  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (!selectedCell?.isEditing) return;

      const keyMap: Record<string, () => void> = {
        Enter: () => {
          event.preventDefault();
          event.stopPropagation();

          setIsEditing(false);
          moveSelectionVertical(event.shiftKey ? 'up' : 'down');
        },
        Tab: () => {
          event.preventDefault();
          event.stopPropagation();

          setIsEditing(false);
          moveSelectionHorizontal(event.shiftKey ? 'left' : 'right');
        },
        Escape: () => {
          event.preventDefault();
          event.stopPropagation();

          setIsEditing(false);
        }
      };

      if (keyMap[event.key]) keyMap[event.key]();
    },
    [selectedCell?.isEditing, moveSelectionHorizontal, moveSelectionVertical, setIsEditing]
  );

  useEffect(() => {
    const inputElement = ref?.current;

    if (inputElement) {
      inputElement.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      if (inputElement) {
        inputElement.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, [ref, handleKeyDown]);
};
