import { useTranslation } from 'react-i18next';
import { Tabs, type TabsListItem } from '@knack/asterisk-react';

import { type ReportView } from '@/types/schema/views/ReportView';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { ReportDataDisplay } from '@/pages/pages/settings-panel/view-settings/report/data-display/ReportDataDisplay';
import { useViewInputEditModeContext } from '@/pages/pages/settings-panel/view-settings/ViewInputEditModeContext';
import { ActiveChartContextProvider } from './ActiveChartContext';
import { ChartFilteringSettings } from './ChartFilteringSettings';
import { ChartGeneralSettings } from './ChartGeneralSettings';

export function ChartSettings() {
  const [t] = useTranslation();

  const { view } = useActiveViewContext<ReportView>();
  const { viewInputId: chartId } = useViewInputEditModeContext();

  const chart = view.rows[0].reports.find((c) => c.id === chartId);

  if (!chart) {
    return t('pages.element_settings.report.select_chart');
  }

  const items: TabsListItem[] = [
    {
      children: <span>{t('pages.element_settings.report.categories.general.name')}</span>,
      value: 'general'
    },
    {
      children: <span>{t('pages.element_settings.report.categories.data_display.name')}</span>,
      value: 'data-display'
    },
    {
      children: <span>{t('keywords.filtering')}</span>,
      value: 'filtering'
    }
  ];

  return (
    <ActiveChartContextProvider chart={chart}>
      <Tabs defaultValue="general">
        <Tabs.List items={items} intent="page" shouldDisableResponsive shouldUseGradient />
        <Tabs.Content value="general" className="p-0 text-default">
          <ChartGeneralSettings />
        </Tabs.Content>
        <Tabs.Content value="data-display" className="p-0 text-default">
          <ReportDataDisplay />
        </Tabs.Content>
        <Tabs.Content value="filtering" className="p-0 text-default">
          <ChartFilteringSettings />
        </Tabs.Content>
      </Tabs>
    </ActiveChartContextProvider>
  );
}
