import { type Path } from 'react-hook-form';

/**
 * With this function you can make an existing form reusable by adding a prefix to the field names
 * It will "fake" as if you are not using a prefix, but the prefix will be added to the field names
 * You have to add the FormSchema as a generic, and from there it will infer the type of the path
 *
 * To use it you can pass the formPath as a prop to the component, and then use it like this:
 *
 * const getFieldKey = useFieldNameConcatenate<FormSchema>(formPath);
 * const { register, errors } = useFormContext<FormSchema>();
 *
 * Then when you need to referenciate a form field path you can do it like this:
 * <Input {...register(getFieldKey('field_name'))} />
 * <ErrorMessage errors={errors} name={getFieldKey('field_name')} />
 *
 * Take into account that you will still be able to use the field name without this function, but it will break the reusability of the form and can lead to bugs
 */
export const useFieldNameConcatenate =
  <D>(formPath?: string) =>
  <T extends Path<D>>(path: T): T => {
    const fieldPath = formPath ? `${formPath}.` : '';
    return `${fieldPath}${path}` as T;
  };
