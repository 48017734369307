import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Label, Select } from '@knack/asterisk-react';

import { type CountField } from '@/types/schema/fields';
import { type KnackConnection } from '@/types/schema/KnackObject';
import { useApplication } from '@/hooks/useApplication';

type CountFieldSelectorProps = {
  field: CountField;
  aggregateFieldConnections: KnackConnection[];
};

export function CountFieldSelector({ field, aggregateFieldConnections }: CountFieldSelectorProps) {
  const [t] = useTranslation();

  const application = useApplication();
  const knackObjects = application?.objects;

  const { setValue, getValues } = useFormContext();

  const availableCountConnectedFields = useMemo(() => {
    const countConnectedFields = aggregateFieldConnections?.map((conn) => {
      const connectedTable = knackObjects?.find((object) => object.key === conn.object);
      return {
        key: conn.key,
        tableName: connectedTable?.name,
        name: conn.name
      };
    });
    return countConnectedFields;
  }, [aggregateFieldConnections, knackObjects]);

  // Initialize the connection field on the Add new field form
  useEffect(() => {
    if (!getValues('format.connection')) {
      setValue('format.connection', availableCountConnectedFields[0]?.key);
    }
  }, [availableCountConnectedFields, setValue, getValues]);

  return (
    aggregateFieldConnections?.length > 0 && (
      <div className="flex flex-col gap-2">
        <Label htmlFor="connection-item-value">
          {t('components.data_table.attributes.field_settings.aggregate.connection_to_count')}
        </Label>
        <Select
          defaultValue={field?.format?.connection || aggregateFieldConnections[0]?.key}
          onValueChange={(value) => {
            setValue('format.connection', value);
          }}
        >
          <Select.Trigger className="w-full truncate" />
          <Select.Content>
            {availableCountConnectedFields?.map((f) => (
              <Select.Item key={f.key} value={f.key} className="truncate">
                {`${f.tableName} (${f.name})`}
              </Select.Item>
            ))}
          </Select.Content>
        </Select>
      </div>
    )
  );
}
