import { Dialog } from '@knack/asterisk-react';

import { type BuilderViewActionRule } from '@/types/schema/BuilderView';
import { type KnackObject } from '@/types/schema/KnackObject';
import { usePageEditorContext } from '@/pages/pages/page-editor/PageEditorContext';
import { DynamicActionsDialogContent } from '@/pages/pages/settings-panel/view-settings/table/data-display/field-management/column-settings/dynamic-actions/DynamicActionsDialogContent';

interface DynamicActionsDialogProps {
  action?: BuilderViewActionRule;
  sourceObject: KnackObject;
  onUpdate: (data: BuilderViewActionRule) => void;
  onOpenChange: (isOpen: boolean) => void;
}

export function DynamicActionsDialog({
  sourceObject,
  action,
  onUpdate,
  onOpenChange
}: DynamicActionsDialogProps) {
  const { page: activePage } = usePageEditorContext();

  const handleColumnActionSubmit = (data: BuilderViewActionRule) => {
    const formattedSubmitRule = data.submit_rules[0];

    // If the action is 'child_page' and the child page doesn't already exist, we need to set the `scene` to be an object with specific properties so the API can create the page.
    // Once the page is created, the rule's `scene` value will be a string containing the new page's slug.
    if (
      formattedSubmitRule.action === 'child_page' &&
      !action?.submit_rules[0].scene &&
      formattedSubmitRule.scene &&
      typeof formattedSubmitRule.scene !== 'string'
    ) {
      formattedSubmitRule.scene = {
        name: formattedSubmitRule.scene.name,
        object: sourceObject.key,
        parent: activePage.slug,
        allowed_profiles: activePage.allowedProfileKeys || [],
        views: []
      };
    }

    onUpdate({ ...data, submit_rules: [formattedSubmitRule] });
    onOpenChange(false);
  };

  return (
    <Dialog open onOpenChange={onOpenChange}>
      <Dialog.Content>
        <DynamicActionsDialogContent
          sourceObject={sourceObject}
          action={action}
          onFormSubmit={handleColumnActionSubmit}
        />
      </Dialog.Content>
    </Dialog>
  );
}
