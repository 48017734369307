import { createContext, useContext, useMemo, useState } from 'react';

import { type BuilderViewKey } from '@/types/schema/BuilderView';

type ActiveTabContextState = {
  activeTab: string | undefined;
  setActiveTab: (activeTab: string | undefined) => void;
} | null;

type ActiveTabContextProviderProps = {
  children: React.ReactNode;
};

export type ViewInputEditModeData = {
  viewKey: BuilderViewKey;
  viewInputId: string;
};

const ActiveTabContext = createContext<ActiveTabContextState>(null);

export function ActiveTabContextProvider({ children }: ActiveTabContextProviderProps) {
  const [activeTab, setActiveTab] = useState<string | undefined>(undefined);

  const contextValue = useMemo(
    () => ({
      activeTab,
      setActiveTab
    }),
    [setActiveTab, activeTab]
  );

  return <ActiveTabContext.Provider value={contextValue}>{children}</ActiveTabContext.Provider>;
}

export const useActiveTabContext = () => {
  const context = useContext(ActiveTabContext);
  if (!context) {
    throw new Error('useActiveTabContext must be used within a ActiveTabContext');
  }
  return context;
};
