import { DateTime } from 'luxon';

import { type TimeFormatValue } from '@/types/schema/fields';

export function mapTimeFormatFromKnackSchema(timeFormat: TimeFormatValue) {
  switch (timeFormat) {
    case 'Ignore Time':
      return '';

    case 'HH MM (military)':
      return 'HH:mm';

    case 'HH:MM am':
      return 'h:mm a';

    default:
      return 'h:mm a';
  }
}

export function formatTime(time?: string, format?: TimeFormatValue) {
  if (!time || format === 'Ignore Time') return '';

  return DateTime.fromISO(time, {
    zone: 'utc'
  })
    .toFormat(mapTimeFormatFromKnackSchema(format || 'HH:MM am'))
    .toUpperCase();
}
