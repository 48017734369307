import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdFilterAlt as FilterIcon } from 'react-icons/md';
import { Button, Chip, Popover } from '@knack/asterisk-react';

import { type KnackCriteria } from '@/types/schema/KnackCriteria';
import { cn } from '@/utils/tailwind';
import { Filters } from '@/components/data-table/display/header/filters/Filters';
import { getFilteredFieldsKeys } from '@/components/data-table/display/header/filters/helpers';
import {
  useDataTableStore,
  useDataTableStorePersist
} from '@/components/data-table/useDataTableStore';
import { FieldIcon } from '@/components/FieldIcon';
import { TextTooltip } from '@/components/TextTooltip';

export type DataTableFilter = {
  match: 'and' | 'or';
  rules: KnackCriteria[];
};

const MAX_FILTERED_FIELDS_TO_SHOW = 2;

export function FilteringSection() {
  const [t] = useTranslation();
  const [isFilterPopoverOpen, setIsFilterPopoverOpen] = useState(false);

  const fields = useDataTableStore().use.fields();
  const filters = useDataTableStorePersist().use.filters();
  const totalRecords = useDataTableStore().use.totalRecords();
  const isDraftModeEnabled = useDataTableStore().use.isDraftModeEnabled();
  const { setFilters, setPage } = useDataTableStorePersist().use.actions();
  const { refetchPagesInViewport, setPagesInViewport } = useDataTableStore().use.actions();

  const filteredFieldsKeys = getFilteredFieldsKeys(filters);

  const onApplyFilter = (newFilter: DataTableFilter | null) => {
    setFilters(newFilter);
    setPage(null);
    setPagesInViewport([0]);
    refetchPagesInViewport();
    setIsFilterPopoverOpen(false);
  };

  useEffect(() => {
    // Iterate the rules and remove the fields that doesn't exist.
    // This can happen if you remove a field or you change the url manually
    const listOfFieldsInTable = fields.map((field) => field.key);
    const rulesWithoutMissingFields =
      filters?.rules.filter((filter) => listOfFieldsInTable.includes(filter.field)) || [];

    if (rulesWithoutMissingFields.length === 0) {
      setFilters(null);
      return;
    }

    setFilters({
      ...(filters || { match: 'and' }),
      rules: rulesWithoutMissingFields
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  return (
    <Popover open={isFilterPopoverOpen} onOpenChange={setIsFilterPopoverOpen}>
      <TextTooltip
        label={isDraftModeEnabled ? t('components.data_table.disabled_because_draftmode') : ''}
        asChild
      >
        <div>
          <Popover.Trigger data-testid="data-table-filter-button" asChild>
            {/* TODO: FE-2522 - These are not the final colors. We'll change them with the correct ones when they are available in Asterisk */}
            <Button
              intent="secondary"
              className={cn('h-9 p-2', { 'bg-illustration-blue': filters })}
              disabled={isDraftModeEnabled}
              asChild
            >
              <div role="button">
                <FilterIcon size={20} className="mr-1 flex-shrink-0 text-default" />
                {filters
                  ? t('components.data_table.filtering.filtered_by')
                  : t('components.data_table.header.filter')}

                {filteredFieldsKeys.length <= MAX_FILTERED_FIELDS_TO_SHOW &&
                  filteredFieldsKeys.map((field) => {
                    const fieldSelectedFormat = fields.find((el) => el.key === field);

                    if (!fieldSelectedFormat) return null;

                    return (
                      <div
                        key={field}
                        className="ml-1 flex h-6 max-w-24 items-center rounded bg-blue-100 px-1"
                      >
                        <FieldIcon name={fieldSelectedFormat.type} size={13} className="mr-1" />
                        <p className="truncate">{fieldSelectedFormat.name}</p>
                      </div>
                    );
                  })}

                {filteredFieldsKeys.length > MAX_FILTERED_FIELDS_TO_SHOW &&
                  ` ${filteredFieldsKeys.length} ${t('components.data_table.filtering.fields')}`}
                {filters && (
                  <Chip
                    text={totalRecords.toString()}
                    size="sm"
                    className="pointer-events-none m-0 ml-1 bg-blue-100 px-2 py-3"
                  />
                )}
              </div>
            </Button>
          </Popover.Trigger>
        </div>
      </TextTooltip>
      <Popover.Content className="p-6" align="end">
        <h2 className="mb-1 text-xl">{t('components.data_table.filtering.filter_by')}</h2>
        <p className="mb-6 text-xs">{t('components.data_table.filtering.choose_field')}</p>

        <Filters
          filters={filters}
          onApplyFilter={onApplyFilter}
          onCancel={() => setIsFilterPopoverOpen(false)}
        />
      </Popover.Content>
    </Popover>
  );
}
