import { useTranslation } from 'react-i18next';
import { Label, Select } from '@knack/asterisk-react';

import { type ListView } from '@/types/schema/views/ListView';
import { type MapView } from '@/types/schema/views/MapView';
import { type TableView } from '@/types/schema/views/TableView';
import { BuilderAccordion } from '@/components/BuilderAccordion';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { useUpdateView } from '@/pages/pages/settings-panel/view-settings/useUpdateView';
import { ViewSettingsSwitch } from './ViewSettingsSwitch';

const DEFAULT_ROWS_PER_PAGE_OPTION = '25';
const ROWS_PER_PAGE_OPTIONS = ['10', DEFAULT_ROWS_PER_PAGE_OPTION, '50', '100'];

export function ViewPaginationSettings() {
  const [t] = useTranslation();

  const { view } = useActiveViewContext<TableView | ListView | MapView>();
  const updateViewSchema = useUpdateView<TableView | ListView | MapView>();

  return (
    <BuilderAccordion.Item
      isDefaultOpen
      label={t('pages.element_settings.common.categories.general_settings.pagination')}
    >
      <div className="space-y-2">
        <Label>
          <span className="font-medium">
            {t('pages.element_settings.common.categories.general_settings.default_pagination')}
          </span>
          <Select
            value={view.rows_per_page || DEFAULT_ROWS_PER_PAGE_OPTION}
            onValueChange={(value) => {
              updateViewSchema({
                rows_per_page: value
              });
            }}
          >
            <Select.Trigger
              data-testid="view-settings-default-pagination-select"
              className="mt-1 w-full text-subtle"
            />
            <Select.Content>
              {ROWS_PER_PAGE_OPTIONS.map((option) => (
                <Select.Item
                  key={option}
                  value={option}
                  data-testid={`view-settings-default-pagination-select-${option}-records`}
                >
                  {t(
                    'pages.element_settings.common.categories.general_settings.display_record_count',
                    { rowsPerPage: option }
                  )}
                </Select.Item>
              ))}
            </Select.Content>
          </Select>
        </Label>
        <ViewSettingsSwitch
          label={t('pages.element_settings.common.categories.general_settings.allow_pagination')}
          onCheckedChange={(value) =>
            updateViewSchema({
              allow_limit: value
            })
          }
          checked={view.allow_limit}
        />
      </div>
    </BuilderAccordion.Item>
  );
}
