import { type BuilderPage, type BuilderPageSection } from '@/types/schema/BuilderPage';
import { type BuilderView } from '@/types/schema/BuilderView';
import { generateNewSection } from '@/pages/pages/page-editor/helpers/generate-new-section';
import { type PageEditorUpdate, type PageEditorUpdatedItem } from './types';

export function updatePage(
  page: BuilderPage,
  update: PageEditorUpdate
): {
  updatedPage: BuilderPage | null;
  updatedItem: PageEditorUpdatedItem | null;
} {
  let updatedPage: BuilderPage | null = { ...page };
  let updatedItem: PageEditorUpdatedItem | null = null;

  if (update.type === 'page') {
    switch (update.action) {
      case 'update': {
        const { pageUpdates } = update;

        updatedPage = {
          ...page,
          ...pageUpdates
        };

        break;
      }
      default: {
        updatedPage = null;
        updatedItem = null;
      }
    }
  }

  if (update.type === 'view') {
    switch (update.action) {
      case 'add': {
        const { viewSchema, columnId, sectionId, originViewKey, position } = update;
        const { groups: sections } = page;

        if (!viewSchema.key) {
          return { updatedPage, updatedItem };
        }

        const newViewKey = viewSchema.key;
        const hasTargetSection = sectionId && columnId;

        updatedPage = {
          ...page,
          views: [...page.views, viewSchema as BuilderView],

          // We only add the view to a section if a sectionId and columnId are provided.
          // There are cases where a view only needs to be added to the page but not to a section (e.g. adding a child form view that is not always visible on the page)
          ...(hasTargetSection && {
            groups: sections.map((section) => {
              if (section.id !== sectionId) {
                return section;
              }

              // If there is no originViewKey, we just add the new view to the end of the column (e.g. when adding a new view to an empty column)
              if (!originViewKey || !position) {
                return {
                  ...section,
                  columns: section.columns.map((column) => {
                    if (column.id !== columnId) {
                      return column;
                    }

                    return { ...column, viewKeys: [...column.viewKeys, newViewKey] };
                  })
                };
              }

              // Otherwise, we need to find the correct position to insert the new view
              return {
                ...section,
                columns: section.columns.map((column) => {
                  if (column.id !== columnId) {
                    return column;
                  }

                  const viewKeys = [...column.viewKeys];
                  const viewIndex = viewKeys.findIndex((key) => key === originViewKey);

                  if (position === 'above') {
                    viewKeys.splice(viewIndex, 0, newViewKey);
                  } else if (position === 'below') {
                    viewKeys.splice(viewIndex + 1, 0, newViewKey);
                  }

                  return { ...column, viewKeys };
                })
              };
            })
          })
        };

        updatedItem = {
          type: update.type,
          view: viewSchema as BuilderView
        };

        break;
      }
      case 'delete': {
        const { views } = page;
        const { viewKey, sectionId } = update;

        const viewToDelete = views.find((view) => view.key === viewKey);

        if (!viewToDelete) {
          return { updatedPage, updatedItem };
        }

        updatedPage = {
          ...page,
          views: views.filter((view) => view.key !== viewKey),

          // Remove the view from the section if a sectionId is provided. Otherwise, the view is not associated with a section and is not always visible on the page
          ...(sectionId && {
            groups: page.groups.map((section) => {
              if (section.id !== sectionId) {
                return section;
              }

              return {
                ...section,
                columns: section.columns.map((column) => ({
                  ...column,
                  viewKeys: column.viewKeys.filter((key) => key !== viewKey)
                }))
              };
            })
          })
        };

        updatedItem = {
          type: update.type,
          view: viewToDelete
        };

        break;
      }
      case 'update': {
        if (update.origin === 'builder') {
          const { viewSchema, viewKey } = update;
          const { views } = page;
          let updatedView: BuilderView | null = null;

          updatedPage = {
            ...page,
            views: views.map((view) => {
              if (view.key !== viewKey) {
                return view;
              }

              updatedView = { ...view, ...viewSchema } as BuilderView;
              return updatedView;
            })
          };

          if (updatedView) {
            updatedItem = {
              type: update.type,
              view: updatedView
            };
          }
        }

        if (update.origin === 'live-app') {
          const { updatedView } = update;
          const { views } = page;

          updatedPage = {
            ...page,
            views: views.map((view) => {
              if (view.key !== updatedView.key) {
                return view;
              }

              return updatedView;
            })
          };

          updatedItem = {
            type: update.type,
            view: updatedView
          };
        }

        break;
      }
      default: {
        updatedPage = null;
        updatedItem = null;
      }
    }
  }

  if (update.type === 'section') {
    switch (update.action) {
      case 'add': {
        const { groups: sections } = page;
        const { originSectionId, newSectionPosition } = update;

        const originSectionIndex = sections.findIndex((section) => section.id === originSectionId);

        // Create a new section with one empty column
        const newSection: BuilderPageSection = generateNewSection();

        const newSections = [...sections];

        // Insert the new section at the correct position based on the origin section
        if (newSectionPosition === 'above') {
          newSections.splice(originSectionIndex, 0, newSection);
        } else {
          newSections.splice(originSectionIndex + 1, 0, newSection);
        }

        updatedPage = { ...page, groups: newSections };
        updatedItem = {
          type: update.type,
          section: newSection
        };
        break;
      }
      case 'update': {
        const { groups: sections } = page;
        const { updatedSection } = update;

        updatedPage = {
          ...page,
          groups: sections.map((section) => {
            if (section.id !== updatedSection.id) {
              return section;
            }

            return updatedSection;
          })
        };

        updatedItem = {
          type: update.type,
          section: updatedSection
        };

        break;
      }
      case 'sort': {
        const { sortedSections } = update;
        updatedPage = { ...page, groups: sortedSections };

        break;
      }
      case 'delete': {
        const { groups: sections, views } = page;
        const { sectionId } = update;

        const sectionToDelete = sections.find((section) => section.id === sectionId);

        if (!sectionToDelete) {
          return { updatedPage, updatedItem };
        }

        // We need to find the view keys associated with the section's columns
        const viewKeysToDelete = new Set(
          sectionToDelete.columns.flatMap((column) => column.viewKeys)
        );

        updatedPage = {
          ...page,
          groups: sections.filter((section) => section.id !== sectionId),
          views: views.filter((view) => !viewKeysToDelete.has(view.key))
        };

        updatedItem = {
          type: update.type,
          section: sectionToDelete
        };

        break;
      }
      default:
        updatedPage = null;
        updatedItem = null;
    }
  }

  return { updatedPage, updatedItem };
}
