import { useTranslation } from 'react-i18next';
import { HiExclamationCircle as InfoIcon } from 'react-icons/hi2';
import { Input, Label, Switch, Tooltip } from '@knack/asterisk-react';

import { type BuilderViewSourceCriteriaRule } from '@/types/schema/BuilderView';
import { type ListView } from '@/types/schema/views/ListView';
import { BuilderAccordion } from '@/components/BuilderAccordion';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { SourceFiltersDialog } from '@/pages/pages/settings-panel/view-settings/common/source-filters/SourceFiltersDialog';
import { SourceFiltersGroupsCard } from '@/pages/pages/settings-panel/view-settings/common/source-filters/SourceFiltersGroupCard';
import { SourceFiltersWarningBanner } from '@/pages/pages/settings-panel/view-settings/common/source-filters/SourceFiltersWarningBanner';
import { SortingDialog } from '@/pages/pages/settings-panel/view-settings/list/data-display/sorting/SortingDialog';
import { SortingListItem } from '@/pages/pages/settings-panel/view-settings/list/data-display/sorting/SortingListItem';
import { useUpdateView } from '@/pages/pages/settings-panel/view-settings/useUpdateView';

const DEFAULT_RECORDS_LIMIT = '25';

function CustomLimitLabel() {
  const [t] = useTranslation();

  return (
    <div className="flex items-center gap-2">
      <span className="font-medium text-emphasis">
        {t('pages.element_settings.common.categories.data_display.general_settings.limit')}
      </span>
      <Tooltip>
        <Tooltip.Trigger asChild>
          <span>
            <InfoIcon size={16} />
          </span>
        </Tooltip.Trigger>
        <Tooltip.Content align="start">
          {t(
            'pages.element_settings.common.categories.data_display.general_settings.refresh_the_page'
          )}
        </Tooltip.Content>
      </Tooltip>
    </div>
  );
}

export function DataDisplayGeneralSettings({
  isSortingEnabled = true
}: {
  isSortingEnabled?: boolean;
}) {
  const [t] = useTranslation();
  const { view, sourceObject } = useActiveViewContext<ListView>();
  const updateViewSchema = useUpdateView<ListView>();

  // This option has been removed in v4. For old apps, we will show a warning banner.
  const shouldShowSourceFiltersWarning =
    view.source.criteria.match === 'all' && view.source.criteria.rules.length > 0;

  const handleChangeLimit = (value: boolean) => {
    if (value) {
      updateViewSchema({
        source: {
          ...view.source,
          limit: ''
        }
      });
      return;
    }

    updateViewSchema({
      source: {
        ...view.source,
        limit: DEFAULT_RECORDS_LIMIT
      }
    });
  };

  const handleChangeRecordsLimitInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateViewSchema({
      source: {
        ...view.source,
        limit: e.target.value
      }
    });
  };

  const handleSourceFiltersSubmit = (data: BuilderViewSourceCriteriaRule[][]) => {
    updateViewSchema({
      source: {
        ...view.source,
        criteria: {
          match: 'any',
          rules: [],
          groups: data || []
        }
      }
    });
  };

  return (
    <div className="space-y-4">
      <BuilderAccordion>
        <BuilderAccordion.Item
          isDefaultOpen
          label={t(
            'pages.element_settings.common.categories.data_display.general_settings.source_filters'
          )}
        >
          <div className="space-y-4">
            {shouldShowSourceFiltersWarning && <SourceFiltersWarningBanner />}
            <SourceFiltersGroupsCard
              sourceObject={sourceObject}
              sourceFiltersCriteria={view.source.criteria}
            />
            <SourceFiltersDialog
              sourceObject={sourceObject}
              sourceFiltersCriteria={view.source.criteria}
              onFormSubmit={handleSourceFiltersSubmit}
            />
          </div>
        </BuilderAccordion.Item>
        {isSortingEnabled && (
          <BuilderAccordion.Item isDefaultOpen label={t('keywords.sorting')}>
            <div className="space-y-4">
              <p className="text-xs">
                {t(
                  'pages.element_settings.common.categories.data_display.general_settings.sorting_description'
                )}
              </p>
              <div className="space-y-2">
                {view.source.sort?.map((sortItem, index) => (
                  <SortingListItem
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${sortItem.field}-${index}`}
                    sortItem={sortItem}
                    dataTestId={`inline-knack-sorting-field-${index}`}
                  />
                ))}
              </div>
              <SortingDialog />
            </div>
          </BuilderAccordion.Item>
        )}
        <BuilderAccordion.Item isDefaultOpen customLabel={<CustomLimitLabel />}>
          <Label className="flex items-center gap-2">
            <Switch checked={view.source?.limit === ''} onCheckedChange={handleChangeLimit} />
            {t(
              'pages.element_settings.common.categories.data_display.general_settings.show_all_records'
            )}
          </Label>
          {view.source?.limit && (
            <div className="ml-10 mt-2 flex items-center gap-1">
              <span>
                {t(
                  'pages.element_settings.common.categories.data_display.general_settings.show_the_first'
                )}
              </span>
              <Input
                aria-label={t(
                  'pages.element_settings.common.categories.data_display.general_settings.show_all_records'
                )}
                data-testid="records-limit-input"
                className="h-auto w-10 px-2 py-1.5"
                value={view.source.limit}
                onChange={handleChangeRecordsLimitInput}
              />
              <span>{t('keywords.records')}</span>
            </div>
          )}
        </BuilderAccordion.Item>
        <BuilderAccordion.Item
          isDefaultOpen
          label={t(
            'pages.element_settings.common.categories.data_display.general_settings.empty_state'
          )}
        >
          <Label>
            {t(
              'pages.element_settings.common.categories.data_display.general_settings.empty_list_text'
            )}
            <Input
              className="my-2"
              value={view.no_data_text}
              onChange={(e) =>
                updateViewSchema({
                  no_data_text: e.target.value
                })
              }
            />
          </Label>
          <p className="mb-4 text-xs text-subtle">
            {t(
              'pages.element_settings.common.categories.data_display.general_settings.empty_records_text_description'
            )}
          </p>
          <Label className="mb-4 flex items-center gap-2">
            <Switch
              checked={view.hide_fields}
              onCheckedChange={(value) =>
                updateViewSchema({
                  hide_fields: value
                })
              }
            />
            {t(
              'pages.element_settings.common.categories.data_display.general_settings.hide_fields_with_no_values'
            )}
          </Label>
        </BuilderAccordion.Item>
      </BuilderAccordion>
    </div>
  );
}
