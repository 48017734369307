import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  HiTrash as DeleteIcon,
  HiArrowTopRightOnSquare as LinkIcon,
  HiPencil as PencilIcon
} from 'react-icons/hi2';
import { MdDragIndicator as DragIcon } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Button, Dialog, useToast } from '@knack/asterisk-react';

import { type KnackConnection, type KnackObject } from '@/types/schema/KnackObject';
import { useDeleteFieldMutation } from '@/hooks/api/mutations/useDeleteFieldMutation';
import { cn } from '@/utils/tailwind';
import { DiscardChangesModal } from '@/components/DiscardChangesModal';
import { FieldSettings } from '@/components/field-settings/FieldSettings';
import { TextTooltip } from '@/components/TextTooltip';
import { ROUTES } from '@/Router';
import { ConnectionCard } from './ConnectionCard';

export type ConnectionType = 'inbound' | 'outbound';

export function ConnectionCardWrapper({
  connection,
  table,
  connectionType
}: {
  connection: KnackConnection;
  table: KnackObject;
  connectionType: ConnectionType;
}) {
  const [t] = useTranslation();

  const [shouldRenderEditForm, setShouldRenderEditForm] = useState(false);
  const [shouldShowDeleteConnectionModal, setShouldShowDeleteConnectionModal] = useState(false);
  const [shouldShowDiscardChangesModal, setShouldShowDiscardChangesModal] = useState(false);

  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: connection.key
  });

  const sortableItemStyles = {
    transform: CSS.Translate.toString(transform),
    transition
  };

  const { presentToast } = useToast();

  const deleteFieldMutation = useDeleteFieldMutation();

  const handleDeleteConnection = () => {
    deleteFieldMutation.mutate(
      {
        tableKey: table.key,
        fieldKey: connection.key
      },
      {
        onSuccess: () => {
          presentToast({
            title: t('components.data_table.delete_field_dialog.success')
          });
          setShouldShowDeleteConnectionModal(false);
        },
        onError: () => {
          presentToast({
            title: t('components.data_table.delete_field_dialog.error')
          });
          setShouldShowDeleteConnectionModal(false);
        }
      }
    );
  };

  const handleDiscardChanges = () => {
    setShouldShowDiscardChangesModal(false);
    setShouldRenderEditForm(false);
  };

  const hasMoreThanOneConnection =
    (connectionType === 'inbound' && table.connections.inbound.length > 1) ||
    (connectionType === 'outbound' && table.connections.outbound.length > 1);

  return (
    <div
      data-testid={`connection-card-wrapper-${connection.key}`}
      ref={setNodeRef}
      style={sortableItemStyles}
    >
      {!shouldRenderEditForm && (
        <div className="group relative">
          {hasMoreThanOneConnection && (
            <div
              data-testid={`connection-card-rearrange-icon-${connection.key}`}
              className={cn(
                'invisible absolute -left-2.5 top-4 cursor-grab rounded-l align-middle active:cursor-grabbing group-hover:visible group-hover:bg-subtle',
                {
                  'group-hover:visible': !transform,
                  visible: isDragging
                }
              )}
              {...listeners}
              {...attributes}
            >
              <DragIcon size={20} />
            </div>
          )}
          <ConnectionCard
            className="group-hover:bg-subtle"
            connection={connection}
            table={table}
            connectionType={connectionType}
          >
            <span
              className="invisible absolute right-2 top-2 space-x-2 bg-subtle group-hover:visible"
              data-testid="action-buttons"
            >
              {connectionType === 'outbound' && (
                <>
                  <TextTooltip
                    label={t(
                      'components.data_table.right_sidebar.connections.edit_connection_tooltip'
                    )}
                  >
                    <Button
                      intent="minimal"
                      size="xs"
                      className="hover:bg-muted"
                      data-testid="edit-connection-button"
                      onClick={() => setShouldRenderEditForm(true)}
                    >
                      <PencilIcon size={14} />
                    </Button>
                  </TextTooltip>
                  <TextTooltip
                    label={t(
                      'components.data_table.right_sidebar.connections.delete_connection_tooltip'
                    )}
                    shouldPortal
                  >
                    <Button
                      intent="minimal"
                      size="xs"
                      className="hover:bg-muted"
                      data-testid="delete-connection-button"
                      onClick={() => setShouldShowDeleteConnectionModal(true)}
                    >
                      <DeleteIcon size={14} />
                    </Button>
                  </TextTooltip>
                </>
              )}
              {connectionType === 'inbound' && (
                <Link
                  to={`${ROUTES.TABLES}/${connection.object}`}
                  data-testid={`link-to-connection-${connection.key}`}
                >
                  <TextTooltip
                    label={t(
                      'components.data_table.right_sidebar.connections.link_to_connection_tooltip'
                    )}
                  >
                    <Button
                      intent="minimal"
                      size="xs"
                      className="hover:bg-muted"
                      data-testid="link-to-connection-button"
                    >
                      <LinkIcon size={14} />
                    </Button>
                  </TextTooltip>
                </Link>
              )}
            </span>
          </ConnectionCard>
        </div>
      )}

      {shouldRenderEditForm && (
        <div className="relative gap-4 rounded-lg bg-muted p-4">
          <h4 className="mb-4 font-semibold">
            {t('components.data_table.right_sidebar.connections.edit_connection')}
          </h4>
          <FieldSettings
            objectKey={connectionType === 'outbound' ? table.key : connection.object}
            fieldId={connection.key}
            onCancel={() => setShouldShowDiscardChangesModal(true)}
            onSave={() => setShouldRenderEditForm(false)}
            newFieldState={{ isBeingCreated: false }}
          />
          <Button
            intent="minimal"
            size="xs"
            className="absolute right-4 top-4"
            data-testid="delete-connection-button"
            onClick={() => setShouldShowDeleteConnectionModal(true)}
          >
            <DeleteIcon size={14} />
          </Button>
        </div>
      )}

      <Dialog
        open={shouldShowDeleteConnectionModal}
        onOpenChange={setShouldShowDeleteConnectionModal}
      >
        <Dialog.Content data-testid="delete-connection-modal">
          <Dialog.MainContent className="pb-0">
            <Dialog.Header>
              <Dialog.Title>
                {t(
                  'components.data_table.right_sidebar.connections.delete_connection_dialog.title'
                )}
              </Dialog.Title>
            </Dialog.Header>
            <div className="mt-6">
              <p>
                <Trans
                  i18nKey="components.data_table.right_sidebar.connections.delete_connection_dialog.description"
                  values={{
                    fieldName: connection.name
                  }}
                >
                  <span className="font-semibold">everywhere</span>
                  <br />
                  <ConnectionCard
                    key="1"
                    className="mt-4"
                    connection={connection}
                    table={table}
                    connectionType={connectionType}
                  />
                  <span className="font-semibold">This cannot be undone</span>
                </Trans>
              </p>
            </div>
          </Dialog.MainContent>
          <Dialog.Footer className="gap-2">
            <Dialog.Close asChild>
              <Button intent="minimal">{t('actions.cancel')}</Button>
            </Dialog.Close>
            <Button
              intent="destructive"
              onClick={handleDeleteConnection}
              data-testid="confirm-delete-connection-button"
            >
              {t('actions.delete')}
            </Button>
          </Dialog.Footer>
        </Dialog.Content>
      </Dialog>

      <DiscardChangesModal
        isOpen={shouldShowDiscardChangesModal}
        onOpenChange={setShouldShowDiscardChangesModal}
        onConfirm={handleDiscardChanges}
      />
    </div>
  );
}
