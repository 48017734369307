import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { HiBarsArrowDown as BarsDownIcon, HiInformationCircle as InfoIcon } from 'react-icons/hi2';
import { Button, Dialog } from '@knack/asterisk-react';

import { KNOWLEDGE_BASE_URLS } from '@/utils/knowledge-base';
import { LearnMoreLink } from '@/components/LearnMoreLink';

export function FormDisplayRuleActionsInfoButton() {
  const [t] = useTranslation();

  const [isRuleActionInfoModalOpen, setIsRuleActionInfoModalOpen] = useState(false);

  return (
    <>
      <button
        type="button"
        onClick={() => setIsRuleActionInfoModalOpen(true)}
        aria-label={t(
          'pages.element_settings.form.categories.form_fields.display_rules.rule_actions_info_title'
        )}
      >
        <InfoIcon size={16} />
      </button>

      <Dialog open={isRuleActionInfoModalOpen} onOpenChange={setIsRuleActionInfoModalOpen}>
        <Dialog.Content>
          <Dialog.MainContent>
            <Dialog.Header>
              <Dialog.Title>
                {t(
                  'pages.element_settings.form.categories.form_fields.display_rules.rule_actions_info_title'
                )}
              </Dialog.Title>
              <Dialog.Description className="sr-only">
                {t(
                  'pages.element_settings.form.categories.form_fields.display_rules.rule_actions_info_description'
                )}
              </Dialog.Description>
            </Dialog.Header>

            <div className="mt-4">
              <h3 className="mb-2 font-medium">
                {t(
                  'pages.element_settings.form.categories.form_fields.display_rules.rule_action_types'
                )}
              </h3>
              <ul className="mb-4 list-outside list-disc space-y-2 pl-5">
                <li>
                  <Trans
                    i18nKey="pages.element_settings.form.categories.form_fields.display_rules.rules_information_3"
                    components={[<span key="0" className="font-semibold" />]}
                  />
                </li>
                <li>
                  <Trans
                    i18nKey="pages.element_settings.form.categories.form_fields.display_rules.rules_information_4"
                    components={[<span key="0" className="font-semibold" />]}
                  />
                </li>
              </ul>
              <div>
                <h3 className="mb-2 font-medium">
                  {t('pages.element_settings.form.categories.form_fields.display_rules.rule_order')}
                </h3>
                <div className="flex items-center rounded-lg border border-default p-2 text-xs">
                  <BarsDownIcon size={28} className="mr-1 shrink-0 text-brand" />
                  {t(
                    'pages.element_settings.form.categories.form_fields.display_rules.rule_order_description'
                  )}
                </div>
              </div>
            </div>
          </Dialog.MainContent>
          <Dialog.Footer className="justify-between">
            <LearnMoreLink href={KNOWLEDGE_BASE_URLS.DISPLAY_RULES_OVERVIEW} />
            <Dialog.Close asChild>
              <Button intent="minimal">{t('actions.close')}</Button>
            </Dialog.Close>
          </Dialog.Footer>
        </Dialog.Content>
      </Dialog>
    </>
  );
}
